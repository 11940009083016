import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { APIGet } from '../../API';
import Flag from '../Flags';
import I18N from '../../I18N'
import { url_lang } from '../../languages';

import show_more_icon from './c-add.svg';
import show_less_icon from './c-delete.svg';

const PerformanceItem = ({icons, headline, children, render_static}) => {
	return(<>
		<Col className='pb-3 pb-lg-0 d-none d-md-block'>
			<div className="py-4 text-center">
				<div>
					{icons.map( (i,id) => <img alt='headline' key={'img' + id} className='icon me-2' src={i} /> )}
				</div>
				<div className="numbers pt-3 pb-1">{children}</div>
				<span className="text">{headline}</span>
			</div>
		</Col>
		<Col xs={12} className='pb-3 pb-lg-0 d-md-none'>
			<div className="py-4 text-center">
				<div>
					{icons.map( (i,id) => <img alt='headline' key={'img' + id} className='icon me-2' src={i} /> )}
				</div>
				<div className="numbers pt-3 pb-1">{children}</div>
				<span className="text">{headline}</span>
			</div>
		</Col>
	</>);
}

const i = (num) => I18N.intval(num);
const f = (num) => I18N.floatval(num, 2);

const AdminInternDashboard = (props) => {
	const [ updateTrigger, setUpdateTrigger ] = useState(0);
	const [ stats, setStats ] = useState(null);
	const [ exchangeRates, setExchangeRates ] = useState({});
	const this_year = new Date().getFullYear();
	const this_month = new Date().getMonth();

	const [ moreRevenue, setMoreRevenue ] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			setUpdateTrigger( (ot) => ot + 1 );
		}, 300000);

		return () => clearTimeout(timer);
	}, [])

	useEffect( () => {
		APIGet('exchange-rates').then( (r) => {
			r.data.body.forEach( e => setExchangeRates((oe) => {
				return {...oe, [e.currency_code]: e.rate};
			}));
		});
	}, []);

	useEffect( () => {
		APIGet('admin', {'action': 'dump_stats_cache', 'new_cache': 'true'}).then( (r) => {
			setStats(r.data.body.stats);
		});
	}, [updateTrigger]);

	const MoneyFormat = ({revenue_item, show_all = true}) => {
		if(!Object.keys(exchangeRates).length)
			show_all = true;
		let show_item = revenue_item;
		if(!show_item[url_lang().currency])
			show_item[url_lang().currency] = 0;
		if(!show_all) {
			show_item = {[url_lang().currency]: revenue_item[url_lang().currency]};
			Object.keys(revenue_item).forEach( (ri) => {
				if (ri !== url_lang().currency) {
					show_item[url_lang().currency] += (revenue_item[ri] * exchangeRates[ri]) / exchangeRates[url_lang().currency];
				}
			});
		}
		return Object.keys(show_item).map( (ri,i) => {
			switch (ri) {
				case 'dkk':
					return <span key={ri + '' + i}>{!!i && <br />}<Flag lang='da' height={14} />{f(show_item[ri])} kr.</span>;
				case 'nok':
					return <span key={ri + '' + i}>{!!i && <br />}<Flag lang='no' height={14} />{f(show_item[ri])} kr.</span>;
				case 'sek':
					return <span key={ri + '' + i}>{!!i && <br />}<Flag lang='sv' height={14} />{f(show_item[ri])} kr.</span>;
				case 'usd':
					return <span key={ri + '' + i}>{!!i && <br />}<Flag lang='en' height={14} />${f(show_item[ri])}</span>;
				case 'eur':
					return <span key={ri + '' + i}>{!!i && <br />}<Flag lang='eu' height={14} />€{f(show_item[ri])}</span>;
				default:
					return <span key={ri + '' + i}>{!!i && <br />}{ri.toUpperCase()}</span>;
			}
		});
	}

	if(!stats)
		return null;

	return (<>
		<Container className='py-4'>
			<Row className='px-3 align-items-center box_row' style={{backgroundColor: "#07a5bb"}}>
				<PerformanceItem
					icons={['https://static.zevio.com/gfx/admin-money-icon-0.svg']}
					headline={'Revenue today'}
				>
					<MoneyFormat revenue_item={stats.revenue_day} show_all={moreRevenue} />
				</PerformanceItem>
				<PerformanceItem
					icons={['https://static.zevio.com/gfx/admin-money-icon-0.svg']}
					headline={'Revenue this week'}
				>
					<MoneyFormat revenue_item={stats.revenue_this_week} show_all={moreRevenue} />
				</PerformanceItem>
				<PerformanceItem
					icons={['https://static.zevio.com/gfx/admin-money-icon-0.svg']}
					headline={'Revenue last week'}
				>
					<MoneyFormat revenue_item={stats.revenue_last_week} show_all={moreRevenue} />
				</PerformanceItem>
				<PerformanceItem
					icons={['https://static.zevio.com/gfx/admin-money-icon-0.svg']}
					headline={'Revenue ' + this_year}
				>
					<MoneyFormat revenue_item={stats.revenue_year} show_all={moreRevenue} />
				</PerformanceItem>
			</Row>{moreRevenue ? <div className='cursor-pointer text-end pe-3' onClick={() => setMoreRevenue(false)}><img alt='toggleMore' className='toggle_neg_margin' style={{width: "24px"}} src={show_less_icon} /></div>
			: <div className='cursor-pointer text-end pe-3' onClick={() => setMoreRevenue(true)}><img alt='toggleLess' className='toggle_neg_margin'  style={{width: "24px"}} src={show_more_icon} /></div>}
			<Row className='px-3 mb-3 align-items-center box_row' style={{backgroundColor: "#f16c65"}}>
				<PerformanceItem
					icons={ ['https://static.zevio.com/gfx/admin-ticket-icon-0.svg', 'https://static.zevio.com/gfx/person-icon-0.svg', 'https://static.zevio.com/gfx/admin-app-icon-1.svg']}
					headline={'Registrations today'}
				>
					{i(stats.event_registrations_day)} / {i(stats.user_registrations_day)} / {i(stats.event_registrations_app_day)}
				</PerformanceItem>
				<PerformanceItem
					icons={ ['https://static.zevio.com/gfx/admin-ticket-icon-0.svg', 'https://static.zevio.com/gfx/person-icon-0.svg', 'https://static.zevio.com/gfx/admin-app-icon-1.svg']}
					headline={'Registrations yesterday'}
				>
					{i(stats.event_registrations_yesterday)} / {i(stats.user_registrations_yesterday)} / {i(stats.event_registrations_app_yesterday)}
				</PerformanceItem>
				<PerformanceItem
					icons={ ['https://static.zevio.com/gfx/admin-ticket-icon-0.svg', 'https://static.zevio.com/gfx/person-icon-0.svg', 'https://static.zevio.com/gfx/admin-app-icon-1.svg']}
					headline={'Registrations '+ props.render_static(I18N.month(this_month))}
				>
					{i(stats.event_registrations_month)} / {i(stats.user_registrations_month)} / {i(stats.event_registrations_app_month)}
				</PerformanceItem>
				<PerformanceItem
					icons={ ['https://static.zevio.com/gfx/admin-ticket-icon-0.svg', 'https://static.zevio.com/gfx/person-icon-0.svg', 'https://static.zevio.com/gfx/admin-app-icon-1.svg']}
					headline={'Registrations ' + this_year}
				>
					<span style={{fontSize: "80%"}}>{i(stats.event_registrations_year)} / {i(stats.user_registrations_year)} / {i(stats.event_registrations_app_year)}</span>
				</PerformanceItem>
			</Row>
			<Row className='px-3 mb-3 align-items-center box_row' style={{backgroundColor: "#c9b600"}}>
				<PerformanceItem
					icons={['https://static.zevio.com/gfx/person-icon-0.svg']}
					headline={'Users this week'}
				>
					{i(stats.new_users_this_week)}
				</PerformanceItem>
				<PerformanceItem
					icons={['https://static.zevio.com/gfx/person-icon-0.svg']}
					headline={'Users last week'}
				>
					{i(stats.new_users_last_week)}
				</PerformanceItem>
				<PerformanceItem
					icons={['https://static.zevio.com/gfx/person-icon-0.svg']}
					headline={'Users ' + props.render_static(I18N.month(this_month))}
				>
					{i(stats.new_users_month)}
				</PerformanceItem>
				<PerformanceItem
					icons={['https://static.zevio.com/gfx/person-icon-0.svg']}
					headline={'Users ' + this_year}
				>
					{i(stats.new_users_year)}
				</PerformanceItem>
			</Row>
			<Row className='px-3 mb-3 align-items-center box_row' style={{backgroundColor: '#475f72'}}>
				<PerformanceItem
					icons={['https://static.zevio.com/gfx/admin-likefollow-icon-0.svg']}
					headline={'Follow/likes today'}
				>
					{i(stats.org_likes_day)}
				</PerformanceItem>
				<PerformanceItem
					icons={['https://static.zevio.com/gfx/admin-likefollow-icon-0.svg']}
					headline={'Follow/likes ' + props.render_static(I18N.month(this_month))}
				>
					{i(stats.org_likes_month)}
				</PerformanceItem>
				<PerformanceItem
					icons={['https://static.zevio.com/gfx/admin-likefollow-icon-0.svg']}
					headline={'Follow/likes ' + this_year}
				>
					{i(stats.org_likes_year)}
				</PerformanceItem>
				<PerformanceItem
					icons={['https://static.zevio.com/gfx/admin-likefollow-icon-0.svg']}
					headline={'Follow/likes total'}
				>
					{i(stats.org_likes_total)}
				</PerformanceItem>
			</Row>
			<Row className='px-3 mb-3 align-items-center box_row' style={{backgroundColor: '#a45f95'}}>
				<PerformanceItem
					icons={['https://static.zevio.com/gfx/admin-organizer-icon-1.svg']}
					headline={'New organizers this week'}
				>
					{i(stats.new_orgs_this_week)}
				</PerformanceItem>
				<PerformanceItem
					icons={['https://static.zevio.com/gfx/admin-organizer-icon-1.svg']}
					headline={'New organizers last week'}
				>
					{i(stats.new_orgs_last_week)}
				</PerformanceItem>
				<PerformanceItem
					icons={['https://static.zevio.com/gfx/admin-organizer-icon-1.svg']}
					headline={'New organizers ' + props.render_static(I18N.month(this_month))}
				>
					{i(stats.new_orgs_month)}
				</PerformanceItem>
				<PerformanceItem
					icons={['https://static.zevio.com/gfx/admin-organizer-icon-1.svg']}
					headline={'New organizers ' + this_year}
				>
					{i(stats.new_orgs_year)}
				</PerformanceItem>
			</Row>
			<Row className='px-3 mb-3 align-items-center box_row' style={{backgroundColor: "#868686"}}>
				<PerformanceItem
					icons={['https://static.zevio.com/gfx/admin-event-icon-0.svg']}
					headline={'Upcoming events'}
				>
					{i(stats.future_events)}
				</PerformanceItem>
				<PerformanceItem
					icons={['https://static.zevio.com/gfx/admin-ticket-icon-0.svg']}
					headline={'Registrations upcoming events'}
				>
					{i(stats.registrations_future_events)}
				</PerformanceItem>
				<PerformanceItem
					icons={['https://static.zevio.com/gfx/admin-event-icon-0.svg']}
					headline={'Total events'}
				>
					{i(stats.total_events)}
				</PerformanceItem>
			</Row>
		</Container>
	</>);
}

export default AdminInternDashboard;
