import React, { useState, useEffect } from "react";
import { Col, Container, Row } from 'reactstrap';
import Footer from '../c/Footer';
import { GreenButton, AlertReusable } from '../c/ReusableElems';
import ZevioNav from '../c/ZevioNav';
import { T } from '../traks';
import { LL } from "../Routes";
import { useNavigate, useParams } from "react-router-dom";
import { APIPost } from "../API";
import { RefreshSession } from "./Login";

const ResetPasswordPage = () => {

	const navigate = useNavigate();
	const { token } = useParams();
	const sessionId = token;
	const [user, setUser] = useState({
		'password': '',
		'session_id': sessionId,
	});

	const [verifyPassword, setVerifyPassword] = useState('');

	const submit_button_disabled = (user.password.length < 6 || verifyPassword.length < 6 || user.password !== verifyPassword);

	const [feedbackTxt, setFeedbackTxt] = useState('');
	const [feedbackTxt2, setFeedbackTxt2] = useState('');
	const [activateAlert, setActivateAlert] = useState(0);
	const [alertColor, setAlertColor] = useState('');
	const [alertText, setAlertText] = useState('');

	useEffect(() => {
		(user.password.length > 1 && user.password.length < 6 ? setFeedbackTxt(<T>New password too short</T>) : setFeedbackTxt(''));
	}, [user.password]);
	useEffect(() => {
		if (user.password.length > 5 && verifyPassword !== '') {
			(user.password !== verifyPassword ? setFeedbackTxt2(<T>Passwords do not match</T>) : setFeedbackTxt2(''));
		}
	}, [verifyPassword, user.password]);

	const updateUser = () => {
		APIPost("users", { ...user, 'action': 'update_user' }).then((response) => {
			if (response.status === 200) {
				setAlertText(<T>Password reset. Redirecting.</T>)
				setAlertColor("success");
				setActivateAlert(activateAlert + 1);
				RefreshSession(user.sessionId);
				setTimeout(() => navigate(LL('/login')), 1000);
			}
			}).catch((error) => {
				setAlertText(<T>Password reset failed.</T>)
				setAlertColor("danger");
				setActivateAlert(activateAlert + 1);
			}
		)
	}

	return (
		<Container className="pb-5">
			<div className="pt-4 pb-3 d-flex flex-col align-items-center justify-content-between">
				<div>
					<h3><T>Reset password</T></h3>
				</div>
			</div>
			<AlertReusable
				activateAlert={activateAlert}
				color={alertColor}
				text={alertText}
			/>
			<form autoComplete="off" onSubmit={(e) => e.preventDefault()} id='reset_password'>
				<div className="pt-5"> </div>
				<Container fluid className="bg_white promotion_box p-0 pb-3">
					<Row>
						<div className="bottom_border_promo_listing w-100 font_15px font_weight_500">
							<div className="py-2 px-2 ps-lg-4 font_weight_500">
								<T>Change password</T>
							</div>
						</div>
						<Col xs="12" lg="6" className="pt-2 pb-3">
							<div className="px-2 ps-lg-4">
								<div className="py-2">
									<T>New password</T>
									<input
										type='password'
										name="Password"
										id="Password"
										form='edit_user'
										autoComplete="off"
										className='zevio_input w-100'
										onChange={(e) => setUser({ ...user, password: e.target.value })}
								/>
								</div>
							</div>
							<p className="red_text m-0 ps-4">{feedbackTxt}</p>
						</Col>
						<Col xs="12" lg="6" className="pt-2 pb-3">
							<div className="px-2 pe-lg-4">
								<div className="py-2">
									<T>New password again</T>
									<input
										type='password'
										name='Passwordagain'
										id='Passwordagain'
										form='edit_user'
										autoComplete="off"
										className='zevio_input w-100'
										onChange={(e) => setVerifyPassword(e.target.value) }
									/>
								</div>
							</div>
							<p className="red_text m-0">{feedbackTxt2}</p>
						</Col>
					</Row>
				</Container>
				<div className="pt-4 d-flex flex-col align-items-center justify-content-end">
					<GreenButton disabled={submit_button_disabled} onClick={updateUser}><T>Reset</T></GreenButton>
				</div>
			</form>
		</Container>
	);
}

const ResetPassword = () => {
	return (
		<React.Fragment>
			<ZevioNav />
			<ResetPasswordPage />
			<Footer />
		</React.Fragment>
	);
}
export default ResetPassword;
