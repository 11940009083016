import React, { useContext } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { MeContext } from '../MeContext';
import { TraksConsumer } from '../traks';
import AdminInternDashboard from '../c/admin/AdminInternDashboard';
import AdminInternNav from '../c/admin/AdminInternNav';
import AdminInternOrders from '../c/admin/AdminInternOrders';
import AdminInternOrganizers from '../c/admin/AdminInternOrganizers';
import Seppuku from '../c/admin/Seppuku';
import { useParams } from "react-router-dom";

import '../css/AdminIntern.css';

const NotEnoughPrivileges = () => {
	return (
		<Container style={{paddingBottom: "1000px"}}>
			<Row className='pt-4'>
				<Col>
					<h4>Insufficient privileges</h4>
					<div className='pt-4'>
						You need to be an internal administrator to access this page
					</div>
				</Col>
			</Row>
		</Container>
	);
}

const AdminPage = ({page}) => {
	switch(page) {
		case 'orders':
			return <AdminInternOrders />;
		case 'organizers':
			return <AdminInternOrganizers />;
		case 'seppuku':
			return <Seppuku />;
		default:
			return <TraksConsumer><AdminInternDashboard /></TraksConsumer>;
	}
}

const AdminIntern = () => {
	const { me } = useContext(MeContext);
	const { page } = useParams();

	return (<>
		<AdminInternNav />
		<Container fluid className='admin_intern'>
			{me && me.roles.includes(420) ? <AdminPage page={page} /> : <NotEnoughPrivileges />}
		</Container>
	</>);
}

export default AdminIntern;
