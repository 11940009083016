import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { url_lang } from '../languages';

const checkPureText = (html) => {
	const tmp = document.createElement("div");
	tmp.innerHTML = html;
	return tmp.innerText.trim().length;
}

const BigTextInner = (props) => {
	const [ body, set_body ] = useState(null);
	const [ failed, set_failed ] = useState(false);
	const [ lang, set_lang ] = useState(props.lang.lang);

	useEffect(() => {
		const url = "https://zwedit.s3.eu-north-1.amazonaws.com/b/zeviong/b." + props.k + "." + lang + ".html";
		axios.get(url, { responseType: "text" }).then(x => {
			if (x.status === 200) {
				if(checkPureText(x.data)) {
					set_body(x.data);
				} else {
					if(lang !== 'en') {
						set_lang('en');
					}
				}
			} else {
				set_failed(true);
			}
		}).catch(e => {
			set_failed(true);
		});
	}, [lang, props.k]);

	let r = <span>...</span>; // XXX allow custom loader?
	if (failed) {
		r = <span data-error="could not fetch document"></span>; // XXX allow custom "not found" message?
	} else if (body !== null) {
		r = <div dangerouslySetInnerHTML={{__html:body}}></div>;
	}
	return r;
}

const BigText = (props) => {
	let lang = url_lang();
	if (!lang) return <span></span>;
	return <BigTextInner {...props} lang={lang}/>
};

export default BigText;
