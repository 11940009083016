import React, { useContext } from "react";
import OrderContext from "../Amopus/useOrder";
import Receipt from "./Receipt";


const TransactionSummary = () => {
	const { productData, showCurrency, orderDetails, orderId } = useContext(OrderContext);
	return (
		<div className="w-100">
			<div className="p-3 invisible d-lg-none">T</div>
			<div className="d-flex flex-col gap-3">
				<div className="p-0" style={{ maxWidth: 100 }}>
					<img
						className="w-100 rounded overflow-hidden"
						src={productData.imageURL}
						alt="prodImg"
					/>
				</div>
				<div className="d-flex flex-column align-content-center">
					<p>{productData.headline}</p>
					<p className="text-secondary">{productData.ownerName}</p>
					<p>
						{showCurrency.format(orderDetails.lines[0].unitpriceinclvat / 100)}
					</p>
				</div>
			</div>
			{!orderId && <Quantifier /> }
			<Receipt />
			<hr className="d-md-none" />
		</div>
	);
};

const Quantifier = () => {
	const { quantity, setQuantity } = useContext(OrderContext);
	const setVal = (e) => {
		let val = e.target.value;
		if (!val.length) setQuantity();
		else if (val <= 0) setQuantity(1);
		else if (val >= 99) setQuantity(99);
		else {
			setQuantity(val);
		}
	};
	const filterInput = (e) => {
		if (e.code.includes("Key")) e.preventDefault();
	};
	return (
		<div
			className="d-flex rounded border text-center w-100 justify-content-evenly"
			style={{ maxWidth: "100px" }}
		>
			<button
				disabled={quantity >= 99}
				onClick={() => setQuantity((p) => p + 1)}
				className="w-25 rounded-start border-0 btn btn-outline-secondary p-0"
			>
				+
			</button>
			<input
				type="number"
				value={quantity}
				onKeyDown={filterInput}
				onChange={setVal}
				min={1}
				max={99}
				className="border-0 border-start border-end w-50 text-center"
			/>
			<button
				disabled={quantity <= 1}
				onClick={() => setQuantity((p) => p - 1)}
				className="rounded-end w-25 border-0 btn btn-outline-secondary p-0"
			>
				-
			</button>
		</div>
	);
};


export default TransactionSummary