import React, {useState, useEffect} from 'react';
import { Carousel, CarouselItem, CarouselIndicators, Col, Container, Row } from 'reactstrap';
import { EventDateTime, GreenButton, ImageWithSrcSet } from '../c/ReusableElems';
import { APIGet } from '../API.js';
import Countdown from './Countdown';
import { T } from '../traks';
import { LL } from "../Routes";

const PromotionalCarousel = (props) => {

	const [activeIndex, setActiveIndex] = useState(0);
	const [animating, setAnimating] = useState(false);
	const [items, setData] = useState([]);

	function onExiting() {
		setAnimating(true);
	}

	function onExited() {
		setAnimating(false);
	}

	function next() {
		if (animating) return;
		const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
		setActiveIndex(nextIndex);
	}

	function previous() {
		if (animating) return;
		const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
		setActiveIndex(nextIndex);
	}

	function goToIndex(newIndex) {
		if (animating) return;
		setActiveIndex(newIndex);
	}
	const SignUpButton = ({item, children}) => {
		return (
			<a className="link_nostyle" href={
				LL("/event/") + (item.Safename + ("-") + item.Id)
			}>
				<GreenButton className="w100_mobile">{children}</GreenButton>
			</a>
		);
	}

	useEffect(() => {
		if(props.searchParams)
			APIGet('search', props.searchParams)
				.then((res) => {
					setData(res.data.body)
				})
				.catch((error) => {
					console.log('Error:', error);
				})
	}, [props.searchParams]);

	if(items.length) {
		return (
		<Carousel className="text-white" activeIndex={activeIndex} next={next} previous={previous}>
			{items.map((item) => {
				const futureEvent = item.startTimeStamp > Math.floor(Date.now()/1000);
				let signUpText = <span className="px-4"><T>Watch now</T></span>;
				/* Legacy support for old image handling system */
				let coverImageElem;
				if(item.ImageURL) {
					coverImageElem = <img src={item.ImageURL} alt="Event Hero" className="carousel_img_scale" />;
				}
				if(item.CoverImage) {
					coverImageElem = <ImageWithSrcSet className="carousel_img_scale" URL={item.CoverImage} imgstr='eventimages/cover' img2xstr='eventimages/2xcover' alttxt="Event Hero"/>;
				}
				if(futureEvent)
					signUpText = <T>Sign Up</T>;
				return (
					
					<CarouselItem
						key={item.Id}
						onExiting={onExiting}
						onExited={onExited}
					>
						<div className="carousel_explore d-none d-lg-block">
							<Container className="carousel_height d-flex align-items-center">
								<Row>
									<Col md={7} xl={6} xxl={5} className='d-flex align-items-center'>
										<div>
											<div className="carousel_text" style={{ maxWidth: 560 }}>{item.Title.length > 73 ? `${item.Title.substring(0, 73)}...` : item.Title}</div>
											{futureEvent &&
												<div className="font_18px pt-3">
													<img width="20" height="20" style={{marginBottom: "4px", filter: "invert(100%) sepia(1%) saturate(67%) hue-rotate(4deg) brightness(114%) contrast(100%)"}} className="pt-1" alt="ico_cal" src="https://static.zevio.com/gfx/ico_calendercard.svg"/>
													<span className="ps-2">
														<EventDateTime eventStartTs={item.startTimeStamp} />
													</span>
												</div>
											}
											<div className="d-flex flex-row flex-lg-row pt-4">
												<div className="d-flex justify-content-center align-items-center">
													<SignUpButton item={item}>{signUpText}</SignUpButton>
													{futureEvent && <span className="py-2 mx-lg-4 vertical_line" />}
												</div>
												{futureEvent &&
													<div className="d-flex font_13px align-items-center">
														<div className='mt-3'>
															<Countdown dateVal={item.startTimeStamp}/>
														</div>
													</div>
												}
											</div>
										</div>
									</Col>
									<Col md={5} xl={6} xxl={7}>
										<div className="carousel_gradient">
											{coverImageElem}
											<div className="h3 carousel_img_text">
												<span>
													{item.SubTitle}
												</span>
											</div>
										</div>
									</Col>
								</Row>
							</Container>
						</div>
						<div className="d-lg-none text-center navbar_bg_dark">
							<div style={{position: "relative"}}>
								{coverImageElem}
								<div className="carousel_img_text pb-4 carousel_gradient">
									{item.SubTitle}
								</div>
							</div>
							<div className='pt-4 pb-3 px-4'>
								<h1>{item.Title}</h1>
								{futureEvent && <>
									<div className="font_18px pt-3">
										<img width="20" height="20" style={{marginBottom: "5px", filter: "invert(100%) sepia(1%) saturate(67%) hue-rotate(4deg) brightness(114%) contrast(100%)"}} className="pt-1" alt="ico_cal" src="https://static.zevio.com/gfx/ico_calendercard.svg"/>
										<span className="font-15px ps-2">
											<EventDateTime eventStartTs={item.startTimeStamp} />
										</span>
									</div>
									<div className="countdown_text d-flex font_13px pt-3 pb-3 justify-content-center">
										<Countdown dateVal={item.startTimeStamp}/>
									</div>
								</>}
								<div className="pt-3 pb-4">
									<SignUpButton item={item}>{signUpText}</SignUpButton>
								</div>
							</div>
						</div>
					</CarouselItem>
				)})}
				<div className="d-none d-lg-block">
					<Container>
						<Col className="carousel_dots d-none d-lg-block" >
							<CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
						</Col>
					</Container>
				</div>
		</Carousel>
		);
	} else {
		return (
			<div className='pushcontentfix'>Loading...</div>
		);
	}
}

export default PromotionalCarousel;
