import React from "react";
import { T } from "../../../traks";
import DotLoader from "../../DotLoader";
const Processing = () => {
	return (    
			<div
				style={{ height: "180px" }}
				className="d-flex flex-row justify-content-center align-items-center"
			>
				<T>Processing registration</T>
				<DotLoader />
			</div>
	);
};

export default Processing;
