import React, { useState, useEffect } from 'react';
import { Container } from 'reactstrap';
import { APIGet, APIPost } from '../API.js';
import { T } from '../traks';
import { EventCard, OrganizerCard, ProductCard } from "./Cards";
import { HorizontalSmoothScroller } from "./ReusableElems";
import {url_lang } from "../languages";
import EventFiltering from "../c/EventFiltering";

export const MostWatchedEvents = () => {
	const language = (url_lang().id.toString());
	const [eventData, setEventData] = useState();
	useEffect(() => {
		APIGet('search', { 'most_watched': true, 'oldevents': true, 'lang': language, 'limit': 10 })
			.then((res) => {
				setEventData(res.data.body);
		}	)
	}, [language])

	if (eventData && eventData.length)
		return (
			<HorizontalSmoothScroller>
				{eventData.map((v, i) => (
					<EventCard
						eventData={v} key={"explore" + i}
					/>
				))}
			</HorizontalSmoothScroller>
		);
	else {
		return (
		<div className="py-4">
			<span className="font_15px"><T>No events found with matching criteria</T></span>
		</div>
		)
	}
}

const ProductCardComponent = () => {

	const [productsData, setProductsData] = useState([]);
	useEffect(() => {
		const productparams = {
			'organizer_ids': [1],
			'action': 'get_products',
			'only_active': ['true'],
		};
		APIPost('products', productparams)
			.then((res) => {
				setProductsData(res.data.products);
			})
			.catch((error) => {
				console.log(['Error getting products:', error]);
		}	)
	}, [])

	if (productsData && productsData.length)
		return (
			<div className="scrolling-wrapper-flexbox">
				{productsData.map((v, i) => v.active === true ?
					<ProductCard data={v} i={i} key={i} />
					:
					null
				)}
			</div>
		);
	return null;
}

const ExploreContent = () => {

	let text = <T>All events</T>

	return (
		<Container fluid className="bg_white px-0">
			<Container fluid className="explore_background_color py-5">
				<Container className='px-2 px-lg-0'>
					<EventFiltering
						limit={8}
						buttonLoad={true}
						noAutoLang={false}
						allEvents={true}
						headerText={text}
					/>
				</Container>
			</Container>
			<Container fluid className="pt-5">
				<Container className="px-2 px-lg-0">
					<div className="explore_subtitle"><T>Hot deals</T></div>
					<div className="py-3 py-lg-4">
						<ProductCardComponent />
					</div>
				</Container>
			</Container>
		</Container>
	);
}

export default ExploreContent;
