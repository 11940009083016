import React, { useContext, useState, useEffect } from 'react';
import { HorizontalSmoothScroller } from "./ReusableElems";
import { APIGet } from '../API.js';
import { url_lang } from "../languages";
import { T } from '../traks';
import { EventCard } from "./Cards";
import { MeContext } from '../MeContext';

const shuffle = (array) => {
	let currentIndex = array.length,  randomIndex;

	while (currentIndex > 0) {
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--;
		[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
	}

	return array;
}

const PremiumEvents = () => {
	const { me } = useContext(MeContext);
	const language = (url_lang().id.toString());
	const [eventData, setEventData] = useState();
	useEffect(() => {
		APIGet('search', { 'paidonly': true, 'oldevents': true, 'limit': 10 })
			.then((res) => {
				setEventData(shuffle(res.data.body));
		}	)
	}, [language])

	if (eventData && eventData.length) {
		return (
			<HorizontalSmoothScroller>
				{eventData.map((v, i) => (
					<EventCard
						eventData={v} key={"explore" + i}
					/>
				))}
			</HorizontalSmoothScroller>
		);
	}
	return (
		<div className="py-4">
			<span className="font_15px"><T>No events found with matching criteria</T></span>
		</div>
	);
}

export default PremiumEvents;
