import React, { useContext, useEffect, useState } from "react";
import { APIPost } from "../../API";
import EventCtx from "./useEvent";

export const useProducts = () => {
	const [productsData, setProductsData] = useState([]);
	const { eventId } = useContext(EventCtx);
	useEffect(() => {
		APIPost("products", {
			action: "get_products",
			only_active: ["true"],
			event_ids: [eventId],
		})
			.then((res) => {
				setProductsData(res.data.products);
			})
			.catch((error) => {
				console.log(["Error getting products:", error]);
			});
	}, [eventId]);

	return { productsData };
};

const ProductsCtx = React.createContext();

export default ProductsCtx;
