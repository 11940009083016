import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Col, Modal, ModalBody } from 'reactstrap';
import { T } from '../traks';

const GetAppModal = forwardRef((props, ref) => {

	useImperativeHandle(ref, () => ({
		handleToggle: toggle
	}));

	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);

	return (
		<div>
			<Modal centered isOpen={modal} toggle={toggle}>
				<ModalBody className="text-center">
					<div>
						<img className="btn" style={{ float: 'right' }} src="https://static.zevio.com/gfx/ico_close.svg" alt='icoClose' onClick={toggle} />
					</div>
					<div className="pt-4">
						<T>Select your platform:</T>
					</div>
					<div className="d-flex justify-content-center py-3">
						<Col className="pt-4 pb-2">
							<a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.zevio">
								<img alt="Google Play Store" src="https://static.zevio.com/imgs/getItOnGooglePlay.svg" style={{height: "42px"}}/>
							</a>
						</Col>
						<Col className="pt-4 pb-2">
							<a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/dk/app/zevio/id1586777122">
								<img alt="App store" src="https://static.zevio.com/imgs/download-on-the-app-store.svg" style={{height: "42px"}} />
							</a>
						</Col>
					</div>
				</ModalBody>
			</Modal>
		</div >
	);
})

export default GetAppModal;
