import React, { Suspense, useState } from "react";
import { createRoot } from "react-dom/client";
import { ZevioRoutes } from "./Routes";
import { RefreshSession } from './p/Login';
import { BrowserRouter } from "react-router-dom";
import { MeContext } from './MeContext';
import LikeContext, { useLike } from './LikeContext';
import { APIGet } from './API';
import { TraksProvider } from './traks';
import { language_set } from './languages';

import "./css/zevio.css";
const appRootElem = document.getElementById("app_root");
const approot = createRoot(appRootElem);

APIGet("ipse").then((ipse_me) => {
	if(ipse_me.data.body) {
		ipse_me = ipse_me.data.body.user;
		if(ipse_me.roles === null) {
			ipse_me.roles = []
		}
		if(ipse_me.organizers === null) {
			ipse_me.organizers = []
		}
	} else {
		ipse_me = null;
	}

	const App = (props) => {
		let [ me, setMe ] = useState(ipse_me);

		if(!me) {
			RefreshSession(false);
		} else {
			const cur_org_id = parseInt(localStorage.getItem("zevio_current_organizer_id"));

			if(me.organizers) {
				if(me.organizers.includes(cur_org_id)) {
					me.currentSelectedOrganizer = cur_org_id;
				} else {
					if (me.organizers.length) {
						me.currentSelectedOrganizer = me.organizers[0];
						localStorage.setItem("zevio_current_organizer_id", me.organizers[0]);
					} else {
						me.currentSelectedOrganizer = null
					}
				}
			}
		}

		// XXX I'd prefer to have <ZevioRoutes/> reveal the language,
		// but it's awkward because <ZevioRoutes/ is deeper in the
		// "virtual DOM" than <TraksProvider/>
		let lang = "en";
		const try_lang = window.location.pathname.split("/").slice(1)[0].toLowerCase();
		if (language_set[try_lang]) lang = try_lang;
		return (
			<TraksProvider lang={lang}>
				<MeContext.Provider value={{me, setMe}}>
					<LikeContext.Provider value={useLike(me)}>
						<BrowserRouter>
							<Suspense fallback={<div>Loading...</div>}>
								<ZevioRoutes />
							</Suspense>
						</BrowserRouter>
					</LikeContext.Provider>
				</MeContext.Provider>
			</TraksProvider>
		);
	};

	approot.render(<App/>);
}).catch( (err) => console.log(err));
