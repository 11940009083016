import React from "react";
import ZevioNav from "../c/ZevioNav";
import { FrontPageOrganizerHero, ProfessionalPlatform, JoinCommunity, OrganizeLiveEvent, EngageWithAudience, SellYourProducts, StreamLive, EventsPromotion, HowItWorks } from "../c/OrganizerLanding";
import Footer from "../c/Footer";

const ZevioOrganizerLanding = (props) => {
	return (
		<React.Fragment>
			<ZevioNav />
				<FrontPageOrganizerHero />
				<ProfessionalPlatform />
				<JoinCommunity />
				<StreamLive />
				<HowItWorks />
				<OrganizeLiveEvent />
				<EngageWithAudience />
				<SellYourProducts />
				<EventsPromotion />
			<Footer />
		</React.Fragment>
	);
};

export default ZevioOrganizerLanding;