import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { APIGet } from '../../API';
import { T } from '../../traks';

const NameAndEmail = ({ order }) => {
	return <span>{order.Name}</span>;
}

const OrderRow = ({ o, i }) => {
	let payment = "free";
	if(o.OrderStatusId == 3) {
		payment = <span style={{color: 'red'}}>Awaiting payment</span>
	}
	if(o.OrderStatusId == 1 && o.AmountCents) {
		payment = <span style={{color: 'green', fontWeight: 500}}>{o.AmountCurrency +" "+o.AmountCents/100}</span>;
	}
	return (
		<tr key={'row_' + i} className='text-start'>
			<td><tt>{o.Id}</tt></td>
			<td className='text-start text-nowrap ps-4'><NameAndEmail order={o} /></td>
			<td className='text-nowrap text-truncate ps-4' style={{maxWidth: "33rem"}}><tt className='font_weight_600'>{o.EventId}</tt> - {o.EventTitle}</td>
			<td className='text-nowrap text-truncate ps-4' style={{maxWidth: "5rem"}}>{payment}</td>
			<td className='text-nowrap text-truncate ps-4 d-none d-xxl-table-cell' style={{maxWidth: "20rem"}}>{o.EventSubTitle}</td>
			<td className='text-nowrap ps-4 font_weight_500'><tt>{o.Created}</tt></td>
		</tr>
	);
}

const OrderTable = ({ orders }) => {
	return (
		<table className='table table-striped'>
			<thead>
				<tr className='text-start'>
					<th scope='col'>Id</th>
					<th scope='col' className='ps-4' style={{width: "1px"}}><T>Customer</T></th>
					<th scope='col' className='ps-4'><T>Event</T></th>
					<th scope='col' className='ps-4'><T>Amount paid</T></th>
					<th scope='col' className='ps-4 d-none d-xxl-table-cell'><T>Speaker</T></th>
					<th scope='col' className='ps-4'><T>Time (UTC)</T></th>
				</tr>
			</thead>
			<tbody>
				{orders.map( (o, i) => <OrderRow o={o} i={i} /> )}
			</tbody>
		</table>
	);
}

const AdminInternOrders = () => {
	const [ updateTrigger, setUpdateTrigger ] = useState(0);
	const [ orders, setOrders ] = useState();

	useEffect(() => {
		const timer = setTimeout(() => {
			setUpdateTrigger( (ot) => ot + 1 );
		}, 300000);

		return () => clearTimeout(timer);
	}, [])

	useEffect( () => {
		APIGet('admin', {'action': 'get_orders'}).then( (r) => {
			setOrders(r.data.body.orders);
			console.log(r.data.body.orders);
		});
	}, [updateTrigger]);

	if(!orders)
		return null;

	return (<>
		<div className='py-4 text-center'>
			<h3><T>Zevio Orders</T></h3>
		</div>
		<Container fluid className='ps-4 bg-white' style={{borderRadius: '8px'}}>
			<Row className='px-0'>
				<Col className='px-0'>
					<OrderTable orders={orders} />
				</Col>
			</Row>
		</Container>
	</>);
}

export default AdminInternOrders;
