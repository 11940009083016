import React, { useState, useContext, useEffect, useRef } from "react";
import { Col, Container, Row } from 'reactstrap';
import ZevioNav from "../c/ZevioNav";
import { APIPost } from "../API.js";
import { MeContext } from '../MeContext';
import { T, TraksConsumer } from '../traks';
import { LL } from "../Routes";
import { useNavigate } from "react-router-dom";
import InputCustom from "../c/Input.js";
import DotLoader from '../c/DotLoader';

export const RefreshSession = (session_id) => {
	const key = "zevio_ipse_session_id";
	if(session_id) {
		localStorage.setItem(key, session_id);
	} else {
		localStorage.removeItem(key);
	}
	return;
}

const LoginButton = ({ isProcessing, login }) => {
	if(isProcessing) {
		return <div className="text-center"><DotLoader><T>Processing</T></DotLoader></div>;
	}
	return (
		<button onClick={login} type="submit" className="text-white font_15px btn btn_green w-100 mt-2"><T>Sign&nbsp;in</T></button>
	);
}

const Login = (props) => {
	const [resetPassword, setResetPassword] = useState(false);
	const [wrongCredentials, setWrongCredentials] = useState(false);
	const navigate = useNavigate();
	const [successText, setSuccessText] = useState();
	const queryStringParams = new URLSearchParams(window.location.search);
	const { setMe } = useContext(MeContext);
	const [user, setUser] = useState({
		'username': queryStringParams.get("email") ? queryStringParams.get("email") : '',
		'password': '',
	});

	const [showInvalidEmail, setShowInvalidEmail] = useState(false);
	const [showInvalidPassword, setShowInvalidPassword] = useState(false);
	const [isProcessing, setIsProcessing] = useState(false);

	const login = () => {
		if (!user.username.includes('@')) {
			setShowInvalidEmail(true);
		}
		if (user.password.length < 4) {
			setShowInvalidPassword(true);
		}
		if (!submit_disabled) {
			setIsProcessing(true);
			APIPost("login", user)
				.then((reply) => {
					const user = reply.data.body;
					if (user.sessionId) {
						setMe(user);
						RefreshSession(user.sessionId);
						setTimeout(() => navigate(LL('/upcoming')), 500);
					}
				})
				.catch((error) => {
					setIsProcessing(false);
					if (error.response.status === 401) {
						setUser({ ...user, password: '' });
						setWrongCredentials(true);
					}
				}
			)
		}
	};

	const clickReset = () => {
		setResetPassword(true);
		setSuccessText(<T>We will send you an e-mail with intructions for resetting your password.</T>);
	}

	const doTheReset = () => {
		setResetPassword(false);
		setWrongCredentials(false);
		setSuccessText(<div className="text-center color_green font_15px"><i><T>Check your inbox for a link to reset password.</T></i></div>)
		const postObj = {
			'action': 'forgot_password',
			'email': user.username,
			'request_domain': window.location.origin,
		};
		APIPost('users', postObj).then((response) => console.log(response));
	}

	const WrongCredentials = () => {
		return (
			<div className="py-4 red_text">
				<T>Wrong credentials.</T>&nbsp;
				<span className="btn red_text m-0 p-0" onClick={clickReset}>
					<u><T>Reset password?</T></u>
				</span>
			</div>
		);
	};

	const submit_disabled = !(user.username.length && user.username.includes('@') && user.password.length > 4);
	const reset_button_disabled = !(user.username.length && user.username.includes("@"));
	const placeholders = {email: props.render_static(<T>E-mail</T>), password: props.render_static(<T>Password</T>)};

	const inputElement = useRef(null);
	useEffect(() => {
		if (inputElement.current) {
			inputElement.current.focus();
		}
	}, []);

	return <>
		<div className="font_20px font_weight_600">
			{resetPassword ? <T>Reset password</T> : <T>Login to Zevio</T>}
		</div>
		{successText && <div className="py-4 grey_frontpage">{successText}</div>}
		<div>
			<form id='form_login_email' onSubmit={(e) => e.preventDefault()}>
				<InputCustom
					autofocus={true}
					innerRef={inputElement}
					className='input_login pt-4 w-100'
					value={user.username}
					placeholder={placeholders.email}
					type='email'
					onChange={(username) => setUser({ ...user, username: username})}
				/>
				{(!user.username.includes('@') && showInvalidEmail && !resetPassword) && <div className="red_text"><i><T>Please insert a valid email</T></i></div>}
				{resetPassword || 
					<InputCustom
						className='input_login pt-4 w-100'
						value={user.password}
						placeholder={placeholders.password}
						type='password'
						onChange={(password) => setUser({ ...user, password: password })}
					/>
				}
				{(showInvalidPassword && user.password.length < 4 && !resetPassword) && <div className="red_text"><i>Please insert a valid password</i></div>}
				{(resetPassword && !user.username.includes('@')) && <div className="red_text"><i><T>Please insert a valid email</T></i></div>}
				<div className="pt-4">
					{resetPassword ?
					<button disabled={reset_button_disabled} onClick={doTheReset} type="submit" className="text-white font_15px btn btn_green w-100 mt-2">Reset password</button>
					:
					<LoginButton login={login} isProcessing={isProcessing} />					}
				</div>
				{(wrongCredentials && !resetPassword) && <WrongCredentials />}
			</form>
			<div className="pt-4 font_13px text-center">
				<span className="font_weight_500"><T>Not a member yet?</T></span>
				<span className="ps-2 color_green font_weight_600">
					<a href={LL("/signup")} className="text_no_decoration color_green">
						<T>Sign&nbsp;up</T>
					</a>
				</span>
				{resetPassword || <span className="ps-2 font_11px grey_frontpage cursor-pointer" onClick={clickReset}><T>Forgot password?</T></span>}
			</div>
			<div className="pt-3 color_green text-center font_11px">
				<a href={LL("/terms")} className="color_green text_no_decoration"><T>Terms</T></a> / <a href={LL("/privacy")} className="color_green text_no_decoration"><T>Privacy</T></a>
			</div>
		</div>
	</>;
}

export const LoginPage = () => {
	return (<>
		<ZevioNav />
		<Container>
			<Row className="py-lg-5">
				<Col xs="12" lg="10" className="promotion_box mx-auto p-0">
					<div className='d-flex flex-row'>
						<div className="d-none d-lg-block">
							<svg width="560" height="650" className="login_svg" viewBox="0 0 560 650" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd" d="M522.881 760.203C458.979 795.401 387.578 813 308.625 813C228.948 813 157.159 795.401 93.2567 760.203C29.3548 725.005 -20.8409 676.368 -57.3045 614.293C-93.7682 552.218 -112 482.946 -112 406.5C-112 330.054 -93.7682 260.782 -57.3045 198.707C-20.8409 136.632 29.3548 87.9953 93.2567 52.7972C157.159 17.5991 228.948 0 308.625 0C387.578 0 458.979 17.5991 522.881 52.7972C586.783 87.9953 636.798 136.441 672.873 198.133C708.949 259.825 727 329.289 727 406.5C727 483.711 708.949 553.175 672.873 614.867C636.798 676.559 586.783 725.005 522.881 760.203ZM151.427 672.252C198.382 698.751 250.79 712 308.624 712C366.485 712 418.505 698.751 464.71 672.252C510.916 645.753 547.327 609.21 573.996 562.646C600.665 516.082 614 464.041 614 406.5C614 348.959 600.665 296.918 573.996 250.354C547.327 203.79 510.916 167.247 464.71 140.748C418.505 114.249 366.485 101 308.624 101C250.79 101 198.382 114.249 151.427 140.748C104.472 167.247 67.6726 203.79 41.0035 250.354C14.3345 296.918 1 348.959 1 406.5C1 464.041 14.3345 516.082 41.0035 562.646C67.6726 609.21 104.472 645.753 151.427 672.252Z" fill="white" />
								<ellipse cx="116" cy="395.5" rx="141" ry="141.5" fill="white" />
							</svg>
						</div>
						<div className="bg-white w-100 px-2 py-3 p-lg-5">
							<TraksConsumer>
								<Login />
							</TraksConsumer>
						</div>
					</div>
				</Col>
			</Row>
		</Container>
	</>);
}
export default LoginPage;
