import React, { useState, useEffect, useRef } from 'react';

const Input = ({ className, type, id, name, placeholder, autoComplete, onChange, autofocus=false, initValue='' }) => {
	const [renderedClassList, setRenderedClassList] = useState(className)
	const [value, setValue] = useState(initValue);
	const focusClass = "focus-input";
	const hasValueClass = "has-value";
	

	const blur = () => {
		const resettedClass = className
		const hasValue = value.length
		const removedFocusClass = renderedClassList.replace(`${focusClass} `, "")

		const newClassName = hasValue ? removedFocusClass : resettedClass
		setRenderedClassList(newClassName);
	}

	const focus = () => {
		inputElement.current.focus();
		const hasValue = /has-value/.test(renderedClassList) ? "" : hasValueClass + " "
		setRenderedClassList(`${hasValue}${focusClass} ${renderedClassList}`);
	}

	const targetValue = (e) => {
		setValue(e.target.value);
		onChange(e.target.value);
	}

	const inputElement = useRef(null);
	useEffect(() => {
		if (autofocus && inputElement.current) {
			inputElement.current.focus();
		}
	}, [autofocus]);

	return (
		<div className={`input-block ${className || ''}`}>
			<input type={type} ref={inputElement} id={id} name={name} autoComplete="off" value={value} required onChange={targetValue} className={renderedClassList} onFocus={focus} onBlur={blur} />
			<span className="placeholder">{placeholder}</span>
		</div>
	);
};

export const FeedbackInput = ({
  className="",
  type,
  id,
  name,
  placeholder,
  autoComplete,
  autofocus = false,
	invalid=false,
  errorMessage,
}) => {
  const [renderedClassList, setRenderedClassList] = useState(className);
  const blur = (e) => {
    setRenderedClassList(`${inputElement.current?.value ? "has-value ":""} ${className}`);
  };

  const focus = (e) => {
    setRenderedClassList(`focus-input has-value ${className}`);
  };
 
	const inputElement = useRef(null);
  useEffect(() => {
    if (inputElement.current && autofocus) {
      inputElement.current.focus();
    }
  }, [autofocus]);
	
  return (
    <div className={className} >
      <div className={`input-block`} >
        <input
          type={type}
          id={id}
          name={name}
					ref={inputElement}
					autoComplete={autoComplete}
          className={renderedClassList + " w-100"}
          onFocus={focus}
          onBlur={blur}
        />
        <span className="placeholder w-100">{placeholder}</span>
      </div>
      {invalid && (
        <div className="red_text">
          <i>{errorMessage}</i>
        </div>
      )}
    </div>
  );
};

export default Input;
