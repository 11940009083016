import React from "react";
import { T } from "../../traks";
import TransactionSummary from "./TransactionSummary";

const PurchaseComplete = () => {
	return (
		<div>
			<div>
				<h4>
					<span className="py-2">
						<img
							className="pe-2 pb-1"
							style={{
								filter:
									"invert(52%) sepia(88%) saturate(423%) hue-rotate(94deg) brightness(98%) contrast(77%)",
							}}
							src="https://static.zevio.com/gfx/ico_signUpComplete.svg"
							alt="icosuccess"
						/>
						<T>Purchase complete!</T>
					</span>
				</h4>
			</div>
			<TransactionSummary />
		</div>
	);
};

export default PurchaseComplete;
