import React, {useEffect} from "react";


export const ScrollAnimate = (props) => {

    useEffect(() => {
        
		const observer = new IntersectionObserver(entries => {
            // Loop over the entries
            entries.forEach(entry => {
                // If the element is visible
                if (entry.isIntersecting) {
					
					setTimeout(() => {
						entry.target.classList.add('scroll-animate')
					  }, 500)
                }
            });
        });
        
		const targets = document.querySelectorAll('.target-scroll-animate');

        for (const target of targets) {
            observer.observe(target);
        }
        
    
    }, []);
    return (
        <div className="target-scroll-animate">
            {props.children}
        </div>
    );
}
