import React, { useEffect, useState } from 'react';
import { APIGet } from "../API";
import { Container, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, Table, Modal } from "reactstrap";
import { T } from "../traks";
import ZevioNav from '../c/ZevioNav';
import Footer from '../c/Footer';
import { LL } from "../Routes";
import { useNavigate } from "react-router-dom";
import { EventDateTime } from '../c/ReusableElems';

const MyWebinars = () => {
	const [upcoming, setUpcoming] = useState([]);
	const [past, setPast] = useState([]);
	const [serial, setSerial] = useState(0);

	useEffect(() => {
		APIGet("users", { 'action': 'user_info' }).then((response) => {
			if (response.status === 200) {
				let ids = [];
				let events = {};
				for (const order of response.data.body.user_info.orders)
					ids = [...ids, order.eventId];
				APIGet("search", { 'ids': ids.join(',') }).then((searchres) => {
					setPast([]);
					setUpcoming([]);
					for (const e of searchres.data.body)
						events[e.Id] = e;
					const rightNow = parseInt(Date.now() / 1000);
					response.data.body.user_info.orders.map((o) => {
						o.eventData = events[o.eventId];
						if (o.startTimestamp > rightNow) {
							setUpcoming((u) => [...u, o]);
						} else {
							setPast((p) => [...p, o]);
						}
						return true;
					})
				})
			}
		})
	}, [serial]);

	const unregister = (orderId) => {
		APIGet("register", { "action": "cancelRegistration", "orderId": orderId }).then((response) => {
			if (response.status === 200) {
				setSerial((s) => s + 1);
			} else {
				console.log(['Error cancelRegistration', response]);
			}
		});
	}

	const ViewURL = ({ eventLink, onClick }) => {
		const [copyText, setCopyText] = useState("COPY");
		const urlPort = window.location.port;
		const liveURL = window.location.protocol + "//" + window.location.hostname + (urlPort ? ":" + urlPort : "") + LL("/live/") + eventLink;
		if (eventLink) {
			return (
				<div className="p-3">
					<div>
						<img onClick={onClick} className="btn" style={{ float: 'right' }} src="https://static.zevio.com/gfx/ico_close.svg" alt='icoClose' />
					</div>
					<div className="pt-4">
						<h5>Broadcast info</h5>
					</div>
					<div className="pt-3">
						<T>Live event watch URL:</T>
					</div>
					{eventLink ?
						<div className="pt-1">
							<div className="successbox opposites">
								<span className="break-word py-2 ps-2">{liveURL}</span>
								<span className="btn login_or_seperator" onClick={() => setCopyText("COPIED") + navigator.clipboard.writeText(liveURL)} >{copyText}</span>
							</div>
						</div>
						: <span className="pt-1 textCenter"><T>Not available</T></span>}
					<div className="py-3">
						<button onClick={onClick} className="btn_editevent zevio_input w-100"><T>Close</T></button>
					</div>
				</div >
			)
		} else {
			return <div className='text-center py-4'>No broadcast info available</div>;
		}
	}

	const UnregisterElement = ({ orderId, canUnRegister, eventLink }) => {

		const [modalBroadcast, setModalBroadcast] = useState(false);
		const toggleBroadcast = () => setModalBroadcast(!modalBroadcast);
		const [isOpen, setIsOpen] = useState(false);
		const toggle = () => {
			setIsOpen(!isOpen);
		}
		if (!canUnRegister) return null;
		else return (
			<Dropdown isOpen={isOpen} toggle={toggle}>
				<DropdownToggle onClick={toggle} className="mywebinars_toggledropdown p-0 p-lg-2">
					<img alt='ellipsis' src='https://static.zevio.com/gfx/ico_ellipsis.svg' />
				</DropdownToggle>
				<DropdownMenu>
					<DropdownItem>
						<div onClick={() => unregister(orderId)}><T>Cancel registration</T></div>
					</DropdownItem>
					<DropdownItem onClick={() => toggleBroadcast(true)}>
						<Modal toggle={toggleBroadcast} centered isOpen={modalBroadcast}>
							<ViewURL onClick={toggleBroadcast} eventLink={eventLink} />
						</Modal>
						<span><T>Broadcast info</T></span>
					</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		);
	}

	const EventElement = ({ order, canUnRegister }) => {
		const navigate = useNavigate();
		let targetUrl = "/event/" + (order.eventData.Id);
		if (order.eventData.startTimeStamp < parseInt(Date.now()/1000)) targetUrl = "/live/" + (order.eventData.UniqueHash)
		return (
			<tr className="align-middle">
				<td onClick={() => navigate(LL(targetUrl))} className="ps-3 py-2 cursor-pointer">
					<div className="mywebinars_smallimg">
						{order.eventData.ThumbImageURL ? <img className="broadcast_event_thumb" src={order.eventData.ThumbImageURL} alt='thumbimg' /> :
						order.eventData.ThumbnailImage ? <img className="broadcast_event_thumb" src={order.eventData.ThumbnailImage} alt='thumbimg' /> : <span className="broadcast_event_thumb" />}
						<span className="d-none d-lg-inline ps-2">{order.eventData.Title}</span>
					</div>
				</td>
				<td className="py-2 d-none d-lg-table-cell">{order.eventData.SubTitle}</td>
				<td className="py-2 d-none d-lg-table-cell">
					<EventDateTime eventStartTs={order.eventData.startTimeStamp} />
				</td>
				<td className="d-none d-lg-table-cell text-end py-1">
					<UnregisterElement orderId={order.orderId} canUnRegister={canUnRegister} eventLink={order.eventData.UniqueHash} />
				</td>
				<td className="d-lg-none pb-3">
					<div className="mywebinars_date d-flex flex-row justify-content-between">
						<span className="my-auto pt-3">
							<EventDateTime eventStartTs={order.eventData.startTimeStamp} />
						</span>
						<UnregisterElement orderId={order.orderId} canUnRegister={canUnRegister} eventLink={order.eventData.UniqueHash} />
					</div>
					<span className="d-flex flex-row justify-content-between">
						{order.eventData.Title.length > 26 ? `${order.eventData.Title.substring(0, 26)}...` : order.eventData.Title}
					</span>
					<div className="mywebinars_speakers">
						{order.eventData.SubTitle}
					</div>
				</td>
			</tr>
		);
	}

	const UpcomingElement = () => {
		if (upcoming.length === 0)
			return null;
		return (
			<Table className="mywebinars">
				<tbody>
					<tr className="font_weight_500">
						<td className="ps-3 py-3 d-none d-lg-block"><T>Upcoming events</T></td>
						<td className="ps-2 py-1 d-lg-none font_15px font_weight_500"><T>Coming</T></td>
						<td className="py-3 d-none d-lg-table-cell"><T>Speaker</T></td>
						<td className="py-3 d-none d-lg-table-cell"><T>Date</T></td>
						<td />
					</tr>
					{upcoming.map((o, i) => <EventElement canUnRegister={!o?.eventData?.AccessProductId} key={i + "-" + o['orderId']} order={o} />)}
				</tbody>
			</Table>
		);
	}

	const PastElement = () => {
		if (past.length === 0)
			return null;
		return (
			<Table className="mywebinars">
				<tbody>
					<tr className="font_weight_500">
						<td className="ps-3 py-3 d-none d-lg-block"><T>Past events</T></td>
						<td className="ps-2 py-1 d-lg-none font_15px font_weight_500"><T>Past</T></td>
						<td className="py-3 d-none d-lg-table-cell"><T>Speaker</T></td>
						<td className="py-3 d-none d-lg-table-cell"><T>Date</T></td>
						<td />
					</tr>
					{past.map((o, i) => <EventElement canUnRegister={false} key={i + "-" + o['orderId']} order={o} />)}
				</tbody>
			</Table>
		);
	}

	const NoWebinars = () => {
		if (upcoming.length === 0 && past.length === 0) {
			return (
				<div><i><T>You have not watched any events</T></i></div>
			);
		} else {
			return null;
		}
	}

	return (
		<>
			<ZevioNav />
			<Container className="pb-4">
				<div className="py-4 font_20px font_weight_600"><T>My webinars</T></div>
				<Container style={{ overflow: "visible" }} fluid className="bg_white promotion_box p-0">
					<UpcomingElement />
				</Container>
				<Container style={{ overflow: "visible" }} fluid className="bg_white promotion_box p-0">
					<PastElement />
				</Container>
				<Container fluid className="bg_white promotion_box p-0">
					<NoWebinars />
				</Container>
			</Container>
			<Footer />
		</>
	);
}

export default MyWebinars;
