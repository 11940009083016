import React, { useContext } from "react";
import I18N from "../../I18N";
import { url_lang } from "../../languages";
import { T } from "../../traks";
import OrderContext from "../Amopus/useOrder";

const ShowProduct = ({onClick}) => {
	const { productData} =
		useContext(OrderContext);
	return (
		<div className="d-md-flex flex-row gap-2">
			<div className="col-md-6">
				<img
					className="rounded overflow-hidden w-100"
					src={productData.imageURL}
					alt="prodImg"
					style={{ objectFit: "contain" }}
				/>
			</div>
			<div className="col-md-6 d-flex flex-column">
				<div className="">
					<div>
						<span className="card_product_subtitle">
							{productData.ownerName}
						</span>
					</div>
					<div>
						<span className="card_product_header">{productData.headline}</span>
					</div>
					<div className="py-2 row-cols-auto">
						<span className="card_product_price ">
							{I18N.floatval_unit(
								productData.price[url_lang().currency],
								2,
								url_lang().currency.toUpperCase(),
								"card_product_currency"
							)}
						</span>
					</div>
					<div
						className="custom_scrollbar_section"
						id="this-one-perhaps"
						style={{ maxHeight: "176px" }}
					>
						<div
							id="product_desc"
							dangerouslySetInnerHTML={{ __html: productData.description }}
							className="card_product_subtitle pe-3"
						/>
					</div>
				</div>
				<div className="mt-auto w-75 align-self-center">
					<button
						onClick={onClick}
						className="font_16px btn btn_green w-100 font_weight_700 mt-4"
					>
						<T>Buy</T>
					</button>
				</div>
			</div>
		</div>
	);
};

export default ShowProduct;
