import React, { useRef } from "react";
import { Container, Row, Col } from "reactstrap";
import { GreenButton } from "./ReusableElems";
import GetAppModal from "./GetAppModal";
import { LL } from "../Routes";
import { T } from "../traks";

const Footer = (props) => {
	const childRef = useRef();
	const thisYear = new Date().getFullYear();

	return (
		<Container fluid className="zevio_footer min_w100 min_h100">
			<Container>
				<Row className="pt-3 pt-lg-5 pb-4 justify-content-between">
					<Col xs="12" lg="auto" className="d-lg-none text-center pt-4 pt-lg-0">
						<div className="text-white font_24px"><span className="font_weight_600"><T>Get started now</T></span></div>
						<GreenButton onClick={() => childRef.current.handleToggle()}>
							<GetAppModal ref={childRef} centered />
							<T>Get the app</T>
						</GreenButton>
						<div className="font_15px grey_frontpage pt-3">
							<a href={LL("/signup")}><T>Create account</T></a>
						</div>
					</Col>
					<Col xs="12" lg="auto" className="text-center text-lg-start pt-3 pt-lg-0">
						<div className="text-white font_24px"><span className="font_weight_600"><T>Useful links</T></span></div>
						<div className="font_15px grey_frontpage">
							<a href={LL("/privacy")} className="grey_frontpage"><T>Privacy policy</T></a>
						</div>
						<div className="font_15px grey_frontpage">
							<a href={LL("/terms")} className="grey_frontpage"><T>Terms of use</T></a>
						</div>
						<div className="font_15px grey_frontpage">
							<a href={LL("/termsoftrade")} className="grey_frontpage"><T>Trade conditions</T></a>
						</div>
					</Col>
					<Col xs="12" lg="auto" className="d-none d-lg-block text-center">
						<div className="text-white font_24px"><span className="font_weight_600"><T>Get started now</T></span></div>
						<div className="grey_frontpage pb-3">
							<T>Zevio is always free</T>
						</div>
						<div className="font_15px grey_frontpage pt-3">
							<a className="link_nostyle w-100" href={
								LL("/signup/")
							}>
								<T>Signup</T>
							</a>
						</div>
					</Col>
					<Col xs="12" lg="auto" className="text-center text-lg-end pt-4 pt-lg-0">
					</Col>
				</Row>
				<div className="text-center grey_frontpage pt-3 pb-1">
					<T>Copyright &copy;</T>&nbsp;{thisYear}&nbsp;<T>ZEVIO ApS - All rights reserved</T>
				</div>
			</Container>
		</Container>
	);
}

export default Footer;
