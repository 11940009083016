import React, { useEffect, useState, useRef } from 'react';
import { Button, Col, Container, Row, Collapse, Alert } from "reactstrap";
import { EventCard } from "./Cards";
import { T } from '../traks';
import { LL } from '../Routes';
import GetAppModal from "./GetAppModal";
import { url_lang } from "../languages";
import { APIGet } from '../API.js';
import BigText from '../c/BigText';
import I18N from '../I18N';

export const smallCoverImageURL = (URL) => {
	if(URL === "") return "";
	const re = new RegExp("^(https://.*/)(test_|)(organizer|event|product)images/(2x|)cover-(.*)$");
	const matches = URL.match(re);

	if(matches)
		return matches[1] + matches[2] + matches[3] + 'images/2xsmallcover-' + matches[5];
	else
		return URL;
}

export const smallThumbnailImageURL = (URL) => {
	if(URL === "") return "";
	const re = new RegExp("^(https://.*/)(test_|)(organizer|event|product)images/(2x|)thumb-(.*)$");
	const matches = URL.match(re);

	if(matches)
		return matches[1] + matches[2] + matches[3] + 'images/2xsmallthumb-' + matches[5];
	else
		return URL;
}

export const createHash = (length = 6) => {
	const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let result = '';
	const charactersLength = characters.length;
	for ( let i = 0; i < length; i++ ) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}

	return result;
}

export const isValidEmail = (email) => {
	return /\S+@\S+\.\S+/.test(email);
}

export const sendToDataLayer = (obj2send) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push(obj2send);
}



export const EventDateTime = ({eventStartTs}) => {
	const nowTs = Math.floor(new Date().getTime() / 1000);

	if (nowTs > eventStartTs)
		return I18N.date(new Date(eventStartTs * 1000));

	return (<>
			<span className='d-none d-lg-inline'>{I18N.datetime_short(new Date(eventStartTs * 1000))}</span>
			<span className='d-lg-none'>{I18N.datetime_shorter(new Date(eventStartTs * 1000))}</span>
	</>);
}

export const NotOrganizer = () => {
	return (
		<Container className="bg_white promotion_box p-5 my-5">
			<div>
				<h5><T>You need to <a href={LL("/login")}>Login</a> to create an organiser profile</T></h5>
			</div>
			<div>
				<T>
				Why would I want to create an organiser profile? Because it's a damn
				good idea to do! It'll make you rich, famous and happy. Not to mention all
				the bad and unfortunate things that will happen to you, if you don't.
				</T>
			</div>
			<div className="pt-5">
				<T>
				You can check out why it's a great idea to make an organiser
				page <a href={LL("/organizers")}>here</a>.
				</T>
			</div>
		</Container>
	);
}

export const AlertReusable = ({ activateAlert, text, color, delay }) => {

	const [isOpen, setIsOpen] = useState(false);
	const onDismiss = () => setIsOpen(false);

	useEffect(() => {
		if ( activateAlert !== 0) {
			setIsOpen(true);
		}
		if (delay !== undefined) {
			setTimeout(() => setIsOpen(false), delay);
		}
	}, [activateAlert, delay]);

	return (
		<div>
			<Alert
				color={color}
				isOpen={isOpen}
				toggle={onDismiss}
				className="text-center"
			>
				{text}
			</Alert >
		</div>
	);
};

export const HorizontalSmoothScroller = (props) => {
	let widthToShowButtons = 767;
	if(props.widthToShowButtons)
		widthToShowButtons = parseInt(props.widthToShowButtons);
	const showButtons = props.showButtons ? true : false;
	const [isMobile, setIsMobile] = useState(window.innerWidth > widthToShowButtons);
	const [scrollStateRight, setscrollStateRight] = useState(true);
	const [scrollStateLeft, setscrollStateLeft] = useState(false);
	const sliderRef = useRef(null);
	const slideAmount = showButtons ? 200 : 1200;

	useEffect(() => {
		window.addEventListener("resize", updateViewport);
		return () => window.removeEventListener("resize", updateViewport);
	});

	const updateViewport = () => setIsMobile(window.innerWidth > widthToShowButtons);
	const slideRight = () => sliderRef.current.scrollLeft = sliderRef.current.scrollLeft + Math.min(Math.max(parseInt((window.innerWidth / 2.2)), 1), slideAmount);
	const slideLeft = () => sliderRef.current.scrollLeft = sliderRef.current.scrollLeft - Math.min(Math.max(parseInt((window.innerWidth / 2.2)), 1), slideAmount);
	const [pressed, setPressed] = useState(false);

	const onMouseMove = (event) => {
		if (pressed) {
			if (event.movementX < 0) {
				setPressed(false);
				slideRight();
			}
			if (event.movementX > 0) {
				slideLeft();
				setPressed(false);
			}
		}
	}
	const handleScroll = (e) => {
		setscrollStateRight(true)
		setscrollStateLeft(true)
		if (e.target.scrollWidth - e.target.scrollLeft === e.target.clientWidth) {
			setscrollStateRight(false)
		}
		if (e.target.scrollLeft === 0) {
			setscrollStateLeft(false)
		}
	}
	const Buttons = () => {
		const LButton = () => {
			return ( scrollStateLeft ?
				<ul className="pagination justify-content-end">
					<span onClick={() => slideLeft()} className="btn carousel-control-prev-icon promotion_box"></span>
				</ul> : null
			);
		}
		const RButton = () => {
			return ( scrollStateRight ?
				<ul className="pagination justify-content-end ps-3">
					<span onClick={() => slideRight()} className="btn carousel-control-next-icon promotion_box"></span>
				</ul> : <span className="px-4"></span>
			);
		}
		return (
			<span className="pagination justify-content-end">
				<LButton /><RButton />
			</span>)
	}
	let css_blur_end = "";
	if (props.children.length > 4)
		css_blur_end = "blur_end_scrollables_white";

	return (
		<div>
			<div
				ref={sliderRef}
				onScroll={handleScroll}
				className={"scrolling-wrapper-flexbox scroll-smooth " + css_blur_end}
				onMouseMove={onMouseMove}
				onMouseDown={() => setPressed(true)}
				onMouseUp={() => setPressed(false)}
			>
				{props.children}
			</div>
			{(isMobile && props.children.length > 4 )|| showButtons ? <Buttons /> : null}
		</div>
	);
}

export const ImageWithSrcSet = ({ URL, imgstr, img2xstr, alttxt = "", className = "", width = "", height = "" }) => {
	let src = URL;
	if(URL.includes(img2xstr + '-')) {
		src = URL.replace(img2xstr + '-', imgstr + '-');
		const srcSet = URL + " 2x";
		return <img width={width} height={height} className={className} src={src} srcSet={srcSet} alt={alttxt} />;
	}

	return <img width={width} height={height} className={className} src={src} alt={alttxt} />;
}

export const CustomScrollBar = (props) => {
	const { contentWidth, wrapperWidth, wrapperHeight, children } = props;
	return (
		<div
			className="custom_scrollbar_section"
			style={{
				width: wrapperWidth || "300px",
				height: wrapperHeight || "300px",
				scrollX: contentWidth ? "scroll" : "hidden"
			}}
		>
			<div style={{ width: contentWidth || "auto" }}>{children}</div>
		</div>
	);
}

export const CollapsibleTroubleshoot = () => {
	const [showMore, setShowMore] = useState(false);
	const toggle = () => {
		setShowMore(!showMore);
	}
	return (
		<div className="pb-3">
			<div onClick={toggle} className="collapsible p-4">
				<h6>
					<div className="pb-3"> <T>Having trouble viewing this event?</T> </div><div className="font_weight_700"> <T>Troubleshoot</T> <img className="align_right pt-2" src="https://static.zevio.com/gfx/ico_expandable.svg" alt='icoexp' /> </div>
				</h6>
				<Collapse className="grey_light pt-2" isOpen={showMore}>
					<BigText k="troubleshoot_watch"/>
				</Collapse>
			</div>
		</div>
	);
}

export const EventGuideCollapsibles = () => {
	const [showMore, setShowMore] = useState(false);
	const toggle = () => {
		setShowMore(!showMore);
	}
	const [showMore2, setShowMore2] = useState(false);
	const toggle2 = () => {
		setShowMore2(!showMore2);
	}
	return (
		<div>
			<div className="bg_white promotion_box mt-3 p-3">
				<Collapse onClick={toggle} isOpen={true}>
					<span><T>Get help with the practicalities</T><img className="pt-2 btn p-0 align_right" src="https://static.zevio.com/gfx/ico_expandable.svg" alt='icoexp' /></span>
					<Collapse className="pt-3 grey_light" isOpen={showMore}>
						<T>Write us at hello@zevio.com if you are interested. Please include what you want to talk about, and a link to your homepage / company.</T>
					</Collapse>
				</Collapse>
			</div>
			<div className="bg_white promotion_box mt-3 mb-5 p-3">
				<Collapse onClick={toggle2} isOpen={true}>
					<span><T>Broadcasting guides</T><img className="pt-2 btn p-0 align_right" src="https://static.zevio.com/gfx/ico_expandable.svg" alt='icoexp' /></span>
					<Collapse className="pt-3 grey_light" isOpen={showMore2}>
						<T>No illegal material, violence or rygestop etc.</T>
					</Collapse>
				</Collapse>
			</div>
		</div>
	);
}

export const BannerDownloadApp = () => {
	const childRef = useRef();
	return (
		<Container className="py-5 d-none d-lg-block">
			<Row>
				<Col xs={12} className="px-lg-0">
					<div className="w-100 align-content-center banner_getapp d-flex flex-row justify-content-between">
						<div className="ps-3">
							<img alt="2phones" width="33" height="50" src="https://static.zevio.com/imgs/2phones-75x100px-0.webp" />
						</div>
						<div className="text-center">
							<h5 className="pt-2"><T>Download our app and watch directly on your smartphone</T></h5>
						</div>
						<div className="pe-3">
							<button onClick={() => childRef.current.handleToggle()} className="btn btn_white_dark_border px-4 mt-1"><GetAppModal ref={childRef} centered /><T>Get the app</T></button>
						</div>
					</div>
				</Col>
			</Row>
		</Container>
	);
}

export const CheckMark = (props) => {
	let svg_fill = "#29b869";
	if(props.svg_fill) svg_fill = props.svg_fill;

	return (
		<svg width="24px" height="24px" fill={svg_fill} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
			<path d="M 19.581 4.511 C 20.709 3.529 22.577 4.032 22.944 5.416 C 23.102 6.015 22.924 6.647 22.468 7.101 L 11.7 19.408 C 10.919 20.177 9.597 20.2 8.785 19.457 L 1.645 12.931 C 0.505 11.959 0.942 10.225 2.432 9.809 C 3.172 9.601 3.977 9.799 4.505 10.317 L 10.156 15.479 L 19.529 4.565 C 19.546 4.545 19.564 4.528 19.583 4.511 Z"/>
		</svg>
	);
}

export const SVGStars = (props) => {
	let svg_fill = "#29b869";
	let count_stars = 5;
	if(props.svg_fill) svg_fill = props.svg_fill;
	if(props.count_stars) count_stars = props.count_stars;

	const star_elem = (
		<span key="first_star">
			<svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
				<path fill={svg_fill} d="M 25.932 9.946 C 25.761 9.418 25.294 9.044 24.744 8.994 L 17.238 8.31 L 14.272 1.342 C 14.053 0.83 13.555 0.5 13 0.5 C 12.445 0.5 11.947 0.83 11.729 1.342 L 8.763 8.31 L 1.256 8.994 C 0.706 9.045 0.24 9.419 0.068 9.946 C -0.103 10.474 0.055 11.054 0.471 11.42 L 6.145 16.412 L 4.472 23.804 C 4.349 24.348 4.56 24.91 5.009 25.236 C 5.251 25.412 5.535 25.5 5.82 25.5 C 6.064 25.5 6.309 25.435 6.528 25.303 L 13 21.421 L 19.471 25.303 C 19.945 25.588 20.542 25.562 20.991 25.236 C 21.44 24.91 21.651 24.348 21.528 23.804 L 19.855 16.412 L 25.529 11.42 C 25.945 11.054 26.103 10.476 25.932 9.946 Z"/>
			</svg>
		</span>
	);

	let stars = [star_elem];

	if(count_stars > 1) {
		stars = [<span key="unique_key" style={{paddingRight: "5px"}}>{star_elem}</span>];
		for(let i = 0; i < count_stars - 2; i++) {
			stars.push(<span key={i} style={{paddingRight: "5px"}}>{star_elem}</span>);
		}
		stars.push(star_elem);
	}

	return stars;
}

export const RedButton = (props) => {
	let {
		className,
		...otherProps
	} = props;
	let classNames = ['btn_red px-4 py-2 border-0', className].join(' ');

	const btnElem = <Button color="false" className={classNames} {...otherProps}>{props.children}</Button>;

	return btnElem;
}

export const GreenButton = (props) => {
	let {
		className,
		...otherProps
	} = props;
	let classNames = ['btn_green px-4 py-2', className].join(' ');

	const btnElem = <Button color="false" className={classNames} {...otherProps}>{props.children}</Button>;

	return btnElem;
}

export const ImgSrcSet = ({src1x, src2x, alt, className}) => {
	return (
		<img alt={alt} src={src1x} srcSet={src2x + " 2x"} className={className} width="100%" height="100%" />
	);
}

export const FrontPageHero = ({heroEvent}) => {
	return (
		<Container fluid className="frontpage_hero background_dark">
			<Container>
				<Row className="align-items-center">
					<Col className="d-flex flex-column justify-content-center" xs="12" lg="6">
						<h1 className="pt-3"><T>Live&nbsp;talks</T></h1>
						<div className="font_20px">
							<T>Athletes, scientists, authors, politicians and more.<br />Get experts at your fingertips.</T>
						</div>
						<div className="grey_frontpage font_13px">
							<div className="pb-3"><T>Zevio is free to download and use.</T>
								<span className="d-none d-md-block"><a className='link_nostyle' href={LL("/signup")}><T>Or get started right now on the desktop</T></a></span>
							</div>
						</div>
					</Col>
					<Col xs="12" lg="6" className='text-center'>
						<div className='event_card'>
							{heroEvent && <EventCard eventData={heroEvent} />}
						</div>
					</Col>
				</Row>
			</Container>
		</Container>
	);
}

export const FrontPageRandomEvents = ({ randomEvents }) => {
	if (randomEvents && randomEvents.length > 16) {
		randomEvents = randomEvents.slice(0, 16);
	}

	return (
			<Container className="px-2 px-lg-0 pb-4">
				<Row className="text-center">
					<Col xs="12" className="pt-3">
						<h2 className="d-none d-lg-block pt-4 pb-2"><T>Unlimited access. Unlimited inspiration - for free.</T></h2>
						<h2 className="d-lg-none pt-4 pb-2"><T>Unlimited access.<br />Unlimited inspiration.</T></h2>
					</Col>
				</Row>
				<div className="pt-3">
					{randomEvents.map((v, i) => (
						<EventCard
							eventData={v} key={"explore" + i}
						/>
					))}
				</div>
			</Container>
	);
}

export const OrganizerImage = (props) => {
	return (
		<div className="position-relative mx-auto border_radius_10px overflow-hidden">
			<img className="w-100 h-100" src={props.src} srcSet={props.srcSet} alt={props.speaker_name} />
			<div className="img_overlay position-absolute top-0 start-0" />
			<div className="position-absolute bottom-0 start-50 translate-middle-x">
				<h3 className="text-white pb-3">{props.speaker_name}</h3>
			</div>
		</div>
	);
}

export const FrontPageEventsPromotion = () => {
	return (
		<Container fluid className="background_dark">
			<Container>
				<Row className="pt-4 pt-lg-5">
					<Col xs="12" className="text-center">
						<div className="text-white organizer_landing_headline"><T>For the truly curious.</T></div>
						<div className="organizer_landing_promo_text grey_frontpage pt-3">
							<T>Go beyond the traditional media one-liners</T>
						</div>
					</Col>
				</Row>
				<Row className="py-5">
					<Col className="text-center pt-lg-0 px-4" lg="4">
						<SVGStars />
						<div className="font_15px text-white py-3">
							<T>Thank You again! This talk was really interesting and rarely has 30 minutes
							felt so short. As mentioned during the broadcast, this should have lasted much longer!</T>
						</div>
						<div className="font_13px grey_frontpage">
							&mdash;Charlotte O.
						</div>
					</Col>
					<Col className="text-center pt-4 pt-lg-0 px-4" lg="4">
						<SVGStars />
						<div className="font_15px text-white py-3">
							<T>Thank You for a great talk. It was really relevant for me in my education as a metacognitive
							therapist. It is always a pleasure to listen to people with real experience and in their own words.</T>
						</div>
						<div className="font_13px grey_frontpage">
							&mdash;Anne-Mette P.
						</div>
					</Col>
					<Col className="text-center pt-4 pt-lg-0 px-4" lg="4">
						<SVGStars />
						<div className="font_15px text-white py-3">
							<T>This was simply great. I’m very excited. Many thanks, and thanks for hosting this free talk!</T>
						</div>
						<div className="font_13px grey_frontpage">
							&mdash;<T>Anonymous</T>
						</div>
					</Col>
				</Row>
			</Container>
		</Container>
	);
}

export const FrontPageCoreServicesPromotion = () => {
	return (
		<Container className="py-3">
			<Row xs="1" lg="3" className="py-3 py-lg-5 justify-content-center">
				<Col xs="auto" className="core_service_entry text-center pb-4 px-2 px-lg-5">
					<img alt="core service icon" width="72" height="62" src="https://static.zevio.com/gfx/core-promotion-icon0-0.svg" />
					<div className="font_20px py-3">
						<T>Interesting topics</T>
					</div>
					<div className="font_15px">
						<T>No one-liners - no skipping the surface of the topic. Enjoy 30 minutes of unscripted live
						sessions with the most knowledgeable among us. No payment required.</T>
					</div>
				</Col>
				<Col xs="auto" className="core_service_entry text-center pb-4 px-2 px-lg-5">
					<img alt="core service icon" width="72" height="62" src="https://static.zevio.com/gfx/core-promotion-icon1-0.svg" />
					<div className="font_20px py-3">
						<T>Organise your own webinar</T>
					</div>
					<div className="font_15px">
						<T>Enjoy live talks in English, French, German, Danish, Swedish, and Norwegian. Full HD. And watch
						from anywhere this side of the moon.</T>
					</div>
				</Col>
				<Col xs="auto" className="core_service_entry text-center pb-4 px-2 px-lg-5">
					<img alt="core service icon" width="72" height="62" src="https://static.zevio.com/gfx/core-promotion-icon2-0.svg" />
					<div className="font_20px py-3">
						<T>Built-in webshop</T>
					</div>
					<div className="font_15px">
						<T>Get special discounts and offers from speakers and organisers. Get the book, join the trip, secure
						your tickets &mdash; or whatever the case may be. But this time, you’ve met the person you’re buying from.</T>
					</div>
				</Col>
			</Row>
		</Container>
	);
}
