import React, { useEffect, useState } from 'react';

const DotLoader = ({ children, className = '', interval=600 }) => {
	const [dots, setDots] = useState('.');

	useEffect(() => {
		const timer = setTimeout(() => {
			setDots((d) => {
				return d.length === 5 ? '.' : d + ' .';
			});
		}, interval);

		return () => clearTimeout(timer);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dots]);

	return (
		<div className={className}>
			{children}&nbsp;{dots}
		</div>
	);
};

export default DotLoader;
