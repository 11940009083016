
/* returns the first argument that's not `undefined`. or returns `undefined`,
 * if all arguments are `undefined` */
function coalesce() {
	for (var i = 0; i < arguments.length; i++) {
		var arg = arguments[i];
		if (arg !== undefined) return arg;
	}
	return undefined;
}

function parse_date(dstr) {
	if (!dstr) return null;
	const xs = dstr.split("-");
	if (xs.length !== 3) return null;
	const yyyy = parseInt(xs[0], 10);
	const mm1 = parseInt(xs[1], 10);
	const dd = parseInt(xs[2], 10);
	if (yyyy === 0 && mm1 === 0 && dd === 0) return null;
	return new Date(yyyy, mm1-1, dd);
}

function check_date(dval) {
	if (dval instanceof Date) return dval;
	return parse_date(dval);
}

/* if n=3 and xs=[1,2,3,4,5,6,7,8], this will return [[1,2,3],[4,5,6],[7,8]] */
function segmentize(n, xs) {
	var segments = [];
	var segment = [];
	for (const x of xs) {
		segment.push(x);
		if (xs.length >= n) {
			segments.push(segment);
			segment = [];
		}
	}
	if (segment.length > 0) segments.push(segment);
	return segments;
}

function array2set(xs) {
	var set = {};
	for (var x of (xs||[])) set[x] = true;
	return set;
}

export {
	coalesce,
	parse_date,
	check_date,
	segmentize,
	array2set,
};
