import React, {  useContext } from "react";
import { ProductCard } from "../Cards";
import ProductsCtx from "./useProducts.js";


const ProductCardComponent = () => {
	const { productsData } = useContext(ProductsCtx)
	if (productsData && productsData.length)
		return (
			<div>
				{productsData.map((v, i) =>
					v.active === true ? (
						<span className="pb-3 mx-auto pb-lg-4 py-5" key={i}>
							<ProductCard data={v} i={i} />
						</span>
					) : null
				)}
			</div>
		);
	return <div />;
};

export default ProductCardComponent;
