import React, { useContext,  useEffect} from "react";
import { MeContext } from "../../MeContext";
import { T, TraksConsumer } from "../../traks";
import { FeedbackInput } from "../Input";
import { isValidEmail } from "../ReusableElems";

const AddressForm = ({ address, showInvalid, formRef }) => (
	<TraksConsumer>
		<AddressFormConsumer {...{address, showInvalid, formRef}} />
	</TraksConsumer>
)

const AddressFormConsumer = ({ render_static,  address, showInvalid, formRef }) => {
	const { me } = useContext(MeContext);
	// Fill in form with data from MeContext if it exists
	useEffect(()=>{
		if (formRef?.current && me) {
			Object.keys(formRef.current).forEach( k => {
				let id = formRef.current[k].id
				if (me[id]) formRef.current[k].value = me[id] 
				else if (id==="zip") {
					formRef.current[k].value	= me.zipCode
				} else if (id === "homephonenumber") {
					formRef.current[k].value	= me.phoneNo
				} else if (id ==="firstname") {
					formRef.current[k].value	= me.firstName
				} else if (id ==="lastname") {
					formRef.current[k].value	= me.lastName
				}
			})
		}
	},[formRef, me])

	return (
		<div>
			<div className="pt-3 row m-0 gap-2">
				<FeedbackInput
					type="text"
					name="firstName" 
					autoComplete="given-name"
					id="firstname"
					className="col-xl p-0"
					placeholder={render_static(<T>First Name</T>) + "*"}
					invalid={showInvalid && !address.firstname}
					errorMessage={render_static(<T>Please insert name</T>)}
				/>
				<FeedbackInput
					type="text"
					name="lastName"
					autoComplete="family-name"
					id="lastname"
					className="col-xl p-0"
					placeholder={render_static(<T>Last Name</T>) + "*"}
					errorMessage={render_static(<T>Please insert name</T>)}
					invalid={showInvalid && !address.lastname}
				/>
			</div>
			<FeedbackInput
				name="email"
				type="email"
				id="email"
				className="pt-3 w-100"
				placeholder={render_static(<T>E-mail*</T>)}
				errorMessage={render_static(<T>Invalid email address</T>)}
				invalid={showInvalid && !isValidEmail(address.email)}
			/>
			<FeedbackInput
				className="pt-3 w-100"
				name="street-address"
				id="street"
				type="string"
				placeholder={render_static(<T>Street</T>) + "*"}
				errorMessage={render_static(<T>Please insert address</T>)}
				invalid={showInvalid && !address.street}
			/>
			<div className="pt-3 row w-100 m-0 gap-2">
				<FeedbackInput
					className="col p-0"
					name="postal-code"
					id="zip"
					type="number"
					placeholder={render_static(<T>Zip/Postal Code</T>) + "*"}
					errorMessage={render_static(<T>Invalid Zip/Postal Code</T>)}
					invalid={showInvalid && !address.zip}
				/>
				<FeedbackInput
					className="col-8 p-0"
					name="city"
					type="text"
					id="city"
					placeholder={render_static(<T>City</T>) + "*"}
					errorMessage={render_static(<T>Please insert city</T>)}
					invalid={showInvalid && !address.city}
				/>
			</div>
			<FeedbackInput
				className="pt-3 w-100"
				name="country-name"
				id="country"
				type="string"
				placeholder={render_static(<T>Country</T>) + "*"}
				errorMessage={render_static(<T>Please insert address</T>)}
				invalid={showInvalid && !address.country}
			/>
			<FeedbackInput
				className="pt-3 col-md"
				type="number"
				id="homephonenumber"
				name="tel"
				autoComplete="tel"
				placeholder={render_static(<T>Phone no.</T>)}
				errorMessage={render_static(<T>Please insert a phone number</T>)}
				invalid={showInvalid && !address.homephonenumber}
			/>
		</div>
	);
};

export default AddressForm