
 import React from "react";
/* eslint-disable import/no-anonymous-default-export */
const O = React.Fragment;

const months = {
	en: [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December"
	],
	da: [
		"januar",
		"februar",
		"marts",
		"april",
		"maj",
		"juni",
		"juli",
		"august",
		"september",
		"oktober",
		"november",
		"december"
	],
	sv: [
		"januari",
		"februari",
		"mars",
		"april",
		"maj",
		"juni",
		"juli",
		"augusti",
		"september",
		"oktober",
		"november",
		"december"
	],
	de: [
		"Januar",
		"Februar",
		"März",
		"April",
		"Mai",
		"Juni",
		"Juli",
		"August",
		"September",
		"Oktober",
		"November",
		"Dezember",
	],
	no: [
		"januar",
		"februar",
		"mars",
		"april",
		"mai",
		"juni",
		"juli",
		"august",
		"september",
		"oktober",
		"november",
		"desember",
	],
	fr: [
		"janvier",
		"février",
		"mars",
		"avril",
		"mai",
		"juin",
		"juillet",
		"août",
		"septembre",
		"octobre",
		"novembre",
		"décembre",
	],
	nl: [
		"januari",
		"februari",
		"maart",
		"april",
		"mei",
		"juni",
		"juli",
		"augustus",
		"september",
		"oktober",
		"november",
		"december",
	],

}

const months_short = {
	en: [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec"
	],
	da: [
		"jan",
		"feb",
		"mar",
		"apr",
		"maj",
		"jun",
		"jul",
		"aug",
		"sep",
		"okt",
		"nov",
		"dec"
	],
	sv: [
		"jan",
		"feb",
		"mar",
		"apr",
		"maj",
		"jun",
		"jul",
		"aug",
		"sep",
		"okt",
		"nov",
		"dec"
	],
	de: [
		"Jan",
		"Feb",
		"Mär",
		"Apr",
		"Mai",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Okt",
		"Nov",
		"Dez",
	],
	no: [
		"jan",
		"feb",
		"mar",
		"apr",
		"mai",
		"jun",
		"jul",
		"aug",
		"sep",
		"okt",
		"nov",
		"des"
	],
	fr: [
		"jan.",
		"févr.",
		"mars",
		"avril",
		"mai",
		"juin",
		"juil.",
		"août",
		"sept.",
		"oct.",
		"nov.",
		"déc.",
	],
	nl: [
		"jan.",
		"feb.",
		"maart",
		"apr.",
		"mei",
		"juni",
		"juli",
		"aug.",
		"sept.",
		"okt.",
		"nov.",
		"dec.",
	],
}

/* We might need them in the future
const weekday = {
	en: [
		"Sunday",
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
		"Saturday",
	],
	da: [
		"søndag",
		"mandag",
		"tirsdag",
		"onsdag",
		"torsdag",
		"fredag",
		"lørdag",
	],
	sv: [
		"söndag",
		"måndag",
		"tisdag",
		"onsdag",
		"torsdag",
		"fredag",
		"lördag",
	],
	de: [
		"Sonntag",
		"Montag",
		"Dienstag",
		"Mittwoch",
		"Donnerstag",
		"Freitag",
		"Samstag",
	],
	no: [
		"søndag",
		"mandag",
		"tirsdag",
		"onsdag",
		"torsdag",
		"fredag",
		"lørdag",
	],
	fr: [
		"dimanche",
		"lundi",
		"mardi",
		"mercredi",
		"jeudi",
		"vendredi",
		"samedi",
	],
	nl: [
		"maandag",
		"dinsdag",
		"woensdag",
		"donderdag",
		"vrijdag",
		"zaterdag",
		"zondag",
	],
}
*/

const weekday_short = {
	en: [
		"Sun",
		"Mon",
		"Tue",
		"Wed",
		"Thu",
		"Fri",
		"Sat",
	],
	da: [
		"søn",
		"man",
		"tir",
		"ons",
		"tor",
		"fre",
		"lør",
	],
	sv: [
		"sön",
		"mån",
		"tis",
		"ons",
		"tor",
		"fre",
		"lör",
	],
	de: [
		"So.",
		"Mo.",
		"Di.",
		"Mi.",
		"Do.",
		"Fr.",
		"Sa.",
	],
	no: [
		"søn",
		"man",
		"tir",
		"ons",
		"tor",
		"fre",
		"lør",
	],
	fr: [
		"lun",
		"mar",
		"mer",
		"jeu",
		"ven",
		"sam",
		"dim",
	],
	nl: [
		"ma.",
		"di.",
		"wo.",
		"do.",
		"vr.",
		"za.",
		"zo.",
	],
}

const ts2date = (ts) => new Date(ts * 1000);

function npad(value, n) {
	value = ""+value;
	while (value.length < n) value = "0" + value;
	return value;
}

function format_number(floatval, n_decimals, thousand_separator, decimal_separator) {
	var intval = Math.round(floatval * Math.pow(10, n_decimals));

	var is_positive = true;
	if (intval < 0) {
		is_positive = false;
		intval = -intval;
	}

	intval = intval.toString();
	while (intval.length <= n_decimals) intval = "0" + intval;
	const fraction = intval.substring(intval.length - n_decimals);
	var rest = intval.substring(0, intval.length - n_decimals);
	var head = "";
	while (rest.length > 3) {
		head = thousand_separator + rest.substring(rest.length - 3) + head;
		rest = rest.substring(0, rest.length - 3);
	}
	head = rest + head;
	if (n_decimals > 0) head += (decimal_separator + fraction);
	return (is_positive ? "" : "-") + head;
}

function format_number_en(floatval, n_decimals) {
	return format_number(floatval, n_decimals, ",", ".");
}

function format_number_da(floatval, n_decimals) {
	return format_number(floatval, n_decimals, ".", ",");
}

function format_number_sv(floatval, n_decimals) {
	return format_number(floatval, n_decimals, "\u00A0", ","); // (0x00A0 is non-breaking space)
}

const format_number_de = format_number_da; // deutsch is as danish
const format_number_nl = format_number_da; // dutch is as danish
const format_number_no = format_number_da; // norwegian is as danish
const format_number_fr = format_number_sv; // french is as swedish

function format_duration(units, total_seconds) {
	total_seconds = Math.floor(total_seconds);

	const one_minute = 60;
	const one_hour = 60 * one_minute;
	const one_day = 24 * one_hour;

	const days = Math.floor(total_seconds / one_day);
	const hours = Math.floor(total_seconds / one_hour) % 24;
	const minutes = Math.floor(total_seconds / one_minute) % 60;
	const seconds = total_seconds % 60;

	const fmt = (f) => {
		var str = "";
		for (var i = 0; i < f.length; i++) {
			const ch = f[i];
			const value = {d:days, h:hours, m:minutes, s:seconds}[ch];
			if (value !== undefined) {
				str += value + units[ch];
			} else {
				str += ch;
			}
		}
		return str;
	};

	if (total_seconds > one_day) {
		return fmt("dh");
	} else if (total_seconds > one_hour) {
		return fmt("hm");
	} else if (total_seconds > one_minute) {
		return fmt("ms");
	} else {
		return fmt("s");
	}
}

export default {
	"1789c4efe40a": {
		"_context": "1h 2m",
		"en": (seconds) => <O>{format_duration({d:'d',h:'h',m:'min',s:'s'}, seconds)}</O>,
		"da": (seconds) => <O>{format_duration({d:'d',h:'t',m:'min',s:'s'}, seconds)}</O>,
		"sv": (seconds) => <O>{format_duration({d:'d',h:'t',m:'min',s:'s'}, seconds)}</O>,
		"de": (seconds) => <O>{format_duration({d:'d',h:'h',m:'min',s:'s'}, seconds)}</O>,
		"fr": (seconds) => <O>{format_duration({d:'j',h:'h',m:'min',s:'s'}, seconds)}</O>,
		"nl": (seconds) => <O>{format_duration({d:'d',h:'u',m:'min',s:'s'}, seconds)}</O>,
		"no": (seconds) => <O>{format_duration({d:'d',h:'t',m:'min',s:'s'}, seconds)}</O>,
	},

	"9f38ea14748d": {
		"_context": "123,456.78",
		"en": (n_decimals, val) => <O>{format_number_en(val, n_decimals)}</O>,
		"da": (n_decimals, val) => <O>{format_number_da(val, n_decimals)}</O>,
		"sv": (n_decimals, val) => <O>{format_number_sv(val, n_decimals)}</O>,
		"de": (n_decimals, val) => <O>{format_number_de(val, n_decimals)}</O>,
		"nl": (n_decimals, val) => <O>{format_number_nl(val, n_decimals)}</O>,
		"no": (n_decimals, val) => <O>{format_number_no(val, n_decimals)}</O>,
		"fr": (n_decimals, val) => <O>{format_number_fr(val, n_decimals)}</O>,
	},

	"d1024cd66c31": {
		"_context": "DKK 123,456.78",
		"da": (currency_css, n_decimals, unit, val) => {
			switch (unit) {
			case "USD": return <O>${format_number_da(val, n_decimals)}</O>;
			case "EUR": return <O>€{format_number_da(val, n_decimals)}</O>;
			case "DKK": return <O>{format_number_da(val, n_decimals)}<span className={"ps-2 " + currency_css}>kr.</span></O>;
			default:    return <O><span className={"ps-2 " + currency_css}>{unit}</span>&nbsp;{format_number_da(val, n_decimals)}</O>;
			}
		},
		"de": (currency_css, n_decimals, unit, val) => {
			switch (unit) {
			case "USD": return <O>${format_number_de(val, n_decimals)}</O>;
			case "EUR": return <O>€{format_number_de(val, n_decimals)}</O>;
			default:    return <O><span className={"ps-2 " + currency_css}>{unit}</span>&nbsp;{format_number_de(val, n_decimals)}</O>;
			}
		},
		"en": (currency_css, n_decimals, unit, val) => {
			switch (unit) {
			case "USD": return <O>${format_number_en(val, n_decimals)}</O>;
			case "EUR": return <O>€{format_number_en(val, n_decimals)}</O>;
			default:    return <O><span className={"ps-2 " + currency_css}>{unit}</span>&nbsp;{format_number_en(val, n_decimals)}</O>;
			}
		},
		"fr": (currency_css, n_decimals, unit, val) => {
			switch (unit) {
			case "USD": return <O>${format_number_fr(val, n_decimals)}</O>;
			case "EUR": return <O>€{format_number_fr(val, n_decimals)}</O>;
			default:    return <O><span className={"ps-2 " + currency_css}>{unit}</span>&nbsp;{format_number_de(val, n_decimals)}</O>;
			}
		},
		"nl": (currency_css, n_decimals, unit, val) => {
			switch (unit) {
			case "USD": return <O>${format_number_nl(val, n_decimals)}</O>;
			case "EUR": return <O>€{format_number_nl(val, n_decimals)}</O>;
			default:    return <O><span className={"ps-2 " + currency_css}>{unit}</span>&nbsp;{format_number_da(val, n_decimals)}</O>;
			}
		},
		"no": (currency_css, n_decimals, unit, val) => {
			switch (unit) {
			case "USD": return <O>${format_number_no(val, n_decimals)}</O>;
			case "EUR": return <O>€{format_number_no(val, n_decimals)}</O>;
			case "NOK": return <O>{format_number_no(val, n_decimals)} <span className={"ps-2 " + currency_css}>kr</span></O>;
			default:    return <O><span className={"ps-2 " + currency_css}>{unit}</span>&nbsp;{format_number_sv(val, n_decimals)}</O>;
			}
		},
		"sv": (currency_css, n_decimals, unit, val) => {
			switch (unit) {
			case "USD": return <O>${format_number_sv(val, n_decimals)}</O>;
			case "EUR": return <O>€{format_number_sv(val, n_decimals)}</O>;
			case "SEK": return <O>{format_number_sv(val, n_decimals)} <span className={"ps-2 " + currency_css}>kr</span></O>;
			default:    return <O><span className={"ps-2 " + currency_css}>{unit}</span>&nbsp;{format_number_sv(val, n_decimals)}</O>;
			}
		},
	},

	"7de72a232de7": {
		"_context": "Mon",
		"en": (weekday_bewteen_0_and_6_monday_to_sunday) => <O>{weekday_short.en[(weekday_bewteen_0_and_6_monday_to_sunday+1)%7]}</O>,
		"da": (weekday_bewteen_0_and_6_monday_to_sunday) => <O>{weekday_short.da[(weekday_bewteen_0_and_6_monday_to_sunday+1)%7]}</O>,
		"sv": (weekday_bewteen_0_and_6_monday_to_sunday) => <O>{weekday_short.sv[(weekday_bewteen_0_and_6_monday_to_sunday+1)%7]}</O>,
		"de": (weekday_bewteen_0_and_6_monday_to_sunday) => <O>{weekday_short.de[(weekday_bewteen_0_and_6_monday_to_sunday+1)%7]}</O>,
		"no": (weekday_bewteen_0_and_6_monday_to_sunday) => <O>{weekday_short.no[(weekday_bewteen_0_and_6_monday_to_sunday+1)%7]}</O>,
		"nl": (weekday_bewteen_0_and_6_monday_to_sunday) => <O>{weekday_short.nl[(weekday_bewteen_0_and_6_monday_to_sunday+1)%7]}</O>,
		"fr": (weekday_bewteen_0_and_6_monday_to_sunday) => <O>{weekday_short.fr[(weekday_bewteen_0_and_6_monday_to_sunday+1)%7]}</O>,
	},

	"89ef4092463f": {
		"_context": "January",
		"en": (month_between_0_and_11) => <O>{months.en[month_between_0_and_11]}</O>,
		"da": (month_between_0_and_11) => <O>{months.da[month_between_0_and_11]}</O>,
		"sv": (month_between_0_and_11) => <O>{months.sv[month_between_0_and_11]}</O>,
		"de": (month_between_0_and_11) => <O>{months.de[month_between_0_and_11]}</O>,
		"no": (month_between_0_and_11) => <O>{months.no[month_between_0_and_11]}</O>,
		"nl": (month_between_0_and_11) => <O>{months.nl[month_between_0_and_11]}</O>,
		"fr": (month_between_0_and_11) => <O>{months.fr[month_between_0_and_11]}</O>,
	},

	"473b562fa7fc": {
		"_context": "1 January 2018",
		"en": (timestamp) => {
			const d = ts2date(timestamp);
			const dd = d.getDate();
			const mm = d.getMonth();
			const yyyy = d.getFullYear();
			return <O>{dd} {months.en[mm]} {yyyy}</O>;
		},
		"da": (timestamp) => {
			const d = ts2date(timestamp);
			const dd = d.getDate();
			const mm = d.getMonth();
			const yyyy = d.getFullYear();
			return <O>{dd}. {months.da[mm]} {yyyy}</O>;
		},
		"sv": (timestamp) => {
			const d = ts2date(timestamp);
			const dd = d.getDate();
			const mm = d.getMonth();
			const yyyy = d.getFullYear();
			return <O>{dd} {months.sv[mm]} {yyyy}</O>;
		},
		"de": (timestamp) => {
			const d = ts2date(timestamp);
			const dd = d.getDate();
			const mm = d.getMonth();
			const yyyy = d.getFullYear();
			return <O>{dd}. {months.de[mm]} {yyyy}</O>;
		},
		"no": (timestamp) => {
			const d = ts2date(timestamp);
			const dd = d.getDate();
			const mm = d.getMonth();
			const yyyy = d.getFullYear();
			return <O>{dd}. {months.no[mm]} {yyyy}</O>;
		},
		"nl": (timestamp) => {
			const d = ts2date(timestamp);
			const dd = d.getDate();
			const mm = d.getMonth();
			const yyyy = d.getFullYear();
			return <O>{dd} {months.nl[mm]} {yyyy}</O>;
		},
		"fr": (timestamp) => {
			const d = ts2date(timestamp);
			const dd = d.getDate();
			const mm = d.getMonth();
			const yyyy = d.getFullYear();
			return <O>{dd} {months.fr[mm]} {yyyy}</O>;
		},
	},

	"a1fc00dbd506": {
		"_context": "1/1/18",
		"en": (timestamp) => {
			const date = ts2date(timestamp);
			const m = date.getMonth()+1;
			const d = date.getDate();
			const yy = date.getYear() - 100;
			return <O>{m}/{d}/{yy}</O>
		},
		"da": (timestamp) => {
			const date = ts2date(timestamp);
			const m = date.getMonth()+1;
			const d = date.getDate();
			const yy = date.getYear() - 100;
			return <O>{d}/{m}-{yy}</O>
		},
		"sv": (timestamp) => {
			const date = ts2date(timestamp);
			const m = date.getMonth()+1;
			const d = date.getDate();
			const yy = date.getYear() - 100;
			return <O>{d}/{m}.{yy}</O>
		},
		"de": (timestamp) => {
			const date = ts2date(timestamp);
			const m = date.getMonth()+1;
			const d = date.getDate();
			const yy = date.getYear() - 100;
			return <O>{d}.{m}.{yy}</O>
		},
		"no": (timestamp) => {
			const date = ts2date(timestamp);
			const m = date.getMonth()+1;
			const d = date.getDate();
			const yy = date.getYear() - 100;
			return <O>{d}/{m}-{yy}</O>
		},
		"nl": (timestamp) => {
			const date = ts2date(timestamp);
			const m = date.getMonth()+1;
			const d = date.getDate();
			const yy = date.getYear() - 100;
			return <O>{d}-{m}-{yy}</O>
		},
		"fr": (timestamp) => {
			const date = ts2date(timestamp);
			const m = date.getMonth()+1;
			const d = date.getDate();
			const yy = date.getYear() - 100;
			return <O>{d}.{m}.{yy}</O>
		},
	},

	"2496a8470234": {
		"_context": "Fri, Jan 1 4:20 PM",
		"en": (timestamp) => {
			const d = ts2date(timestamp);
			const ww = d.getDay();
			const mm = d.getMonth();
			const yyyy = d.getFullYear();
			const dd = d.getDate();
			const hrs = d.getHours();
			const hrs12 = ((hrs+11)%12)+1;
			const ampm = hrs >= 12 ? "PM" : "AM";
			const mins = d.getMinutes();
			return <O>{weekday_short.en[ww]}, {months_short.en[mm]} {dd} {yyyy} {hrs12}:{npad(mins,2)} {ampm}</O>;
		},
		"da": (timestamp) => {
			const d = ts2date(timestamp);
			const ww = d.getDay();
			const mm = d.getMonth();
			const yyyy = d.getFullYear();
			const dd = d.getDate();
			const hrs = d.getHours();
			const mins = d.getMinutes();
			return <O>{weekday_short.da[ww]} d. {dd}. {months_short.da[mm]} {yyyy} {hrs}:{npad(mins,2)}</O>;
		},
		"sv": (timestamp) => {
			const d = ts2date(timestamp);
			const ww = d.getDay();
			const mm = d.getMonth();
			const yyyy = d.getFullYear();
			const dd = d.getDate();
			const hrs = d.getHours();
			const mins = d.getMinutes();
			return <O>{weekday_short.sv[ww]} d. {dd}. {months_short.sv[mm]} {yyyy} {hrs}:{npad(mins,2)}</O>;
		},
		"de": (timestamp) => {
			const d = ts2date(timestamp);
			const ww = d.getDay();
			const mm = d.getMonth();
			const yyyy = d.getFullYear();
			const dd = d.getDate();
			const hrs = d.getHours();
			const mins = d.getMinutes();
			return <O>{weekday_short.de[ww]} d. {dd}. {months_short.de[mm]} {yyyy} {hrs}:{npad(mins,2)}</O>;
		},
		"no": (timestamp) => {
			const d = ts2date(timestamp);
			const ww = d.getDay();
			const mm = d.getMonth();
			const yyyy = d.getFullYear();
			const dd = d.getDate();
			const hrs = d.getHours();
			const mins = d.getMinutes();
			return <O>{weekday_short.no[ww]} {dd}. {months_short.no[mm]} {yyyy} {hrs}:{npad(mins,2)}</O>;
		},
		"nl": (timestamp) => {
			const d = ts2date(timestamp);
			const ww = d.getDay();
			const mm = d.getMonth();
			const yyyy = d.getFullYear();
			const dd = d.getDate();
			const hrs = d.getHours();
			const mins = d.getMinutes();
			return <O>{weekday_short.nl[ww]} {dd} {months_short.nl[mm]} {yyyy} {hrs}:{npad(mins,2)}</O>;
		},
		"fr": (timestamp) => {
			const d = ts2date(timestamp);
			const ww = d.getDay();
			const mm = d.getMonth();
			const yyyy = d.getFullYear();
			const dd = d.getDate();
			const hrs = d.getHours();
			const mins = d.getMinutes();
			return <O>{weekday_short.fr[ww]} {dd} {months_short.fr[mm]} {yyyy} {hrs}:{npad(mins,2)}</O>;
		},
	},

	"8e83ffa3c180": {
		"_context": "1/1 4:20 PM",
		"en": (timestamp) => {
			const date = ts2date(timestamp);
			const m = date.getMonth()+1;
			const d = date.getDate();
			const hrs = date.getHours();
			const hrs12 = ((hrs+11)%12)+1;
			const ampm = hrs >= 12 ? "PM" : "AM";
			const mins = date.getMinutes();
			return <O>{m}/{d} {hrs12}:{npad(mins,2)} {ampm}</O>;
		},
		"da": (timestamp) => {
			const date = ts2date(timestamp);
			const m = date.getMonth()+1;
			const d = date.getDate();
			const hrs24 = date.getHours();
			const mins = date.getMinutes();
			return <O>{d}/{m} {hrs24}:{npad(mins,2)}</O>;
		},
		"sv": (timestamp) => {
			const date = ts2date(timestamp);
			const m = date.getMonth()+1;
			const d = date.getDate();
			const hrs24 = date.getHours();
			const mins = date.getMinutes();
			return <O>{d}/{m} {hrs24}:{npad(mins,2)}</O>;
		},
		"de": (timestamp) => {
			const date = ts2date(timestamp);
			const m = date.getMonth()+1;
			const d = date.getDate();
			const hrs24 = date.getHours();
			const mins = date.getMinutes();
			return <O>{d}.{m}. {hrs24}:{npad(mins,2)}</O>;
		},
		"no": (timestamp) => {
			const date = ts2date(timestamp);
			const m = date.getMonth()+1;
			const d = date.getDate();
			const hrs24 = date.getHours();
			const mins = date.getMinutes();
			return <O>{d}/{m} {hrs24}:{npad(mins,2)}</O>;
		},
		"nl": (timestamp) => {
			const date = ts2date(timestamp);
			const m = date.getMonth()+1;
			const d = date.getDate();
			const hrs24 = date.getHours();
			const mins = date.getMinutes();
			return <O>{d}-{m} {hrs24}:{npad(mins,2)}</O>;
		},
		"fr": (timestamp) => {
			const date = ts2date(timestamp);
			const m = date.getMonth()+1;
			const d = date.getDate();
			const hrs24 = date.getHours();
			const mins = date.getMinutes();
			return <O>{d}.{m}. {hrs24}:{npad(mins,2)}</O>;
		},
	},

	"c086310b427b": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Betal med kort</O>,
		"de": () => <O>Bezahlen Sie mit Kreditkarte</O>,
		"en": () => <O>Pay with credit card</O>,
		"fr": () => <O>Paiement par carte de crédit</O>,
		"nl": () => <O>Betaal met creditcard</O>,
		"no": () => <O>Betal med kredittkort</O>,
		"sv": () => <O>Betala med kreditkort</O>,
	},

	"3df3a6d8b2db": {
		"en": () => <O>Download our app and watch directly on your smartphone</O>,
		"da": () => <O>Download vores app direkte til din smartphone (det er gratis)</O>,
		"de": () => <O>Übernehmen Sie unsere App und schauen Sie direkt auf Ihrem Smartphone </O>,
		"sv": () => <O>Ladda ner vår app och titta direkt på din smartphone</O>,
		"no": () => <O>Last ned appen og se direkte på mobilen.</O>,
		"fr": () => <O>Téléchargez notre application et regardez directement sur votre smartphone</O>,
		"nl": () => <O>Download onze app en kijk direct op uw smartphone</O>,
	},

	"5ac2e02ca07f": {
		"da": () => <O>Viden er bedst, når den bliver delt.</O>,
		"de": () => <O>Wissen ist am besten, wenn es geteilt wird.</O>,
		"en": () => <O>Knowledge is best when shared.</O>,
		"fr": () => <O>Le savoir est meilleur lorsqu'il est partagé.</O>,
		"nl": () => <O>Kennis is het beste als het gedeeld wordt.</O>,
		"no": () => <O>Kunnskap er best når den deles.</O>,
		"sv": () => <O>Kunskap är bäst när den delas.</O>,
	},

	"725d353c735c": {
		"da": () => <O>Tilmeld dig</O>,
		"de": () => <O>Registrieren</O>,
		"en": () => <O>Sign up to</O>,
		"fr": () => <O>S'inscrire</O>,
		"nl": () => <O>Aanmelden</O>,
		"no": () => <O>Registrer deg</O>,
		"sv": () => <O>Registrera dig</O>,
	},

	"588494626cf3": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>ZEVIO ApS - forbeholdt alle rettigheder</O>,
		"de": () => <O>Zevio - Alle Rechte vorbehalten</O>,
		"en": () => <O>ZEVIO ApS - All rights reserved</O>,
		"fr": () => <O>Zevio - Tous droits réservés</O>,
		"nl": () => <O>Zevio - Alle rechten voorbehouden</O>,
		"no": () => <O>Zevio - Alle rettigheter reservert</O>,
		"sv": () => <O>Zevio - Alla rättigheter förbehållna</O>,
	},

	"bc8c550f4a4e": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Copyright &copy;</O>,
		"de": () => <O>Copyright &copy;</O>,
		"en": () => <O>Copyright &copy;</O>,
		"fr": () => <O>Copyright &copy;</O>,
		"nl": () => <O>Copyright &copy;</O>,
		"no": () => <O>Copyright &copy;</O>,
		"sv": () => <O>Copyright &copy;</O>,
	},

	"226f6e5596d0": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Lav din egen udsendelse på Zevio</O>,
		"de": () => <O>Publish your own streams</O>,
		"en": () => <O>Create your own streams on Zevio</O>,
		"fr": () => <O>Publiez vos propres flux</O>,
		"nl": () => <O>Publiceer je eigen streams</O>,
		"no": () => <O>Publiser dine egne strømmer</O>,
		"sv": () => <O>Publicera dina egna strömmar</O>,
	},

	"a83628eb4b41": {
		"da": () => <O>Velkommen til din ordrehub</O>,
		"de": () => <O>Willkommen zu Ihrem Bestellportal</O>,
		"en": () => <O>Welcome to your order hub</O>,
		"fr": () => <O>Bienvenue dans votre centre de commandes</O>,
		"nl": () => <O>Welkom bij je bestelhub</O>,
		"no": () => <O>Velkommen til din bestillingshub</O>,
		"sv": () => <O>Välkommen till din beställningscentral</O>,
	},

	"eba44930a20c": {
		"da": () => <O>Dette er, hvor du administrerer dine ordrer fra Zevio</O>,
		"de": () => <O>Hier verwalten Sie Ihre Bestellungen von Zevio</O>,
		"en": () => <O>This is where you manage your orders from Zevio</O>,
		"fr": () => <O>C'est ici que vous gérez vos commandes depuis Zevio</O>,
		"nl": () => <O>Dit is waar je je bestellingen van Zevio beheert</O>,
		"no": () => <O>Dette er hvor du administrerer bestillingene dine fra Zevio</O>,
		"sv": () => <O>Detta är där du hanterar dina beställningar från Zevio</O>,
	},

	"723485ff5782": {
		"da": () => <O>Vælg produkt</O>,
		"de": () => <O>Produkt auswählen</O>,
		"en": () => <O>Select product</O>,
		"fr": () => <O>Sélectionner un produit</O>,
		"nl": () => <O>Product selecteren</O>,
		"no": () => <O>Velg produkt</O>,
		"sv": () => <O>Välj produkt</O>,
	},

	"501f6cdcbcea": {
		"en": () => <O>Get the app</O>,
		"da": () => <O>Hent vores app</O>,
		"de": () => <O>Hier auch als kostenlose App</O>,
		"sv": () => <O>Få appen gratis</O>,
		"no": () => <O>Last ned gratis app</O>,
		"fr": () => <O>Téléchargez l'application gratuite</O>,
		"nl": () => <O>Download de gratis app</O>,
	},

	"867a2728c7f7": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Arrangørnavn</O>,
		"de": () => <O>Veranstaltername</O>,
		"en": () => <O>Organizer Name</O>,
		"fr": () => <O>Nom de l'organisateur</O>,
		"nl": () => <O>Organisatienaam</O>,
		"no": () => <O>Arrangørnavn</O>,
		"sv": () => <O>Arrangörsnamn</O>,
	},

	"0990424a1ef6": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Slet email fra GetResponse-lister</O>,
		"de": () => <O>E-Mail von Getresponse-Listen löschen</O>,
		"en": () => <O>Delete email from GetResponse lists</O>,
		"fr": () => <O>Supprimer l'e-mail des listes Getresponse</O>,
		"nl": () => <O>Verwijder e-mail uit Getresponse-lijsten</O>,
		"no": () => <O>Slett e-post fra Getresponse-lister</O>,
		"sv": () => <O>Ta bort e-post från Getresponse-listor</O>,
	},

	"aab9c339f452": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Livedato </O>,
		"de": () => <O>Live: </O>,
		"en": () => <O>Live: </O>,
		"fr": () => <O>Live: </O>,
		"nl": () => <O>Live: </O>,
		"no": () => <O>Live: </O>,
		"sv": () => <O>Live: </O>,
	},

	"718de8f63903": {
		"da": () => <O>Opret konto</O>,
		"de": () => <O>Konto erstellen</O>,
		"en": () => <O>Create account</O>,
		"fr": () => <O>Créer un compte</O>,
		"nl": () => <O>Account aanmaken</O>,
		"no": () => <O>Opprett konto</O>,
		"sv": () => <O>Skapa konto</O>,
	},

	"c1cf017b61d1": {
		"da": () => <O>Kamera og/eller mikrofon er blokeret.</O>,
		"de": () => <O>Kamera und/oder Mikrofon blockiert</O>,
		"en": () => <O>Camera and/or microphone blocked</O>,
		"fr": () => <O>La caméra et/ou le microphone sont bloqués.</O>,
		"nl": () => <O>Caméra et/ou microphone bloqué(e)(s)</O>,
		"no": () => <O>Kamera og/eller mikrofon blokkert</O>,
		"sv": () => <O>Kamera och/eller mikrofon blockerad(e)</O>,
	},

	"f81a025a6936": {
		"da": () => {
			return (
				<O>
									For at kunne sende direkte fra din browser, er det nødvendigt at give browseren adgang til dit kamera og din mikrofon. Hvis du klikker på knappen herunder og re-initialiserer, så husk at klikke ja til browserens forespørgsel.
								</O>
			);
		},
		"de": () => {
			return (
				<O>
									Um Zevio Self-Service nutzen zu können, müssen Sie dem Browser erlauben, Ihre Kameras und Ihr Mikrofon zu verwenden.					Wenn Sie auf die Schaltfläche unten klicken und neu starten, erlauben Sie dem Browser den Zugriff.
								</O>
			);
		},
		"en": () => {
			return (
				<O>
					In order to use Zevio self-service, you need to allow the browser to use your cameras and microphone.
					If you click the below button and re-initialize, make sure to let the browser get access.
				</O>
			);
		},
		"fr": () => {
			return (
				<O>
									Pour utiliser le service en libre-service Zevio, vous devez autoriser le navigateur à utiliser vos caméras et votre microphone. Si vous cliquez sur le bouton ci-dessous et redémarrez, assurez-vous de donner accès au navigateur.
								</O>
			);
		},
		"nl": () => {
			return (
				<O>
									Om gebruik te kunnen maken van de Zevio-selfservice, moet je de browser toestaan je camera's en microfoon te gebruiken. Als je op de onderstaande knop klikt en opnieuw start, zorg er dan voor dat je de browser toegang verleent.
								</O>
			);
		},
		"no": () => {
			return (
				<O>
									For å bruke Zevio selvbetjening må du tillate nettleseren å bruke kameraet og mikrofonen din. Hvis du klikker på knappen nedenfor og initialiserer på nytt, sørg for å gi nettleseren tilgang.
								</O>
			);
		},
		"sv": () => {
			return (
				<O>
									För att använda Zevio självbetjäning måste du tillåta webbläsaren att använda dina kameror och mikrofon. Om du klickar på knappen nedan och återställer, se till att ge webbläsaren åtkomst.
								</O>
			);
		},
	},

	"4c38a60d6e08": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Re-initialiser kamera og mikrofon</O>,
		"de": () => <O>Wiederholung der Hardware-Initialisierung</O>,
		"en": () => <O>Retry hardware initialization</O>,
		"fr": () => <O> Réessayez l'initialisation du matériel.</O>,
		"nl": () => <O>Opnieuw hardware-initialisatie proberen</O>,
		"no": () => <O>Prøv igjen med maskinvareinitialisering</O>,
		"sv": () => <O>Försök initialisera hårdvaran igen</O>,
	},

	"06d363018336": {
		"da": () => <O>Vælg kamera</O>,
		"de": () => <O>Kamera auswählen</O>,
		"en": () => <O>Select camera</O>,
		"fr": () => <O> Sélectionnez la caméra.</O>,
		"nl": () => <O>Selecteer camera</O>,
		"no": () => <O>Velg kamera</O>,
		"sv": () => <O>Välj kamera</O>,
	},

	"f49c7d3e1055": {
		"da": () => <O>Finder tilgængelige kameraer</O>,
		"de": () => <O>Verfügbare Kameras erkennen</O>,
		"en": () => <O>Detecting available cameras</O>,
		"fr": () => <O>Détection des caméras disponibles</O>,
		"nl": () => <O>Detecteren van beschikbare camera's</O>,
		"no": () => <O>Oppdager tilgjengelige kameraer</O>,
		"sv": () => <O>Uppspårning av tillgängliga kameror</O>,
	},

	"176e55a02bee": {
		"da": () => <O>Vælg mikrofon</O>,
		"de": () => <O>Mikrofon auswählen</O>,
		"en": () => <O>Select microphone</O>,
		"fr": () => <O>Sélectionnez le microphone</O>,
		"nl": () => <O>Selecteer microfoon</O>,
		"no": () => <O> Velg mikrofonen</O>,
		"sv": () => <O>Välj mikrofonen</O>,
	},

	"409fd0bee206": {
		"da": () => <O>Finder tilgængelige mikrofoner</O>,
		"de": () => <O>Verfügbare Mikrofone erkennen</O>,
		"en": () => <O>Detecting available microphones</O>,
		"fr": () => <O>Détection des microphones disponibles</O>,
		"nl": () => <O>Detecteren van beschikbare microfoons</O>,
		"no": () => <O>Oppdager tilgjengelige mikrofoner</O>,
		"sv": () => <O> Upptäcker tillgängliga mikrofoner</O>,
	},

	"a7b6d9e22aae": {
		"da": () => <O>Starter kamera og mikrofon. Det tager et øjeblik.</O>,
		"de": () => <O>Die Kamera und das Mikrofon werden gestartet. Bitte haben Sie Geduld.</O>,
		"en": () => <O>Inititalizing camera and microphone. Please be patient.</O>,
		"fr": () => <O>Initialisation de la caméra et du microphone. Veuillez patienter.</O>,
		"nl": () => <O>Initialiseren van camera en microfoon. Gelieve geduld te hebben.</O>,
		"no": () => <O>Initialiserer kamera og mikrofon. Vennligst vent. </O>,
		"sv": () => <O>Initierar kamera och mikrofon. Var vänlig vänta.</O>,
	},

	"2793bf50e462": {
		"da": () => <O>Gode links</O>,
		"de": () => <O>Nützliche Links</O>,
		"en": () => <O>Useful links</O>,
		"fr": () => <O>Liens utiles</O>,
		"nl": () => <O>Handige links</O>,
		"no": () => <O>Nyttige lenker</O>,
		"sv": () => <O>Användbara länkar</O>,
	},

	"2935c3969d3f": {
		"da": () => <O>Privatlivspolitik</O>,
		"de": () => <O>Datenschutzbestimmungen</O>,
		"en": () => <O>Privacy policy</O>,
		"fr": () => <O>Politique de confidentialité</O>,
		"nl": () => <O>Privacybeleid</O>,
		"no": () => <O>Personvernerklæring</O>,
		"sv": () => <O>Integritetspolicy</O>,
	},

	"a46011adbf80": {
		"da": () => <O>Betingelser for brug</O>,
		"de": () => <O>Nutzungsbedingungen</O>,
		"en": () => <O>Terms of use</O>,
		"fr": () => <O>Conditions d'utilisation</O>,
		"nl": () => <O>Voorwaarden van de dienst</O>,
		"no": () => <O>Vilkår for bruk</O>,
		"sv": () => <O>Villkor för tjänsten</O>,
	},

	"9885abb91866": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Handelsbetingelser</O>,
		"de": () => <O>Handelsbedingungen</O>,
		"en": () => <O>Trade conditions</O>,
		"fr": () => <O>Conditions de commerce</O>,
		"nl": () => <O>Handelsvoorwaarden</O>,
		"no": () => <O>Handelsvilkår</O>,
		"sv": () => <O>Handelsvillkor</O>,
	},

	"63452d4b6246": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Se din første talk i dag</O>,
		"de": () => <O>Los gehts!</O>,
		"en": () => <O>Get started now</O>,
		"fr": () => <O>Commencez maintenant</O>,
		"nl": () => <O>Begin nu</O>,
		"no": () => <O>Kom igang nå</O>,
		"sv": () => <O>Kom igång nu</O>,
	},

	"fe9a9b386d20": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Webadressen for din privatlivspolitik</O>,
		"de": () => <O>Zu den Datenschutzbestimmungen</O>,
		"en": () => <O>The URL to your organizations privacy policy</O>,
		"fr": () => <O>L'URL de la politique de confidentialité de votre organisation</O>,
		"nl": () => <O>De URL naar het privacybeleid van uw organisatie</O>,
		"no": () => <O>Lenke til din personvernserklæring</O>,
		"sv": () => <O>URL till din organisations integritetspolicy</O>,
	},

	"3b1dd895ac66": {
		"en": () => <O>Online talks with the experts</O>,
		"da": () => <O>Se foredrag med danske eksperter</O>,
		"de": () => <O>Virtuelle Vorträge</O>,
		"sv": () => <O>Online talks</O>,
		"no": () => <O>Inspirerende og lærerike webinarer</O>,
		"fr": () => <O>Discussions en direct</O>,
		"nl": () => <O>Online lezingen</O>,
	},

	"40594e578d4b": {
		"en": () => <O>Free streams with the ones who know.<br/>Get experts at your fingertips</O>,
		"da": () => <O>Atleter, videnskabsfolk, forfattere, politikere med flere<br />Hør direkte fra eksperterne</O>,
		"de": () => <O>Fachexperten, Wissenschaftler, Sportler, Autoren, Politiker und weitere interessante Persönlichkeiten stehen dir zur Verfügung<br/></O>,
		"sv": () => <O>Idrottare, vetenskapsmän, författare, politiker med mera<br />Lyssna på experter direkt i telefonen</O>,
		"no": () => <O>Idrettsutøvere, vitenskapsfolk, forfattere, politikere og mer<br />Ha ekspertene lett tilgjengelig</O>,
		"fr": () => <O>Athlètes, scientifiques, auteurs, politiciens et plus encore<br />Des experts à portée de main</O>,
		"nl": () => <O>Atleten, wetenschappers, auteurs, politici en meer<br/>Alle experts binnen handbereik</O>,
	},

	"be64d187056d": {
		"en": () => <O>Zevio app is free to download.</O>,
		"da": () => <O>Zevios app er gratis at downloade</O>,
		"de": () => <O>Die Zevio-App ist kostenlos herunterzuladen</O>,
		"sv": () => <O>Zevios app är gratis att ladda ner.</O>,
		"no": () => <O>Zevios app er gratis å laste ned.</O>,
		"fr": () => <O>L'application de Zevio est gratuite à télécharger.</O>,
		"nl": () => <O>De app van Zevio is gratis te downloaden.</O>,
	},

	"c620aaf40747": {
		"en": () => <O>Or find and join a stream right now on desktop</O>,
		"da": () => <O>Eller dyk ned i vores udsendelse direkte i din browser.</O>,
		"de": () => <O>Alternativ kannst du gleich im Browser loslegen.</O>,
		"sv": () => <O>Eller kom igång direkt på skrivbordet</O>,
		"no": () => <O>Eller kom i gang i nettleseren nå</O>,
		"fr": () => <O>Ou commencez dès maintenant</O>,
		"nl": () => <O>Of ga meteen aan de slag op een desktop computer</O>,
	},

	"921a8a5e40bb": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Find en talk, der inspirerer dig.</O>,
		"de": () => <O>Grenzenloser Zugang - Grenzenlose Inspiration.</O>,
		"en": () => <O>Meet your inspiration.</O>,
		"fr": () => <O>Accès illimité. Inspiration illimitée.</O>,
		"nl": () => <O>Onbeperkt toegang. Onbeperkte inspiratie.</O>,
		"no": () => <O>Ubegrenset tilgang. Ubegrenset inspirasjon</O>,
		"sv": () => <O>Obegränsad tillgång. Obegränsad inspiration.</O>,
	},

	"5ac38753feb3": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Find en talk.<br/>Registrér dig og få din plads.</O>,
		"de": () => <O>Grenzenloser Zugang.<br />Grenzenlose Inspiration.</O>,
		"en": () => <O>Unlimited access.<br />Unlimited inspiration.</O>,
		"fr": () => <O>Accès illimité.<br/>Inspiration illimitée.</O>,
		"nl": () => <O>Onbeperkt toegang.<br/>Onbeperkte inspiratie.</O>,
		"no": () => <O>Ubegrenset tilgang.<br/>Ubegrenset inspirasjon.</O>,
		"sv": () => <O>Obegränsad tillgång.<br/>Obegränsad inspiration.</O>,
	},

	"ccf31f6f9016": {
		"en": () => <O>For the truly curious.</O>,
		"da": () => <O>For de virkeligt nysgerrige</O>,
		"de": () => <O>Für die wirklich Neugierigen</O>,
		"sv": () => <O>För dig som är riktigt nyfiken</O>,
		"no": () => <O>For de virkelig nysgjerrige</O>,
		"fr": () => <O>Pour les vrais curieux</O>,
		"nl": () => <O>Voor de echte nieuwsgierigen</O>,
	},

	"be902191cc67": {
		"en": () => <O>No ads, no topic to big or small</O>,
		"da": () => <O>Kom i dybden med netop det, der interesserer dig.</O>,
		"de": () => <O>Halte dich nicht mit den traditionellen medialen Einzeilern auf</O>,
		"sv": () => <O>Gå bortom de traditionella rubrikerna</O>,
		"no": () => <O>Gå dypere enn overskriftene</O>,
		"fr": () => <O>Allez plus loin que les phrases toutes faites des médias traditionnels</O>,
		"nl": () => <O>Wat de deelnemers zeggen over de lezingen</O>,
	},

	"93edf3bc2e9a": {
		"en": () => {
			return (
				<O>Thank You again! This talk was really interesting and rarely has 30 minutes				felt so short. As mentioned during the stream, this should have lasted much longer!</O>
			);
		},
		"da": () => {
			return (
				<O>Tusind tak igen!!! Det var virkelig interessant, og sjældent har en halv time forløbet så hurtigt!! Som også nævnt under webinaret, så måtte dette webinar godt have varet meget, meget længere!!“ - Charlotte O</O>
			);
		},
		"de": () => {
			return (
				<O>Nochmals danke! Dieses Gespräch war sehr interessant und selten sind 30 Minuten so schnell vergangen. Wie während der Übertragung erwähnt, hätte es auch noch viel länger sein können!</O>
			);
		},
		"sv": () => {
			return (
				<O>Tack igen!!! Det här föredraget var riktigt intressant och sällan har 30 minuter känts så kort. Som nämndes under sändningen borde detta ha pågått mycket längre!!</O>
			);
		},
		"no": () => {
			return (
				<O>Takk igjen!! Dette foredraget var virkelig interessant, og sjelden har 30 minutter føltes kortere. Som nevnt under sendingen, burde dette vart mye lengre!!</O>
			);
		},
		"fr": () => {
			return (
				<O>Merci encore ! !! Cette conférence était vraiment intéressante et rarement 30 minutes n'ont semblé aussi courtes. Comme mentionné pendant la diffusion, cela aurait dû durer beaucoup plus longtemps !!!</O>
			);
		},
		"nl": () => {
			return (
				<O>Nogmaals bedankt! Dit gesprek was erg interessant en zelden hebben 30 minuten zo kort gevoeld. Zoals vermeld tijdens de uitzending had dit veel langer mogen duren!</O>
			);
		},
	},

	"1d22fd9adc7c": {
		"en": () => {
			return (
				<O>Thank You for a great stream. It was really relevant for me in my education as a metacognitive				therapist. It is always a pleasure to listen to people with real experience and in their own words.</O>
			);
		},
		"da": () => {
			return (
				<O>Tak for et virkelig godt webinar. Rigtig relevant og brug i min uddannelse til metakognitiv terapeut. Altid godt at høre personer med deres egne ord og levede erfaring. “ Anne-Mette P</O>
			);
		},
		"de": () => {
			return (
				<O>Danke für das großartige Gespräch. Das war sehr wichtig für meine Ausbildung als metakognitive Therapeutin. Es ist immer wieder toll, Leuten mit wirklicher Erfahrung zuzuhören. Anne-Mette P.</O>
			);
		},
		"sv": () => {
			return (
				<O>Tack för ett bra föredrag. Det var verkligen relevant för mig i min utbildning som metakognitiv terapeut. Det är alltid ett nöje att lyssna på människor med verklig erfarenhet och med deras egna ord. Anne-Mette P.</O>
			);
		},
		"no": () => {
			return (
				<O>Takk for et supert foredrag. Dette var virkelig relevant for utdanningen min som metakognitiv terapeut. Det er alltid en fornøyelse å høre på mennesker med ekte erfaring som sier ting med egne ord. Anne-Mette P.</O>
			);
		},
		"fr": () => {
			return (
				<O>Merci pour cet excellent exposé. Il était vraiment pertinent pour moi dans ma formation de thérapeute métacognitif. C'est toujours un plaisir d'écouter des personnes ayant une réelle expérience et avec leurs propres mots. Anne-Mette P.</O>
			);
		},
		"nl": () => {
			return (
				<O>Bedankt voor een geweldige lezing. Het was echt relevant voor mij in mijn opleiding tot metacognitief therapeut. Het is altijd een plezier om te luisteren naar mensen met echte ervaring en in hun eigen woorden. Anne-Mette P.</O>
			);
		},
	},

	"d61545e9cca4": {
		"en": () => <O>This was simply great. I’m very excited. Many thanks, and thanks for hosting this free stream!</O>,
		"da": () => <O>Jeg har tidligere smidt 10.000 kroner efter annoncering på sociale medier og solgte kun ét online kursus. Efter mit webinar her hos Zevio har jeg haft et rigtigt godt salg. Der er super god support, og det er rart at have en teknikker med som hjælp undervejs.  Zevio laver alt det praktiske, de har deltagerne og jeg kan koncentrere mig og indholdet.</O>,
		"de": () => <O>Das war einfach klasse. Ich bin sehr ergriffen. Ganz herzlichen Dank für das tolle Angebot dieses virtuellen Vortrages! (Anonym)</O>,
		"sv": () => <O>Detta var helt enkelt fantastiskt. Jag är väldigt exalterad. Stort tack och tack för att du var värd för detta kostnadsfria föredrag! (Anonym)</O>,
		"no": () => <O>Dette var rett og slett kjempebra. Jeg er veldig begeistret. Tusen takk, og takk for at du var vert for dette gratisforedraget! Anonym</O>,
		"fr": () => <O>C'était tout simplement génial. Je suis très enthousiaste. Merci beaucoup, et merci d'avoir organisé cette conférence gratuite ! Anonyme</O>,
		"nl": () => <O>Dit was gewoon geweldig. Ik ben zeer enthousiast. Hartelijk dank en bedankt voor het hosten van deze gratis lezing!</O>,
	},

	"da6b7097fa30": {
		"en": () => <O>Anonymous</O>,
		"da": () => <O>-Annbritt Olsen, Krop & Kommunikation</O>,
		"de": () => <O>Anonym</O>,
		"sv": () => <O>Anonym</O>,
		"no": () => <O>Anonym</O>,
		"fr": () => <O>Anonyme</O>,
		"nl": () => <O>Anoniem</O>,
	},

	"60e92009eec6": {
		"en": () => <O>Interesting topics</O>,
		"da": () => <O>Mere i dybden</O>,
		"de": () => <O>Interessante Themen</O>,
		"sv": () => <O>Interesanta ämnen</O>,
		"no": () => <O>Interessante temaer</O>,
		"fr": () => <O>Des sujets intéressants</O>,
		"nl": () => <O>Interessante Topics</O>,
	},

	"32f03025be6d": {
		"en": () => {
			return (
				<O>No headlines and empty knowledge calories. Enjoy 30 minutes of passionate knowledge sharing from the experts.</O>
			);
		},
		"da": () => {
			return (
				<O>Ingen overskrifter og tomme videnskalorier. Nyd passioneret vidensdeling fra eksperterne.</O>
			);
		},
		"de": () => {
			return (
				<O>Keine Überschriften und leeren Wissenskalorien. Genießen Sie 30 Minuten leidenschaftlichen Wissensaustausch von den Experten</O>
			);
		},
		"sv": () => {
			return (
				<O>Inga rubriker och tomma kunskapskalorier. Njut av 30 minuters passionerad kunskapsdelning från experterna</O>
			);
		},
		"no": () => {
			return (
				<O>Ingen overskrifter og tomme kunnskapskalorier. Nyt 30 minutter med lidenskapelig kunnskapsdeling fra ekspertene.</O>
			);
		},
		"fr": () => {
			return (
				<O>Pas de titres et de calories de connaissances vides. Profitez de 30 minutes de partage passionné de connaissances par les experts</O>
			);
		},
		"nl": () => {
			return (
				<O>Geen koppen en lege kenniscalorieën. Geniet van 30 minuten gepassioneerde kennisdeling van de experts</O>
			);
		},
	},

	"e7fac2c351ab": {
		"en": () => <O>Streams in many languages</O>,
		"da": () => <O>Streams på flere sprog</O>,
		"de": () => <O>Streams in mehreren Sprachen</O>,
		"sv": () => <O>Strömmar på flera språk</O>,
		"no": () => <O>Strømmer på flere språk</O>,
		"fr": () => <O>Diffusions en plusieurs langues</O>,
		"nl": () => <O>Streams in meerdere talen</O>,
	},

	"9e5e6c3a8dde": {
		"en": () => {
			return (
				<O>Enjoy streams in English, French, German, Danish, Swedish, and Norwegian. Full HD. And watch				from anywhere this side of the moon.</O>
			);
		},
		"da": () => {
			return (
				<O>Få knivskarpe foredrag på engelsk, fransk, tysk, dansk, svensk og norsk. Og se med uanset hvor du befinder dig.</O>
			);
		},
		"de": () => {
			return (
				<O>Erlebe virtuelle Vorträge in Full-HD auf Englisch, Französisch, Deutsch, Dänisch, Schwedisch und Norwegisch. Du kannst von überall ein Teil davon sein.</O>
			);
		},
		"sv": () => {
			return (
				<O>Njut av föreläsingar i Full HD på engelska, franska, tyska, danska, svenska och norska. Och titta från var som helst på den här sidan månen.</O>
			);
		},
		"no": () => {
			return (
				<O>Nyt foredrag i full-HD på engelsk, fransk, tysk, dansk, svensk og norsk. Og se dem fra over hele verden. </O>
			);
		},
		"fr": () => {
			return (
				<O>Profitez de conférences en direct en Full HD en anglais, français, allemand, danois, suédois et norvégien. Et regardez-les depuis n'importe quel endroit.</O>
			);
		},
		"nl": () => {
			return (
				<O>Geniet van lezingen in het Engels, Frans, Duits, Deens, Zweeds en Noors. Full HD. En te bekijken waar je ook bent.</O>
			);
		},
	},

	"e3668c00669b": {
		"en": () => <O>Free or Premium?</O>,
		"da": () => <O>Gratis eller Premium</O>,
		"de": () => <O>Kostenlos oder Premium?</O>,
		"sv": () => <O>Gratis eller Premium?</O>,
		"no": () => <O>Gratis eller Premium?</O>,
		"fr": () => <O>Gratuit ou Premium ?</O>,
		"nl": () => <O>Gratis of Premium?</O>,
	},

	"f19344b845a3": {
		"en": () => {
			return (
				<O>Most of our streams are free to watch. But our premium streams are longer and with more content. They cost a small amount to watch - but its always a one-time payment, never a subscription</O>
			);
		},
		"da": () => {
			return (
				<O>Mange talks er gratis, andre koster penge. Det er op til indholdsskaberen. Vi arbejder ikke med abonnementer - betal kun for det du vil se.</O>
			);
		},
		"de": () => {
			return (
				<O>Die meisten unserer Streams sind kostenlos anzusehen. Aber unsere Premium-Streams sind länger und enthalten mehr Inhalte. Sie kosten eine geringe Gebühr - aber es ist immer eine einmalige Zahlung, nie ein Abonnement</O>
			);
		},
		"sv": () => {
			return (
				<O>De flesta av våra streams är gratis att titta på. Men våra premium streams är längre och med mer innehåll. De kostar en liten summa att titta på - men det är alltid en engångsbetalning, aldrig en prenumeration</O>
			);
		},
		"no": () => {
			return (
				<O>De fleste av våre strømmer er gratis å se på. Men våre premium strømmer er lengre og med mer innhold. De koster en liten sum å se på - men det er alltid en engangsbetaling, aldri et abonnement</O>
			);
		},
		"fr": () => {
			return (
				<O>La plupart de nos diffusions sont gratuites à regarder. Mais nos diffusions premium sont plus longues et avec plus de contenu. Elles coûtent une petite somme à regarder - mais c'est toujours un paiement unique, jamais un abonnement</O>
			);
		},
		"nl": () => {
			return (
				<O>De meeste van onze streams zijn gratis te bekijken. Maar onze premium streams zijn langer en bevatten meer inhoud. Ze kosten een klein bedrag om te bekijken - maar het is altijd een eenmalige betaling, nooit een abonnement</O>
			);
		},
	},

	"c41ec9705744": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Ordrer</O>,
		"de": () => <O>Bestellungen</O>,
		"en": () => <O>Orders</O>,
		"fr": () => <O>Ordres</O>,
		"nl": () => <O>Bestellingen</O>,
		"no": () => <O>Ordre</O>,
		"sv": () => <O>Order</O>,
	},

	"5e956180db1c": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Organizers</O>,
		"de": () => <O>Veranstalter</O>,
		"en": () => <O>Organizers</O>,
		"fr": () => <O>Organisateurs</O>,
		"nl": () => <O>Organisatoren</O>,
		"no": () => <O>Arrangører</O>,
		"sv": () => <O>Organizers</O>,
	},

	"4e711ddba653": {
		"da": () => <O>Download chat transcript</O>,
		"de": () => <O>Chatprotokoll herunterladen</O>,
		"en": () => <O>Download chat transcript</O>,
		"fr": () => <O>Télécharger la transcription du chat</O>,
		"nl": () => <O>Download chattranscriptie</O>,
		"no": () => <O>Last ned chat-transkripsjon</O>,
		"sv": () => <O>Ladda ner chattprotokoll</O>,
	},

	"3514e42ed832": {
		"da": () => <O>Prøver igen ... </O>,
		"de": () => <O>Bestellung erneut senden..</O>,
		"en": () => <O>Re-sending order..</O>,
		"fr": () => <O>Renvoi de la commande..</O>,
		"nl": () => <O>Bestelling opnieuw verzenden..</O>,
		"no": () => <O>Prøver igjen..</O>,
		"sv": () => <O>Försöker igen</O>,
	},

	"7fce1c40e3a4": {
		"da": () => <O>Gensendt til DBK</O>,
		"de": () => <O>Auftrag wurde erneut an DBK gesendet</O>,
		"en": () => <O>Order was sent again to DBK</O>,
		"fr": () => <O>La commande a été renvoyée à DBK</O>,
		"nl": () => <O>Bestelling is opnieuw verzonden naar DBK</O>,
		"no": () => <O>Bestillingen ble sendt igjen til DBK</O>,
		"sv": () => <O>Beställningen skickades igen till DBK</O>,
	},

	"bcccaa8ad1f3": {
		"da": () => <O>Bearbejder manuelt...</O>,
		"de": () => <O>Manuelle Bearbeitung...</O>,
		"en": () => <O>Processing manually...</O>,
		"fr": () => <O>Traitement manuel en cours...</O>,
		"nl": () => <O>Handmatig verwerken...</O>,
		"no": () => <O>Prosesserer manuelt...</O>,
		"sv": () => <O>Bearbetar manuellt...</O>,
	},

	"e06c1c212cad": {
		"da": () => <O>Order has been processed manually</O>,
		"de": () => <O>Bestellung wurde manuell bearbeitet</O>,
		"en": () => <O>Order has been processed manually</O>,
		"fr": () => <O>La commande a été traitée manuellement</O>,
		"nl": () => <O>Bestelling is handmatig verwerkt</O>,
		"no": () => <O>Bestillingen har blir prosessert manuelt</O>,
		"sv": () => <O>Beställningen har behandlats manuellt</O>,
	},

	"9c996d9266a3": {
		"da": () => <O>Ups, noget gik galt</O>,
		"de": () => <O>Etwas ist schief gelaufen</O>,
		"en": () => <O>Something went wrong</O>,
		"fr": () => <O>Quelque chose s'est mal passé</O>,
		"nl": () => <O>Er is iets fout gegaan</O>,
		"no": () => <O>Noe gikk galt</O>,
		"sv": () => <O>Något gick fel</O>,
	},

	"e0d751e37b87": {
		"da": () => <O>Order lines:</O>,
		"de": () => <O>Bestellzeilen</O>,
		"en": () => <O>Order lines:</O>,
		"fr": () => <O>Lignes de commande</O>,
		"nl": () => <O>Bestelregels:</O>,
		"no": () => <O>Ordrelinjer:</O>,
		"sv": () => <O>Beställningsrader:</O>,
	},

	"f9339d397e84": {
		"da": () => <O>Fejlmeldinger:</O>,
		"de": () => <O>Fehlermeldungen:</O>,
		"en": () => <O>Error messages:</O>,
		"fr": () => <O>Messages d'erreur</O>,
		"nl": () => <O>Foutmeldingen:</O>,
		"no": () => <O>Feilmeldinger:</O>,
		"sv": () => <O>Felmeddelanden:</O>,
	},

	"17962c5b3d3f": {
		"da": () => <O>Delivery address</O>,
		"de": () => <O>Lieferadresse</O>,
		"en": () => <O>Delivery address</O>,
		"fr": () => <O>Adresse de livraison</O>,
		"nl": () => <O>Bezorgadres</O>,
		"no": () => <O>Leveringsadresse</O>,
		"sv": () => <O> Leveransadress</O>,
	},

	"5c2f537d2dd1": {
		"da": () => <O>Husk: <i>With great power comes great responsibility</i></O>,
		"de": () => <O>Denken Sie dran: <i>With great power comes great responsibility</i></O>,
		"en": () => <O>Remember: <i>With great power comes great responsibility</i></O>,
		"fr": () => <O>Se souvenir: <i>With great power comes great responsibility</i></O>,
		"nl": () => <O>Onthoudt: <i>With great power comes great responsibility</i></O>,
		"no": () => <O>Husk: <i>With great power comes great responsibility</i></O>,
		"sv": () => <O>Kom ihåg: <i>With great power comes great responsibility</i></O>,
	},

	"110622a731a0": {
		"da": () => <O>Hver gang du klikker på den grønne knap, sendes ordren til DBK, uanset den eksisterende status. Den gule knap ændrer blot status til <tt>processed_manually</tt></O>,
		"de": () => <O>Jeder Klick auf den grünen Button sendet die Bestellung an DBK, unabhängig vom bestehenden Status. Der gelbe Button ändert einfach den Status auf <tt>processed_manually</tt></O>,
		"en": () => <O>Every click of the green button sends the order to DBK, no matter the existing status. The yellow one simply changes the status to <tt>processed_manually</tt></O>,
		"fr": () => <O>Chaque clic sur le bouton vert envoie la commande à DBK, quelle que soit la situation actuelle. Le bouton jaune change simplement le statut en <tt>processed_manually</tt></O>,
		"nl": () => <O>Elke klik op de groene knop stuurt de bestelling naar DBK, ongeacht de bestaande status. De gele verandert eenvoudig de status in <tt>processed_manually</tt></O>,
		"no": () => <O>Når du trykker på den grønne knappen sendes bestillingen til DBK, uansett status. Den gule knappen endrer status til <tt>processed_manually</tt></O>,
		"sv": () => <O>Varje klick på den gröna knappen skickar ordern till DBK, oavsett befintlig status. Den gula ändrar bara status till <tt>processed_manually</tt></O>,
	},

	"86ad63bcd409": {
		"da": () => <O>detaljer</O>,
		"de": () => <O>Details</O>,
		"en": () => <O>details</O>,
		"fr": () => <O>détails</O>,
		"nl": () => <O>details</O>,
		"no": () => <O>detaljer</O>,
		"sv": () => <O>detaljer</O>,
	},

	"96717bb0fe1f": {
		"da": () => <O>Indlæser ordrer</O>,
		"de": () => <O>Lade Aufträge</O>,
		"en": () => <O>Loading orders</O>,
		"fr": () => <O>Chargement des commandes</O>,
		"nl": () => <O>Orders laden</O>,
		"no": () => <O>Laster bestillinger</O>,
		"sv": () => <O>Laddar beställningar</O>,
	},

	"76983e3c3f38": {
		"da": () => <O>Du skal være administrator eller være en del af Dagens Bog for at få adgang til denne side</O>,
		"de": () => <O>Sie müssen Administrator oder Teil von Dagens Bog sein, um auf diese Seite zugreifen zu können</O>,
		"en": () => <O>You need to be admin or part of Dagens Bog to access this page</O>,
		"fr": () => <O>Vous devez être administrateur ou membre de Dagens Bog pour accéder à cette page</O>,
		"nl": () => <O>Je moet beheerder zijn of deel uitmaken van Dagens Bog om toegang te krijgen tot deze pagina</O>,
		"no": () => <O>Du må være admin eller en del av Dagens Bog for å få tilgang til denne siden</O>,
		"sv": () => <O>Du måste vara admin eller en del av Dagens Bok för att komma åt den här sidan</O>,
	},

	"0e56c4432fe3": {
		"da": () => <O>Aktive Dagens Bog-ordrer</O>,
		"de": () => <O>Aktive Dagens Bog Bestellungen</O>,
		"en": () => <O>Active Dagens Bog orders</O>,
		"fr": () => <O>Commandes actives de Dagens Bog</O>,
		"nl": () => <O>Actieve Dagens Bog-bestellingen</O>,
		"no": () => <O>Aktive Dagens Bok-bestillinger</O>,
		"sv": () => <O>Aktiva Dagens Bok beställningar</O>,
	},

	"18e697046333": {
		"en": () => <O>Minimum dimensions 1320px &times; 742px. <span className="font_weight_500"><i>Recommended</i></span> minimum is 2640px &times; 1484px</O>,
		"da": () => <O>Billedet skal være minimum 1320px &times; 742px. <span className="font_weight_500"><i>Anbefalet</i></span> minimum er 2640px &times; 1484px</O>,
		"de": () => <O>Bildgröße mindestens 1320px &times; 742px. <span className="font_weight_500"><i>Empfohlenes</i></span> Minimum: 2640px &times; 1484px</O>,
		"sv": () => <O>Minsta mått är 1320px &times; 742px. <span className="font_weight_500"><i>Rekommenderad:</i></span> 2640px &times; 1484px</O>,
		"no": () => <O>Minimum oppløsning er 1320px × 742px. <span className="font_weight_500"><i>Anbefalt:</i></span> 2640px × 1484px</O>,
		"fr": () => <O>Les dimensions minimales sont 1320px &times; 742px. <span className="font_weight_500"><i>Recommandé:</i></span> 2640px &times; 1484px</O>,
		"nl": () => <O>Minimale afmetingen zijn 1320px &times; 742px. <span className="font_weight_500"><i>Aanbevolen:</i></span> 2640px &times; 1484px</O>,
	},

	"d892acd7d617": {
		"en": () => <O>Thumbnail Image</O>,
		"da": () => <O>Logo/Portræt</O>,
		"de": () => <O>Thumbnail-Bild</O>,
		"sv": () => <O>Miniatyrbild</O>,
		"no": () => <O>Miniatyrbilde</O>,
		"fr": () => <O>Image en vignette</O>,
		"nl": () => <O>Miniatuurafbeelding</O>,
	},

	"7df314ad2c47": {
		"en": () => <O>Minimum dimensions 312px &times; 191px. <span className="font_weight_500"><i>Recommended</i></span> minimum is 624px &times; 382px</O>,
		"da": () => <O>Billedet skal være minimum 312px &times; 191px. <span className="font_weight_500"><i>Anbefalet:</i></span> 624px &times; 382px</O>,
		"de": () => <O>Bildgröße mindestens 312px &times; 191px. <span className="font_weight_500"><i>Empfohlenes</i></span> Minimum: is 624px &times; 382px</O>,
		"sv": () => <O>Minsta mått är 312px &times; 191px. <span className="font_weight_500"><i>Rekommenderad:</i></span> 624px &times; 382px</O>,
		"no": () => <O>Minimum oppløsning er 312px × 191px. <span className="font_weight_500"><i>Anbefalt:</i></span> 624px × 382px</O>,
		"fr": () => <O>Les dimensions minimales sont 312px &times; 191px. <span className="font_weight_500"><i>Recommandé:</i></span> 624px &times; 382px</O>,
		"nl": () => <O>inimale afmetingen zijn 312px &times; 191px. <span className="font_weight_500"><i>Aanbevolen:</i></span> 624px &times; 382px</O>,
	},

	"41668ea051ac": {
		"en": () => <O>Time and Date</O>,
		"da": () => <O>Tid og dato</O>,
		"de": () => <O>Uhrzeit und Datum</O>,
		"sv": () => <O>Tid och datum</O>,
		"no": () => <O>Tid og dato</O>,
		"fr": () => <O>Heure et date</O>,
		"nl": () => <O>Tijd en datum</O>,
	},

	"bbbf51aeebd3": {
		"en": () => <O>Start Date & Time</O>,
		"da": () => <O>Start dato & tid</O>,
		"de": () => <O>Uhrzeit und Datum</O>,
		"sv": () => <O>Startdatum och starttid</O>,
		"no": () => <O>Startdato og tid</O>,
		"fr": () => <O>La date et l'heure du début</O>,
		"nl": () => <O>Begindatum en -uur</O>,
	},

	"40ff7067f8af": {
		"da": () => <O>Startdato og tidspunkt</O>,
		"de": () => <O>Start Datum & Zeit</O>,
		"en": () => <O>Start date & time</O>,
		"fr": () => <O>Date et heure de début</O>,
		"nl": () => <O>Start datum & tijd</O>,
		"no": () => <O>Startdato & tid</O>,
		"sv": () => <O>Startdatum och tid</O>,
	},

	"2da6da5691ac": {
		"en": () => <O>End time</O>,
		"da": () => <O>Sluttidspunkt</O>,
		"de": () => <O>Endzeit</O>,
		"sv": () => <O>Sluttid</O>,
		"no": () => <O>Sluttid</O>,
		"fr": () => <O>L'heure de la fin</O>,
		"nl": () => <O>Eindtijd</O>,
	},

	"56b055a94bcd": {
		"en": () => <O>Social</O>,
		"da": () => <O>Social</O>,
		"de": () => <O>Sozial</O>,
		"sv": () => <O>Social</O>,
		"no": () => <O>Social</O>,
		"fr": () => <O>Social</O>,
		"nl": () => <O>Social</O>,
	},

	"dcc009c837b0": {
		"da": () => <O>Vis chat</O>,
		"de": () => <O>Chat anzeigen</O>,
		"en": () => <O>Show chat</O>,
		"fr": () => <O>Afficher la conversation</O>,
		"nl": () => <O>Toon chat</O>,
		"no": () => <O>Vis chat</O>,
		"sv": () => <O>Visa chatt</O>,
	},

	"6aefe995d60b": {
		"en": () => <O>Would you want people to be able to chat while you are streaming?</O>,
		"da": () => <O>Ønsker du chatten slået til under udsendelsen?</O>,
		"de": () => <O>Möchtest du, dass der Chat während der Übertragung eingeschaltet ist?</O>,
		"sv": () => <O>Vill du ha chatten igång under sändning?</O>,
		"no": () => <O>Vil du ha chatten påskrudd under sendingen?</O>,
		"fr": () => <O>Est ce que vous voulez utiliser les salles de chat pendant la diffusion? </O>,
		"nl": () => <O>Wil je dat mensen kunnen chatten tijdens het uitzenden?</O>,
	},

	"fa0590fc591c": {
		"en": () => <O>Facebook event URL</O>,
		"da": () => <O>URL til facebookbegivenhed</O>,
		"de": () => <O>URL des Facebook-Events</O>,
		"sv": () => <O>URL till Facebook-evenemang</O>,
		"no": () => <O>Facebook-arrangement URL</O>,
		"fr": () => <O>L'URL de l'événement Facebook</O>,
		"nl": () => <O>Facebook evenement URL</O>,
	},

	"667a44f657d4": {
		"en": () => <O>If you have made an event on Facebook for this event, you can add the link here.</O>,
		"da": () => <O>Hvis du har oprettet et facebook event for denne begivenhed, så tilføj linket her (anbefalet).</O>,
		"de": () => <O>Wenn du ein Event auf Facebook für dieses Ereignis erstellt hast, kannst du den Link hier einfügen.</O>,
		"sv": () => <O>Om du har skapat ett evenemang på Facebook för den här händelsen kan du lägga in länken här.</O>,
		"no": () => <O>Hvis du har opprettet et arrangement på Facebook for dette arrangementet, kan du lime inn linken her.</O>,
		"fr": () => <O>Si vous avez crée un événement Facebook pour l'événement vous pouvez ajoutez le lien ici</O>,
		"nl": () => <O>Als u voor dit evenement een evenement op Facebook hebt aangemaakt, kunt u de link hier toevoegen.</O>,
	},

	"a463f5ac74a9": {
		"en": () => <O>Visibility and more</O>,
		"da": () => <O>Synlighed og mere</O>,
		"de": () => <O>Sichtbarkeit und mehr</O>,
		"sv": () => <O>Synlighet m.m.</O>,
		"no": () => <O>Synlighet og mer</O>,
		"fr": () => <O>Visibilité et plus</O>,
		"nl": () => <O>Zichtbaarheid en meer</O>,
	},

	"686a5ec563cb": {
		"en": () => <O>Make this event unsearchable</O>,
		"da": () => <O>Gem event fra søgeresultaterne</O>,
		"de": () => <O>Mach dieses Event unauffindbar</O>,
		"sv": () => <O>Gör detta evenemang osökbart.</O>,
		"no": () => <O>Gjør dette arrangementet usøkbart</O>,
		"fr": () => <O>Rendre cet événement non recherchable</O>,
		"nl": () => <O>Maak dit evenement niet doorzoekbaar</O>,
	},

	"5a4101867321": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Kræv, at brugeren indtaster postnummer sammen med navn og e-mail ved tilmelding</O>,
		"de": () => <O>Benutzer muss bei der Registrierung zusätzlich zur Angabe des Namens und der E-Mail-Adresse auch die Postleitzahl eingeben</O>,
		"en": () => <O>Require user to enter zip code in addition to name and email when signing up</O>,
		"fr": () => <O>Exiger à l'utilisateur de saisir le code postal en plus du nom et de l'adresse e-mail lors de l'inscription</O>,
		"nl": () => <O>Vereis van gebruikers dat ze bij het aanmelden hun postcode invoeren, naast hun naam en e-mailadres</O>,
		"no": () => <O>Krever at brukeren oppgir postnummer i tillegg til navn og e-post ved registrering</O>,
		"sv": () => <O>Kräv att användaren anger postnummer utöver namn och e-postadress vid registrering</O>,
	},

	"afa71afd3183": {
		"en": () => <O>This is a business-to-business event.</O>,
		"da": () => <O>Dette er et b2b-event</O>,
		"de": () => <O>Dies ist ein Business-to-Business-Event.</O>,
		"sv": () => <O>Det här är ett business-to-business-evenemang.</O>,
		"no": () => <O>Dette er et bedrift-til-bedrift arrangement.</O>,
		"fr": () => <O>L'événement est business-to-business</O>,
		"nl": () => <O>This is a business-to-business event.</O>,
	},

	"cdeee8f611de": {
		"da": () => <O>(Tilføj Telefon, Titel og Virksomhed til betaling)</O>,
		"de": () => <O> (Telefon, Titel und Firma zur Kasse hinzufügen)</O>,
		"en": () => <O>(Add phone, Title and Company to checkout)</O>,
		"fr": () => <O>(Ajouter Téléphone, Titre et Entreprise au paiement)</O>,
		"nl": () => <O>(Voeg telefoon, Titel en Bedrijf toe aan de afrekenpagina)</O>,
		"no": () => <O>(Legg til telefon, tittel og bedrift i kassen)</O>,
		"sv": () => <O>(Lägg till telefon, titel och företag i kassan)</O>,
	},

	"12291265be8f": {
		"da": () => <O>Vælg de produkter, som du vil tilbyde i denne stream</O>,
		"de": () => <O>Event Produkte</O>,
		"en": () => <O>Event products</O>,
		"fr": () => <O>Produits d'événement</O>,
		"nl": () => <O>Event producten</O>,
		"no": () => <O>Velg produkter du vil tilby i denne sendingen</O>,
		"sv": () => <O>Välj de produkter som du vill erbjuda i detta webinar</O>,
	},

	"5feb5acf0104": {
		"en": (LL) => <O>You need to <a href={LL("/login")}>login</a> to create an organiser profile</O>,
		"da": (LL) => <O>Du skal <a href={LL("/login")}>logge ind</a> for at lave en arrangørprofil</O>,
		"de": (LL) => <O>Du musst dich <a href={LL("/login")}>einloggen,</a> um ein Veranstalter-Profil zu erstellen</O>,
		"sv": (LL) => <O>Du måste <a href={LL("/login")}>Logga in</a> för att skapa en organisatörsprofil.</O>,
		"no": (LL) => <O>Du må <a href={LL("/login")}>logge inn</a>for å opprette en arrangørprofil</O>,
		"fr": (LL) => <O>Vous devez vous pour crée un profil d'organiseur <a href={LL("/login")}>connecter</a> pour crée un profil d'organiseur</O>,
		"nl": (LL) => <O>U moet <a href={LL("/login")}>inloggen</a> tom een organisatorenprofiel aan te maken</O>,
	},

	"d95544295314": {
		"en": () => {
			return (
				<O>
								Create an organiser profile to start streaming on Zevio. People should know who they will be listening to - and how to find their business.
								</O>
			);
		},
		"da": () => {
			return (
				<O>
				Opret en arrangørprofil for at begynde at sende på Zevio.
				</O>
			);
		},
		"de": () => {
			return (
				<O>
								Erstelle ein Veranstalter-Profil, um mit der Übertragung an Zevio zu beginnen
								</O>
			);
		},
		"sv": () => {
			return (
				<O>
				Tittarna behöver veta vem du är. Med en kostnadsfri organisatörsprofil låter du dem ta reda på mer om ditt företag och du kan hålla alla dina sändningar tillgängliga på en plats.
				</O>
			);
		},
		"no": () => {
			return (
				<O>
				Opprett en arrangørprofil for å komme i gang med å kringkaste til Zevio.
				</O>
			);
		},
		"fr": () => {
			return (
				<O>
				Crée-vous un profile d'organiseur pour commencer á diffuser avec Zevio.
				</O>
			);
		},
		"nl": () => {
			return (
				<O>
				Maak een organisatorenprofiel aan om te beginnen uitzenden op Zevio.
				</O>
			);
		},
	},

	"26e52f983833": {
		"en": (LL) => {
			return (
				<O>
				You can check out why it's a great idea to make an organiser
				page <a href={LL("/organizers")}>here</a>.
				</O>
			);
		},
		"da": (LL) => {
			return (
				<O>
				Klik <a href={LL("/organizers")}>her</a> for at se hvorfor det er en god idé, at lave en arrangørprofil..
				</O>
			);
		},
		"de": (LL) => {
			return (
				<O>
								<a href={LL("/organizers")}>Hier</a> kannst du erfahren, warum es eine gute Idee ist, eine Veranstalter-Seite zu erstellen.
								</O>
			);
		},
		"sv": (LL) => {
			return (
				<O>
				Ta reda på varför det är en bra idé att skapa en organisatörssida <a href={LL("/organizers")}>här.</a>.
				</O>
			);
		},
		"no": (LL) => {
			return (
				<O>
			Du kan sjekke ut hvorfor det er en god idé å lage en arrangørside <a href={LL("/organizers")}>her</a>.
				</O>
			);
		},
		"fr": (LL) => {
			return (
				<O>
				Vous pouvez voir pourquoi c'est une bonne idée de faire une page d'organisation <a href={LL("/organizers")}>ici</a>.
				</O>
			);
		},
		"nl": (LL) => {
			return (
				<O>
				Waarom het een goed idee is om een organiserpagina te maken, kunt u <a href={LL("/organizers")}>hier bekijken</a>.
				</O>
			);
		},
	},

	"758f55ce0199": {
		"da": () => <O>Arrangør gemt</O>,
		"de": () => <O>Veranstalter gespeichert</O>,
		"en": () => <O>Organizer saved</O>,
		"fr": () => <O> Organisateur enregistré</O>,
		"nl": () => <O>Organisator bewaard</O>,
		"no": () => <O>Arrangør lagret</O>,
		"sv": () => <O>Arrangören sparad</O>,
	},

	"1190f1b999a3": {
		"da": () => <O>Opdatering mislykkedes</O>,
		"de": () => <O>Veranstalter Update fehlgeschlagen</O>,
		"en": () => <O>Organizer update failed</O>,
		"fr": () => <O>Échec de la mise à jour de l'organisateur</O>,
		"nl": () => <O>Update organisator mislukt</O>,
		"no": () => <O>Oppdatering av arrangør mislyktes</O>,
		"sv": () => <O>Uppdateringen av arrangören misslyckades</O>,
	},

	"0e65704cef30": {
		"en": () => {
			return (
				<O>The audience need to know who you are. Use your free organiser profile to let them know about your business, and to keep all your streams available in one place.</O>
			);
		},
		"da": () => {
			return (
				<O>Publikum skal vide hvem du er. Opret en gratis arrangørprofil, så seerne kan finde mere om dit virke og følge dig direkte.</O>
			);
		},
		"de": () => {
			return (
				<O>Das Publikum muss wissen, wer du bist. Nutze dein kostenloses Veranstalter-Profil, um über dein Unternehmen zu informieren und alle deine virtuellen Vorträge an einem Ort verfügbar zu machen.</O>
			);
		},
		"sv": () => {
			return (
				<O>Tittarna behöver veta vem du är. Med en kostnadsfri organisatörsprofil låter du dem ta reda på mer om ditt företag och du kan hålla alla dina sändningar tillgängliga på en plats.</O>
			);
		},
		"no": () => {
			return (
				<O>Publikum bør vite hvem du er. Bruk din gratis arrangørprofil for å fortelle dem om virksomheten din, og for å holde alle sendingene dine tilgjengelige på ett sted.</O>
			);
		},
		"fr": () => {
			return (
				<O>Le public a besoin de savoir qui vous êtes. Utilisez votre profil d'organisateur gratuit pour leur faire connaître votre entreprise et pour que toutes vos émissions soient disponibles en un seul endroit.</O>
			);
		},
		"nl": () => {
			return (
				<O>Het publiek moet weten wie u bent. Gebruik uw gratis organiser profiel om hen te laten weten over uw bedrijf, en om al uw uitzendingen beschikbaar te houden op een plaats.</O>
			);
		},
	},

	"a91e953420ea": {
		"en": (LL) => {
			return (
				<O>You can check out why it's a great idea to make an organiser
				page <a href={LL("/organizers")}>here</a>.</O>
			);
		},
		"da": (LL) => {
			return (
				<O>Se hvorfor det er en god ide at lave en arrangørprofil lige <a href={LL("/organizers")}>her</a>.</O>
			);
		},
		"de": (LL) => {
			return (
				<O>Hier kannst du erfahren, warum es eine gute Idee ist, eine Veranstalter-Seite zu erstellen. <a href={LL("/organizers")}>here</a>.</O>
			);
		},
		"sv": (LL) => {
			return (
				<O>Ta reda på varför det är en bra idé att skapa en organisatörssida <a href={LL("/organizers")}>här.</a>.</O>
			);
		},
		"no": (LL) => {
			return (
				<O>Du kan sjekke ut hvorfor det er en god idé å lage en arrangørside her. <a href={LL("/organizers")}>here</a>.</O>
			);
		},
		"fr": (LL) => {
			return (
				<O>Vous pouvez voir pourquoi c'est une bonne idée de faire une page d'organisation <a href={LL("/organizers")}>ici</a>.</O>
			);
		},
		"nl": (LL) => {
			return (
				<O>Waarom het een goed idee is om een organiserpagina te maken, kunt u <a href={LL("/organizers")}>ier bekijken</a>.</O>
			);
		},
	},

	"e2b9fcdc4b34": {
		"da": () => <O>Er du sikker på, at du ønsker at slette:</O>,
		"de": () => <O>Sicher, dass das Produkt gelöscht werden soll:</O>,
		"en": () => <O>Are you sure you want to delete product:</O>,
		"fr": () => <O>Êtes-vous sûr de vouloir supprimer le produit :</O>,
		"nl": () => <O>Weet u zeker dat u het product wilt verwijderen:</O>,
		"no": () => <O>Er du sikker på at du vil fjerne produktet:</O>,
		"sv": () => <O>Är du säker på att du vill ta bort produkten:</O>,
	},

	"3ef630092972": {
		"da": () => <O>for at slette dette produkt</O>,
		"de": () => <O>Zum Löschen des Produkts</O>,
		"en": () => <O>to delete this product</O>,
		"fr": () => <O>pour supprimer ce produit</O>,
		"nl": () => <O>om dit product te verwijderen</O>,
		"no": () => <O>fjern dette produktet</O>,
		"sv": () => <O> för att ta bort den här produkten</O>,
	},

	"a897c82b9b0f": {
		"da": () => <O>Slet produkt</O>,
		"de": () => <O>Produkt löschen</O>,
		"en": () => <O>Delete product</O>,
		"fr": () => <O>Supprimer le produit</O>,
		"nl": () => <O>Verwijder product</O>,
		"no": () => <O>Fjern produkt</O>,
		"sv": () => <O> Ta bort produkt</O>,
	},

	"a7ec1573f2c0": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Se ordrer</O>,
		"de": () => <O>Bestellungen anzeigen</O>,
		"en": () => <O>View orders</O>,
		"fr": () => <O>Voir les commandes</O>,
		"nl": () => <O>Bekijk bestellingen</O>,
		"no": () => <O>Vis bestillinger</O>,
		"sv": () => <O>Visa beställningar</O>,
	},

	"ee3c31047842": {
		"da": () => <O>Rediger produkt</O>,
		"de": () => <O>Produkt bearbeiten</O>,
		"en": () => <O>Edit product</O>,
		"fr": () => <O> Modifier le produit</O>,
		"nl": () => <O>Product bewerken</O>,
		"no": () => <O>Rediger produkt</O>,
		"sv": () => <O>Redigera produkt</O>,
	},

	"2f2051be68e5": {
		"en": () => <O>Are you sure you want to delete event:</O>,
		"da": () => <O>Er du sikker på at du vil slette dette event:</O>,
		"de": () => <O>Sicher, dass dieses Event gelöscht werden soll:</O>,
		"sv": () => <O>Är du säker på att du vill radera evenemang?:</O>,
		"no": () => <O>Er du sikker på at du vil slette dette arrangementet:</O>,
		"fr": () => <O>Vous êtes sûr de vouloir supprimer l'événement:</O>,
		"nl": () => <O>Weet u zeker dat u de gebeurtenis wilt verwijderen:</O>,
	},

	"c015ac1d51a0": {
		"en": () => <O>Edit my page</O>,
		"da": () => <O>Rediger min side</O>,
		"de": () => <O>Meine Seite bearbeiten</O>,
		"sv": () => <O>Redigera min sida</O>,
		"no": () => <O>Rediger min side</O>,
		"fr": () => <O>Modifier ma page</O>,
		"nl": () => <O>Mijn pagina aanpassen</O>,
	},

	"c4959f845fe9": {
		"en": () => <O>Preview</O>,
		"da": () => <O>Forhåndsvisning</O>,
		"de": () => <O>Vorschau</O>,
		"sv": () => <O>Förhandsvisning</O>,
		"no": () => <O>Forhåndsvisning</O>,
		"fr": () => <O>Prévisualisation</O>,
		"nl": () => <O>Voorbeeld</O>,
	},

	"4ada977548f4": {
		"en": () => <O>Save</O>,
		"da": () => <O>Gem</O>,
		"de": () => <O>Speichern</O>,
		"sv": () => <O>Spara</O>,
		"no": () => <O>Lagre</O>,
		"fr": () => <O>Sauvegarder</O>,
		"nl": () => <O>Opslaan</O>,
	},

	"3755f1d802ec": {
		"da": () => <O>Om produktet</O>,
		"de": () => <O>Produktinformation</O>,
		"en": () => <O>Product info</O>,
		"fr": () => <O>Informations sur le produit</O>,
		"nl": () => <O>Productinformatie</O>,
		"no": () => <O>Produktinformasjon</O>,
		"sv": () => <O> Produktinformation</O>,
	},

	"e4736576ba39": {
		"da": () => <O>Overskrift</O>,
		"de": () => <O>Produkt Titel</O>,
		"en": () => <O>Product title</O>,
		"fr": () => <O>Titre du produit</O>,
		"nl": () => <O>Producttitel</O>,
		"no": () => <O>Overskrift</O>,
		"sv": () => <O> Produkt titel</O>,
	},

	"112921d53538": {
		"da": () => <O>Navnet på dit produkt (f.eks. Bogen om Læring)</O>,
		"de": () => <O>Name des Produkts (z. B. Book of Learning)</O>,
		"en": () => <O>The name of your product (e.g. Book of Learning)</O>,
		"fr": () => <O>Le nom de votre produit (par exemple, Livre d'apprentissage)</O>,
		"nl": () => <O>De naam van uw product (bijv. Boek van Leren)</O>,
		"no": () => <O>Navnet på produktet ditt (f.eks. Book of Learning)</O>,
		"sv": () => <O>Produktnamnet (t.ex. Book of Learning)</O>,
	},

	"f1772ab86250": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Page</O>,
		"de": () => <O>Page</O>,
		"en": () => <O>Page</O>,
		"fr": () => <O>Page</O>,
		"nl": () => <O>Page</O>,
		"no": () => <O>Page</O>,
		"sv": () => <O>Page</O>,
	},

	"eeea4ce6d374": {
		"_new": true, // FIXME remove this line when translation is done
		"da": (organizer) => <O>All {organizer.Name} events</O>,
		"de": (organizer) => <O>All {organizer.Name} events</O>,
		"en": (organizer) => <O>All {organizer.Name} events</O>,
		"fr": (organizer) => <O>All {organizer.Name} events</O>,
		"nl": (organizer) => <O>All {organizer.Name} events</O>,
		"no": (organizer) => <O>All {organizer.Name} events</O>,
		"sv": (organizer) => <O>All {organizer.Name} events</O>,
	},

	"60e4f306b6fe": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Loading events...</O>,
		"de": () => <O>Loading events...</O>,
		"en": () => <O>Loading events...</O>,
		"fr": () => <O>Loading events...</O>,
		"nl": () => <O>Loading events...</O>,
		"no": () => <O>Loading events...</O>,
		"sv": () => <O>Loading events...</O>,
	},

	"9253748b4cc3": {
		"en": () => <O>Organiser info</O>,
		"da": () => <O>Arrangørinformation</O>,
		"de": () => <O>Veranstalter-Informationen </O>,
		"sv": () => <O>Organisatörsinfo</O>,
		"no": () => <O>Arrangørinformasjon</O>,
		"fr": () => <O>Info organisateur</O>,
		"nl": () => <O>Organisatoren info</O>,
	},

	"c64b3e3d83ed": {
		"da": () => <O>Arrangører af dette event</O>,
		"de": () => <O>Veranstalter des Events</O>,
		"en": () => <O>Organisers of this event</O>,
		"fr": () => <O>Organisateurs de cet événement</O>,
		"nl": () => <O>Organisatoren van dit evenement</O>,
		"no": () => <O>Arrangører av dette eventet</O>,
		"sv": () => <O>Arrangörer av detta evenemang</O>,
	},

	"073ab3b900d3": {
		"en": () => <O>Name</O>,
		"da": () => <O>Navn</O>,
		"de": () => <O>Name</O>,
		"sv": () => <O>Namn</O>,
		"no": () => <O>Navn</O>,
		"fr": () => <O>Nom</O>,
		"nl": () => <O>Naam</O>,
	},

	"3255ea79d06f": {
		"da": () => <O>Indtast emailadresse</O>,
		"de": () => <O>E-Mail</O>,
		"en": () => <O>E-Mail</O>,
		"fr": () => <O>E-mail</O>,
		"nl": () => <O>E-Mail</O>,
		"no": () => <O>E-post</O>,
		"sv": () => <O> E-post</O>,
	},

	"3dc52a4dfdde": {
		"da": () => <O>Indtast emailadresse igen</O>,
		"de": () => <O>E-Mail wiederholen</O>,
		"en": () => <O>E-Mail again</O>,
		"fr": () => <O>Répétez l'email</O>,
		"nl": () => <O>E-Mail nogmaals</O>,
		"no": () => <O>Gjenta e-post</O>,
		"sv": () => <O> E-post igen</O>,
	},

	"ea39fc5dd84c": {
		"en": () => <O>The name of your organisation (e.g. 'Acme Corporation')</O>,
		"da": () => <O>Navnet på din organisation (f.eks. 'Egon Olsens Bande')</O>,
		"de": () => <O>Name der Organisation (z.B. "Egon Olsens Bande")</O>,
		"sv": () => <O>Namnet på din organisation (t.ex. "Acme AB")</O>,
		"no": () => <O>Navnet på virksomheten din (f.eks. «Hansen Bil»)</O>,
		"fr": () => <O>Le nom de votre organisation (par exemple "Acme Corporation")</O>,
		"nl": () => <O>De naam van je organisatie (bijv. 'Acme Corporation')</O>,
	},

	"8b88951763c5": {
		"en": () => <O>Tagline</O>,
		"da": () => <O>Slogan</O>,
		"de": () => <O>Slogan</O>,
		"sv": () => <O>Slogan</O>,
		"no": () => <O>Slagord</O>,
		"fr": () => <O>Tagline</O>,
		"nl": () => <O>Slogan</O>,
	},

	"c46733caa61e": {
		"en": () => <O>Your organisation tagline (e.g. 'Catching Road Runner since 1949')</O>,
		"da": () => <O>Din organisations slogan (f.eks. 'Jeg har en plan')</O>,
		"de": () => <O>Slogan der Organisation (z.B. "Ich hab' einen Plan")</O>,
		"sv": () => <O>Din organisations slagord (t.ex. "Iögonfallande löpning sedan 1949")</O>,
		"no": () => <O>Organisasjonens slagord (f.eks. «Best på bil siden 1949»)</O>,
		"fr": () => <O>Le slogan de votre organisation (par exemple, "Catching Road Runner since 1949")</O>,
		"nl": () => <O>Slogan van je organisatie (bijv. 'Catchin Road Runner since 1949')</O>,
	},

	"72cb874879d9": {
		"en": () => <O>Contact name</O>,
		"da": () => <O>Kontakt</O>,
		"de": () => <O>Kontakt</O>,
		"sv": () => <O>Kontakt Namn</O>,
		"no": () => <O>Kontaktnavn</O>,
		"fr": () => <O>Nom du contact</O>,
		"nl": () => <O>Naam van contactpersoon</O>,
	},

	"28f54aa63421": {
		"en": () => <O>Who is the main contact of your org.? (e.g. 'Wile E. Coyote')</O>,
		"da": () => <O>Hvem er tovholder på kontoen? (f.eks. 'Egon Olsen')</O>,
		"de": () => <O>Wer ist Koordinator/Koordinatorin des Kontos? (z.B. "Egon Olsen")</O>,
		"sv": () => <O>Vem är din organisations huvudkontakt? (t.ex. "Wile E. Coyote")</O>,
		"no": () => <O>Hvem er kontaktperson i organisasjonen din? (f.eks. «Jan Hansen»)</O>,
		"fr": () => <O>Qui est le contact principal de votre organisation ? (par exemple, "Wile E. Coyote")</O>,
		"nl": () => <O>Wie is de belangrijkste contactpersoon van je organisatie? (bijv. 'Wile E. Coyote')</O>,
	},

	"2c8d2883b7ae": {
		"en": () => <O>About / Description</O>,
		"da": () => <O>Om / beskrivelse</O>,
		"de": () => <O>Über / Beschreibung</O>,
		"sv": () => <O>Om / Beskrivning</O>,
		"no": () => <O>Om / beskrivelse</O>,
		"fr": () => <O>À propos / Description</O>,
		"nl": () => <O>Over / omschrijving</O>,
	},

	"9fa9fd228454": {
		"da": () => <O>Modtaget. Vi har gemt det hele.</O>,
		"de": () => <O>Event gespeichert</O>,
		"en": () => <O>Event saved</O>,
		"fr": () => <O>Événement enregistré</O>,
		"nl": () => <O>Event bewaard</O>,
		"no": () => <O>Arrangement lagret</O>,
		"sv": () => <O>Eventet har sparats</O>,
	},

	"26f92a526703": {
		"da": () => <O>Vi kunne ikke gemme. Øv.</O>,
		"de": () => <O>Veranstaltung speichern fehlgeschlagen</O>,
		"en": () => <O>Event save failed</O>,
		"fr": () => <O>Échec de l'enregistrement de l'événement</O>,
		"nl": () => <O>Opslaan van event mislukt</O>,
		"no": () => <O>Det gikk ikke å lagre arrangementet</O>,
		"sv": () => <O> Det gick inte att spara eventet</O>,
	},

	"2b6dddb3e155": {
		"da": () => <O>Approval requested</O>,
		"de": () => <O>Genehmigung angefragt</O>,
		"en": () => <O>Approval requested</O>,
		"fr": () => <O>Demande d'approbation</O>,
		"nl": () => <O>Goedkeuring gevraagd</O>,
		"no": () => <O>Ber om godkjenning</O>,
		"sv": () => <O> Begäran om godkännande</O>,
	},

	"093aa70d50f4": {
		"da": () => <O>Approval request failed</O>,
		"de": () => <O>Genehmigungsanfrage fehlgeschlagen</O>,
		"en": () => <O>Approval request failed</O>,
		"fr": () => <O>Échec de la demande d'approbation</O>,
		"nl": () => <O>Goedkeuringsverzoek mislukt</O>,
		"no": () => <O>Kunne ikke be om godkjenning</O>,
		"sv": () => <O> Begäran om godkännande misslyckades</O>,
	},

	"93f5e0627022": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Talk aktiveret</O>,
		"de": () => <O>Veranstaltung aktiviert</O>,
		"en": () => <O>Event activated</O>,
		"fr": () => <O>Événement activé</O>,
		"nl": () => <O>Evenement geactiveerd</O>,
		"no": () => <O>Hendelse aktivert</O>,
		"sv": () => <O>Händelse aktiverad</O>,
	},

	"16741f381a42": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Kunne ikke sætte active</O>,
		"de": () => <O>Aktive Änderungsanfrage fehlgeschlagen</O>,
		"en": () => <O>Active change request failed</O>,
		"fr": () => <O>La demande de modification active a échoué</O>,
		"nl": () => <O>Actief wijzigingsverzoek mislukt</O>,
		"no": () => <O>Aktiv endringsforespørsel mislyktes</O>,
		"sv": () => <O>Aktivt ändringsbegäran misslyckades</O>,
	},

	"153a261fdb4c": {
		"da": () => <O>Talk deaktiveret</O>,
		"de": () => <O>Event deaktiviert</O>,
		"en": () => <O>Event deactivated</O>,
		"fr": () => <O>Événement désactivé</O>,
		"nl": () => <O>Evenement gedeactiveerd</O>,
		"no": () => <O>Arrangement deaktivert</O>,
		"sv": () => <O>Evenemang inaktiverat</O>,
	},

	"250bb83e5494": {
		"en": () => <O>Edit event</O>,
		"da": () => <O>Rediger talk</O>,
		"de": () => <O>Event bearbeiten</O>,
		"sv": () => <O>Redigera evenemang</O>,
		"no": () => <O>Endre arrangement</O>,
		"fr": () => <O>Editer l'événement</O>,
		"nl": () => <O>Bewerk evenement</O>,
	},

	"0e3959207f40": {
		"en": () => <O>Status:</O>,
		"da": () => <O>Status:</O>,
		"de": () => <O>Status:</O>,
		"sv": () => <O>Status:</O>,
		"no": () => <O>Status:</O>,
		"fr": () => <O>Statut:</O>,
		"nl": () => <O>Status:</O>,
	},

	"ad68512b0602": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Synlighed:</O>,
		"de": () => <O>Sichtbarkeit:</O>,
		"sv": () => <O>Synlighet:</O>,
		"no": () => <O>Synlighet:</O>,
		"fr": () => <O>Visibilité:</O>,
		"nl": () => <O>Zichtbaarheid:</O>,
		"en": () => <O>Visibility:</O>,
	},

	"ec6437da2f10": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Tilmeldinger:</O>,
		"de": () => <O>Anmeldungen:</O>,
		"en": () => <O>Signups:</O>,
		"fr": () => <O>Inscriptions:</O>,
		"nl": () => <O>Aanmeldingen:</O>,
		"no": () => <O>Påmeldinger:</O>,
		"sv": () => <O>Anmälningar:</O>,
	},

	"da9daca082b6": {
		"en": () => <O>Event info</O>,
		"da": () => <O>Foredragsinformation</O>,
		"de": () => <O>Informationen zum Event</O>,
		"sv": () => <O>Evenemangsinfo</O>,
		"no": () => <O>Arrangementsinformasjon</O>,
		"fr": () => <O>Informations sur l'événement</O>,
		"nl": () => <O>Informatie over het evenement</O>,
	},

	"6e220e94d083": {
		"en": () => <O>Event title</O>,
		"da": () => <O>Hvad skal din stream hedde?</O>,
		"de": () => <O>Event-Name</O>,
		"sv": () => <O>Evenemangstitel</O>,
		"no": () => <O>Arrangementstittel</O>,
		"fr": () => <O>Titre de l'événement</O>,
		"nl": () => <O>Titel van het evenement</O>,
	},

	"664413c82f72": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Titlen på din stream (f.eks. Lær at investere)</O>,
		"de": () => <O>Name der Veranstaltung (z. B. ‘Learn Investing’)</O>,
		"en": () => <O>The name of your event (e.g. &lsquo;Learn Investing&rsquo;)</O>,
		"fr": () => <O>Le nom de votre événement (par exemple, 'Apprendre à investir')</O>,
		"nl": () => <O>De naam van je evenement (bijvoorbeeld 'Leren beleggen')</O>,
		"no": () => <O>Tittelen på arrangementet (e.g. ‘Lær å investere’)</O>,
		"sv": () => <O> Namnet på ditt evenemang (t.ex. "Lär dig investera")</O>,
	},

	"b5a4a80db7d0": {
		"en": () => <O>Speakers</O>,
		"da": () => <O>Streamer</O>,
		"de": () => <O>Referent</O>,
		"sv": () => <O>Talare</O>,
		"no": () => <O>Foredragsholder</O>,
		"fr": () => <O>Docenten</O>,
		"nl": () => <O>Conférenciers</O>,
	},

	"5e6cc340e049": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Streamerens navn (f.eks. Jørgen McMartin)</O>,
		"de": () => <O>Name des Referenten (z. B. ’Donald McChris‘)</O>,
		"en": () => <O>The name of the speaker (e.g. &rsquo;Donald McChris&lsquo;)</O>,
		"fr": () => <O>Le nom de l'intervenant (par exemple, 'Donald McChris')</O>,
		"nl": () => <O>De naam van de spreker (bijvoorbeeld ’Erik Scherder‘)</O>,
		"no": () => <O>Navnet på foredragsholderen (e.g. ’Donald McChris‘)</O>,
		"sv": () => <O> Namnet på talaren (t.ex. 'Donald McChris')</O>,
	},

	"c3af31abad4d": {
		"en": () => <O>Total views</O>,
		"da": () => <O>Seere</O>,
		"de": () => <O>Gesamtzuschauerzahl</O>,
		"sv": () => <O>Totalt antal tittare</O>,
		"no": () => <O>Totalt antall seere</O>,
		"fr": () => <O>Nombre total de vues</O>,
		"nl": () => <O>Totaal bekeken</O>,
	},

	"cb32b9604afd": {
		"en": () => <O>Signups</O>,
		"da": () => <O>Tilmeldte</O>,
		"de": () => <O>Anmeldungen</O>,
		"sv": () => <O>Anmälningar</O>,
		"no": () => <O>Registreringer</O>,
		"fr": () => <O>Inscriptions</O>,
		"nl": () => <O>Inschrijvingen</O>,
	},

	"a62c09e0f363": {
		"en": () => <O>Date</O>,
		"da": () => <O>Dato</O>,
		"de": () => <O>Datum</O>,
		"sv": () => <O>Datum</O>,
		"no": () => <O>Dato</O>,
		"fr": () => <O>Date</O>,
		"nl": () => <O>Datum</O>,
	},

	"3c1ec58542a0": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O> Placeret af</O>,
		"de": () => <O>Plaziert von</O>,
		"en": () => <O>Placed by</O>,
		"fr": () => <O>Placée par</O>,
		"nl": () => <O>Geplaatst door</O>,
		"no": () => <O>Plassert av</O>,
		"sv": () => <O>Placerad av</O>,
	},

	"cc66469768fd": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Samlet total</O>,
		"de": () => <O>Gesamtsumme</O>,
		"en": () => <O>Grand total</O>,
		"fr": () => <O>Montant total</O>,
		"nl": () => <O>Totaalbedrag</O>,
		"no": () => <O>Totalbeløp</O>,
		"sv": () => <O>Totalt belopp</O>,
	},

	"7fe7dc7744c0": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>You have not watched any events</O>,
		"de": () => <O>Noch wurden keine Veranstaltungen angesehen</O>,
		"en": () => <O>You have not watched any events</O>,
		"fr": () => <O>Vous n'avez regardé aucun événement</O>,
		"nl": () => <O>Je hebt geen evenementen bekeken</O>,
		"no": () => <O>Du har ikke sett på noen arrangementer ennå</O>,
		"sv": () => <O> Du har inte kollat på några evenemang</O>,
	},

	"ed7f6f54777a": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Mine streams</O>,
		"de": () => <O>Meine virtuellen Vorträge</O>,
		"en": () => <O>My webinars</O>,
		"fr": () => <O>Mes webinaires</O>,
		"nl": () => <O>Mijn webinars</O>,
		"no": () => <O>Mine webinarer</O>,
		"sv": () => <O>Mina webinar</O>,
	},

	"99e675f6f662": {
		"en": () => <O>Status</O>,
		"da": () => <O>Status</O>,
		"de": () => <O>Status</O>,
		"sv": () => <O>Status</O>,
		"no": () => <O>Status</O>,
		"fr": () => <O>Statut</O>,
		"nl": () => <O>Toestand</O>,
	},

	"655911e75dc6": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Betalingshåndtering</O>,
		"de": () => <O>Zahlung erfassen</O>,
		"en": () => <O>Capture&nbsp;payment</O>,
		"fr": () => <O>Capture de paiement</O>,
		"nl": () => <O>Betaling vastleggen</O>,
		"no": () => <O>Betaling utført</O>,
		"sv": () => <O>Betalning genomförd</O>,
	},

	"3e8f26dba377": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Genindsend faktura</O>,
		"de": () => <O>Rechnung erneut senden</O>,
		"en": () => <O>Re-send invoice</O>,
		"fr": () => <O>Renvoyer la facture</O>,
		"nl": () => <O>Factuur opnieuw verzenden</O>,
		"no": () => <O>Send faktura på nytt</O>,
		"sv": () => <O>Skicka fakturan igen</O>,
	},

	"c9bc65105ad5": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Faktureringsadresse</O>,
		"de": () => <O>Rechnungsadresse</O>,
		"en": () => <O>Billing address</O>,
		"fr": () => <O>Adresse de facturation</O>,
		"nl": () => <O>Factuuradres</O>,
		"no": () => <O>Faktureringsadresse</O>,
		"sv": () => <O>Faktureringsadress</O>,
	},

	"ff32c626ba16": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Leveringsadresse</O>,
		"de": () => <O>Lieferadresse</O>,
		"en": () => <O>Shipping address</O>,
		"fr": () => <O>Adresse de livraison</O>,
		"nl": () => <O>Verzendadres</O>,
		"no": () => <O>Leveringsadresse</O>,
		"sv": () => <O>Leveransadress</O>,
	},

	"5115a6011ece": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Varer</O>,
		"de": () => <O>Artikel</O>,
		"en": () => <O>Items</O>,
		"fr": () => <O>Articles</O>,
		"nl": () => <O>Artikelen</O>,
		"no": () => <O>Varer</O>,
		"sv": () => <O>Varor</O>,
	},

	"cb66ee31bc1a": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Enheder: </O>,
		"de": () => <O>Einheiten: </O>,
		"en": () => <O>Units:&nbsp;</O>,
		"fr": () => <O>Unités: </O>,
		"nl": () => <O>Eenheid: </O>,
		"no": () => <O>Enheter: </O>,
		"sv": () => <O>Enheter: </O>,
	},

	"b3b3db57afe7": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Nettoenhed: </O>,
		"de": () => <O>Einzelpreis netto: </O>,
		"en": () => <O>Unit net:&nbsp;</O>,
		"fr": () => <O>Prix unitaire net: </O>,
		"nl": () => <O>Nettoprijs per eenheid: </O>,
		"no": () => <O>Nettoenhet: </O>,
		"sv": () => <O>Enhet netto: </O>,
	},

	"a3ba85f986de": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Bruttoenhed: </O>,
		"de": () => <O>Einzelpreis brutto: </O>,
		"en": () => <O>Unit gross:&nbsp;</O>,
		"fr": () => <O>Prix unitaire brut: </O>,
		"nl": () => <O>Bruto prijs per eenheid: </O>,
		"no": () => <O>Bruttoenhet: </O>,
		"sv": () => <O>Enhet brutto: </O>,
	},

	"cf74d7fb5cd1": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Kopieret til udklipsholder</O>,
		"de": () => <O>In die Zwischenablage kopiert</O>,
		"en": () => <O>Copied to clipboard</O>,
		"fr": () => <O>Copié dans le presse-papiers</O>,
		"nl": () => <O>Gekopieerd naar het klembord</O>,
		"no": () => <O>Kopiert til utklippstavle</O>,
		"sv": () => <O>Kopierat till urklipp</O>,
	},

	"65c242e152d2": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Kopier til udklipsholder</O>,
		"de": () => <O>In Zwischenablage kopieren</O>,
		"en": () => <O>Copy to clipboard</O>,
		"fr": () => <O>Copier dans le presse-papiers</O>,
		"nl": () => <O>Kopiëren naar klembord</O>,
		"no": () => <O>Kopier til utklippstavle</O>,
		"sv": () => <O>Kopiera till urklipp</O>,
	},

	"a864dbffc25e": {
		"da": () => <O>Telefon</O>,
		"de": () => <O>Telefon</O>,
		"en": () => <O>Phone</O>,
		"fr": () => <O>Téléphone</O>,
		"nl": () => <O>Telefoon</O>,
		"no": () => <O>Telefon</O>,
		"sv": () => <O>Telefon</O>,
	},

	"cb73721c88af": {
		"da": () => <O>Telefon #2</O>,
		"de": () => <O>Telefon #2</O>,
		"en": () => <O>Phone #2</O>,
		"fr": () => <O>Téléphone #2</O>,
		"nl": () => <O>Telefoon #2</O>,
		"no": () => <O>Telefon #2</O>,
		"sv": () => <O>Telefon #2</O>,
	},

	"63c2292795b4": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Premium Streams</O>,
		"de": () => <O>Premium Streams</O>,
		"en": () => <O>Premium Streams</O>,
		"fr": () => <O>Premium Streams</O>,
		"nl": () => <O>Premium Streams</O>,
		"no": () => <O>Premium Streams</O>,
		"sv": () => <O>Premium Streams</O>,
	},

	"71d52a493730": {
		"da": () => <O>No events found with matching criteria</O>,
		"de": () => <O>Keine Veranstaltungen mit den gleichen Kriterien gefunden</O>,
		"en": () => <O>No events found with matching criteria</O>,
		"fr": () => <O>Aucun événement trouvé correspondant aux critères</O>,
		"nl": () => <O>Geen evenementen gevonden met overeenkomende criteria</O>,
		"no": () => <O>Ingen events funnet med matchende kriterier</O>,
		"sv": () => <O>No events found with matching criteria</O>,
	},

	"4e8d504c5145": {
		"da": () => <O>Alle kategorier</O>,
		"de": () => <O>Alle Kategorien</O>,
		"en": () => <O>All categories</O>,
		"fr": () => <O>Toutes les catégories</O>,
		"nl": () => <O>Alle categorieën</O>,
		"no": () => <O>Alle kategorier</O>,
		"sv": () => <O>Alla kategorier</O>,
	},

	"8ab7bc9f20ff": {
		"da": () => <O>Arrangør</O>,
		"de": () => <O>Veranstalter</O>,
		"en": () => <O>Organiser</O>,
		"fr": () => <O>Organisateur</O>,
		"nl": () => <O>Organisator</O>,
		"no": () => <O>Arrangør</O>,
		"sv": () => <O>organisatör</O>,
	},

	"a8cecea7e0c1": {
		"en": () => <O>Language</O>,
		"da": () => <O>Sprog</O>,
		"de": () => <O>Sprache</O>,
		"sv": () => <O>Språk</O>,
		"no": () => <O>Språk</O>,
		"fr": () => <O>Langue</O>,
		"nl": () => <O>Taal</O>,
	},

	"d877c067b255": {
		"en": () => <O>User menu</O>,
		"da": () => <O>Brugermenu</O>,
		"de": () => <O>Benutzermenü</O>,
		"sv": () => <O>Användarmeny</O>,
		"no": () => <O>Brukermeny</O>,
		"fr": () => <O>Menu</O>,
		"nl": () => <O>Menu gebruiker</O>,
	},

	"3df7113a98ee": {
		"en": () => <O>Category</O>,
		"da": () => <O>Kategori</O>,
		"de": () => <O>Kategorie</O>,
		"sv": () => <O>Kategori</O>,
		"no": () => <O>Kategori</O>,
		"fr": () => <O>Catégorie</O>,
		"nl": () => <O>Categorie</O>,
	},

	"7986edc0c2cd": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Stream payment</O>,
		"de": () => <O>Stream payment</O>,
		"en": () => <O>Stream payment</O>,
		"fr": () => <O>Stream payment</O>,
		"nl": () => <O>Stream payment</O>,
		"no": () => <O>Stream payment</O>,
		"sv": () => <O>Stream payment</O>,
	},

	"1d45debd1bf1": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>If you want the audience to pay for access, select a price here.</O>,
		"de": () => <O>If you want the audience to pay for access, select a price here.</O>,
		"en": () => <O>If you want the audience to pay for access, select a price here.</O>,
		"fr": () => <O>If you want the audience to pay for access, select a price here.</O>,
		"nl": () => <O>If you want the audience to pay for access, select a price here.</O>,
		"no": () => <O>If you want the audience to pay for access, select a price here.</O>,
		"sv": () => <O>If you want the audience to pay for access, select a price here.</O>,
	},

	"6ad3024dc4ae": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Event organisers</O>,
		"de": () => <O>Veranstalter</O>,
		"en": () => <O>Event organisers</O>,
		"fr": () => <O>Organisateurs d'événements</O>,
		"nl": () => <O>Event organisatoren</O>,
		"no": () => <O>Arrangører</O>,
		"sv": () => <O> Evenemangsarrangörer</O>,
	},

	"140bc370b1d7": {
		"en": () => <O>Short Description</O>,
		"da": () => <O>Kort beskrivelse</O>,
		"de": () => <O>Kurze Beschreibung</O>,
		"sv": () => <O>Kort beskrivning</O>,
		"no": () => <O>Kort beskrivelse</O>,
		"fr": () => <O>Description sommaire</O>,
		"nl": () => <O>Korte beschrijving</O>,
	},

	"292b1f8a8aa4": {
		"en": () => <O>Let your audience know in one or two sentences what this webinar will give them</O>,
		"da": () => <O>Opsummer hele streamen på én eller to sætninger.</O>,
		"de": () => <O>Sag deinem Publikum in ein bis zwei Sätzen, was die Vorteile dieses virtuellen Vortrages sind.</O>,
		"sv": () => <O>Låt dina tittare veta vad det här webbinaret ger dem med en eller två meningar.</O>,
		"no": () => <O>Fortell publikum i en eller to setninger hva dette webinaret vil gi dem</O>,
		"fr": () => <O>Indiquez à votre public, en une ou deux phrases, ce que ce webinaire lui apportera.</O>,
		"nl": () => <O>Laat uw publiek in één of twee zinnen weten wat dit webinar hen zal opleveren</O>,
	},

	"95087375a31c": {
		"en": () => <O>Description</O>,
		"da": () => <O>Beskrivelse</O>,
		"de": () => <O>Beschreibung</O>,
		"sv": () => <O>Beskrivning</O>,
		"no": () => <O>Beskrivelse</O>,
		"fr": () => <O>Description</O>,
		"nl": () => <O>omschrijving</O>,
	},

	"b9f63ffa7e38": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Fortæl dine potentielle kunder mere om dette produkt</O>,
		"de": () => <O>Beschreibung des Produkts</O>,
		"en": () => <O>Description of your product</O>,
		"fr": () => <O>Description de votre produit</O>,
		"nl": () => <O>Beschrijving van uw product</O>,
		"no": () => <O>Beskrivelse av produktet ditt</O>,
		"sv": () => <O> Beskrivning av din produkt</O>,
	},

	"8bee7e7bcf0c": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Beskrivelsen af dit produkt</O>,
		"de": () => <O>Beschreibung des Produkts</O>,
		"en": () => <O>The description of your product</O>,
		"fr": () => <O>La description de votre produit</O>,
		"nl": () => <O>De beschrijving van uw product</O>,
		"no": () => <O>Produktbeskrivelse</O>,
		"sv": () => <O>The description of your product</O>,
	},

	"b7e0ad415014": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Pris</O>,
		"de": () => <O>Preis</O>,
		"en": () => <O>Price</O>,
		"fr": () => <O>Prix</O>,
		"nl": () => <O>Prijs</O>,
		"no": () => <O>Pris</O>,
		"sv": () => <O>Pris</O>,
	},

	"c08edbee7e21": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Produktets pris i den valgte valuta</O>,
		"de": () => <O>Der Preis des Produkts (z. B. 299)</O>,
		"en": () => <O>The price of the product (e.g. 299)</O>,
		"fr": () => <O>Le prix du produit (par exemple, 299)</O>,
		"nl": () => <O>The price of the product (e.g. 299)</O>,
		"no": () => <O>Produktpris</O>,
		"sv": () => <O>The price of the product (e.g. 299)</O>,
	},

	"8f080726be13": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Valuta</O>,
		"de": () => <O>Währung</O>,
		"en": () => <O>Currency</O>,
		"fr": () => <O>Devise</O>,
		"nl": () => <O>Munteenheid</O>,
		"no": () => <O>Valuta</O>,
		"sv": () => <O> Valuta</O>,
	},

	"32d928e5b040": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Produktbillede</O>,
		"de": () => <O>Produktbild</O>,
		"en": () => <O>Product image</O>,
		"fr": () => <O>Image du produit</O>,
		"nl": () => <O>Product afbeelding</O>,
		"no": () => <O>Produktbilde</O>,
		"sv": () => <O> Produktbild</O>,
	},

	"8d493b5f97c6": {
		"da": () => <O>Mindst 350px × 350px. <span className="font_weight_500">Anbefalet</span> minimum er 700px × 700px</O>,
		"de": () => <O>Mindestabmessungen 350px × 350px. <span className="font_weight_500">Empfohlenes</span> Minimum ist 700px × 700px</O>,
		"en": () => <O>Minimum dimensions 350px &times; 350px. <span className="font_weight_500">Recommended</span> minimum is 700px &times; 700px</O>,
		"fr": () => <O>Dimensions minimales : 350px × 350px. Les <span className="font_weight_500">recommandé</span> minimales sont de 700px × 700px.</O>,
		"nl": () => <O>Minimale afmetingen 350px × 350px. <span className="font_weight_500">Aanbevolen</span> minimum is 700px × 700px</O>,
		"no": () => <O>Minimum oppløsning 350px × 350px. <span className="font_weight_500">Anbefalt</span> 700px × 700px</O>,
		"sv": () => <O>Minsta mått 350px × 350px. <span className="font_weight_500">Rekommenderad</span> minimum är 700px × 700px</O>,
	},

	"ecb68f21ed72": {
		"en": () => <O>Write a detailed description</O>,
		"da": () => <O>Lav en mere uddybende beskrivelse</O>,
		"de": () => <O>Geben Sie eine ausführlichere Beschreibung</O>,
		"sv": () => <O>Gör en mer utförlig beskrivning</O>,
		"no": () => <O>Lag en mer utfyllende beskrivelse</O>,
		"fr": () => <O>L'histoire plus verbeuse de votre organisation</O>,
		"nl": () => <O>Maak een meer uitgebreide beschrijving</O>,
	},

	"0c3f656de4dc": {
		"en": () => <O>Action text</O>,
		"da": () => <O>Opfordring til handling</O>,
		"de": () => <O>Handlungstext</O>,
		"sv": () => <O>Uppmaning till handling</O>,
		"no": () => <O>Handlingstekst</O>,
		"fr": () => <O>Texte d'action</O>,
		"nl": () => <O>Actie tekst</O>,
	},

	"6e182b5a978e": {
		"en": () => <O>Get the viewer to take action. For instance: 'Find my book on Amazon (LINK)'</O>,
		"da": () => <O>Beskriv den handling du ønsker seerne tager. F.eks.: 'Køb produktet på min hjemmeside (link)...'</O>,
		"de": () => <O>Bringe den Zuschauer zum Handeln. Zum Beispiel: „Finde mein Buch bei Amazon ...“</O>,
		"sv": () => <O>Uppmana tittaren till handling. Till exempel: "Hitta min bok på Amazon"</O>,
		"no": () => <O>Få seeren til å handle. For eksempel: "Finn boken min på Norli ..."</O>,
		"fr": () => <O>Incitez le spectateur à agir. Par exemple : " Trouvez mon livre sur Amazon... ".</O>,
		"nl": () => <O>Zet de kijker aan tot actie. Bijvoorbeeld: 'Vind mijn boek in Amazon...'</O>,
	},

	"6bdf1f4dba22": {
		"en": () => <O>Cover image</O>,
		"da": () => <O>Forsidebillede</O>,
		"de": () => <O>Titelbild</O>,
		"sv": () => <O>Omslagsbild för din profilsida</O>,
		"no": () => <O>Forsidebilde på profilsiden din</O>,
		"fr": () => <O>Image de couverture pour votre page de profil</O>,
		"nl": () => <O>Omslagafbeelding voor je profielpagina</O>,
	},

	"b4487ed18e23": {
		"da": () => <O>Yderligere info:</O>,
		"de": () => <O>Spezifikation</O>,
		"en": () => <O>Specifications</O>,
		"fr": () => <O>Spécifications</O>,
		"nl": () => <O>Specificaties</O>,
		"no": () => <O>Spesifikasjoner</O>,
		"sv": () => <O>Specifikationer</O>,
	},

	"a2facc5017a2": {
		"en": () => <O>Cover image for your profile page</O>,
		"da": () => <O>Coverbillede til din profilside (minimum 1320px &times; 400px)</O>,
		"de": () => <O>Titelbild für die Profilseite</O>,
		"sv": () => <O>Profilbild för din profilsida</O>,
		"no": () => <O>Profilbilde for profilsiden din</O>,
		"fr": () => <O>Image de profil pour votre page de profil</O>,
		"nl": () => <O>Profielfoto voor je profielpagina</O>,
	},

	"78928fc0d7cc": {
		"en": () => <O>Minimum dimensions 1320px &times; 400px. <span className="font_weight_500"><i>Recommended</i></span> minimum is 2640px &times; 800px.</O>,
		"da": () => <O>Billedet skal være minimum 1320px &times; 400px. <span className="font_weight_500"><i>Anbefalet:</i></span> 2640px &times; 800px.</O>,
		"de": () => <O>Bildgröße mindestens 1320px &times; 400px. <span className="font_weight_500"><i>Empfohlenes</i></span> Minimum is 2640px &times; 800px.</O>,
		"sv": () => <O>Minsta mått är 1320px &times; 400px. <span className="font_weight_500"><i>Rekommenderad:</i></span> 2640px &times; 800px.</O>,
		"no": () => <O>Minimum oppløsning er 1320px × 400px. <span className="font_weight_500"><i>Anbefalt:</i></span> 2640px × 800px.</O>,
		"fr": () => <O>Les dimensions minimales sont 1320px &times; 400px. <span className="font_weight_500"><i>Recommandé:</i></span> 2640px &times; 800px.</O>,
		"nl": () => <O>Minimale afmetingen zijn 1320px &times; 400px. <span className="font_weight_500"><i>Aanbevolen:</i></span> 2640px &times; 800px.</O>,
	},

	"17a0935ff7fb": {
		"en": () => <O>Profile picture/icon for your profile page</O>,
		"da": () => <O>Profilbillede/ikon</O>,
		"de": () => <O>Profilbild/Ikon</O>,
		"sv": () => <O>Profilbild/ikon för din profilsida</O>,
		"no": () => <O>Profilbilde/ikon for profilsiden dine</O>,
		"fr": () => <O>Image/icône de profil pour votre page de profil</O>,
		"nl": () => <O>Profielfoto/pictogram voor je profielpagina</O>,
	},

	"67ec92516683": {
		"en": () => <O>Minimum dimensions 300px &times; 300px. <span className="font_weight_500"><i>Recommended</i></span> minimum is 600px &times; 600px</O>,
		"da": () => <O>Billedet skal være minimum 300px &times; 300px. <span className="font_weight_500"><i>Anbefalet:</i></span> 600px &times; 600px</O>,
		"de": () => <O>Bildgröße mindestens 300px &times; 300px. <span className="font_weight_500"><i>Empfohlenes</i></span> Minimum is 600px &times; 600px</O>,
		"sv": () => <O>Minsta mått är 300px &times; 300px. <span className="font_weight_500"><i>Rekommenderad:</i></span> 600px &times; 600px</O>,
		"no": () => <O>Minimum oppløsning 300px × 300px. <span className="font_weight_500"><i>Anbefalt:</i></span> 600px × 600px</O>,
		"fr": () => <O>Les dimensions minimales sont 300px &times; 300px. <span className="font_weight_500"><i>Recommandé:</i></span> 600px &times; 600px</O>,
		"nl": () => <O>Minimale afmetingen zijn 300px &times; 300px. <span className="font_weight_500"><i>Aanbevolen:</i></span> 600px &times; 600px</O>,
	},

	"50a6525ab1ca": {
		"en": () => <O>Your links</O>,
		"da": () => <O>Dine links</O>,
		"de": () => <O>Deine Links</O>,
		"sv": () => <O>Dina länkar</O>,
		"no": () => <O>Lenkene dine</O>,
		"fr": () => <O>Vos liens</O>,
		"nl": () => <O>Vos liens</O>,
	},

	"05a5f280e3e5": {
		"en": () => <O>Website</O>,
		"da": () => <O>Hjemmeside</O>,
		"de": () => <O>Homepage</O>,
		"sv": () => <O>Hemsida</O>,
		"no": () => <O>Nettside</O>,
		"fr": () => <O>Site Web</O>,
		"nl": () => <O>Website</O>,
	},

	"ea15125851be": {
		"en": () => <O>The URL of your website, if any.</O>,
		"da": () => <O>Din hjemmesideadresse, hvis du har en.</O>,
		"de": () => <O>Adresse deiner Homepage, falls vorhanden.</O>,
		"sv": () => <O>Din hemsidas URL, om den finns</O>,
		"no": () => <O>Nettsidens URL, hvis aktuelt.</O>,
		"fr": () => <O>L'URL de votre site Web, le cas échéant.</O>,
		"nl": () => <O>De URL van jouw website, indien van toepassing.</O>,
	},

	"3001dd430b33": {
		"en": () => <O>Popular</O>,
		"da": () => <O>Populær</O>,
		"de": () => <O>Beliebt</O>,
		"sv": () => <O>Populär</O>,
		"no": () => <O>Populær</O>,
		"fr": () => <O>Populaire</O>,
		"nl": () => <O>Populair</O>,
	},

	"5b8d183c87e7": {
		"en": () => <O>Live</O>,
		"da": () => <O>Live</O>,
		"de": () => <O>Live</O>,
		"sv": () => <O>Live</O>,
		"no": () => <O>Live</O>,
		"fr": () => <O>Live</O>,
		"nl": () => <O>Live</O>,
	},

	"8c35bf8135a1": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>On demand</O>,
		"de": () => <O>On demand</O>,
		"en": () => <O>On demand</O>,
		"fr": () => <O>On demand</O>,
		"nl": () => <O>On demand</O>,
		"no": () => <O>On demand</O>,
		"sv": () => <O>On demand</O>,
	},

	"321338dbefa1": {
		"en": () => <O>min</O>,
		"da": () => <O>min</O>,
		"de": () => <O>min</O>,
		"sv": () => <O>min</O>,
		"no": () => <O>min</O>,
		"fr": () => <O>min</O>,
		"nl": () => <O>min</O>,
	},

	"fc79296f762e": {
		"en": () => <O>NEW!</O>,
		"da": () => <O>Ny!</O>,
		"de": () => <O>Neu!</O>,
		"sv": () => <O>Nyhet!</O>,
		"no": () => <O>Ny!</O>,
		"fr": () => <O>Nouveau !</O>,
		"nl": () => <O>Nieuw!</O>,
	},

	"d5d9c636a53d": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>timer</O>,
		"de": () => <O>Stunden</O>,
		"en": () => <O>hours</O>,
		"fr": () => <O>Heures</O>,
		"nl": () => <O>Uren</O>,
		"no": () => <O>Timmar</O>,
		"sv": () => <O>Timer</O>,
	},

	"2608d398c9b8": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>timer</O>,
		"de": () => <O>Stunde</O>,
		"en": () => <O>hour</O>,
		"fr": () => <O>Heure</O>,
		"nl": () => <O>Uur</O>,
		"no": () => <O>Time</O>,
		"sv": () => <O>Timme</O>,
	},

	"29cfb015e408": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Klar til at streame</O>,
		"de": () => <O>Bereit zum Streamen</O>,
		"en": () => <O>Ready to stream</O>,
		"fr": () => <O>Prêt à diffuser</O>,
		"nl": () => <O>Klaar om te streamen</O>,
		"no": () => <O>Klar til å streame</O>,
		"sv": () => <O>Klar att strömma</O>,
	},

	"a525e7d92ffc": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Start udsendelsen</O>,
		"de": () => <O>Live gehen</O>,
		"en": () => <O>Go Live</O>,
		"fr": () => <O>Aller en direct</O>,
		"nl": () => <O>Ga live</O>,
		"no": () => <O>Gå direkte</O>,
		"sv": () => <O>Gå live</O>,
	},

	"7ba11e136cf4": {
		"_new": true, // FIXME remove this line when translation is done
		"da": (I18N, currency, price) => <O>Køb adgang for {I18N.floatval_unit(price, 2, currency.toUpperCase())}</O>,
		"de": (I18N, currency, price) => <O>Buy access for {I18N.floatval_unit(price, 2, currency.toUpperCase())}</O>,
		"en": (I18N, currency, price) => <O>Buy access for {I18N.floatval_unit(price, 2, currency.toUpperCase())}</O>,
		"fr": (I18N, currency, price) => <O>Buy access for {I18N.floatval_unit(price, 2, currency.toUpperCase())}</O>,
		"nl": (I18N, currency, price) => <O>Buy access for {I18N.floatval_unit(price, 2, currency.toUpperCase())}</O>,
		"no": (I18N, currency, price) => <O>Buy access for {I18N.floatval_unit(price, 2, currency.toUpperCase())}</O>,
		"sv": (I18N, currency, price) => <O>Buy access for {I18N.floatval_unit(price, 2, currency.toUpperCase())}</O>,
	},

	"fb92cdcc2696": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Dit starttidspunkt er forbipasseret</O>,
		"de": () => <O>Die Startzeit des Streams ist abgelaufen.</O>,
		"en": () => <O>Stream start time has passed.</O>,
		"fr": () => <O>L'heure de début du stream est passée</O>,
		"nl": () => <O>De starttijd van de stream is verstreken.</O>,
		"no": () => <O>Starttiden for strømmen har passert.</O>,
		"sv": () => <O>Streamstarttiden har passerat.</O>,
	},

	"37815adccd60": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Streamen er overstået</O>,
		"de": () => <O>Der Stream ist beendet.</O>,
		"en": () => <O>Stream has ended.</O>,
		"fr": () => <O>Le stream est terminé.</O>,
		"nl": () => <O>De stream is beëindigd.</O>,
		"no": () => <O>Strømmen er avsluttet.</O>,
		"sv": () => <O>Streamen har avslutats.</O>,
	},

	"901f44441719": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Varighed:</O>,
		"de": () => <O>Dauer:</O>,
		"en": () => <O>Duration:</O>,
		"fr": () => <O>Durée :</O>,
		"nl": () => <O>Duur:</O>,
		"no": () => <O>Varighet:</O>,
		"sv": () => <O>Varaktighet:</O>,
	},

	"851e785fe052": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Streaming</O>,
		"de": () => <O>Streamen</O>,
		"en": () => <O>Streaming</O>,
		"fr": () => <O>Streaming</O>,
		"nl": () => <O>Streaming</O>,
		"no": () => <O>Streaming</O>,
		"sv": () => <O>Streaming</O>,
	},

	"ec5621e5f546": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Stop deling</O>,
		"de": () => <O>Teilen beenden</O>,
		"en": () => <O>Stop sharing</O>,
		"fr": () => <O>Arrêter le partage</O>,
		"nl": () => <O>Stoppen met delen</O>,
		"no": () => <O>Stopp deling</O>,
		"sv": () => <O>Sluta dela</O>,
	},

	"dfa87b5e0d63": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Del skærm</O>,
		"de": () => <O>Bildschirm teilen</O>,
		"en": () => <O>Share screen</O>,
		"fr": () => <O>Partager l'écran</O>,
		"nl": () => <O>Scherm delen</O>,
		"no": () => <O>Del skjerm</O>,
		"sv": () => <O>Dela skärm</O>,
	},

	"d4e7bb3a755e": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Du kan gå live om:</O>,
		"de": () => <O>Ihr Stream kann online gehen in:</O>,
		"en": () => <O>Your stream can go live in:</O>,
		"fr": () => <O>Votre diffusion peut commencer dans :</O>,
		"nl": () => <O>Je stream kan live gaan in:</O>,
		"no": () => <O>Streamen din kan starte om:</O>,
		"sv": () => <O> Din stream kan gå live om:</O>,
	},

	"81dd21746c40": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Genstart udsendelsen</O>,
		"de": () => <O>Übertragung neu starten</O>,
		"en": () => <O>Restart broadcast</O>,
		"fr": () => <O>Redémarrer la diffusion</O>,
		"nl": () => <O>Uitzending herstarten</O>,
		"no": () => <O>Start direktesendingen på nytt</O>,
		"sv": () => <O>Starta om sändningen</O>,
	},

	"350448a2c029": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Afslut udsendelsen</O>,
		"de": () => <O>Stream beenden</O>,
		"en": () => <O>End stream</O>,
		"fr": () => <O>Terminer la diffusion</O>,
		"nl": () => <O>Einde stream</O>,
		"no": () => <O>Avsluta strömningen</O>,
		"sv": () => <O>Avslutt strømmen</O>,
	},

	"126ff24c4f14": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Fra</O>,
		"de": () => <O>Ab</O>,
		"en": () => <O>From</O>,
		"fr": () => <O>De</O>,
		"nl": () => <O>Vanaf</O>,
		"no": () => <O>Fra</O>,
		"sv": () => <O>Från</O>,
	},

	"bfd288b1148a": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Til</O>,
		"de": () => <O>Für</O>,
		"en": () => <O>To</O>,
		"fr": () => <O>À</O>,
		"nl": () => <O>Voor</O>,
		"no": () => <O>Til</O>,
		"sv": () => <O>Till</O>,
	},

	"6c68688ac67e": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Forespørg om pris</O>,
		"de": () => <O>Kontaktiere uns für Preise</O>,
		"en": () => <O>Contact for price</O>,
		"fr": () => <O>Contactez-nous pour le prix</O>,
		"nl": () => <O>Neem contact op voor de prijs</O>,
		"no": () => <O>Ta kontakt for pris</O>,
		"sv": () => <O>Kontakta oss för prisinformation</O>,
	},

	"8e3f1514f3a6": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Du er nu tilmeldt </O>,
		"de": () => <O>Anmeldung abgeschlossen</O>,
		"en": () => <O>Signup complete</O>,
		"fr": () => <O>Inscription terminée</O>,
		"nl": () => <O>Aanmelding voltooid</O>,
		"no": () => <O>Registrering fullført</O>,
		"sv": () => <O> Registreringen är klar</O>,
	},

	"74a7c4d1949d": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>I want to receive newsletters from</O>,
		"de": () => <O>I want to receive newsletters from</O>,
		"en": () => <O>I want to receive newsletters from</O>,
		"fr": () => <O>I want to receive newsletters from</O>,
		"nl": () => <O>I want to receive newsletters from</O>,
		"no": () => <O>I want to receive newsletters from</O>,
		"sv": () => <O>I want to receive newsletters from</O>,
	},

	"61df9d613fbb": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>and <span style={{fontWeight:700}}>Zevio</span></O>,
		"de": () => <O>and <span style={{fontWeight:700}}>Zevio</span></O>,
		"en": () => <O>and <span style={{fontWeight:700}}>Zevio</span></O>,
		"fr": () => <O>and <span style={{fontWeight:700}}>Zevio</span></O>,
		"nl": () => <O>and <span style={{fontWeight:700}}>Zevio</span></O>,
		"no": () => <O>and <span style={{fontWeight:700}}>Zevio</span></O>,
		"sv": () => <O>and <span style={{fontWeight:700}}>Zevio</span></O>,
	},

	"90b5b42b47c1": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Ja tak. Jeg vil gerne modtage nyhedsbrev fra arrangøren</O>,
		"de": () => <O>Ich möchte gerne den Newsletter vom Veranstalter erhalten</O>,
		"en": () => <O>I want to receive newsletters from the organizers</O>,
		"fr": () => <O>Je souhaite recevoir la lettre d'information de l'organisateur</O>,
		"nl": () => <O>Ik wil graag de nieuwsbrief ontvangen van de organisator</O>,
		"no": () => <O>Ja takk. Jeg vil gjerne motta nyhetsbrev fra arrangøren</O>,
		"sv": () => <O>Ja tack. Jag vill gärna få nyhetsbrev från arrangören</O>,
	},

	"6376fca4c5ae": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Vi har sendt en kvittering til din mail</O>,
		"de": () => <O>Bestätigung wurde per Mail gesendet</O>,
		"en": () => <O>We have sent a confirmation to your email</O>,
		"fr": () => <O>Nous avons envoyé une confirmation à votre adresse e-mail</O>,
		"nl": () => <O>We hebben een bevestiging naar je e-mail gestuurd</O>,
		"no": () => <O>Vi har sendt deg en bekreftelse på e-post</O>,
		"sv": () => <O> Vi har skickat en bekräftelse till din e-post</O>,
	},

	"7f3c4293d833": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Få mere ud af denne stream</O>,
		"de": () => <O>Das Meiste aus dieser Veranstaltung machen</O>,
		"en": () => <O>Get the most out of this event</O>,
		"fr": () => <O>Profitez au maximum de cet événement</O>,
		"nl": () => <O>Om het meeste uit dit evenement te halen</O>,
		"no": () => <O>For å få mest ut av dette arrangementet</O>,
		"sv": () => <O> För att få ut det mesta av detta evenemang</O>,
	},

	"93d91b5bf205": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Anbefaling til dig</O>,
		"de": () => <O>Empfehlung</O>,
		"en": () => <O>Recommendation  </O>,
		"fr": () => <O>Recommandation</O>,
		"nl": () => <O>raden wij aan:</O>,
		"no": () => <O>Anbefaling til deg</O>,
		"sv": () => <O> Rekommendation till dig</O>,
	},

	"3922c1f95a92": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Måske senere</O>,
		"de": () => <O>Vielleicht später</O>,
		"en": () => <O>Maybe later</O>,
		"fr": () => <O>Peut-être plus tard</O>,
		"nl": () => <O>Misschien later</O>,
		"no": () => <O>Kanskje senere</O>,
		"sv": () => <O> Kanske senare</O>,
	},

	"d4d8acce0f61": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Du er allerede tilmeldt dette event</O>,
		"de": () => <O>Sie haben sich bereits für dieses Ereignis angemeldet</O>,
		"en": () => <O>You have already signed up to this event</O>,
		"fr": () => <O>Vous vous êtes déjà inscrit à cet événement</O>,
		"nl": () => <O>Je hebt je al aangemeld voor dit evenement</O>,
		"no": () => <O>Du har allerede meldt deg på dette arrangementet</O>,
		"sv": () => <O>Du har redan anmält dig till detta evenemang</O>,
	},

	"fbb2f02a7091": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Se mere</O>,
		"de": () => <O>Mehr ansehen</O>,
		"en": () => <O>See more</O>,
		"fr": () => <O>Voir plus</O>,
		"nl": () => <O>Hier meer</O>,
		"no": () => <O>Se mer</O>,
		"sv": () => <O>See more</O>,
	},

	"58c6b6554b62": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Event URL:</O>,
		"de": () => <O>Event URL:</O>,
		"en": () => <O>Event URL:</O>,
		"fr": () => <O>Event URL:</O>,
		"nl": () => <O>Event URL:</O>,
		"no": () => <O>Event URL:</O>,
		"sv": () => <O>Event URL:</O>,
	},

	"8dca54184106": {
		"_new": true, // FIXME remove this line when translation is done
		"da": (minHeight, minWidth, targetImg) => <O>Minimum dimensionerne er {minWidth}px × {minHeight}px (du gav os: {targetImg.width}px × {targetImg.height}px)</O>,
		"de": (minHeight, minWidth, targetImg) => <O>Die Mindestabmessungen betragen {minWidth}px × {minHeight}px (Sie haben hochgeladen {targetImg.width}px × {targetImg.height}px)</O>,
		"en": (minHeight, minWidth, targetImg) => <O>Minimum dimensions is {minWidth}px &times; {minHeight}px (you uploaded {targetImg.width}px &times; {targetImg.height}px)</O>,
		"fr": (minHeight, minWidth, targetImg) => <O>Les dimensions minimales sont de {minWidth}px × {minHeight}px (Vous avez téléchargé {targetImg.width}px × {targetImg.height}px)</O>,
		"nl": (minHeight, minWidth, targetImg) => <O>Minimale afmetingen zijn {minWidth}px × {minHeight}px (die jij hebt geüpload {targetImg.width}px × {targetImg.height}px)</O>,
		"no": (minHeight, minWidth, targetImg) => <O>Minimum oppløsning er {minWidth}px × {minHeight}px (du lastet opp {targetImg.width}px × {targetImg.height}px)</O>,
		"sv": (minHeight, minWidth, targetImg) => <O>Minsta mått är {minWidth}px × {minHeight} px (du laddade upp {targetImg.width}px × {targetImg.height}px)</O>,
	},

	"0fc275d4bae5": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Træk et billede (PNG eller JPEG) herhen</O>,
		"de": () => <O>Bild (PNG oder JPEG) zum Hochladen hineinziehen</O>,
		"en": () => <O>Drag image (PNG or JPEG) to upload</O>,
		"fr": () => <O>Glissez l'image (PNG ou JPEG) pour télécharger</O>,
		"nl": () => <O>Sleep afbeelding (PNG of JPEG) om te uploaden</O>,
		"no": () => <O>Dra bildet (PNG/JPEG) for å laste opp</O>,
		"sv": () => <O> Dra bilden (PNG eller JPEG) för att ladda upp</O>,
	},

	"642d0cacb15b": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Release image to upload</O>,
		"de": () => <O>Bild zum Hochladen freigeben</O>,
		"en": () => <O>Release image to upload</O>,
		"fr": () => <O>Lâchez l'image pour télécharger</O>,
		"nl": () => <O>Afbeelding loslaten om te uploaden</O>,
		"no": () => <O>Slipp bildet for å laste opp</O>,
		"sv": () => <O>Släpp bilden för att ladda upp</O>,
	},

	"f3876c81d8c6": {
		"en": () => <O>Start chatting</O>,
		"da": () => <O>Start chatten</O>,
		"de": () => <O>Chatten beginnen</O>,
		"sv": () => <O>Börja chatta</O>,
		"no": () => <O>Begynn å chatte</O>,
		"fr": () => <O>Commencer à chatter</O>,
		"nl": () => <O>Begin te chatten</O>,
	},

	"d52f9ce7c4e6": {
		"en": (LL) => <O>Be nice. Do not spam. <a target='_blank' rel='noopener noreferrer' href={LL('/terms')}>Terms apply</a></O>,
		"da": (LL) => <O>Opfør dig ordentligt. Snak pænt. <a target='_blank' rel='noopener noreferrer' href={LL('/terms')}>Vilkår gælder.</a></O>,
		"de": (LL) => <O>Bitte nett sein und nicht spammen. <a target='_blank' rel='noopener noreferrer' href={LL('/terms')}>Es gelten die AGBs.</a></O>,
		"sv": (LL) => <O>Var trevlig. Spamma inte. <a target='_blank' rel='noopener noreferrer' href={LL('/terms')}>Villkor gäller.</a></O>,
		"no": (LL) => <O>Vær grei. Ikke spam. <a target='_blank' rel='noopener noreferrer' href={LL('/terms')}>Vilkår gjelder.</a></O>,
		"fr": (LL) => <O>Soyez gentil. N'envoyez pas de spam. <a target='_blank' rel='noopener noreferrer' href={LL('/terms')}>Les conditions s'appliquent.</a></O>,
		"nl": (LL) => <O>Wees aardig. Spam niet. <a target='_blank' rel='noopener noreferrer' href={LL('/terms')}>Voorwaarden zijn van toepassing.</a></O>,
	},

	"e6e251db8cb7": {
		"en": () => <O>Enter your message...</O>,
		"da": () => <O>Indtast din besked...</O>,
		"de": () => <O>Nachricht eingeben...</O>,
		"sv": () => <O>Skriv ditt meddelande...</O>,
		"no": () => <O>Skriv inn meldingen din...</O>,
		"fr": () => <O>Entrez votre message...</O>,
		"nl": () => <O>Geef uw bericht...</O>,
	},

	"575d9e7da3dd": {
		"en": () => <O>Troubleshoot</O>,
		"da": () => <O>Klik her for at få hjælp til problemløsningen</O>,
		"de": () => <O>Fehlerbehebung</O>,
		"sv": () => <O>Felsöka</O>,
		"no": () => <O>Problemløsning</O>,
		"fr": () => <O>Résolution des problèmes</O>,
		"nl": () => <O>Probleemoplossing</O>,
	},

	"bd65593daf39": {
		"da": () => <O>Tilkøb markedsføring hos Zevio</O>,
		"de": () => <O>Hol dir Hilfe für die praktische Anwendung.</O>,
		"en": () => <O>Get help with the practicalities</O>,
		"fr": () => <O>Obtenez de l'aide pour les aspects pratiques</O>,
		"nl": () => <O>Krijg hulp met de praktische zaken</O>,
		"no": () => <O>Få hjelp med det praktiske</O>,
		"sv": () => <O>Få hjälp med det praktiska</O>,
	},

	"2cca2873d303": {
		"da": () => <O>Det er sjovest med seere. Har du brug for hjælp til markedsføringen? Zevio kan hjælpe med at finde de rette seere - skriv til os på hello@zevio.com og så tager vi en snak om mulighederne.</O>,
		"de": () => <O>Schreiben Sie uns an hello@zevio.com, wenn Sie interessiert sind. Bitte geben Sie an, worüber Sie sprechen möchten, und einen Link zu Ihrer Homepage / Firma.</O>,
		"en": () => <O>Write us at hello@zevio.com if you are interested. Please include what you want to talk about, and a link to your homepage / company.</O>,
		"fr": () => <O>Écrivez-nous à hello@zevio.com si vous êtes intéressé. Veuillez inclure le sujet que vous souhaitez aborder, ainsi qu'un lien vers votre page d'accueil / votre entreprise.</O>,
		"nl": () => <O>Schrijf ons op hello@zevio.com als u geïnteresseerd bent. Vermeld waar u het over wilt hebben, en een link naar uw homepage/bedrijf.</O>,
		"no": () => <O>Skriv til oss på hello@zevio.com hvis du er interessert. Ta med det du vil snakke om, og en lenke til hjemmesiden/bedriften din.</O>,
		"sv": () => <O>Skriv till oss på hello@zevio.com om du är intresserad. Ange vad du vill prata om och en länk till din hemsida/företag.</O>,
	},

	"561eac49f490": {
		"da": () => <O>Teknisk hjælp</O>,
		"de": () => <O>Aufnahme Richtlinien</O>,
		"en": () => <O>Streaming guides</O>,
		"fr": () => <O>Guides de diffusion</O>,
		"nl": () => <O>Uitzendgidsen</O>,
		"no": () => <O>Kringkastingsguide</O>,
		"sv": () => <O>Guider för sändningar</O>,
	},

	"9ee65be8835a": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Foredrag på Zevio bør være omkring 30 minutter. Bliver du ved længere, kan forbindelsen ryge.<br />Du kan sende til Zevio i 30 eller 60 FPS i 1080p ved op til 8.5 mpbs. Du kan altid gå live til din kanal i forvejen for at teste forbindelsen. <br /> Du skal forbinde med minimum TLS 1.2 og for lavere latency anbefaler vi et keyframe interval på 2 (eller 1). Vi anvender h.264.</O>,
		"de": () => <O>Streaming zu Zevio: Die Auflösung kann bis zu 1080p und die Bitrate bis zu 8,5 Mbps betragen. FPS: 30 oder 60TLS Minimum: 1.2Video: Stellen Sie IDR/Keyframe auf ein 2-Sekunden-Intervall ein (oder 1 Sekunde, für eine noch geringere End-to-End-Latenz).H.264-Level: MainScene change: Aus (bevorzugt)Chroma-Subsample: YUV420PCABAC: BevorzugtAudio:Codec: AAC (LC)Bitrate: 96 Kbps bis 320 KbpsAbtastrate: 44,1 Khz oder 48 Khz (am besten passend zum Audiofluss Ihrer Produktion)Kanäle: Maximal 2 - Stereo (1: Mono oder 2: Stereo-Audiokanal-Unterstützung) Verwenden Sie eine konstante Bitrate, keine variable Bitrate. Verwenden Sie progressive Signale. Als Faustregel gilt, dass Sie 50 % mehr Bandbreite als erforderlich zuweisen.</O>,
		"en": () => <O>Streaming to Zevio:

Resolution can be up to 1080p and bitrate can be up to 8.5 Mbps.

FPS: 30 or 60

TLS minimum: 1.2

Video:

Set IDR/Keyframe to a 2-second interval (or 1 second, for even lower end-to-end latency).
H.264 level: Main
Scene change: Off (preferred)
Chroma subsample: YUV420P
CABAC: Preferred
Audio:

Codec: AAC (LC)
Bitrate: 96 Kbps to 320 Kbps
Sample rate: 44.1 Khz or 48 Khz (it is best to match your production audio flow)
Channels: Maximum 2 - Stereo (1: mono or 2: stereo audio channel support)
Use constant bitrate, not Variable bitrate.
Use progressive signals.

As a rule of thumb allocate 50% more bandwidth than required.</O>,
		"fr": () => <O>Diffusion vers Zevio : La résolution peut aller jusqu'à 1080p et le débit peut atteindre 8,5 Mbps. IPS : 30 ou 60. TLS minimum : 1.2. Vidéo : Réglez l'IDR/Cadre clé sur un intervalle de 2 secondes (ou 1 seconde pour une latence de bout en bout encore plus faible). Niveau H.264 : Principal. Changement de scène : Désactivé (préféré). Sous-échantillonnage chromatique : YUV420PC. CABAC : Préféré. Audio : Codec : AAC (LC). Débit : De 96 Kbps à 320 Kbps. Fréquence d'échantillonnage : 44,1 Khz ou 48 Khz (il est préférable de correspondre à votre flux audio de production). Canaux : Maximum 2 - Stéréo (1 : mono ou 2 : support de canal audio stéréo). Utilisez un débit binaire constant, pas un débit binaire variable. Utilisez des signaux progressifs. En règle générale, allouez 50 % de bande passante supplémentaire par rapport aux besoins.</O>,
		"nl": () => <O>Streaming naar Zevio: resolutie kan tot 1080p zijn en bitrate kan tot 8,5 Mbps zijn.FPS: 30 of 60TLS minimum: 1,2Video:Stel IDR/keyframe in op een interval van 2 seconden (of 1 seconde, voor nog lagere end-to -einde latentie).H.264-niveau: MainScene-wijziging: Uit (bij voorkeur)Chroma-subsample: YUV420PCABAC: PreferredAudio:Codec: AAC (LC)Bitrate: 96 Kbps tot 320 KbpsBemonsteringssnelheid: 44,1 Khz of 48 Khz (het is het beste om te matchen uw productie-audiostroom)Kanalen: Maximaal 2 - Stereo (1: mono of 2: ondersteuning voor stereo-audiokanalen)Gebruik een constante bitsnelheid, geen variabele bitsnelheid.Gebruik progressieve signalen.Wijs als vuistregel 50% meer bandbreedte toe dan vereist.</O>,
		"no": () => <O>Streaming to Zevio:

Resolution can be up to 1080p and bitrate can be up to 8.5 Mbps.

FPS: 30 or 60

TLS minimum: 1.2

Video:

Set IDR/Keyframe to a 2-second interval (or 1 second, for even lower end-to-end latency).
H.264 level: Main
Scene change: Off (preferred)
Chroma subsample: YUV420P
CABAC: Preferred
Audio:

Codec: AAC (LC)
Bitrate: 96 Kbps to 320 Kbps
Sample rate: 44.1 Khz or 48 Khz (it is best to match your production audio flow)
Channels: Maximum 2 - Stereo (1: mono or 2: stereo audio channel support)
Use constant bitrate, not Variable bitrate.
Use progressive signals.

As a rule of thumb allocate 50% more bandwidth than required.</O>,
		"sv": () => <O>Streaming till Zevio: Upplösningen kan vara upp till 1080p och bithastigheten kan vara upp till 8,5 Mbps.FPS: 30 eller 60TLS minimum: 1.2 Video: Ställ in IDR/Keyframe på ett 2-sekundersintervall (eller 1 sekund, för ännu lägre slut till -slut latency). H.264-nivå: Huvudscenändring: Av (föredragen)Chroma-delsample: YUV420PCABAC: PreferredAudio:Codec: AAC (LC)Bitrate: 96 Kbps till 320 KbpsSamplingshastighet: 44,1 Khz eller 48 Khz är bäst att matcha (det är bäst att matcha) ditt produktionsljudflöde)Kanaler: Max 2 - Stereo (1: mono eller 2: stöd för stereoljudkanal) Använd konstant bithastighet, inte variabel bithastighet. Använd progressiva signaler. Som en tumregel allokera 50 % mer bandbredd än vad som krävs.</O>,
	},

	"75558df170af": {
		"en": () => <O>Having trouble viewing this event?</O>,
		"da": () => <O>Har du brug for hjælp?</O>,
		"de": () => <O>Probleme damit, dieses Event anzuschauen? </O>,
		"sv": () => <O>Har du problem att visa detta evenemang?</O>,
		"no": () => <O>Har du problemer med å se denne hendelsen?</O>,
		"fr": () => <O>Vous avez des difficultés à visualiser cet événement ?</O>,
		"nl": () => <O>Heb je problemen met het bekijken van dit evenement?</O>,
	},

	"ec51c19bba8b": {
		"en": () => <O>Sign Up</O>,
		"da": () => <O>Tilmeld dig her</O>,
		"de": () => <O>Anmelden</O>,
		"sv": () => <O>Anmäl dig</O>,
		"no": () => <O>Registrer deg</O>,
		"fr": () => <O>S'inscrire</O>,
		"nl": () => <O>Schrijf je in</O>,
	},

	"1e4bdbb24c3d": {
		"en": () => <O>Organized by:</O>,
		"da": () => <O>Arrangeret af:</O>,
		"de": () => <O>Veranstaltet von:</O>,
		"sv": () => <O>Arrangerat av:</O>,
		"no": () => <O>Arrangert av:</O>,
		"fr": () => <O>Organisé par :</O>,
		"nl": () => <O>Georganiseerd door:</O>,
	},

	"c33d2118f065": {
		"en": () => <O>Subscribers</O>,
		"da": () => <O>Følgere</O>,
		"de": () => <O>Abonnenten</O>,
		"sv": () => <O>Följare</O>,
		"no": () => <O>Abonnenter</O>,
		"fr": () => <O>Abonnés</O>,
		"nl": () => <O>Abonnees</O>,
	},

	"1ab4b4e94452": {
		"_new": true, // FIXME remove this line when translation is done
		"da": (I18N, currency, price) => <O>Køb adgang for {I18N.floatval_unit(price, 2, currency.toLocaleUpperCase())}</O>,
		"de": (I18N, currency, price) => <O>Buy access for {I18N.floatval_unit(price, 2, currency.toLocaleUpperCase())}</O>,
		"en": (I18N, currency, price) => <O>Buy access for {I18N.floatval_unit(price, 2, currency.toLocaleUpperCase())}</O>,
		"fr": (I18N, currency, price) => <O>Buy access for {I18N.floatval_unit(price, 2, currency.toLocaleUpperCase())}</O>,
		"nl": (I18N, currency, price) => <O>Buy access for {I18N.floatval_unit(price, 2, currency.toLocaleUpperCase())}</O>,
		"no": (I18N, currency, price) => <O>Buy access for {I18N.floatval_unit(price, 2, currency.toLocaleUpperCase())}</O>,
		"sv": (I18N, currency, price) => <O>Buy access for {I18N.floatval_unit(price, 2, currency.toLocaleUpperCase())}</O>,
	},

	"e68e6fbd7217": {
		"en": () => <O>Visit webite</O>,
		"da": () => <O>Besøg hjemmeside</O>,
		"de": () => <O>Webseite besuchen</O>,
		"sv": () => <O>Besök hemsidan</O>,
		"no": () => <O>Besøk nettside</O>,
		"fr": () => <O>Visitez le site web</O>,
		"nl": () => <O>Bezoek de website</O>,
	},

	"4e71af808d39": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Vi kan ikke finde arrangøren</O>,
		"de": () => <O>Veranstalter nicht gefunden</O>,
		"en": () => <O>Organiser not found</O>,
		"fr": () => <O>Organisateur introuvable</O>,
		"nl": () => <O>Organisator niet gevonden</O>,
		"no": () => <O>Arrangør ikke funnet</O>,
		"sv": () => <O>Arrangör ej hittad</O>,
	},

	"130c2474368f": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Denne arrangør eksisterer ikke.</O>,
		"de": () => <O>Dieser Veranstalter existiert nicht</O>,
		"en": () => <O>This organiser does not exist.</O>,
		"fr": () => <O>Cet organisateur n'existe pas.</O>,
		"nl": () => <O>Deze organisator bestaat niet.</O>,
		"no": () => <O>Denne arrangøren eksisterer ikke.</O>,
		"sv": () => <O>Denna arrangör existerar inte.</O>,
	},

	"03d1c8e103f7": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Udforsk streams</O>,
		"de": () => <O>Streams erkunden</O>,
		"en": () => <O>Explore streams</O>,
		"fr": () => <O>Explorer les diffusions en direct</O>,
		"nl": () => <O>Verken streams</O>,
		"no": () => <O>Utforsk strømmer</O>,
		"sv": () => <O>Utforska strömmar</O>,
	},

	"57d6354c4f92": {
		"en": () => <O>Subscribed</O>,
		"da": () => <O>Følger</O>,
		"de": () => <O>Abonniert</O>,
		"sv": () => <O>Följde</O>,
		"no": () => <O>Abonnert</O>,
		"fr": () => <O>Abonné</O>,
		"nl": () => <O>Geabonneerd</O>,
	},

	"31ea56011bd1": {
		"en": () => <O>Subscribe</O>,
		"da": () => <O>Følg</O>,
		"de": () => <O>Abonnieren</O>,
		"sv": () => <O>Följ</O>,
		"no": () => <O>Abonner</O>,
		"fr": () => <O>S'abonner</O>,
		"nl": () => <O>Abonneren</O>,
	},

	"34a9edb43c2d": {
		"_new": true, // FIXME remove this line when translation is done
		"da": (LL) => <O>Se i  <span className="font_weight_500"><a href={LL('/mywebinars')}>mine streams</a></span></O>,
		"de": (LL) => <O>Siehe unter <span className="font_weight_500"><a href={LL('/mywebinars')}>Meine virtuellen Vorträge</a></span></O>,
		"en": (LL) => <O>View under <span className="font_weight_500"><a href={LL('/mywebinars')}>My Events</a></span></O>,
		"fr": (LL) => <O>Afficher en dessous <span className="font_weight_500"><a href={LL('/mywebinars')}>My Events</a></span></O>,
		"nl": (LL) => <O>Bekijk onder <span className="font_weight_500"><a href={LL('/mywebinars')}>My Events</a></span></O>,
		"no": (LL) => <O>Se under <span className="font_weight_500"><a href={LL('/mywebinars')}>My Events</a></span></O>,
		"sv": (LL) => <O> Se under <span className="font_weight_500"><a href={LL('/mywebinars')}>My Events</a></span></O>,
	},

	"324eae06a3c0": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Processerer tilmelding</O>,
		"de": () => <O>Bearbeitung der Anmeldung</O>,
		"en": () => <O>Processing registration</O>,
		"fr": () => <O>Traitement de l'inscription</O>,
		"nl": () => <O>Verwerking registratie</O>,
		"no": () => <O>Behandler registrering</O>,
		"sv": () => <O>Processing registration</O>,
	},

	"138c7b179a54": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Vil du gerne følge</O>,
		"de": () => <O>Möchtest du folgen</O>,
		"en": () => <O>Would you like to follow</O>,
		"fr": () => <O>Souhaitez-vous suivre?</O>,
		"nl": () => <O>Wil je volgen?</O>,
		"no": () => <O>Vil du følge?</O>,
		"sv": () => <O>Vill du följa?</O>,
	},

	"9d1364fe1c13": {
		"_new": true, // FIXME remove this line when translation is done
		"da": (I18N, accessProduct, currency) => <O>Køb adgang for {I18N.floatval_unit(accessProduct.price[currency], 2, currency.toLocaleUpperCase())}</O>,
		"de": (I18N, accessProduct, currency) => <O>Buy access for {I18N.floatval_unit(accessProduct.price[currency], 2, currency.toLocaleUpperCase())}</O>,
		"en": (I18N, accessProduct, currency) => <O>Buy access for {I18N.floatval_unit(accessProduct.price[currency], 2, currency.toLocaleUpperCase())}</O>,
		"fr": (I18N, accessProduct, currency) => <O>Buy access for {I18N.floatval_unit(accessProduct.price[currency], 2, currency.toLocaleUpperCase())}</O>,
		"nl": (I18N, accessProduct, currency) => <O>Buy access for {I18N.floatval_unit(accessProduct.price[currency], 2, currency.toLocaleUpperCase())}</O>,
		"no": (I18N, accessProduct, currency) => <O>Buy access for {I18N.floatval_unit(accessProduct.price[currency], 2, currency.toLocaleUpperCase())}</O>,
		"sv": (I18N, accessProduct, currency) => <O>Buy access for {I18N.floatval_unit(accessProduct.price[currency], 2, currency.toLocaleUpperCase())}</O>,
	},

	"e731f337c47b": {
		"da": () => <O>Du er allerede tilmeldt denne stream.</O>,
		"de": () => <O>You are signed up for this stream</O>,
		"en": () => <O>You are signed up for this stream</O>,
		"fr": () => <O>You are signed up for this stream</O>,
		"nl": () => <O>You are signed up for this stream</O>,
		"no": () => <O>You are signed up for this stream</O>,
		"sv": () => <O>You are signed up for this stream</O>,
	},

	"3cf62d4ffa7f": {
		"en": () => <O>Start here</O>,
		"da": () => <O>Start her</O>,
		"de": () => <O>Jetzt anschauen</O>,
		"sv": () => <O>Anmäl dig här</O>,
		"no": () => <O>Start her</O>,
		"fr": () => <O>Regardez maintenant</O>,
		"nl": () => <O>Kijk nu</O>,
	},

	"aa929453ef05": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Tilmeld dig</O>,
		"de": () => <O>Anmeldung zur Veranstaltung</O>,
		"en": () => <O>Sign up for event</O>,
		"fr": () => <O>S'inscrire à l'événement</O>,
		"nl": () => <O>Meld je aan voor een event</O>,
		"no": () => <O>Meld deg på arrangementet</O>,
		"sv": () => <O>Anmäl dig till evenemanget</O>,
	},

	"b81febafba69": {
		"en": () => <O>Event starting in:</O>,
		"da": () => <O>Udsendelsen starter om:</O>,
		"de": () => <O>Der virtuelle Vortrag beginnt in: </O>,
		"sv": () => <O>Evenemanget börjar om:</O>,
		"no": () => <O>Arrangementet starter om:</O>,
		"fr": () => <O>Événement débutant à : </O>,
		"nl": () => <O>Het evenement begint in:</O>,
	},

	"191da78e46b4": {
		"en": () => <O>About</O>,
		"da": () => <O>Om</O>,
		"de": () => <O>Über</O>,
		"sv": () => <O>Om</O>,
		"no": () => <O>Om</O>,
		"fr": () => <O>À propos</O>,
		"nl": () => <O>Over</O>,
	},

	"87d6dbcd12be": {
		"en": () => <O>See less</O>,
		"da": () => <O>Se mindre</O>,
		"de": () => <O>Weniger sehen</O>,
		"sv": () => <O>Se mindre</O>,
		"no": () => <O>Se mindre</O>,
		"fr": () => <O>Voir moins</O>,
		"nl": () => <O>Zie minder</O>,
	},

	"42295cbdeb74": {
		"en": () => <O>Read more</O>,
		"da": () => <O>Læs mere</O>,
		"de": () => <O>Mehr lesen</O>,
		"sv": () => <O>Läs mer</O>,
		"no": () => <O>Les mer</O>,
		"fr": () => <O>Lire plus</O>,
		"nl": () => <O>Lees meer</O>,
	},

	"9a59135af787": {
		"en": () => <O>Loading data...</O>,
		"da": () => <O>Henter info...</O>,
		"de": () => <O>Daten werden geladen...</O>,
		"sv": () => <O>Laddar...</O>,
		"no": () => <O>Laster data...</O>,
		"fr": () => <O>Chargement des données ...</O>,
		"nl": () => <O>Gegevens laden...</O>,
	},

	"186c70ed32c0": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Free to watch</O>,
		"de": () => <O>Free to watch</O>,
		"en": () => <O>Free to watch</O>,
		"fr": () => <O>Free to watch</O>,
		"nl": () => <O>Free to watch</O>,
		"no": () => <O>Free to watch</O>,
		"sv": () => <O>Free to watch</O>,
	},

	"4b72f3be9126": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Initialiserer stream</O>,
		"de": () => <O>Initialisiere Stream...</O>,
		"en": () => <O>Initializing stream...</O>,
		"fr": () => <O>Initialisation du flux...</O>,
		"nl": () => <O>Initialiseren van de stream...</O>,
		"no": () => <O>Initialiserer strøm...</O>,
		"sv": () => <O>Initierar strömning...</O>,
	},

	"f6a8a61d7e87": {
		"_new": true, // FIXME remove this line when translation is done
		"da": (eventId) => <O>Dit event er ikke sat til LIVE. Kontrollér dine indstillinger i  <a target="_blank" rel="noopener noreferrer" href={"https://lgcy.zevio.com/broadcastended.php?=" + eventId}>'post production'</a>.</O>,
		"de": (eventId) => <O>Ihre Veranstaltung ist nicht als Live-Event festgelegt. Bitte überprüfen Sie Ihre Einstellungen unter <a target="_blank" rel="noopener noreferrer" href={"https://lgcy.zevio.com/broadcastended.php?=" + eventId}>'post production'</a>.</O>,
		"en": (eventId) => <O>Your event is not set to live. Please check your settings in <a target="_blank" rel="noopener noreferrer" href={"https://lgcy.zevio.com/broadcastended.php?=" + eventId}>'post production'</a>.</O>,
		"fr": (eventId) => <O>Votre événement n'est pas configuré en direct. Veuillez vérifier vos paramètres dans <a target="_blank" rel="noopener noreferrer" href={"https://lgcy.zevio.com/broadcastended.php?=" + eventId}>'post production'</a>.</O>,
		"nl": (eventId) => <O>Uw evenement is niet ingesteld om live te gaan. Controleer uw instellingen in <a target="_blank" rel="noopener noreferrer" href={"https://lgcy.zevio.com/broadcastended.php?=" + eventId}>'post production'</a>.</O>,
		"no": (eventId) => <O>Ditt arrangement er ikke satt opp som direktesending. Vennligst sjekk innstillingene dine i <a target="_blank" rel="noopener noreferrer" href={"https://lgcy.zevio.com/broadcastended.php?=" + eventId}>'post production'</a>.</O>,
		"sv": (eventId) => <O>Ditt evenemang är inte inställt som live. Vänligen kontrollera dina inställningar i <a target="_blank" rel="noopener noreferrer" href={"https://lgcy.zevio.com/broadcastended.php?=" + eventId}>'post production'</a>.</O>,
	},

	"81cb9b5a1284": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Vi understøtter ikke din browser i øjeblikket. Brug enten Chrome, Firefox, Edge, Safari, Opera eller Android Chrome eller iOS Safari.</O>,
		"de": () => <O>Leider wird Ihr Browser derzeit nicht unterstützt. Wir unterstützen Chrome, Firefox, Edge, Safari, Opera sowie Android Chrome und iOS Safari.</O>,
		"en": () => <O>Unfortunately Your browser is not supported at this point. We support Chrome, Firefox, Edge, Safari, Opera, as well as Android Chrome and iOS Safari.</O>,
		"fr": () => <O>Malheureusement, votre navigateur n'est pas pris en charge pour le moment. Nous prenons en charge Chrome, Firefox, Edge, Safari, Opera, ainsi que Chrome pour Android et Safari pour iOS.</O>,
		"nl": () => <O>Helaas wordt uw browser op dit moment niet ondersteund. We ondersteunen Chrome, Firefox, Edge, Safari, Opera, evenals Android Chrome en iOS Safari.</O>,
		"no": () => <O>Dessverre støttes ikke nettleseren din på dette tidspunktet. Vi støtter Chrome, Firefox, Edge, Safari, Opera samt Android Chrome og iOS Safari.</O>,
		"sv": () => <O>Tyvärr stöds inte din webbläsare för tillfället. Vi stöder Chrome, Firefox, Edge, Safari, Opera samt Android Chrome och iOS Safari.</O>,
	},

	"68c3b056b236": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Du skal være organiser for det event du gerne vil sende på.</O>,
		"de": () => <O>Sie müssen Veranstalter des Events sein, das Sie übertragen möchten.</O>,
		"en": () => <O>You need to be an organizer for the event you want to broadcast.</O>,
		"fr": () => <O>Vous devez être organisateur de l'événement que vous souhaitez diffuser.</O>,
		"nl": () => <O>U moet een organisator zijn van het evenement dat u wilt uitzenden.</O>,
		"no": () => <O>Du må være arrangør for arrangementet du ønsker å sende.</O>,
		"sv": () => <O>Du må vara arrangör för evenemanget du vill sända.</O>,
	},

	"dd428578b12e": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Initialiserer udsendelse</O>,
		"de": () => <O>Übertragung wird gestartet...</O>,
		"en": () => <O>Initializing broadcast...</O>,
		"fr": () => <O>Initialisation de la diffusion...</O>,
		"nl": () => <O>Initialiseren van de uitzending...</O>,
		"no": () => <O>Initialiserer sending...</O>,
		"sv": () => <O>Initierar sändning...</O>,
	},

	"a655e0d28183": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Ryd</O>,
		"de": () => <O>Löschen</O>,
		"en": () => <O>Clear</O>,
		"fr": () => <O>Effacer</O>,
		"nl": () => <O>Wissen</O>,
		"no": () => <O>Tøm</O>,
		"sv": () => <O>Rensa</O>,
	},

	"b61ecaa11f44": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Alle events</O>,
		"de": () => <O>Alle Veranstaltungen</O>,
		"en": () => <O>All events</O>,
		"fr": () => <O>Tous les événements</O>,
		"nl": () => <O>Alle gebeurtenissen</O>,
		"no": () => <O>Alle arrangementer</O>,
		"sv": () => <O>Alla evenemang</O>,
	},

	"3a32015025a8": {
		"en": () => <O>Most watched</O>,
		"da": () => <O>Mest sete</O>,
		"de": () => <O>Meist gesehen</O>,
		"sv": () => <O>Mest sedda</O>,
		"no": () => <O>Mest sett</O>,
		"fr": () => <O>Les plus regardés</O>,
		"nl": () => <O>Meest bekeken</O>,
	},

	"d49b608e94a3": {
		"en": () => <O>Hot deals</O>,
		"da": () => <O>Tilbud</O>,
		"de": () => <O>Angebote</O>,
		"sv": () => <O>Heta erbjudanden</O>,
		"no": () => <O>Hete tilbud</O>,
		"fr": () => <O>Offres spéciales</O>,
		"nl": () => <O>Topaanbiedingen</O>,
	},

	"f09acef726d8": {
		"en": () => <O>Select your platform:</O>,
		"da": () => <O>Vælg platform:</O>,
		"de": () => <O>Plattform wählen</O>,
		"sv": () => <O>Välj din plattform:</O>,
		"no": () => <O>Velg din plattform:</O>,
		"fr": () => <O>Sélectionnez votre plateforme :</O>,
		"nl": () => <O>Kies uw platform:</O>,
	},

	"d5b46bb9ba99": {
		"en": () => <O>Can I watch streams on demand?</O>,
		"da": () => <O>Kan jeg se foredrag, hvis jeg ikke kan være med når de er live?</O>,
		"de": () => <O>Kann ich mir die Gespräche nochmal ansehen, wenn sie vorbei sind oder wenn ich mich verspätet habe?</O>,
		"sv": () => <O>Kan jag lyssna på föredragen vid senare tillfälle om jag blir försenad?</O>,
		"no": () => <O>Kan jeg se foredraget på nytt dersom jeg blir forsinket?</O>,
		"fr": () => <O>Puis-je regarder les conférences après qu'elles aient eu lieu si je suis en retard ?</O>,
		"nl": () => <O>Kan ik lezingen terugkijken als ik niet live aanwezig kan zijn?</O>,
	},

	"e0e5d6d2b7f9": {
		"en": () => {
			return (
				<O>Yes, but for a limited time.<br />
				Our speakers and organisers decide how long an event will be available.<br />
				We aspire to keep all events viewable for at least 48 hours after we go live. However, this is entirely
				at the discretion of the organisers.</O>
			);
		},
		"da": () => {
			return (
				<O>Ja, men i en begrænset periode.<br/>
								Vores streamere og arrangører beslutter hvor længe et event er tilgængeligt. Vi forsøger at holde alle events tilgængeligt i minimum 48 timer. Det er dog helt op til vores foredragsholdere.</O>
			);
		},
		"de": () => {
			return (
				<O>Ja, für eine begrenzte Zeit.<br/>
								Unsere Referenten und Veranstalter entscheiden selbst, wie lange ein Event verfügbar ist. Wir sind bestrebt, alle Events für mindestens 48 Stunden, nachdem wir live waren, zur Verfügung zu stellen. Aber es ist einzig die Entscheidung des jeweiligen Veranstalters.</O>
			);
		},
		"sv": () => {
			return (
				<O>Ja, men under en begränsad tid.<br />
			 Våra talare och arrangörer bestämmer hur länge ett evenemang ska vara tillgängligt. Vi strävar efter att hålla alla evenemang synliga i minst 48 timmar efter att vi sänt live. Det är dock helt och hållet arrangörerna som beslutar om det.</O>
			);
		},
		"no": () => {
			return (
				<O>Ja, men i en begrenset tidsperiode.<br/>
							Våre foredragsholdere og arrangører bestemmer hvor lenge et arrangement er tilgjengelig. Vi prøver å holde alle arrangementer tilgjengelig i minst 48 timer etter direktesending. Men dette er helt opp til arrangørene. </O>
			);
		},
		"fr": () => {
			return (
				<O>Oui, mais pour une durée limitée.<br />
				Nos orateurs et nos organisateurs décident de la durée de disponibilité d'un événement. Nous nous efforçons de garder tous les événements visibles pendant au moins 48 heures après leur mise en ligne. Toutefois, cela reste à l'entière discrétion des organisateurs.</O>
			);
		},
		"nl": () => {
			return (
				<O>Ja, maar voor een beperkte tijd.<br/>
								Onze sprekers en organisatoren bepalen hoe lang een evenement beschikbaar blijft. We streven ernaar om alle evenementen minimaal 48 uur zichtbaar te houden nadat we live zijn gegaan. Dit is echter geheel ter beoordeling van de organisatoren.</O>
			);
		},
	},

	"86d76ae752f7": {
		"en": () => <O>How much does it cost?</O>,
		"da": () => <O>Hvor meget koster det at se foredrag på Zevio?</O>,
		"de": () => <O>Wie hoch sind die Kosten für mich?</O>,
		"sv": () => <O>Hur mycket kostar det?</O>,
		"no": () => <O>Hvor mye koster det?</O>,
		"fr": () => <O>Combien cela coûte-t-il?</O>,
		"nl": () => <O>Hoeveel kost het?</O>,
	},

	"63f80351aba5": {
		"en": () => {
			return (
				<O>Enjoying live streams on Zevio is free.<br/>
								Viewers may be signed up to mailing lists from organisers and Zevio when registering for a stream.				You can, of course, unsubscribe from mailing lists at any time. Also, we want our speakers to be				able to pursue their passion - and we do this by letting them give our viewers special offers.				We take a commission on these sales.</O>
			);
		},
		"da": () => {
			return (
				<O>Foredrag på Zevio kan variere i pris.<br/>
												Nogle events koster blot en tilmelding, men vil dermed blive tilmeldt nyhedsbreve fra arrangøren. Andre koster et beløb, der fremgår ved checkout. Ofte vil betalte events være længere og grundigere. Streamere og arrangører kan vælge at give seere tilbud på produkter undervejs i udsendelserne.</O>
			);
		},
		"de": () => {
			return (
				<O>Die Live-Gespräche auf Zevio kannst du kostenfrei genießen.<br/>
								Zuschauer können von den Veranstaltern und Zevio auf Mailing-Listen eingetragen werden, wenn sie sich für einen virtuellen Vortrag registrieren. Natürlich kannst du dich aus den Mailing-Listen jederzeit austragen. Wir möchten natürlich auch, dass unsere Speaker ihrer Leidenschaft nachgehen können - deshalb können sie ihren Zuschauern spezielle Angebote machen. Wir erhalten eine Provision für diese Verkäufe.</O>
			);
		},
		"sv": () => {
			return (
				<O>Att njuta av livesamtal på Zevio är gratis.<br />
				Tittare kan registreras på e-postlistor från arrangörer och Zevio när de registrerar sig för ett föredrag. Du kan självklart avregistrera dig från e-postlistor när som helst. Vi vill också att våra talare ska kunna fullfölja sin passion - och vi gör detta genom att låta dem ge våra tittare specialerbjudanden. Vi tar provision på dessa försäljningar.</O>
			);
		},
		"no": () => {
			return (
				<O>Det er gratis å se liveforedrag på Zevio.<br/>
								Seerne kan skrive seg på e-postlistene til arrangørene og Zevio når de registrerer seg for et foredrag. Men, du kan selvsagt fjerne navnet ditt fra e-postlistene når du vil. Vi vil også at foredragsholderne våre skal følge lidenskapen sin – og vi gjør dette ved å la dem gi spesialtilbud til seerne våre. Vi tjener provisjon på disse salgene. </O>
			);
		},
		"fr": () => {
			return (
				<O>L'accès aux conférences en direct sur Zevio est gratuit.<br />
				Les spectateurs peuvent être inscrits aux listes de diffusion des organisateurs et de Zevio lorsqu'ils s'inscrivent à une conférence. Vous pouvez, bien entendu, vous désinscrire des listes de diffusion à tout moment. Par ailleurs, nous voulons que nos conférenciers puissent poursuivre leur passion - et nous le faisons en leur permettant de proposer des offres spéciales à nos spectateurs. Nous prenons une commission sur ces ventes.</O>
			);
		},
		"nl": () => {
			return (
				<O>Genieten van live talks op Zevio is gratis.<br/>
								Kijkers kunnen worden aangemeld voor mailinglijsten van organisatoren en Zevio wanneer ze zich registreren voor een lezing. U kunt zich natuurlijk op elk moment afmelden voor mailinglijsten. We willen ook dat onze sprekers hun passie kunnen delen - en we doen dit door ze de kijkers speciale aanbiedingen te laten doen tijdens een sessie. Op deze verkopen rekenen wij een commissie.</O>
			);
		},
	},

	"aff14f801983": {
		"en": () => <O>Can I go live on Zevio?</O>,
		"da": () => <O>Kan jeg selv sende på Zevio?</O>,
		"de": () => <O>Kann ich selbst auch live auf Zevio gehen?</O>,
		"sv": () => <O>Kan jag gå live på Zevio?</O>,
		"no": () => <O>Kan jeg sende direkte på Zevio?</O>,
		"fr": () => <O>Puis-je être en direct sur Zevio ?</O>,
		"nl": () => <O>Kan ik live gaan op Zevio?</O>,
	},

	"c8902323e0fe": {
		"en": () => {
			return (
				<O>If you want to publish videos or streams via Zevio, contact hello@zevio.com</O>
			);
		},
		"da": () => {
			return (
				<O>Hvis du ønsker at udgive videoer eller streams via Zevio så kontakt hello@zevio.com </O>
			);
		},
		"de": () => {
			return (
				<O>Wenn Sie Videos oder Streams über Zevio veröffentlichen möchten, kontaktieren Sie hello@zevio.com </O>
			);
		},
		"sv": () => {
			return (
				<O>Om du vill publicera videor eller streams via Zevio, kontakta hello@zevio.com </O>
			);
		},
		"no": () => {
			return (
				<O>Hvis du ønsker å publisere videoer eller strømmer via Zevio, kontakt hello@zevio.com </O>
			);
		},
		"fr": () => {
			return (
				<O>Si vous souhaitez publier des vidéos ou des streams via Zevio, contactez hello@zevio.com </O>
			);
		},
		"nl": () => {
			return (
				<O>Als je video's of streams via Zevio wilt publiceren, neem contact op met hello@zevio.com  </O>
			);
		},
	},

	"79f5ac6be7c2": {
		"en": () => <O>Can I ask my questions to the speakers?</O>,
		"da": () => <O>Kan jeg stille spørgsmål til streamerne?</O>,
		"de": () => <O>Kann ich den Vortragenden Fragen stellen?</O>,
		"sv": () => <O>Kan jag ställa mina frågor till talarna?</O>,
		"no": () => <O>Kan jeg stille spørsmål til foredragsholderne?</O>,
		"fr": () => <O>Puis-je poser mes questions aux orateurs?</O>,
		"nl": () => <O>Puis-je poser mes questions aux orateurs ?</O>,
	},

	"b5c476c351b1": {
		"en": () => <O>Some speakers take questions during the sessions; some do not. The speakers decide.</O>,
		"da": () => <O>Nogle streamere svarer på spørgsmål undervejs og nogle gør ikke. Det afhænger af den tid vi har til rådighed.</O>,
		"de": () => <O>Einige Vortragende lassen Fragen während der Sessions zu. Andere nicht. Das entscheidet jeder Referent für sich.</O>,
		"sv": () => <O>Vissa talare tar emot frågor under sändningarna, andra inte. Talarna bestämmer.</O>,
		"no": () => <O>Noen foredragsholdere tar imot spørsmål under sendingen, andre gjør ikke det. Foredragsholderne bestemmer dette.</O>,
		"fr": () => <O>Certains orateurs répondent aux questions pendant les sessions, d'autres non. Ce sont les orateurs qui décident.</O>,
		"nl": () => <O>De ene spreker beantwoord vragen tijdens de uitzending, de ander doet dat niet. Deze beslissing ligt bij de spreker zelf.</O>,
	},

	"ec08e7b22517": {
		"en": () => <O>Is it always live?</O>,
		"da": () => <O>Er det altid live?</O>,
		"de": () => <O>Ist alles live?</O>,
		"sv": () => <O>Är det alltid live?</O>,
		"no": () => <O>Er det alltid direkte?</O>,
		"fr": () => <O>Est-ce que c'est toujours en direct?</O>,
		"nl": () => <O>Is elke uitzending live?</O>,
	},

	"01cac25cc2a8": {
		"en": () => {
			return (
				<O>No. There have been instances of pre-recorded videos. Recordings happen when
				there is a conflict of calendars or unforeseen circumstances set in. We aspire to
				be live every time, but everyone has daily lives that take unexpected turns now
				and then.</O>
			);
		},
		"da": () => {
			return (
				<O>Nej. Vi oplever ligesom alle andre, at hverdagen nogle gange kommer i vejen. Således også for streamere. I nogle tilfælde kan det derfor blive nødvendigt at 'bånde' et foredrag og sende det som om det er live. Det er også muligt at genbesøge alle tidligere udsendelser, og disse er - i sagens natur - ikke længere live.</O>
			);
		},
		"de": () => {
			return (
				<O>Nein. Es gibt auch Videos, die im Vorfeld aufgenommen wurden. Das wird gemacht, wenn sich Termine überschneiden oder es zu unvorhergesehenen Umständen kommt. Wir bemühen uns, immer live zu senden, aber jeder hat auch seinen eigenen, persönlichen Alltag, der hin und wieder unerwartete Wendungen nehmen kann.</O>
			);
		},
		"sv": () => {
			return (
				<O>Nej. Det har förekommit fall av förinspelade videor. Förinspelningar sker när det uppstår en konflikt mellan kalendrar eller oförutsedda omständigheter. Vi strävar efter att sända live varje gång, men i livet kan det ske oförutsedda händelser ibland.</O>
			);
		},
		"no": () => {
			return (
				<O>Nei. Det hender vi sender et opptak eller forhåndsinnspilt webinar. Vi prøver å gjøre det live så ofte som mulig, men noen ganger passer det best med opptak.  </O>
			);
		},
		"fr": () => {
			return (
				<O>Non. Il y a eu des cas de vidéos préenregistrées. Les enregistrements ont lieu lorsqu'il y a un conflit de calendriers ou des circonstances imprévues. Nous aspirons à être en direct à chaque fois, mais tout le monde a une vie quotidienne qui prend des tournures inattendues de temps à autre.</O>
			);
		},
		"nl": () => {
			return (
				<O>Nee. Soms worden uitzendingen vooraf opgenomen. Dit kan gebeuren wanneer er een probleem is met de geplande uitzendtijd of bij onvoorziene omstandigheden. We proberen de uitzendingen zoveel mogelijk live te streamen, echter kunnen er zo nu en dan natuurlijk situaties ontstaan binnen het dagelijks leven van de sprekers. </O>
			);
		},
	},

	"ec39581faf2f": {
		"en": () => <O>What devices do you support?</O>,
		"da": () => <O>Hvilke enheder understøtter Zevio?</O>,
		"de": () => <O>Welche Endgeräte werden von euch unterstützt?</O>,
		"sv": () => <O>Vilka enheter stödjer ni?</O>,
		"no": () => <O>Hvilke enheter støtter dere?</O>,
		"fr": () => <O>Quels sont les appareils que vous prenez en charge ?</O>,
		"nl": () => <O>Welke apparaten ondersteunen jullie?</O>,
	},

	"f3364783dcee": {
		"en": () => {
			return (
				<O>You can watch Zevio talks on any reasonably new smartphone, tablet or computer.
				Any browser with a decent internet connection should do. However, some browsers
				may have restrictive policies on playing streams with sound and/or auto-playing
				streams.<br />
				It is not possible to provide support for every configuration of
				browser/computer/device out there.<br />
				We suggest using the free Zevio-app for a more streamlined experience. Find it
				where you usually get your apps.</O>
			);
		},
		"da": () => {
			return (
				<O>Du kan se zevio-foredrag på alle nyere telefoner, tablets eller computere.<br />
				Det er desværre ikke muligt for os at yde support på enkelte konfigurationer af enheder, styresystemer og forbindelser.<br />
				Det kræver en pålidelig og hurtig internetforbindelse at se live streaming. Vi anbefaler at du bruger den gratis Zevio App til Android og iOS hvis du ønsker den mest strømlinede oplevelse.</O>
			);
		},
		"de": () => {
			return (
				<O>Du kannst die virtuellen Vorträge bei Zevio mit jedem neuen Smartphone, Tablet oder Computer erleben. Jeder Browser mit einer ordentlichen, stabilen Internet-Verbindung ist ausreichend. Einige Browser können Streams mit Ton und/oder automatisch abspielende Streams blockieren. <br/>
								Es ist nicht möglich, für jede Zusammenstellung von Browser, Computer und Gerät einen Support anzubieten.<br/>
							Um sicher zu sein, dass du alles auf Zevio genießen kannst, kannst du dir die kostenlose Zevio-App herunterladen. Du findest sie dort, wo du normalerweise deine Apps herunterlädst - im PlayStore oder AppleStore.</O>
			);
		},
		"sv": () => {
			return (
				<O>Du kan se Zevio-föreläsningar på vilken ny smartphone, surfplatta eller dator som helst. Alla webbläsare med en bra internetanslutning borde göra det. Vissa webbläsare kan dock ha restriktiva policyer för att spela upp sändningar med ljud och/eller sådana som spelas automatiskt.<br />
				Det är inte möjligt att stödja varje konfiguration av webbläsare/dator/enhet där ute.<br />
			Vi föreslår att du använder den kostnadsfria Zevio-appen för en mer strömlinjeformad upplevelse. Hitta den där du vanligtvis hittar dina appar.</O>
			);
		},
		"no": () => {
			return (
				<O>Du kan se Zevio-foredrag på alle nyere smarttelefoner, nettbrett og datamaskiner. Enhver nettleser med en brukbar internettforbindelse bør fungere. Men noen nettlesere kan ha begrensninger når det kommer til strømmetjenester med lyd og/eller automatisk strømming.<br />
			Det er ikke mulig å tilby støtte for alle konfigurasjoner av nettlesere/datamaskiner/enheter som finnes.<br />
				Vi foreslår at du bruker vår gratis Zevio-app for en mer strømlinjeformet opplevelse. Finn den der du vanligvis finner appene dine. </O>
			);
		},
		"fr": () => {
			return (
				<O>Vous pouvez regarder les conférences Zevio sur tout smartphone, tablette ou ordinateur raisonnablement récent. Tout navigateur avec une connexion internet décente devrait faire l'affaire. Cependant, certains navigateurs peuvent avoir des politiques restrictives sur la lecture des flux avec du son et/ou des flux en lecture automatique.<br />
				Il n'est pas possible de fournir un support pour chaque configuration de navigateur/ordinateur/appareil existant.<br />
			Nous vous suggérons d'utiliser l'application gratuite Zevio pour une expérience plus simple. Vous la trouverez là où vous trouvez habituellement vos applications.</O>
			);
		},
		"nl": () => {
			return (
				<O>Je kunt uitzendingen op Zevio bekijken op elke redelijk nieuwe smartphone, tablet of computer. Elke browser met degelijke internetverbinding is geschikt. Sommige browsers hebben echter restricties op gebied van livestreams met geluid en/of livestreams met autoplay.<br />
				Het is helaas niet mogelijk om ondersteuning te bieden voor alle bestaande apparatuur en configuraties.<br />
				We raden aan om de Zevio app te gebruiken voor een vloeiende gebruikerservaring. Je kunt de app vinden in je reguliere app store.</O>
			);
		},
	},

	"53c0fd2cf9ab": {
		"en": () => <O>Stream your knowledge at Zevio</O>,
		"da": () => <O>Tjen penge på din viden - også mens du sover</O>,
		"de": () => <O>Verbreiten Sie Ihr Wissen auf Zevio</O>,
		"sv": () => <O>Streama din kunskap med hjälp av Zevio</O>,
		"no": () => <O>La kunnskapen din nå ut på Zevio</O>,
		"fr": () => <O>Diffusez vos connaissances à travers un Livestream sur Zevio</O>,
		"nl": () => <O>Jouw kennis is je kracht</O>,
	},

	"14e280524ace": {
		"en": () => <O>Host live webinars and connect with customers all over the world, build your trust, and receive more inquiries. Focus on your message, and get full technical support.</O>,
		"da": () => <O>Har du noget på hjerte, som andre kan have brug for at vide? Opret dit event, vælg din pris og gå live direkte fra din browser.  </O>,
		"de": () => <O>Veranstalten Sie Live-Webinare und treten Sie mit Kunden in aller Welt in Kontakt, bauen Sie Ihr Vertrauen auf und erhalten Sie mehr Anfragen. Konzentrieren Sie sich auf Ihre Botschaft und erhalten Sie umfassenden technischen Support von Zevio.</O>,
		"sv": () => <O>Skapa live webinar och koppla ihop dig med kunder över hela världen, öka ditt förtroende och få fler förfrågningar. Fokusera på ditt budskap och ta del av heltäckande teknisk support.</O>,
		"no": () => <O>Direktesendinger til publikum over hele verden. Du fokuserer på budskapet, vi tar oss av det tekniske.</O>,
		"fr": () => <O>Organisez des webinaires en direct et connectez-vous avec des clients du monde entier, renforcez votre confiance et recevez plus de demandes. Concentrez-vous sur ce que vous voulez communiquer et bénéficiez d'une assistance technique complète.</O>,
		"nl": () => <O>Ga live om uw kennis te benutten en uw bedrijf te laten bloeien.</O>,
	},

	"d7c310db0090": {
		"en": () => <O>Get started</O>,
		"da": () => <O>Bliv streamer</O>,
		"de": () => <O>Werde Veranstalter</O>,
		"sv": () => <O>Kom igång</O>,
		"no": () => <O>Bli en arrangør</O>,
		"fr": () => <O>Commencez ici</O>,
		"nl": () => <O>Word een organisator</O>,
	},

	"16c5fb9dfd5a": {
		"en": () => <O>Learn more</O>,
		"da": () => <O>Læs mere</O>,
		"de": () => <O>Mehr Informationen</O>,
		"sv": () => <O>Läs mer</O>,
		"no": () => <O>Les mer</O>,
		"fr": () => <O>En savoir plus</O>,
		"nl": () => <O>Leer meer</O>,
	},

	"ef91c460c30d": {
		"en": () => <O>Streaming made easy</O>,
		"da": () => <O>Foredrag for alle</O>,
		"de": () => <O>Streaming leicht gemacht</O>,
		"sv": () => <O>Streaming på enklast möjliga sätt</O>,
		"no": () => <O>Streaming gjort enkelt</O>,
		"fr": () => <O>Des Livestreams simplifiés pour vous</O>,
		"nl": () => <O>Sommige zaken zijn het waard om uitgelegd te worden</O>,
	},

	"744a2e8d6407": {
		"en": () => <O>Go live whereever you want, and maximize your online impact on the real world. Zevio enables you to connect with audiences virtually anywhere in realtime. No subscribtion needed.</O>,
		"da": () => <O>Opret dit event, sæt din pris og se tilmeldingerne tikke ind.  </O>,
		"de": () => <O>Präsentieren Sie sich live, wo immer Sie wollen, und maximieren Sie Ihren Online-Einfluss auf die reale Welt. Zevio ermöglicht es Ihnen, praktisch überall in Echtzeit mit Ihrem Publikum in Kontakt zu treten. Kein Abonnement erforderlich.</O>,
		"sv": () => <O>Du kan gå live var som helst och maximera effekten av din onlinenärvaro. Zevio gör att du kan komma i kontakt med din publik på dina egna villkor i realtid. Ingen prenumeration behövs.</O>,
		"no": () => <O>Gå live, og bygg relasjoner med ditt publikum, når og hvor det passer deg best. Ingen abonnement nødvendig. </O>,
		"fr": () => <O>Faites un LIVE où vous voulez et maximisez votre impact en ligne dans le monde réel. Zevio vous permet de vous connecter avec votre public n'importe où et en temps réel. Vous n'avez pas besoin de connaissances techniques ou d'équipement avancé pour diffuser des webinaires. Aucun abonnement nécessaire. </O>,
		"nl": () => <O>Zevio betekent je bedrijf op een goede manier laten groeien. Bevrijd jezelf van marketingsuites, zoekmachineoptimalisatie, tracking, eindeloze campagnes en budgetaanpassingen. Jouw bedrijf heeft maar één ding nodig ... jou. </O>,
	},

	"274c5dc5b389": {
		"en": () => <O>You don't need technical knowledge or advanced equipment to stream webinars. Our team will help you ensure a professional set-up and a smooth experience every time.</O>,
		"da": () => <O>Du kan uploade dit foredrag direkte, eller gå live fra din browser.</O>,
		"de": () => <O>Für das Streaming von Webinaren benötigen Sie keine technischen Kenntnisse oder fortschrittliche Ausrüstung. Unser Team hilft Ihnen, eine professionelle Einrichtung und einen reibungslosen Ablauf zu gewährleisten.</O>,
		"sv": () => <O>Du behöver inte någon teknisk expertis eller någon avancerad utrustning för att streama webinar. Vårt team ser till att du får professionella förberedelser och att allting går smidigt och enkelt.</O>,
		"no": () => <O>Du trenger verken avansert utstyr eller doktorgrad i streaming-teknikk for å holde webinarer. Teamet vårt hjelper deg på hvert steg og sørger for at du får en profesjonell og stressfri opplevelse.</O>,
		"fr": () => <O>Vous n'avez pas besoin de connaissances techniques ou d'équipement avancé pour diffuser des webinaires. Notre équipe vous aidera à assurer une configuration professionnelle et une expérience fluide à chaque fois.</O>,
		"nl": () => <O>Zevio zorgt voor de kijkers en de technologie om live te gaan voor een publiek dat naar jouw boodschap wil luisteren. Jij brengt jouw expertise en je passie mee. Vaarwel eindeloze social media campagnes. Hallo klanten.</O>,
	},

	"cf4a5cc313c2": {
		"en": () => <O>You're in great company</O>,
		"da": () => <O>Mød nogle af dem, der allerede bruger Zevio</O>,
		"de": () => <O>Sie sind in bester Gesellschaft</O>,
		"sv": () => <O>Möt dem som redan använder Zevio</O>,
		"no": () => <O>Du er i godt selskap</O>,
		"fr": () => <O>Vous êtes en bonne compagnie</O>,
		"nl": () => <O>Maak kennis met actieve gebruikers van Zevio</O>,
	},

	"4033d744237d": {
		"en": () => <O>Join scientists, athletes, authors, consultants, politicians and organisations already using Zevio</O>,
		"da": () => <O>Del din viden sammen med forskere, atleter, forfattere, konsulenter, politikere, organisationer og mange flere.</O>,
		"de": () => <O>Schließen Sie sich Wissenschaftlern, Sportlern, Autoren, Beratern, Politikern und Organisationen an, die Zevio bereits nutzen</O>,
		"sv": () => <O>Förena dig med forskare, idrottare, författare, konsulter, politiker och organisationer som redan använder Zevio.</O>,
		"no": () => <O>Slå deg sammen med vitenskapsfolk, idrettsfolk, forfattere, konsulenter, politikere og organisasjoner som allerede bruker Zevio. </O>,
		"fr": () => <O>Rejoignez des scientifiques, des athlètes, des auteurs, des consultants, des politiciens et des organisations utilisant déjà Zevio</O>,
		"nl": () => <O>Sluit je aan bij wetenschappers, atleten, auteurs, adviseurs, politici en organisaties die Zevio al gebruiken</O>,
	},

	"544a17a3ea6c": {
		"en": () => <O>Engage your audience with the live chat feature </O>,
		"da": () => <O>Interager med dine seere i chatten, mens du sender live</O>,
		"de": () => <O>Binden Sie Ihr Publikum mit der Live-Chat-Funktion ein</O>,
		"sv": () => <O>Aktivera din publik med funktionen för livechatt</O>,
		"no": () => <O>Skap en engasjerende samtale med live chat</O>,
		"fr": () => <O>Interagissez avec votre public grâce à la fonction de chat en direct</O>,
		"nl": () => <O>Scherpe streams in full HD met tot wel 15.000 kijkers</O>,
	},

	"820f883f1ace": {
		"en": () => <O>And look professional every time. Let our team moderate the chat function and studio quality streaming.  </O>,
		"da": () => <O>Og se knivskarp ud imens. Vores teknologi sikrer høj videokvalitet. </O>,
		"de": () => <O>Und sehen Sie dabei immer professionell aus. Lassen Sie unser Team die Moderation der Chatfunktion und das Streaming in Studioqualität übernehmen.</O>,
		"sv": () => <O>Och visa dig alltid från din bästa sida. Låt vårt team moderera livechatten och se till att streamingen håller studiokvalitet.</O>,
		"no": () => <O>Vi styrer lyd og video, slik at du alltid ser skarp ut. </O>,
		"fr": () => <O>Ayez toujours l'air vif et laissez notre équipe modérer la fonction de chat et un streaming de qualité.</O>,
		"nl": () => <O>Stream direct naar onze RTMPS server met beelden tot 1080p60, 8,5 mbps. (Of laat de technische details aan ons over en krijg assistentie op maat van Zevio HQ voor jouw uitzendingen) </O>,
	},

	"8762485503a2": {
		"en": () => <O>Book a call</O>,
		"da": () => <O>Har du brug for en snak?</O>,
		"de": () => <O>Buchen Sie einen Anruf</O>,
		"sv": () => <O>Boka ett samtal</O>,
		"no": () => <O>Book et møte</O>,
		"fr": () => <O>Entrer en contact</O>,
		"nl": () => <O>Herhaal</O>,
	},

	"06d75cb74060": {
		"en": () => <O>Got questions?</O>,
		"da": () => <O>Du har sikkert spørgsmål. Vi har svar. <br/> <a href="https://calendly.com/olch-zevio/intro-mode-til-zevio">Book en <b>uforpligtende </b> samtale og hør om dine muligheder.</a> </O>,
		"de": () => <O>Haben Sie Fragen?</O>,
		"sv": () => <O>Har du några frågor?</O>,
		"no": () => <O>Har du spørsmål? La oss ta en prat om dine muligheter på Zevio </O>,
		"fr": () => <O>Des questions?</O>,
		"nl": () => <O>Behoud je gebruikers en blijf hen boeien. Wij brengen je volgers op de hoogte wanneer je een nieuw evenement organiseert.</O>,
	},

	"3977a7295d51": {
		"en": () => <O>+100.000</O>,
		"da": () => <O>+100.000</O>,
		"de": () => <O>+100.000</O>,
		"sv": () => <O>+100.000</O>,
		"no": () => <O>+100.000</O>,
		"fr": () => <O>+100.000</O>,
		"nl": () => <O>+100.000</O>,
	},

	"fe5aa8fd0810": {
		"en": () => <O>hours streamed</O>,
		"da": () => <O>timer streamet</O>,
		"de": () => <O>gestreamte Stunden</O>,
		"sv": () => <O>streamingtimmar</O>,
		"no": () => <O>timer strømmet</O>,
		"fr": () => <O>heures de streaming</O>,
		"nl": () => <O>uur beeldmateriaal gestreamed</O>,
	},

	"a1880f82fb83": {
		"en": () => <O>events</O>,
		"da": () => <O>events</O>,
		"de": () => <O>Events</O>,
		"sv": () => <O>events</O>,
		"no": () => <O>arrangementer</O>,
		"fr": () => <O>événements</O>,
		"nl": () => <O>evenementen</O>,
	},

	"2613ff3fb4c6": {
		"en": () => <O>+1.000.000</O>,
		"da": () => <O>+1.000.000</O>,
		"de": () => <O>+1.000.000</O>,
		"sv": () => <O>+1.000.000</O>,
		"no": () => <O>+1.000.000</O>,
		"fr": () => <O>+1.000.000</O>,
		"nl": () => <O>+1.000.000</O>,
	},

	"c5591ac569b7": {
		"da": () => <O>Seere</O>,
		"de": () => <O>Betrachter</O>,
		"en": () => <O>viewers</O>,
		"fr": () => <O>téléspectateurs</O>,
		"nl": () => <O>kijkers</O>,
		"no": () => <O>Seere</O>,
		"sv": () => <O>Tittare</O>,
	},

	"24d5d3f14944": {
		"en": () => <O>Who are Zevio and what do you do?</O>,
		"da": () => <O>Hvem er og hvad laver Zevio?</O>,
		"de": () => <O>Wie viele Leute sehen sich meinen Stream an?</O>,
		"sv": () => <O>Vilka är Zevio och vad gör ni?</O>,
		"no": () => <O>Hvem er Zevio, og hva driver dere med?</O>,
		"fr": () => <O>Qui est Zevio et que fait Zevio?</O>,
		"nl": () => <O>Organiseer je volgende evenement - en blijf verkopen</O>,
	},

	"7a96d951f9b2": {
		"en": () => <O>How many people are watching my stream?</O>,
		"da": () => <O>Hvor mange ser med på min stream?</O>,
		"de": () => <O>Finde dein Publikum – sogar während du schläfst</O>,
		"sv": () => <O>Hur många personer tittar på min stream?</O>,
		"no": () => <O>Hvor mange ser på sendingene?</O>,
		"fr": () => <O>Combien de personnes regardent mon livestream?</O>,
		"nl": () => <O>Vergroot je publiek - zelfs wanneer je slaapt</O>,
	},

	"d1bd18a035c5": {
		"en": () => <O>What and how much can I sell?</O>,
		"da": () => <O>Hvad og hvor meget kan jeg sælge?</O>,
		"de": () => <O>Was und wie viel kann ich verkaufen?</O>,
		"sv": () => <O>Vad och hur mycket kan jag sälja?</O>,
		"no": () => <O>Hva kan jeg selge? Og hvor mye?</O>,
		"fr": () => <O>Que puis-je vendre et combien?</O>,
		"nl": () => <O>Verkrijg een verkoopkanaal dat klanten blijft binnenharken</O>,
	},

	"fb51f2b671e4": {
		"en": () => <O>Can I use my old computer?</O>,
		"da": () => <O>Kan jeg bruge en gammel computer?</O>,
		"de": () => <O>Kann ich meinen alten Computer verwenden?</O>,
		"sv": () => <O>Kan jag använda min gamla dator?</O>,
		"no": () => <O>Kan jeg bruke min gamle PC?</O>,
		"fr": () => <O>Puis-je utiliser mon ancien ordinateur?</O>,
		"nl": () => <O>Assistentie op maat bij je uitzendingen</O>,
	},

	"8a41913d3c6b": {
		"en": () => <O>How do I get people to sign up for my stream?</O>,
		"da": () => <O>Hvordan får jeg seere til min stream?</O>,
		"de": () => <O>Wie kann ich Leute dazu bringen, sich für meinen Stream anzumelden?</O>,
		"sv": () => <O>Hur får jag folk att registrera sig för min stream?</O>,
		"no": () => <O>Hvordan får jeg folk til å melde deg på?</O>,
		"fr": () => <O>Comment faire en sorte que les gens s'inscrivent à Livestream?</O>,
		"nl": () => <O>Beheer alle aspecten van het proces.</O>,
	},

	"6362df3359f5": {
		"en": () => <O>Meet some of the talent already using Zevio</O>,
		"da": () => <O>Brugt af nogle af Danmarks største foredragsholdere</O>,
		"de": () => <O>Entdecken Sie einige der Talente, die Zevio bereits nutzen</O>,
		"sv": () => <O>Möt några av talangerna som redan använder Zevio</O>,
		"no": () => <O>Møt noen av talentene som allerede bruker Zevio</O>,
		"fr": () => <O>Rencontrez quelques-uns des talents qui utilisent déjà Zevio</O>,
		"nl": () => <O>Ontmoet een paar talentvolle gebruikers van Zevio</O>,
	},

	"e1d6a09d8638": {
		"en": () => <O>Get a customized solution</O>,
		"da": () => <O>Få en skræddersyet streamingløsning</O>,
		"de": () => <O>Erhalten Sie eine maßgeschneiderte Lösung</O>,
		"sv": () => <O>Få en skräddarsydd lösning</O>,
		"no": () => <O>Streaming med skreddersøm</O>,
		"fr": () => <O>Obtenez une solution personnalisée</O>,
		"nl": () => <O>Hoe het in zijn werk gaat</O>,
	},

	"d07888f5e88e": {
		"en": () => <O>Choose a solution based on your needs: </O>,
		"da": () => <O>Klar, parat - passion!</O>,
		"de": () => <O>Wählen Sie eine Lösung, die Ihren Bedürfnissen entspricht:</O>,
		"sv": () => <O>Välj en lösning som passar dina behov:</O>,
		"no": () => <O>Velg det som passer deg best</O>,
		"fr": () => <O>Choisissez une solution en fonction de vos besoins:</O>,
		"nl": () => <O>Meld je aan als organisator</O>,
	},

	"dbdfa1927d4c": {
		"en": () => <O>Sign up today and create your first event.</O>,
		"da": () => <O> Opret en konto og start for 0 kroner.  </O>,
		"de": () => <O>Registriere dich noch heute und erstelle dein erstes Event.</O>,
		"sv": () => <O>Anmäl dig idag och skapa ditt första event.</O>,
		"no": () => <O>Registrer deg i dag og lag ditt første arrangement.</O>,
		"fr": () => <O>Inscrivez-vous dès aujourd'hui et créez votre premier événement.</O>,
		"nl": () => <O>Meld je vandaag nog aan en creëer je eerste evenement.</O>,
	},

	"bf38f1de09f6": {
		"en": () => <O>Zevio will start attracting viewers for your event. You should chip in &mdash; our system detects popular events (in terms of registered attendees) and promotes our marketing accordingly.</O>,
		"da": () => <O><b>Send hjemmefra, når det passer dig.</b><br/><br/><b>Vi klarer billetsalget.</b><br/><br/><b>Lign en million og besøg vores studie i Odense eller København: 5995 kroner</b><br/><br/><b>Ring til os, hvis vi skal hjælpe med markedsføringen. </b><br/><br/></O>,
		"de": () => <O>Zevio wird damit beginnen, Zuschauer für Ihr Event zu gewinnen. Sie sollten mitmachen - unser System erkennt beliebte Veranstaltungen (in Bezug auf die Anzahl der registrierten Teilnehmer) und fördert unser Marketing entsprechend.</O>,
		"sv": () => <O>Zevio kommer börja locka till sig tittare till ditt event. Du bör också bidra – vårt system känner igen populära event (med avseende på antal registrerade deltagare) och anpassar vår marknadsföring efter det.</O>,
		"no": () => <O>Vi begynner å tiltrekke et publikum umiddelbart - men det er best å dele i egne kanaler også. Jo flere, jo bedre.</O>,
		"fr": () => <O>Zevio commencera à attirer des spectateurs pour votre événement. Avec votre participation — notre système détecte les événements populaires (en termes d'inscriptions) et fait la promotion de l'événement en conséquence. </O>,
		"nl": () => <O>Zevio begint direct met het aantrekken van kijkers. Hou zelf ook een oogje in het zeil - onze software markeert populaire evenementen met veel geregistreerde deelnemers en past de promotie daarop aan.</O>,
	},

	"d3bf666c1537": {
		"en": () => <O>When it is time to go live, simply jump in your studio and hit that live button. We’ll let everyone know you are live.</O>,
		"da": () => <O>Skab et produkt, der tjener penge mens du sover.</O>,
		"de": () => <O>Wenn es an der Zeit ist, online zu gehen, gehen Sie einfach in Ihr Studio und drücken den Live-Button. Wir lassen alle wissen, dass Sie auf Sendung sind.</O>,
		"sv": () => <O>När det är dags att gå live, behöver du helt enkelt bara gå till din studio och trycka på live-knappen. Vi ser till att alla får veta att du är live.</O>,
		"no": () => <O>Når det er på tide å sende direkte, går du bare inn i studioet ditt og trykker på live-knappen. Vi forteller alle at du sender direkte.</O>,
		"fr": () => <O>Quand il est temps d'aller LIVE (diffuser), sautez simplement dans votre studio et appuyez sur le bouton pour aller en LIVE. Nous ferons savoir à tout le monde que vous êtes LIVE.</O>,
		"nl": () => <O>Als het tijd is om live te gaan neem je plaats voor je camera en klik je op de live knop. Wij zullen iedereen laten weten dat jij live bent. </O>,
	},

	"d6aa45455725": {
		"en": () => <O>We'll handle the marketing</O>,
		"da": () => <O>Markedsfører din stream</O>,
		"de": () => <O>Wir kümmern uns um das Marketing</O>,
		"sv": () => <O>Vi tar hand om marknadsföringen</O>,
		"no": () => <O>Vi tar oss av markedsføringen</O>,
		"fr": () => <O>Nous nous occupons du marketing</O>,
		"nl": () => <O>Voeg je producten toe</O>,
	},

	"b77d463f8a08": {
		"en": () => <O>We know your audience. Focus on your message, and get help spreading the word before you stream or record. You'll get:  </O>,
		"da": () => <O>Alle events bliver vist på vores hjemmeside og du kan nemt linke til din udsendelse:      </O>,
		"de": () => <O>Wir kennen Ihr Publikum. Konzentrieren Sie sich auf Ihre Botschaft und lassen Sie sich helfen, sie zu verbreiten, bevor Sie streamen oder aufnehmen. Sie erhalten:</O>,
		"sv": () => <O>Vi har koll på din målgrupp. Fokusera på ditt budskap, och få hjälp att nå ut när det är dags att streama eller spela in. Vi erbjuder:</O>,
		"no": () => <O>Vi kjenner publikumet. Fokuser på budskapet ditt, så hjelper vi med å få det ut før du er live. Du får: </O>,
		"fr": () => <O>Nous connaissons votre public. Concentrez-vous sur votre message et obtenez de l'aide pour passer le mot avant d'aller LIVE ou d'enregistrer votre webinaire. Vous aurez: </O>,
		"nl": () => <O>Verkoop direct aan je kijkers. Jij maakt je product - wij doen het papierwerk en laten je weten waar je de producten naartoe moet sturen.</O>,
	},

	"2a0adf17843e": {
		"en": () => <O>Personalized newsletter</O>,
		"da": () => <O>Nyhedsbreve</O>,
		"de": () => <O>Einen personalisierten Newsletter</O>,
		"sv": () => <O>Personligt nyhetsbrev</O>,
		"no": () => <O>Personlig nyhetsbrev</O>,
		"fr": () => <O>Newsletter personnalisée</O>,
		"nl": () => <O>Kies uit Dollars, Euro's en Kronen (Noorwegen, Denemarken en Zweden).</O>,
	},

	"b4a8eb3274ac": {
		"en": () => <O>Sign-up link in monthly newsletter </O>,
		"da": () => <O>Sociale medier </O>,
		"de": () => <O>Einen Link zur Anmeldung im monatlichen Newsletter</O>,
		"sv": () => <O>Registeringslänk i nyhetsbrev som skickas månadsvis</O>,
		"no": () => <O>Påmeldingslenke i månedlig nyhetsbrev</O>,
		"fr": () => <O>Lien d'inscription dans la newsletter mensuelle</O>,
		"nl": () => <O>Ga met de Zevio app direct live op de smartphone van je klanten.</O>,
	},

	"2a13fa5fa0f6": {
		"en": () => <O>Facebook event with weekly updates</O>,
		"da": () => <O>Hjemmeside</O>,
		"de": () => <O>Eine Facebook Veranstaltung mit wöchentlichen Updates</O>,
		"sv": () => <O>Facebook event som uppdateras veckovis</O>,
		"no": () => <O>Facebookevent med ukentlige oppdateringer</O>,
		"fr": () => <O>Événement Facebook avec des mises à jour hebdomadaires</O>,
		"nl": () => <O>Maak zoveel producten aan als je maar wilt.</O>,
	},

	"9bef2401eff0": {
		"en": () => <O>Facebook advertising</O>,
		"da": () => <O>Facebook-annoncering af begivenhed</O>,
		"de": () => <O>Facebook-Werbung</O>,
		"sv": () => <O> Facebook-annonsering </O>,
		"no": () => <O>Annonsering på Facebook</O>,
		"fr": () => <O>De la publicité sur Facebook</O>,
		"nl": () => <O>Bereik tot wel 15.000 kijkers in full HD.</O>,
	},

	"54153384653d": {
		"en": () => <O>Chance to get extended advertising</O>,
		"da": () => <O>Zevios sociale kanaler </O>,
		"de": () => <O>Chance auf erweiterte Werbung</O>,
		"sv": () => <O>Potentiellt förlängd annonsering</O>,
		"no": () => <O>Mulighet for utvidet promotering</O>,
		"fr": () => <O>Possibilité d'intensifier la publicité</O>,
		"nl": () => <O>Je hebt geen creditcard nodig.</O>,
	},

	"49a45fd9fdea": {
		"en": () => <O>Increased brand exposure on our website and in our app</O>,
		"da": () => <O>Eksponering på Zevios hjemmeside og app</O>,
		"de": () => <O>Erhöhte Markenpräsenz auf unserer Website und in unserer App</O>,
		"sv": () => <O>Ökad synlighet för ditt varumärke på vår webbsida och i vår app</O>,
		"no": () => <O>Økt tilstedeværelse på vår nettside og app </O>,
		"fr": () => <O>Augmentation de l'exposition de la marque sur notre site Web et dans notre application</O>,
		"nl": () => <O>Niet goed, geld terug. Zevio ontvangt een commissie van 25% - we verdienen dus alleen wanneer jij dat ook doet.</O>,
	},

	"aef7600c1a77": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Sælg dine produkter direkte</O>,
		"de": () => <O>Verkaufen Sie Ihre Produkte auf Zevio</O>,
		"en": () => <O>Sell your products on Zevio</O>,
		"fr": () => <O>Vendez vos produits sur Zevio</O>,
		"nl": () => <O>Washington, Copenhagen, or Kyoto</O>,
		"no": () => <O>Selg produktene dine på Zevio</O>,
		"sv": () => <O>Sälj dina produkter hos Zevio</O>,
	},

	"f0053dbc6917": {
		"en": () => <O>Sell your books and online courses easily and instantly. Create your product, and get notified, whenever an item is sold.   </O>,
		"da": () => <O>Sælger du bøger, onlinekurser eller noget helt tredje? Opret dine produkter og send kunderne direkte til din webshop.  </O>,
		"de": () => <O>Verkaufen Sie Ihre Produkte und Online-Kurse einfach und sofort. Erstellen Sie Ihr Produkt und lassen Sie sich benachrichtigen, wenn ein Artikel verkauft wird.</O>,
		"sv": () => <O>Sälj dina produkter och onlinekurser på ett enkelt och direkt sätt. Skapa din produkt och få information när någonting har sålts.</O>,
		"no": () => <O>Har du skrevet bok, eller tilbyr du et kurs? Opprett produkter og få beskjed når seerne kjøper.  </O>,
		"fr": () => <O>Vendez vos produits et cours en ligne facilement et instantanément. Créez votre produit et soyez averti chaque fois qu'un article est vendu.</O>,
		"nl": () => <O>Het enige dat je nodig hebt is een degelijke computer en betrouwbaar internet. Jouw uitzending kan overal ter wereld worden bekeken - met slechts 5 seconde vertraging.</O>,
	},

	"4862ae5b06db": {
		"en": () => <O>Sell products on website and in app</O>,
		"da": () => <O>Sælg både på hjemmeside og app</O>,
		"de": () => <O>Verkaufen Sie Produkte auf der Website und in der App</O>,
		"sv": () => <O>Sälj produkter på webbsidan och i appen</O>,
		"no": () => <O>Selg produkter på nettsiden og i app</O>,
		"fr": () => <O>Vendez vos produits sur le site Web et dans l'application</O>,
		"nl": () => <O>Uitzenden naar 15.000 mensen in full HD met maximaal 60 frames per seconde</O>,
	},

	"54975bd77697": {
		"en": () => <O>Sell more without administration</O>,
		"da": () => <O>Slip for administrativt papirarbejde</O>,
		"de": () => <O>Verkaufen Sie mehr ohne Verwaltungsaufwand</O>,
		"sv": () => <O>Sälj mer utan krånglig administration</O>,
		"no": () => <O>Tilby produkter til et større publikum</O>,
		"fr": () => <O>Vendez plus sans paperasse</O>,
		"nl": () => <O>Ga live rechtstreeks vanuit je browser (of gebruik onze studio's)</O>,
	},

	"a89ee01bd6f5": {
		"en": () => <O>Keep your product on Zevio after your stream</O>,
		"da": () => <O>Øget eksponering</O>,
		"de": () => <O>Lassen Sie Ihr Produkt nach Ihrem Stream auf Zevio</O>,
		"sv": () => <O>Behåll din product hos Zevio efter din stream</O>,
		"no": () => <O>Produktene blir stående til de som ser opptaket</O>,
		"fr": () => <O>Gardez votre produit sur Zevio après votre livestream</O>,
		"nl": () => <O>Download je uitzending en gebruik hem ergens anders</O>,
	},

	"4b522a262cff": {
		"en": () => <O>Fully adaptive bitrate streaming to your viewers for smooth playback</O>,
		"da": () => <O>Opret et ubegrænset antal produkter  </O>,
		"de": () => <O>Vollständig adaptives Bitraten-Streaming für Ihre Zuschauer für eine reibungslose Wiedergabe</O>,
		"sv": () => <O>Fullt anpassningsbar streaming till dina tittare för problemfri uppspelning</O>,
		"no": () => <O>Ingen avgifter og full teknisk støtte </O>,
		"fr": () => <O>Streaming à débit entièrement adaptatif pour mieux s'adapter à vos téléspectateurs et garder une lecture fluide à tout moment</O>,
		"nl": () => <O>Soepel afspelen voor je kijkers door een volledig adaptieve bitrate streaming</O>,
	},

	"3d9709f4c2b8": {
		"en": () => <O>Build, grow, and protect your brand  </O>,
		"da": () => <O>Øg din omsætning</O>,
		"de": () => <O>Aufbau, Wachstum und Schutz Ihrer Marke</O>,
		"sv": () => <O>Utveckla, expandera och skydda ditt varumärke</O>,
		"no": () => <O>Styrk merkevaren din</O>,
		"fr": () => <O>Construisez, développez et protégez votre marque</O>,
		"nl": () => <O>Ben je al een professionele streamer? Stream direct naar onze RTMPS</O>,
	},

	"9207a2faf87f": {
		"en": () => <O>Exclusive deals</O>,
		"da": () => <O>Arrangøren anbefaler</O>,
		"de": () => <O>Exklusive Angebote</O>,
		"sv": () => <O>Exklusiva erbjudanden</O>,
		"no": () => <O>Eksklusive tilbud</O>,
		"fr": () => <O>Offres exclusives</O>,
		"nl": () => <O>Exclusieve aanbiedingen</O>,
	},

	"2602bc216b71": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Anbefalet af arrangøren:</O>,
		"de": () => <O>Empfohlene Produkte:</O>,
		"en": () => <O>Recommended product:</O>,
		"fr": () => <O>Recommended product:</O>,
		"nl": () => <O>Aanbevolen product:</O>,
		"no": () => <O>Recommended product:</O>,
		"sv": () => <O> Rekommenderat av arrangören:</O>,
	},

	"246322cadabe": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Anbefalet af arrangøren:</O>,
		"de": () => <O>Empfohlene Produkte:</O>,
		"en": () => <O>Recommended products:</O>,
		"fr": () => <O>Produits recommandés:</O>,
		"nl": () => <O>Aanbevolen producten:</O>,
		"no": () => <O>Recommended products:</O>,
		"sv": () => <O> Rekommenderat av arrangören:</O>,
	},

	"c6f3d30ef588": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Nej tak, måske senere</O>,
		"de": () => <O>Nein danke, weiter zum Video</O>,
		"en": () => <O>No thanks, continue to video</O>,
		"fr": () => <O>Pas de merci, continuer vers la vidéo</O>,
		"nl": () => <O>Nee bedankt, ga verder met video</O>,
		"no": () => <O>Nei takk, kanskje senere</O>,
		"sv": () => <O>Nej tack, fortsätt till video</O>,
	},

	"de2afd8883f7": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Dette event eksisterer ikke, eller også er det ikke frigivet endnu.</O>,
		"de": () => <O>Dieses Event existiert nicht bzw. wurde noch nicht veröffentlicht.</O>,
		"en": () => <O>This event does not exist, or has not yet been released to the public.</O>,
		"fr": () => <O>Cet événement n'existe pas ou n'a pas encore été publié au public.</O>,
		"nl": () => <O>Nee bedankt, ga verder met de video. Dit evenement bestaat niet, of is nog niet vrijgegeven voor het publiek.</O>,
		"no": () => <O>Dette arrangementet eksisterer ikke, eller det har ennå ikke blitt gjort tilgjengelig for offentligheten.</O>,
		"sv": () => <O>Det här evenemanget existerar inte eller har ännu inte släppts till allmänheten.</O>,
	},

	"a110c98ba993": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Kontakt os, hvis du mener dette er en fejl</O>,
		"de": () => <O>Bitte kontaktiere uns, wenn du glaubst, dass dies ein Fehler ist.</O>,
		"en": () => <O>Please contact us, if you believe this is in err.</O>,
		"fr": () => <O>Veuillez nous contacter si vous pensez qu'il s'agit d'une erreur.</O>,
		"nl": () => <O>Neem contact met ons op als u denkt dat dit niet klopt.</O>,
		"no": () => <O>Kontakt oss hvis du tror dette er en feil.</O>,
		"sv": () => <O>Kontakta oss om du tror att detta är ett fel.</O>,
	},

	"0085b99735af": {
		"da": () => <O>Denne talk er arrangeret af</O>,
		"de": () => <O>Dieser Stream wird organisiert von</O>,
		"en": () => <O>This stream is organized by</O>,
		"fr": () => <O>Ce stream est organisé par</O>,
		"nl": () => <O>Deze stream wordt georganiseerd door</O>,
		"no": () => <O>Denne stream er organisert av</O>,
		"sv": () => <O>Denna stream är organiserad av</O>,
	},

	"41362e9166c0": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Fortryd tilmelding</O>,
		"de": () => <O>Anmeldung abbrechen</O>,
		"en": () => <O>Cancel registration</O>,
		"fr": () => <O>Annuler l'inscription</O>,
		"nl": () => <O>Annuleer registratie</O>,
		"no": () => <O>Registratie annuleren</O>,
		"sv": () => <O> Avbryt registreringen</O>,
	},

	"13ca0db1bdd1": {
		"en": () => <O>Upcoming events</O>,
		"da": () => <O>Kommende streams</O>,
		"de": () => <O>Kommende Vorträge</O>,
		"sv": () => <O>Kommande</O>,
		"no": () => <O>Kommende</O>,
		"fr": () => <O>Prochainement</O>,
		"nl": () => <O>Toekomstige</O>,
	},

	"598f63bfb5c6": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Kommende</O>,
		"de": () => <O>Meine Events</O>,
		"en": () => <O>Coming</O>,
		"fr": () => <O>À venir</O>,
		"nl": () => <O>Aankomende</O>,
		"no": () => <O>Komt eraan</O>,
		"sv": () => <O>Kommande</O>,
	},

	"28193d5e4222": {
		"en": () => <O>Past events</O>,
		"da": () => <O>Tidligere streams</O>,
		"de": () => <O>Vergangene Vorträge</O>,
		"sv": () => <O>Tidigare föreläsningar</O>,
		"no": () => <O>Tidligere arrangementer</O>,
		"fr": () => <O>Événements passés</O>,
		"nl": () => <O>Voorgaande evenementen</O>,
	},

	"b228135483d6": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Premium streams</O>,
		"de": () => <O>Premium streams</O>,
		"en": () => <O>Premium streams</O>,
		"fr": () => <O>Premium streams</O>,
		"nl": () => <O>Premium streams</O>,
		"no": () => <O>Premium streams</O>,
		"sv": () => <O>Premium streams</O>,
	},

	"d4b2bf7d1900": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Tidligere</O>,
		"de": () => <O>Aufzeichnungen</O>,
		"en": () => <O>Passé</O>,
		"fr": () => <O>Past</O>,
		"nl": () => <O>Verleden</O>,
		"no": () => <O>Tidligere</O>,
		"sv": () => <O>Dåtid</O>,
	},

	"f3f2995b2949": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Ingen resultater</O>,
		"de": () => <O>Keine Suchergebnisse</O>,
		"en": () => <O>No search results</O>,
		"fr": () => <O>Aucun résultat de recherche</O>,
		"nl": () => <O>Geen resultaten gevonden</O>,
		"no": () => <O>Ingen søkeresultater</O>,
		"sv": () => <O> Inga sökresultat</O>,
	},

	"b1ab38865cd8": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Kunde</O>,
		"de": () => <O>Kunde</O>,
		"en": () => <O>Customer</O>,
		"fr": () => <O>Client</O>,
		"nl": () => <O>Klant</O>,
		"no": () => <O>Kunde</O>,
		"sv": () => <O>Kund</O>,
	},

	"60f0bc802666": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Event</O>,
		"de": () => <O>Event</O>,
		"en": () => <O>Event</O>,
		"fr": () => <O>Event</O>,
		"nl": () => <O>Event</O>,
		"no": () => <O>Event</O>,
		"sv": () => <O>Event</O>,
	},

	"b5fd1ae939c3": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Amount paid</O>,
		"de": () => <O>Amount paid</O>,
		"en": () => <O>Amount paid</O>,
		"fr": () => <O>Amount paid</O>,
		"nl": () => <O>Amount paid</O>,
		"no": () => <O>Amount paid</O>,
		"sv": () => <O>Amount paid</O>,
	},

	"37a7228f278f": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Foredragsholder</O>,
		"de": () => <O>Referent</O>,
		"en": () => <O>Speaker</O>,
		"fr": () => <O>Conférencier</O>,
		"nl": () => <O>Spreker</O>,
		"no": () => <O>Foredragsholder</O>,
		"sv": () => <O>Talare</O>,
	},

	"a7ae9e07df78": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Tid (UTC)</O>,
		"de": () => <O>Zeit (UTC)</O>,
		"en": () => <O>Time (UTC)</O>,
		"fr": () => <O>Heure (UTC)</O>,
		"nl": () => <O>Tijd (UTC)</O>,
		"no": () => <O>Tid (UTC)</O>,
		"sv": () => <O>Tid (UTC)</O>,
	},

	"ef560e5297c3": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Zevio Ordrer</O>,
		"de": () => <O>Zevio Bestellungen</O>,
		"en": () => <O>Zevio Orders</O>,
		"fr": () => <O>Commandes Zevio</O>,
		"nl": () => <O>Zevio bestellingen</O>,
		"no": () => <O>Zevio ordre</O>,
		"sv": () => <O>Zevio beställningar</O>,
	},

	"f21efb02f847": {
		"en": () => <O>Home</O>,
		"da": () => <O>Hjem</O>,
		"de": () => <O>Startseite</O>,
		"sv": () => <O>Hem</O>,
		"no": () => <O>Hjem</O>,
		"fr": () => <O>Accueil</O>,
		"nl": () => <O>Homepagina</O>,
	},

	"2d6f0a23f195": {
		"en": () => <O>Broadcasts</O>,
		"da": () => <O>Streams</O>,
		"de": () => <O>Virtuelle Vorträge</O>,
		"sv": () => <O>Sändningar</O>,
		"no": () => <O>Sendinger</O>,
		"fr": () => <O>Livestreams</O>,
		"nl": () => <O>Uitzendingen</O>,
	},

	"47f4ed8ca236": {
		"en": () => <O>My page</O>,
		"da": () => <O>Min side</O>,
		"de": () => <O>Meine Seite</O>,
		"sv": () => <O>Min sida</O>,
		"no": () => <O>Min side</O>,
		"fr": () => <O>Ma page</O>,
		"nl": () => <O>Mijn pagina</O>,
	},

	"39eeda81087c": {
		"en": () => <O>Order hub</O>,
		"da": () => <O>Ordrer</O>,
		"de": () => <O>Bestellbereich</O>,
		"sv": () => <O>Beställningssortiment</O>,
		"no": () => <O>Ordreknutepunkt</O>,
		"fr": () => <O>centre de commande</O>,
		"nl": () => <O>Bestelhub</O>,
	},

	"4bcbc3240fcf": {
		"en": () => <O>Products</O>,
		"da": () => <O>Produkter</O>,
		"de": () => <O>Produkte</O>,
		"sv": () => <O>Produkter</O>,
		"no": () => <O>Produkter</O>,
		"fr": () => <O>Produits</O>,
		"nl": () => <O>Producten</O>,
	},

	"3fda0e6d656d": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Tilføj produkt</O>,
		"de": () => <O>Neues Produkt</O>,
		"en": () => <O>New product</O>,
		"fr": () => <O>Nouveau produit</O>,
		"nl": () => <O>Nieuw product</O>,
		"no": () => <O>Nytt produkt</O>,
		"sv": () => <O>Ny produkt</O>,
	},

	"88f8263dbd86": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Tilgængelige produkter</O>,
		"de": () => <O>Verfügbare Produkte</O>,
		"en": () => <O>Available products</O>,
		"fr": () => <O>Produits disponibles</O>,
		"nl": () => <O>Beschikbare producten</O>,
		"no": () => <O>Tilgjengelige produkter</O>,
		"sv": () => <O>Tillgängliga produkter</O>,
	},

	"973dafc68955": {
		"en": () => <O>Added products</O>,
		"da": () => <O>Tilføjede produkter</O>,
		"de": () => <O>Hinzugefügte Produkte</O>,
		"sv": () => <O>Tillagda produkter</O>,
		"no": () => <O>Produkter som er lagt til</O>,
		"fr": () => <O>Produits ajoutés</O>,
		"nl": () => <O>Toegevoegde producten</O>,
	},

	"a45cffb65c87": {
		"en": () => <O>Save event</O>,
		"da": () => <O>Gem</O>,
		"de": () => <O>Speichern</O>,
		"sv": () => <O>Spara</O>,
		"no": () => <O>Lagre</O>,
		"fr": () => <O>Sauvegarder</O>,
		"nl": () => <O>Opslaan</O>,
	},

	"41d85199b866": {
		"en": () => <O>Make it watchable on demand</O>,
		"da": () => <O>Lad dit foredrag være online til seere, der ikke var med live.</O>,
		"de": () => <O>Stell deinen Vortrag auf Abruf zur Verfügung</O>,
		"sv": () => <O>Gör det möjligt att titta på den när som helst som on demand</O>,
		"no": () => <O>Gjør foredragene dine tilgjengelige på forespørsel</O>,
		"fr": () => <O>Rendez-le regardable à la demande</O>,
		"nl": () => <O>Maak het bekijkbaar op aanvraag</O>,
	},

	"2678319f25c9": {
		"en": () => <O>Post processing</O>,
		"da": () => <O>Redigér optagelsen</O>,
		"de": () => <O>Nachbearbeitung</O>,
		"sv": () => <O>Efterbearbetning</O>,
		"no": () => <O>Etterbehandling</O>,
		"fr": () => <O>Post-traitement</O>,
		"nl": () => <O>Nabewerking</O>,
	},

	"d98dccd29959": {
		"en": () => <O>Post processing for editing and reupload of video will be available once the event is over</O>,
		"da": () => <O>Efterredigering er tilgængelig efter udsendelsen</O>,
		"de": () => <O>Die Nachbearbeitung ist nach der Ausstrahlung möglich.</O>,
		"sv": () => <O>Efterbearbetning för redigering och återladdning av videon kommer att vara tillgänglig när evenemanget är över.</O>,
		"no": () => <O>Etterbehandling for å redigere og laste opp videoen på nytt er mulig etter arrangementet.</O>,
		"fr": () => <O>Le post-traitement pour le montage et le rechargement de la vidéo sera disponible une fois l'événement terminé.</O>,
		"nl": () => <O>Na afloop van het evenement kan de video worden bewerkt en opnieuw worden geüpload.</O>,
	},

	"38c91d6535a3": {
		"en": () => <O>New event</O>,
		"da": () => <O>Opret ny stream</O>,
		"de": () => <O>Neues Event</O>,
		"sv": () => <O>Ny föreläsning</O>,
		"no": () => <O>Nytt arrangement</O>,
		"fr": () => <O>Nouvelle lecture</O>,
		"nl": () => <O>Nieuwe lezing</O>,
	},

	"fbfb5abbc43c": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Premium</O>,
		"de": () => <O>Premium</O>,
		"en": () => <O>Premium</O>,
		"fr": () => <O>Premium</O>,
		"nl": () => <O>Premium</O>,
		"no": () => <O>Premium</O>,
		"sv": () => <O>Premium</O>,
	},

	"615b14682de8": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>All streams</O>,
		"de": () => <O>All streams</O>,
		"en": () => <O>All streams</O>,
		"fr": () => <O>All streams</O>,
		"nl": () => <O>All streams</O>,
		"no": () => <O>All streams</O>,
		"sv": () => <O>All streams</O>,
	},

	"2179dc502e59": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Vælg</O>,
		"de": () => <O>Auswählen</O>,
		"en": () => <O>Select</O>,
		"fr": () => <O>Sélectionner</O>,
		"nl": () => <O>Selecteer</O>,
		"no": () => <O>Select</O>,
		"sv": () => <O> Välj</O>,
	},

	"493ec1d1db5a": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Tilføj venligst arrangør(er)</O>,
		"de": () => <O>Bitte den/die Veranstalter hinzufügen</O>,
		"en": () => <O>Please add organizer(s)</O>,
		"fr": () => <O>Veuillez ajouter l'organisateur</O>,
		"nl": () => <O>Voeg organisator(en) toe</O>,
		"no": () => <O>Vennligst legg til arrangør(er)</O>,
		"sv": () => <O>Vänligen lägg till arrangör(er)</O>,
	},

	"8461f487dc7f": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Email på produktejer</O>,
		"de": () => <O>E-Mail des Eigentümers</O>,
		"en": () => <O>Owner email</O>,
		"fr": () => <O>Adresse e-mail du propriétaire</O>,
		"nl": () => <O>Eigenaar e-mail</O>,
		"no": () => <O>Eiers e-post</O>,
		"sv": () => <O> Ägarens e-post</O>,
	},

	"0578c4c2fb5c": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Denne bliver trykt på kvittering i tilfælde af spørgsmål</O>,
		"de": () => <O>E-Mail des Produkteigentümers, welche auf den Beleg gedruckt werden soll.</O>,
		"en": () => <O>Product owner email to be printed on receipt.</O>,
		"fr": () => <O>Adresse e-mail du propriétaire du produit à imprimer sur le reçu.</O>,
		"nl": () => <O>E-mailadres van de producteigenaar moet bij ontvangst worden afgedrukt.</O>,
		"no": () => <O>Produkteiers e-post som printes på kvitteringen.</O>,
		"sv": () => <O>Produktägarens e-post kommer att skrivas ut på kvittot vid frågor</O>,
	},

	"f94a4230d623": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Telefon på produktejer</O>,
		"de": () => <O>Telefonnummer des Eigentümers</O>,
		"en": () => <O>Owner phone</O>,
		"fr": () => <O>Téléphone du propriétaire</O>,
		"nl": () => <O>Eigenaar telefoon</O>,
		"no": () => <O>Eiers mobilnummer</O>,
		"sv": () => <O>Ägartelefon</O>,
	},

	"5260326f9917": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Dette bliver trykt på kvitteringen i tilfælde af spørgsmål</O>,
		"de": () => <O>Telefonnummer des Produkteigentümers, welche auf den Beleg gedruckt werden soll.</O>,
		"en": () => <O>Product owner phone number to be printed on receipt</O>,
		"fr": () => <O>Numéro de téléphone du propriétaire du produit à imprimer sur le reçu</O>,
		"nl": () => <O>Telefoonnummer van de producteigenaar dat op de bon moet worden afgedrukt</O>,
		"no": () => <O>Produkteiers mobilnummer som printes på kvitteringen.</O>,
		"sv": () => <O>Produktägarens telefonnummer kommer att skrivas ut på kvittot vid frågor</O>,
	},

	"7337faaa661c": {
		"da": () => <O>Log ind</O>,
		"de": () => <O>Anmelden</O>,
		"en": () => <O>Log in</O>,
		"fr": () => <O>Log in</O>,
		"nl": () => <O>Log in</O>,
		"no": () => <O>Logg inn</O>,
		"sv": () => <O>Logga in</O>,
	},

	"da132a4c854e": {
		"en": () => <O>Search events...</O>,
		"da": () => <O>Søg i foredrag...</O>,
		"de": () => <O>Veranstaltungen suchen...</O>,
		"sv": () => <O>Sök events...</O>,
		"no": () => <O>Søk arrangementer</O>,
		"fr": () => <O>Rechercher des événements</O>,
		"nl": () => <O>Zoek evenementen</O>,
	},

	"02438ccf9398": {
		"en": () => <O>Name*</O>,
		"da": () => <O>Navn*</O>,
		"de": () => <O>Name*</O>,
		"sv": () => <O>Namn*</O>,
		"no": () => <O>Navn*</O>,
		"fr": () => <O>Nom*</O>,
		"nl": () => <O>Naam*</O>,
	},

	"b056b6874904": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Forsendelsesadresse</O>,
		"de": () => <O>Lieferadresse</O>,
		"en": () => <O>Shipping Address</O>,
		"fr": () => <O>Adresse de livraison</O>,
		"nl": () => <O>Verzendadres</O>,
		"no": () => <O>Leveringsadresse</O>,
		"sv": () => <O>Leveransadress</O>,
	},

	"ac12a5dea2d4": {
		"_new": true, // FIXME remove this line when translation is done
		"da": (LL) => {
			return (
				<O>
													Jeg accepterer Zevios 
													<a href={LL("/termsoftrade")} rel="noreferrer" target="_blank">
														handelsbetingelser
													</a>
												</O>
			);
		},
		"de": (LL) => {
			return (
				<O>
									Ich stimme den Handelsbedingungen von 
									<a href={LL("/termsoftrade")} rel="noreferrer" target="_blank">
										zu
									</a>
								</O>
			);
		},
		"en": (LL) => {
			return (
				<O>
					I agree to the&nbsp;
					<a
						href={LL("/termsoftrade")}
						rel="noreferrer"
						target="_blank"
					>
						terms of trade
					</a>
				</O>
			);
		},
		"fr": (LL) => {
			return (
				<O>
									J'accepte les conditions commerciales de 
									<a href={LL("/termsoftrade")} rel="noreferrer" target="_blank"></a>
								</O>
			);
		},
		"nl": (LL) => {
			return (
				<O>
									Ik ga akkoord met de handelsvoorwaarden van 
									<a href={LL("/termsoftrade")} rel="noreferrer" target="_blank"></a>
								</O>
			);
		},
		"no": (LL) => {
			return (
				<O>
									Jeg godtar 
									<a href={LL("/termsoftrade")} rel="noreferrer" target="_blank">
										handelsvilkår
									</a>
								</O>
			);
		},
		"sv": (LL) => {
			return (
				<O>
									Jag godkänner 
									<a href={LL("/termsoftrade")} rel="noreferrer" target="_blank">
										handelsvillkor
									</a>
								</O>
			);
		},
	},

	"af8ba3d21be0": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Faktureringsadresse</O>,
		"de": () => <O>Rechnungsadresse</O>,
		"en": () => <O>Billing Address</O>,
		"fr": () => <O>Adresse de facturation</O>,
		"nl": () => <O>Factuuradres</O>,
		"no": () => <O>Faktureringsadresse</O>,
		"sv": () => <O>Faktureringsadress</O>,
	},

	"3520b68d9fe4": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Brug som leveringsadresse</O>,
		"de": () => <O>Als Lieferadresse verwenden</O>,
		"en": () => <O>Use as shipping address</O>,
		"fr": () => <O>Utiliser comme adresse de livraison</O>,
		"nl": () => <O>Gebruiken als verzendadres</O>,
		"no": () => <O>Bruk som leveringsadresse</O>,
		"sv": () => <O>Använd som leveransadress</O>,
	},

	"2a79ae72d838": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Fornavn</O>,
		"de": () => <O>Vorname</O>,
		"en": () => <O>First Name</O>,
		"fr": () => <O>Prénom</O>,
		"nl": () => <O>Voornaam</O>,
		"no": () => <O>Fornavn</O>,
		"sv": () => <O>Förnamn</O>,
	},

	"e8593a560786": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Indtast navn</O>,
		"de": () => <O>Bitte Name einfügen</O>,
		"en": () => <O>Please insert name</O>,
		"fr": () => <O>Veuillez saisir votre nom</O>,
		"nl": () => <O>Gelieve naam in te vullen</O>,
		"no": () => <O>Vennligst skriv inn navn</O>,
		"sv": () => <O>Vänligen ange namn</O>,
	},

	"e4432538431e": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Efternavn</O>,
		"de": () => <O>Nachname</O>,
		"en": () => <O>Last Name</O>,
		"fr": () => <O>Nom de famille</O>,
		"nl": () => <O>Achternaam</O>,
		"no": () => <O>Etternavn</O>,
		"sv": () => <O>Efternamn</O>,
	},

	"022712a3f8f4": {
		"_new": true, // FIXME remove this line when translation is done
		"da": (eventData) => <O>Køb billet (og undgå emails) til <i>{eventData.Title}</i></O>,
		"de": (eventData) => <O>Bezahlen Sie für ein Inkognito-Ticket für <i>{eventData.Title}</i></O>,
		"en": (eventData) => <O>Pay for an incognito-ticket to <i>{eventData.Title}</i></O>,
		"fr": (eventData) => <O>Payer pour un billet incognito pour <i>{eventData.Title}</i></O>,
		"nl": (eventData) => <O>Betaal voor een incognito-ticket voor <i>{eventData.Title}</i></O>,
		"no": (eventData) => <O>Betal for en inkognito-billett til <i>{eventData.Title}</i></O>,
		"sv": (eventData) => <O>Betala för en inkognito-biljett till <i>{eventData.Title}</i></O>,
	},

	"ce7ddef9105a": {
		"da": () => {
			return (
				<O>
													Nogle udsendelser kan kun ses mod tilmelding og registrering af data. Hvis du ikke ønsker at dele din data kan du købe en inkognito-billet her.
												</O>
			);
		},
		"de": () => {
			return (
				<O>
									Unsere Webinare sind kostenlos, aber wir bitten Sie, sich bei uns zu registrieren. Um zu verhindern, dass wir Ihre Daten speichern, können Sie hier ein Inkognito-Ticket kaufen.
								</O>
			);
		},
		"en": () => {
			return (
				<O>
					Our webinars are free, but we do require that you register with
					us. To avoid saving your data with us, you can buy an
					incognito-ticket here.
				</O>
			);
		},
		"fr": () => {
			return (
				<O>
									Nos webinaires sont gratuits, mais nous demandons que vous vous inscriviez auprès de nous. Pour éviter d'enregistrer vos données chez nous, vous pouvez acheter un billet incognito ici.
								</O>
			);
		},
		"nl": () => {
			return (
				<O>
									Onze webinars zijn gratis, maar we vragen u wel om u bij ons te registreren. Om te voorkomen dat we uw gegevens opslaan, kunt u hier een incognito-ticket kopen.
								</O>
			);
		},
		"no": () => {
			return (
				<O>
									Våre webinarer er gratis, men vi krever at du registrerer deg hos oss. For å unngå å lagre dataene dine hos oss, kan du kjøpe en inkognito-billett her.
								</O>
			);
		},
		"sv": () => {
			return (
				<O>
									Våra webbinarier är gratis, men vi ber dig att registrera dig hos oss. För att undvika att spara dina data hos oss kan du köpa en inkognito-biljett här.
								</O>
			);
		},
	},

	"1f13a691abd6": {
		"en": () => <O>E-mail*</O>,
		"da": () => <O>Email*</O>,
		"de": () => <O>E-mail*</O>,
		"sv": () => <O>Epost*</O>,
		"no": () => <O>E-postadresse*</O>,
		"fr": () => <O>Courriel*</O>,
		"nl": () => <O>E-mailadres*</O>,
	},

	"67f4612b3539": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Ugyldig emailadresse</O>,
		"de": () => <O>Ungültige E-Mail-Adresse</O>,
		"en": () => <O>Invalid email address</O>,
		"fr": () => <O>Adresse courriel invalide</O>,
		"nl": () => <O>Ongeldig e-mailadres</O>,
		"no": () => <O>Ugyldig e-postadresse</O>,
		"sv": () => <O>Ogiltig epostadress</O>,
	},

	"8a1be146ed60": {
		"_new": true, // FIXME remove this line when translation is done
		"da": (eventData, formattedPrice) => {
			return (
				<O>
									Betal {formattedPrice} for en inkognito-billet til{" "}
									<i>{eventData.Title}</i>
								</O>
			);
		},
		"de": (eventData, formattedPrice) => {
			return (
				<O>
									Bezahlen {formattedPrice} Sie für ein Inkognito-Ticket für{" "}
									<i>{eventData.Title}</i>
								</O>
			);
		},
		"en": (eventData, formattedPrice) => {
			return (
				<O>
					Pay {formattedPrice} for an incognito-ticket to{" "}
					<i>{eventData.Title}</i>
				</O>
			);
		},
		"fr": (eventData, formattedPrice) => {
			return (
				<O>
									Payer {formattedPrice} pour un billet incognito pour{" "}
									<i>{eventData.Title}</i>
								</O>
			);
		},
		"nl": (eventData, formattedPrice) => {
			return (
				<O>
									Betaal {formattedPrice} voor een incognito-ticket voor{" "}
									<i>{eventData.Title}</i>
								</O>
			);
		},
		"no": (eventData, formattedPrice) => {
			return (
				<O>
									Betal {formattedPrice} for en inkognito-billett til{" "}
									<i>{eventData.Title}</i>
								</O>
			);
		},
		"sv": (eventData, formattedPrice) => {
			return (
				<O>
									Betala {formattedPrice} för en inkognito-biljett till{" "}
									<i>{eventData.Title}</i>
								</O>
			);
		},
	},

	"e202c354f947": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => {
			return (
				<O>
									Klik her for at returnere og se med uden omkostninger ved at tilmelde dig.
								</O>
			);
		},
		"de": () => {
			return (
				<O>
									Klicken Sie hier, um zurückzugehen und sich kostenlos anzumelden, indem Sie die Registrierungsinformationen angeben.
								</O>
			);
		},
		"en": () => {
			return (
				<O>
					Click here to go back and join for free by providing the
					registration info.
				</O>
			);
		},
		"fr": () => {
			return (
				<O>
									Cliquez ici pour revenir en arrière et rejoindre gratuitement en fournissant les informations d'inscription.
								</O>
			);
		},
		"nl": () => {
			return (
				<O>
									Klik hier om terug te gaan en gratis deel te nemen door de registratiegegevens te verstrekken.
								</O>
			);
		},
		"no": () => {
			return (
				<O>
									Klikk her for å gå tilbake og delta gratis ved å oppgi registreringsinformasjonen.
								</O>
			);
		},
		"sv": () => {
			return (
				<O>
									Klicka här för att gå tillbaka och delta gratis genom att ange registreringsinformationen.
								</O>
			);
		},
	},

	"0ec5ef226f6a": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Gade</O>,
		"de": () => <O>Straße</O>,
		"en": () => <O>Street</O>,
		"fr": () => <O>Rue</O>,
		"nl": () => <O>Straat</O>,
		"no": () => <O>Gate</O>,
		"sv": () => <O>Gata</O>,
	},

	"1cb96d32886d": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Indsæt venligst adresse</O>,
		"de": () => <O>Bitte geben Sie Ihre Adresse ein</O>,
		"en": () => <O>Please insert address</O>,
		"fr": () => <O>Veuillez saisir votre adresse</O>,
		"nl": () => <O>Vul alstublieft uw adres in</O>,
		"no": () => <O>Vennligst skriv inn adresse</O>,
		"sv": () => <O>Vänligen ange din adress</O>,
	},

	"a8d5e7033813": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Køb</O>,
		"de": () => <O>Kaufen</O>,
		"en": () => <O>Buy</O>,
		"fr": () => <O>Acheter</O>,
		"nl": () => <O>Kopen</O>,
		"no": () => <O>Kjøp</O>,
		"sv": () => <O>Köp</O>,
	},

	"268db5001df5": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Levering</O>,
		"de": () => <O>Lieferung</O>,
		"en": () => <O>Delivery</O>,
		"fr": () => <O>Livraison</O>,
		"nl": () => <O>Levering</O>,
		"no": () => <O>Levering</O>,
		"sv": () => <O>Leverans</O>,
	},

	"f28e37c63ec4": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Din forsendelse leveres til</O>,
		"de": () => <O>Ihr Paket wird zugestellt an</O>,
		"en": () => <O>Your package will be delivered to</O>,
		"fr": () => <O>Votre colis sera livré à</O>,
		"nl": () => <O>Uw pakket wordt bezorgd op</O>,
		"no": () => <O>Pakken din vil bli levert til</O>,
		"sv": () => <O>Ditt paket kommer att levereras till</O>,
	},

	"ec5d670a124e": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Kodeord skal matche og indeholde minimum 7 tegn.</O>,
		"de": () => <O>Das Passwort muss übereinstimmen und mindestens 7 Zeichen enthalten</O>,
		"en": () => <O>Passwords must match and contain a minimum of 7 characters</O>,
		"fr": () => <O>Les mots de passe doivent correspondre et contenir au moins 7 caractères</O>,
		"nl": () => <O>Wachtwoorden moeten overeenkomen en minimaal 7 tekens bevatten</O>,
		"no": () => <O>Passord må være like og ha minst 7 tegn</O>,
		"sv": () => <O>Lösenord måste matcha och ha minst 7 tecken.</O>,
	},

	"4fe0b18afe56": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Download den gratis app</O>,
		"de": () => <O>App kostenlos herunterladen</O>,
		"en": () => <O>Download app for free</O>,
		"fr": () => <O>Download app for free</O>,
		"nl": () => <O>Download app for free</O>,
		"no": () => <O>Download app for free</O>,
		"sv": () => <O>Download app for free</O>,
	},

	"bf9a75e69f42": {
		"en": () => <O>Zip/Postal Code</O>,
		"da": () => <O>Postnummer</O>,
		"de": () => <O>Postleitzahl</O>,
		"sv": () => <O>Postnummer</O>,
		"no": () => <O>Postnummer</O>,
		"fr": () => <O>Code postal</O>,
		"nl": () => <O>Postcode</O>,
	},

	"cdd7ef1c9be8": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Ugyldigt postnummer</O>,
		"de": () => <O>Invalid Zip/Postal Code</O>,
		"en": () => <O>Invalid Zip/Postal Code</O>,
		"fr": () => <O>Invalid Zip/Postal Code</O>,
		"nl": () => <O>Invalid Zip/Postal Code</O>,
		"no": () => <O>Invalid Zip/Postal Code</O>,
		"sv": () => <O>Invalid Zip/Postal Code</O>,
	},

	"62a492bcffb7": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>By</O>,
		"de": () => <O>City</O>,
		"en": () => <O>City</O>,
		"fr": () => <O>City</O>,
		"nl": () => <O>City</O>,
		"no": () => <O>City</O>,
		"sv": () => <O>City</O>,
	},

	"b1097c525d8f": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Indsæt venligst by</O>,
		"de": () => <O>Please insert city</O>,
		"en": () => <O>Please insert city</O>,
		"fr": () => <O>Please insert city</O>,
		"nl": () => <O>Please insert city</O>,
		"no": () => <O>Please insert city</O>,
		"sv": () => <O>Please insert city</O>,
	},

	"5b7b0906317c": {
		"en": () => <O>New password must contain a minimum of 7 characters</O>,
		"da": () => <O> Det nye kodeord skal indeholde mindst 7 tegn</O>,
		"de": () => <O>Das neue Passwort muss mindestens 7 Zeichen enthalten</O>,
		"sv": () => <O>Det nya lösenordet måste innehålla minst 7 tecken</O>,
		"no": () => <O>Det nye passordet må inneholde minimum 7 tegn</O>,
		"fr": () => <O>Le nouveau mot de passe doit contenir au moins 7 caractères</O>,
		"nl": () => <O>Het nieuwe wachtwoord moet minimaal 7 tekens bevatten</O>,
	},

	"28c53487292f": {
		"en": () => <O>Passwords do not match</O>,
		"da": () => <O>Kodeord stemmer ikke overens</O>,
		"de": () => <O>Die Passwörter stimmen nicht überein</O>,
		"sv": () => <O>Lösenorden stämmer inte överens</O>,
		"no": () => <O>Passordene stemmer ikke overens</O>,
		"fr": () => <O>Les mots de passe ne correspondent pas</O>,
		"nl": () => <O>Wachtwoorden komen niet overeen</O>,
	},

	"2e7185be4158": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Profilen er gemt</O>,
		"de": () => <O>Profil gespeichert</O>,
		"en": () => <O>Profile saved</O>,
		"fr": () => <O>Profile saved</O>,
		"nl": () => <O>Profiel bewaaard</O>,
		"no": () => <O>Profil lagret</O>,
		"sv": () => <O> Profilen har sparats</O>,
	},

	"8bded8b011ab": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Vi kunne ikke gemme profilen</O>,
		"de": () => <O>Profil speichern fehlgeschlagen</O>,
		"en": () => <O>Profile save failed</O>,
		"fr": () => <O>Profile save failed</O>,
		"nl": () => <O>Profiel opslaan mislukt</O>,
		"no": () => <O>Kunne ikke lagre profil</O>,
		"sv": () => <O>Det gick inte att spara profilen</O>,
	},

	"1c5282d36bf4": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Password nulstillet. Vi sender dig videre.</O>,
		"de": () => <O>Passwort zurücksetzen. Umleiten.</O>,
		"en": () => <O>Password reset. Redirecting.</O>,
		"fr": () => <O>Password reset. Redirecting.</O>,
		"nl": () => <O>Wachtwoord reset. Omleiden.</O>,
		"no": () => <O>Nullstill passord. Omdirigerer.</O>,
		"sv": () => <O> Lösenordsåterställning. Omdirigerar.</O>,
	},

	"ff76584a9a37": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Password reset fejlede.</O>,
		"de": () => <O>Passwort zurücksetzen fehlgeschlagen</O>,
		"en": () => <O>Password reset failed.</O>,
		"fr": () => <O>Password reset failed.</O>,
		"nl": () => <O>Wachtwoord opnieuw instellen is mislukt.</O>,
		"no": () => <O>Kunne ikke nullstille passord.</O>,
		"sv": () => <O> Lösenordsåterställning misslyckades.</O>,
	},

	"5c365605fed4": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Reset</O>,
		"de": () => <O>Zurücksetzen</O>,
		"en": () => <O>Reset</O>,
		"fr": () => <O>Reset</O>,
		"nl": () => <O>Resetten</O>,
		"no": () => <O>Nullstill</O>,
		"sv": () => <O> Återställ</O>,
	},

	"53403478d4ab": {
		"en": () => <O>Delete account</O>,
		"da": () => <O>Slet konto</O>,
		"de": () => <O>Konto löschen</O>,
		"sv": () => <O>Radera konto</O>,
		"no": () => <O>Slett konto</O>,
		"fr": () => <O>Supprimer le compte</O>,
		"nl": () => <O>Account verwijderen</O>,
	},

	"1d25f7293705": {
		"en": () => <O>Type in your email and we will send you a reset link</O>,
		"da": () => <O>Indtast din email. Så sender vi dig et nulstillingslink</O>,
		"de": () => <O>Bitte E-Mail-Adresse eingeben, wir senden dann einen Link zum Zurücksetzen</O>,
		"sv": () => <O>Ange din e-postadress så skickar vi dig en länk för återställning.</O>,
		"no": () => <O>Skriv inn e-posten din, så sender vi deg en tilbakestillingslenke</O>,
		"fr": () => <O>Saisissez votre adresse électronique et nous vous enverrons un lien de réinitialisation.</O>,
		"nl": () => <O>Voer uw e-mail in en wij sturen u een reset-link</O>,
	},

	"72265c0cb443": {
		"en": () => <O>Password has been reset and emailed to you</O>,
		"da": () => <O>Vi har sendt dit nye kodeord til din mail.</O>,
		"de": () => <O>Das Passwort wurde zugeschickt</O>,
		"sv": () => <O>Lösenordet har återställts och skickats till dig via e-post.</O>,
		"no": () => <O>Nytt passord har blitt sendt til din oppgitte e-postadresse</O>,
		"fr": () => <O>Le mot de passe a été réinitialisé et vous a été envoyé par e-mail.</O>,
		"nl": () => <O>Wachtwoord is opnieuw ingesteld en naar u gemaild</O>,
	},

	"c31f4e7db37c": {
		"en": () => <O>Are you sure you want to delete your account?</O>,
		"da": () => <O>Er du sikker på du vil slette din konto?</O>,
		"de": () => <O>Sicher, dass der Account gelöscht werden soll? </O>,
		"sv": () => <O>Är du säker på att du vill radera ditt konto?</O>,
		"no": () => <O>Er du sikker på at du vil slette kontoen din?</O>,
		"fr": () => <O>Êtes-vous sûr de vouloir supprimer votre compte ?</O>,
		"nl": () => <O>Weet u zeker dat u uw account wilt verwijderen?</O>,
	},

	"173a7faee57a": {
		"en": () => <O>Select your chat name</O>,
		"da": () => <O>Vælg navn til chatten</O>,
		"de": () => <O>Chat-Namen auswählen</O>,
		"sv": () => <O>Välj chattnamn</O>,
		"no": () => <O>Velg chatnavn</O>,
		"fr": () => <O>Sélectionnez votre nom de chat</O>,
		"nl": () => <O>Kies uw chat naam</O>,
	},

	"da608f8dc670": {
		"en": () => <O>This will be the name you chat with. It has a limit of 25 characters.</O>,
		"da": () => <O>Dette er dit chatnavn. Maksimalt 25 tegn.</O>,
		"de": () => <O>Dieser Name wird beim Chatten angezeigt. Er ist auf 25 Zeichen begrenzt.</O>,
		"sv": () => <O>Det här är namnet du kommer att chatta med. Du kan använda max 25 tecken.</O>,
		"no": () => <O>Dette vil være navnet du chatter med. Det har en grense på 25 tegn.</O>,
		"fr": () => <O>Il s'agit du nom avec lequel vous chattez. Il est limité à 25 caractères.</O>,
		"nl": () => <O>Dit zal de naam zijn waarmee je chat. Het heeft een limiet van 25 tekens.</O>,
	},

	"cb6860d535cd": {
		"en": () => <O>Set chat name</O>,
		"da": () => <O>Vælg navn til chatten</O>,
		"de": () => <O>Chat-Namen festlegen</O>,
		"sv": () => <O>Ange chattnamn</O>,
		"no": () => <O>Bestem chatnavn</O>,
		"fr": () => <O>Définir le nom du chat</O>,
		"nl": () => <O>Chatnaam instellen</O>,
	},

	"b21d438814a2": {
		"en": () => <O>Chat name</O>,
		"da": () => <O>Chat-navn</O>,
		"de": () => <O>Chat-Name</O>,
		"sv": () => <O>Chattnamn</O>,
		"no": () => <O>Chatnavn</O>,
		"fr": () => <O>Nom du chat</O>,
		"nl": () => <O>Chat naam</O>,
	},

	"f14b509798dd": {
		"en": () => <O>Reset password</O>,
		"da": () => <O>Nulstil kodeord</O>,
		"de": () => <O>Passwort zurücksetzen</O>,
		"sv": () => <O>Återställ lösenord</O>,
		"no": () => <O>Gjenopprett passord</O>,
		"fr": () => <O>Réinitialiser le mot de passe</O>,
		"nl": () => <O>Wachtwoord aanpassen</O>,
	},

	"aa92bbdc6bc2": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Indtast en gyldig email</O>,
		"de": () => <O>Bitte eine gültige E-Mail eingeben</O>,
		"en": () => <O>Please insert a valid email</O>,
		"fr": () => <O>Please insert a valid email</O>,
		"nl": () => <O>Voer een geldig e-mailadres in</O>,
		"no": () => <O>Vennligst oppgi en gyldig e-post</O>,
		"sv": () => <O> Vänligen ange en giltig e-postadress</O>,
	},

	"b63113a8c78a": {
		"en": () => <O>My account</O>,
		"da": () => <O>Min konto</O>,
		"de": () => <O>Mein Konto</O>,
		"sv": () => <O>Mitt konto</O>,
		"no": () => <O>Min konto</O>,
		"fr": () => <O>Mon compte</O>,
		"nl": () => <O>Mijn account</O>,
	},

	"d2d0e6f5eb7a": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Log ud</O>,
		"de": () => <O>Abmelden</O>,
		"en": () => <O>Logout</O>,
		"fr": () => <O>Logout</O>,
		"nl": () => <O>Log uit</O>,
		"no": () => <O>Logg ut</O>,
		"sv": () => <O> Logga ut</O>,
	},

	"252b1d113a7e": {
		"en": () => <O>Account information</O>,
		"da": () => <O>Kontodetaljer</O>,
		"de": () => <O>Informationen zum Konto</O>,
		"sv": () => <O>Kontoinformation</O>,
		"no": () => <O>Kontoinformasjon</O>,
		"fr": () => <O>Informations sur le compte</O>,
		"nl": () => <O>Accountinformatie</O>,
	},

	"38e941b1feb8": {
		"en": () => <O>First name</O>,
		"da": () => <O>Fornavn</O>,
		"de": () => <O>Vorname</O>,
		"sv": () => <O>Förnamn</O>,
		"no": () => <O>Fornavn</O>,
		"fr": () => <O>Prénom</O>,
		"nl": () => <O>Voornaam</O>,
	},

	"e1eb91616a5b": {
		"en": () => <O>Email</O>,
		"da": () => <O>Email</O>,
		"de": () => <O>E-Mail</O>,
		"sv": () => <O>Epost</O>,
		"no": () => <O>E-postadresse</O>,
		"fr": () => <O>Courriel</O>,
		"nl": () => <O>E-mailadres</O>,
	},

	"1c35ded06fc8": {
		"en": () => <O>Zip/Postal Code</O>,
		"da": () => <O>Postnummer</O>,
		"de": () => <O>Postleitzahl</O>,
		"sv": () => <O>Postnummer</O>,
		"no": () => <O>Postnummer</O>,
		"fr": () => <O>Code postal</O>,
		"nl": () => <O>Postcode</O>,
	},

	"7192bad2833d": {
		"en": () => <O>Job title</O>,
		"da": () => <O>Jobtitel</O>,
		"de": () => <O>Job-Titel</O>,
		"sv": () => <O>Jobbtitel</O>,
		"no": () => <O>Jobbtittel</O>,
		"fr": () => <O>Titre du poste</O>,
		"nl": () => <O>Functietitel</O>,
	},

	"f2873726a713": {
		"en": () => <O>Last name</O>,
		"da": () => <O>Efternavn</O>,
		"de": () => <O>Nachname</O>,
		"sv": () => <O>Efternamn</O>,
		"no": () => <O>Etternavn</O>,
		"fr": () => <O>Nom de famille</O>,
		"nl": () => <O>Achternaam</O>,
	},

	"42d1a4bfbc99": {
		"en": () => <O>Forgot password?</O>,
		"da": () => <O>Glemt kodeord</O>,
		"de": () => <O>Passwort vergessen</O>,
		"sv": () => <O>Glömt lösenordet</O>,
		"no": () => <O>Glemt passord</O>,
		"fr": () => <O>Mot de passe oublié ?</O>,
		"nl": () => <O>Wachtwoord vergeten?</O>,
	},

	"4ff7eb1a1772": {
		"en": (guideText) => <O>{guideText}</O>,
		"da": (guideText) => <O>{guideText}</O>,
		"de": (guideText) => <O>{guideText}</O>,
		"sv": (guideText) => <O>{guideText}</O>,
		"no": (guideText) => <O>{guideText}</O>,
		"fr": (guideText) => <O>{guideText}</O>,
		"nl": (guideText) => <O>{guideText}</O>,
	},

	"15fc4cdfbd43": {
		"da": () => <O>Din email</O>,
		"de": () => <O>Deine E-Mail</O>,
		"en": () => <O>Your email</O>,
		"fr": () => <O>Votre e-mail</O>,
		"nl": () => <O>Uw e-mail</O>,
		"no": () => <O>Din epost</O>,
		"sv": () => <O>Din e-postadress</O>,
	},

	"a977cc64e2fa": {
		"en": () => <O> to delete this account</O>,
		"da": () => <O> for at slette denne konto</O>,
		"de": () => <O> um dieses Konto zu löschen</O>,
		"sv": () => <O> to delete this account</O>,
		"no": () => <O> for å slette brukeren</O>,
		"fr": () => <O> to delete this account</O>,
		"nl": () => <O> to delete this account</O>,
	},

	"bbbe0f577f4b": {
		"en": () => <O>Delete event</O>,
		"da": () => <O>Slet event</O>,
		"de": () => <O>Event löschen</O>,
		"sv": () => <O>Radera evenemang</O>,
		"no": () => <O>Slett arrangement</O>,
		"fr": () => <O>Supprimer l'événement</O>,
		"nl": () => <O>Gebeurtenis verwijderen</O>,
	},

	"8b05d582fe8f": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => {
			return (
				<O>
					You have added more than 1 organizer for this event. This is against Zevio company policy, so please
					confirm with Zevio that is is okay, before proceeding with this approach.
				</O>
			);
		},
		"de": () => {
			return (
				<O>
					You have added more than 1 organizer for this event. This is against Zevio company policy, so please
					confirm with Zevio that is is okay, before proceeding with this approach.
				</O>
			);
		},
		"en": () => {
			return (
				<O>
					You have added more than 1 organizer for this event. This is against Zevio company policy, so please
					confirm with Zevio that is is okay, before proceeding with this approach.
				</O>
			);
		},
		"fr": () => {
			return (
				<O>
					You have added more than 1 organizer for this event. This is against Zevio company policy, so please
					confirm with Zevio that is is okay, before proceeding with this approach.
				</O>
			);
		},
		"nl": () => {
			return (
				<O>
					You have added more than 1 organizer for this event. This is against Zevio company policy, so please
					confirm with Zevio that is is okay, before proceeding with this approach.
				</O>
			);
		},
		"no": () => {
			return (
				<O>
					You have added more than 1 organizer for this event. This is against Zevio company policy, so please
					confirm with Zevio that is is okay, before proceeding with this approach.
				</O>
			);
		},
		"sv": () => {
			return (
				<O>
					You have added more than 1 organizer for this event. This is against Zevio company policy, so please
					confirm with Zevio that is is okay, before proceeding with this approach.
				</O>
			);
		},
	},

	"11d94ba1306b": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Gør aktiv</O>,
		"de": () => <O>Aktiv gesetzt</O>,
		"en": () => <O>Set active</O>,
		"fr": () => <O>Set active</O>,
		"nl": () => <O>Activeren</O>,
		"no": () => <O>Aktiver</O>,
		"sv": () => <O>Aktivera</O>,
	},

	"08f363768397": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Skjul produkt</O>,
		"de": () => <O>Produkt ausblenden</O>,
		"en": () => <O>Hide product</O>,
		"fr": () => <O>Hide product</O>,
		"nl": () => <O>Verberg product</O>,
		"no": () => <O>Skjul produkt</O>,
		"sv": () => <O> Dölj produkt</O>,
	},

	"48201130a821": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Produkt gemt</O>,
		"de": () => <O>Produkt gespeichert</O>,
		"en": () => <O>Product saved</O>,
		"fr": () => <O>Product saved</O>,
		"nl": () => <O>Product bewaard</O>,
		"no": () => <O>Produkt lagret</O>,
		"sv": () => <O> Produkten sparad</O>,
	},

	"177db5f4fb64": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Deaktiver</O>,
		"de": () => <O>Deaktivieren</O>,
		"en": () => <O>DeActivate</O>,
		"fr": () => <O>DeActivate</O>,
		"nl": () => <O>Deactiveren</O>,
		"no": () => <O>Deaktiver</O>,
		"sv": () => <O>Avaktivera</O>,
	},

	"6a2e8d0ef3c5": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Inaktiv</O>,
		"de": () => <O>Inaktiv</O>,
		"en": () => <O>InActive</O>,
		"fr": () => <O>InActive</O>,
		"nl": () => <O>Inactief</O>,
		"no": () => <O>Inaktiv</O>,
		"sv": () => <O>Inaktiv</O>,
	},

	"c9ff15cc8bcd": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Aktiv</O>,
		"de": () => <O>Aktiv</O>,
		"en": () => <O>Active</O>,
		"fr": () => <O>Active</O>,
		"nl": () => <O>Actief</O>,
		"no": () => <O>Aktiv</O>,
		"sv": () => <O>Aktiva</O>,
	},

	"a849f4d6d560": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Gør dette produkt aktivt / inaktivt</O>,
		"de": () => <O>Dieses Produkt aktiv / inaktiv setzen</O>,
		"en": () => <O>Make this product active / inactive</O>,
		"fr": () => <O>Make this product active / inactive</O>,
		"nl": () => <O>Dit product actief / inactief maken</O>,
		"no": () => <O>Aktiver / deaktiver produkt</O>,
		"sv": () => <O> Gör denna produkt aktiv/inaktiv</O>,
	},

	"88ebe9f51258": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Forsendelse?</O>,
		"de": () => <O>Versandfähig</O>,
		"en": () => <O>Shippable</O>,
		"fr": () => <O>Shippable</O>,
		"nl": () => <O>Verzendbaar</O>,
		"no": () => <O>Kan sendes</O>,
		"sv": () => <O>Kan skickas</O>,
	},

	"4a673148d78a": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Tilføj porto, hvis produktet skal sendes</O>,
		"de": () => <O>Kann dieses Produkt versandt werden</O>,
		"en": () => <O>Can this product be shipped</O>,
		"fr": () => <O>Can this product be shipped</O>,
		"nl": () => <O>Kan dit product worden verzonden?</O>,
		"no": () => <O>Legg til frakt om produktet skal sendes</O>,
		"sv": () => <O> Lägg till porto om produkten behöver skickas</O>,
	},

	"af02f428e2e0": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Forsendelse</O>,
		"de": () => <O>Versandkosten</O>,
		"en": () => <O>Price of shipping</O>,
		"fr": () => <O>Price of shipping</O>,
		"nl": () => <O>Price of shipping</O>,
		"no": () => <O>Fraktkostnad</O>,
		"sv": () => <O>Price of shipping</O>,
	},

	"b50065b5d46d": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Skattepligtig</O>,
		"de": () => <O>Steuerpflichtig</O>,
		"en": () => <O>Taxable</O>,
		"fr": () => <O>Taxable</O>,
		"nl": () => <O>Belastbaar</O>,
		"no": () => <O>Skattepliktig</O>,
		"sv": () => <O> Beskattningsbar</O>,
	},

	"dc3143c218ae": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Skal der opkræves moms på dette produkt?</O>,
		"de": () => <O>Ist dieses Produkt steuerpflichtig</O>,
		"en": () => <O>Is this product taxable</O>,
		"fr": () => <O>Is this product taxable</O>,
		"nl": () => <O>Is dit product belastbaar?</O>,
		"no": () => <O>Er produktet skattepliktig</O>,
		"sv": () => <O> Är denna produkt beskattningsbar</O>,
	},

	"16ca031eff90": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>ISBN (Dagens Bog)</O>,
		"de": () => <O>ISBN (Dagens Bog)</O>,
		"en": () => <O>ISBN (Dagens Bog)</O>,
		"fr": () => <O>ISBN (Dagens Bog)</O>,
		"nl": () => <O>ISBN (Dagens Bog)</O>,
		"no": () => <O>ISBN (Dagens Bok)</O>,
		"sv": () => <O>ISBN (Dagens Bog)</O>,
	},

	"66141b930090": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Eksternt link</O>,
		"de": () => <O>Externer Link</O>,
		"en": () => <O>External link</O>,
		"fr": () => <O>External link</O>,
		"nl": () => <O>External link</O>,
		"no": () => <O>Ekstern lenke</O>,
		"sv": () => <O>External link</O>,
	},

	"1f9e73bcea2e": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Inkludér hele linket inklusive http eller https</O>,
		"de": () => <O>Bitte vollständigen Link mit http oder https angeben</O>,
		"en": () => <O>Please include full link containing http or https</O>,
		"fr": () => <O>Please include full link containing http or https</O>,
		"nl": () => <O>Please include full link containing http or https</O>,
		"no": () => <O>Please include full link containing http or https</O>,
		"sv": () => <O>Please include full link containing http or https</O>,
	},

	"a652967a13c5": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Hvis dette eksisterer vil 'tilføj til kurv'-linket sende folk herhen.</O>,
		"de": () => <O>Wenn dieser vorhanden ist, wird der Button "In den Warenkorb" dorthin verlinken</O>,
		"en": () => <O>If this exists, the add to cart button will link there.</O>,
		"fr": () => <O>If this exists, the add to cart button will link there.</O>,
		"nl": () => <O>If this exists, the add to cart button will link there.</O>,
		"no": () => <O>If this exists, the add to cart button will link there.</O>,
		"sv": () => <O>If this exists, the add to cart button will link there.</O>,
	},

	"74d02dc18643": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Produktet udløbsdato (valgfri)</O>,
		"de": () => <O>Verfallsdatum des Produkts</O>,
		"en": () => <O>Product expiry date</O>,
		"fr": () => <O>Product expiry date</O>,
		"nl": () => <O>Product expiry date</O>,
		"no": () => <O>Produktet utløper</O>,
		"sv": () => <O>Product expiry date</O>,
	},

	"14e501d7508e": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Fjern dato</O>,
		"de": () => <O>Datum löschen</O>,
		"en": () => <O>Clear date</O>,
		"fr": () => <O>Clear date</O>,
		"nl": () => <O>Clear date</O>,
		"no": () => <O>Clear date</O>,
		"sv": () => <O>Clear date</O>,
	},

	"4c4cc98aeb93": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Fra denne dag er produktet ikke længere synligt.</O>,
		"de": () => <O>Ab diesem Datum wird das Produkt nicht mehr aktiv sein.</O>,
		"en": () => <O>Starting this date, the product no longer will be active.</O>,
		"fr": () => <O>Starting this date, the product no longer will be active.</O>,
		"nl": () => <O>Starting this date, the product no longer will be active.</O>,
		"no": () => <O>Produktet deaktiveres fra og med denne datoen</O>,
		"sv": () => <O>Starting this date, the product no longer will be active.</O>,
	},

	"43c1b35ef328": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Klik for at vælge dato</O>,
		"de": () => <O>Zum Auswählen des Datums klicken</O>,
		"en": () => <O>Click to select date</O>,
		"fr": () => <O>Click to select date</O>,
		"nl": () => <O>Click to select date</O>,
		"no": () => <O>Trykk for å velge dato</O>,
		"sv": () => <O>Click to select date</O>,
	},

	"bf3bd34ec4de": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Kontaktinformationer</O>,
		"de": () => <O>Kontaktinformationen</O>,
		"en": () => <O>Contact details</O>,
		"fr": () => <O>Contact details</O>,
		"nl": () => <O>Contact details</O>,
		"no": () => <O>Kontaktinformasjon</O>,
		"sv": () => <O>Kontaktuppgifter</O>,
	},

	"f192b23686fa": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Navn/firma</O>,
		"de": () => <O>Name Eigentümer</O>,
		"en": () => <O>Owner name</O>,
		"fr": () => <O>Owner name</O>,
		"nl": () => <O>Naam eigenaar</O>,
		"no": () => <O>Navn/Bedrift</O>,
		"sv": () => <O> Ägarnamn</O>,
	},

	"02bc9be02932": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Produktejerens navn/firma vises til kunden på zevio.com</O>,
		"de": () => <O>Name des Produkteigentümers, der auf den Beleg gedruckt werden soll</O>,
		"en": () => <O>Product owner name to be printed on receipt</O>,
		"fr": () => <O>Product owner name to be printed on receipt</O>,
		"nl": () => <O>Naam van de producteigenaar die op de bon moet worden afgedrukt</O>,
		"no": () => <O>Produkteiers navn som printes på kvitteringen</O>,
		"sv": () => <O> Produktägarens namn ska skrivas ut på kvittot</O>,
	},

	"050b11b43845": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Navnet på produktejeren (f.eks. Georg Gabaldi)</O>,
		"de": () => <O>Name des Produkteigentümers (z. B. Chris McDonald)</O>,
		"en": () => <O>The name of the product owner (e.g. Chris McDonald)</O>,
		"fr": () => <O>The name of the product owner (e.g. Chris McDonald)</O>,
		"nl": () => <O>The name of the product owner (e.g. Chris McDonald)</O>,
		"no": () => <O>Navnet på produkteier (e.g. Chris McDonald)</O>,
		"sv": () => <O>The name of the product owner (e.g. Chris McDonald)</O>,
	},

	"2a5cf168c043": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Vælg arrangører</O>,
		"de": () => <O>Verfügbare Veranstalter</O>,
		"en": () => <O>Available organizers</O>,
		"fr": () => <O>Organisateurs disponibles</O>,
		"nl": () => <O>Beschikbare organisatoren</O>,
		"no": () => <O>Tilgjengelige arrangører</O>,
		"sv": () => <O>Tillgängliga arrangörer</O>,
	},

	"1df20fb652bf": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Vælg de arrangører, der kan sælge dette produkt (hvis flere end én)</O>,
		"de": () => <O>Welche Veranstalter dürfen dieses Produkt zum Verkauf anbieten?</O>,
		"en": () => <O>Which organisers are allowed to put this product up for sale?</O>,
		"fr": () => <O>Quels organisateurs sont autorisés à mettre ce produit en vente ?</O>,
		"nl": () => <O>Welke organisatoren mogen dit product te koop aanbieden?</O>,
		"no": () => <O>Hvilke arrangører kan selge dette produktet?</O>,
		"sv": () => <O>Vilka arrangörer får lägga ut den här produkten till försäljning?</O>,
	},

	"54f69d56b773": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Udsendelses URL:</O>,
		"de": () => <O>Sende-URL:</O>,
		"en": () => <O>Broadcast URL:</O>,
		"fr": () => <O>URL de diffusion :</O>,
		"nl": () => <O>Uitzend-URL:</O>,
		"no": () => <O>Broadcast URL:</O>,
		"sv": () => <O>Sändnings URL:</O>,
	},

	"fe92e13ce15a": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>KOPIER</O>,
		"de": () => <O>KOPIEREN</O>,
		"en": () => <O>COPY</O>,
		"fr": () => <O>COPIER</O>,
		"nl": () => <O>KOPIËREN</O>,
		"no": () => <O>KOPIER</O>,
		"sv": () => <O> KOPIERA</O>,
	},

	"1c14d3e62e3f": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Ikke tilgængelig</O>,
		"de": () => <O>Nicht verfügbar</O>,
		"en": () => <O>Not available</O>,
		"fr": () => <O>Non disponible</O>,
		"nl": () => <O>Niet beschikbaar</O>,
		"no": () => <O>Ikke tilgjengelig</O>,
		"sv": () => <O> Inte tillgänglig</O>,
	},

	"2d6a01e441d5": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Luk</O>,
		"de": () => <O>Schließen</O>,
		"en": () => <O>Close</O>,
		"fr": () => <O>Fermer</O>,
		"nl": () => <O>Sluiten</O>,
		"no": () => <O>Steng</O>,
		"sv": () => <O>Stäng</O>,
	},

	"74916e2692ee": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Udsendelsen vises her:</O>,
		"de": () => <O>URL für die Live-Übertragung der Veranstaltung:</O>,
		"en": () => <O>Live event watch URL:</O>,
		"fr": () => <O>URL de visionnage de l'événement en direct :</O>,
		"nl": () => <O>URL voor live-evenement kijken:</O>,
		"no": () => <O>URL for å se sendingen:</O>,
		"sv": () => <O>URL för att titta på liveevenemang:</O>,
	},

	"b3b97b6cf7cd": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>RTMPS-nøgle:</O>,
		"de": () => <O>Sende Taste:</O>,
		"en": () => <O>Broadcast key:</O>,
		"fr": () => <O>Clé de diffusion :</O>,
		"nl": () => <O>Uitzendsleutel:</O>,
		"no": () => <O>Broadcast key:</O>,
		"sv": () => <O>Sändningsnyckel:</O>,
	},

	"4ac95b268ec8": {
		"en": () => <O>Make visible</O>,
		"da": () => <O>Gør synlig</O>,
		"de": () => <O>Sichtbar machen</O>,
		"sv": () => <O>Synliggöra</O>,
		"no": () => <O>Gjør synlig</O>,
		"fr": () => <O>Rendre visible</O>,
		"nl": () => <O>Maak zichtbaar</O>,
	},

	"dba81a48ff7f": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Send fra din browser</O>,
		"de": () => <O>Gehen Sie live aus Ihrem Browser</O>,
		"en": () => <O>Go live from browser</O>,
		"fr": () => <O>Passer en direct depuis votre navigateur</O>,
		"nl": () => <O>Ga live vanuit uw browser</O>,
		"no": () => <O>Gå live fra nettleseren din</O>,
		"sv": () => <O>Gå live från din webbläsare</O>,
	},

	"57d59e06eafb": {
		"da": () => <O>Stream info</O>,
		"de": () => <O>Sendeinfo</O>,
		"en": () => <O>Stream info</O>,
		"fr": () => <O>Stream info</O>,
		"nl": () => <O>Uitzend info</O>,
		"no": () => <O>Sendingsinformasjon</O>,
		"sv": () => <O>Sändningsinformation</O>,
	},

	"fe0ad2701a38": {
		"da": () => <O>Aktivér stream</O>,
		"de": () => <O>Activate event</O>,
		"en": () => <O>Activate event</O>,
		"fr": () => <O>Activate event</O>,
		"nl": () => <O>Activate event</O>,
		"no": () => <O>Activate event</O>,
		"sv": () => <O>Activate event</O>,
	},

	"6b015b16a42e": {
		"da": () => <O>Deaktivér stream</O>,
		"de": () => <O>Deactivate event</O>,
		"en": () => <O>Deactivate event</O>,
		"fr": () => <O>Deactivate event</O>,
		"nl": () => <O>Deactivate event</O>,
		"no": () => <O>Deactivate event</O>,
		"sv": () => <O>Deactivate event</O>,
	},

	"0af811c9025b": {
		"en": () => <O>Approved</O>,
		"da": () => <O>Godkendt</O>,
		"de": () => <O>Genehmigt</O>,
		"sv": () => <O>Godkänd</O>,
		"no": () => <O>Godkjent</O>,
		"fr": () => <O>Approuvé</O>,
		"nl": () => <O>Goedgekeurd</O>,
	},

	"cb9040e39b0b": {
		"en": () => <O>Pending</O>,
		"da": () => <O>Afventer</O>,
		"de": () => <O>Anhängig</O>,
		"sv": () => <O>Pågående</O>,
		"no": () => <O>Venter</O>,
		"fr": () => <O>En attente</O>,
		"nl": () => <O>Hangende</O>,
	},

	"263e2b755a2d": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>All</O>,
		"de": () => <O>All</O>,
		"en": () => <O>All</O>,
		"fr": () => <O>All</O>,
		"nl": () => <O>All</O>,
		"no": () => <O>All</O>,
		"sv": () => <O>All</O>,
	},

	"24a13e6fb5c0": {
		"en": () => <O>Not approved</O>,
		"da": () => <O>Ikke godkendt</O>,
		"de": () => <O>Nicht genehmigt</O>,
		"sv": () => <O>Ej godkänt</O>,
		"no": () => <O>Not approved</O>,
		"fr": () => <O>Non approuvé</O>,
		"nl": () => <O>Niet goedgekeurd</O>,
	},

	"38bd554f8118": {
		"en": () => <O>Hidden</O>,
		"da": () => <O>Skjult</O>,
		"de": () => <O>Versteckt</O>,
		"sv": () => <O>Dold</O>,
		"no": () => <O>Skjult</O>,
		"fr": () => <O>Caché</O>,
		"nl": () => <O>Verborgen</O>,
	},

	"7f0991f7a4cd": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Ikke aktiv</O>,
		"de": () => <O>Inaktiv</O>,
		"en": () => <O>Inactive</O>,
		"fr": () => <O>Inactif</O>,
		"nl": () => <O>Inactief</O>,
		"no": () => <O>Inaktiv</O>,
		"sv": () => <O>Inaktiv</O>,
	},

	"e5409d245f8c": {
		"en": () => <O>Visible</O>,
		"da": () => <O>Synlig</O>,
		"de": () => <O>Sichtbar</O>,
		"sv": () => <O>Synlig</O>,
		"no": () => <O>Synlig</O>,
		"fr": () => <O>Visible</O>,
		"nl": () => <O>Zichtbaar</O>,
	},

	"696473711203": {
		"da": () => <O>Broadcast info</O>,
		"de": () => <O>Sendeinfo</O>,
		"en": () => <O>Broadcast info</O>,
		"fr": () => <O>Broadcast info</O>,
		"nl": () => <O>Uitzend info</O>,
		"no": () => <O>Sendingsinformasjon</O>,
		"sv": () => <O>Sändningsinformation</O>,
	},

	"e0bb38372b77": {
		"en": () => <O>Request approval</O>,
		"da": () => <O>Anmod om godkendelse</O>,
		"de": () => <O>Antrag auf Zulassung</O>,
		"sv": () => <O>Begäran om godkännande</O>,
		"no": () => <O>Be om godkjenning</O>,
		"fr": () => <O>Demande d'autorisation</O>,
		"nl": () => <O>Verzoek goedkeuring</O>,
	},

	"232cba6a85f6": {
		"da": () => <O>Live page</O>,
		"de": () => <O>Aktuelle Seite</O>,
		"en": () => <O>Live page</O>,
		"fr": () => <O>Live page</O>,
		"nl": () => <O>Live page</O>,
		"no": () => <O>Live page</O>,
		"sv": () => <O>Live page</O>,
	},

	"f8ac87e2e2a8": {
		"en": () => <O>Hide event</O>,
		"da": () => <O>Skjul denne stream</O>,
		"de": () => <O>Vortrag ausblenden</O>,
		"sv": () => <O>Dölj föreläsningen</O>,
		"no": () => <O>Skjul arrangement</O>,
		"fr": () => <O>Cacher l'événement</O>,
		"nl": () => <O>Verberg evenement</O>,
	},

	"03d2ff6cd786": {
		"en": () => <O> to delete this event</O>,
		"da": () => <O> for at slette dette foredrag</O>,
		"de": () => <O> um dieses Event zu löschen</O>,
		"sv": () => <O> to delete this event</O>,
		"no": () => <O> å slette dette arrangementet</O>,
		"fr": () => <O> to delete this event</O>,
		"nl": () => <O> to delete this event</O>,
	},

	"387d13d59f38": {
		"en": () => <O>Type: </O>,
		"da": () => <O>Skriv: </O>,
		"de": () => <O>Type: </O>,
		"sv": () => <O>Type: </O>,
		"no": () => <O>Type: </O>,
		"fr": () => <O>Type: </O>,
		"nl": () => <O>Type: </O>,
	},

	"cb5057b9da38": {
		"en": () => <O>Country</O>,
		"da": () => <O>Land</O>,
		"de": () => <O>Land</O>,
		"sv": () => <O>Land</O>,
		"no": () => <O>Land</O>,
		"fr": () => <O>Pays</O>,
		"nl": () => <O>Land</O>,
	},

	"25d5bd8f4782": {
		"en": () => <O>Phone number (area & country code allowed)</O>,
		"da": () => <O>Telefonnummer (inklusive landekode)</O>,
		"de": () => <O>Telefonnummer (Region und Landesvorwahl erlaubt)</O>,
		"sv": () => <O>Telefonnummer (riktnummer och landsnummer tillåtna)</O>,
		"no": () => <O>Telefonnummer (område- og landskode tillatt)</O>,
		"fr": () => <O>Numéro de téléphone (indicatif régional et national autorisé)</O>,
		"nl": () => <O>Telefoonnummer (netnummer en landnummer toegestaan)</O>,
	},

	"984c86457989": {
		"en": () => <O>Company / Organization</O>,
		"da": () => <O>Firma/organisation</O>,
		"de": () => <O>Unternehmen/Organisation</O>,
		"sv": () => <O>Företag/Organisation</O>,
		"no": () => <O>Bedrift / Organisasjon</O>,
		"fr": () => <O>Entreprise/organisation</O>,
		"nl": () => <O>Bedrijf/organisatie</O>,
	},

	"36738d9cd592": {
		"en": () => <O>Change password</O>,
		"da": () => <O>Skift kodeord</O>,
		"de": () => <O>Passwort ändern</O>,
		"sv": () => <O>Byt lösenord</O>,
		"no": () => <O>Endre passord</O>,
		"fr": () => <O>Modifier le mot de passe</O>,
		"nl": () => <O>Wijzig wachtwoord</O>,
	},

	"71981c963a87": {
		"en": () => <O>New password (Must contain a minimum of 7 characters)</O>,
		"da": () => <O>Nyt kodeord (Skal indeholde mindst 7 tegn)</O>,
		"de": () => <O>Neues Passwort (Muss mindestens 7 Zeichen enthalten)</O>,
		"sv": () => <O>Nytt lösenord (Måste innehålla minst 7 tecken)</O>,
		"no": () => <O>Nytt passord (Må inneholde minst 7 tegn)</O>,
		"fr": () => <O>Nouveau mot de passe (Doit contenir au moins 7 caractères)</O>,
		"nl": () => <O>Nieuw wachtwoord (Moet minimaal 7 tekens bevatten)</O>,
	},

	"9bf5daedf85f": {
		"en": () => <O>New password again (Must contain a minimum of 7 characters)</O>,
		"da": () => <O>Gentag nyt kodeord (Skal indeholde mindst 7 tegn)</O>,
		"de": () => <O>Neues Passwort wiederholen (Muss mindestens 7 Zeichen enthalten)</O>,
		"sv": () => <O>Upprepa nytt lösenord (Måste innehålla minst 7 tecken)</O>,
		"no": () => <O>Nytt passord på nytt (Må inneholde minst 7 tegn)</O>,
		"fr": () => <O>Nouveau mot de passe (Doit contenir au moins 7 caractères)</O>,
		"nl": () => <O>Weer een nieuw wachtwoord (Moet minimaal 7 tekens bevatten)</O>,
	},

	"fa6f7ad4808a": {
		"en": () => <O>Job title</O>,
		"da": () => <O>Stillingsbetegnelse</O>,
		"de": () => <O>Job-Titel</O>,
		"sv": () => <O>Jobbtitel</O>,
		"no": () => <O>Jobbtittel</O>,
		"fr": () => <O>Titre du poste</O>,
		"nl": () => <O>Functietitel</O>,
	},

	"3f891083f889": {
		"da": () => <O>Indtast jobtitel eller skriv privat</O>,
		"de": () => <O>Bitte Stellenbezeichnung einfügen</O>,
		"en": () => <O>Please insert job title</O>,
		"fr": () => <O>Please insert job title</O>,
		"nl": () => <O>Vul a.u.b. functietitel in</O>,
		"no": () => <O>Oppgi stillingstittel</O>,
		"sv": () => <O>Vänligen ange jobbtitel</O>,
	},

	"4a23f8a1b752": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Opret bruger</O>,
		"de": () => <O>Konto erstellen</O>,
		"en": () => <O>Create account</O>,
		"fr": () => <O>Créer compte</O>,
		"nl": () => <O>Account aanmaken</O>,
		"no": () => <O>Opprett konto</O>,
		"sv": () => <O>Skapa konto</O>,
	},

	"00c4fedee38b": {
		"en": () => <O>Company / Organization</O>,
		"da": () => <O>Firma/organisation</O>,
		"de": () => <O>Unternehmen/Organisation</O>,
		"sv": () => <O>Företag/Organisation</O>,
		"no": () => <O>Bedrift / Organisasjon</O>,
		"fr": () => <O>Entreprise/organisation</O>,
		"nl": () => <O>Bedrijf/organisatie</O>,
	},

	"30649e577015": {
		"da": () => <O>Indtast virksomhed eller skriv Privat</O>,
		"de": () => <O>Bitte Firma einfügen</O>,
		"en": () => <O>Please insert a company</O>,
		"fr": () => <O>Please insert a company</O>,
		"nl": () => <O>Voer een bedrijf in</O>,
		"no": () => <O>Oppgi bedrift</O>,
		"sv": () => <O> Vänligen ange ett företag</O>,
	},

	"bce24af2a49a": {
		"en": () => <O>Phone no.</O>,
		"da": () => <O>Telefonnummer</O>,
		"de": () => <O>Telefon-Nr.</O>,
		"sv": () => <O>Telefonnummer</O>,
		"no": () => <O>Telefonnummer</O>,
		"fr": () => <O>Numéro de téléphone</O>,
		"nl": () => <O>Telefoon</O>,
	},

	"06682977826f": {
		"da": () => <O>Indsæt telefonnummer eller 0</O>,
		"de": () => <O>Bitte Telefonnummer einfügen</O>,
		"en": () => <O>Please insert a phone number</O>,
		"fr": () => <O>Please insert a phone number</O>,
		"nl": () => <O>Voer een telefoonnummer in</O>,
		"no": () => <O>Oppgi telefonnummer</O>,
		"sv": () => <O> Vänligen ange ett telefonnummer</O>,
	},

	"a7fbcb748f11": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Bestilling gennemført!</O>,
		"de": () => <O>Kauf abgeschlossen!</O>,
		"en": () => <O>Purchase complete!</O>,
		"fr": () => <O>Achat terminé !</O>,
		"nl": () => <O>Aankoop voltooid!</O>,
		"no": () => <O>Kjøpet er fullført!</O>,
		"sv": () => <O>Köp slutfört!</O>,
	},

	"68fae65cdb35": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Videre til kassen</O>,
		"de": () => <O>Zur Kasse gehen</O>,
		"en": () => <O>Continue to checkout</O>,
		"fr": () => <O>Passer à la caisse</O>,
		"nl": () => <O>Doorgaan naar afrekenen</O>,
		"no": () => <O>Fortsett til kassen</O>,
		"sv": () => <O>Fortsätt till kassan</O>,
	},

	"d976365de3d1": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Til kassen</O>,
		"de": () => <O>Zur Kasse gehen</O>,
		"en": () => <O>Checkout</O>,
		"fr": () => <O>Aller à la caisse</O>,
		"nl": () => <O>Naar de kassa</O>,
		"no": () => <O>Til kassen</O>,
		"sv": () => <O>Till kassan</O>,
	},

	"0fe2380f5672": {
		"da": () => <O>Nej tak</O>,
		"de": () => <O>Nein Danke</O>,
		"en": () => <O>No thanks</O>,
		"fr": () => <O>No thanks</O>,
		"nl": () => <O>Nee bedankt</O>,
		"sv": () => <O>Nej tack</O>,
		"no": () => <O>Nei takk</O>,
	},

	"d1e6e6d19489": {
		"en": () => <O>Password*</O>,
		"da": () => <O>Kodeord*</O>,
		"de": () => <O>Passwort*</O>,
		"sv": () => <O>Lösenord*</O>,
		"no": () => <O>Passord*</O>,
		"fr": () => <O>Mot de passe*</O>,
		"nl": () => <O>Wachtwoord*</O>,
	},

	"f7654b9f0a1f": {
		"en": () => <O>Password again</O>,
		"da": () => <O>Gentag kodeord</O>,
		"de": () => <O>Passwort wiederholen</O>,
		"sv": () => <O>Upprepa lösenord</O>,
		"no": () => <O>passord på nytt</O>,
		"fr": () => <O>Nouveau mot de passe</O>,
		"nl": () => <O>Wachtwoord opnieuw</O>,
	},

	"38862ed7a343": {
		"da": (email) => {
			return (
				<O>Denne mailadresse <tt>{email}</tt> har allerede en konto på Zevio. Klik på knappen herunder for at logge ind.</O>
			);
		},
		"de": (email) => {
			return (
				<O>Diese E-Mail-Adresse <tt>{email}</tt> hat bereits ein Konto bei Zevio. Klicken Sie unten, um zur Anmeldeseite zu gelangen.</O>
			);
		},
		"en": (email) => {
			return (
				<O>The email address <tt>{email}</tt> already has an account on Zevio. Click below
				to go to the login page.</O>
			);
		},
		"fr": (email) => {
			return (
				<O>The email address <tt>{email}</tt> already has an account on Zevio. Click below
				to go to the login page.</O>
			);
		},
		"nl": (email) => {
			return (
				<O>Het email adres <tt>{email}</tt> heeft al een account op Zevio. Klik hieronder om naar de inlogpagina te gaan.</O>
			);
		},
		"no": (email) => {
			return (
				<O>Denne e-postadressen <tt>{email}</tt> er allerede tilknyttet en Zevio-bruker. Trykk under				for å logge inn.</O>
			);
		},
		"sv": (email) => {
			return (
				<O>E-postadressen <tt>{email}</tt>  har redan ett konto på Zevio. Klicka nedan                        för att gå till inloggningssidan.</O>
			);
		},
	},

	"895637f91045": {
		"da": (LL) => {
			return (
				<O>
					Ved at indtaste mit navn &amp; email og tilmelde mig denne stream giver jeg
					tilladelse til, at Zevio kan bruge mit navn, min email og informationer om hvad
					jeg har set, til at sende nyhedsbreve om streams og streamere, samt relaterede
					produkter. Jeg kan til enhver tid tilbagetrække mit samtykke via linket i
					nyhedsbrevet og min personlige information bliver behandlet i overensstemmelse
					med Zevios <a target="_blank" rel="noopener noreferrer"
					href={LL("/privacy")}>privatlivspolitik.</a>.
				</O>
			);
		},
		"de": (LL) => {
			return (
				<O>
					Indem ich meinen Namen und meine E-Mail-Adresse eingebe und mich für das Webinar
					anmelde, erkläre ich mich damit einverstanden, dass Zevio meinen Namen, meine
					E-Mail-Adresse und Informationen über meine Anzeigepräferenzen für die Zusendung
					von Newslettern mit Marketinginformationen von Zevio über Referenten und
					Webinare verwendet. Meine Einwilligung kann ich jederzeit über den Link im
					Newsletter widerrufen und meine persönlichen Daten werden in Übereinstimmung
					verwendet mit Zevios <a target="_blank" rel="noopener noreferrer"
					href={LL("/privacy")}>Datenschutzbestimmungen</a>.
				</O>
			);
		},
		"en": (LL) => {
			return (
				<O>
					By entering my name and email and signing up for the webinar, I consent to
					Zevio using my name, email address, and information about my viewing
					preferences to send newsletters containing marketing information from
					Zevio about speakers and webinars. My consent may at any time be revoked
					using the link in the newsletter and my personal information is processed in
					accordance with Zevios <a target="_blank" rel="noopener noreferrer"
					href={LL("/privacy")}>Privacy Policy</a>.
				</O>
			);
		},
		"fr": (LL) => {
			return (
				<O>
					By entering my name and email and signing up for the webinar, I consent to
					Zevio using my name, email address, and information about my viewing
					preferences to send newsletters containing marketing information from
					Zevio about speakers and webinars. My consent may at any time be revoked
					using the link in the newsletter and my personal information is processed in
					accordance with Zevios <a target="_blank" rel="noopener noreferrer"
					href={LL("/privacy")}>Privacy Policy</a>.
				</O>
			);
		},
		"nl": (LL) => {
			return (
				<O>
					By entering my name and email and signing up for the webinar, I consent to
					Zevio using my name, email address, and information about my viewing
					preferences to send newsletters containing marketing information from
					Zevio about speakers and webinars. My consent may at any time be revoked
					using the link in the newsletter and my personal information is processed in
					accordance with Zevios <a target="_blank" rel="noopener noreferrer"
					href={LL("/privacy")}>Privacy Policy</a>.
				</O>
			);
		},
		"no": (LL) => {
			return (
				<O>
					By entering my name and email and signing up for the webinar, I consent to
					Zevio using my name, email address, and information about my viewing
					preferences to send newsletters containing marketing information from
					Zevio about speakers and webinars. My consent may at any time be revoked
					using the link in the newsletter and my personal information is processed in
					accordance with Zevios <a target="_blank" rel="noopener noreferrer"
					href={LL("/privacy")}>Privacy Policy</a>.
				</O>
			);
		},
		"sv": (LL) => {
			return (
				<O>
					By entering my name and email and signing up for the webinar, I consent to
					Zevio using my name, email address, and information about my viewing
					preferences to send newsletters containing marketing information from
					Zevio about speakers and webinars. My consent may at any time be revoked
					using the link in the newsletter and my personal information is processed in
					accordance with Zevios <a target="_blank" rel="noopener noreferrer"
					href={LL("/privacy")}>Privacy Policy</a>.
				</O>
			);
		},
	},

	"989fe18fe821": {
		"da": (LL, conglomerateNames, toggleOrganizerDataModal) => {
			return (
				<O>
					Ved at indtaste mit navn og email samt tilmelde mig denne stream giver jeg
					tilladelse til, at Zevio kan bruge mit navn, min email og informationer om hvad
					jeg har set, til at sende nyhedsbreve om streams og streamere, samt relaterede
					produkter.<br /><br /><b>Jeg forstår og accepterer, at min data kan blive delt med
					arrangør <a href="/organizers" onClick={toggleOrganizerDataModal}>
					{conglomerateNames.join(', ')}</a> og at
					jeg til enhver tid kan tilbagetrække mit samtykke via linket i nyhedsbrevet og
					at min personlige information bliver behandlet i overensstemmelse med Zevios <a
					target="_blank" rel="noopener noreferrer"
					href={LL("/privacy")}>privatlivspolitik</a></b>.
				</O>
			);
		},
		"de": (LL, conglomerateNames, toggleOrganizerDataModal) => {
			return (
				<O>
					Indem ich meinen Namen und meine E-Mail-Adresse eingebe und mich für das Webinar
					anmelde, erkläre ich mich damit einverstanden, dass Zevio meinen Namen, meine
					E-Mail-Adresse und Informationen über meine Anzeigepräferenzen verwendet, um Newsletter
					mit Marketinginformationen von Zevio über Referenten und Webinare zu versenden.
					<br /><br />Mir ist bekannt, dass meine Daten an die folgenden Stellen
					weitergeleitet werden können <a
					href="/organizers" onClick={toggleOrganizerDataModal}>{conglomerateNames.join(', ')}</a> und dass ich
					meine Einwilligung jederzeit über den Link im Newsletter widerrufen kann und dass meine
					personenbezogenen Daten im Einklang verarbeitet werden mit Zevios <a target="_blank"
					rel="noopener noreferrer" href={LL("/privacy")}>Datenschutzbestimmungen</a>.
				</O>
			);
		},
		"en": (LL, conglomerateNames, toggleOrganizerDataModal) => {
			return (
				<O>
					By entering my name and my email and signing up for the webinar, I consent to
					Zevio using my name, my email address, and information about my viewing
					preferences to send newsletters containing marketing information from Zevio
					about speakers and webinars. I am aware that my data can be forwarded to
					to <b><a href="/organizers" onClick={toggleOrganizerDataModal}>
					{conglomerateNames.join(', ')}</a></b> and that my consent may at any time be revoked
					using the link in the newsletter, and that my personal information is processed in accordance with
					Zevios <a target="_blank" rel="noopener noreferrer" href={LL("/privacy")}>Privacy Policy</a>.
				</O>
			);
		},
		"fr": (LL, conglomerateNames, toggleOrganizerDataModal) => {
			return (
				<O>
					By entering my name and my email and signing up for the webinar, I consent to
					Zevio using my name, my email address, and information about my viewing
					preferences to send newsletters containing marketing information from Zevio
					about speakers and webinars. I am aware that my data can be forwarded to
					to <b><a href="/organizers" onClick={toggleOrganizerDataModal}>
					{conglomerateNames.join(', ')}</a></b> and that my consent may at any time be revoked
					using the link in the newsletter, and that my personal information is processed in accordance with
					Zevios <a target="_blank" rel="noopener noreferrer" href={LL("/privacy")}>Privacy Policy</a>.
				</O>
			);
		},
		"nl": (LL, conglomerateNames, toggleOrganizerDataModal) => {
			return (
				<O>
					Door mijn naam en mijn e-mailadres in te voeren en me aan te melden voor het
					webinar, stem ik ermee in dat Zevio mijn naam, mijn e-mailadres en informatie
					over mijn kijkvoorkeuren gebruikt om nieuwsbrieven te verzenden met
					marketinginformatie van Zevio over sprekers en webinars.<br /><br />
					Ik ben me ervan bewust dat mijn gegevens kunnen worden doorgestuurd naar
					de <b><a href="/organizers" onClick={toggleOrganizerDataModal}>
					{conglomerateNames.join(', ')}</a></b> en dat mijn toestemming op
					elk moment kan worden ingetrokken via de link in de nieuwsbrief, en dat mijn
					persoonlijke gegevens worden verwerkt in overeenstemming met het  <a
					target="_blank" rel="noopener noreferrer" href={LL("/privacy")}>privacybeleid
					van Zevio</a>.
				</O>
			);
		},
		"no": (LL, conglomerateNames, toggleOrganizerDataModal) => {
			return (
				<O>
					Ved å angi navn og e-postadresse og å melde meg på dette webinaret godtar jeg at
					Zevio bruker mitt navn, e-postadresse og info om mine preferanser for å sende
					meg nyhetsbrev som inneholder markedsføring fra Zevio om foredragsholdere og
					webinarer.<br /><br />Jeg er klar over at informasjonen kan sendes videre til <b><a
					href="/organizers" onClick={toggleOrganizerDataModal}>{conglomerateNames.join(', ')}</a></b> og at mitt
					samtykke når som helst kan trekkes tilbake gjennom lenken i nyhetsbrevet, og at
					min personlige informasjon behandles i tråd med Zevios <a target="_blank"
					rel="noopener noreferrer" href={LL("/privacy")}>personvernpolicy </a>.
				</O>
			);
		},
		"sv": (LL, conglomerateNames, toggleOrganizerDataModal) => {
			return (
				<O>
					Genom att ange mitt namn och min e-postadress och anmäla mig till webbseminariet samtycker jag till
					att Zevio använder mitt namn, min e-postadress och information om mina preferenser för att skicka
					nyhetsbrev med marknadsföringsinformation från Zevio om talare och webbseminarier.<br /><br />
					Jag är medveten om att mina uppgifter kan vidarebefordras till <b><a href="/organizers"
					onClick={toggleOrganizerDataModal}>{conglomerateNames.join(', ')}</a></b> och att mitt samtycke när som helst kan
					återkallas med hjälp av länken i nyhetsbrevet, samt att mina personuppgifter behandlas i enlighet
					med Zevios <a target="_blank" rel="noopener noreferrer" href={LL("/privacy")}>integritetspolicy</a>.
				</O>
			);
		},
	},

	"8135e494128f": {
		"da": () => {
			return (
				<O>
												  Alle betalingsdata vil blive gemt hos vores betalingsudbyder, Worldline. For at overholde handelsreglerne vil vi opbevare oplysninger om din betaling i fem (5) år. Vi sender en (1) bekræftelses-e-mail med dato og link til din stream. Vi kan ikke informere dig om eventuelle ændringer i programmet, men dette vil altid fremgå af livesiden. 
												</O>
			);
		},
		"de": () => {
			return (
				<O>
												  Alle Zahlungsdaten werden bei unserem Zahlungsanbieter, Worldline, gespeichert. Um Handelsregeln einzuhalten, werden wir Ihre Zahlungsinformationen für fünf (5) Jahre aufbewahren. Wir senden eine (1) Bestätigungs-E-Mail mit Datum und Link zu Ihrem Stream. Wir können Sie nicht über Änderungen im Programm informieren, aber dies wird immer auf der Live-Seite angezeigt.
												</O>
			);
		},
		"en": () => {
			return (
				<O>
								  All payment data will be stored with our payment provider, Worldline. To comply with trade rules, we will retain your payment information for five (5) years. We will send one (1) confirmation email with the date and link to your stream. We cannot inform you about any changes in the program, but this will always be shown on the live page.
								</O>
			);
		},
		"fr": () => {
			return (
				<O>
												  Toutes les données de paiement seront stockées chez notre fournisseur de paiement, Worldline. Pour respecter les règles commerciales, nous conserverons les informations de votre paiement pendant cinq (5) ans. Nous enverrons un (1) e-mail de confirmation avec la date et le lien vers votre flux. Nous ne pouvons pas vous informer de tout changement dans le programme, mais cela sera toujours indiqué sur la page en direct.
												</O>
			);
		},
		"nl": () => {
			return (
				<O>
												  Alle betalingsgegevens worden opgeslagen bij onze betalingsprovider, Worldline. Om te voldoen aan handelsregels, bewaren we uw betalingsinformatie vijf (5) jaar. We sturen één (1) bevestigingsmail met de datum en link naar je stream. We kunnen je niet informeren over eventuele wijzigingen in het programma, maar dit zal altijd te zien zijn op de live-pagina.
												</O>
			);
		},
		"no": () => {
			return (
				<O>
												  Alle betalingsdata vil bli lagret hos vår betalingsleverandør, Worldline. For å overholde handelsreglene, vil vi beholde informasjon om betalingen din i fem (5) år. Vi sender en (1) bekreftelses-e-post med dato og lenke til strømmen din. Vi kan ikke informere deg om eventuelle endringer i programmet, men dette vil alltid være tydelig på livesiden.
												</O>
			);
		},
		"sv": () => {
			return (
				<O>
												  Alla betalningsdata kommer att lagras hos vår betalningsleverantör, Worldline. För att följa handelsreglerna kommer vi att behålla information om din betalning i fem (5) år. Vi skickar ett (1) bekräftelsemail med datum och länk till din ström. Vi kan inte informera dig om eventuella ändringar i programmet, men detta kommer alltid att framgå av livesidan.
												</O>
			);
		},
	},

	"b35600788537": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Total</O>,
		"de": () => <O>Gesamt</O>,
		"en": () => <O>Net</O>,
		"fr": () => <O>Total</O>,
		"nl": () => <O>Totaal</O>,
		"no": () => <O>Total</O>,
		"sv": () => <O>Total</O>,
	},

	"369201a4a181": {
		"_new": true, // FIXME remove this line when translation is done
		"da": (vat) => <O>{vat.repr} ({vat.rate}% inkluderet)</O>,
		"de": (vat) => <O>{vat.repr} ({vat.rate}% inclusive)</O>,
		"en": (vat) => <O>{vat.repr} ({vat.rate}% inclusive)</O>,
		"fr": (vat) => <O>{vat.repr} ({vat.rate}% inclusive)</O>,
		"nl": (vat) => <O>{vat.repr} ({vat.rate}% inclusive)</O>,
		"no": (vat) => <O>{vat.repr} ({vat.rate}% inclusive)</O>,
		"sv": (vat) => <O>{vat.repr} ({vat.rate}% inclusive)</O>,
	},

	"7b5e607ce299": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Brutto</O>,
		"de": () => <O>Brutto</O>,
		"en": () => <O>Gross</O>,
		"fr": () => <O>Brut</O>,
		"nl": () => <O>Bruto</O>,
		"no": () => <O>Brutto</O>,
		"sv": () => <O>Brutto</O>,
	},

	"33f2b345ad72": {
		"_new": true, // FIXME remove this line when translation is done
		"da": (orderId) => <O>Ordrenummer: {orderId}</O>,
		"de": (orderId) => <O>Bestellnummer: {orderId}</O>,
		"en": (orderId) => <O>Order id: {orderId}</O>,
		"fr": (orderId) => <O>Numéro de commande: {orderId}</O>,
		"nl": (orderId) => <O>Ordernummer: {orderId}</O>,
		"no": (orderId) => <O>Ordrenummer: {orderId}</O>,
		"sv": (orderId) => <O>Ordernummer: {orderId}</O>,
	},

	"3d0c946843fb": {
		"_new": true, // FIXME remove this line when translation is done
		"da": (invoiceId) => <O>Faktura-id: {invoiceId}</O>,
		"de": (invoiceId) => <O>Rechnungs-ID: {invoiceId}</O>,
		"en": (invoiceId) => <O>Invoice id: {invoiceId}</O>,
		"fr": (invoiceId) => <O>Identifiant de facture: {invoiceId}</O>,
		"nl": (invoiceId) => <O>Factuur-ID: {invoiceId}</O>,
		"no": (invoiceId) => <O>Faktura-ID: {invoiceId}</O>,
		"sv": (invoiceId) => <O> Faktura-ID: {invoiceId}</O>,
	},

	"a559f5ffb310": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Total</O>,
		"de": () => <O>Total</O>,
		"en": () => <O>Total</O>,
		"fr": () => <O>Total</O>,
		"nl": () => <O>Total</O>,
		"no": () => <O>Total</O>,
		"sv": () => <O>Total</O>,
	},

	"aad4fb9baa30": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Tyskland</O>,
		"de": () => <O>Deutschland</O>,
		"en": () => <O>Germany</O>,
		"fr": () => <O>Allemagne</O>,
		"nl": () => <O>Duitsland</O>,
		"no": () => <O>Tyskland</O>,
		"sv": () => <O>Tyskland</O>,
	},

	"a32f5bbe4a7e": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Danmark</O>,
		"de": () => <O>Dänemark</O>,
		"en": () => <O>Denmark</O>,
		"fr": () => <O>Danemark</O>,
		"nl": () => <O>Denemarken</O>,
		"no": () => <O>Danmark</O>,
		"sv": () => <O>Danmark</O>,
	},

	"c3fd690881ed": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Østrig</O>,
		"de": () => <O>Österreich</O>,
		"en": () => <O>Austria</O>,
		"fr": () => <O>Autriche</O>,
		"nl": () => <O>Oostenrijk</O>,
		"no": () => <O>Østerrike</O>,
		"sv": () => <O>Österrike</O>,
	},

	"d612ee652f49": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Belgien</O>,
		"de": () => <O>Belgien</O>,
		"en": () => <O>Belgium</O>,
		"fr": () => <O>Belgique</O>,
		"nl": () => <O>België</O>,
		"no": () => <O>Belgia</O>,
		"sv": () => <O>Belgien</O>,
	},

	"99e8c7f4d255": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Bulgarien</O>,
		"de": () => <O>Bulgarien</O>,
		"en": () => <O>Bulgaria</O>,
		"fr": () => <O>Bulgarie</O>,
		"nl": () => <O>Bulgarije</O>,
		"no": () => <O>Bulgaria</O>,
		"sv": () => <O>Bulgarien</O>,
	},

	"03dc7e209656": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Cypern</O>,
		"de": () => <O>Zypern</O>,
		"en": () => <O>Cyprus</O>,
		"fr": () => <O>Chypre</O>,
		"nl": () => <O>Cyprus</O>,
		"no": () => <O>Kypros</O>,
		"sv": () => <O>Cypern</O>,
	},

	"c369b3c7a568": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Tjekkiet</O>,
		"de": () => <O>Tschechien</O>,
		"en": () => <O>Czechia</O>,
		"fr": () => <O>Tchéquie</O>,
		"nl": () => <O>Tsjechië</O>,
		"no": () => <O>Tsjekkia</O>,
		"sv": () => <O>Tjeckien</O>,
	},

	"4c3440127f9d": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Estland</O>,
		"de": () => <O>Estland</O>,
		"en": () => <O>Estonia</O>,
		"fr": () => <O>Estonia</O>,
		"nl": () => <O>Estland</O>,
		"no": () => <O>Estland</O>,
		"sv": () => <O>Estland</O>,
	},

	"bf2ac4d83c4f": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Grækenland</O>,
		"de": () => <O>Griechenland</O>,
		"en": () => <O>Greece</O>,
		"fr": () => <O>Grèce</O>,
		"nl": () => <O>Griekenland</O>,
		"no": () => <O>Hellas</O>,
		"sv": () => <O>Grekland</O>,
	},

	"f48602a2d111": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Spanien</O>,
		"de": () => <O>Spanien</O>,
		"en": () => <O>Spain</O>,
		"fr": () => <O>Espagne</O>,
		"nl": () => <O>Spanje</O>,
		"no": () => <O>Spania</O>,
		"sv": () => <O>Spanien</O>,
	},

	"2542979ad8f7": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Finland</O>,
		"de": () => <O>Finnland</O>,
		"en": () => <O>Finland</O>,
		"fr": () => <O>Finlande</O>,
		"nl": () => <O>Finland</O>,
		"no": () => <O>Finland</O>,
		"sv": () => <O>Finland</O>,
	},

	"6207155856aa": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Frankrig</O>,
		"de": () => <O>Frankreich</O>,
		"en": () => <O>France</O>,
		"fr": () => <O>France</O>,
		"nl": () => <O>Frankrijk</O>,
		"no": () => <O>Frankrike</O>,
		"sv": () => <O>Frankrike</O>,
	},

	"ad5536311f44": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Kroatien</O>,
		"de": () => <O>Kroatien</O>,
		"en": () => <O>Croatia</O>,
		"fr": () => <O>Croatie</O>,
		"nl": () => <O>Kroatië</O>,
		"no": () => <O>Kroatia</O>,
		"sv": () => <O>Kroatien</O>,
	},

	"b86e39d8a98c": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Ungarn</O>,
		"de": () => <O>Ungarn</O>,
		"en": () => <O>Hungary</O>,
		"fr": () => <O>Hongrie</O>,
		"nl": () => <O>Hongarije</O>,
		"no": () => <O>Ungarn</O>,
		"sv": () => <O>Ungern</O>,
	},

	"317016c4b7c0": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Irland</O>,
		"de": () => <O>Irland</O>,
		"en": () => <O>Ireland</O>,
		"fr": () => <O>Irlande</O>,
		"nl": () => <O>Ierland</O>,
		"no": () => <O>Irland</O>,
		"sv": () => <O>Irland</O>,
	},

	"9fdddb178433": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Italien</O>,
		"de": () => <O>Italien</O>,
		"en": () => <O>Italy</O>,
		"fr": () => <O>Italie</O>,
		"nl": () => <O>Italië</O>,
		"no": () => <O>Italia</O>,
		"sv": () => <O>Italien</O>,
	},

	"029b082b9298": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Letland</O>,
		"de": () => <O>Lettland</O>,
		"en": () => <O>Latvia</O>,
		"fr": () => <O>Lettonie</O>,
		"nl": () => <O>Letland</O>,
		"no": () => <O>Latvia</O>,
		"sv": () => <O>Lettland</O>,
	},

	"5b26aa6dc25d": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Litauen</O>,
		"de": () => <O>Litauen</O>,
		"en": () => <O>Lithuania</O>,
		"fr": () => <O>Lituanie</O>,
		"nl": () => <O>Litouwen</O>,
		"no": () => <O>Litauen</O>,
		"sv": () => <O>Litauen</O>,
	},

	"ee51478fa420": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Luxembourg</O>,
		"de": () => <O>Luxemburg</O>,
		"en": () => <O>Luxembourg</O>,
		"fr": () => <O>Luxembourg</O>,
		"nl": () => <O>Luxemburg</O>,
		"no": () => <O>Luxembourg</O>,
		"sv": () => <O>Luxemburg</O>,
	},

	"413bc122dfc1": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Malta</O>,
		"de": () => <O>Malta</O>,
		"en": () => <O>Malta</O>,
		"fr": () => <O>Malte</O>,
		"nl": () => <O>Malta</O>,
		"no": () => <O>Malta</O>,
		"sv": () => <O>Malta</O>,
	},

	"d859d5355525": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Holland</O>,
		"de": () => <O>Niederlande</O>,
		"en": () => <O>Netherlands</O>,
		"fr": () => <O>Pays-Bas</O>,
		"nl": () => <O>Netherlands</O>,
		"no": () => <O>Nederland</O>,
		"sv": () => <O>Nederländerna</O>,
	},

	"ca3f26c249e4": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Norge</O>,
		"de": () => <O>Norwegen</O>,
		"en": () => <O>Norway</O>,
		"fr": () => <O>Norvège</O>,
		"nl": () => <O>Norway</O>,
		"no": () => <O>Norge</O>,
		"sv": () => <O>Norge</O>,
	},

	"b1c9245ed607": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Poland</O>,
		"de": () => <O>Poland</O>,
		"en": () => <O>Poland</O>,
		"fr": () => <O>Poland</O>,
		"nl": () => <O>Poland</O>,
		"no": () => <O>Poland</O>,
		"sv": () => <O>Poland</O>,
	},

	"f6c12caddc40": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Portugal</O>,
		"de": () => <O>Portugal</O>,
		"en": () => <O>Portugal</O>,
		"fr": () => <O>Portugal</O>,
		"nl": () => <O>Portugal</O>,
		"no": () => <O>Portugal</O>,
		"sv": () => <O>Portugal</O>,
	},

	"bc51f4753b8e": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Rumænien</O>,
		"de": () => <O>Rumänien</O>,
		"en": () => <O>Romania</O>,
		"fr": () => <O>Roumanie</O>,
		"nl": () => <O>Roemenië</O>,
		"no": () => <O>Romania</O>,
		"sv": () => <O>Rumänien</O>,
	},

	"5dc734c0694a": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Sverige</O>,
		"de": () => <O>Schweden</O>,
		"en": () => <O>Sweden</O>,
		"fr": () => <O>Suède</O>,
		"nl": () => <O>Zweden</O>,
		"no": () => <O>Sverige</O>,
		"sv": () => <O>Sverige</O>,
	},

	"2029cac12895": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Ikke-EU-land</O>,
		"de": () => <O>Nicht-EU-Land</O>,
		"en": () => <O>Non-EU country</O>,
		"fr": () => <O>Pays non membre de l'Union européenne</O>,
		"nl": () => <O>Niet-EU-land</O>,
		"no": () => <O>Ikke-EU-land</O>,
		"sv": () => <O>Icke-EU-land</O>,
	},

	"f953ebd95d25": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Kom i gang med at streame</O>,
		"de": () => <O>Erstellen Sie ein Zevio-Streamer-Konto</O>,
		"en": () => <O>Create Zevio streamer account</O>,
		"fr": () => <O>Créez un compte de streamer Zevio</O>,
		"nl": () => <O>Maak een Zevio-streameraccount aan</O>,
		"no": () => <O>Opprett en Zevio-streamerkonto</O>,
		"sv": () => <O>Skapa ett Zevio-streamerkonto</O>,
	},

	"67f42e117738": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Hvis du ønsker at offentliggøre indhold på Zevio, skal du oprette en streamerkonto. Det er gratis.</O>,
		"de": () => <O>Wenn Sie Inhalte auf Zevio veröffentlichen möchten, müssen Sie ein Streamer-Konto erstellen</O>,
		"en": () => <O>If you want to publish content at Zevio, you need to create a streamer account.</O>,
		"fr": () => <O>Si vous souhaitez publier du contenu sur Zevio, vous devez créer un compte de streamer</O>,
		"nl": () => <O>Als u inhoud wilt publiceren op Zevio, moet u een streameraccount aanmaken</O>,
		"no": () => <O>Hvis du vil publisere innhold på Zevio, må du opprette en streamerkonto</O>,
		"sv": () => <O>Om du vill publicera innehåll på Zevio måste du skapa ett streamerkonto</O>,
	},

	"dde6e95a5e12": {
		"da": () => <O>En streamerkonto giver dig adgang til en række værktøjer og funktioner, der gør det muligt at streame, administrere og dele dit indhold på Zevio.</O>,
		"de": () => <O>Ein Streamer-Konto gibt Ihnen Zugang zu einer Vielzahl von Tools und Funktionen, die es ermöglichen, Ihre Inhalte auf Zevio zu streamen, zu verwalten und zu teilen</O>,
		"en": () => <O>A streamer account gives you access to a variety of tools and functions, that makes it possible to upload, administer and share your content at Zebio.</O>,
		"fr": () => <O>Un compte de streamer vous donne accès à une variété d'outils et de fonctions, qui vous permettent de diffuser, d'administrer et de partager votre contenu sur Zevio</O>,
		"nl": () => <O>Een streameraccount geeft je toegang tot een verscheidenheid aan tools en functies, waarmee je je content kunt streamen, beheren en delen op Zevio.</O>,
		"no": () => <O>En streamerkonto gir deg tilgang til en rekke verktøy og funksjoner, som gjør det mulig å strømme, administrere og dele innholdet ditt på Zevio</O>,
		"sv": () => <O>Ett streamerkonto ger dig tillgång till en mängd verktyg och funktioner, som gör det möjligt att streama, administrera och dela ditt innehåll på Zevio</O>,
	},

	"a5269c8ce464": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Kontonavn (firma, organisation eller person)</O>,
		"de": () => <O>Kontoname (Firma, Organisation oder Person)</O>,
		"en": () => <O>Streamer account name</O>,
		"fr": () => <O>Nom du compte (entreprise, organisation ou personne)</O>,
		"nl": () => <O>Accountnaam (bedrijf, organisatie of persoon)</O>,
		"no": () => <O>Kontonavn (firma, organisasjon eller person)</O>,
		"sv": () => <O>Kontonamn (företag, organisation eller person)</O>,
	},

	"13fe36a8baa8": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Opret</O>,
		"de": () => <O>Erstellen</O>,
		"en": () => <O>Create</O>,
		"fr": () => <O>Créer</O>,
		"nl": () => <O>Creëer</O>,
		"no": () => <O>Opprett</O>,
		"sv": () => <O>Skapa</O>,
	},

	"c940cba78a24": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Streamer name already taken</O>,
		"de": () => <O>Streamer name already taken</O>,
		"en": () => <O>Streamer name already taken</O>,
		"fr": () => <O>Streamer name already taken</O>,
		"nl": () => <O>Streamer name already taken</O>,
		"no": () => <O>Streamer name already taken</O>,
		"sv": () => <O>Streamer name already taken</O>,
	},

	"bb85c6766594": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Din alt-i-en-streamingløsning</O>,
		"de": () => <O>Ihre All-in-One-Streaming-Lösung</O>,
		"en": () => <O>Organize your own live events</O>,
		"fr": () => <O>Votre solution de streaming tout-en-un</O>,
		"nl": () => <O>Uw alles-in-één streamingoplossing</O>,
		"no": () => <O>Din alt-i-ett-streamingløsning</O>,
		"sv": () => <O>Din allt-i-ett-streaminglösning</O>,
	},

	"f624e06a5f58": {
		"da": () => <O>Opsæt dit arrangement, tilføj dine tilbud, del og start med at samle registreringer - og gå live direkte fra din browser.</O>,
		"de": () => <O>Zevio ist Ihre One-Stop-Shop für alles rund ums Streaming. Richten Sie Ihre Veranstaltung ein, fügen Sie Ihre Wertangebote hinzu, beginnen Sie mit der Sammlung von Registrierungen - und gehen Sie direkt aus Ihrem Browser live.</O>,
		"en": () => <O>Experience the platform that helps you generate revenue, increase your audience and promote your insights and passion</O>,
		"fr": () => <O>Zevio est votre guichet unique pour tout ce qui concerne le streaming. Configurez votre événement, ajoutez vos propositions de valeur, commencez à collecter des inscriptions - et passez en direct directement depuis votre navigateur.</O>,
		"nl": () => <O>Zevio is je one-stop-shop voor alles wat met streaming te maken heeft. Stel je evenement in, voeg je waardeproposities toe, begin met het verzamelen van registraties - en ga live rechtstreeks vanuit je browser.</O>,
		"no": () => <O>Zevio er din One Stop Shop for alt som har med streaming å gjøre. Sett opp arrangementet ditt, legg til verdi forslagene dine, start å samle registreringer - og gå live rett fra nettleseren din.</O>,
		"sv": () => <O>Zevio är din one-stop-shop för allt som har med streaming att göra. Ställ in ditt evenemang, lägg till dina värdeerbjudanden, börja samla in registreringar - och sänd live direkt från din webbläsare.</O>,
	},

	"834b154e7198": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Lav dine udsendelser</O>,
		"de": () => <O>Kostenloses Streaming - keine Abonnement erforderlich</O>,
		"en": () => <O>Free online streaming of webinars - no subscription necessary</O>,
		"fr": () => <O>Streaming gratuit - aucun abonnement requis</O>,
		"nl": () => <O> Gratis streaming - geen abonnement vereist </O>,
		"no": () => <O>Gratis streaming - ingen abonnement nødvendig</O>,
		"sv": () => <O>Gratis streaming - ingen prenumeration krävs</O>,
	},

	"2f5a8dd68841": {
		"da": () => <O>Få følgere og opbyg en meningsfuld forbindelse til dine seere</O>,
		"de": () => <O>Gewinnen Sie Follower und bauen Sie eine bedeutungsvolle Verbindung auf</O>,
		"en": () => <O>Gain followers and engage with your audience</O>,
		"fr": () => <O>Gagnez des abonnés et établissez une connexion significative</O>,
		"nl": () => <O>Win volgers en bouw een zinvolle connectie op</O>,
		"no": () => <O>Skaff følgere og bygg en meningsfylt forbindelse</O>,
		"sv": () => <O>Skaffa följare och bygg en meningsfull koppling</O>,
	},

	"089c0df7162f": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Administrer dit videobibliotek</O>,
		"de": () => <O>Verwalten Sie Ihre Videobibliothek</O>,
		"en": () => <O>Manage your video library</O>,
		"fr": () => <O>Gérez votre bibliothèque vidéo</O>,
		"nl": () => <O>Beheer uw videobibliotheek</O>,
		"no": () => <O>Administrer ditt videobibliotek</O>,
		"sv": () => <O>Hantera ditt videobibliotek</O>,
	},

	"093f86ffb81a": {
		"da": () => <O>Sælg dine produkter eller send seerne til din hjemmeside.</O>,
		"de": () => <O>Verkaufen Sie Ihre Produkte oder leiten Sie Zuschauer auf Ihre Website</O>,
		"en": () => <O>Sell your products or send viewers to your website</O>,
		"fr": () => <O>Vendez vos produits ou envoyez les spectateurs sur votre site web</O>,
		"nl": () => <O>Verkoop je producten of stuur kijkers naar je website</O>,
		"no": () => <O>Selg produktene dine eller send seere til nettstedet ditt</O>,
		"sv": () => <O>Sälj dina produkter eller skicka tittare till din webbplats</O>,
	},

	"ed26168e19b4": {
		"da": () => <O>Intet specielt udstyr kræves - du kan gå live direkte fra din browser.</O>,
		"de": () => <O>Keine spezielle Ausrüstung benötigt - gehen Sie direkt aus Ihrem Browser live.</O>,
		"en": () => <O>No special equipment needed - go live from your browser</O>,
		"fr": () => <O>Pas besoin d'équipement spécial - allez en direct directement depuis votre navigateur</O>,
		"nl": () => <O>Geen speciale apparatuur nodig - ga live rechtstreeks vanuit je browser</O>,
		"no": () => <O>Ingen spesielt utstyr nødvendig - gå live direkte fra nettleseren din</O>,
		"sv": () => <O>Inga speciella utrustning behövs - sänd live direkt från din webbläsare</O>,
	},

	"e63136c2cd4e": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Sådan!</O>,
		"de": () => <O>Perfekt</O>,
		"en": () => <O>All set</O>,
		"fr": () => <O>Parfait</O>,
		"nl": () => <O>Perfect</O>,
		"no": () => <O>Perfekt</O>,
		"sv": () => <O>Perfekt</O>,
	},

	"ea6b01cec81d": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Din konto er oprettet</O>,
		"de": () => <O>Ihr Konto wurde erstellt</O>,
		"en": () => <O>Your account has been created</O>,
		"fr": () => <O>Votre compte a été créé</O>,
		"nl": () => <O>Uw account is aangemaakt</O>,
		"no": () => <O>Kontoen din er opprettet</O>,
		"sv": () => <O>Ditt konto har skapats</O>,
	},

	"64c4900b214e": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Næste trin er at udfylde dine profiloplysninger og derefter oprette en begivenhed.</O>,
		"de": () => <O>Der nächste Schritt besteht darin, Ihre Profilinformationen auszufüllen und dann eine Veranstaltung zu erstellen.</O>,
		"en": () => <O>Next step is to fill out your profile information and then create an event</O>,
		"fr": () => <O>La prochaine étape consiste à remplir vos informations de profil puis à créer un événement.</O>,
		"nl": () => <O>De volgende stap is het invullen van je profielinformatie en dan een evenement aanmaken.</O>,
		"no": () => <O>Neste steg er å fylle ut profilinformasjonen din og deretter opprette en hendelse.</O>,
		"sv": () => <O>Nästa steg är att fylla i din profilinformation och sedan skapa ett evenemang.</O>,
	},

	"1d172b814109": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Opsæt din profil</O>,
		"de": () => <O>Richten Sie Ihr Profil ein</O>,
		"en": () => <O>Setup your profile</O>,
		"fr": () => <O>Configurez votre profil</O>,
		"nl": () => <O>Stel je profiel in</O>,
		"no": () => <O>Sett opp profilen din</O>,
		"sv": () => <O>Ställ in din profil</O>,
	},

	"3557e848d25f": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Slovenien</O>,
		"de": () => <O>Slowenien</O>,
		"en": () => <O>Slovenia</O>,
		"fr": () => <O>Slovénie</O>,
		"nl": () => <O>Slovenië</O>,
		"no": () => <O>Slovenia</O>,
		"sv": () => <O>Slovenien</O>,
	},

	"3415c47f34d1": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Slovakiet</O>,
		"de": () => <O>Slowakei</O>,
		"en": () => <O>Slovakia</O>,
		"fr": () => <O>Slovaquie</O>,
		"nl": () => <O>Slowakije</O>,
		"no": () => <O>Slovakia</O>,
		"sv": () => <O>Slovakien</O>,
	},

	"332400e6e037": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Det ser ud til du allerede har en konto med denne email. Log venligst ind først.</O>,
		"de": () => <O>Es sieht so aus, als ob bereits ein Konto mit dieser E-Mail existiert. Bitte logge dich ein.</O>,
		"en": () => <O>It looks like you've already got an account associated with this email. Please log in first.</O>,
		"fr": () => <O> Il semble que vous ayez déjà un compte associé à cette adresse e-mail. Veuillez d'abord vous connecter.</O>,
		"nl": () => <O>Het lijkt erop dat er al een account is gekoppeld aan dit e-mailadres. Gelieve eerst in te loggen.</O>,
		"no": () => <O>Det ser ut til at du allerede har en konto knyttet til denne e-posten. Vennligst logg inn først.</O>,
		"sv": () => <O>Det verkar som att du redan har ett konto kopplat till denna e-postadress. Vänligen logga in först.</O>,
	},

	"c3056026f8a2": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Ingen konto fundet / forkert adgangskode eller email</O>,
		"de": () => <O>Kein Konto gefunden / Falsches Passwort oder E-Mail</O>,
		"en": () => <O>No account found / incorrect password or email</O>,
		"fr": () => <O>Aucun compte trouvé / Mot de passe ou adresse e-mail incorrecte</O>,
		"nl": () => <O>Geen account gevonden / Onjuist wachtwoord of e-mailadres</O>,
		"no": () => <O>Ingen konto funnet / Feil passord eller e-post</O>,
		"sv": () => <O>Inget konto hittades / Fel lösenord eller e-postadress</O>,
	},

	"0fc07b2b0ad7": {
		"da": (LL) => <O>Jeg samtykker til <a className="color_green" href={LL("/terms")}>brugsvilkår</a> og <a className="greentext" href={LL("/privacy")}>privatlivspolitik</a></O>,
		"de": (LL) => <O>Ich stimme den <a className="color_green" href={LL("/terms")}>Allgemeinen Geschäftsbedingungen</a> und <a className="greentext" href={LL("/privacy")}>Datenschutz</a> zu</O>,
		"en": (LL) => <O>I agree to the <a className="color_green" href={LL("/terms")}>terms</a> and I am aware of the <a className="greentext" href={LL("/privacy")}>privacy policy</a></O>,
		"fr": (LL) => <O>J'accepte <a className="color_green" href={LL("/terms")}>les conditions</a> et <a className="greentext" href={LL("/privacy")}> la politique de confidentialité</a></O>,
		"nl": (LL) => <O>Ik ga akkoord met <a className="color_green" href={LL("/terms")}>de voorwaarden</a> en het <a className="greentext" href={LL("/privacy")}>privacybeleid</a></O>,
		"no": (LL) => <O>Jeg godtar vilkårene <a className="color_green" href={LL("/terms")}>Bruksvilkår</a> and <a className="greentext" href={LL("/privacy")}>personvernerklæring</a></O>,
		"sv": (LL) => <O>Jeg samtycker med <a className="color_green" href={LL("/terms")}>användarvillkor</a> och <a className="greentext" href={LL("/privacy")}>integritetspolicy</a></O>,
	},

	"a16a4305ddab": {
		"da": () => <O>Du skal acceptere vilkårene for at fortsætte.</O>,
		"de": () => <O>Bitte die Bedingungen akzeptieren</O>,
		"en": () => <O>You need to accept the terms</O>,
		"fr": () => <O>You need to accept the terms</O>,
		"nl": () => <O>U moet de voorwaarden accepteren</O>,
		"no": () => <O>Du må akseptere vilkårene for å fortsette</O>,
		"sv": () => <O> Du måste acceptera villkoren</O>,
	},

	"78a612942b75": {
		"en": () => <O>Wrong credentials.</O>,
		"da": () => <O>Email og/eller kodeord er forkert.</O>,
		"de": () => <O>Falsche Anmeldedaten.</O>,
		"sv": () => <O>Fel inloggningsuppgifter</O>,
		"no": () => <O>Feil legitimasjon.</O>,
		"fr": () => <O>Mauvaises informations.</O>,
		"nl": () => <O>Verkeerde geloofsbrieven.</O>,
	},

	"5b255ad78ba2": {
		"en": () => <O>Reset password?</O>,
		"da": () => <O>Nulstil kodeord</O>,
		"de": () => <O>Passwort zurücksetzen</O>,
		"sv": () => <O>Återställ lösenord</O>,
		"no": () => <O>Gjenopprett passord</O>,
		"fr": () => <O>Réinitialiser le mot de passe</O>,
		"nl": () => <O>Wachtwoord aanpassen</O>,
	},

	"484f9573380d": {
		"_new": true, // FIXME remove this line when translation is done
		"da": () => <O>Adgangskode (Skal indeholde mindst 7 tegn)</O>,
		"de": () => <O>Passwort (Muss mindestens 7 Zeichen enthalten)</O>,
		"en": () => <O>Password (Must contain a minimum of 7 characters)</O>,
		"fr": () => <O>Mot de passe (Doit contenir au moins 7 caractères)</O>,
		"nl": () => <O>Wachtwoord (Moet minimaal 7 tekens bevatten)</O>,
		"no": () => <O>Passord (Må inneholde minst 7 tegn)</O>,
		"sv": () => <O> Lösenord (Måste innehålla minst 7 tecken)</O>,
	},

	"adf8a7414a42": {
		"da": () => <O>Adgangskode igen</O>,
		"de": () => <O>Passwort wiederholen</O>,
		"en": () => <O>Password again</O>,
		"fr": () => <O>Répétez le mot de passe</O>,
		"nl": () => <O>Wachtwoord (nogmaals)</O>,
		"no": () => <O>Gjenta passord</O>,
		"sv": () => <O> Lösenord igen</O>,
	},

	"baf2c2d582d4": {
		"da": () => <O>E-mail</O>,
		"de": () => <O>E-Mail</O>,
		"en": () => <O>E-mail</O>,
		"fr": () => <O>E-mail</O>,
		"nl": () => <O>E-mail</O>,
		"no": () => <O>E-post</O>,
		"sv": () => <O>E-post</O>,
	},

	"a6bea166c3cd": {
		"en": () => <O>Sign in</O>,
		"da": () => <O>Login</O>,
		"de": () => <O>Anmelden</O>,
		"sv": () => <O>Logga in</O>,
		"no": () => <O>Logg inn</O>,
		"fr": () => <O>S'inscrire</O>,
		"nl": () => <O>Meld je aan</O>,
	},

	"645c8bf3c26d": {
		"da": () => <O>Jeg har glemt mit kodeord</O>,
		"de": () => <O>Ich habe mein Passwort vergessen</O>,
		"en": () => <O>I forgot my password</O>,
		"fr": () => <O>I forgot my password</O>,
		"nl": () => <O>I forgot my password</O>,
		"no": () => <O>I forgot my password</O>,
		"sv": () => <O>I forgot my password</O>,
	},

	"690806e5fb4e": {
		"en": () => <O>Sign in</O>,
		"da": () => <O>Login</O>,
		"de": () => <O>Anmelden</O>,
		"sv": () => <O>Logga in</O>,
		"no": () => <O>Logg inn</O>,
		"fr": () => <O>S'inscrire</O>,
		"nl": () => <O>Meld je aan</O>,
	},

	"9f0a5e1aa464": {
		"en": () => <O>Already a member?&nbsp; </O>,
		"da": () => <O>Allerede medlem?&nbsp; </O>,
		"de": () => <O>Bereits Mitglied?  </O>,
		"sv": () => <O>Redan medlem?&nbsp; </O>,
		"no": () => <O>Allerede medlem?&nbsp; </O>,
		"fr": () => <O>Vous êtes déjà membre?&nbsp; </O>,
		"nl": () => <O>Ben je al lid?&nbsp; </O>,
	},

	"9dd4b19b9f82": {
		"en": () => <O>Create account</O>,
		"da": () => <O>Opret en bruger</O>,
		"de": () => <O>Konto erstellen</O>,
		"sv": () => <O>Skapa konto</O>,
		"no": () => <O>Opprett bruker</O>,
		"fr": () => <O>Créer un compte</O>,
		"nl": () => <O>Account aanmaken</O>,
	},

	"a9c26df78c41": {
		"en": () => <O>privacy policy</O>,
		"da": () => <O>privatlivspolitik</O>,
		"de": () => <O>Datenschutz</O>,
		"sv": () => <O>integritetspolicy</O>,
		"no": () => <O>personvernerklæring</O>,
		"fr": () => <O>Politique de confidentialité</O>,
		"nl": () => <O>privacybeleid</O>,
	},

	"c92dc0c9195e": {
		"da": () => <O>Vi sender dig en e-mail med instruktioner at nulstille din adgangskode.</O>,
		"de": () => <O>Wir werden dir eine E-Mail mit einer Anleitung zum Zurücksetzen des Passworts schicken.</O>,
		"en": () => <O>We will send you an e-mail with intructions for resetting your password.</O>,
		"fr": () => <O>Nous vous enverrons un e-mail avec des instructions pour réinitialiser votre mot de passe.</O>,
		"nl": () => <O>We sturen u een e-mail met instructies om uw wachtwoord opnieuw in te stellen.</O>,
		"no": () => <O>Vi sender deg en e-post med instruksjoner for å gjenopprette passord.</O>,
		"sv": () => <O> Vi kommer att skicka ett e-postmeddelande med instruktioner för att återställa ditt lösenord.</O>,
	},

	"6e79778967ff": {
		"da": () => <O>Check your inbox for a link to reset password.</O>,
		"de": () => <O>Prüfe bitte deinen Posteingang auf einen Link zum Zurücksetzen des Passworts.</O>,
		"en": () => <O>Check your inbox for a link to reset password.</O>,
		"fr": () => <O>Check your inbox for a link to reset password.</O>,
		"nl": () => <O>Controleer uw inbox voor een link om het wachtwoord opnieuw in te stellen.</O>,
		"no": () => <O>Sjekk innboksen din for å få lenken til å gjenopprette passordet</O>,
		"sv": () => <O> Kontrollera din inkorg efter en länk för att återställa lösenordet.</O>,
	},

	"9ba13c6e7789": {
		"da": () => <O>Processerer</O>,
		"de": () => <O>Wird bearbeitet</O>,
		"en": () => <O>Processing</O>,
		"fr": () => <O>En traitement</O>,
		"nl": () => <O>Verwerken</O>,
		"no": () => <O>Prosesserer</O>,
		"sv": () => <O>Prosesserer</O>,
	},

	"56bd31226105": {
		"da": () => <O>Can't log in?</O>,
		"de": () => <O>Du kannst dich nicht anmelden?</O>,
		"en": () => <O>Can't log in?</O>,
		"fr": () => <O>Can't log in?</O>,
		"nl": () => <O>Can't log in?</O>,
		"no": () => <O>Can't log in?</O>,
		"sv": () => <O>Can't log in?</O>,
	},

	"cc1f84d204e0": {
		"da": () => <O>Vi sender et nulstillingslink til</O>,
		"de": () => <O>Wir senden einen Wiederherstellungslink an</O>,
		"en": () => <O>We'll send a recovery link to</O>,
		"fr": () => <O>We'll send a recovery link to</O>,
		"nl": () => <O>We'll send a recovery link to</O>,
		"no": () => <O>We'll send a recovery link to</O>,
		"sv": () => <O>We'll send a recovery link to</O>,
	},

	"5e6fe03a46b0": {
		"da": () => <O>Send recovery link</O>,
		"de": () => <O>Wiederherstellungslink senden</O>,
		"en": () => <O>Send recovery link</O>,
		"fr": () => <O>Send recovery link</O>,
		"nl": () => <O>Send recovery link</O>,
		"no": () => <O>Send recovery link</O>,
		"sv": () => <O>Send recovery link</O>,
	},

	"a248b9f85dfc": {
		"da": () => <O>Back to login</O>,
		"de": () => <O>Zurück zur Anmeldung</O>,
		"en": () => <O>Back to login</O>,
		"fr": () => <O>Back to login</O>,
		"nl": () => <O>Back to login</O>,
		"no": () => <O>Back to login</O>,
		"sv": () => <O>Back to login</O>,
	},

	"23fe2a6e872a": {
		"da": () => <O>Husk min info, så jeg nemt og gratis kan se andre streams?</O>,
		"de": () => <O>Diese E-Mail für zukünftige Veranstaltungen verwenden?</O>,
		"en": () => <O>Use this email for future events?</O>,
		"fr": () => <O>Use this email for future events?</O>,
		"nl": () => <O>Use this email for future events?</O>,
		"no": () => <O>Use this email for future events?</O>,
		"sv": () => <O>Use this email for future events?</O>,
	},

	"cd376d944b68": {
		"da": () => <O>Ja</O>,
		"de": () => <O>Ja</O>,
		"en": () => <O>Yes</O>,
		"fr": () => <O>Oui</O>,
		"nl": () => <O>Ja</O>,
		"no": () => <O>Ja</O>,
		"sv": () => <O>Ja</O>,
	},

	"ce407b21f8cd": {
		"en": () => <O>Login to Zevio</O>,
		"da": () => <O>Log ind på Zevio</O>,
		"de": () => <O>Einloggen</O>,
		"sv": () => <O>Logga in på Zevio</O>,
		"no": () => <O>Logg inn på Zevio</O>,
		"fr": () => <O>Login to Zevio</O>,
		"nl": () => <O>Login to Zevio</O>,
	},

	"853397f57197": {
		"en": () => <O>Terms</O>,
		"da": () => <O>Brugsbetingelser</O>,
		"de": () => <O>Geschäftsbedingungen</O>,
		"sv": () => <O>Användarvillkor</O>,
		"no": () => <O>Vilkår for bruk</O>,
		"fr": () => <O>Conditions d'utilisation</O>,
		"nl": () => <O>Voorwaarden van de dienst</O>,
	},

	"c4aea98fb1c7": {
		"en": () => <O>Not a member yet?</O>,
		"da": () => <O>Ikke medlem endnu?</O>,
		"de": () => <O>Noch kein Mitglied?</O>,
		"sv": () => <O>Inte medlem än?</O>,
		"no": () => <O>Ikke medlem enda?</O>,
		"fr": () => <O>Pas encore membre?</O>,
		"nl": () => <O>Ben je nog geen lid?</O>,
	},

	"6beff5e78383": {
		"en": () => <O>Loading organizer</O>,
		"da": () => <O>Henter arrangør</O>,
		"de": () => <O>Veranstalter wird geladen</O>,
		"sv": () => <O>Laddar organisatör</O>,
		"no": () => <O>Laster arrangør</O>,
		"fr": () => <O>Organisateur de chargement</O>,
		"nl": () => <O>Organizer laden</O>,
	},

	"281b9d6477e0": {
		"en": () => <O>Days</O>,
		"da": () => <O>Dage</O>,
		"de": () => <O>Tagen</O>,
		"sv": () => <O>Dagar</O>,
		"no": () => <O>Dager</O>,
		"fr": () => <O>Jours</O>,
		"nl": () => <O>Dagen</O>,
	},

	"189561b6c6cd": {
		"en": () => <O>Hours</O>,
		"da": () => <O>Timer</O>,
		"de": () => <O>Stunden</O>,
		"sv": () => <O>Timmar</O>,
		"no": () => <O>Timer</O>,
		"fr": () => <O>Heures</O>,
		"nl": () => <O>Uren</O>,
	},

	"a0a3e0ceceae": {
		"en": () => <O>Minutes</O>,
		"da": () => <O>Minutter</O>,
		"de": () => <O>Minuten</O>,
		"sv": () => <O>Minuter</O>,
		"no": () => <O>Minutter</O>,
		"fr": () => <O>Minutes</O>,
		"nl": () => <O>Minuten</O>,
	},

	"3ef591126720": {
		"en": () => <O>Seconds</O>,
		"da": () => <O>Sekunder</O>,
		"de": () => <O>Sekunden</O>,
		"sv": () => <O>Sekunder</O>,
		"no": () => <O>Sekunder</O>,
		"fr": () => <O>Secondes</O>,
		"nl": () => <O>Seconden</O>,
	},
}
