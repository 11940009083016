import React, { useContext, useEffect, useState } from 'react';
import { CardImgOverlay, Modal, ModalBody, Card } from 'reactstrap';
import { LL } from "../Routes";
import { T } from '../traks';
import { APIGet, APIPost } from '../API';
import { EventDateTime, ImageWithSrcSet, smallCoverImageURL } from './ReusableElems';
import { ProductCardButton, ProductPriceElem } from './ProductCardButton';
import LikeContext from '../LikeContext';
import get_country_info from './Amopus/util';
import I18N from '../I18N';

const OrganizerFollowElem = ({ organizerId }) => {
	const { userDoesLikeOrg, likeOrganizer, unlikeOrganizer } = useContext(LikeContext);
	const [ followers, setFollowers] = useState(0);
	const [ userFollowing, setUserFollowing ] = useState(undefined);

	useEffect(() => {
		let isMounted = true;
		setUserFollowing(userDoesLikeOrg(organizerId));
		APIGet('sequor', {'action': 'organizer_likes', 'organizerIds': organizerId})
			.then((res) => {
				if(isMounted) {
					setFollowers(res.data.body[organizerId].count);
				}
			})
			.catch((error) => {
				console.log('Error fetching followers:', error);
			})

		return () => {
			isMounted = false;
		}
        }, [organizerId, userDoesLikeOrg]);

	const clickFollowIcon = () => {
		userFollowing ? unlikeOrganizer(organizerId) : likeOrganizer(organizerId);
		setFollowers(userFollowing ? followers -1 : followers +1);
		setUserFollowing(!userFollowing);
	}

	const followIcon = userFollowing ? "https://static.zevio.com/gfx/ico_following.svg" : "https://static.zevio.com/gfx/ico_follow.svg";

	return (
		<div onClick={clickFollowIcon} className="pe-2 font_13px cursor-pointer">
			<img width="25" height="25" className="pb-1 pe-1" src={followIcon} alt='icocomment' />
			{followers}&nbsp;<T>followers</T>
		</div>
	);
}

const ConditionalPriceTag = ({eventData}) => {
	const [price, setPrice] = useState()
	let { currency } = get_country_info();
	useEffect(()=>{
		if (eventData?.AccessProductId) {
			APIPost('products', { action: "get_event_access_products" }).then(r => {
				if (r.status === 200) {
					let product = r.data.body.products.find(p => p.Id === eventData?.AccessProductId)
					if (product) {
						setPrice(product.price[currency])
					}
				}
				else { console.log("error setting access products") }
			})
		}
	}, [eventData, currency])
	if (!price) return null
	return  <div className='text-white bg_green d-flex justify-content-center align-items-center' style={{height:"35px", fontWeight:600}}><span ><T>Buy access for {I18N.floatval_unit(price, 2, currency.toLocaleUpperCase())}</T></span></div>
}


export const EventCard = (props) => {

	const ConditionalPopularBadge = (props) => {
		if (props.Popular) {
			return <span className="card_badge badgecolor_popular align_right"><T>Popular</T></span>;
		}
		return null;
	}

	const ConditionalLiveBadge = (props) => {
		if (props.LiveNow) {
			return <span className="card_badge badgecolor_live align_right"><T>Live</T></span>;
		}
		return null;
	}

	let thumbImageElem;
	if(props.eventData.ThumbImageURL) {
		thumbImageElem = <img src={props.eventData.ThumbImageURL} alt="Event Hero" width="312" height="255" className="mw-100 w-100" />;
	}
	if(props.eventData.ThumbnailImage) {
		thumbImageElem = <ImageWithSrcSet URL={props.eventData.ThumbnailImage} width="312" height="191" imgstr='eventimages/thumb' img2xstr='eventimages/2xthumb' alttxt="Event Hero"/>;
	}

	let eventDateTime = <T>On demand</T>;
	if (props.eventData.startTimeStamp > Date.now()/1000) {
		eventDateTime = <EventDateTime eventStartTs={props.eventData.startTimeStamp} />;
	}

	return (
		<div className="py-4 pe-2 hvr-bob">
			<a className="link_nostyle" href={
				LL("/event/") + props.eventData.Safename + ("-") + props.eventData.Id
			}>
				<Card className="explore_event_card position-relative shadow text-center">
					<div className="explore_card_scale">
						{thumbImageElem}
					</div>
					<CardImgOverlay>
						<ConditionalPopularBadge Popular={props.eventData.Popular} />
						<ConditionalLiveBadge LiveNow={props.eventData.LiveNow} />
					</CardImgOverlay>
					<ConditionalPriceTag eventData={props.eventData}/>
					<div className="p-2">
						<div className="card_header pb-1">{props.eventData.Title.length > 56 ? `${props.eventData.Title.substring(0, 56)}...` : props.eventData.Title}</div>
						<span>{props.eventData.SubTitle}</span>
						<div className="card_bottom position-absolute bottom-0 pb-2 pt-3 w-100">
							<div className="d-flex pe-3 justify-content-between">
								<div className="">
									<img
										style={{marginBottom: "1px"}}
										width="14"
										height="14"
										src="https://static.zevio.com/gfx/ico_calendercard.svg"
										alt="icocal"
									/>
									<span className="px-1 font_14px">
										{eventDateTime}
									</span>
								</div>
								<div className="card_badge badgecolor_grey badge_time">
									<div style={{paddingTop: "2px"}}>
									{props.eventData.Duration / 60} <T>min</T>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Card>
			</a>
		</div>
	);
}

export const ProductCard = (props) => {

	const i = props.i;
	const productData = props.data;
	const [selectedInd, setSelectedInd] = useState(-1);
	const toggle = () => setSelectedInd(-1);
	const modal = selectedInd >= -0;

	const ConditionalNyhed = (props) => {
		if (props.Nyhed) {
			return <span className="product_nyhed product_nyhed_margin position-absolute"><T>NEW!</T></span>;
		}
		return null;
	}

	const ModalComponent = () => {

		return (
			modal && <Modal isOpen={modal} toggle={toggle} size="lg" centered>
				<ModalBody className="product_modal">
					<img className="btn p-0" style={{ float: 'right' }} src="https://static.zevio.com/gfx/ico_close.svg" alt='icoClose' onClick={toggle} />
					<div className="d-flex flex-column flex-lg-row bg_white">
						<div className="py-3 ps-lg-3">
							<img className="product_card_modal_img" src={productData.imageURL} alt="" />
						</div>
						<div className="px-lg-4 py-3">
							<div >
								<span className="card_product_subtitle">{productData.ownerName}</span>
							</div>
							<div>
								<span className="card_product_header">{productData.headline}</span>
							</div>
							<div className="pt-2 row-cols-auto">
								<ProductPriceElem productData={productData} />
							</div>
							<div className="custom_scrollbar_section" id='this-one-perhaps' style={{maxHeight: "176px"}}>
								<div id="product_desc" dangerouslySetInnerHTML={{ __html: productData.description}} className="card_product_subtitle pe-3" />
							</div>
							<div className="pt-3">
								<ProductCardButton addToCart={addToCart} classes='btn btn_green px-2 py-2 border-0 w-100' productData={productData} />
							</div>
						</div>
					</div>
				</ModalBody>
			</Modal>
		);
	}

	if (productData.length === 0 || productData.active === "false" || !props.data.ownerName || !props.data.headline || !props.data.description ) {
		return null;
	}

	/* Coming in the future
	const beforeAndSaveElement = (
		<Col>
			<Row className="pt-2 ps-2">
				<Row className="discountbadge"><span className='text-uppercase'><T>Save</T></span> {props.data.shippingFee}</Row>
				<Row><span className='text-uppercase'><T>Before</T></span> {props.data.shippingFee}</Row>
			</Row>
		</Col>
	);*/

	const addToCart = (productID) => {
		const addBtnElement = document.getElementById("add2cartButton-" + productID);
		addBtnElement.click();
		return null;
	}

	return (
		<React.Fragment>
			<div className="cursor-pointer p-0 pe-4 hvr-bob pb-3">
				<div className="pb-4 d-flex flex-column product_card bg_white position-relative">
					<div className="px-3 pt-3">
						<div onClick={() => setSelectedInd(i)}>
							<div>
								<ConditionalNyhed Nyhed={!props.data.Nyhed} />
								<img className="product_card_img" src={props.data.imageURL} alt="" />
							</div>
							<div className="pt-4">
								<span className="card_product_subtitle">{props.data.ownerName.length > 25 ? `${props.data.ownerName.substring(0, 25)}...` : props.data.ownerName}</span>
							</div>
							<div>
								<span className="card_product_header">{props.data.headline.length > 25 ? `${props.data.headline.substring(0, 25)}...` : props.data.headline}</span>
							</div>
							<div className="pt-2">
								<ProductPriceElem productData={productData} />
							</div>
							<div>
								<span dangerouslySetInnerHTML={{ __html: props.data.description.length > 90 ? `${props.data.description.substring(0, 90)}...` : props.data.description }} className="card_product_subtitle" />
							</div>
						</div>
						<div className="position-absolute px-3 bottom-0 start-0 pb-3 w-100">
							<div className='w-100'>
								<ProductCardButton addToCart={addToCart} classes='btn btn_green px-2 py-2 border-0 w-100' productData={productData} />
							</div>
						</div>
					</div>
				</div>
			</div>
			<ModalComponent />
		</React.Fragment>
	);
}

export const OrganizerCard = (props) => {
	let coverImage = props.organizerData.CoverImage;
	let thumbnailImage = props.organizerData.ThumbnailImage;
	if(coverImage === "")
		coverImage = 'https://static.zevio.com/organizerimages/2xcover-I7dqPUxtkU-1661340587843.webp';
	if(thumbnailImage === "")
		thumbnailImage = 'https://static.zevio.com/organizerimages/2xthumb-8ceSfGmAGv-1661340575544.webp';

	return (
		<div className="shadow text-center bg_white organizer_card position-relative hvr-bob">
			<a className="link_nostyle" href={
				LL("/organizer/") + props.organizerData.Safename + "-" + props.organizerData.Id
			}>
				<div className="pb-2">
					<ImageWithSrcSet className="organizer_img_card_scale" width="312" height="110" URL={smallCoverImageURL(coverImage)} imgstr='organizerimages/smallcover' img2xstr='organizerimages/2xsmallcover' alttxt="Organizer Small Hero"/>
					<ImageWithSrcSet className="organizer_profile_image rounded-circle" width="100" height="100" URL={thumbnailImage} imgstr='organizerimages/smallthumb' img2xstr='organizerimages/2xsmallthumb' alttxt='Organizer Small Thumb' />
				</div>
			</a>
			<div>
				<div className="card_header light_grey">{props.organizerData.Name}</div>
				<div className="font_13px px-2">{props.organizerData.Tagline}</div>
				<div className="position-absolute bottom-0 w-100 pb-2">
					<OrganizerFollowElem organizerId={props.organizerData.Id}/>
					{/*<span className="px-2"><img width="32" height="32" src="https://static.zevio.com/gfx/ico_vid.svg" alt='icoeyefill' className="px-2 pb-1" />{props.organizerData.Videos}</span>*/}
				</div>
			</div>
		</div>
	);
}
