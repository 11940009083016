import React, { useCallback, useEffect, useState } from "react";
import { APIGet } from "../../API";
import { LL } from "../../Routes";
import { useParams } from "react-router-dom";

const eventNameIdParser = new RegExp("([a-zA-Z0-9-]+)-([0-9]+)");

export const useEvent = () => {
	const [eventData, setEventData] = useState();
	const [eventId, setEventId] = useState();
	const [uniqueHash, setUniqueHash] = useState();
	const [liveUrl, setLiveUrl] = useState("");
	const [organizerIdsOnEvent, setOrganizerIdsOnEvent] = useState([]);
	const [showingCountDown, setShowingCountdown] = useState(false);
	const [suggestFollow, setSuggestFollow] = useState(true);
	const [followStr, setFollowStr] = useState("");
	const [isOnLiveUrl, setIsOnLiveUrl] = useState(false);

	const params = useParams();

	useEffect(() => {
		if (params.eventNameId) {
			if (!isNaN(params.eventNameId)) {
				setEventId(params.eventNameId);
				setIsOnLiveUrl(false);
			} else {
				setEventId(params.eventNameId.match(eventNameIdParser)[2]);
				setIsOnLiveUrl(false);
				setUniqueHash();
			}
		} else if (params.uniqueHash || params.previewUniqueHash) {
			let isLive = !params.previewUniqueHash;
			setUniqueHash(params.uniqueHash|| params.previewUniqueHash);
			setEventId();
			setIsOnLiveUrl(isLive);
		}
	}, [params]);

	const redirectLiveURL = useCallback(() => {
			window.location.replace(LL("/live/" + eventData.UniqueHash));
		
	}, [eventData]);

	useEffect(() => {
		let organizerNames = {},
			organizers2followStr = "";
		if (!eventData || !Object.keys(eventData).length) {
			return () => null;
		}
		let _organizerIdsOnEvent = [];
		eventData.organizers.forEach((o) => {
			_organizerIdsOnEvent.push(o.Id);
			organizerNames[o.Id] = o.Name;
		});
		setOrganizerIdsOnEvent(_organizerIdsOnEvent);
		APIGet("sequor", {
			action: "organizer_likes",
			organizerIds: _organizerIdsOnEvent.join(","),
		})
			.then((res) => {
				const likeStats = res.data.body;
				Object.keys(res.data.body).forEach((lo) => {
					if (!likeStats[lo].userLikes) {
						if (organizers2followStr.length) organizers2followStr += ", ";
						organizers2followStr += organizerNames[lo];
					}
				});
				if (organizers2followStr.length) setFollowStr(organizers2followStr);
				else setSuggestFollow(false);
			})
			.catch((error) => {
				console.log("Error fetching followers:", error);
			});
	}, [eventData]);

	useEffect(() => {
		if (!!eventId ^ !!uniqueHash) {
			// Only run when we have exactly one of either
			APIGet("search", {
				...(eventId ? { ids: eventId } : {}),
				...(uniqueHash ? { unique_hash: uniqueHash } : {}),
			})
				.then((res) => {
					if (res.data.body.length === 0) {
						setEventData(404);
					} else {
						setEventData(res.data.body[0]);
						setEventId(res.data.body[0].Id);
						setLiveUrl(!!res.data.body[0].UniqueHash);
						setShowingCountdown(
							res.data.body[0].startTimeStamp > parseInt(Date.now() / 1000)
						);
					}
				})
				.catch((error) => {
					console.log(["Error:", error]);
				});
		}
	}, [eventId, uniqueHash]);

	return {
		eventData,
		eventId,
		redirectLiveURL,
		liveUrl,
		organizerIdsOnEvent,
		showingCountDown,
		suggestFollow,
		setSuggestFollow,
		followStr,
		isOnLiveUrl,
	};
};

const EventCtx = React.createContext();

export default EventCtx;
