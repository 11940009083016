import React, { useContext } from "react";
import { smallCoverImageURL } from "../../ReusableElems";
import EventCtx from "../useEvent";

const ImgContainer = () => {
	const {eventData} = useContext(EventCtx)
	let smallCoverImageElem;
	if (eventData.ImageURL) {
		smallCoverImageElem = (
			<img
				src={smallCoverImageURL(eventData.ImageURL)}
				alt="Event Hero"
				className="w-100"
				style={{ maxWidth: "100%" }}
			/>
		);
	}
	if (eventData.CoverImage) {
		smallCoverImageElem = (
			<img
				src={smallCoverImageURL(eventData.CoverImage)}
				alt="Event Hero"
				className="w-100"
				style={{ maxWidth: "100%" }}
			/>
		);
	}

	return (
		<div
			style={{
				overflow: "hidden",
				position: "relative",
				borderTopLeftRadius: 8,
				borderTopRightRadius: 8,
			}}
		>
			{smallCoverImageElem}
			<div className="event_signup_info_container">
				<div className="event_signup_info px-4 text-white mb-sm-0 pb-sm-0">
					<p className="signup_header mb-lg-2 mb-0">
						{eventData.Title.length > 31
							? `${eventData.Title.substring(0, 31)}...`
							: eventData.Title}
					</p>
					<p style={{ fontWeight: "lighter" }}>{eventData.SubTitle}</p>
				</div>
			</div>
		</div>
	);
};

export default ImgContainer;
