// To add routes, edit `src/routes.json`, and add component name to `const
// components` below (reason for src/routes.json is server-side rendering)

import React, { lazy } from 'react';

import { Routes, Route, Link as RRLink, useResolvedPath, createPath  } from 'react-router-dom';

import routes from './routes.json';
import { languages, language_set } from './languages';

import OrderHub from './c/OrderHub/OrderHub';
import ZevioFrontPage from './p/ZevioFrontPage';
import ZevioOrganizerLanding from "./p/ZevioOrganizerLanding";
import { LoginPage } from './p/Login';
import SignUp from './p/SignUp';
import Upcoming from './p/Upcoming';
import PremiumStreams from './p/PremiumStreams';
import Broadcasts from './p/Broadcasts';
import Account from "./p/Account";
import Organizer from './p/Organizer';
import OrganizerEvents from './p/OrganizerEvents';
import Explore from "./p/Explore";
import Event from "./p/Event";
import ChatPage from "./p/ChatPage";
import PrivacyPolicy from "./p/PrivacyPolicy";
import TermsOfUse from "./p/TermsOfUse";
import TermsOfTrade from "./p/TermsOfTrade";
import ResetPassword from "./p/ResetPassword";
import LegacyAdmin from "./p/LegacyAdmin";
import MyWebinars from "./p/MyWebinars";
import Products from "./p/Products";
import EditProduct from "./p/EditProduct";
import BuchDesTages from "./p/BuchDesTages";
import AdminIntern from "./p/AdminIntern";

const EditEvent = lazy( () => import('./p/EditEvent') );
const EditOrganizer = lazy( () => import('./p/EditOrganizer') );
const GoLiveDaily = lazy( () => import('./p/GoLiveDaily') );

const components = {
	OrderHub,
	ZevioFrontPage,
	ZevioOrganizerLanding,
	LoginPage,
	SignUp,
	Upcoming,
	PremiumStreams,
	EditOrganizer,
	EditEvent,
	Broadcasts,
	Account,
	Organizer,
	OrganizerEvents,
	Explore,
	Event,
	ChatPage,
	PrivacyPolicy,
	TermsOfUse,
	TermsOfTrade,
	ResetPassword,
	LegacyAdmin,
	MyWebinars,
	GoLiveDaily,
	Products,
	EditProduct,
	BuchDesTages,
	AdminIntern,
};

const ZevioRoutes = (props) => {
	const get_component = (r) => {
		let C = components[r.component];
		if (C === undefined) throw new Error("component defined in routes.json but does not exist locally: " + r.component + " for route \"" + r.route + "\"");
		return <C/>;
	}
	let rs = [];
	for (const route of routes) {
		for (const language of languages) {
			const key = language + ":" + route.route;
			const path = "/" + language + route.route;
			rs.push(<Route key={key} path={path} element={get_component(route)}/>);
		}
	}
	return <Routes>{rs}</Routes>;
};

const get_path_array = () => {
	// react-router-dom has `useLocation`, which ostensibly does the same
	// as `window.location`, but has these weird React-hook requirements
	// that make them unusable outside of components, so here's some really
	// old fashioned JS for you!
	return window.location.pathname.split("/").slice(1);
}

const maybe_get_current_language = () => {
	const ps = get_path_array();
	if (ps.length === 0) return null;
	const lang = ps[0];
	if (language_set[lang]) {
		return lang;
	} else {
		return null;
	}
};

const get_current_language = () => {
	const lang = maybe_get_current_language();
	if (!lang) throw new Error("failed to get current language");
	return lang;
};

const LL = (path) => "/" + get_current_language() + path;

const Link = (props) => {
	const path = LL(createPath(useResolvedPath(props.to)));
	const pprops = {...props, to: undefined, children: undefined};
	return <RRLink {...pprops} to={path}>{props.children}</RRLink>;
};

const goto_language = (lang) => {
	if (lang === get_current_language()) return;
	let xs = get_path_array();
	xs[0] = lang;
	window.location.pathname = xs.join("/");
};

export {
	ZevioRoutes,
	Link,
	LL,
	get_current_language,
	goto_language,
};
