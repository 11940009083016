import React from "react";
import { T } from '../../traks';

const DateSelect = ({ setFromDate, fromDate, setToDate, toDate }) => {
	return (
		<div className="d-flex my-3">
			<div className="me-3 mb-3">
				<label htmlFor="fromDate" className="form-label">
					<T>From</T>
				</label>
				<input
					className="form-control"
					id="fromDate"
					type="date"
					onChange={(e) => setFromDate(e.target.value)}
					value={fromDate}
				/>
			</div>
			<div className="mx-3 mb-3">
				<label htmlFor="toDate" className="form-label">
					<T>To</T>
				</label>
				<input
					id="toDate"
					className="form-control"
					type="date"
					onChange={(e) => setToDate(e.target.value)}
					value={toDate}
				/>
			</div>
		</div>
	);
};

export default DateSelect
