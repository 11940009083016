import React, { useState, useEffect } from "react";
import { Container, Spinner, Collapse } from "reactstrap";
import { APIGet, APIPost } from '../API.js';
import { EventCard, ProductCard } from './Cards';
import { T } from '../traks';
import { LL } from "../Routes";
import { useNavigate } from "react-router-dom";
import { GreenButton, ImageWithSrcSet, HorizontalSmoothScroller, smallCoverImageURL } from './ReusableElems';
import EventFiltering from "./EventFiltering";
import { MostWatchedEvents } from "./ExploreContent";

export const OrganizerNotFound = () => {
	return (<>
		<Container fluid className="bg_very_light_grey">
			<Container className="py-5">
				<div className='text-center py-lg-5'>
					<div className="font_24px font_weight_600">
						<T>Organiser not found</T>
					</div>
					<div className="font_26px grey_frontpage pb-4">
						<T>This organiser does not exist.</T>
					</div>
					<a href={LL("/explore")}><GreenButton><T>Explore streams</T></GreenButton></a>
				</div>
			</Container>
		</Container>
		<Container fluid className="bg_white">
			<Container className="pt-3">
				<div className="pt-3 pt-lg-5">
					<div className="explore_subtitle"><T>Most watched</T></div>
					<MostWatchedEvents />
				</div>
			</Container>
		</Container>
	</>);
}

const OrganizerProfile = (props) => {
	const organizerNameIdParser = new RegExp('([a-zA-Z0-9-]+)-([0-9]+)');
	const [ organizerData, setOrganizerData ] = useState({});
	const [ followers, setFollowers ] = useState(0);
	const [ userFollowing, setUserFollowing ] = useState(false);
	const navigate = useNavigate();
	let organizerId;
	let safenameFromURL = '';
	if(!isNaN(props.organizerNameId)) {
		organizerId = props.organizerNameId;
	} else {
		organizerId = props.organizerNameId.match(organizerNameIdParser)[2];
		safenameFromURL = props.organizerNameId.match(organizerNameIdParser)[1];
	}

	useEffect(() => {
		APIGet('search', {'organizer_id': organizerId})
			.then((res) => {
				if(res.data.body[0].Safename !== safenameFromURL) {
					navigate(LL('/organizer/' + res.data.body[0].Safename + '-' + organizerId));
				}
				if(res.data.body[0].CoverImage === "") {
					res.data.body[0].CoverImage = 'https://static.zevio.com/organizerimages/2xcover-I7dqPUxtkU-1661340587843.webp';
				}
				if(res.data.body[0].ThumbnailImage === "") {
					res.data.body[0].ThumbnailImage = 'https://static.zevio.com/organizerimages/2xthumb-8ceSfGmAGv-1661340575544.webp';
				}
				setOrganizerData(res.data.body[0]);
			})
			.catch((error) => {
				if(error.response.status === 404) {
					setOrganizerData(404);
				} else {
					console.log(['API Error', error]);
				}
			})
		if(organizerId) {
			getFollowStatus(organizerId);
		}
	}, [organizerId, safenameFromURL, navigate]);

	const getFollowStatus = (organizerId) => {
		APIGet('sequor', {'action': 'organizer_likes', 'organizerIds': organizerId})
			.then((res) => {
				setFollowers(res.data.body[organizerId].count);
				setUserFollowing(res.data.body[organizerId].userLikes);
			})
			.catch((error) => {
				console.log('Error fetching followers:', error);
			})
        };

	const FollowElem = () => {
		const action = userFollowing ? 'unlike_organizers' : 'like_organizers';
		const clickFollowIcon = () => {
			APIGet('sequor', {'action': action, 'organizerIds': organizerId})
				.then((res) => {
					setFollowers(userFollowing ? followers -1 : followers +1);
					setUserFollowing(!userFollowing);
				})
				.catch((error) => {
					console.log('Error fetching followers:', error);
				})
		}
		let followButton = userFollowing ? <T>Following</T> : <span><img className="pe-1" width="23" height="23" src="https://static.zevio.com/gfx/ico_bell.svg" alt='icoath' /><T>Follow</T></span>;
		return (
			<button onClick={clickFollowIcon} className="w45_mobile btn btn_green py-2 px-3">{followButton}</button>
		);
	}

	const OrganizerProfile = () => {
		
		const followIcon = userFollowing ? "https://static.zevio.com/gfx/ico_following.svg" : "https://static.zevio.com/gfx/ico_follow.svg";

		return (
			<Container className="p-0">
				<div className="font_18px">
					<ImageWithSrcSet className="w-100 d-none d-lg-block organizer_hero" width="1320" height="400" URL={organizerData.CoverImage} imgstr='organizerimages/cover' img2xstr='organizerimages/2xcover' alttxt="Organizer Hero"/>
					<ImageWithSrcSet className="w-100 d-lg-none" width="320" height="97" URL={smallCoverImageURL(organizerData.CoverImage)} imgstr='organizerimages/smallcover' img2xstr='organizerimages/2xsmallcover' alt="Organizer Hero" />
					<div className="d-flex flex-row">
						<ImageWithSrcSet className="d-none d-lg-block organizer_profile_image rounded-circle" width="150" height="150" URL={organizerData.ThumbnailImage} imgstr='organizerimages/thumb' img2xstr='organizerimages/2xthumb' alt="Profile" />
						<ImageWithSrcSet className="d-lg-none organizer_profile_image rounded-circle" width="75" height="75" URL={organizerData.ThumbnailImage} imgstr='organizerimages/thumb' img2xstr='organizerimages/2xthumb' alt="Profile" />
						<div className="d-flex flex-column ps-2">
							<h5 className="m-0 pt-2">
								{organizerData.Name}
							</h5>
							<div className="p-0 grey_light">
								{organizerData.Tagline}
							</div>
							<div className="d-none d-lg-block">
								<span className="pe-2 font_15px"><img className="pb-1 pe-1" width="25" height="25" src={followIcon} alt='icocomment' />{followers} <T>followers</T></span>
								<FacebookLink URL={organizerData.FacebookLink} />
								<InstagramLink URL={organizerData.InstagramLink} />
								<YoutubeLink URL={organizerData.YoutubeLink} />
								<LinkedInLink URL={organizerData.LinkedinLink} />
								<TwitterLink URL={organizerData.TwitterLink} />
							</div>
						</div>
						<div className="d-none d-lg-block ms-auto">
							<div className="pt-4 text-nowrap">
								{organizerData.Website.length > 0 && <span className="pe-2"><button onClick={() => window.open((organizerData.Website), "_blank")} className="w45_mobile btn btn_grey font_weight_500 py-2 ms-1"><T>Visit webite</T></button></span>}
								<FollowElem />
							</div>
						</div>
					</div>
					<div className="d-lg-none d-flex flex-row justify-content-start ps-3 pe-1 pt-3">
						<span className="font_15px me-auto"><img className="pb-1 pe-1" width="25" height="25" src={followIcon} alt='icocomment' />{followers} <T>followers</T></span>
						<FacebookLink URL={organizerData.FacebookLink} />
						<InstagramLink URL={organizerData.InstagramLink} />
						<YoutubeLink URL={organizerData.YoutubeLink} />
						<LinkedInLink URL={organizerData.LinkedinLink} />
						<TwitterLink URL={organizerData.TwitterLink} />
					</div>
					<div className="d-lg-none text-center text-lg-end">
						<div className="pt-4">
							<span className="pe-2"><button onClick={() => window.open((organizerData.Website), "_blank")} className="w45_mobile btn btn_grey font_weight_500 py-2"><T>Visit webite</T></button></span>
							<FollowElem />
						</div>
					</div>
				</div>
			</Container>
		);
	}

	if (Object.keys(organizerData).length) {
		return (
			<Container className="p-0">
				<OrganizerProfile />
				<Collapsible organizerData={organizerData} />
				<OrganizerEventChoice organizerId={organizerId} organizerNameId={props.organizerNameId} />
			</Container>
		);
	} else {
		if(organizerData === 404)
			return <OrganizerNotFound />

		return <div className='text-center py-2'><T>Loading organizer</T> ... <Spinner className="p-2" color="success" /></div>;
	}
};

const OrganizerEventChoice = (props) => {

	const organizerNameIdParser = new RegExp('[a-zA-Z0-9-]+-([0-9]+)');
	const id = props.organizerNameId.match(organizerNameIdParser)[1];
	const [index, setIndex] = useState(1);
	const [activeEvents, setActiveEvents] = useState([]);
	const [products, setProducts] = useState(false);

	useEffect(() => {
		APIGet('search', { 'limit': '32', 'organizer': id, 'futureandpast': true })
			.then((res) => {
				if (res.data.body.length) {
					setActiveEvents(res.data.body);
				}
			})
			.catch((error) => {
				console.log('Error:', error);
			})
	},	// eslint-disable-next-line react-hooks/exhaustive-deps
	[]);

	const EventCardComponent = () => {
		return (
			<HorizontalSmoothScroller>
				{activeEvents.map((v, i) => (
					<EventCard eventData={v} key={"explore" + i}
					/>
				))}
			</HorizontalSmoothScroller>
		);
	}

	const [productsData, setProductsData] = useState([]);

	useEffect(() => {
		const paramsHotDeals = {
			'action': 'get_products',
			'organizer_ids': [props.organizerId],
			'only_active': ['true'],
		};
		APIPost('products', paramsHotDeals)
			.then((res) => {
				setProductsData(res.data.products);
			})
			.catch((error) => {
				console.log(['Error getting products:', error]);
		}	)
		return () => setProductsData('');
	}, [props.organizerId])

	const ProductCardComponent = () => {
		if (productsData.length === 0) {
			return null
		}

		return (
			<div>
				<div className="ps-3 ps-lg-0 py-4">
					<h2><T>Exclusive deals</T></h2>
				</div>
				<HorizontalSmoothScroller>
					{productsData.map((v, i) => v.active === true ?
						<div key={i}>
							<ProductCard data={v} i={i} />
						</div>
						:
						null
					)}
				</HorizontalSmoothScroller>
			</div>
		);
	}

	const ProductButton = () => {
		if (productsData.length === 0) {
			return null
		}
		return (
			<button className="btn_radio"
				onClick={() => setIndex(2) + setProducts(true)} disabled={index === 2}>
				<T>Products</T>
			</button>
		);
	}

	return (
		<div className="py-4">
				<HotDealsProducts productsData={productsData} />
				<div className="buttonGroup">
					{(activeEvents && activeEvents.length)  &&
						<button className="btn_radio"
							onClick={() => setIndex(1) + setProducts(false)} disabled={index === 1}
						>
							<T>All events</T>
						</button>
					}
					<ProductButton />
				</div>
			<div className="py-4">
				{products
					?
					<ProductCardComponent />
					:
					<div>
							<EventCardComponent />
					</div>
				}
			</div>
		</div>
	);
}

const Collapsible = (props) => {
	const organizerData = props.organizerData;
	const [showMore, setShowMore] = useState(false);
	const toggle = () => {
		setShowMore(!showMore);
	}
	return (
		organizerData.Description.length > 650 ?
		<div className="px-3 px-lg-0 pt-4 pt-lg-5">
			<div className="collapsible p-2 p-lg-4">
				<h6>
					<T>About</T> {organizerData.Name}
				</h6>
				<Collapse className="pt-3 grey_light" isOpen={showMore}>
					{organizerData.Description}
				</Collapse>
				<Collapse className="pt-3 grey_light" isOpen={!showMore}>
					{organizerData.Description.length > 25 ? `${organizerData.Description.substring(0, 430)}...` : organizerData.Description}
				</Collapse>
				<div className="color_green btn_no_highlight pt-4 cursor-pointer font_weight_700" onClick={toggle}>
					{showMore ? <T>See less</T> : <T>Read more</T>}
				</div>
			</div>
		</div>
		:
			<div className="collapsible p-2 m-2 mt-5 px-3 py-3 p-lg-4">
			<h6 className="pb-3">
				<T>About</T> {organizerData.Name}
			</h6>
			<span className="grey_light">{organizerData.Description}</span>
		</div>
	);
}

const FilterComponent = (props) => {
	return (
		<EventFiltering
			limit={8}
			buttonLoad={true}
			noAutoLang={true}
			oldEvents={Boolean(props.indexN)}
			organizerId={props.orgId}
			disableCount={true}
		/>
	);
}

const InstagramLink = (props) => {
	if (props.URL) {
		return (
			<span className="pe-2">
				<a alt="Instagram profile" target="_blank" rel="noopener noreferrer" href={props.URL}>
					<img src="https://static.zevio.com/imgs/instagram-logo-0.svg" className="organizer_icon_some" alt='icoig' />
				</a>
			</span>
		);
	}
	return null;
}

const FacebookLink = (props) => {
	if (props.URL) {
		return (
			<span className="pe-2">
				<a alt="Facebook profile" target="_blank" rel="noopener noreferrer" href={props.URL}>
					<img src="https://static.zevio.com/imgs/facebook-logo-0.svg" className="organizer_icon_some" alt="icofb" />
				</a>
			</span>
		);
	}
	return null;
}

const LinkedInLink = (props) => {
	if (props.URL) {
		return (
			<span className="pe-2">
				<a alt="LinkedIn profile" target="_blank" rel="noopener noreferrer" href={props.URL}>
					<img src="https://static.zevio.com/imgs/linkedin-logo-0.svg" className="organizer_icon_some" alt="icoli" />
				</a>
			</span>
		);
	}
	return null;
}

const TwitterLink = (props) => {
	if (props.URL) {
		return (
			<span className="pe-2">
				<a alt="Twitter profile" target="_blank" rel="noopener noreferrer" href={props.URL}>
					<img src="https://static.zevio.com/imgs/twitter-logo-0.svg" className="organizer_icon_some" alt="icotw" />
				</a>
			</span>
		);
	}
	return null;
}

const YoutubeLink = (props) => {
	if (props.URL) {
		return (
			<span className="pe-2">
				<a alt="Youtube profile" target="_blank" rel="noopener noreferrer" href={props.URL}>
					<img src="https://static.zevio.com/imgs/youtube-logo-0.svg" className="organizer_icon_some" alt="icoyt" />
				</a>
			</span>
		);
	}
	return null;
}

const HotDealsProducts = (props) => {
	if (props.productsData && props.productsData.length)
		return (
			<>
				<div className="ps-3 ps-lg-0 pt-3 pt-lg-5 pb-2">
					<h2><T>Hot deals</T></h2>
				</div>
				<div className="scrolling-wrapper-flexbox">
					{props.productsData.map((v, i) => v.active === true ?
						<ProductCard data={v} i={i} key={i} />
						:
						null
					)}
				</div>
			</>
		);
	return null;
}

export default OrganizerProfile;
