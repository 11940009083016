import React, { useContext, useEffect, useState } from "react";
import { Collapse, Container, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import Flag from './Flags';
import ZevioSearchBar from "./ZevioSearchBar";
import { GreenButton } from "./ReusableElems";
import { MeContext } from '../MeContext';
import { T, TraksConsumer } from '../traks';
import { APIGet } from '../API';
import { LL, goto_language, get_current_language } from '../Routes';
import { languagedefs } from '../languages';
import { useNavigate } from "react-router-dom";
import { url_lang } from "../languages";

import navbarlogo from './logo-color-0.svg';

export const LanguageSelect = (props) => {
	const [ is_open, set_is_open ] = useState(false);
	const toggle = () => set_is_open(!is_open);
	let selector = null;

	const langs = languagedefs.map((langdef) => {
		if(langdef.id === 4) return null; // French is language non grata
		const lang = langdef.lang;
		const do_select = () => goto_language(lang);
		const selected = get_current_language() === lang;
		if(!selected) {
			return (
				<DropdownItem key={lang} onClick={do_select}>
					<Flag className="lang_dropdown_flag pe-1" width="24" height="18" lang={lang} />
					<span className="text-capitalize">{langdef.native}</span>
				</DropdownItem>
			);
		} else {
			selector = <Flag className="lang_dropdown_flag" width="24" height="18" lang={lang} />;
			return null;
		}
	});

	return (
		<Dropdown isOpen={is_open} toggle={toggle}>
			<DropdownToggle id="selectlang" aria-label="langflag" className="lang_selector px-0 px-lg-2">
				<span className="d-lg-none pe-2"><T>Language</T></span>{selector}
			</DropdownToggle>
			<DropdownMenu>
				{langs}
			</DropdownMenu>
		</Dropdown>
	);
};

const LoggedInIcon = () => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const navigate = useNavigate();
	const { setMe } = useContext(MeContext);

	const logout = () => {
		APIGet("logout").then((response) => {
			if (response.status === 200) {
				localStorage.removeItem('zevio_ipse_session_id');
				setMe(null);
				navigate(LL('/upcoming'));
			}
		})
	};

	return (
		<Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)} >
			<DropdownToggle className="lang_selector px-0 px-lg-2 me-lg-2">
				<span className="d-lg-none pe-2"><T>User menu</T></span>
				<img alt="User menu" src="https://static.zevio.com/gfx/ico_user.svg" width="26px" height="26px" className="navbar_user_icon"/>
			</DropdownToggle>
			<DropdownMenu>
				<DropdownItem onClick={() => navigate(LL('/account'))}><T>My account</T></DropdownItem>
				<DropdownItem divider />
				<DropdownItem onClick={() => navigate(LL('/mywebinars'))}><T>My webinars</T></DropdownItem>
				<DropdownItem divider />
				<DropdownItem onClick={logout}><T>Logout</T></DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
}

const nav_green_button = (button_href, button_text) => {
	return (
		<NavLink className="text-white pe-0 pe-lg-3" href={button_href}>
			<GreenButton>{button_text}</GreenButton>
		</NavLink>
	);
}

const NavsNotLoggedIn = () => {
	const desktop_top_right_button = <div>{nav_green_button(LL("/signup"), <T>Sign up</T>)}</div>;

	return (
		<NavItem className="d-flex d-none d-lg-block align-items-center">
			{desktop_top_right_button}
		</NavItem>
	);
}

const ProfilePicture = (props) => {
	let organizer = null;
	if(props.organizer) {
		organizer = props.organizer;
	}
	if(!organizer || organizer.ThumbnailImage === "") {
		return (
			<div className="text-center navbar_organizer_profile_img d-flex align-items-center justify-content-center" style={{backgroundColor: "#2c0c7c", color: "white"}}>
				<div className='font_24px text-white'>{organizer.Name[0]}</div>
			</div>
		);
	} else {
		return <img width="100%" height="100%" alt="profile" className="navbar_organizer_profile_img" src={organizer.ThumbnailImage} />;
	}
}

const NameAndProfilePicture = ({myOrganizer}) => {
	return(
		<div className="d-flex align-items-center">
			<div className="font_weight_500 pe-2">{myOrganizer.Name}</div>
			<ProfilePicture organizer={myOrganizer} />
		</div>
	);
}

const OrganizerSelector = (props) => {
	let myOrganizer = {};
	const [ isOpen, setIsOpen ] = useState(false);
	const { me, setMe } = useContext( MeContext );
	const navigate = useNavigate();

	const toggle = (e) => !isHovered && setIsOpen(!isOpen);

	const orgs = props.organizers.sort((a, b) => a.Safename.localeCompare(b.Safename)).map( (org) => {
		if(org.Id === me.currentSelectedOrganizer) {
			myOrganizer = org;
			return null;
		} else {
			const selectOrganizer = () => {
				localStorage.setItem("zevio_current_organizer_id", org.Id);
				setMe( {...me, currentSelectedOrganizer: org.Id} );
				navigate(LL('/organizer/' + org.Safename + '-' + org.Id));
			}
			return <DropdownItem className="pos_right" key={org.Id} onClick={selectOrganizer}><NameAndProfilePicture myOrganizer={org} /></DropdownItem>;
		}
	});

	const [isHovered, setIsHovered] = useState(false);

	return (
		<Dropdown isOpen={isOpen} toggle={toggle}>
			<DropdownToggle className="organizer_dropdown_toggle pe-0">
				<NameAndProfilePicture myOrganizer={myOrganizer} />
			</DropdownToggle>
			{orgs && <DropdownMenu>
				<div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
					{orgs}
				</div>
			</DropdownMenu>
			}
		</Dropdown>
	);
}

const OrganizerNavPart = (props) => {
	const [organizers, setOrganizers] = useState(null);

	useEffect(() => {
		APIGet('search', { 'organizer_id': props.me.organizers.join(',') }).then((res) => {
			setOrganizers(res.data.body);
		}).catch((error) => {
			console.log('Error:', error);
		});
	}, [props.me.organizers]);

	const isAdmin = () => {
		return props.me && props.me.roles.includes(42);
	}

	return (
		<Container>
			<div className="d-flex flex-row font_15px align-items-center">
				<div className="d-none pe-5">
					<svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginBottom: "4px", marginRight: "5px"}}>
					<path id="Shape" fillRule="evenodd" clipRule="evenodd" d="M12.501 4.79272V1.29272C12.501 1.01658 12.2771 0.792725 12.001 0.792725H11.001C10.7248 0.792725 10.501 1.01658 10.501 1.29272V2.79272L12.501 4.79272ZM1.50098 5.79272V12.2927C1.50098 13.1212 2.17255 13.7927 3.00098 13.7927H12.001C12.8294 13.7927 13.501 13.1212 13.501 12.2927V5.79272H12.501V12.2927C12.501 12.5689 12.2771 12.7927 12.001 12.7927H3.00098C2.72483 12.7927 2.50098 12.5689 2.50098 12.2927V5.79272H1.50098Z" fill="#2A2D32"/>
					<path id="Path" d="M6.79363 0.292786C7.18413 -0.0975955 7.81713 -0.0975955 8.20763 0.292786L14.8546 6.93879C15.0501 7.1343 15.0501 7.45128 14.8546 7.64679C14.6591 7.8423 14.3421 7.8423 14.1466 7.64679L7.50063 0.999786L0.854632 7.64679C0.659123 7.8423 0.34214 7.8423 0.146632 7.64679C-0.0488772 7.45128 -0.0488772 7.1343 0.146632 6.93879L6.79363 0.292786Z" fill="black"/>
					</svg>
					<T>Home</T>
				</div>
				<div className="pe-5">
					<svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginBottom: "2px", marginRight: "5px"}}>
					<path id="Shape" fillRule="evenodd" clipRule="evenodd" d="M0 2C0 0.89543 0.89543 1.40531e-08 2 1.40531e-08H9.5C10.5034 -0.000118015 11.3516 0.743259 11.483 1.738L14.593 0.356C14.9023 0.21819 15.2603 0.24625 15.5443 0.430562C15.8284 0.614875 15.9999 0.930401 16 1.269V8.731C15.9997 9.06933 15.8284 9.38456 15.5446 9.56882C15.2609 9.75307 14.9032 9.78136 14.594 9.644L11.483 8.262C11.3516 9.25674 10.5034 10.0001 9.5 10H2C0.89543 10 0 9.10457 0 8V2ZM11.5 7.175L15 8.731V1.269L11.5 2.825V7.175ZM1 2C1 1.44772 1.44772 1 2 1H9.5C10.0523 1 10.5 1.44772 10.5 2V8C10.5 8.55229 10.0523 9 9.5 9H2C1.44772 9 1 8.55229 1 8V2Z" fill="#1D2229"/>
					</svg>
					<a alt="broadcasts" href={LL("/broadcasts")}><T>Broadcasts</T></a>
				</div>
				<div className="pe-5">
					<svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginBottom: "2px", marginRight: "5px"}}>
					<path fillRule="evenodd" clipRule="evenodd" d="M0 1.5C0 0.671573 0.671573 0 1.5 0H14.5C15.3284 0 16 0.671573 16 1.5V10.5C16 11.3284 15.3284 12 14.5 12H1.5C0.671573 12 0 11.3284 0 10.5V1.5ZM15 1.5C15 1.22386 14.7761 1 14.5 1H1.5C1.22386 1 1 1.22386 1 1.5V10.5C1 10.7761 1.22386 11 1.5 11H14.5C14.7761 11 15 10.7761 15 10.5V1.5Z" fill="#1D2229"/>
					<path fillRule="evenodd" clipRule="evenodd" d="M3.5 3C3.22386 3 3 3.22386 3 3.5V4.5C3 4.77614 3.22386 5 3.5 5H12.5C12.7761 5 13 4.77614 13 4.5V3.5C13 3.22386 12.7761 3 12.5 3H3.5ZM3 6.5C3 6.22386 3.22386 6 3.5 6H12.5C12.7761 6 13 6.22386 13 6.5C13 6.77614 12.7761 7 12.5 7H3.5C3.22386 7 3 6.77614 3 6.5ZM3 8.5C3 8.22386 3.22386 8 3.5 8H9.5C9.77614 8 10 8.22386 10 8.5C10 8.77614 9.77614 9 9.5 9H3.5C3.22386 9 3 8.77614 3 8.5Z" fill="#1D2229"/>
					</svg>
					<a alt="my page" href={LL("/editorganizer")}><T>My page</T></a>
				</div>
				{isAdmin() && <>
					<div className="pe-5">
						<svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginBottom: "2px", marginRight: "5px"}}>
						<path id="Shape" fillRule="evenodd" clipRule="evenodd" d="M0 1.5C0 0.671573 0.671573 0 1.5 0H14.5C15.3284 0 16 0.671573 16 1.5V10.5C16 11.3284 15.3284 12 14.5 12H1.5C0.671573 12 0 11.3284 0 10.5V1.5ZM15 1.5C15 1.22386 14.7761 1 14.5 1H1.5C1.22386 1 1 1.22386 1 1.5V10.5C1 10.7761 1.22386 11 1.5 11H14.5C14.7761 11 15 10.7761 15 10.5V1.5Z" fill="#1D2229"/>
						<path id="Shape_2" fillRule="evenodd" clipRule="evenodd" d="M5.65095 2.99992C5.65095 2.86709 5.5981 2.73972 5.50406 2.64592C5.41025 2.55188 5.28288 2.49902 5.15006 2.49902C5.01723 2.49902 4.88986 2.55188 4.79606 2.64592L3.65006 3.79292L3.50406 3.64592C3.37759 3.51945 3.19325 3.47005 3.02048 3.51634C2.84772 3.56264 2.71278 3.69758 2.66648 3.87034C2.62019 4.04311 2.66959 4.22745 2.79606 4.35392L3.29606 4.85392C3.38986 4.94796 3.51723 5.00081 3.65006 5.00081C3.78288 5.00081 3.91025 4.94796 4.00406 4.85392L5.50406 3.35392C5.5981 3.26011 5.65095 3.13274 5.65095 2.99992ZM7.50006 2.99992C7.22391 2.99992 7.00006 3.22377 7.00006 3.49992C7.00006 3.77606 7.22391 3.99992 7.50006 3.99992H12.5001C12.7762 3.99992 13.0001 3.77606 13.0001 3.49992C13.0001 3.22377 12.7762 2.99992 12.5001 2.99992H7.50006ZM7.00006 7.49992C7.00006 7.22377 7.22391 6.99992 7.50006 6.99992H12.5001C12.7762 6.99992 13.0001 7.22377 13.0001 7.49992C13.0001 7.77606 12.7762 7.99992 12.5001 7.99992H7.50006C7.22391 7.99992 7.00006 7.77606 7.00006 7.49992ZM5.65095 6.99992C5.65095 6.86709 5.5981 6.73972 5.50406 6.64592C5.41025 6.55188 5.28288 6.49902 5.15006 6.49902C5.01723 6.49902 4.88986 6.55188 4.79606 6.64592L3.65006 7.79292L3.50406 7.64592C3.37759 7.51944 3.19325 7.47005 3.02048 7.51634C2.84772 7.56264 2.71278 7.69758 2.66648 7.87034C2.62019 8.04311 2.66959 8.22745 2.79606 8.35392L3.29606 8.85392C3.38986 8.94796 3.51723 9.00081 3.65006 9.00081C3.78288 9.00081 3.91025 8.94796 4.00406 8.85392L5.50406 7.35392C5.5981 7.26011 5.65095 7.13274 5.65095 6.99992Z" fill="#1D2229"/>
						</svg>
						<a alt="Order hub" href={LL("/orderhub")}><T>Order hub</T></a>
					</div>
					<div className="pe-5">
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginBottom: "2px", marginRight: "5px"}}>
						<path id="Shape" fillRule="evenodd" clipRule="evenodd" d="M8.557 0.107251C8.19943 -0.0357502 7.80057 -0.0357502 7.443 0.107251L0.314 2.95925C0.124371 3.03525 4.75466e-05 3.21896 0 3.42325V12.0853C0.000422151 12.4943 0.249957 12.8619 0.63 13.0133L7.814 15.8873C7.93338 15.9351 8.06662 15.9351 8.186 15.8873L15.371 13.0133C15.7507 12.8616 15.9997 12.4941 16 12.0853V3.42325C16 3.21896 15.8756 3.03525 15.686 2.95925L8.557 0.107251ZM7.814 1.03625C7.93338 0.988406 8.06662 0.988406 8.186 1.03625L14.154 3.42325L8 5.88425L1.846 3.42325L7.814 1.03625ZM8.5 6.76225L15 4.16225V4.16325V12.0843L8.5 14.6843V6.76225ZM7.5 14.6853V6.76125L1 4.16225V12.0853L7.5 14.6853Z" fill="black"/>
						</svg>
						<a alt="Order hub" href={LL("/products")}><T>Products</T></a>
					</div>
					<div className="pe-5">
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginBottom: "2px", marginRight: "5px" }}>
							<path id="Shape" fillRule="evenodd" clipRule="evenodd" d="M8.557 0.107251C8.19943 -0.0357502 7.80057 -0.0357502 7.443 0.107251L0.314 2.95925C0.124371 3.03525 4.75466e-05 3.21896 0 3.42325V12.0853C0.000422151 12.4943 0.249957 12.8619 0.63 13.0133L7.814 15.8873C7.93338 15.9351 8.06662 15.9351 8.186 15.8873L15.371 13.0133C15.7507 12.8616 15.9997 12.4941 16 12.0853V3.42325C16 3.21896 15.8756 3.03525 15.686 2.95925L8.557 0.107251ZM7.814 1.03625C7.93338 0.988406 8.06662 0.988406 8.186 1.03625L14.154 3.42325L8 5.88425L1.846 3.42325L7.814 1.03625ZM8.5 6.76225L15 4.16225V4.16325V12.0843L8.5 14.6843V6.76225ZM7.5 14.6853V6.76125L1 4.16225V12.0853L7.5 14.6853Z" fill="black" />
						</svg>
						<a alt="Order hub" target="_blank" rel="noopener noreferrer" href={LL("/editproduct")}><T>New product</T></a>
					</div>
				</>}
				<div className="pe-5">
					<svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginBottom: "2px", marginRight: "5px" }}>
						<path fillRule="evenodd" clipRule="evenodd" d="M0 1.5C0 0.671573 0.671573 0 1.5 0H14.5C15.3284 0 16 0.671573 16 1.5V10.5C16 11.3284 15.3284 12 14.5 12H1.5C0.671573 12 0 11.3284 0 10.5V1.5ZM15 1.5C15 1.22386 14.7761 1 14.5 1H1.5C1.22386 1 1 1.22386 1 1.5V10.5C1 10.7761 1.22386 11 1.5 11H14.5C14.7761 11 15 10.7761 15 10.5V1.5Z" fill="#1D2229" />
						<path fillRule="evenodd" clipRule="evenodd" d="M3.5 3C3.22386 3 3 3.22386 3 3.5V4.5C3 4.77614 3.22386 5 3.5 5H12.5C12.7761 5 13 4.77614 13 4.5V3.5C13 3.22386 12.7761 3 12.5 3H3.5ZM3 6.5C3 6.22386 3.22386 6 3.5 6H12.5C12.7761 6 13 6.22386 13 6.5C13 6.77614 12.7761 7 12.5 7H3.5C3.22386 7 3 6.77614 3 6.5ZM3 8.5C3 8.22386 3.22386 8 3.5 8H9.5C9.77614 8 10 8.22386 10 8.5C10 8.77614 9.77614 9 9.5 9H3.5C3.22386 9 3 8.77614 3 8.5Z" fill="#1D2229" />
					</svg>
					<a alt="my page" target="_blank" rel="noopener noreferrer" href={LL("/editevent")}><T>New event</T></a>
				</div>
				<div className="ms-auto py-2 d-flex align-items-center">
					{organizers && <OrganizerSelector me={props.me} organizers={organizers} />}
				</div>
			</div>
		</Container>
	)
}

const ZevioNavInner = (props) => {
	const { me } = useContext(MeContext);
	const [ barIsOpen, setBarIsOpen ] = useState(false);
	let class_name = 'navbar_height d-flex flex-row align-items-center';
	let bg_css_class = 'navbar_bg_dark';
	let top_search_css = 'col-form-label-sm search_navbar no_outline_focus input_search w-100';
	let top_search_css_extra = 'search_bg_dark';
	let fg_css_class = 'text-white';

	if(props.transparent) {
		bg_css_class = '';
		top_search_css_extra = 'search_bg_transparent';
		fg_css_class = 'navbar_fg_dark';
	}

	class_name = `${class_name} ${bg_css_class} ${fg_css_class}`;
	top_search_css = `${top_search_css} ${top_search_css_extra}`;
	let currentSelectedOrganizer = null;
	if(me && me.currentSelectedOrganizer) {
		currentSelectedOrganizer = me.currentSelectedOrganizer;
	}

	const toggleNavbar = () => {
		setBarIsOpen(!barIsOpen);
	}

	return(
		<>
		<Navbar expand="lg" className={class_name}>
			<NavbarBrand className="ps-lg-5" href={LL("/")}>
				<img alt="Zevio event streaming" width="48" height="48" className="logo_navbar" src={navbarlogo} />
			</NavbarBrand>
			<div className="d-lg-none ms-auto d-flex">
				<NavbarToggler className="ps-0" onClick={toggleNavbar} />
			</div>
			<div className="search_width">
				<TraksConsumer>
					<ZevioSearchBar cssClasses={top_search_css} />
				</TraksConsumer>
			</div>
			<Collapse navbar isOpen={barIsOpen}>
				<Nav navbar className="d-none d-lg-block me-auto">
					<NavItem className="d-flex ps-xl-5 d-none">
						<TraksConsumer>
							<ZevioSearchBar cssClasses={top_search_css} />
						</TraksConsumer>
					</NavItem>
				</Nav>
				<Nav navbar>
					{url_lang().id === 1 &&
					<NavItem className="d-flex align-items-center pt-3 pt-lg-0">
						<NavLink className={fg_css_class} href={LL("/upcoming")}><T>Premium</T></NavLink>
					</NavItem>
					}
					<NavItem className="d-flex align-items-center">
						<NavLink className={fg_css_class} href={LL("/explore")}><T>All streams</T></NavLink>
					</NavItem>
					<NavItem className="d-flex align-items-center">
						<LanguageSelect/>
					</NavItem>
					{me ?
						<NavItem className="d-flex align-items-center pb-2 pb-lg-0">
							<LoggedInIcon />
						</NavItem>
						:
						<>
							<NavItem className="d-flex align-items-center">
								<NavLink className={fg_css_class} href={LL("/login")}><T>Log&nbsp;in</T></NavLink>
							</NavItem>
							<NavsNotLoggedIn />
						</>
					}
				</Nav>
			</Collapse>
		</Navbar>
		<div className="d-none d-md-block">
			{ currentSelectedOrganizer && <OrganizerNavPart me={me} /> }
		</div>
		</>
	);
}

const ZevioNav = () => {
	return (
		<ZevioNavInner className="min_vh100 min_vw100" />
	);
}

export default ZevioNav;
