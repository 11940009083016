import axios from 'axios';
import { createHash } from './c/ReusableElems';

let ZEVIO_API_URL = 'https://xqn0kv7ak2.execute-api.eu-north-1.amazonaws.com/devel/'; // Should switch depending on build
let ZEVIO_CONTEXT = 'development';

if (window.location.hostname === "zevio.com") {
	ZEVIO_API_URL = 'https://api.zevio.com/';
	ZEVIO_CONTEXT = 'production';
}

if (window.location.hostname.includes("devel")) {
	ZEVIO_API_URL = 'https://xqn0kv7ak2.execute-api.eu-north-1.amazonaws.com/devel/'; 
	ZEVIO_CONTEXT = 'development';
}

if (window.location.hostname.includes("marinade.devel.zevio.com")) {
	ZEVIO_API_URL = 'https://api.zevio.com/';
	ZEVIO_CONTEXT = 'production';
}

export const APIGet = (resource, params = {}) => {
	const session_id = localStorage.getItem("zevio_ipse_session_id");
	const url = ZEVIO_API_URL + resource;

	if (session_id && !params?.session_id) {
		params['session_id'] = session_id;
	}

	if (ZEVIO_CONTEXT !== "production")
		console.log([ZEVIO_API_URL, "APIGet", resource, params]);

	return axios.get(url, {params: params});
};

export const APIPost = (resource, body, headers = { 'Content-Type': 'application/json' }) => {
	const session_id = localStorage.getItem("zevio_ipse_session_id");
	const url = ZEVIO_API_URL + resource;
	if (session_id && !body.session_id) {
		if(headers['Content-Type'] === 'application/json')
			body['session_id'] = session_id;
		if(headers['Content-Type'] === 'multipart/form-data')
			body.append('session_id', session_id);
	}
	if (ZEVIO_CONTEXT !== "production")
		console.log([ZEVIO_API_URL, "APIPost", resource, body]);

	return axios.post(url, body, {headers: headers});
};

export const countPlayEvent = (eventId) => {
	const body = {'token': createHash(16), 'event_id': eventId, 'count': 1};
	if(ZEVIO_CONTEXT !== 'production') {
		console.log("In production I would've counted 1 play for event ID = " + eventId + " with token = '" + body.token + "'");
	} else {
		axios.post("https://6h2w1ebej9.execute-api.eu-north-1.amazonaws.com/production/zevioPressedPlayEvent", body, {headers: { 'Content-Type': 'application/json' }});
	}
}
