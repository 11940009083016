import React from "react";
import ZevioNav from "../c/ZevioNav";
import ExploreContent from "../c/ExploreContent";
import Footer from "../c/Footer";

export const Explore = () => {
	return (
		<>
			<ZevioNav />
			<ExploreContent />
			<Footer />
		</>
	);
}
export default Explore;
