import React, { useState } from "react";
import { APIPost } from "../../API";
import { T, TraksConsumer } from "../../traks";
import { url_lang } from "../../languages";
import { Spinner } from "reactstrap";

const Table = ({ orders }) => (
	<table className="table table-striped" style={{height:"1px"}}>
		<TableHead />
		<TableBody {...{ orders }} />
	</table>
);

const TableHead = () => {
	return (
		<thead>
			<tr style={{ borderTop: "1px solid rgba(0, 0, 0, 0.10)" }}>
				<th scope="col">
					<T>Date</T>
				</th>
				<th scope="col">
					<T>Placed by</T>
				</th>
				<th scope="col">
					<T>Grand total</T>
				</th>
				<th scope="col">
					<T>Status</T>
				</th>
			</tr>
		</thead>
	);
};

const TableBody = ({ orders }) => {
	return (
		<tbody>
			{orders.map((order, idx) => (
				<TraksConsumer key={idx} >
					<TableRow key={idx} order={order} />
				</TraksConsumer>
			))}
		</tbody>
	);
};

const CapturePaymentButton = ({ orderId, orderStatus }) => {
	const [loading, setLoading] = useState(false);
	const capturePayment = (e) => {
		setLoading(true);
		e.preventDefault();
		e.stopPropagation();
		APIPost("products", {
			action: "capture_payment_amopus",
			orderId,
		})
			.then((e) => {
				console.log("capture_payment_amopus", e);
				if (e.status === 200) {
					console.log("success!");
				} else {
					alert(
						"Something went wrong. Please try again in a moment or contact support at hello@zevio.com."
					);
				}
			})
			.finally(() => setLoading(false));
	};
	if (orderStatus !== "Authorized") {
		return null;
	}
	return (
		<button
			className="btn btn_yellow my-3"
			style={{ minWidth: 160 }}
			onClick={capturePayment}
		>
			{!loading ? <T>Capture&nbsp;payment</T> : <Spinner size="sm" />}
		</button>
	);
};

const ResendInvoiceButton = ({ order }) => {
	const [loading, setLoading] = useState(false);
	const resendInvoice = (e) => {
		setLoading(true);
		e.preventDefault();
		e.stopPropagation();
		APIPost("products", {
			action: "resend_invoice_amopus",
			...order,
		})
			.then((e) => {
				console.log("resend_invoice_amopus", e);
				if (e.status === 200) {
					console.log("success!");
				} else {
					alert(
						"Something went wrong. Please try again in a moment or contact support at hello@zevio.com."
					);
				}
			})
			.finally(() => setLoading(false));
	};
	return (
		<button
			className="btn btn_green w-100"
			style={{ minWidth: 160 }}
			onClick={resendInvoice}
		>
			{!loading ? <T>Re-send invoice</T> : <Spinner size="sm" />}
		</button>
	);
};

const TableRow = ({ order, render_static }) => {
	const [expanded, setExpanded] = useState(false);
	const formatter = new Intl.NumberFormat(url_lang(), {
		style: "currency",
		currency: order.details.currency,
	});
	const date = new Intl.DateTimeFormat(url_lang()).format(
		new Date(order.createdTs)
	);
	return (
		<tr
			id={order.orderId}
			className="cursor-pointer h-100"
			onClick={() => setExpanded((p) => !p)}
		>
			{!expanded ? (
				<>
					<td>{date}</td>
					<td>
						{order.details.billingaddress.firstname}{" "}
						{order.details.billingaddress.lastname}
					</td>
					<td>{formatter.format(order.details.amount / 100)}</td>
					<td>{order.orderStatus}</td>
				</>
			) : (
				<>
					<td>
						<Address
							address={order.details.billingaddress}
							title={render_static(<T>Billing address</T>)}
						/>
					</td>
					<td>
						<Address
							address={order.details.shippingaddress}
							title={render_static(<T>Shipping address</T>)}
						/>
					</td>
					<td colSpan={1}>
						<span className="font_weight_600">
							<T>Items</T>
						</span>
						{order.details.lines.map((item, idx) => (
							<TraksConsumer key={`order-item-${idx}`}>
								<Item
									key={`order-item-${idx}`}
									item={item}
									formatter={formatter}
									isLastItem={idx === order.details.lines.length - 1}
								/>
							</TraksConsumer>
						))}
					</td>
					<td style={{height:"1px", width:"1px"}}>
						<div className="d-flex flex-column h-100">
							{order.orderStatus}
							<CapturePaymentButton
								orderId={order.orderId}
								orderStatus={order.orderStatus}
							/>
							<ResendInvoiceButton order={order} />
							<div className="mt-auto font_11px grey_frontpage text-end" >id:&nbsp;{order.orderId}</div>
						</div>
					</td>
				</>
			)}
		</tr>
	);
};

const Item = ({ item, formatter, isLastItem, render_static }) => {
	return (
		<>
		<p>
			<i>
				<ConditionalSpan value={item.description} />
			</i>
			<ConditionalSpan
				value={item.quantity}
				prefix={render_static(<T>Units:&nbsp;</T>)}
				postfix={<span>{item.unit}</span>}
			/>
			<ConditionalSpan
				value={formatter.format(item.unitprice / 100)}
				prefix={render_static(<T>Unit net:&nbsp;</T>)}
			/>
			<ConditionalSpan
				value={formatter.format(item.unitpriceinclvat / 100)}
				prefix={render_static(<T>Unit gross:&nbsp;</T>)}
			/>
			<ConditionalSpan
				value={item.vatrate}
				prefix={<span>{item.vattype}: </span>}
				postfix={"%"}
			/>
			
		</p>
		{!isLastItem && <hr />}
		</>
	);
};

const Address = ({ address, title }) => {
	const [copied, setCopied] = useState(false)
	const hasValues = Object.values(address).reduce((p, c) => p + c, "");
	if (!hasValues) return null;
	const copyToClipboard = (e) => {
		const addStr = [
			`${address.firstname} ${address.lastname}`,
			address.street,
			`${address.zip} ${address.city}`,
			address.state,
			address.country,
		].reduce((prev, curr) => (curr ? `${prev}\n${curr}` : prev), "");
		navigator.clipboard.writeText(addStr);
		e.preventDefault();
		e.stopPropagation();
		setCopied(true)
		setTimeout(()=> setCopied(false), 5000)
	};
	return (
		<div>
			<span className="font_weight_600">
				{title}
				<button className={`mx-2 btn btn_no_highlight ${copied?"text-success":""}`}  style={{minWidth:200}} onClick={copyToClipboard}>
					<u>
						{copied?<T>Copied to clipboard</T>:<T>Copy to clipboard</T>}
					</u>
				</button>
			</span>
			<p>
				<ConditionalSpan value={address.att} prefix={"Att.: "} />
				<ConditionalSpan value={`${address.firstname} ${address.lastname}`} />
				<ConditionalSpan value={address.street} />
				<ConditionalSpan value={`${address.zip} ${address.city}`} />
				<ConditionalSpan value={address.state} />
				<ConditionalSpan value={address.country} />
				<br />
				<ConditionalSpan
					value={address.homephonenumber}
					prefix={
						<span>
							<T>Phone</T>:&nbsp;
						</span>
					}
				/>
				<ConditionalSpan
					value={address.workphonenumber}
					prefix={
						<span>
							<T>Phone #2</T>:&nbsp;
						</span>
					}
				/>
				<ConditionalSpan
					value={address.email}
					prefix={
						<span>
							<T>E-mail</T>:&nbsp;
						</span>
					}
				/>
			</p>
		</div>
	);
};

const ConditionalSpan = ({ value, prefix, postfix }) =>
	!!value && (
		<span>
			{prefix}
			{value}
			{postfix}
			<br />
		</span>
	);

export default Table;
