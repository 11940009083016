import { createContext, useCallback, useEffect, useState } from "react";
import get_country_info, { amopus_config } from "./util";
import { url_lang } from "../../languages";
import I18N from "../../I18N";
import { APIPost } from "../../API";

export const createAmopusOrder = (order) => {
	return APIPost("products", {
		action: "create_order_amopus",
		product_id: order.lines[0].id,
		vatrate: order.lines[0].vatrate,
		vattype: order.lines[0].vattype,
		quantity: order.lines[0].quantity,
		description: order.lines[0].description,
		producturl: order.lines[0].producturl,
		...order,
	})
		.then((r) => {
			if (r?.data?.orderId) {
				let msg = r.data.orderId;
				return msg;
			} else {
				console.log("error:", r);
			}
		})
		.catch((e) => {
			console.log("API error:", e);
		});
};

export const sendConfirmationEmail = (orderId, templateKey) => {
	const { API_KEY, BASE_URL } = amopus_config();
	const requestInit = {
		method: "POST",
		mode: "cors",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: `basic ${API_KEY}`,
		},
		body: JSON.stringify({ templateKey }),
	};
	return fetch(
		`${BASE_URL}/order/${orderId}/emailreceipt?companyId=Zevio`,
		requestInit
	)
		.then((r) => {
			if (r.ok) {
				let msg = r.json();
				console.log("send email receipt", { msg });
				return msg;
			} else {
				if (r.json) {
					try {
						r.json().then((json) => console.log(json.message));
					} catch (e) {
						console.log(e);
					}
				}

				if (r.status === 401) {
					console.log("unauthorized");
				}
			}
		})
		.catch((e) => {
			console.log("Amopus API error:", e);
		});
};

/*
const orderTemplate = {
	currency: "DKK",
	instantcaptureamount: 0,
	metadata:
		"string with stuff that could be useful for e.g. selecting template in webhook",
	shippingaddress: {
		att: "",
		firstname: "",
		lastname: "",
		homephonenumber: "",
		workphonenumber: "",
		email: "",
		street: "",
		zip: "",
		city: "",
		state: "",
		country: "",
	},
	billingaddress: {
		att: "",
		firstname: "",
		lastname: "",
		homephonenumber: "",
		workphonenumber: "",
		email: "",
		street: "",
		zip: "",
		city: "",
		state: "",
		country: "",
	},
	lines: [
		{
			id: "",
			linenumber: "",
			description: "",
			text: "",
			quantity: 1,
			unit: "",
			unitprice: 0,
			unitpriceinclvat: 0,
			vattype: "MOMS",
			vatrate: 25,
		},
	],
};
*/

/** Given price including vat */
export const calculateVAT = (price, vat) => {
	let p = 1 + vat.rate / 100;
	let exclVat = Math.floor(price / p);
	let vatAmount = Math.floor(price * (1 - 1 / p));
	let diff = price - (exclVat + vatAmount);
	vatAmount += diff;
	return { exclVat, vatAmount };
};

/** Maps the data of a Zevio-product into a format suitable for Amopus */
export const useOrder = (productData) => {
	if (productData.producturl)
		productData.metadata = `{"lang":"${url_lang().lang}", "url_template":true}`;
	else productData.metadata = `{"lang":"${url_lang().lang}"}`;
	let { vat, currency } = get_country_info();
	currency = currency.toLocaleUpperCase();
	const showCurrency = {
		format: (v) =>
			I18N.floatval_unit(v, 2, url_lang().currency.toLocaleUpperCase()),
	};

	// We assume that prices from the backend are incl. vat
	const amount = Math.floor(
		(productData.price[currency.toLowerCase()] || productData.price.dkk) * 100
	);
	// If the product is shippable we don't charge immediately, otherwise, the entire amount is captured.
	const instantcaptureamount = productData.shippable
		? 0
		: Math.floor(
				(productData.price[currency.toLowerCase()] || productData.price.dkk) *
					100
		  );

	const [shippingAddress, setShippingAddress] = useState({});
	const [billingAddress, setBillingAddress] = useState({});

	// This should keep the orderId we get from amopus s.t. we can show it to the user.
	const [orderId, setOrderId] = useState();

	const [quantity, setQuantity] = useState(1);
	const createOrder = () =>
		createAmopusOrder(getOrderDetails()).then((v) => setOrderId(v));
	const getOrderDetails = useCallback(() => {
		//** Given price including vat returns price excluding vat */
		const { exclVat } = calculateVAT(amount, vat);
		let shippingFee = !isNaN(parseInt(productData.shippingFee))
			? parseInt(productData.shippingFee) * 100
			: 0;
		let lines = [
			{
				id: `${productData.Id}`,
				linenumber: "1",
				description: productData.headline,
				text: productData.description,
				quantity,
				unit: "pcs.",
				unitprice: exclVat,
				unitpriceinclvat: amount,
				vattype: vat.type,
				vatrate: vat.rate,
				producturl: productData.producturl,
			},
		];
		if (shippingFee) {
			const { exclVat: exclVatShipping } = calculateVAT(shippingFee, vat);
			lines.push({
				id: "shipping",
				description: "Shipping",
				text: "Delivery",
				quantity: 1,
				unit: "pcs.",
				unitprice: exclVatShipping,
				unitpriceinclvat: shippingFee,
				vattype: vat.type,
				vatrate: vat.rate,
			});
		}
		return {
			currency,
			amount,
			instantcaptureamount,
			metadata: productData.metadata,
			shippingaddress: shippingAddress,
			billingaddress: billingAddress,
			lines,
		};
	}, [
		amount,
		vat,
		productData.shippingFee,
		productData.Id,
		productData.headline,
		productData.description,
		productData.producturl,
		productData.metadata,
		quantity,
		currency,
		instantcaptureamount,
		shippingAddress,
		billingAddress,
	]);

	const [orderDetails, setOrderDetails] = useState(getOrderDetails());

	useEffect(() => {
		setOrderDetails(getOrderDetails());
	}, [shippingAddress, billingAddress, getOrderDetails]);

	return {
		orderDetails,
		orderId,
		createOrder,
		quantity,
		setQuantity,
		shippingAddress,
		setShippingAddress,
		billingAddress,
		setBillingAddress,
		vat,
		currency,
		showCurrency,
		productData,
	};
};

const OrderContext = createContext();

export default OrderContext;
