import React, { useCallback, useMemo, useState } from "react";
import { GreenButton, RedButton } from '../c/ReusableElems';
import { useDropzone } from 'react-dropzone'
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { T } from '../traks';

const UploadSVGicon = (props) => {
	let stroke = "#29b869";
	if (props.stroke) stroke = props.stroke;
	return (
		<svg width="42" height="34" viewBox="0 0 42 34" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M32.557 14.4533C32.6142 13.9707 32.7 13.4987 32.7 13C32.7 6.37333 27.461 1 21 1C14.8666 1 9.8954 5.856 9.3962 12.0133C4.8696 13.168 1.5 17.3333 1.5 22.3333C1.5 28.224 6.1566 33 11.9 33H31.4C36.4258 33 40.5 28.8213 40.5 23.6667C40.5 18.9173 37.029 15.0427 32.557 14.4533Z" stroke={stroke} strokeWidth="1.46667" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M12.5 18L19 11L25.5 18" stroke={stroke} strokeWidth="1.46667" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M19 12V28" stroke={stroke} strokeWidth="1.46667" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
	);
}

const dropzoneStyle = {
	height: '238px',
	background: 'rgba(0, 0, 255, 0.1)',
	borderRadius: '10px',
	border: '2px dashed rgba(98, 54, 255, 0.35)',
	color: 'rgba(98, 54, 255, 0.65)'
};

const dropzoneFocusedStyle = {
	border: '2px solid rgba(98, 54, 255, 0.35)',
	background: 'rgba(0, 0, 255, 0.2)'
};

const DropCropper = (props) => {
	let minWidth = 500, minHeight = 500, maxWidth, maxHeight;
	if (props.minWidth) minWidth = parseInt(props.minWidth);
	if (props.minHeight) minHeight = parseInt(props.minHeight);
	if (props.maxWidth) maxWidth = parseInt(props.maxWidth);
	if (props.maxHeight) maxHeight = parseInt(props.maxHeight);
	const [targetImg, setTargetImg] = useState();
	const onDrop = useCallback(acceptedFiles => {
		acceptedFiles.forEach((file) => {
			const reader = new FileReader();

			reader.onabort = () => console.log('file reading was aborted')
			reader.onerror = () => console.log('file reading has failed')
			reader.onloadend = () => {
				const binaryStr = reader.result;
				const imgBlob = new Blob([binaryStr], {type: file.type});
				const imgURL = URL.createObjectURL(imgBlob);
				const tmpImg = new Image();
				tmpImg.onload = (e) => setTargetImg(tmpImg);
				tmpImg.src = imgURL;
			}
			reader.readAsArrayBuffer(file);
		});
	}, []);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({onDrop});

	const style = useMemo(() => ({
			...dropzoneStyle,
			...(isDragActive ? dropzoneFocusedStyle : {}),
		}), [
			isDragActive,
		]
	);
	const restartUpload = () => setTargetImg(null);

	let errorElem = null;
	if(targetImg && targetImg.width >= minWidth && targetImg.height >= minHeight) {
		return <MyCropper maxHeight={maxHeight} maxWidth={maxWidth} onImageCropped={props.onImageCropped} deleteImageFunction={restartUpload} doneCropping={props.doneCropping} cropAspectRatio={minWidth/minHeight} image={targetImg} />
	} else if(targetImg && targetImg.src) {
		errorElem = <div className="red_text w-100 text-center font_13px"><T>Minimum dimensions is {minWidth}px &times; {minHeight}px (you uploaded {targetImg.width}px &times; {targetImg.height}px)</T></div>
	}

	return (
		<React.Fragment>
		{errorElem}
		<div {...getRootProps({style})}>
			<input {...getInputProps()} />
			<div className="w-100 text-center">
				<div className="pt-4 pb-1 px-3">
					{ isDragActive ? <T>Release image to upload</T> : <T>Drag image (PNG or JPEG) to upload</T> }
				</div>
				<div className="pt-5">
					<UploadSVGicon />
				</div>
			</div>
		</div>
		</React.Fragment>
	);
}

const MyCropper = (props) => {
	let cropAspectRatio = 1;
	let maxW = undefined;
	let maxH = undefined;
	if (props.cropAspectRatio) cropAspectRatio = props.cropAspectRatio;
	if (props.maxWidth) maxW = props.maxWidth;
	if (props.maxHeight) maxH = props.maxHeight;
	const [crop, setCrop] = useState();
	const [image, setImage] = useState();
	const [completedCrop, setCompletedCrop] = useState();

	const onImageLoad = (e) => {
		setImage(e.currentTarget);
		const { width, height } = e.currentTarget;
		const crop = centerCrop(
			makeAspectCrop({
				unit: 'px',
				width: width * 0.80,
			},
				cropAspectRatio,
				width,
				height
			),
			width,
			height
		)

		setCrop(crop);
	}
	const getBase64Image = async () => {
		try {
			const canvas = document.createElement("canvas");
			const scaleX = image.naturalWidth / image.width;
			const scaleY = image.naturalHeight / image.height;
			if(maxW && crop.width * scaleX > maxW) {
				canvas.width = maxW;
			} else {
				canvas.width = crop.width * scaleX;
			}
			if(maxH && crop.height * scaleY > maxH) {
				canvas.height = maxH;
			} else {
				canvas.height = crop.height * scaleY;
			}
			const ctx = canvas.getContext("2d");
			ctx.drawImage(
				props.image,
				crop.x * scaleX,
				crop.y * scaleY,
				crop.width * scaleX,
				crop.height * scaleY,
				0,
				0,
				(crop.width * scaleX > maxW ? maxW : crop.width * scaleX),
				(crop.height * scaleY > maxH ? maxH : crop.height * scaleY)
			);
			const base64Image = canvas.toDataURL('image/webp', 1);

			return base64Image;

		} catch (e) {
			console.log("Wir haben eine Canvas problem gefunden!");
		}
	}

	const getCroppedImg = () => {
		getBase64Image().then( (b64) => {
			setCompletedCrop(b64);
			props.doneCropping(b64);
		});
	};

	const cropChanged = (c) => {
		setCrop(c);
		if(props.onImageCropped) {
			getBase64Image().then( (b64) => {
				props.onImageCropped(b64);
			});
		}
	}

	if(completedCrop) {
		return (
			<React.Fragment>
				<img alt="img2crop" src={completedCrop} className='w-100' onLoad={onImageLoad} style={{transform: 'scale(1)'}}/>
				<div className="w-100 text-center pt-3">
					<RedButton onClick={props.deleteImageFunction}>Delete</RedButton>
				</div>
			</React.Fragment>
		);
	} else {
		return (
			<React.Fragment>
				<ReactCrop crop={crop} onChange={(c) => cropChanged(c)} aspect={cropAspectRatio} >
					<img alt="img2crop" src={props.image.src} className='w-100' onLoad={onImageLoad} style={{transform: 'scale(1)'}}/>
				</ReactCrop>
				<div className="w-100 text-center pt-3">
					<GreenButton className="me-3" onClick={getCroppedImg}>Done</GreenButton>
					<RedButton onClick={props.deleteImageFunction}>Cancel</RedButton>
				</div>
			</React.Fragment>
		);
       }
}

export default DropCropper;
