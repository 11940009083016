import React, { useState, useEffect, useRef, useContext } from "react";
import { Col, Container, Row, Spinner, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal } from 'reactstrap';
import { RedButton, AlertReusable } from '../c/ReusableElems';
import Footer from '../c/Footer';
import { MeContext } from '../MeContext';
import ZevioNav from '../c/ZevioNav';
import { LL, Link } from '../Routes';
import { T } from '../traks';
import { APIPost } from "../API";
import { useNavigate } from "react-router-dom";

const NotOrganizer = () => {
	return (
		<Container className="bg_white promotion_box p-5 my-5">
			<div>
				<h5><T>You need to <a href={LL("/login")}>Login</a> to create an organiser profile</T></h5>
			</div>
			<div>
				<T>Why would I want to create an organiser profile? Because it's a damn
					good idea to do! It'll make you rich, famous and happy. Not to mention all
					the bad and unfortunate things that will happen to you, if you don't.</T>
			</div>
			<div className="pt-5">
				<T>You can check out why it's a great idea to make an organiser
					page <a href={LL("/organizers")}>here</a>.</T>
			</div>
		</Container>
	);
}

const ProductsInner = () => {
	const { me } = useContext(MeContext);
	if (me && me.currentSelectedOrganizer) {
		return (
			<ProductsContent />
		);
	} else {
		return (
			<NotOrganizer />
		);
	}
}

const ProductsContent = () => {

	const { me } = useContext(MeContext);
	const [productsData, setProductsData] = useState(['']);
	const [activateAlert, setActivateAlert] = useState(0);
	const [alertText, setAlertText] = useState('');

	const GetProductsData = () => {
		APIPost('products', { 'action': 'get_products', 'organizer_ids': me.organizers })
			.then((res) => {
				let products = {};
				for (const product of res.data.products) {
					products[product.productId] = product;
				}
				const values = Object.values(products);
				setProductsData(values);
		}	)
			.catch((error) => {
				console.log(['Error getting products:', error]);
		}	)
	};

	useEffect(() => {
		GetProductsData();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	const [sortType, setSortType] = useState('');

	useEffect(() => {
		const sortArray = type => {
		const types = {
			active: 'active',
			taxable: 'taxable',
			shippable: 'shippable',
		};
		const sortProperty = types[type];
			const sorted = [...productsData].sort((a, b) => b[sortProperty] - a[sortProperty]);
			setProductsData(sorted);
		};
		sortArray(sortType);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sortType]);
	


	const ModalDelete = ({ productName, productId, onClick }) => {
		const deleteProduct = (e) => {
			if (deleteText === "delete") {
				APIPost('products', { 'action': 'del_products', 'product_ids': [productId] }).then((response) => {
					if (response.status === 200) {
						const productsFiltered = productsData.filter((item) => item.productId !== productId);
						setProductsData(productsFiltered);
						setAlertText('Product deleted')
						setActivateAlert(activateAlert + 1);
						onClick();
					}
				}).catch((error) => {
					console.log(['Error:', error])
				});
			}
		};

		const [deleteText, setDeleteText] = useState('');
		const submit_button_disabled = !(deleteText === ('delete'))

		const inputElement = useRef(null);
		useEffect(() => {
			if (inputElement.current) {
				inputElement.current.focus();
			}
		}, []);

		return (
			<div className="p-3">
				<div>
					<img onClick={onClick} className="btn" style={{ float: 'right' }} src="https://static.zevio.com/gfx/ico_close.svg" alt='icoClose' />
				</div>
				<div className="ps-5 pt-3 image-container">
					<div className="icon_modal_delete">
						<img src="https://static.zevio.com/gfx/ico_deleteprofile.svg" alt='icoDelProf' />
					</div>
				</div>
				<div className="pt-3">
					<span><T>Are you sure you want to delete product:</T></span> <div className="font_weight_500"> {productName}</div>
				</div>
				<div className="pt-4 pb-2">
					<span><T>Type: </T><span style={{ fontWeight: "900" }}>DELETE</span> <T>to delete this product</T></span>
				</div>
				<div>
					<form onSubmit={(e) => e.preventDefault() + deleteProduct(e)}>
						<input
							ref={inputElement}
							name="Delete"
							id="Delete"
							className="zevio_input w-450 w-100"
							onChange={(e) => setDeleteText(e.target.value.toLowerCase())}
						/>
					</form>
				</div>
				<div className='pt-3 pb-4'>
					<RedButton
						style={{ width: '100%' }}
						disabled={submit_button_disabled}
						onClick={(e) => deleteProduct(e) }><T>Delete product</T>
					</RedButton>
				</div>
			</div >
		);
	}

	const Products = (props) => {
		const navigate = useNavigate();
		const productId = props.productsData.productId;
		const [dropdownOpen, setDropdownOpen] = useState(false);
		const [modalDelete, setModalDelete] = useState(false);
		const toggleDelete = () => setModalDelete(!modalDelete);
		const activeCurrency = props.productsData.currency ? props.productsData.currency.toLowerCase() : 'dkk';
		const hideEventText = props.productsData.active ? <T>Hide product</T> : <T>Set active</T>;

		const ChangeActiveStatus = () => {
			let hideVal = !props.productsData.active;
			let activeCurrency = props.productsData.currency
			let activePrice = props.productsData.price[activeCurrency.toLowerCase()]
			const postObj = {
				'action': 'update_product',
				'product': {
					'Id': productId,
					'active': hideVal,
					'price': activePrice,
					'currency': activeCurrency,
				},
			};
			APIPost('products', postObj).then((response) => {
				if (response.status === 200) {
					setAlertText('Product visibility changed')
					setActivateAlert(activateAlert + 1);
					GetProductsData();
				}
			});
		}

		if (productsData) {
			
			return (
				<Container className="w-100 d-flex">
					<Link className="link_nostyle w-100" to={{
						pathname: "/editproduct/" + (props.productsData.productId)
					}}>
						<Col className="py-2 d-flex flex-col justify-content-start align-items-center">
							<div className="col-1">{props.productsData.imageURL ? <img className="productevent_thumb" src={props.productsData.imageURL} alt='thumbimg' /> : <div className="ps-5" />}</div>
							<div className="col-5">{props.productsData.headline}</div>
							<div className="col-2">{props.productsData.ownerName}</div>
							<div className="col-2 ps-4">{props.productsData.price ? JSON.stringify(props.productsData.price[activeCurrency]) : 'n/a'} {props.productsData.currency}</div>
							<span className="ps-5 col-1">{props.productsData.active ? <span className="broadcast_badge badgecolor_approved"><T>Active</T></span> : <span className="broadcast_badge badgecolor_hidden"><T>InActive</T></span>}</span>
						</Col>
					</Link>
					<Col className="d-flex flex-col justify-content-start align-items-center">
						<Dropdown
							isOpen={dropdownOpen}
							toggle={() => setDropdownOpen(!dropdownOpen)}
						>
							<DropdownToggle className="btn_editevent zevio_input">
								<span><img src="https://static.zevio.com/gfx/ico_expandable.svg" alt='icoexp' /></span>
							</DropdownToggle>
							<DropdownMenu className="text-center">
								<DropdownItem onClick={() => toggleDelete(true)}>
									<Modal toggle={toggleDelete} centered isOpen={modalDelete}>
										<ModalDelete onClick={toggleDelete} productName={props.productsData.headline} productId={props.productsData.productId} />
									</Modal>
									<span><T>Delete product</T></span>
								</DropdownItem>
								<DropdownItem divider />
								<DropdownItem onClick={() => ChangeActiveStatus()}>
									<span>{hideEventText}</span>
								</DropdownItem>
								<DropdownItem onClick={() => navigate(`${LL("/orderhub")}?productId=${props.productsData.productId}`)}>
									<span><T>View orders</T></span>
								</DropdownItem>
								
							</DropdownMenu>
					</Dropdown>
					</Col>
				</Container>
			);
		} else {
			return <div className='text-center py-4'><T>Loading data...</T><Spinner className="p-2" color="success" /></div>;
		}
	}

	if (productsData) {
		return (
			<Container className="pb-5">
				<div className="pt-4 pb-3 d-flex flex-col justify-content-between">
					<div>
						<h3><T>Products</T></h3>
					</div>
				</div>
				<AlertReusable
					activateAlert={activateAlert}
					color="success"
					text={alertText}
					delay="1800"
				/>
				<Container fluid className="bg_white promotion_box p-0 overflow_visible pb-2">
					<Row>
						<div className="bottom_border_promo_listing w-100 font_15px">
							<div className="py-2 d-flex flex-col justify-content-start">
								<div className="font_weight_500 ps-3"><T>Products</T><span className="ps-1 login_or_seperator">{productsData.length}</span></div>
								<div className="col-5 ps-5">Title</div>
								<div className="col-2">Owner</div>
								<div className="col-2">Price</div>
								<div className="col-0 ps-3"><T>Status</T></div>
								<select className="btn_editevent zevio_input ms-4 me-0 ps-1 pe-0" onChange={(e) => setSortType(e.target.value)}>
									<option value="" selected disabled hidden>Sort</option>
									<option value="active">Status</option>
									<option value="taxable">Taxable</option>
									<option value="shippable">Shippable</option>
								</select>
							</div>
						</div>
						<div className="ps-4 bottom_border_promo_listing">
							<Row>
								{productsData.map((v, i) => (
									<Row key={i} xs={12} md={4} lg={3} className="pe-0">
										<Products rowcounter={i} productsData={v} />
									</Row>
								))}
							</Row>
						</div>
					</Row>
				</Container>
			</Container>
		);
	} else {
		return <div className='text-center py-4'><T>Loading data...</T><Spinner className="p-2" color="success" /></div>;
	}
}

const Products = () => {
	return (<>
			<ZevioNav />
			<ProductsInner />
			<Footer />
		</>
	);
}
export default Products;
