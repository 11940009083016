import React from "react";
import { Container } from "reactstrap";

const SVGRectangle = ({ width, height }) => {
	return (
		<svg
			viewBox={"0 0 " + width + " " + height}
			width={width + "px"}
			height={height + "px"}
			xmlns="http://www.w3.org/2000/svg"
		>
			<g fillOpacity="0.2">
				<rect width={width} height={height} />
			</g>
		</svg>
	);
};


const ImagePlaceHolder = () => {
	return (
		<Container className="px-0 pt-0 pt-lg-3">
			<div className="position-relative event_cls min_w100">
				<div className="event_profile">
					<div className="d-none d-md-block">
						<SVGRectangle width="1320" height="741" />
					</div>
					<div className="d-md-none">
						<SVGRectangle width="414" height="232" />
					</div>
				</div>
			</div>
		</Container>
	);
};

export default ImagePlaceHolder