import React from 'react'
import useCountdown from '@bradgarropy/use-countdown'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { T } from '../traks'
dayjs.extend(duration)

export default function Countdown({ dateVal }) {

	const countdown = useCountdown({
		seconds: dayjs(dateVal * 1000).diff(dayjs(), 'seconds'),
	})

	const duration = dayjs.duration(countdown.minutes, 'minutes')

	const seconds = countdown.seconds
	const minutes = duration.minutes()
	const hours = duration.hours()
	const days = Math.floor(duration.asDays())

	return (
		<div className="d-flex flex-row">
			<div className="d-flex flex-column justify-content-center pe-2 pe-lg-3">
				<div className="timer_digit pb-lg-2">{days}</div><div><T>Days</T></div>
			</div>
			<div className="d-flex flex-column justify-content-center pe-2 pe-lg-3">
				<div className="timer_digit pb-lg-2">{hours}</div><div><T>Hours</T></div>
			</div>
			<div className="d-flex flex-column justify-content-center pe-2 pe-lg-3">
				<div className="timer_digit pb-lg-2">{minutes}</div><div><T>Minutes</T></div>
			</div>
			<div className="d-flex flex-column justify-content-center pe-2 pe-lg-3">
				<div className="timer_digit pb-lg-2">{seconds}</div><div><T>Seconds</T></div>
			</div>
		</div>
	)
}
