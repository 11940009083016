import React, { useState, useEffect, useRef, useContext } from "react";
import { Col, Container, Row, Spinner, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal } from 'reactstrap';
import { EventGuideCollapsibles, RedButton, AlertReusable } from '../c/ReusableElems';
import Footer from '../c/Footer';
import { MeContext } from '../MeContext';
import ZevioNav from '../c/ZevioNav';
import { LL, Link } from '../Routes';
import { T } from '../traks';
import { APIPost, APIGet } from "../API";
import { ModalBroadcastInfo } from "../p/EditEvent";

const NotOrganizer = () => {
	return (
		<Container className="bg_white promotion_box p-5 my-5">
			<div>
				<h5><T>You need to <a href={LL("/login")}>Login</a> to create an organiser profile</T></h5>
			</div>
			<div>
				<T>Why would I want to create an organiser profile? Because it's a damn
					good idea to do! It'll make you rich, famous and happy. Not to mention all
					the bad and unfortunate things that will happen to you, if you don't.</T>
			</div>
			<div className="pt-5">
				<T>You can check out why it's a great idea to make an organiser
					page <a href={LL("/organizers")}>here</a>.</T>
			</div>
		</Container>
	);
}

const BroadcastsInner = () => {
	const { me } = useContext(MeContext);
	if (me && me.currentSelectedOrganizer) {
		return (
			<BroadcastsContent />
		);
	} else {
		return (
			<NotOrganizer />
		);
	}
}

const ModalDelete = ({ deleteEvent, eventName, eventId, onClick }) => {
	const [deleteText, setDeleteText] = useState('');
	const submit_button_disabled = !(deleteText === ('delete'))

	const inputElement = useRef(null);
	useEffect(() => {
		if (inputElement.current) {
			inputElement.current.focus();
		}
	}, []);

	return (
		<div className="p-3">
			<div>
				<img onClick={onClick} className="btn" style={{ float: 'right' }} src="https://static.zevio.com/gfx/ico_close.svg" alt='icoClose' />
			</div>
			<div className="ps-5 pt-3 image-container">
				<div className="icon_modal_delete">
					<img src="https://static.zevio.com/gfx/ico_deleteprofile.svg" alt='icoDelProf' />
				</div>
			</div>
			<div className="pt-3">
				<span><T>Are you sure you want to delete event:</T></span> <div className="font_weight_500"> {eventName} </div>
			</div>
			<div className="pt-4 pb-2">
				<span><T>Type: </T><span style={{ fontWeight: "900" }}>DELETE</span><T>to delete this event</T></span>
			</div>
			<div>
				<form onSubmit={(e) => e.preventDefault()}>
					<input
						ref={inputElement}
						name="Delete"
						id="Delete"
						className="zevio_input w-450 w-100"
						onChange={(e) => setDeleteText(e.target.value.toLowerCase())}
					/>
				</form>
			</div>
			<div className='pt-3 pb-4'>
				<RedButton
					className="w-100"
					disabled={submit_button_disabled}
					onClick={() => deleteEvent(deleteText, eventId, onClick)}><T>Delete event</T>
				</RedButton>
			</div>
		</div >
	);
}

const VisibilityBadge = ({isHidden}) => {
	if (isHidden) {
		return <span className="broadcast_badge badgecolor_hidden"><T>Hidden</T></span>;
	}
	else {
		return <span className="broadcast_badge badgecolor_approved"><T>Visible</T></span>;
	}
}


const EventEntryDropdown = (props) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const hideEventText = props.eventData.IsHidden ? <T>Make visible</T> : <T>Hide event</T>;
	const goLiveLinkVisible = !!(props.eventData.Approved && props.eventData.Active);

	return (
		<Dropdown
			isOpen={dropdownOpen}
			toggle={() => setDropdownOpen(!dropdownOpen)}
		>
			<DropdownToggle className="btn_editevent zevio_input">
				<span><img src="https://static.zevio.com/gfx/ico_expandable.svg" alt='icoexp' /></span>
			</DropdownToggle>
			<DropdownMenu>
				{goLiveLinkVisible &&
				<DropdownItem>
					<a className="link_nostyle" href={LL("/golive/") + props.eventData.UniqueHash}><T>Go live from browser</T></a>
				</DropdownItem>
				}
				<DropdownItem onClick={() => props.toggleBroadcast(true)}>
					<Modal toggle={props.toggleBroadcast} centered isOpen={props.modalBroadcast}>
						<ModalBroadcastInfoOuter onClick={props.toggleBroadcast} eventsData={props.eventData} />
					</Modal>
					<span><T>Stream info</T></span>
				</DropdownItem>
				<DropdownItem onClick={() => props.toggleDelete(true)}>
					<Modal toggle={props.toggleDelete} centered isOpen={props.modalDelete}>
						<ModalDelete deleteEvent={props.deleteEvent} onClick={props.toggleDelete} eventName={props.eventData.Title} eventId={props.eventData.Id} />
					</Modal>
					<span><T>Delete event</T></span>
				</DropdownItem>
				<DropdownItem divider />
				<DropdownItem onClick={() => props.hideEvent(props.eventData)}>
					<span>{hideEventText}</span><div><VisibilityBadge isHidden={props.eventData.IsHidden} /></div>
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
}

const ModalBroadcastInfoOuter = ({ eventsData, onClick }) => {
	const [eventData, setEventData] = useState(eventsData);
	useEffect(() => {
		if(eventsData && (!eventsData.BroadcastURL || !eventsData.BroadcastKey))
			APIGet('search', { 'unique_hash': eventsData.UniqueHash })
				.then((res) => {
					setEventData(res.data.body[0]);
				})
				.catch((error) => {
					console.log(['Error:', error])
				})
	}, [eventsData, eventsData.UniqueHash, eventsData.BroadcastURL, eventsData.BroadcastKey]);

	if (eventData && eventData.BroadcastURL && eventData.BroadcastKey) {
		const urlPort = window.location.port;
		const liveURL = window.location.protocol + "//" + window.location.hostname + (urlPort ? ":" + urlPort : "") + LL("/live/") + eventData.UniqueHash;
		return <ModalBroadcastInfo eventData={eventData} liveURL={liveURL} onClick={onClick} />
	} else {
		return <div className='text-center py-4'>No broadcast info available</div>;
	}
}

const BroadcastsContent = () => {
	const { me } = useContext(MeContext);
	const [eventPerPage, setEventPerPage] = useState(10);
	const loadMoreUpcoming = () => setUpcomingPage(upcomingPage + 1);
	const loadPrevUpcoming = () => setUpcomingPage(upcomingPage - 1);
	const [upcomingPage, setUpcomingPage] = useState(1);
	const [pastPage, setPastPage] = useState(1);

	const loadMorePast = () => setPastPage(pastPage + 1);
	const loadPrevPast = () => setPastPage(pastPage - 1);
	const [activateAlert, setActivateAlert] = useState(0);
	const [alertText, setAlertText] = useState('');

	const date = new Intl.DateTimeFormat('en-US', { hour12: false, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format();

	const [upcomingEventsData, setUpcomingEventsData] = useState();
	const [upcomingCount, setUpcomingCount] = useState();
	const totalUpcomingPages = Math.ceil(upcomingCount / eventPerPage);

	const paramsUpcoming = {
		'action': 'get_events',
		'organizerId': me.currentSelectedOrganizer,
		'fromDateTime': date,
		'page': upcomingPage,
		'size': eventPerPage
	};

	const [dropdownPageOpen, setDropdownPageOpen] = useState(false);

	const GetOrganizerData = () => {
		APIPost('organizer', paramsUpcoming)
			.then((res) => {
				setUpcomingEventsData(res.data.body.events);
				if (res.data.body.eventsCount) {
					setUpcomingCount(res.data.body.eventsCount);
				}
			}).catch((error) => {
				console.log('Error:', error);
		}	);
	};

	const [pastEventsData, setPastEventsData] = useState();
	const [pastLength, setPastLength] = useState();

	const hideEvent = (eventData) => {
		const newHidden = eventData.IsHidden ? 0 : 1;
		const uploadObj = {
			'action': 'update_event',
			'event': {
				'Id': eventData.Id,
				'IsHidden': newHidden,
			},
		};
		APIPost('events', uploadObj).then((response) => {
			if (response.status === 200) {
				modifyEvent({...eventData, IsHidden: newHidden});
				setAlertText('Event visibility changed')
				setActivateAlert(oaa => oaa + 1);
			}
		});
	}

	const modifyEvent = (newEvent) => {
		let newEvents = [];
		let foundIt = false;
		upcomingEventsData.forEach( (e) => {
			if(e.Id === newEvent.Id) {
				newEvents.push(newEvent);
				foundIt = true;
			} else {
				newEvents.push(e);
			}
		});
		if(foundIt) {
			setUpcomingEventsData(newEvents);
			return;
		}
		newEvents = [];
		foundIt = false;
		pastEventsData.forEach( (e) => {
			if(e.Id === newEvent.Id) {
				newEvent.IsHidden = !!newEvent.IsHidden;
				newEvents.push(newEvent);
			} else {
				newEvents.push(e);
				foundIt = true;
			}
		});
		if(foundIt) {
			setPastEventsData(newEvents);
		}
	}

	const deleteEvent = (deleteText, eventId, onClick) => {
		const uploadObj = {
			'action': 'update_event',
			'event': {'Id': eventId, 'Deleted': 1},
		};

		if (deleteText === "delete") {
			APIPost('events', uploadObj).then((response) => {
				if (response.status === 200) {
					const upcomingFiltered = upcomingEventsData.filter((item) => item.Id !== eventId);
					setUpcomingEventsData(upcomingFiltered);
					const pastFiltered = pastEventsData.filter((item) => item.Id !== eventId);
					setPastEventsData(pastFiltered);
					onClick();
					setAlertText('Event deleted')
					setActivateAlert(activateAlert + 1);
				}
			});
		}
	};


	useEffect(() => {
		GetOrganizerData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [upcomingPage, eventPerPage])

	useEffect(() => {
		APIGet('search', { 'oldevents': true, 'organizer': me.currentSelectedOrganizer, 'page': (pastPage - 1),  'limit': eventPerPage })
			.then((res) => {
				setPastEventsData(res.data.body);
				setPastLength(res.data.body.length);
		}	)
	}, [me.currentSelectedOrganizer, pastPage, eventPerPage])

	const UpcomingEvents = (props) => {
		const [modalDelete, setModalDelete] = useState(false);
		const toggleDelete = () => setModalDelete(!modalDelete);
		const [modalBroadcast, setModalBroadcast] = useState(false);
		const toggleBroadcast = () => setModalBroadcast(!modalBroadcast);

		const StatusBadge = () => {
			if (props.eventsData.Approved === 1) {
				return <span className="broadcast_badge badgecolor_approved"><T>Approved</T></span>;
			}
			else {
				return <span className="broadcast_badge badgecolor_pending"><T>Pending</T></span>;
			}
		}

		return (
			<Container className="py-2 w-100 d-flex">
				<Link className="link_nostyle w-100" to={{
					pathname: "/editevent/" + (props.eventsData.Id)
				}}>
					<Col className="py-2 d-flex flex-col justify-content-between">
						{props.eventsData.ThumbnailImage ? <img className="broadcast_event_thumb" src={props.eventsData.ThumbnailImage} alt='thumbimg' /> : <span className="broadcast_event_thumb" />}
						<div className="col-5">{props.eventsData.Title.length > 55 ? `${props.eventsData.Title.substring(0, 55)}...` : props.eventsData.Title}</div>
						<div className="col-2">{props.eventsData.SubTitle.length > 16 ? `${props.eventsData.SubTitle.substring(0, 15)}...` : props.eventsData.SubTitle}</div>
						<div className="col-1 ps-4 grey_light">{props.eventsData.Signups}</div>
						<div className="col-1 ps-4 grey_light">{props.eventsData.Date}</div>
						<div className="col-2 ps-5"><StatusBadge /></div>
					</Col>
				</Link>
				<EventEntryDropdown
					eventData={props.eventsData}
					modalDelete={modalDelete}
					modalBroadcast={modalBroadcast}
					toggleDelete={toggleDelete}
					toggleBroadcast={toggleBroadcast}
					deleteEvent={deleteEvent}
					hideEvent={hideEvent}
				/>
			</Container>
		);
	}

	const PastEvents = (props) => {

		const [modalDelete, setModalDelete] = useState(false);
		const toggleDelete = () => setModalDelete(!modalDelete);
		const [modalBroadcast, setModalBroadcast] = useState(false);
		const toggleBroadcast = () => setModalBroadcast(!modalBroadcast);

		return (
			<Container className="py-2 w-100 d-flex">
				<Link className="link_nostyle w-100"
					to={{
						pathname: "/editevent/" + (props.eventsData.Id)
					}}>
					<Col className="py-2 d-flex flex-col justify-content-between">
						{props.eventsData.ThumbnailImage ? <img className="broadcast_event_thumb" src={props.eventsData.ThumbnailImage} alt='thumbimg' /> :
						props.eventsData.ThumbImageURL ? <img className="broadcast_event_thumb" src={props.eventsData.ThumbImageURL} alt='thumbimg' /> : <span className="broadcast_event_thumb" />}
						<div className="col-5">{props.eventsData.Title.length > 55 ? `${props.eventsData.Title.substring(0, 55)}...` : props.eventsData.Title}</div>
						<div className="col-2">{props.eventsData.SubTitle.length > 18 ? `${props.eventsData.SubTitle.substring(0, 18)}...` : props.eventsData.SubTitle}</div>
						<div className="col-1 ps-4 grey_light">{props.eventsData.WatchCount}</div>
						<div className="col-1 ps-5 grey_light">{props.eventsData.Signups}</div> {/* XX not available atm */}
						<div className="col-2 ps-5 grey_light">{props.eventsData.Date}</div>
					</Col>
				</Link>
				<EventEntryDropdown
					eventData={props.eventsData}
					modalDelete={modalDelete}
					modalBroadcast={modalBroadcast}
					toggleDelete={toggleDelete}
					toggleBroadcast={toggleBroadcast}
					deleteEvent={deleteEvent}
					hideEvent={hideEvent}
				/>
			</Container>
		);
	}

	if (upcomingEventsData && pastEventsData) {
		return (
			<Container>
				<div className="pt-4 pb-3 d-flex flex-col justify-content-between">
					<div>
						<h3><T>Broadcasts</T></h3>
					</div>
				</div>
				<AlertReusable
					activateAlert={activateAlert}
					color="success"
					text={alertText}
					delay="1800"
				/>
				<Container fluid className="bg_white promotion_box p-0 overflow_visible">
					<Row>
						<div className="bottom_border_promo_listing w-100 font_15px">
							<div className="py-2 d-flex flex-col justify-content-start">
								<div className="font_weight_500 col-5 ps-3"><T>Upcoming events</T><span className="ps-1 login_or_seperator">{upcomingCount}</span></div>
								<div className="col-2 ps-5"><T>Speakers</T></div>
								<div className="col-2 ps-5"><T>Signups</T></div>
								<div className="col-1"><T>Date</T></div>
								<div className="col-1 ps-3"><T>Status</T></div>
							</div>
						</div>
						<div className="ps-4 py-2 bottom_border_promo_listing">
							<Row>
								{upcomingEventsData.map((v, i) => (
									<Row key={i} xs={12} md={4} lg={3}>
										<UpcomingEvents eventsData={v} />
									</Row>
								))}
							</Row>
						</div>
						<div className="pe-5 py-2 d-flex flex-col justify-content-end">
							<span className="font_weight_500 pe-4 py-1">Page {upcomingPage}</span>
							<Row>
								{upcomingPage !== 1 ? <span onClick={loadPrevUpcoming} className="font_weight_500 btn carousel-control-prev-icon me-2 promotion_box"></span> : null}
								{upcomingPage !== totalUpcomingPages && upcomingEventsData.length ? <span onClick={loadMoreUpcoming} className="font_weight_500 btn carousel-control-next-icon me-4 promotion_box"></span> : null}
							</Row>
						</div>
					</Row>
				</Container>
				<div className="py-2"></div>
				<Container fluid className="bg_white promotion_box p-0 overflow_visible">
					<Row>
						<div className="bottom_border_promo_listing w-100 font_15px">
							<div className="py-2 d-flex flex-col justify-content-start">
								<div className="font_weight_500 col-5 ps-3"><T>Past events</T>{/* <span className="ps-1 login_or_seperator">{pastCount}</span> */}</div> {/* no backend*/}
								<div className="col-2 ps-5"><T>Speakers</T></div>
								<div className="col-2 ps-5"><T>Total views</T></div>
								<div className="col-1"><T>Signups</T></div>
								<div className="col-1 ps-2"><T>Date</T></div>
							</div>
						</div>
						<div className="ps-4 py-2 bottom_border_promo_listing">
							<Row>
								{pastEventsData.map((v, i) => (
									<Row key={i} xs={12} md={4} lg={3}>
										<PastEvents eventsData={v} />
									</Row>
								))}
							</Row>
						</div>
						<div className="pe-5 py-2 d-flex flex-col justify-content-end">
							<span className="font_weight_500 pe-4 py-1">Page {pastPage}</span>
							<Row>
								{pastPage !== 1 ? <span onClick={loadPrevPast} className="font_weight_500 btn carousel-control-prev-icon me-2 promotion_box"></span> : null}
								{pastLength === eventPerPage ? <span onClick={loadMorePast} className="font_weight_500 btn carousel-control-next-icon me-4 promotion_box"></span> : null}
							</Row>
						</div>
					</Row>
				</Container>
				<Dropdown
					className="py-2 d-flex flex-col justify-content-end"
					isOpen={dropdownPageOpen}
					toggle={() => setDropdownPageOpen(!dropdownPageOpen)}
				>
					<DropdownToggle className="zevio_input">
						<span>{eventPerPage} per page<img className="ps-2" src="https://static.zevio.com/gfx/ico_expandable.svg" alt='icoexp' /></span>
					</DropdownToggle>
					<DropdownMenu>
						<DropdownItem onClick={() => setEventPerPage(5) + setPastPage(1)}>
							<span>5</span>
						</DropdownItem>
						<DropdownItem onClick={() => setEventPerPage(10) + setPastPage(1)}>
							<span>10</span>
						</DropdownItem>
						<DropdownItem onClick={() => setEventPerPage(25) + setPastPage(1)}>
							<span>25</span>
						</DropdownItem>
						<DropdownItem onClick={() => setEventPerPage(50) + setPastPage(1)}>
							<span>50</span>
						</DropdownItem>
						<DropdownItem onClick={() => setEventPerPage(100) + setPastPage(1)}>
							<span>100</span>
						</DropdownItem>
					</DropdownMenu>
				</Dropdown>
				<EventGuideCollapsibles />
			</Container>
		);
	} else {
		return <div className='text-center py-4'><T>Loading data...</T><Spinner className="p-2" color="success" /></div>;
	}
}

const Broadcasts = () => {
	return (<>
			<ZevioNav />
			<BroadcastsInner />
			<Footer />
		</>
	);
}
export default Broadcasts;
