import React, { useState } from "react";
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from "reactstrap";
import { LanguageSelect } from '../ZevioNav';
import { LL } from '../../Routes';
import { T } from '../../traks';

const AdminInternNav = () => {
	const [ barIsOpen, setBarIsOpen ] = useState(false);

	return(<>
		<Navbar expand="lg" className="navbar_bg_dark">
			<NavbarBrand className="ps-lg-4" href={LL("/admin/intern")}>
				<img alt="Zevio Logo" style={{backgroundColor: "inherit", height: "28px"}} src="https://static.zevio.com/gfx/zevio-admin-logo-0.svg" />
			</NavbarBrand>
			<div className="d-lg-none ms-auto d-flex">
				<NavbarToggler className="ps-0" onClick={() => setBarIsOpen(!barIsOpen)} />
			</div>
			<Collapse navbar isOpen={barIsOpen}>
				<Nav navbar className="d-none d-lg-block me-auto">
					<NavItem className="d-flex ps-xl-5 d-none">
						Searching
					</NavItem>
				</Nav>
				<Nav navbar>
					<NavItem className="d-flex align-items-center pt-3 pt-lg-0">
						<NavLink className='text-white' href={LL("/admin/intern/orders")}><T>Orders</T></NavLink>
					</NavItem>
					<NavItem className="d-flex align-items-center pt-3 pt-lg-0">
						<NavLink className='text-white' href={LL("/admin/intern/organizers")}><T>Organizers</T></NavLink>
					</NavItem>
					<NavItem className="d-flex align-items-center">
						<LanguageSelect/>
					</NavItem>
				</Nav>
			</Collapse>
		</Navbar>
	</>);
}

export default AdminInternNav;
