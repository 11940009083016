import { createContext, useEffect, useState } from 'react';
import { APIGet } from './API';

const LikeContext = createContext();

export const useLike = (me) => {
	const [ orgLikes, setOrgLikes ] = useState([]);

	useEffect( () => {
		if(me) {
			APIGet('sequor', { 'action': 'user_likes' }).then( (res) => setOrgLikes(res.data.body.organizer_likes) );
		}
	}, [me]);

	const likeOrganizer = (org_id) => {
		if(!orgLikes.includes(org_id)) {
			setOrgLikes ( ol => [...ol, org_id] );
			APIGet('sequor', {'action': 'like_organizers', 'organizerIds': org_id})
				.catch((error) => {
					console.log('Error liking:', error);
				})
		}
	}

	const userDoesLikeOrg = ( org_id ) => orgLikes.includes( org_id );

	const unlikeOrganizer = (org_id) => {
		setOrgLikes( ol => ol.filter( o => o !== org_id ));
		APIGet('sequor', {'action': 'unlike_organizers', 'organizerIds': org_id})
			.catch((error) => {
				console.log('Error liking:', error);
			})
	}

	return {
		userDoesLikeOrg,
		likeOrganizer,
		unlikeOrganizer,
	}
}

export default LikeContext;
