import React, { useState, useEffect, useContext, useCallback } from "react";
import OrderContext, { useOrder } from "../../Amopus/useOrder";
import CheckoutContext, { useCheckout } from "../../Amopus/useCheckout";
import { APIPost } from "../../../API";
import { url_lang } from "../../../languages";
import { T, TraksConsumer } from "../../../traks";
import { FeedbackInput } from "../../Input";
import { isValidEmail } from "../../ReusableElems";
import Receipt from "../../BuyProduct/Receipt";
import IframeCheckout from "../../Amopus/IframeCheckout";
import get_country_info from "../../Amopus/util";
import { LL } from "../../../Routes";
import SignupContext from "../useSignup";
import EventCtx from "../useEvent";

export const BuyIncognitoButton = () => {
  const { setTransitionToken } = useContext(SignupContext);
	const {eventData} = useContext(EventCtx)
	if (!eventData) return null
  return (
    <button
      onClick={() => setTransitionToken("BuyIncognitoTicket")}
      style={{ fontSize: 12, fontWeight: "normal" }}
      className="btn btn_darkgrey font_weight_500 w-100 mt-2 mb-1 border-0 py-2"
    >
      <T>Pay for an incognito-ticket to <i>{eventData.Title}</i></T>
    </button>
  );
};

const BuyIncognitoTicket = () => {
	const { setTransitionToken } = useContext(SignupContext);
	const {eventData} = useContext(EventCtx)
  const orderCtx = useOrder(getEventDataAsProductData(eventData))
	const checkoutCtx = useCheckout()
  const [clickedBuy, setClickedBuy] = useState(false);
	
	const onSuccess = useCallback(() => {
		APIPost("register", {
			action: "send_incognito_email",
			site_lang: url_lang().lang,
			event_id:eventData.Id,
			email:orderCtx.billingAddress.email
		})
		setTransitionToken("SignupComplete")
	},[orderCtx?.billingAddress?.email, eventData?.id])

  return (
		<OrderContext.Provider value={orderCtx}>
			<CheckoutContext.Provider value={checkoutCtx}>
				{!clickedBuy ? (
					<TraksConsumer>
						<PreCheckout cancel={() => setTransitionToken("Signup")} confirm={() => {
					setClickedBuy(true);
				}}/>
					</TraksConsumer>
				) : (
					<Checkout
						onSuccess={onSuccess}
						onError={() => console.log("Something went wrong")}
					/>
				)}
			</CheckoutContext.Provider>
		</OrderContext.Provider>
	);
	
};

const PreCheckout = ({render_static, cancel, confirm}) => {
	const { eventData } = useContext(EventCtx);
	const {
		setShippingAddress,
		setBillingAddress,
		billingAddress,
		createOrder,
		orderDetails,
		showCurrency,
	} = useContext(OrderContext);
	const [isValid, setIsValid] = useState(true);
	let formattedPrice = showCurrency.format(
		orderDetails.lines[0].unitpriceinclvat / 100
	);

	const submit = (e) => {
		e.preventDefault();
		let email = e.nativeEvent.target.email.value;
		if (!isValidEmail(email)) {
			setIsValid(false);
		} else {
			setShippingAddress({ email });
			setBillingAddress({ email });
		}
	};

	useEffect(()=>{
		if (billingAddress?.email) {
			createOrder();
			confirm();
		}
	}, [billingAddress?.email, confirm, createOrder])

	return (<>
		<div>
			<div className="pt-4">
				<T>
					Our webinars are free, but we do require that you register with
					us. To avoid saving your data with us, you can buy an
					incognito-ticket here.
				</T>
			</div>
		</div>
		<form onSubmit={submit}>
		<FeedbackInput
			name="email"
			type="email"
			id="email"
			autoComplete="true"
			className="pt-3 w-100"
			placeholder={render_static(<T>E-mail*</T>)}
			invalid={!isValid}
			errorMessage={render_static(<T>Invalid email address</T>)}
		/>
		<div className="my-3">
			<button
				type="submit"
				className="btn btn_green w-100 mt-2 mb-1 border-0"
			>
				<T>
					Pay {formattedPrice} for an incognito-ticket to{" "}
					<i>{eventData.Title}</i>
				</T>
			</button>
		</div>
		</form>
		<div className="mb-3">
			<button
				onClick={cancel}
				style={{ fontSize: 10, fontWeight: "normal" }}
				className="btn btn_grey font_weight_500 w-100 mt-2 mb-1 border-0 py-2"
			>
				<T>
					Click here to go back and join for free by providing the
					registration info.
				</T>
			</button>
		</div>
		<div style={{ fontSize: 10 }}>
			<T>
				All payment data will be stored with out payment-provider,
				Worldline. To comply with commerce regulations, we will retain
				info about your payment for five (5) years. We will send one (1)
				confirmation email with date and link of your webinar. We will
				also notify you of schedule changes. Your email is retained for
				this purpose, and will be deleted after the event.
			</T>
		</div>
	</>)
}

const Checkout = ({onSuccess, onError})=> {
	const {orderId} = useContext(OrderContext);
	const {setOrderId} = useContext(CheckoutContext);

	useEffect(()=>{
		setOrderId(orderId)
	}, [orderId, setOrderId])

	return <>
	<Receipt/>
	<IframeCheckout
		onSuccess={onSuccess}
		onError={onError}
	/>
</>
}



const getEventDataAsProductData = (eventData) => {
	let price = {dkk:29, eur:4, nok:45, sek:45, usd:4 }
	let { currency } = get_country_info();
	let Id = `incognitoTicket${currency.toUpperCase()}`
	let producturl = `https://zevio.com${LL("/live/")}${eventData.UniqueHash}`
  return {Id, headline:eventData.Title, description:`Ticket - ${eventData.SubTitle}`, price, producturl};
};


export default BuyIncognitoTicket;
