import React, { useState, useEffect, useContext} from "react";
import { MeContext } from "../../../MeContext";
import SignupContext from "../useSignup";
import { LL } from "../../../Routes";
import { T } from "../../../traks";
import { APIPost } from "../../../API";
import { ProductCardButton, ProductPriceElem } from "../../ProductCardButton";
import EventCtx from "../useEvent";
import ProductsCtx from "../useProducts";
import { sendToDataLayer } from "../../ReusableElems";
import { url_lang } from "../../../languages";

const SignupComplete = () => {
	const { me } = useContext(MeContext);
	const {eventData, eventId } = useContext(EventCtx)
	const { setSignupComplete, accessProduct } = useContext(SignupContext);
	const [copyText, setCopyText] = useState("COPY");
	const liveURL = LL("/live/") + eventData.UniqueHash;

	useEffect(() => {
		setSignupComplete(true);
		console.log({accessProduct})
		let {currency} = url_lang()
		let data = {
			event: "conversion",
			conversion_value: accessProduct? accessProduct.price[currency]:0,
			// 'order_id': '4433',  We don't need this yet
			paid_conversion: !!accessProduct,
			event_id: eventId,
			currency: currency.toUpperCase(),
		}
		sendToDataLayer(data);
	}, [setSignupComplete]);

	return (
		<div className="w-100 p-0 m-0">
			<div className="event_signupmodal_header text-center py-4">
					<AlreadySigned/>
				<div className="font_18px font_weight_500" />
				<T>We have sent a confirmation to your email</T>
				<div />
			</div>
			<ProductCard
				setSignupComplete={setSignupComplete}
			/>
			<div className="pt-3">
				<div
					className="text-center py-3"
					dangerouslySetInnerHTML={{ __html: eventData.eventStartDateTime }}
				/>
				<div className="pb-2 ps-2 pe-2 vertical_line_tiny d-flex flex-row align-items-center justify-content-center">
					<div>
						<T>Event URL:</T>
					</div>
				</div>
				<div className="mx-4 flex-row d-flex eventurl_box p-2 ps-3 pe-3 mb-2">
					<div style={{ flex: 2 }} className="pt-1 font_weight_500 font_13px">
						{"zevio.com" + liveURL}
					</div>
					<div
						style={{ flex: 1 }}
						className="login_or_seperator cursor-pointer font_13px d-flex flex-row-reverse"
						onClick={() =>
							setCopyText("COPIED") +
							navigator.clipboard.writeText("https://zevio.com" + liveURL)
						}
					>
						{copyText}
					</div>
				</div>
			</div>
			{me && me.me !== null && (
				<div className="pt-3 pb-2 text-center">
					<div className="color_green"><T>View under <span className="font_weight_500"><a href={LL('/mywebinars')}>My Events</a></span></T></div>
				</div>
			)}
		</div>
	);
};

const ConditionalNyhed = (props) => {
	if (props.Nyhed) {
		return (
			<span className="product_nyhed signup_nyhed_margin position-absolute">
				<T>NEW!</T>
			</span>
		);
	}
	return null;
};

export const ProductCard = ({ setSignupComplete }) => {
	const { setModalVisibility } = useContext(SignupContext);
	const {productsData} = useContext(ProductsCtx)

	return (
		<div>
			{productsData[0] ? (
				<div>
					<div className="text-center pb-5 font_20px">
						<div className="font_weight_500 pt-4">
							<T>To get the most out of this event</T>
						</div>
						<div className="pt-1 font_weight_500" />
						<T>we recommend:</T>
						<div />
					</div>
					<div className="text-center">
						<div>
							<ConditionalNyhed Nyhed={!productsData[0].Nyhed} />
							<img
								className="product_card_img"
								src={productsData[0].imageURL}
								alt=""
							/>
						</div>
						<div className="pt-4">
							<span className="pt-2 card_product_subtitle">
								{productsData[0].ownerName}
							</span>
						</div>
						<div>
							<span className="card_product_header">
								{productsData[0].headline}
							</span>
						</div>
						<div className="my-4">
							<ProductPriceElem productData={productsData[0]} />{" "}
						</div>
					</div>
					<div>
						<span className="image-container">
							<button
								onClick={() =>
									setModalVisibility(false) +
									setSignupComplete(true)
								}
								className="btn btn_grey me-lg-2 px-lg-4 mb-lg-0 py-3 font_weight_500"
							>
								<T>Maybe later</T>
							</button>
							<ProductCardButton
								classes="ms-2 btn btn_green px-lg-5 py-3 font_weight_500"
								productData={productsData[0]}
							/>
						</span>
					</div>
				</div>
			) : (
				<div />
			)}
		</div>
	);
};


const AlreadySigned = () => {
	const {alreadySigned} = useContext(SignupContext)
	return <>
		{alreadySigned ?
			<div className="font_18px font_weight_500 py-2"> <T>You have already signed up to this event</T> </div>
			:
			<div className="font_24px font_weight_600 py-2">
				<img
					className="pe-2 pb-1"
					style={{
						filter:
							"invert(52%) sepia(88%) saturate(423%) hue-rotate(94deg) brightness(98%) contrast(77%)",
					}}
					src="https://static.zevio.com/gfx/ico_signUpComplete.svg"
					alt="icosuccess"
				/>
				<T>Signup complete</T>
			</div>}</>
}

export default SignupComplete;
