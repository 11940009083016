import React, { useState, useEffect, useRef, useContext } from "react";
import axios from 'axios';
import { Col, Container, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner, Modal } from 'reactstrap';

import Footer from '../c/Footer';
import { GreenButton, RedButton, AlertReusable, NotOrganizer } from '../c/ReusableElems';
import { ProductCard } from "../c/Cards";
import { MeContext } from '../MeContext';
import ZevioNav from '../c/ZevioNav';
import DropCropper from '../c/DropCropper';
import { LL } from '../Routes';
import { T, TraksConsumer } from '../traks';
import { useParams, useNavigate } from "react-router-dom";

import { APIGet, APIPost } from "../API";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Buffer } from "buffer";

const HelpText = (props) => {
	return (
		<div className="explanation_text">
			{props.children}
		</div>
	);
}

const EditProductInner = () => {
	const { me } = useContext(MeContext);
	if (me && me.currentSelectedOrganizer) {
		return (
			<TraksConsumer>
				<EditProduct/>
			</TraksConsumer>
		);
	} else {
		return (
			<NotOrganizer />
		);
	}
}

const EditProduct = (props) => {
	const { me } = useContext(MeContext);
	const { Id } = useParams();
	const urlParams = Id;
	const [productId, setProductId] = useState(urlParams);

	const [coverImage, setCoverImage] = useState(null);
	const [productData, setProduct] = useState({
		"headline": "",
		"description": "",
		"price": "0",
		"currency": "DKK",
		"taxable": 1,
		"shippable": 0,
		"shippingFee": "",
		"ownerName": "",
		"ownerPhone": "",
		"ownerEmail": "",
		"active": 0,
		"imageURL": "",
		"isbn": "",
		"externalLink": "",
		"expireDate": "",
	});

	const [organizerInfo, setOrganizerInfo] = useState(['']);

	const saveProduct = (props) => {
		let sendProduct = productData;
		delete sendProduct['imageURL'];
		const postObj = {
			'action': 'update_product',
			'product': {
				...sendProduct,
				price: selectedPrice,
				Id: productId,
			},
		};
		APIPost('products', postObj).then((res) => {
			if (res.status === 200 && props) {
				setAlertText(<T>Product saved</T>);
				setAlertColor("success");
				setActivateAlert(activateAlert + 1);
			}
		}).catch((error) => {
			console.log(error);
		});
	}

	const uploadImage = (imageParams) => {
		const imgTypeRegExp = new RegExp('^data:image/([a-z]+);base64,');
		const imgType = imageParams.image.match(imgTypeRegExp)[1];
		APIPost('products', { 'action': 'get_image_upload_url', 'file_extension': [imgType] }).then((res) => {
			const uploadInfo = res.data.body.upload_info;
			let uploadForm = new FormData();
			for (const key in uploadInfo.fields) {
				uploadForm.append(key, uploadInfo.fields[key]);
			};
			uploadForm.append('acl', 'public-read');
			const imgData = imageParams.image.replace(imgTypeRegExp, '');
			const imgBuffer = new Buffer(imgData, 'base64');
			const imgBlob = new Blob([imgBuffer], { type: "image/" + imgType });
			uploadForm.append('file', imgBlob);
			axios.post(uploadInfo.url, uploadForm).then((response) => {
				if (response.status === 204) {
					doneCropping({ [imageParams.whichImage]: uploadInfo.fields.key });
				}
			});
		});
	}

	const populateProductData = () => {
		APIPost('products', { 'action': 'get_products', 'get_organizers': true, 'product_ids': [productId] })
			.then((res) => {
				let org = res.data.products[0].organizers.map(Number);
				setSelectedOrganizers(org);
				let productFromAPI = { ...res.data.products[0] }
				setSelectedPrice(productFromAPI.price[productFromAPI.currency.toLowerCase()]);
				if (productFromAPI.productId) {
					setProductId(productFromAPI.productId);
					delete productFromAPI['productId'];
					delete productFromAPI['organizers'];
					if (productFromAPI['imageURL'])
						setCoverImage(productFromAPI['imageURL']);
					setProduct((oe) => productFromAPI);
				} else {
					setAlertText("Error fetching event id");
					setAlertColor("danger");
					setActivateAlert(activateAlert + 1);
				}
			})
			.catch((error) => {
				console.log(['Error:', error])
		}	)
	}

	useEffect(() => {
		let organizerIdsToGet = [...new Set([...me.organizers])].join(',');
		if(me.roles.indexOf(42) !== -1)
			organizerIdsToGet = "all";
		APIGet('search', { 'organizer_id': organizerIdsToGet })
			.then((res) => {
				setOrganizerInfo(res.data.body);
			}).catch((error) => {
				console.log('Error:', error);
		}	);
		if (!urlParams || !urlParams.length || !productId || !productId.length) {
			APIPost('products', {
				'action': 'create_product',
				'product': { 'active': false, 'taxable': true, 'organizers': [me.currentSelectedOrganizer] } 
			}).then((res) => {
				setProductId(res.data.product.productId);
				window.history.replaceState(null, '', "editproduct/" + res.data.product.productId);
				let org = res.data.product.organizers.map(Number);
				setSelectedOrganizers(org);
			})
			.catch((error) => {
				console.log(['Error:', error])
			})
		} else {
			populateProductData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const ChangeActiveStatus = () => {
		let hideVal = !productData.active;
		const postObj = {
			'action': 'update_product',
			'product': {
				'Id': productId,
				'active': hideVal,
				'currency': productData.currency,
				'price': selectedPrice,
			},
		};
		APIPost('products', postObj).then((response) => {
			if (response.status === 200) {
				setAlertColor("success");
				setAlertText('Product visibility changed')
				setActivateAlert(activateAlert + 1);
				setProduct({ ...productData, active: !productData.active })
			}
		});
	}

	const [selectedPrice, setSelectedPrice] = useState('');
	const [index, setIndex] = useState(0);

	useEffect(() => {
		if (productData && productData.currency) {
			switch (productData.currency) {
				case 'DKK': setIndex(0);
					break;
				case 'EUR': setIndex(1);
					break;
				case 'NOK': setIndex(2);
					break;
				case 'SEK': setIndex(3);
					break;
				case 'USD': setIndex(4);
					break;
				default: setIndex(0);
			}
		}
		if (!selectedPrice && productData.currency && productData.currency.length) {
			setSelectedPrice(productData.price[productData.currency.toLowerCase()]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [productData.currency]);

	const doneCropping = (imageParam) => {
		const uploadObj = {
			'action': 'new_product_image',
			'product_id': productId,
				...imageParam,
		}
		APIPost('products', uploadObj).then((response) => {
		}).catch((error) => {
			console.log(error);
		});
	}

	const doneCroppingCover = (image) => uploadImage({ 'image': image, 'whichImage': 'image_url' });

	const deleteCoverImg = () => {
		const postObj = {
			'action': 'delete_product_image',
			'product_id': productId,
		};
		APIPost('products', postObj).then((res) => {
		}).catch((error) => {
			console.log(error);
		})
		setCoverImage(null);
	}

	const OverlayedImage = (props) => {
		return (
			<div className="position-relative pb-4">
				<img alt="" {...props} />
				<div style={{ background: "rgba(255, 255, 255, 0.55" }} className="position-absolute top-0 start-0 h-100 w-100" />
				<div className="position-absolute top-0 h-100 w-100">
					<img alt="" {...props} style={{ borderRadius: "50%" }} />
				</div>
			</div>
		);
	}

	const ShowImage = (props) => {
		let ourProps = { ...props };
		delete ourProps.onClick;
		let imagePart = (
			<div className="pb-4">
				<img alt="" {...ourProps} />
			</div>
		);

		if (props.overlay) imagePart = <OverlayedImage {...props} />;

		return (
			<div className='text-center'>
				{imagePart}
				{props.showbutton && <RedButton onClick={props.onClick}>Delete</RedButton>}
			</div>
		);
	}

	const [activateAlert, setActivateAlert] = useState(0);
	const [alertText, setAlertText] = useState('');
	const [alertColor, setAlertColor] = useState('');

	const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false);
	const [modalDelete, setModalDelete] = useState(false);
	const toggleDelete = () => setModalDelete(!modalDelete);
	const hideProductText = productData.active ? <T>DeActivate</T> : <T>Active</T>  ;

	const ActiveBadge = () => {
		return <span className="ps-2">{productData.active ? <span className="broadcast_badge badgecolor_approved"><T>Active</T></span> : <span className="broadcast_badge badgecolor_hidden"><T>InActive</T></span>}</span>;
	}

	const [dropdownOrganizersOpen, setDropdownOrganizersOpen] = useState(false);
	const [selectedOrganizers, setSelectedOrganizers] = useState([me.currentSelectedOrganizer]);

	const org_select_it = (e, id) => {
		setSelectedOrganizers(oldArray => [id, ...selectedOrganizers]);
		let uploadObj = {
			'action': 'add_organizers', 'product_id': productId, 'organizer_ids': [id]
		};
		APIPost('products', uploadObj)
			.catch((error) => {
				console.log(['Error adding products:', error]);
		}	)
	}
	const org_deSelect_it = (e, id) => {
		let filtered = selectedOrganizers.filter((org) => org !== id);
		setSelectedOrganizers(filtered);
		let uploadObj = {
			'action': 'delete_organizers', 'product_id': productId, 'organizer_ids': [id]
		};
		APIPost('products', uploadObj)
			.catch((error) => {
				console.log(['Error deleting products:', error]);
		}	)
	}

	const ModalDelete = ({ productName, productId, onClick }) => {
		let navigate = useNavigate();
		const deleteProduct = () => {
			if (deleteText === "delete") {
				APIPost('products', { 'action': 'del_products', 'product_ids': [productId] }).then((response) => {
					if (response.status === 200) {
						onClick();
						setAlertText('Product deleted')
						setAlertColor('success');
						setActivateAlert(activateAlert + 1);
						setTimeout(() => navigate(LL("/products")), 1000);
					}
				}).catch((error) => {
					console.log(['Error:', error])
				});
			}
		};

		const [deleteText, setDeleteText] = useState('');
		const submit_button_disabled = !(deleteText === ('delete'));

		const inputElement = useRef(null);
		useEffect(() => {
			if (inputElement.current) {
				inputElement.current.focus();
			}
		}, []);

		return (
			<div className="p-3">
				<div>
					<img onClick={onClick} className="btn" style={{ float: 'right' }} src="https://static.zevio.com/gfx/ico_close.svg" alt='icoClose' />
				</div>
				<div className="ps-5 pt-3 image-container">
					<div className="icon_modal_delete">
						<img src="https://static.zevio.com/gfx/ico_deleteprofile.svg" alt='icoDelProf' />
					</div>
				</div>
				<div className="pt-3">
					<span><T>Are you sure you want to delete product:</T></span> <div className="font_weight_500"> {productName} </div>
				</div>
				<div className="pt-4 pb-2">
					<span><T>Type: </T><span style={{ fontWeight: "900" }}>DELETE</span> <T>to delete this product</T></span>
				</div>
				<div>
					<form onSubmit={(e) => e.preventDefault() + deleteProduct(e)}>
						<input
							ref={inputElement}
							name="Delete"
							id="Delete"
							className="zevio_input w-450 w-100"
							onChange={(e) => setDeleteText(e.target.value.toLowerCase())}
						/>
					</form>
				</div>
				<div className='pt-3 pb-4'>
					<RedButton
						style={{ width: '100%' }}
						disabled={submit_button_disabled}
						onClick={deleteProduct}><T>Delete product</T>
					</RedButton>
				</div>
			</div >
		);
	}

	const updateDate = (d) => {
		const expireDateStr = d.toISOString().split('T')[0];
		setProduct({...productData, expireDate: expireDateStr });
	}

	const [modalPreview, setModalPreview] = useState(false);
	const togglePreview = () => setModalPreview(!modalPreview);
	const ModalPreviewCard = () => {
		return (
			<div className="d-flex justify-content-center py-3">
				<ProductCard data={productData} i="9999" />
			</div>
		);
	}

	if ([me.organizers] && organizerInfo  ) {
		return (
			<Container>
				<AlertReusable
					color={alertColor}
					activateAlert={activateAlert}
					text={alertText}
					delay="2000"
				/>
				<div className="pt-4 pb-3 d-flex flex-col justify-content-between">
					<span>
						<div className="pb-2 d-flex flex-col">
							<h3><T>Edit product</T></h3>
							<Dropdown
								className="ps-2"
								isOpen={dropdownMenuOpen}
								toggle={() => setDropdownMenuOpen(!dropdownMenuOpen)}
							>
								<DropdownToggle className="zevio_input">
									<span><img src="https://static.zevio.com/gfx/ico_expandable.svg" alt='icoexp' /></span>
								</DropdownToggle>
								<DropdownMenu className="text-center">
									<DropdownItem onClick={() => toggleDelete(true)}>
										<Modal toggle={toggleDelete} centered isOpen={modalDelete}>
											<ModalDelete onClick={toggleDelete} productName={productData.headline} productId={productId} />
										</Modal>
										<span><T>Delete product</T></span>
									</DropdownItem>
									<DropdownItem divider />
									<DropdownItem onClick={() => ChangeActiveStatus()}>
										<span>{hideProductText}</span><div><ActiveBadge /></div>
									</DropdownItem>
								</DropdownMenu>
							</Dropdown>
						</div>
						<span className="px-2">Active:<ActiveBadge /></span>
					</span>
					<div>
						<button onClick={togglePreview} className="btn btn_white_dark_border w-10 py-2 px-3 mx-2"><T>Preview</T>
							<Modal toggle={togglePreview} centered isOpen={modalPreview}>
								<ModalPreviewCard />
							</Modal>
						</button>
						<GreenButton disabled={selectedOrganizers.length === 0} onClick={saveProduct}><span><T>Save</T></span></GreenButton>
					</div>
				</div>
				<form onSubmit={(e) => e.preventDefault()} id='edit_product'>
					<Container fluid className="bg_white promotion_box p-0">
						<Row>
							<div className="bottom_border_promo_listing w-100 font_15px font_weight_500">
								<div className="ps-4 py-2">
									<T>Product info</T>
								</div>
							</div>
							<Col xs="12" lg="6" className="pt-2 pb-3">
								<div className="ps-4">
									<div className="py-2">
										<T>Product title</T>
										<input
											placeholder={props.render_static(<T>The name of your product (e.g. Book of Learning)</T>)}
											onChange={(e) => setProduct({ ...productData, headline: e.target.value })}
											name="ProductTitle"
											id="ProductTitle"
											form="edit_product"
											className="zevio_input w-100 mt-1"
											value={productData.headline}
										/>
									</div>
									<div className="py-2">
										<T>Description</T>
										<HelpText>
											<T>Description of your product</T>
										</HelpText>
										<div>
											<textarea
												style={{ reSize: "true" }}
												rows="3"
												placeholder={props.render_static(<T>The description of your product</T>)}
												onChange={(e) => setProduct({ ...productData, description: e.target.value })}
												name="ProductDesc"
												id="ProductDesc"
												form="edit_product"
												className="zevio_input w-100 mt-1"
												value={productData.description}
											/>
										</div>
									</div>
									<div className="py-2">
										<T>Price</T> ({ productData.currency })
										<input
											key={"123132"}
											placeholder={props.render_static(<T>The price of the product (e.g. 299)</T>)}
											onChange={(e) => setSelectedPrice(e.target.value)}
											name="Price"
											id="Price"
											form="edit_product"
											className="zevio_input w-100 mt-1"
											value={ selectedPrice }
										/>
									</div>
									<div className="py-2">
										<div><T>Currency</T></div>
										<button className="btn_radio"
											onClick={(e) => e.preventDefault() + setProduct({ ...productData, currency: "DKK" })} disabled={index === 0}>
											<span className="font_15px font_weight_500">dkk</span>
										</button>
										<button className="btn_radio"
											onClick={(e) => e.preventDefault() + setProduct({ ...productData, currency: "EUR" })} disabled={index === 1}>
											<span className="font_15px font_weight_500">eur</span>
										</button>
										<button className="btn_radio"
											onClick={(e) => e.preventDefault() + setProduct({ ...productData, currency: "NOK" })} disabled={index === 2}>
											<span className="font_15px font_weight_500">nok</span>
										</button>
										<button className="btn_radio"
											onClick={(e) => e.preventDefault() + setProduct({ ...productData, currency: "SEK" })} disabled={index === 3}>
											<span className="font_15px font_weight_500">sek</span>
										</button>
										<button className="btn_radio"
											onClick={(e) => e.preventDefault() + setProduct({ ...productData, currency: "USD" })} disabled={index === 4}>
											<span className="font_15px font_weight_500">usd</span>
										</button>
									</div>
								</div>
							</Col>
							<Col xs="12" lg="6" className="pt-2 pb-3 pe-4">
								<div className="py-2">
									<T>Product image</T>
									{coverImage
										?
										<ShowImage showbutton="true" src={coverImage} onClick={deleteCoverImg} className="w-50 mw_mh_300px transform_scale_1" />
										:
										(
											<React.Fragment>
												<HelpText>
													<T>Minimum dimensions 350px &times; 350px. <span className="font_weight_500">Recommended</span> minimum is 700px &times; 700px</T>
												</HelpText>
												<div className="pe-lg-4 pt-2 w-50">
													<DropCropper doneCropping={doneCroppingCover} minWidth={350} minHeight={350} maxWidth={700} maxHeight={700} />
												</div>
											</React.Fragment>
										)}
								</div>
							</Col>
						</Row>
					</Container>
					<Container fluid className="bg_white promotion_box p-0 mt-3 mb-4">
						<Row>
							<div className="bottom_border_promo_listing w-100 font_15px font_weight_500">
								<div className="ps-4 py-2">
									<T>Specifications</T>
								</div>
							</div>
							<Col xs="12" lg="4" className="pb-3">
								<div className="px-4">
									<label className='pt-3'>
										<input className="checkbox_green ps-5"
											key={Math.random()}
											style={{ transform: "scale(1.2)", }}
											id="active"
											type="checkbox"
											defaultChecked={productData.active}
											onChange={() => ChangeActiveStatus()}
										/>
										<span className="ps-2"><T>Active</T></span>
										<div className="ps-4">
											<HelpText>
												<T>Make this product active / inactive</T>
											</HelpText>
										</div>
									</label>
								</div>
							</Col>
							<Col xs="12" lg="4" className="pb-3">
								<div className="px-4">
									<label className='pt-3'>
										<input className="checkbox_green ps-5"
											key={Math.random()}
											style={{ transform: "scale(1.2)", }}
											id="shippable"
											type="checkbox"
											defaultChecked={productData.shippable}
											onChange={() => setProduct({ ...productData, shippable: !productData.shippable })}
										/>
										<span className="ps-2"><T>Shippable</T></span>
										<div className="ps-4">
											<HelpText>
												<T>Can this product be shipped</T>
											</HelpText>
										</div>
									</label>
								</div>
								{productData.shippable ?
									<div className="px-4">
										<label className='pt-3'>
											<input
												placeholder={props.render_static(<T>Price of shipping</T>)}
												onChange={(e) => setProduct({ ...productData, shippingFee: e.target.value })}
												name="shippingfee"
												id="shippingfee"
												form="edit_product"
												className="zevio_input w-100"
												value={productData.shippingFee}
											/>
										</label>
									</div>
								: null}
							</Col>
							<Col xs="12" lg="4" className="pb-3">
								<div className="px-4">
									<label className='pt-3'>
										<input className="checkbox_green ps-5"
											key={Math.random()}
											style={{ transform: "scale(1.2)", }}
											id="taxable"
											type="checkbox"
											defaultChecked={productData.taxable}
											onChange={() => setProduct({ ...productData, taxable: !productData.taxable })}
										/>
										<span className="ps-2"><T>Taxable</T></span>
										<div className="ps-4">
											<HelpText>
												<T>Is this product taxable</T>
											</HelpText>
										</div>
									</label>
								</div>
							</Col>
							{me && me.currentSelectedOrganizer === 90 ?
							<Col xs="12" lg="4" className="pb-3">
								<div className="px-4">
									<label className='pt-3'>
										ISBN
										<input
											key={"123"}
											placeholder={props.render_static(<T>ISBN (Dagens Bog)</T>)}
											onChange={(e) => setProduct({ ...productData, isbn: e.target.value })}
											name="isbn"
											id="isbn"
											form="edit_product"
											className="zevio_input w-100 mt-1"
											value={productData.isbn}
										/>
									</label>
								</div>
							</Col>
							: null}
							{me && me.roles.indexOf(42) !== -1 ? <>
							<Col xs="12" lg="4" className="pb-3">
								<div className="px-4">
									<label className='pt-3'>
										<T>External link</T>
										<HelpText>
											<T>If this exists, the add to cart button will link there.</T>
										</HelpText>
										<input
											key={"12xksf4"}
											placeholder={props.render_static(<T>External link</T>)}
											onChange={(e) => setProduct({ ...productData, externalLink: e.target.value })}
											name="externalLink"
											id="externalLink"
											form="edit_product"
											className="zevio_input w-100 mt-1"
											value={productData.externalLink}
										/>
									</label>
									{(productData.externalLink && (!productData.externalLink.includes("http://")) && !productData.externalLink.includes("https://")) ? <span className="red_text pt-1"><T>Please include full link containing http or https</T></span> : null}
								</div>
							</Col>
							<Col xs="12" lg="4" className="pb-3">
								<div className="px-4">
									<label className='pt-3'>
										<T>Product expiry date</T>
										{productData.expireDate &&
											<span className="align_right font_11px cursor-pointer color_red text_underline"
												style={{paddingTop: "4px"}}
												onClick={() => setProduct({...productData, expireDate: '' })}><T>Clear date</T></span>
										}
										<HelpText>
											<T>Starting this date, the product no longer will be active.</T>
										</HelpText>
										<DatePicker
											placeholderText={productData.expireDate ? productData.expiredate : props.render_static(<T>Click to select date</T>)}
											selected={productData.expireDate ? new Date(productData.expireDate) : null}
											minDate={new Date()}
											dateFormat="yyyy-MM-dd"
											onChange={updateDate}
											className="zevio_input w-100 mt-1"
										/>
									</label>
								</div>
							</Col>
							</> : null}
						</Row>
					</Container>
					<Container fluid style={{ overflow: "visible" }} className="bg_white promotion_box p-0 mt-3 mb-4">
						<Row>
							<div className="bottom_border_promo_listing w-100 font_15px font_weight_500">
								<div className="ps-4 py-2">
									<T>Contact details</T>
								</div>
							</div>
							<Row>
								<Col xs="12" lg="6" className="pt-2 pb-3">
									<div className="px-4">
										<div className="py-2">
											<T>Owner name</T>
											<HelpText>
												<T>Product owner name to be printed on receipt</T>
											</HelpText>
											<input
												placeholder={props.render_static(<T>The name of the product owner (e.g. Chris McDonald)</T>)}
												onChange={(e) => setProduct({ ...productData, ownerName: e.target.value })}
												name="OwnerName"
												id="OwnerName"
												form="edit_product"
												className="zevio_input w-100 mt-1"
												value={productData.ownerName}
											/>
										</div>
									</div>
								</Col>
								{organizerInfo && selectedOrganizers ?
								<Col xs="12" lg="6" className="pt-2 pb-3">
									<div className="px-4">
										<div className="py-2">
											<T>Available organizers</T>
											<HelpText>
												<T>What organisers are allowed to put this product up for sale?</T>
											</HelpText>
											<div>
												<Dropdown isOpen={dropdownOrganizersOpen} toggle={() => setDropdownOrganizersOpen(!dropdownOrganizersOpen)}>
													<DropdownToggle className="btn_editevent zevio_input w-100 opposites mt-1"><T>Select</T><img src="https://static.zevio.com/gfx/ico_expandable.svg" alt='icoexp' /></DropdownToggle>
													<DropdownMenu className="dropdown_scroll_menu">
															{organizerInfo.filter(function (item) { return !selectedOrganizers.includes(item.Id) }).sort((a, b) => a.Name.localeCompare(b.Name)).map((org, i) => (
															<DropdownItem
																key={i}
																onClick={(e) => org_select_it(e, org.Id)}
															>
																<span className="zevio_input_dropdown">
																	{org.Name}
																</span>
															</DropdownItem>
														))}
													</DropdownMenu>
												</Dropdown>
												{selectedOrganizers.length === 0 ? <div className="red_text pt-1">Please add organizer(s)</div> : null}
												<div className="pt-1">
													{organizerInfo.filter(function (item) { return selectedOrganizers.includes(item.Id) }).map((org, i) => (
														<button
															key={i}
															className="badge_grey zevio_input mb-2"
															onClick={(e) => e.preventDefault() + org_deSelect_it(e, org.Id)}
														>
															<img src="https://static.zevio.com/gfx/ico_close.svg"
																className="me-1"
																alt="close"
																style={{ marginBottom: "2px" }} />
															{org.Name}
														</button>
													))}
												</div>
											</div>
										</div>
									</div>
								</Col>
								: null}
							</Row>
							<Col xs="12" lg="6" className="pt-2 pb-3">
								<div className="px-4">
									<div className="py-2">
										<T>Owner email</T><br />
										<HelpText>
											<T>Product owner email to be printed on receipt.</T>
										</HelpText>
										<input
											placeholder={props.render_static(<T>E-mail</T>)}
											name="ownerEmail"
											id="ownerEmail"
											form="edit_product"
											className="zevio_input w-100 mt-1"
											value={productData.ownerEmail}
											onChange={(e) => setProduct({ ...productData, ownerEmail: e.target.value })}
										/>
									</div>
								</div>
							</Col>
							<Col xs="12" lg="6" className="pt-2 pb-3">
								<div className="px-3">
									<div className="py-2">
										<T>Owner phone</T><br />
										<HelpText>
											<T>Product owner phone number to be printed on receipt</T>
										</HelpText>
										<input
											placeholder={props.render_static(<T>Phone no.</T>)}
											name="ownerPhone"
											id="ownerPhone"
											form="edit_product"
											className="zevio_input w-100 mt-1"
											value={productData.ownerPhone}
											onChange={(e) => setProduct({ ...productData, ownerPhone: e.target.value })}
										/>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</form>
				{me.currentSelectedOrganizer === 90 && <div className='font_15px pb-4'><u><a href={'https://dagensbog.opusedb.com/product/' + productId} target='_blank' rel='noopener noreferrer'>https://dagensbog.opusedb.com/product/{productId}</a></u></div>}
			</Container>
		);
	} else {
		return <div className='text-center'><T>Loading data...</T><Spinner className="p-2" color="success" /></div>;
	}
}

const EditProductPage = () => {
	return (<>
			<ZevioNav />
			<EditProductInner />
			<Footer />
		</>
	);
}
export default EditProductPage;
