import React, { useState, useEffect, useContext } from "react";
import { Modal, ModalBody } from "reactstrap";
import { HorizontalSmoothScroller } from "../ReusableElems";
import {  ProductCard } from "../Cards";
import { T } from "../../traks";
import ProductsCtx from "./useProducts.js";


const VideoProductModal = () => {
	const { productsData } = useContext(ProductsCtx)
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(false);
	const showButtonsBool = productsData.length > 1 ? true : false;
	const imageContainerCss = productsData.length > 1 ? "" : "image-container";
	const listen = (props) => {
		if (props.includes("add2cartButton")) {
			toggle();
		}
	};

	useEffect(() => {
		if (productsData && productsData.length) {
			if (window.localStorage.getItem("modal_productid") === productsData[0].productId) {
				setModal(false);
			}
			if (window.localStorage.getItem("modal_productid") !== productsData[0].productId) {
				setModal(true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let productText = <T>Recommended product:</T>;
	if (productsData.length > 1) productText = <T>Recommended products:</T>;

	if (productsData.length) {
		return (
			<>
				<Modal
					onClick={(e) => listen(e.target.id)}
					style={{
						top: "150px",
					}}
					isOpen={modal}
					toggle={toggle}
				>
					<ModalBody className="ps-lg-4 event_profile text-center">
						<div className="h3 pt-2">{productText}</div>
						<div className={imageContainerCss}>
							<HorizontalSmoothScroller showButtons={showButtonsBool}>
								{productsData.map((v, i) =>
									v.active === true ? (
										<div key={i}>
											<ProductCard data={v} i={i} />
										</div>
									) : null
								)}
							</HorizontalSmoothScroller>
						</div>
						<div className="pb-4">
							<span
								onClick={() => toggle()}
								className="grey_frontpage cursor-pointer me-3"
							>
								<T>No thanks, continue to video</T>
							</span>
						</div>
					</ModalBody>
				</Modal>
			</>
		);
	} else return null;
};


export default VideoProductModal;
