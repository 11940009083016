import React from "react";
import { LL } from "../../Routes";
import { T } from "../../traks";

const TermsOfTrade = ({ acceptedTerms, setAcceptedTerms, invalidTerms, setInvalidTerms }) => {
	return (
		<div className="pt-4">
			<div className={invalidTerms?"bg-danger bg-opacity-10 rounded":""}>
				<input
					className="checkbox"
					id="termsOfTradeCheck"
					type="checkbox"
					value={acceptedTerms}
					onChange={(e) => {
						setInvalidTerms(false);
						setAcceptedTerms(!!e.target.checked);
					}}
				/>
			<label className="m-2" htmlFor="agree_trade_terms">
				<span>
					<T>
						I agree to the&nbsp;
						<a
							href={LL("/termsoftrade")}
							rel="noreferrer"
							target="_blank"
						>
							terms of trade
						</a>
					</T>
				</span>
			</label>
			</div>
		</div>
	);
};

export default TermsOfTrade;
