import React, { useState, useContext, useEffect, useRef  } from "react";
import { Col, Container, Row } from 'reactstrap';
import ZevioNav from "../c/ZevioNav";
import { APIPost } from "../API";
import { MeContext } from "../MeContext";
import { T, TraksConsumer } from '../traks';
import { LL } from "../Routes";
import { useNavigate } from "react-router-dom";
import { GreenButton } from "../c/ReusableElems";
import InputCustom from "../c/Input.js";
import { RefreshSession } from "./Login";

const SignupEmail = (props) => {

	let [verifyCred, setVerifyCred] = useState({
		'email2': '',
		'password': ''
	});

	const navigate = useNavigate();
	const { setMe } = useContext(MeContext);
	const [agreeTerms, setAgreeTerms] = useState(false);
	const [userExists, setUserExists] = useState(false);
	const [user, setUser] = useState({
		"name": "",
		"email": "",
		"password": "",
	});

	const submit_disabled =
		!(agreeTerms
		&& (user.password === verifyCred.password)
		&& (user.email === verifyCred.email2)
		&& (user.email.length && user.email.includes("@") && user.email === verifyCred.email2)
		&& (user.password.length > 4)
		&& (user.name.length))

	const placeholders = {
		name: props.render_static(<T>Name</T>),
		email: props.render_static(<T>E-Mail</T>),
		emailagain: props.render_static(<T>E-Mail again</T>),
		password: props.render_static(<T>Password</T>),
		passwordagain: props.render_static(<T>Password again</T>)
	};

	const [showInvalidName, setShowInvalidName] = useState(false);
	const [showInvalidEmail, setShowInvalidEmail] = useState(false);
	const [showInvalidTerms, setShowInvalidTerms] = useState(false);
	const [showInvalidPassword, setShowInvalidPassword] = useState(false);

	const submit = () => {
		if (!user.name.length) {
			setShowInvalidName(true);
		}
		if (user.email !== verifyCred.email2 || user.email.length < 3 || !user.email.includes("@")) {
			setShowInvalidEmail(true);
		}
		if (!agreeTerms) {
			setShowInvalidTerms(true);
		}
		if (user.password.length < 5 || user.password !== verifyCred.password) {
			setShowInvalidPassword(true);
		}
		if (!submit_disabled) {
			APIPost("users", { ...user, 'action': 'create_user' }).then((reply) => {
				if (reply.data.response_code === 201 && reply.data.body.sessionId) {
					window.plausible("create_user");
					setMe(reply.data.body.user);
					RefreshSession(reply.data.body.sessionId);
					setTimeout(() => navigate(LL('/account')), 1000);
				} else if (reply.data.response_code === 202) {
					setUserExists(true);
				}
			});
		}
	};

	const inputElement = useRef(null);
	useEffect(() => {
		if (inputElement.current) {
			inputElement.current.focus();
		}
	}, []);
	
	if(userExists)
		return <UserExistsPage email={user.email} />;

	return (
		<form autoComplete="off" id='signup_user' onSubmit={(e) => e.preventDefault()}>
			<InputCustom
				autofocus={true}
				innerRef={inputElement}
				name="Name"
				type="text"
				form="signup_user"
				className="input_login pt-4 w-100"
				placeholder={placeholders.name}
				onChange={(name) => setUser({ ...user, name: name})}
			/>
			{showInvalidName && !user.name.length && <div className="red_text"><i><T>Please insert name</T></i></div>}
			<InputCustom
				name="Email"
				type='email'
				form="signup_user"
				className="input_login pt-4 w-100"
				placeholder={placeholders.email}
				onChange={(email) => setUser({ ...user, email: email })}
			/>
			<InputCustom
				name="EmailAgain"
				id="EmailAgain"
				form="signup_user"
				type='email'
				className="input_login pt-4 w-100"
				placeholder={placeholders.emailagain}
				onChange={(email2) => setVerifyCred({ ...verifyCred, email2: email2 })}
			/>
			{showInvalidEmail && (user.email !== verifyCred.email2 || user.email.length < 3 || !user.email.includes("@")) && <div className="red_text"><i><T>Invalid email address</T></i></div>}
			<InputCustom
				name="Password"
				id="Password"
				type='password'
				form="signup_user"
				className="input_login pt-4 w-100"
				placeholder={placeholders.password}
				onChange={(ps1) => setUser({ ...user, password: ps1 })}
			/>
			<InputCustom
				name="PasswordAgain"
				id="PasswordAgain"
				type='password'
				form="signup_user"
				className="input_login pt-4 w-100"
				placeholder={placeholders.passwordagain}
				onChange={(ps2) => setVerifyCred({ ...verifyCred, password: ps2})}
			/>
			{showInvalidPassword && (!user.password.length || user.password !== verifyCred.password || user.password.length < 5) && <div className="red_text"><i><T>Passwords must match and have sufficient length</T></i></div>}
			<div>
				<label className='pt-4'>
					<input className="checkbox_green"
						id="agree_terms"
						form="signup_user"
						type="checkbox"
						defaultChecked={agreeTerms}
						onChange={(e) => setAgreeTerms(!!e.target.checked)}
					/>
					<span className='px-1 signup_agree_terms_text'><T>I agree to the&nbsp;<a className="color_green" href={LL("/terms")}>terms</a> and <a className="greentext" href={LL("/privacy")}>privacy policy</a></T></span>
				</label>
			</div>
			{showInvalidTerms && !agreeTerms && <div className="red_text"><i><T>You need to accept the terms</T></i></div>}
			<div className="pt-4">
				<button onClick={submit} type="submit" className="text-white font_15px btn btn_green w-100 mt-2"><T>Sign up</T></button>
			</div>
		</form>
	);
}

const UserExistsPage = ({email}) => {
	return (
		<div className="text-center pt-3 pt-lg-4">
			<div>
				<i><T>The email address <tt>{email}</tt> already has an account on Zevio. Click below
				to go to the login page.</T></i>
			</div>
			<div className="pt-3 pt-lg-4">
				<GreenButton href={LL("/login")+"?email="+email}>Login</GreenButton>
			</div>
		</div>
	);
}

const SignUpPage = () => {
	return (
		<React.Fragment>
		<ZevioNav />
		<Container>
			<Row className="py-lg-5">
				<Col xs="12" lg="10" className="promotion_box mx-auto p-0">
					<div className='d-flex flex-row'>
						<div className="d-none d-lg-block">
							<svg width="560" height="770" className="login_svg" viewBox="0 0 560 650" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd" d="M522.881 760.203C458.979 795.401 387.578 813 308.625 813C228.948 813 157.159 795.401 93.2567 760.203C29.3548 725.005 -20.8409 676.368 -57.3045 614.293C-93.7682 552.218 -112 482.946 -112 406.5C-112 330.054 -93.7682 260.782 -57.3045 198.707C-20.8409 136.632 29.3548 87.9953 93.2567 52.7972C157.159 17.5991 228.948 0 308.625 0C387.578 0 458.979 17.5991 522.881 52.7972C586.783 87.9953 636.798 136.441 672.873 198.133C708.949 259.825 727 329.289 727 406.5C727 483.711 708.949 553.175 672.873 614.867C636.798 676.559 586.783 725.005 522.881 760.203ZM151.427 672.252C198.382 698.751 250.79 712 308.624 712C366.485 712 418.505 698.751 464.71 672.252C510.916 645.753 547.327 609.21 573.996 562.646C600.665 516.082 614 464.041 614 406.5C614 348.959 600.665 296.918 573.996 250.354C547.327 203.79 510.916 167.247 464.71 140.748C418.505 114.249 366.485 101 308.624 101C250.79 101 198.382 114.249 151.427 140.748C104.472 167.247 67.6726 203.79 41.0035 250.354C14.3345 296.918 1 348.959 1 406.5C1 464.041 14.3345 516.082 41.0035 562.646C67.6726 609.21 104.472 645.753 151.427 672.252Z" fill="white"/>
								<ellipse cx="116" cy="395.5" rx="141" ry="141.5" fill="white"/>
							</svg>
						</div>
						<div className="bg-white w-100 px-2 py-3 p-lg-5">
							<div className="font_20px">
								<span className="font_weight_600"><T>Sign up</T></span>
							</div>
							<TraksConsumer>
								<SignupEmail />
							</TraksConsumer>
							<div className="pt-4 font_13px text-center">
								<strong><T>Already a member?&nbsp;</T><span className="color_green"><a href={LL("/login")} className="text_no_decoration color_green"><T>Login</T></a></span></strong>
							</div>
							<div className="pt-3 color_green text-center font_11px">
								<a href={LL("/terms")} className="color_green text_no_decoration"><T>Terms</T></a> / <a href="/privacy" className="color_green text_no_decoration"><T>Privacy</T></a>
							</div>
						</div>
					</div>
				</Col>
			</Row>
		</Container>
		</React.Fragment>
	);
}
export default SignUpPage;
