import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from "reactstrap";
import { GreenButton } from '../c/ReusableElems';
import { T } from '../traks';
import { OrganizerCard } from "../c/Cards";
import { APIGet } from '../API.js';
import { FrontPageEventsPromotion } from "./ReusableElems";
import { ScrollAnimate } from './ScrollAnimate';

export const BulletCircle = (props) => {
	let svg_fill = "#29b869";
	if(props.svg_fill) svg_fill = props.svg_fill;

	return (
		<svg width="24px" height="24px" fill={svg_fill} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
			<circle cx="6" cy="11" r="4"></circle>
		</svg>
	);
}

export const CheckMark = (props) => {
	let svg_fill = "#29b869";
	if(props.svg_fill) svg_fill = props.svg_fill;

	return (
		<svg width="24px" height="24px" fill={svg_fill} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
			<path d="M 19.581 4.511 C 20.709 3.529 22.577 4.032 22.944 5.416 C 23.102 6.015 22.924 6.647 22.468 7.101 L 11.7 19.408 C 10.919 20.177 9.597 20.2 8.785 19.457 L 1.645 12.931 C 0.505 11.959 0.942 10.225 2.432 9.809 C 3.172 9.601 3.977 9.799 4.505 10.317 L 10.156 15.479 L 19.529 4.565 C 19.546 4.545 19.564 4.528 19.583 4.511 Z"/>
		</svg>
	);
}

export const FrontPageOrganizerHero = () => {
	return (
		<Container fluid className="frontpage_organizer_hero pt-2 pt-lg-5">
			<Container className="px-0 pt-2 pt-lg-3">
				<div className="d-flex flex-column flex-lg-row justify-content-center">
					<div className="d-lg-none">
						<img className="mw-100" alt="3cards" width="100%" height="100%" src="https://static.zevio.com/imgs/org-landing-3-cards-top-820x784px.webp" />
					</div>
					<div className="text-start pt-lg-5 pe-lg-5">
						<h1 className="hero_text_padding pt-5"><T>Your knowledge is strength</T></h1>
						<div className="py-3 font_20px">
							<T>Go live to leverage your knowledge and make your business thrive.</T>
						</div>
						<div className="py-3 py-lg-5">
							<GreenButton className="py-2 w100_mobile" href={'https://mgmt.zevio.com/become_organiser'}><T>Become an organiser</T></GreenButton>
							<div className="py-lg-5">
								<div className="py-lg-5"
									onClick={() =>
										document.getElementById('scroll_to_here_to_learn_more').scrollIntoView({
											behavior: "smooth",
										})
									}
								>
									<div className="pt-4 pb-3 py-lg-5 btn">
										<img className="pe-2" width="18px" height="18px" src="https://static.zevio.com/gfx/ico_learnmore.svg" alt='icsexp' />
										<span className="py-lg-5 font_weight_700">
											<T>Learn more</T>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="pt-2 d-none d-lg-block">
						<img style={{ maxWidth: "760px" }} alt="3cards" width="820px" height="726px" src="https://static.zevio.com/imgs/org-landing-3-cards-top-820x784px.webp"
							srcSet="https://static.zevio.com/imgs/org-landing-3-cards-top-820x784px.webp 1x, https://static.zevio.com/imgs/org-landing-3-cards-top-1640x1568px.webp 2x" />
					</div>
				</div>
			</Container>
		</Container>
	);
}

export const ProfessionalPlatform = () => {
	return (
		<Container fluid className="bg_white pt-lg-5">
			<Container className="p-0">
				<div className="pt-4 d-flex flex-column-reverse flex-lg-row justify-content-center">
					<div className="text-start pt-4 pt-lg-5 pe-lg-5">
						<div className="organizer_landing_headline pt-2 pb-3 text-center"><T>Some things are worth explaining</T></div>
						<div className="organizer_landing_promo_text">
							<T>Zevio is growing your business right. Free yourself from marketing suites, search engine optimization, tracking, endless campaigns, and budget adjustments. Your business just needs … you.</T>
						</div>
						<div className="pb-4 py-lg-5 organizer_landing_promo_text">
							<T>Zevio brings the viewers and the technology for you to go live to an audience that wants your message. You bring your knowledge and your passion. Goodbye endless social media campaigns. Hello customers.</T>
						</div>
					</div>
					<div className="pt-lg-5">
						<img style={{maxWidth: "969px"}}
							className="d-none d-xl-block float-xxl-end" alt="appdesktop" width="969px" height="583px"
							src="https://static.zevio.com/imgs/org-landing-app-and-desktop-1029x649px.webp"
							srcSet="https://static.zevio.com/imgs/org-landing-app-and-desktop-2058x1298px.webp 2x"
						/>

						<img className="d-xl-none w100_mobile" alt="appdesktop" width="100%" height="100%"
							src="https://static.zevio.com/imgs/org-landing-app-and-desktop-1029x649px.webp"
						/>
					</div>
				</div>
			</Container>
		</Container>
	);
}

export const JoinCommunity = () => {

	const [organizerData, setOrganizerData] = useState([]);

	const OrganizerCardsComponent = () => {
		if (organizerData.length === 0) {
			return <div />;
		}
		return (
			<div className="scrolling-wrapper-flexbox overflow_visible">
				{organizerData.map((v, i) => (
					<Col key={i} className="pe-2 py-3">
						<OrganizerCard organizerData={v} />
					</Col>
				))}
			</div>
		);
	}

	useEffect(() => {
		const getOrganizer = () => {
			APIGet('search', {'popular_organizers': true})
				.then((res) => {
					setOrganizerData(res.data.body)
				})
				.catch((error) => {
					console.log(['Error:', error])
				})
		}
		getOrganizer();
	}, []);

	return (
		<Container fluid className="bg_white pt-4">
			<Container className="p-0">
				<div className="d-flex flex-column text-center">
					<div className="pt-3 pt-lg-5">
						<div className="organizer_landing_headline text-center pb-2"><T>Meet those already using Zevio</T></div>
					</div>
					<div className="pb-lg-5 organizer_landing_promo_text">
						<T>Join scientists, athletes, authors, consultants, politicians and organisations already using Zevio</T>
					</div>
					<div id="scroll_to_here_to_learn_more">
						<OrganizerCardsComponent />
					</div>
				</div>
			</Container>
		</Container>
	)
}

export const StreamLive = () => {
	return (
		<Container fluid className="bg_white">
			<Container id='scroll_to_here_to_learn_more p-0'>
				<div className="pt-lg-5 text-center pb-3">
					<span className="organizer_landing_headline"><T>Crisp full HD streams with up to 15.000 viewers</T></span>
				</div>
				<div className="pb-5 text-center organizer_landing_promo_text">
					<span><T>Stream directly to our RTMPS server in up to 1080p60, 8.5 mbps. (Or leave the technical aspects to us and get bespoke assistance from Zevio HQ for your broadcast)</T></span>
				</div>
			</Container>
			<Container fluid>
				<div className="pb-3 pb-lg-5 text-center">
					<img className="d-lg-none w-100 pb-5" alt="livebroadcast" width="100%" height="100%"
						src="https://static.zevio.com/imgs/org-landing-live-2-screens-700x345px-0.webp"
					/>
					<Container className="d-none d-lg-block pb-5 w-100">
						<img className="d-none d-lg-block pb-5 w-100" alt="livebroadcast" width="100%" height="100%"
							src="https://static.zevio.com/imgs/org-landing-live-2-screens-1413x706-0.webp"
							srcSet="https://static.zevio.com/imgs/org-landing-live-2-screens-2826x1412px-0.webp 2x"
						/>
					</Container>
				</div>
			</Container>
		</Container>
	)
}

export const HowItWorks = () => {
	return (
		<Container fluid className="background_dark color_white py-lg-5">
			<Container className="pb-4">
				<div className="py-4 py-lg-5">
					<div className="text-center organizer_landing_headline">
						<T>How it works</T>
					</div>
				</div>
				<div className="align-items-center d-flex flex-column-reverse flex-lg-row py-lg-4">
					<div className="text-center text-lg-start pt-5 pe-lg-5">
						<div className="frontpage_organizer_badge mx-auto mx-lg-0 mb-3">1</div>
						<div className="organizer_landing_headline"><T>Sign up as an organiser</T></div>
						<div className="py-4 organizer_landing_promo_text">
							<div className="pb-3"><T>Sign up today and create your first event.</T></div>
							<div><T>Zevio will start attracting viewers for your event. You should chip in &mdash; our system detects popular events (in terms of registered attendees) and promotes our marketing accordingly.</T></div>
							<div className="py-3"><T>When it is time to go live, simply jump in your studio and hit that live button. We’ll let everyone know you are live.</T></div>
						</div>
					</div>
					<div>
						<ScrollAnimate>
						<img style={{ maxWidth: "968px" }} className="d-none d-lg-block float-xxl-end" alt="howitworks" width="100%" height="100%"
							src="https://static.zevio.com/imgs/org-landing-how-it-works-1029x649px.webp"
							srcSet="https://static.zevio.com/imgs/org-landing-how-it-works-2058x1298px.webp 2x"
						/>
						</ScrollAnimate>
						<ScrollAnimate>
							<img className="d-lg-none w-100" alt="howitworks" width="100%" height="100%" src="https://static.zevio.com/imgs/org-landing-how-it-works-1029x649px.webp" />
						</ScrollAnimate>
					</div>
				</div>
			</Container>
		</Container>
	);
}

export const OrganizeLiveEvent = () => {
	return (
		<Container fluid className="bg_white pt-4 py-lg-5">
			<Container className="py-lg-5">
				<div className="d-flex flex-column flex-lg-row justify-content-center">
					<div className="text-start pe-lg-5">
					<ScrollAnimate>
						<img className="d-none d-lg-block float-xxl-start mt-5" alt="organize2phones" width="100%"
							src="https://static.zevio.com/imgs/org-landing-organise-own-events-668x669.webp"
							srcSet="https://static.zevio.com/imgs/org-landing-organise-own-events-1336x1338.webp 2x"
						/>
						
					</ScrollAnimate>
						<ScrollAnimate>
							<img className="d-lg-none w-100" alt="organize2phones" width="100%"
								src="https://static.zevio.com/imgs/org-landing-organise-own-events-668x669.webp"
							/>
						</ScrollAnimate>
					</div>
					<div className="d-flex flex-column py-4 p-lg-5">
						<div className="frontpage_organizer_badge mx-auto mx-lg-0 mb-3">2</div>
						<div className="text-center text-lg-start organizer_landing_headline"><T>Add your products</T></div>
						<div className="text-center text-lg-start organizer_landing_promo_text py-3">
							<span className="font_weight_500">
								<T>Sell directly to your viewers. You create your product - we handle the paperwork and let you know where to send your product.</T>
							</span>
						</div>
						<div className="py-2 d-flex flex-row">
							<div className="my-auto"><CheckMark/></div>
							<div className="ps-3 font_13px"><T>Choose between Dollars, Euros and Kroner (Norway, Denmark and Sweden).</T></div>
						</div>
						<div className="py-2 d-flex flex-row">
							<div className="my-auto"><CheckMark/></div>
							<div className="ps-3 font_13px"><T>Go live directly to your customers smart phones using the Zevio App.</T></div>
						</div>
						<div className="py-2 d-flex flex-row">
							<div className="my-auto"><CheckMark/></div>
							<div className="ps-3 font_13px"><T>Create as many products as you like.</T></div>
						</div>
						<div className="py-2 d-flex flex-row">
							<div className="my-auto"><CheckMark/></div>
							<div className="ps-3 font_13px"><T>Present to up to 15.000 viewers in full HD.</T></div>
						</div>
						<div className="py-2 d-flex flex-row">
							<div className="my-auto"><CheckMark/></div>
							<div className="ps-3 font_13px"><T>No credit card needed.</T></div>
						</div>
						<div className="py-2 d-flex flex-row">
							<div className="my-auto"><CheckMark/></div>
							<div className="ps-3 font_13px"><T>No cure, no pay. Zevio takes 25% in commission. We only make money when you do.</T></div>
						</div>
					</div>
				</div>
			</Container>
		</Container>
	);
}

export const EngageWithAudience = () => {
	return (
		<Container fluid className="background_dark color_white position-relative p-0">
			<Container className="p-0 py-5">
				<div className="d-flex flex-column-reverse flex-lg-row justify-content-start organizer_landing_promo_text">
					<div className="d-flex flex-column py-5 py-lg-5 w100xs_50 px-3 px-lg-0">
						<div className="frontpage_organizer_badge mb-4 mx-auto mx-lg-0">3</div>
						<div className="organizer_landing_headline text-center text-lg-start"><T>Washington, Copenhagen, or Kyoto</T></div>
						<div className="organizer_landing_promo_text py-4 text-center text-lg-start">
							<span className="font_weight_500">
								<T>All you need is a decent computer and reliable internet. Your show will be available anywhere on Earth - with as little as 5-second latency.</T>
							</span>
						</div>
						<div className="py-2 d-flex flex-row">
							<div className="my-auto"><CheckMark/></div>
							<div className="ps-3"><T>Broadcast in full HD in up to 60 frames per second to 15.000 people</T></div>
						</div>
						<div className="py-2 d-flex flex-row">
							<div className="my-auto"><CheckMark/></div>
							<div className="ps-3"><T>Go live directly from your browser (or use our studios)</T></div>
						</div>
						<div className="py-2 d-flex flex-row">
							<div className="my-auto"><CheckMark/></div>
							<div className="ps-3"><T>Download your broadcast and use elsewhere</T></div>
						</div>
						<div className="py-2 d-flex flex-row">
							<div className="my-auto"><CheckMark/></div>
							<div className="ps-3"><T>Fully adaptive bitrate streaming to your viewers for smooth playback</T></div>
						</div>
						<div className="py-2 d-flex flex-row">
							<div className="my-auto"><CheckMark/></div>
							<div className="ps-3"><T>Already a pro-streamer? Stream directly to our RTMPS.</T></div>
						</div>
					</div>
					<ScrollAnimate>
						<img style={{marginTop: 70}} className="d-none d-lg-block float-xxl-end" alt="anywhere in the world" width="550px"
							src="https://static.zevio.com/imgs/org-landing-engage-1102x1080.webp"
							srcSet="https://static.zevio.com/imgs/org-landing-engage-1102x1080.webp 2x" />
					</ScrollAnimate>
					<ScrollAnimate>
						<img className="d-lg-none mx-4" style={{width: "85%"}} alt="anywhere in the world"
							src="https://static.zevio.com/imgs/org-landing-engage-1102x1080.webp"
						/>
					</ScrollAnimate>
				</div>
			</Container>
		</Container>
	);
}

export const SellYourProducts = () => {
	return (
		<Container fluid className="bg_white position-relative p-0">
			<Container fluid className="py-4 py-lg-5 ps-lg-5">
				<div className="d-flex flex-column flex-lg-row justify-content-center px-3 ps-lg-5">
					<div className="d-none d-lg-block text-start p-xl-5">
						<ScrollAnimate>
							<img className="float-xxl-start" alt="organize2phones" width="750px" height="434px" src="https://static.zevio.com/imgs/org-landing-products-949x559-2.webp"
								srcSet="https://static.zevio.com/imgs/org-landing-products-1898x1118-2.webp 2x" />
						</ScrollAnimate>
					</div>
					<ScrollAnimate>
						<img className="w-100 d-lg-none" alt="organize2phones" width="100%" height="100%" src="https://static.zevio.com/imgs/org-landing-products-949x559-2.webp" />
					</ScrollAnimate>
					<div className="d-flex flex-column pt-3 p-lg-5 organizer_landing_promo_text">
						<div className="frontpage_organizer_badge mb-3 mx-auto mx-lg-0">4</div>
						<div className="text-center text-lg-start organizer_landing_headline"><T>Repeat</T></div>
						<div className="text-center text-lg-start font_weight_500 py-4">
							<T>Retain your users and keep them hooked. We notify your followers whenever you create a new event.</T>
						</div>
						<div className="py-2 d-flex flex-row">
							<div className="my-auto"><BulletCircle /></div>
							<div><T>Set up your next event - and keep selling.</T></div>
						</div>
						<div className="py-2 d-flex flex-row">
							<div className="my-auto"><BulletCircle /></div>
							<div><T>Grow your audience - even when you are sleeping.</T></div>
						</div>
						<div className="py-2 d-flex flex-row">
							<div className="my-auto"><BulletCircle /></div>
							<div><T>Get a sales channel that keeps funnelling in customers.</T></div>
						</div>
						<div className="py-2 d-flex flex-row">
							<div className="my-auto"><BulletCircle /></div>
							<div><T>Bespoke broadcasting assistance.</T></div>
						</div>
						<div className="py-2 d-flex flex-row">
							<div className="my-auto"><BulletCircle /></div>
							<div><T>Manage all aspects of the process.</T></div>
						</div>
					</div>
				</div>
			</Container>
		</Container>
	);
}

export const EventsPromotion = () => {
	return (
		<Container fluid className="background_dark text-white overflow_hidden">
			<Container>
				<div className="pt-4 pt-lg-5">
					<div className="text-center">
						<div className="organizer_landing_headline"><T>Meet some of the talent already using Zevio</T></div>
						<div className="organizer_landing_promo_text pt-3">
							<T>Retain your users and keep them hooked. We notify your followers whenever you create a new event.</T>
						</div>
					</div>
				</div>
			</Container>
			<Container fluid className="d-none d-lg-block py-5 overflow_hidden">
				<img alt="speakers" className="w-100" width="100%" height="100%" src="https://static.zevio.com/imgs/org-landing-others-who-use-1920x378-0.webp"
					srcSet="https://static.zevio.com/imgs/org-landing-others-who-use-3840x756px-0.webp 2x" />
			</Container>
			<Container fluid className="px-0 pt-4 d-lg-none">
				<img className="w-100" alt="speakers" width="100%" height="100%" src="https://static.zevio.com/imgs/org-landing-others-who-use-744x378px-0.webp" />
			</Container>
			<Container>
				<Row className="pt-4 pt-lg-5">
					<Col className="text-center px-2 px-lg-4 pb-4" lg="4">
						<div className="organizer_promo_numbers"><T>+100.000</T></div>
						<div className="organizer_promo_numbers_text"><T>hours streamed</T></div>
					</Col>
					<Col className="text-center px-2 px-lg-4 pb-4" lg="4">
						<div className="organizer_promo_numbers"><T>+1.000.000</T></div>
						<div className="organizer_promo_numbers_text"><T>viewers</T></div>
					</Col>
					<Col className="text-center px-2 px-lg-4" lg="4">
						<div className="organizer_promo_numbers">+1.000</div>
						<div className="organizer_promo_numbers_text"><T>events</T></div>
					</Col>
				</Row>
			</Container>
			<FrontPageEventsPromotion />
		</Container>
	);
}
