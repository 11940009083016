import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner } from "reactstrap";

const ExploreCategoriesDropdown = (props) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const titleName = props.children;
	let eventDataArray = [];

	if (props.Data) {
		for (const ed of Object.keys(props.Data)) {
			eventDataArray.push({ ...props.Data[ed], 'Id': parseInt(ed) });
		}
		if (props.oldEvents)
			eventDataArray.sort((x, y) => y.EventCountPast - x.EventCountPast);
		else
			eventDataArray.sort((x, y) => y.EventCountFuture - x.EventCountFuture);
		if (props.premium)
			eventDataArray.sort((x, y) => y.EventCountPremium - x.EventCountPremium);
	}

	const clicked = (key, name) => {
		let clickData = ([key, name]);
		props.setVal(clickData);
	}

	const dropdownItemInner = (k, i, disabledMeansRemoved) => {
		let eventCount = k.EventCountPast + k.EventCountFuture;
		if(props.premium)
			eventCount = k.EventCountPremium;
		let disabled = false;
		if (k.Id === 0) {
			return null;
		}
		if (eventCount === 0) {
			if (disabledMeansRemoved) {
				return null;
			}
			disabled = true;
		}
		return (
			<DropdownItem
				className="opposites"
				disabled={disabled}
				key={i}
				onClick={() => clicked(k.Id, k.Name)}
			>
				<span className="font_weight_500 text-capitalize">{k.Name}</span>{props.disableCount ? null : <span className="ps-2 grey_light align_right text-capitalize">({eventCount})</span> }
			</DropdownItem>
		);
	}

	const DropdownComponent = () => {
		if (props.Data && eventDataArray.length) {
			return (
				<Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)} >
					<DropdownToggle className="btn_white_dark_border dropdown_border text-start w100_mobile bg-white">
						<div className="d-flex flex-row justify-content-between py-2">
							<div className="pe-lg-5 text-capitalize">{titleName}</div>
							<img alt="caret" width="12" height="18" src={dropdownOpen ?  "https://static.zevio.com/gfx/ico_dropdown_toggle_open.svg" : "https://static.zevio.com/gfx/ico_dropdown_toggle_closed.svg"} className="dropdown_toggler pt-1" />
						</div>
					</DropdownToggle>
					<DropdownMenu>
						{eventDataArray.map((k, i) => dropdownItemInner(k, i, props.disabledMeansRemoved ? true : false))}
					</DropdownMenu>
				</Dropdown>
			);
		} else {
			return <div className='text-center'>Loading data... <Spinner className="p-2" color="success" /></div>;
		}
	}

	return (
		<DropdownComponent />
	);
};

export default ExploreCategoriesDropdown;
