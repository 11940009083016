import React, { useContext } from "react";
import { T } from "../../traks";
import OrderContext from "../Amopus/useOrder";
import CheckoutContext from "../Amopus/useCheckout";

const Receipt = () => {
	const { orderDetails, vat, showCurrency, orderId} = useContext(OrderContext);
	const {invoiceId} = useContext(CheckoutContext);
	let amount = 0;
	let vatamount = 0;
	orderDetails.lines.forEach(item => {
		amount += item.unitpriceinclvat * item.quantity
		vatamount += (item.unitpriceinclvat - item.unitprice) * item.quantity
	});
	return (
		<div className="pt-3">
			{orderId?<div><p className="fw-bold"><T>Order id: {orderId}</T></p></div>:null}
			{invoiceId?<div><p className="fw-bold"><T>Invoice id: {invoiceId}</T></p></div>:null}
			<LineItem {...{ item: orderDetails.lines[0], vat, showCurrency }} />
			<Delivery />
			<hr className="my-1"/>
			<div className="d-flex flex-row justify-content-between">
				<T>{vat.repr} ({vat.rate}% inclusive)</T>
				<div>
					{showCurrency.format(vatamount / 100)}
				</div>
			</div>
			<div className="d-flex flex-row justify-content-between">
					<b>
						<T>Total</T>
					</b>
				<div>
					<b>{showCurrency.format(amount / 100)}</b>
				</div>
			</div>
			<hr className="m-0"/>
			<hr className="my-1"/>
			<DeliveryAddress/>
		</div>
	);
};

const LineItem = ({ item, vat, showCurrency }) => (
	<div>
		<div>
		{item.quantity} &#215; <i>{item.description}</i> 
		</div>
		<div>
			<div className="d-flex flex-row justify-content-between fw-lighter">
				<div>
					<T>Net</T>
				</div>
				<div>{showCurrency.format(item.quantity * item.unitprice / 100)}</div>
			</div>
			<div className="d-flex flex-row justify-content-between fw-lighter">
				<div>
					<T>{vat.repr} ({vat.rate}% inclusive)</T>
				</div>
				<div>{showCurrency.format(item.quantity *  (item.unitpriceinclvat - item.unitprice) / 100)}</div>
			</div>
			<div className="d-flex flex-row justify-content-between">
				<div>
					<T>Gross</T>
				</div>
				<div>{showCurrency.format(item.quantity * item.unitpriceinclvat / 100)}</div>
			</div>
		</div>
	</div>
);

const Delivery = () => {
	const { orderDetails, showCurrency } = useContext(OrderContext);
	let item = orderDetails.lines.slice(-1)[0];
	if (item.id !== "shipping") return null;
	return (
				<div className="d-flex flex-row justify-content-between mt-3">
					<div>
							<T>Delivery</T>
					</div>
					<div>{showCurrency.format(item.unitpriceinclvat / 100)}</div>
				</div>
	);
};

const DeliveryAddress = () => {
	const { orderDetails } = useContext(OrderContext);
	let item = orderDetails.lines.slice(-1)[0];
	let {street, zip, city, country} = orderDetails.shippingaddress
	let validAddress = !!street && !!zip && !!city && !!country
	if (!validAddress || item.id !== "shipping") return null; 
	return (
		<div className="my-3">
				<div>
					<T>Your package will be delivered to</T>
				</div>
				<div className="mx-2">
					<i>{street}, {zip} {city}, {country}</i>
				</div>
		</div>
	);
};

export default Receipt;
