import React, { useEffect, useState } from "react";
import ZevioNav from "../c/ZevioNav";
import { FrontPageHero, FrontPageEventsPromotion, FrontPageCoreServicesPromotion, FrontPageRandomEvents } from "../c/ReusableElems";
import Footer from "../c/Footer";
import { FAQ } from "../c/FAQ";
import { TraksConsumer } from '../traks';
import { APIGet } from '../API.js';
import { url_lang } from "../languages";

const ZevioFrontPage = () => {
	const [ frontEvents, setFrontEvents ] = useState([]);
	useEffect(() => {
		APIGet('search', { 'action': 'frontpageevents', 'lang': url_lang().id })
			.then((res) => {
				setFrontEvents(res.data.body)
			})
	}, []);
	return (
		<>
			<ZevioNav />
			<FrontPageHero heroEvent={frontEvents[frontEvents.length-1]}/>
			<FrontPageRandomEvents randomEvents={frontEvents} />
			<FrontPageEventsPromotion />
			<FrontPageCoreServicesPromotion />
			<TraksConsumer>
				<FAQ />
			</TraksConsumer>
			<Footer />
		</>
	);
};

export default ZevioFrontPage;
