import React, {  useContext } from "react";
import { Modal, ModalBody } from "reactstrap";
import { T, TraksConsumer } from "../../../traks";
import ImgContainer from "./ImgContainer";
import SignupContext, { stateComponents } from "../useSignup";
import EventCtx from "../useEvent";
import I18N from "../../../I18N";
import { url_lang } from "../../../languages";

export const SignUpModalButton = () => {
	const {  setModalVisibility, accessProduct,  alreadySigned, signupComplete } = useContext(SignupContext);
	const { liveUrl, redirectLiveURL } = useContext(EventCtx)
	const currency = url_lang().currency
	
	const payText = accessProduct?.price ? <T>Buy access for {I18N.floatval_unit(accessProduct.price[currency], 2, currency.toLocaleUpperCase())}</T>:null;
	if (alreadySigned || signupComplete) return <button
			onClick={redirectLiveURL}
			type="signup"
			className="font_16px btn btn_green px-lg-5 py-lg-2 w100_mobile font_weight_700"
		>
			<T>You are signed up for this stream</T>
		</button>;
	return <button
				onClick={() => setModalVisibility(true)}
				type="signup"
				className="font_16px btn btn_green px-lg-5 py-lg-2 w100_mobile font_weight_700"
			>
				{payText ? payText :liveUrl ? <T>Watch now</T>: <T>Sign up for event</T>}
			</button>
}

const SignupModal = () => {
	const { modalVisibility, setModalVisibility, state, reset, signupComplete } = useContext(SignupContext);
	const {redirectLiveURL} = useContext(EventCtx)
	
	const closeIconClick = () => {
		setModalVisibility(false);
		if (signupComplete) redirectLiveURL();
		reset();
	};
	const closeIcon = (
		<img
			className="btn p-1 rounded-circle"
			style={{
				position: "absolute",
				zIndex: 1000,
				right: 8,
				top: 8,
				backgroundColor: "white",
			}}
			onClick={() => closeIconClick()}
			src="https://static.zevio.com/gfx/ico_close.svg"
			alt="icoClose"
		/>
	);
	return (
			<Modal
				fade={false}
				style={{ maxWidth: 430 }}
				className="event_signup_modal_container"
				isOpen={modalVisibility}
				centered
			>
				<ModalBody style={{ padding: 0 }} className="pb-3">
					{closeIcon}
					<div className="event_signup_modal w-100">
						<ImgContainer />
						<div className="px-4 py-1">
							<TraksConsumer>{stateComponents[state]}</TraksConsumer>
						</div>
					</div>
				</ModalBody>
			</Modal>
	);
};

export default SignupModal;
