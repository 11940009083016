import React from 'react';
import { T } from './traks';
import { check_date } from './misc';

var I18N = new (class {
	duration(seconds) {
		return <T context="1h 2m" deps={[seconds]}></T>
	}

	floatval(val, n_decimals) {
		return <T context="123,456.78" deps={[val, n_decimals]}></T>;
	}

	floatval_unit(val, n_decimals, unit, currency_css = "") {
		return <T context="DKK 123,456.78" deps={[val, n_decimals, unit, currency_css]}></T>;
	}

	intval(val) {
		return this.floatval(val, 0);
	}

	intval_unit(val, unit) {
		return this.floatval_unit(val, 0, unit);
	}

	short_weekday(weekday_bewteen_0_and_6_monday_to_sunday) {
		return <T context="Mon" deps={[weekday_bewteen_0_and_6_monday_to_sunday]}></T>;
	}

	month(month_between_0_and_11) {
		return <T context="January" deps={[month_between_0_and_11]}></T>;
	}

	date(d) {
		d = check_date(d);
		const timestamp = d.getTime() / 1000;
		return <T context="1 January 2018" deps={[timestamp]}></T>;
	}

	date_short(d) {
		d = check_date(d);
		const timestamp = d.getTime() / 1000;
		return <T context="1/1/18" deps={[timestamp]}></T>;
	}

	datetime_short(d) {
		const timestamp = d.getTime() / 1000;
		return <T context="Fri, Jan 1 4:20 PM" deps={[timestamp]}></T>;
	}

	datetime_shorter(d) {
		const timestamp = d.getTime() / 1000;
		return <T context="1/1 4:20 PM" deps={[timestamp]}></T>;
	}
})();

export default I18N;
