import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { APIGet } from '../../API';

const AdminInternOrganizers = () => {
	const [ updateTrigger, setUpdateTrigger ] = useState(0);
	const [ organizers, setOrganizers ] = useState(undefined);

	useEffect(() => {
		const timer = setTimeout(() => {
			setUpdateTrigger( (ot) => ot + 1 );
		}, 300000);

		return () => clearTimeout(timer);
	}, [])

	const sortOrganizers = (sort_key) => {
		setOrganizers( (oo) => {
			let tmp = [...oo];
			tmp.sort((a,b) => b.Likes[sort_key] - a.Likes[sort_key]);
			return tmp;
		});
	}

	useEffect( () => {
		APIGet('admin', {'action': 'get_organizer_stats'}).then( (r) => {
			let orgs = r.data.body.organizers;
			orgs.sort((a,b) => b.Likes.total - a.Likes.total);
			setOrganizers(orgs);
		});
	}, [updateTrigger]);

	if(!organizers)
		return null;
	return (<>
		<div className='py-4 text-center'>
			<h3>Zevio Organizers</h3>
			<span style={{color: '#888'}}>(click Likes-* to sort)</span>
		</div>
		<Container className='py-2 bg-white' style={{borderRadius: '8px'}}>
			<Row className='px-0'>
				<Col className='px-0'>
					<table className='table table-striped'>
						<thead>
							<tr className='text-center'>
								<th scope='col' className='py-2'>Id</th>
								<th scope='col' className='text-start'>Name</th>
								<th scope='col'><span className='cursor-pointer pe-3' onClick={() => sortOrganizers('today')}>Likes today</span></th>
								<th scope='col'><span className='cursor-pointer pe-3' onClick={() => sortOrganizers('month')}>Likes month</span></th>
								<th scope='col'><span className='cursor-pointer pe-3' onClick={() => sortOrganizers('year')}>Likes year</span></th>
								<th scope='col'><span className='cursor-pointer pe-3' onClick={() => sortOrganizers('total')}>Likes total</span></th>
							</tr>
						</thead>
						<tbody>
							{organizers.map( (o, i) => (
							<tr key={'row_' + i} className='text-center'>
								<td className='py-2'>{o.Id}</td><td className='text-start'>{o.Name}</td><td>{o.Likes.today}</td><td>{o.Likes.month}</td><td>{o.Likes.year}</td><td>{o.Likes.total}</td>
							</tr>
							))}
						</tbody>
					</table>
				</Col>
			</Row>
		</Container>
	</>);
}

export default AdminInternOrganizers;
