import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import PrivacyPolicy from "./PrivacyPolicy";
import { BuyIncognitoButton } from "./BuyIncognitoTicket";
import { BuyEventTicketButton } from "./BuyEventTicket";
import { MeContext } from "../../../MeContext";
import SignupContext from "../useSignup";
import { FeedbackInput } from "../../Input";
import { T } from "../../../traks";
import { LL } from "../../../Routes";
import EventCtx from "../useEvent";
import SubscribeNewsletter from "./SubscribeNewsletter";

const Signup = ({ render_static }) => {
	const { me } = useContext(MeContext);
	const {
		setUserInfo,
		userInfo,
		hasAcceptedPolicy,
		setTransitionToken,
		accessProduct,
	} = useContext(SignupContext);
	const { eventData } = useContext(EventCtx);
	const [missingPP, setMissingPP] = useState(false);
	const [showInvalid, setShowInvalid] = useState(false);
	const formRef = useRef()
	const fields = getFieldsForSignupFormType(eventData?.SignupFormType)
	// Fill in form with data from MeContext if it exists
	useEffect(()=>{
		if (formRef.current && me) {
			Object.keys(fields).forEach( k => {
				if (!formRef.current[k]) {console.log(formRef.current)}
				else if (me[k]) formRef.current[k].value = me[k]
				else if (k==="name") {
					formRef.current[k].value = me.firstName + " " + me.lastName
				} else if (k==="zip") {
					formRef.current.zipCode.value	= me.zipCode
				}
			})
		}
	},[fields, formRef, me])

	const fieldIsMissing = useCallback(
		(field) => showInvalid && !userInfo[field],
		[showInvalid, userInfo]
	);
	const noMissingFields = useCallback(
		() => !hasMissingField(fields, userInfo),
		[fields, userInfo]
	);
	const validateForm = (e) => {
		e.preventDefault();
		let form = e.nativeEvent.target;
		let address = Object.values(form).filter(o => o.id && !o.id.includes("Check")).map(o => ({[o.id]:o.value})).reduce((acc,curr) => ({...acc, ...curr}))
		setUserInfo((p) => ({
			...p, ...address
		}));
		let isValid = Object.values(form).filter(o => o.id && !o.id.includes("Check")).reduce((acc,curr) => !!curr.value && acc, true)
		setShowInvalid(p=> p || !isValid)
		if (!isValid) return
		if (form.agreeTermsCheck) {
			setMissingPP(!form.agreeTermsCheck.checked)
			setShowInvalid(p => p|| !form.agreeTermsCheck.checked)
			if (!form.agreeTermsCheck.checked) return
		}
		// All boxes checked and fields filled!
		let token = Object.values(form).find(o => o.type==="submit").value
		setTransitionToken(token)
		
	};


	if (noMissingFields() && hasAcceptedPolicy === "previously") {
		setTransitionToken("Register");
	}
	

	return (
		<div className="event_signup_modal w-100">
			<div>
				<div>
					<form onSubmit={validateForm} ref={formRef} >
						{fields?.name.show && (
							<FeedbackInput
								autofocus={!userInfo?.name.length}
								type="text"
								id="name"
								name="name"
								className="pt-3 w-100"
								placeholder={render_static(<T>Name*</T>)}
								invalid={fieldIsMissing("name")}
								errorMessage={render_static(<T>Please insert name</T>)}
							/>
						)}
						{fields?.email.show && (
							<FeedbackInput
								name="email"
								type="email"
								id="email"
								className="pt-3 w-100"
								placeholder={render_static(<T>E-mail*</T>)}
								invalid={fieldIsMissing("email")}
								errorMessage={render_static(<T>Invalid email address</T>)}
							/>
						)}
						{fields?.phoneNo.show && (
							<FeedbackInput
								className="pt-3 w-100"
								name="phone"
								type="tel"
								id="phoneNo"
								placeholder={`${render_static(<T>Phone no.</T>)}${
									fields.phoneNo.req ? "*" : ""
								}`}
								errorMessage={render_static(
									<T>Please insert a phone number</T>
								)}
								invalid={fieldIsMissing("phoneNo")}
							/>
						)}
						{fields?.zip.show && (
							<FeedbackInput
								className="pt-3 w-100"
								name="postal-code"
								id="zipCode"
								type="number"
								placeholder={`${render_static(<T>Zip/Postal Code</T>)}${
									fields.zip.req ? "*" : ""
								}`}
								invalid={fieldIsMissing("zipCode")}
								errorMessage={render_static(<T>Invalid Zip/Postal Code</T>)}
							/>
						)}
						{fields?.company.show && (
							<FeedbackInput
								className="pt-3 w-100"
								name="organisation-name"
								id="company"
								type="text"
								placeholder={`${render_static(<T>Company/Organization*</T>)}${
									fields.company.req ? "*" : ""
								}`}
								errorMessage={render_static(<T>Please insert a company</T>)}
								invalid={fieldIsMissing("company")}
							/>
						)}
						{fields?.jobTitle.show && (
							<FeedbackInput
								className="pt-3 w-100"
								name="organisation-title"
								id="jobTitle"
								type="text"
								placeholder={`${render_static(<T>Job Title*</T>)}${
									fields.jobTitle.req ? "*" : ""
								}`}
								errorMessage={render_static(<T>Please insert job title</T>)}
								invalid={fieldIsMissing("jobTitle")}
							/>
						)}
						<PPChecker missingPP={missingPP} setMissingPP={setMissingPP} />
						<SubscribeNewsletter />
						{accessProduct ? (
							<BuyEventTicketButton/>
						) : (
							<SignupButton/>
						)}
						{!me && !accessProduct && <BuyIncognitoButton />}
					</form>
				</div>
			</div>
		</div>
	);
};

const SignupButton = () => {
	return <button
					type="submit"
					value="Register"
					className="text-white font_15px btn btn_green w-100 mt-3 mb-3 border-0"
				>
					<T>Sign up for event</T>
				</button>
}

const hasMissingField = (reqs, vals) => {
	if (!reqs || !vals) return true;
	let keys= Object.keys(reqs)
	for (let i=0; i<keys.length; i++) {
		let curr = keys[i]
		if (isMissingField(reqs[curr].req, vals[curr]))
			return curr
	}
	return false	
};
const isMissingField = (req, val) => {
	if (req && !val) {
		return true;
	} else return false;
};

const getFieldsForSignupFormType = (t) => {
 return signupFormTypeFields[t] ? signupFormTypeFields[t] : signupFormTypeFields.simple
}

const signupFormTypeFields = {
	simple: {
		name: { show: true, req: true },
		email: { show: true, req: true },
		phoneNo: { show: false, req: false },
		zip: { show: false, req: false },
		jobTitle: { show: false, req: false },
		company: { show: false, req: false },
	},
	simple_zip: {
		name: { show: true, req: true },
		email: { show: true, req: true },
		phoneNo: { show: false, req: false },
		zip: { show: true, req: true },
		jobTitle: { show: false, req: false },
		company: { show: false, req: false },
	},
	b2b: {
		name: { show: true, req: true },
		email: { show: true, req: true },
		phoneNo: { show: true, req: true },
		zip: { show: true, req: true },
		jobTitle: { show: true, req: true },
		company: { show: true, req: true },
	},
};
/*
const SignupOnlyMissingPP = () => {
	const { setHasAcceptedPolicy, accessProduct } = useContext(SignupContext);
	return (
		<div className="mt-3">
			<PrivacyPolicy />
			<SubscribeNewsletter/>
			{accessProduct ? <BuyEventTicketButton onClick={() => setHasAcceptedPolicy(true)}/> : <SignupButton />}
		</div>
	);
};
*/

const PPChecker = ({missingPP, setMissingPP}) => {
	const { hasAcceptedPolicy, setHasAcceptedPolicy } = useContext(SignupContext);
	if (hasAcceptedPolicy === "previously") {
		setMissingPP(false)
		return null;}
	return (
		<div>
			<label className={`pt-4 d-flex align-items-center pb-2  `}>
				<input
					className="checkbox_green"
					id="agreeTermsCheck"
					type="checkbox"
					onChange={(e) => {
						setHasAcceptedPolicy(e.nativeEvent.target.checked);
					}}
				/>
				<div className={`px-1 mx-1 signup_agree_terms_text ${missingPP? "bg-danger bg-opacity-10 rounded":""}`}>
					<T>I agree to the&nbsp;<a className="color_green" href={LL("/terms")}>terms</a> and <a className="greentext" href={LL("/privacy")}>privacy policy</a></T>
				</div>
			</label>
			<PrivacyPolicy />
		</div>
	);
};

export default Signup;
