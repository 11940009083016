import React from "react";
import { T } from "../../traks";
import { url_lang } from "../../languages";

export const amopus_config = () => {
	let API_KEY = "Y2hxcTZnZGZmYmxzNzVqaDQxMjA=";
	let BASE_URL = "https://test-api.amopus.com"
	let AMOPUS_API = "https://test-api.amopus.com/order?companyId=Zevio";
	let AMOPUS_CHECKOUT = `wss://checkout-test.amopus.com?authorization=${API_KEY}&companyId=Zevio`;

	if (window.location.hostname === "zevio.com") {
		API_KEY = "Y2g5cTFwdjdsdGRzNzBtczNyNWc=";
		BASE_URL = "https://api.amopus.com"
		AMOPUS_CHECKOUT = `wss://checkout.amopus.com?authorization=${API_KEY}&companyId=Zevio`;
		AMOPUS_API = "https://api.amopus.com/order?companyId=Zevio";
	}
	return { API_KEY, AMOPUS_API, AMOPUS_CHECKOUT, BASE_URL };
};

const get_country_info = () => {
	let country = guess_country();
	let currency = url_lang().currency
	if (countries[country]) {
		
		return {...countries[country], currency};
	} else {
		return {...countries.other, currency};
	}
};

// If we can't guess which EU-country it is we return 'other'
const guess_country = () => {
	const { timeZone, locale } = Intl.DateTimeFormat().resolvedOptions();
	if (timezones[timeZone]) {
		let countryCodes = timezones[timeZone];
		let [countryCode] = locale.split("-").slice(-1);
		if (countryCodes.includes(countryCode)) return countryCode;
		return countryCodes.slice(-1)[0];
	}
	return "other";
};

const timezones = {
	"Europe/Copenhagen": ["DK"],
	"Europe/Stockholm": ["SE"],
	"Europe/Oslo": ["NO"],
	"Europe/Amsterdam": ["NL"],
	"Europe/Andorra": ["AD"],
	"Europe/Astrakhan": ["RU"],
	"Europe/Athens": ["GR"],
	"Europe/Belfast": ["GB"],
	"Europe/Belgrade": ["RS", "BA", "HR", "ME", "MK", "SI"],
	"Europe/Berlin": ["DE", "DK", "NO", "SE", "SJ"],
	"Europe/Bratislava": ["SK"],
	"Europe/Brussels": ["BE", "LU", "NL"],
	"Europe/Bucharest": ["RO"],
	"Europe/Budapest": ["HU"],
	"Europe/Busingen": ["DE"],
	"Europe/Chisinau": ["MD"],
	"Europe/Dublin": ["IE"],
	"Europe/Gibraltar": ["GI"],
	"Europe/Guernsey": ["GG"],
	"Europe/Helsinki": ["FI", "AX"],
	"Europe/Isle_of_Man": ["IM"],
	"Europe/Istanbul": ["TR"],
	"Europe/Jersey": ["JE"],
	"Europe/Kaliningrad": ["RU"],
	"Europe/Kiev": ["UA"],
	"Europe/Kirov": ["RU"],
	"Europe/Kyiv": ["UA"],
	"Europe/Lisbon": ["PT"],
	"Europe/Ljubljana": ["SI"],
	"Europe/London": ["GB", "GG", "IM", "JE"],
	"Europe/Luxembourg": ["LU"],
	"Europe/Madrid": ["ES"],
	"Europe/Malta": ["MT"],
	"Europe/Mariehamn": ["AX"],
	"Europe/Minsk": ["BY"],
	"Europe/Monaco": ["MC"],
	"Europe/Moscow": ["RU"],
	"Europe/Nicosia": ["CY"],
	"Europe/Paris": ["FR", "MC"],
	"Europe/Podgorica": ["ME"],
	"Europe/Prague": ["CZ", "SK"],
	"Europe/Riga": ["LV"],
	"Europe/Rome": ["IT", "SM", "VA"],
	"Europe/Samara": ["RU"],
	"Europe/San_Marino": ["SM"],
	"Europe/Sarajevo": ["BA"],
	"Europe/Saratov": ["RU"],
	"Europe/Simferopol": ["RU", "UA"],
	"Europe/Skopje": ["MK"],
	"Europe/Sofia": ["BG"],
	"Europe/Tallinn": ["EE"],
	"Europe/Tirane": ["AL"],
	"Europe/Tiraspol": ["MD"],
	"Europe/Ulyanovsk": ["RU"],
	"Europe/Uzhgorod": ["UA"],
	"Europe/Vaduz": ["LI"],
	"Europe/Vatican": ["VA"],
	"Europe/Vienna": ["AT"],
	"Europe/Vilnius": ["LT"],
	"Europe/Volgograd": ["RU"],
	"Europe/Warsaw": ["PL"],
	"Europe/Zagreb": ["HR"],
	"Europe/Zaporozhye": ["UA"],
	"Europe/Zurich": ["CH", "DE", "LI"],
};

const countries = {
	AT: {vat:{type:"Sales tax", repr:"USt - Austria",rate:20}, code: "AT", name: <T>Austria</T>, currency: "EUR" },
	BE: {vat:{type:"Sales tax", repr:"TVA - Belgium",rate:21}, code: "BE", name: <T>Belgium</T>, currency: "EUR" },
	BG: {vat:{type:"Sales tax", repr:"ДДС - Bulgaria",rate:20}, code: "BG", name: <T>Bulgaria</T>, currency: "EUR" },
	HR: {vat:{type:"PDV", repr:"Croatia - PDV",rate:25}, code: "HR", name: <T>Croatia</T>, currency: "EUR" },
	CZ: {vat:{type:"DPH", repr:"DPH",rate:21}, code: "CZ", name: <T>Czechia</T>, currency: "EUR" },
	CY: {vat:{type:"Sales tax", repr:"ΦΠΑ - Cyprus",rate:19}, code: "CY", name: <T>Cyprus</T>, currency: "EUR" },
	DK: {vat:{type:"Moms", repr:"Moms - Danmark",rate:25}, code: "DK", name: <T>Denmark</T>, currency: "DKK" },
	EE: {vat:{type:"Km", repr:"Km - Estonia",rate:20}, code: "EE", name: <T>Estonia</T>, currency: "EUR" },
	FI: {vat:{type:"ALV", repr:"ALV - Finland",rate:24}, code: "FI", name: <T>Finland</T>, currency: "EUR" },
	FR: {vat:{type:"TVA", repr:"TVA - France",rate:20}, code: "FR", name: <T>France</T>, currency: "EUR" },
	DE: {vat:{type:"MwSt", repr:"MwSt - Germany",rate:19}, code: "DE", name: <T>Germany</T>, currency: "EUR" },
	GR: {vat:{type:"ΦΠΑ", repr:"ΦΠΑ - Greece",rate:24}, code: "GR", name: <T>Greece</T>, currency: "EUR" },
	HU: {vat:{type:"ÁFA", repr:"ÁFA - Hungary",rate:27}, code: "HU", name: <T>Hungary</T>, currency: "EUR" },
	IE: {vat:{type:"VAT", repr:"VAT - Ireland",rate:23}, code: "IE", name: <T>Ireland</T>, currency: "EUR" },
	IT: {vat:{type:"IVA", repr:"IVA - Italy",rate:22}, code: "IT", name: <T>Italy</T>, currency: "EUR" },
	LV: {vat:{type:"PVN", repr:"PVN - Latvia",rate:21}, code: "LV", name: <T>Latvia</T>, currency: "EUR" },
	LT: {vat:{type:"PVM", repr:"PVM - Lithuania",rate:21}, code: "LT", name: <T>Lithuania</T>, currency: "EUR" },
	LU: {vat:{type:"TVA", repr:"Lux - TVA",rate:17}, code: "LU", name: <T>Luxembourg</T>, currency: "EUR" },
	MT: {vat:{type:"VAT", repr:"Malta - VAT",rate:18}, code: "MT", name: <T>Malta</T>, currency: "EUR" },
	NL: {vat:{type:"BTW", repr:"Netherlands - BTW",rate:21}, code: "NL", name: <T>Netherlands</T>, currency: "EUR" },
	NO: {vat:{type:"MVA", repr:"MVA - Norway", rate:25}, code: "NO", name: <T>Norway</T>, currency: "NOK" },
	PL: {vat:{type:"PTU", repr:"Poland - Ptu",rate:23}, code: "PL", name: <T>Poland</T>, currency: "EUR" },
	PT: {vat:{type:"IVA", repr:"Portugal - IVA",rate:23}, code: "PT", name: <T>Portugal</T>, currency: "EUR" },
	RO: {vat:{type:"TVA", repr:"TVA - Romania",rate:19}, code: "RO", name: <T>Romania</T>, currency: "EUR" },
	SK: {vat:{type:"DPH", repr:"Slovakia - DPH",rate:22}, code: "SK", name: <T>Slovakia</T>, currency: "EUR" },
	SI: {vat:{type:"DDV", repr:"DDV - Slovenia",rate:22}, code: "SI", name: <T>Slovenia</T>, currency: "EUR" },
	ES: {vat:{type:"IVA", repr:"IVA - Spain", rate:21}, code: "ES", name: <T>Spain</T>, currency: "EUR" },
	SE: {vat:{type:"Moms", repr:"Moms - Sweden",rate:25}, code: "SE", name: <T>Sweden</T>, currency: "SEK" },
	other: {
		code: "other",
		name: <T>Non-EU country</T>,
		vat: {type:"VAT", repr:"", rate:0},
		currency: "USD",
	},
};


export default get_country_info