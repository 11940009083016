import { useEffect, useState, createContext } from "react";
import { amopus_config } from "./util";

/** This hook initiates a checkout session when the state of 'orderId' changes.
 * 'orderId' is the id of the order in Amopus, use this as a future reference for the transaction.
 * 'result' is set to "Captured" or "Authorized" when the transaction is successfully completed, depending on
 * whether 'order.instantcapture' is set to 'true' or 'false', respectively.
 * If the transactions fails, result is set to a string that includes 'error'.
 * To use mobilepay, set `checkoutType` to `mobilepay` for credit cards use `iframe`.
 */
export const useCheckout = () => {
	const [orderId, setOrderId] = useState();
	const [checkoutType, setCheckoutType] = useState()
	const [instantcapture, setInstantcapture] = useState(false);
	const [socket, setSocket] = useState();
	const [checkoutUrl, setCheckoutUrl] = useState();
	const [token, setToken] = useState();
	const [error, setError] = useState(false);
	const [completed, setCompleted] = useState(false)
	const [loading, setLoading] = useState(false);

	// Initiate checkout session when given orderId
	useEffect(() => {
		const {AMOPUS_CHECKOUT} = amopus_config()
		if (orderId && checkoutType) {
			let url = AMOPUS_CHECKOUT;
			setSocket(new WebSocket(url));
			setLoading(true);
		}
	}, [orderId, checkoutType]);

	// Hook up websocket to states and send initial message.
	// Stops loading on message received.
	useEffect(() => {
		if (socket && orderId && checkoutType) {

			let msg = {
				action: "sendmessage",
				checkoutType,
				orderId,
			}
			socket.onopen = () => {
				socket.send(
					JSON.stringify(msg)
				);
			};
			socket.onmessage = (m) => {
				let result = JSON.parse(m.data)
				let data = result.data
				if (result.status === "ok") {
					// Give some time for bamboras component to load
					setTimeout(() => setLoading(false), 2000);
					if (data.iframe) {
						setCheckoutUrl(data.iframe);
					}
				} else if (result.status === "error") {
					setError(m.data.message);
				} else if (result.status === "success") {
					setCompleted(true)
				} else {
					setError(data?.message || "Unknown status")
				}
			};
		}
	}, [socket, orderId, instantcapture, checkoutType]);

	return {
		setOrderId,
		checkoutType, 
		setCheckoutType,
		checkoutUrl,
		completed,
		error,
		setError,
		loading,
		setLoading,
		token,
		setToken,
		setInstantcapture
	};
};

const CheckoutContext = createContext();

export default CheckoutContext;
