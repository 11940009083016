import React, { useState, useEffect, useCallback } from "react";
import { Col, Container, Row, Spinner, Input } from "reactstrap";
import { APIGet } from "../API.js";
import InfiniteScroll from "react-infinite-scroller";
import { EventCard } from "../c/Cards";
import ExploreCategoriesDropDown from "../c/ExploreCategoriesDropdown";
import {languagedefs, url_lang } from "../languages";
import { T, TraksConsumer } from '../traks';
import { LL } from "../Routes";

function Filtering(props) {

	//Pagination & EventData
	const [canload, setCanLoad] = useState(false);
	const [currentPage, setCurrentPage] = useState(0);
	const paramLimit = props.limit;
	const oldEvents = props.oldEvents;
	const allEvents = props.allEvents;
	const premiumEvents = props.premium;
	const [searchCount, setSearchCount] = useState(0);

	const [category, setCategory] = useState('');
	const [organizers, setOrganizers] = useState(props.organizerId ? props.organizerId : '');
	const [language, setLanguage] = useState( props.noAutoLang ? '' : url_lang().id.toString());

	const [eventData, setEventData] = useState([]);
	const [categoryData, setCategoryData] = useState([]);

	const [filters, setFilters] = useState([]);
	const [langDefault, setLangDefault] = useState(false);
	const [showButton, setShowButton] = useState(props.buttonLoad);

	const [searchActive, setSearchActive] = useState(false);
	const [searchWord, setSearchWord] = useState('');

	const paramsSearch = {
		'string': searchWord,
	};

	useEffect(() => {
		if (paramLimit) paramsSearch["limit"] = paramLimit;
		if (organizers) paramsSearch["organizer"] = organizers;
		if (oldEvents) paramsSearch["oldevents"] = oldEvents;
		if (allEvents) paramsSearch["futureandpast"] = true;
		if (premiumEvents){
			paramsSearch["paidonly"] = true;
			paramsSearch["futureandpast"] = true;
		}
		if (searchWord.length > 2) {
			setSearchActive(true);
			APIGet('search', paramsSearch)
				.then((res) => {
					setEventData(res.data.body);
			}	)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchWord]);

	let params = {
		oldevents: oldEvents,
		futureandpast: allEvents,
		limit: paramLimit,
	};

	if(premiumEvents) {
		params['paidonly'] = true;
		params['futureandpast'] = true;
		delete params['oldevents'];
	};

	useEffect(() => { //eventstats
		APIGet("search", {
			event_stats: true,
		}).then((response) => {
			setCategoryData(response.data.body);
			if (!props.noAutoLang) {
				setFilters([[url_lang().id, languagedefs[url_lang().id - 1].native, 2]]);
			}
		})
	}, [props.noAutoLang]);

	const addEnglishEvents = useCallback((limit) => {
		if (url_lang().id !== 3 && limit) {
			APIGet("search", { limit: limit, lang: 3, page: 0 }).then((res) => {
				setEventData((oed) => [...oed, ...res.data.body]);
				setFilters((filters) => [...filters, [3, "english", 2]]);
				setLanguage((oel) => (oel + ",3"));
			});
		}
	}, []);

	const [hasLoadedEvent, setHasLoadedEvent] = useState(false);
	const searchFunction = () => {
		setCurrentPage(0);
		if (category) params["category"] = category;
		if (organizers) params["organizer"] = organizers;
		if (language) params["lang"] = language;
		setCanLoad(false);
		params["page"] = 0;
		APIGet("search", params).then((res) => {
			setEventData(res.data.body);
			setHasLoadedEvent(true);
			setShowButton(false);
			setSearchActive(false);
			setSearchWord('');
			if (res.data.body.length >= paramLimit) {
				setCanLoad(true);
				setShowButton(true);
			} else if (searchCount === 0 && !props.organizerId) {
				addEnglishEvents(paramLimit - res.data.body.length);
			}
		});
		setSearchCount((oc) => oc + 1);
	};

	useEffect(() => {
		searchFunction();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [language, organizers, category, oldEvents, addEnglishEvents]);

	const handleLoadMore = () => {
		setCanLoad(false);
		if (canload && !searchActive) {
			setShowButton(false);
			params["page"] = currentPage + 1;
			setCurrentPage((cp) => cp + 1);
			if (oldEvents) params["oldevents"] = oldEvents;
			if (category) params["category"] = category;
			if (organizers) params["organizer"] = organizers;
			if (language) params["lang"] = language;
			APIGet("search", params).then((res) => {
				if (res.data.body.length > 0) {
					setEventData((eventData) => eventData.concat(res.data.body));
					setCanLoad(true);
					if (props.buttonLoad){
						if (res.data.body.length >= paramLimit) {
							setShowButton(true);
						} else {
							setShowButton(false);
						}
					}
				}
			});
		}
	};

	const removeFromFiltered = (catId) => {
		setCurrentPage(0);
		if (filters.length !== 1) {
			const productsFiltered = filters.filter((item) => item[1] !== catId[1]);
			setFilters(productsFiltered);
			switch(catId[2]) {
				case 0:
					if (category.indexOf(catId[0]) === 0 || category.length === 1) {
						setCategory(!category.includes(",") ? category.replace(catId[0],'') : category.replace(catId[0] + ",",''));
					}
					else {
						setCategory(category.replace("," + catId[0],''));
					}
					break;
				case 1:
					if (organizers.indexOf(catId[0]) === 0 || organizers.length === 1) {
						setOrganizers(!organizers.includes(",") ? organizers.replace(catId[0],'') : organizers.replace(catId[0] + ",",''));
					}
					else {
						setOrganizers(organizers.replace("," + catId[0],''));
					}
					break;
				case 2:
					if (language.indexOf(catId[0]) === 0 || language.length === 1) {
						setLanguage(!language.includes(",") ? language.replace(catId[0],'') : language.replace(catId[0] + ",",''));
					}
					else {
						setLanguage(language.replace("," + catId[0],''));
					}
					break;
				default:
			}
		}
		else {
			clearFilters();
		}
	};

	const clearFilters = () => {
		setSearchWord('');
		setLanguage('');
		setCategory('');
		setOrganizers(props.organizerId ? props.organizerId : '');
		setLangDefault(true);
		setFilters([]);
	};

	const Dropdowns = () => {
		if (categoryData)
			return (
				<div className="d-flex flex-column flex-lg-row mb-3">
					<div className="w100_mobile pb-2 pe-lg-3">
						<ExploreCategoriesDropDown
							disabledMeansRemoved={true}
							oldEvents={oldEvents}
							premium={premiumEvents}
							setVal={(clickData) => {
								if (!filters.toString().includes(clickData[1])) {
									clickData.push(0);
									setCurrentPage(0);
									setFilters([...filters, clickData]);
									setCategory(category.length ? category + ',' + clickData[0].toString() : clickData[0].toString());
								}
							}}
							Data={categoryData.categoryEventCount}
							disableCount={props.disableCount}
						>
							<span className="text_oneline"><T>All categories</T></span>
						</ExploreCategoriesDropDown>
					</div>
					{!props.organizerId ? 
						<div className="w100_mobile pb-2 pe-lg-3">
							<ExploreCategoriesDropDown
								disabledMeansRemoved={true}
								oldEvents={oldEvents}
								premium={premiumEvents}
								setVal={(clickData) => {
									if (!filters.toString().includes(clickData[1])) {
										clickData.push(1);
										setCurrentPage(0);
										setFilters([...filters, clickData]);
										setOrganizers(organizers.length ? organizers + ',' + clickData[0].toString() : clickData[0].toString());
									}
								}}
								Data={categoryData.organizerEventCount}
								disableCount={props.disableCount}
							>
								<T>Organiser</T>
							</ExploreCategoriesDropDown>
						</div>
					: null}
					<div className="w100_mobile pe-lg-3">
						<ExploreCategoriesDropDown
							oldEvents={oldEvents}
							premium={premiumEvents}
							setVal={(clickData) => {
								if (!filters.toString().includes(clickData[1])) {
									clickData.push(2);
									setCurrentPage(0);
									setFilters([...filters, clickData]);
									if (langDefault) {
										setLangDefault(false);
										setLanguage(clickData[0].toString());
									}
									else {
										setLanguage(language.length ? language + ',' + clickData[0].toString() : clickData[0].toString());
									}
								}
							}}
							Data={categoryData.languageEventCount}
							disableCount={props.disableCount}
						>
							<T>Language</T>
						</ExploreCategoriesDropDown>
					</div>
				</div>
			);
		else {
			return (
				<div className="text-center pushcontentfix">
					<T>Loading data...</T> <Spinner className="p-2" color="success" />
				</div>
			);
		}
	};

	const FilteredButtons = () => {
		if (filters.length)
			return (
			<div className="pt-3">
				{filters.map((v, i) => (
				<div
					className="badge_white me-2 mb-2 text-capitalize btn"
					onClick={() => removeFromFiltered([v[0],v[1],v[2]]) }
					key={i}
					color="text-dark"
					value={v}
					>
					{v[1]}
					<img src="https://static.zevio.com/gfx/ico_close.svg"
						height="8px"
						width="8px"
						className="mx-1"
						alt="close"
						style={{ marginBottom: "2px" }}
					/>
				</div>
				))}
				<span className="text_underline font_weight_600 btn" onClick={() => clearFilters()}><T>Clear</T></span>
			</div>)
		else {
			return (
				<span className="badge_white me-2 mb-2 my-3 btn" onClick={() => clearFilters()}><T>All events</T></span>
			);
		}
	};

	const EventCards = () => {
		if (eventData.length === 0) {
			return (
				<div className="py-5">
					<T>No events found with matching criteria</T>
				</div>
			);
		}

		return (
			<InfiniteScroll
				pageStart={0}
				hasMore={true || false}
				loadMore={!props.buttonLoad ? handleLoadMore : FilteredButtons} // warning hack
			>
				<Row className="py-4">
					{eventData.map((v, i) => (
						<Col key={i} xs={12} sm={12} md={6} lg={4} xl={3}>
							<div className="d-flex justify-content-center">
								<>
									<EventCard eventData={v} />
								</>
							</div>
						</Col>
					))}
				</Row>
			</InfiniteScroll>
		);
	};
	if (eventData && categoryData && categoryData.languageEventCount) {
		return (
			<Container className="p-0">
				<div className="explore_subtitle pt-lg-2 pb-3">
					{props.headerText}
				</div>
				<div className="d-flex flex-column flex-lg-row buttonGroup">
					<TraksConsumer>
						<Search setSearchWord={setSearchWord} clear={clearFilters}  />
					</TraksConsumer>
					<div className="ms-3">
						<Dropdowns />
					</div>
				</div>
				<FilteredButtons />
				<EventCards />
				<LoadMoreButton handleLoadMore={handleLoadMore} showButton={showButton} buttonLoad={props.buttonLoad} searchActive={searchActive}  />
			</Container>
		);
	} else {
	return (
		<div className="pushcontentfix"/>
	);
	}
}

const LoadMoreButton = (props) => {
	if (props.buttonLoad && props.showButton && !props.searchActive)
		return (
		<div className="text-center">
			<button
				onClick={() => props.handleLoadMore()}
				type="submit"
				className="text-white font_15px btn btn_green mt-3 mb-3 border-0 px-5"><T>See more</T>
			</button>
		</div> 
	)
	else {
		return (
			null
		);
	}
};

const Search = ({setSearchWord, clear, render_static}) => {
	
	const [length, setLength] = useState(false);
	const handleChange = (e) => {
		if (e.target.value.length) {
			setLength(true);
			setSearchWord(e.target.value);
		} else {
			if (length) {
				clear();
			}
			setLength(false);
		}
	}
	return (
		<div className="w100_mobile w-100">
			<form id="topbar_search_field" className="form-inline pb-3 pb-lg-1">
				<Input
					autoComplete="off"
					placeholder={render_static(<T>Search events..</T>)}
					type="text"
					className={"search_navbar search_bg_transparent no_outline_focus input_search w-100 bg-white"}
					onChange={handleChange}
				/>
			</form>
		</div>
	)
};

export default Filtering;
