import React from "react";
import translations from "./traks-translations";

let m;
if ("TRAKS_COMPILE_TIME_MAGICK_CONST__IS_BAKED") {
	let setup = require('traks/setup-baked');
	m = setup({
		translations,
		lang: "TRAKS_COMPILE_TIME_MAGICK_CONST__LANG",
		set_lang: (lang) => { console.log(["TODO set lang", lang]); }
	});
} else {
	let setup = require('traks/setup');
	const TranslationMissing = (props) => (
		<div style={{ backgroundColor: "#f0f", color: "#0f0" }}>
			{props.children || null}
		</div>
	);

	m = setup({
		translations,
		default_lang: "en",
		translation_missing_component: TranslationMissing,
	});
}

const { T, TraksProvider, TraksConsumer } = m;

export { T, TraksProvider, TraksConsumer };
