import React, { useState, useContext, useRef, useEffect } from "react";
import { MeContext } from "../../../MeContext";
import { isValidEmail } from "../../ReusableElems";
import { APIPost } from "../../../API";
import { RefreshSession } from "../../../p/Login";
import { T } from "../../../traks";
import { FeedbackInput } from "../../Input";
import SignupContext from "../useSignup";

const AlreadyUser = ({ render_static }) => {
	const { setMe } = useContext(MeContext);
	const { setTransitionToken, userInfo,  setModalVisibility } = useContext(SignupContext);
	const [invalidEmail, setInvalidEmail] = useState(false)
	const [showError, setShowError] = useState(false);
	const formRef = useRef()

	useEffect(()=>{
		if (userInfo && formRef.current) {
			formRef.current.username.value = userInfo.email
		}
	},[userInfo, formRef])

	const submit = (e) => {
		e.preventDefault()
		let form = e.nativeEvent.target
		if (!isValidEmail(form.username.value)) {setInvalidEmail(true);return}
		let username = form.username.value;
		let password = form.password.value;
		APIPost("login",{username, password})
			.then((reply) => {
				const user = reply.data.body;
				if (user.sessionId) {
					window.localStorage.setItem("zevio_createuser", true);
					RefreshSession(user.sessionId);
					setMe(user);
					setModalVisibility(false)
					window.location.reload(false)
				}
			})
			.catch((error) => {
				if (error.response.status === 401) {
					formRef.current.password.value=""
					setShowError(true);
				}
			});
	};

	return (
		<div>
			<div className="px-4 py-1">
				<p className="pt-4 mb-0 font_15px">
					<T>It looks like you've already got an account associated with this email. Please log in first.</T>
				</p>
				{showError && (
					<div className="red_text">
						<i>
							<T>No account found / incorrect password or email</T>
						</i>
					</div>
				)}
				<form onSubmit={submit} ref={formRef}>
				<FeedbackInput
					name="email"
					type="email"
					id="username"
					className="pt-3 w-100"
					placeholder={render_static(<T>E-mail*</T>)}
					invalid={invalidEmail}
					errorMessage={render_static(<T>Invalid email address</T>)}
				/>
				<FeedbackInput
					autofocus={true}
					type="password"
					name="password"
					id="password"
					className="pt-4 w-100"
					placeholder={render_static(<T>Password</T>)}
				/>
				<button
					value="login"
					type="submit"
					className="text-white font_15px btn btn_green w-100 mt-4 mb-2 border-0"
				>
					<T>Sign&nbsp;in</T>
				</button>
				</form>
				<button
					onClick={()=> setTransitionToken("RecoverPassword")}
					style={{ fontWeight: "normal" }}
					className="btn btn_white font_13px w-100 mb-1 border-0 py-2"
				>
					<T>I forgot my password</T>
				</button>
				
			</div>
		</div>
	);
};

export default AlreadyUser;
