import React from "react";
import { Col, Container, Row } from "reactstrap";

const LegacyAdmin = () => {
	return (
		<Container><Row><Col className="p-5">
			<div className="d-flex flex-column p-5" style={{background: "white", border: "1px solid black", borderRadius: "12px"}}>
				<div className="text-center">You are probably looking for the old <tt style={{fontSize: "130%"}}>/admin</tt> from zevio.com. This now lives here:</div>
				<div className="text-center py-4" style={{fontSize: "250%"}}>
					<tt><a href="https://lgcy.zevio.com/admin">https://lgcy.zevio.com/admin</a></tt>
				</div>
				<div className="text-center">Please update your bookmarks, since this reminder page might go away in the future.</div>
			</div>
		</Col></Row></Container>
	);
}

export default LegacyAdmin;
