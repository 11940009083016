import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import ZevioNav from "../c/ZevioNav";
import Footer from "../c/Footer";
import { APIGet } from '../API.js';
import { useParams } from "react-router-dom";
import { OrganizerNotFound } from "../c/OrganizerProfile";
import DotLoader from "../c/DotLoader";
import { T } from "../traks";
import { LL } from "../Routes";

const MaybeLink = ({ children, newPage, disabled, setPage }) => {
	if(disabled) return children;
	return (
		<div className='cursor-pointer' onClick={() => setPage(newPage)}>{children}</div>
	)
}

const Events = ({ events, hits, limit, page, base_url, setPage }) => {
	const nextDisabled = page >= Math.ceil(hits/limit)-1;
	const prevDisabled = page === 0;
	return (<>
		<div>
			{events.map( (e, i) =>
				<div key={e.Safename + "-" + i}>
					<a alt={'link to ' + e.Title} href={LL(e.eventLink)}>{e.Title}</a>
				</div>
			)}
		</div>
		<div className='d-flex justify-content-center pt-4'>
			<div className='d-flex align-items-center'>
				<MaybeLink newPage={page-1} setPage={setPage} disabled={prevDisabled}>
					<span className='font_24px pe-3'>&lt;</span>
				</MaybeLink>
				<div>
					<T>Page</T>&nbsp;{page + 1}&nbsp;/&nbsp;{Math.ceil(hits/limit)}
				</div>
				<MaybeLink newPage={page+1} setPage={setPage} disabled={nextDisabled}>
					<span className='font_24px ps-3'>&gt;</span>
				</MaybeLink>
			</div>
		</div>
	</>);
}

const OrganizerEventsInner = () => {
	const { organizerId } = useParams();
	const [ organizer, setOrganizer ] = useState([]);
	const [ page, setPage ] = useState(0);
	const [ past, setPast ] = useState([]);
	const [ pastHits, setPastHits ] = useState(-1);
	const [ future, setFuture ] = useState([]);
	const [ futureHits, setFutureHits ] = useState(-1);
	const limit = 50;

	useEffect(() => {
		APIGet('search', {'organizer_id': organizerId})
			.then((res) => {
				setOrganizer(res.data.body[0]);
			})
			.catch((error) => {
				if(error.response.status === 404) {
					setOrganizer(404);
				} else {
					console.log(['API Error', error]);
				}
			})
	}, [organizerId]);

	useEffect(() => {
		APIGet('search', {'organizer': organizerId, 'limit': limit, 'verbose': 'true', 'page': page})
			.then((res) => {
				setFuture(res.data.body.events);
				setFutureHits(res.data.body.total_hits.value);
			})
	}, [organizerId, page]);

	useEffect(() => {
		APIGet('search', {'organizer': organizerId, 'verbose': 'true', 'oldevents': 'true', 'limit': limit, 'page': page})
			.then((res) => {
				setPast(res.data.body.events);
				setPastHits(res.data.body.total_hits.value);
			})

	}, [organizerId, page]);

	if(organizer === 404) return <OrganizerNotFound />
	return (
		<Container>
		<Row>
		<Col className="bg_white my-5 border_radius_10px px-4 pb-4 mx-auto" xs={12} lg={8}>
			<div className='font_24px py-3'><T>All {organizer.Name} events</T></div>
			{(pastHits + futureHits) >= 0
			?
				<Events base_url={LL('/organizer/' + organizer.Id + '/events/page/')} events={[...future, ...past]} hits={pastHits + futureHits} limit={limit} page={page} setPage={setPage} />
			:
				<DotLoader><T>Loading events...</T></DotLoader>
			}
		</Col>
		</Row>
		</Container>
	);
}

const OrganizerEvents = () => {
	return (<>
		<ZevioNav />
		<OrganizerEventsInner />
		<Footer />
	</>);
}

export default OrganizerEvents;
