import React, { useEffect, useContext, useState, useCallback } from "react";
import get_country_info from "../../Amopus/util";
import CheckoutContext, { useCheckout } from "../../Amopus/useCheckout";
import IframeCheckout from "../../Amopus/IframeCheckout";
import { T } from "../../../traks";
import I18N from "../../../I18N";
import SignupContext from "../useSignup";
import { APIPost } from "../../../API";
import { url_lang } from "../../../languages";
import EventCtx from "../useEvent";
import { MeContext } from "../../../MeContext";
import { sendToDataLayer } from "../../ReusableElems";

export const BuyEventTicketButton = ({ onClick }) => {
	const { accessProduct } = useContext(SignupContext);
	const [price, setPrice] = useState(0);
	let { currency } = get_country_info();
	useEffect(() => {
		if (accessProduct?.price) {
			setPrice(accessProduct.price[currency]);
		}
	}, [accessProduct, currency]);

	return (
		<button
			onClick={onClick}
			type="submit"
			value="BuyEventTicket"
			className="text-white font_15px btn btn_green w-100 mt-3 mb-3 border-0"
		>
			<T>Buy access for {I18N.floatval_unit(price, 2, currency.toUpperCase())}</T>
		</button>
	);
};

const BuyEventTicket = () => {
	const { eventData } = useContext(EventCtx);
	const { me } = useContext(MeContext);
	const [orderId, setOrderId] = useState();
	const { setTransitionToken, accessProduct, userInfo, subscribeNewsletter } =
		useContext(SignupContext);
	const checkoutCtx = useCheckout();
	let { vat, currency } = get_country_info();
	const productUrl = `zevio.com/${url_lang().lang}/live/${
		eventData.UniqueHash
	}`;
	const getOrderId = useCallback(() => {
		return APIPost("register", {
			...userInfo,
			subscribe_newsletter: subscribeNewsletter,
			origin: "web",
			action: "create_amopus_order",
			eventId: eventData.Id,
			site_lang: url_lang().lang,
			data: {
				product_id: accessProduct.Id,
				description: eventData.Title,
				text: eventData.SubTitle,
				linenumber: eventData.Id,
				quantity: 1,
				unit: "pcs.",
				producturl: productUrl,
				vattype: vat.type,
				vatrate: vat.rate,
				currency,
				metadata: `{"lang":"${url_lang().lang}", "url_template":true}`,
				shippingaddress: { email: userInfo.email },
				billingaddress: { email: userInfo.email },
			},
		}).then(({ data }) => {
			if (
				data.body.msg === "Payment received" ||
				data.body.msg === "Email already registered"
			) {
				if (!me) {
					// The user already paid for the event. Please login
					setTransitionToken("AlreadyUser");
				} else {
					// We shouldn't be here. Quickly, reload the page and pray!!
					window.location.reload(false);
				}
			} else {
				setOrderId(data.body.orderId);
			}
		});
	}, [
		accessProduct.Id,
		currency,
		eventData.Id,
		eventData.SubTitle,
		eventData.Title,
		me,
		productUrl,
		setTransitionToken,
		subscribeNewsletter,
		userInfo,
		vat.rate,
		vat.type,
	]);

	useEffect(() => {
		if (!orderId) getOrderId();
	}, [getOrderId, orderId]);

	return (
		<CheckoutContext.Provider value={checkoutCtx}>
			{orderId ? (
				<Checkout
					onSuccess={() => {
						sendToDataLayer({
							event: "purchase",
							ecommerce: {
								currency,
								shipping: 0,
								value: accessProduct.price[currency],
								transaction_id: orderId,
								items: [
									{
										item_name: `access product: ${eventData.Title}`,
										item_id: eventData.Id,
										price: accessProduct.price[currency],
										quantity: 1,
									},
								],
							},
						});
						setTransitionToken("SignupComplete");
					}}
					onError={() => setTransitionToken("Signup")}
					initorderId={orderId}
				/>
			) : (
				<div className="d-flex justify-content-center align-items-center w-100">
					<div
						className="spinner-border"
						role="status"
						style={{ width: "3rem", height: "3rem" }}
					/>
				</div>
			)}
		</CheckoutContext.Provider>
	);
};

const Checkout = ({ onSuccess, onError, initorderId }) => {
	const { orderId, setOrderId } = useContext(CheckoutContext);
	const { setAmopusId } = useContext(SignupContext);
	useEffect(() => {
		if (!orderId && initorderId) {
			setOrderId(initorderId);
			setAmopusId(initorderId);
		}
	}, [initorderId, orderId, setOrderId]);

	return (
		<>
			<IframeCheckout onSuccess={onSuccess} onError={onError} />
		</>
	);
};

export default BuyEventTicket;
