import React, { useContext, useEffect, useState } from "react";
import { Container } from "reactstrap";
import ZevioNav from "../c/ZevioNav";
import Footer from "../c/Footer";
import { APIGet, APIPost } from "../API";
import { T } from '../traks';
import { MeContext } from '../MeContext';

const print_ts = (ts) => {
	const orderDate = new Date(ts * 1000);
	const dateRegEx = new RegExp('(.*)GMT.*');
	return orderDate.toString().match(dateRegEx)[1];
}

const OrderDetails = ({order}) => {
	const [resent, setResent] = useState(false);
	const [delivery, setDelivery] = useState({
		full_name: order.dbkRequest.order.head.delivery.full_name,
		address1: order.dbkRequest.order.head.delivery.address1,
		address2: order.dbkRequest.order.head.delivery.address2,
		city_zip: order.dbkRequest.order.head.delivery.city_zip,
		country: order.dbkRequest.order.head.delivery.country,
		phone: order.dbkRequest.order.head.delivery.phone,
		type: order.dbkRequest.order.head.delivery.type,
	});

	const error_messages = [];
	Object.keys(order).forEach( (o) => {
		const object_key = o.split("-");
		let mydate = "";
		if(object_key[0] === 'event')
			mydate = print_ts(object_key[1]) + ": ";
		if(order[o].error_msg) {
			error_messages.push(mydate + order[o].error_msg);
		}
	});

	const process_manually = () => {
		setResent(<T>Processing manually...</T>);
		APIPost('dagensbogorders', {'action': 'process_manually', 'order_id': order.id}).then( () => {
			setResent(<T>Order has been processed manually</T>);
		}).catch( (err) => {
			setResent(<span className="zevio_red"><T>Something went wrong</T></span>);
			console.log(err);
		});
	}

	const resend_manipulated_order = () => {
		setResent(<T>Re-sending order..</T>);
		order.dbkRequest.order.head.delivery = delivery;
		APIPost('dagensbogorders', {'action': 'resend_dbkdelivery', 'order_id': order.id, 'new_request': order.dbkRequest}).then( () => {
			setResent(<T>Order was sent again to DBK</T>);
		}).catch( (err) => {
			setResent(<span className="zevio_red"><T>Something went wrong</T></span>);
			console.log(err);
		});
	}

	return (
		<div>
		{resent ? <div className="p-3"><i>{resent}</i></div> :
		<div className="w-100 ps-3 py-3" style={{borderTop: "1px solid #ccc", borderBottom: "1px solid #ccc"}}>
			<h5><T>Order lines:</T></h5>
			<div className="pb-3">
			{order.dbkRequest.order.lines.map( (l, i) => {
				return (
					<div key={order.id + i} className="pb-1 pe-2">{l.quantity} &times; {l.title} (ISBN13: {l.isbn13})</div>
				)
			})}
			</div>
			{error_messages.length > 0 &&
			<div>
				<h5><T>Error messages:</T></h5>
				<div className="pb-3">
				{error_messages.map( (e, i) => {
					return (
						<li key={'error_msg' + order.id + i} className="pb-1 pe-2 color_red">{e}</li>
					)
				})}
				</div>
			</div>}
			<h5><T>Delivery address</T></h5>
			<form className="font_monospace" onSubmit={(e) => e.preventDefault()}>
				<div className="pb-1">
				Navn:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input className="w-50 zevio_input" type='text' value={delivery.full_name} onChange={(e) => setDelivery( (od) => {return {...od, full_name: e.target.value}})} />
				</div>
				<div className="pb-1">
				Adresse1: <input className="w-50 zevio_input" type='text' value={delivery.address1} onChange={(e) => setDelivery( (od) => {return {...od, address1: e.target.value}})} />
				</div>
				<div className="pb-1">
				Adresse2: <input className="w-50 zevio_input" type='text' value={delivery.address2} onChange={(e) => setDelivery( (od) => {return {...od, address2: e.target.value}})} />
				</div>
				<div className="pb-1">
				Post/by:&nbsp;&nbsp;<input className="w-50 zevio_input" type='text' value={delivery.city_zip} onChange={(e) => setDelivery( (od) => {return {...od, city_zip: e.target.value}})} />
				</div>
				<div className="pb-1">
				Land:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input className="w-50 zevio_input" type='text' value={delivery.country} onChange={(e) => setDelivery( (od) => {return {...od, country: e.target.value}})} />
				</div>
				<div className="pb-1">
				Tlf:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input className="w-50 zevio_input" type='text' value={delivery.phone} onChange={(e) => setDelivery( (od) => {return {...od, phone: e.target.value}})} />
				</div>
			</form>
			<div className="pt-4 pb-2">
				<T>Remember: <i>With great power comes great responsibility</i></T><br />
				<div style={{color: "#888", fontSize: "70%"}}><T>Every click of the green button sends the order to DBK, no matter the existing status. The yellow one simply changes the status to <tt>processed_manually</tt></T></div>
			</div>
			<div className="d-flex">
				<div>
					<button onClick={resend_manipulated_order} className="btn btn_green">Re-send manipulated order to DBK</button>
				</div>
				<div className="ms-auto pe-3">
					<button onClick={process_manually} className="btn btn_yellow">Process manually</button>
				</div>
			</div>
		</div>
		}
		</div>
	);
}

const Order = ({order, count}) => {
	const [ details, setDetails ] = useState(false);
	let classes = "py-2 w-100 d-flex font_monospace"
	if(count % 2) classes += " bg_lightlight_grey";

	if(!order.addedTs) {
		order['addedTs'] = 0;
		console.log(['This order has no added timestamp', order]);
	}

	return (<>
		<div className={classes}>
			<div className="ps-3 pe-4">{order.id}</div>
			<div className="pe-4">{print_ts(order.addedTs)}</div>
			<div className="pe-4">{order.status}</div>
			<div className="ms-auto pe-4 cursor-pointer" onClick={() => setDetails( !details )}>(<span className="text_underline"><T>details</T></span>)</div>
		</div>
		{details && <OrderDetails order={order} />}
	</>);
}

const DagensBogOrders = ({me}) => {
	const [orders, setOrders] = useState(null);

	useEffect( () => {
		APIGet('dagensbogorders', {'action': 'get_orders'}).then( (res) => {
			let orders = [...res.data.body.orders];
			orders.sort((a,b) => a.addedTs - b.addedTs);
			setOrders(orders);
		});
	}, []);

	if(orders && orders.length) {
		return orders.map((order, i) => <Order key={order.id} count={i} order={order} />);

	}
	return <div><T>Loading orders</T></div>;
}

export const BuchDesTages = () => {
	const { me } = useContext(MeContext);
	let content = <div className="color_red p-3" style={{fontSize: "125%"}}><i><T>You need to be admin or part of Dagens Bog to access this page</T></i></div>;

	if(me.organizers.indexOf(90) !== -1 || me.roles.indexOf(42) !== -1)
		content = <DagensBogOrders />;

	return (
		<React.Fragment>
			<ZevioNav />
			<Container className="w-75 pb-5">
			<Container className="bg_white p-0 promotion_box">
				<h4 className="ps-3 pt-3"><T>Active Dagens Bog orders</T></h4>
				<div className="d-flex flex-column">
					{content}
				</div>
			</Container>
			</Container>
			<div className="py-5" />
			<div className="py-5" />
			<Footer />
		</React.Fragment>
	);
}

export default BuchDesTages;

