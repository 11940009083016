import React, { useState, useContext, useEffect } from "react";
import { MeContext } from "../../../MeContext";
import { APIPost } from "../../../API";
import { RefreshSession } from "../../../p/Login";
import { T } from "../../../traks";
import { FeedbackInput } from "../../Input";
import SignupContext from "../useSignup";

const RememberMe = ({ render_static }) => {
	const { me, setMe } = useContext(MeContext);
	const { userInfo, setTransitionToken } = useContext(SignupContext);

	useEffect(()=>{
		if (!!me) {
			setTransitionToken("SignupComplete")
		}
	}, [me, setTransitionToken])

	const Desktop = () => {
		const [saveEmail, setSaveEmail] = useState(false);
		const [invalid, setInvalid] = useState(false);


		const submit = (e) => {
			e.preventDefault();
			let password = e.nativeEvent.target.password.value
			let passwordConfirm = e.nativeEvent.target.passwordConfirm.value
			if (password !== passwordConfirm || password.length<7) {
				setInvalid(true);
				return
			}
			APIPost("users", {
				...userInfo,
				password,
				action: "create_user",
			}).then((reply) => {
				if (reply.data.response_code === 201 && reply.data.body.sessionId) {
					RefreshSession(reply.data.body.sessionId);
					setMe(reply.data.body.user);
					setTransitionToken("SignupComplete");
				} else if (reply.data.response_code === 202) {
					setTransitionToken("AlreadyUser");
				}
			});
		};

		return (
			<div>
				<div className="pb-2 py-4">
					<T>Use this email for future events?</T>
				</div>
				{!saveEmail ? (
					<div className="text-center text-nowrap mt-3 mb-2">
						<span className="image-container">
							<button
								id="yes_use_this_email_for_future_events_button"
								onClick={() => setSaveEmail(true)}
								type="submit"
								className="btn btn_green px-lg-5 py-2 font_weight_500 w-100"
							>
								<T>Yes</T>
							</button>
							&nbsp;
							<button
								onClick={() => setTransitionToken("SignupComplete")}
								type="submit"
								className="btn btn_grey font_weight_400 me-lg-2 px-lg-4 mb-lg-0 py-2 font_weight_300 ms-2"
							>
								<T>No thanks</T>
							</button>
						</span>
					</div>
				) : null}
				{saveEmail ? (
					<form onSubmit={submit}>
						<FeedbackInput
							autofocus={true}
							className="pt-4 w-100"
							placeholder={render_static(<T>Password*</T>)}
							type="password"
							name="password1"
							id="password"
							autoComplete="off"
							invalid={invalid}
							errorMessage={render_static(<T>Passwords must match and have sufficient length</T>)}
						/>
						<FeedbackInput
							className="pt-4 w-100"
							placeholder={render_static(<T>Password again*</T>)}
							type="password"
							name="password2"
							id="passwordConfirm"
							autoComplete="off"
							invalid={invalid}
							errorMessage={render_static(<T>Passwords must match and have sufficient length</T>)}
						/>
						<div className="text-center text-nowrap mt-4 mb-2">
							<button
								type="submit"
								className="text-white font_15px btn btn_green py-1 border-0 w-100 py-2 mt-4 text-center"
							>
								{<T>Save</T>}
							</button>
						</div>
					</form>
				) : null}
			</div>
		);
	};

	const Mobile = () => {
		const getApp = () => {
			if (navigator.userAgent.match(/Android/i)) {
				window.open(
					"https://play.google.com/store/apps/details?id=com.zevio",
					"_blank",
					"noopener,noreferrer"
				);
			} else {
				window.open(
					"https://apps.apple.com/dk/app/zevio/id1586777122",
					"_blank",
					"noopener,noreferrer"
				);
			}
		};

		return (
			<div>
				<div className="text-center text-nowrap mt-4 mb-2">
					<button
						onClick={() => getApp()}
						type="submit"
						className="text-white font_15px btn btn_green py-2 border-0 me-2 font_weight_600"
					>
						{<T>Download app for free</T>}
					</button>
					&nbsp;
					<button
						onClick={() => setTransitionToken("SignupComplete")}
						type="submit"
						style={{ lineHeight: "32px" }}
						className="btn btn_grey py-2 font_weight_600"
					>
						<T>No thanks</T>
					</button>
				</div>
			</div>
		);
	};
	return <div>{window.innerWidth > 768 ? <Desktop /> : <Mobile />}</div>;
};

export default RememberMe;
