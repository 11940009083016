import React from "react";
import { Container, Row } from "reactstrap";
import EventFiltering from "../c/EventFiltering";
import { T } from '../traks';

function PremiumStreamsPage() {

	const EventCardsPremium = () => {
		let text = <T>Premium Streams</T>
		return (
			<Row className="py-4">
				<EventFiltering
					limit={12}
					headerText={text}
					noAutoLang={false}
					premium={true}
				/>
			</Row>
		);
	};
	return (
		<Container>
			<EventCardsPremium />
		</Container>
	);
}

export default PremiumStreamsPage;