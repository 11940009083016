import React, {
	createContext,
	useState,
	useEffect,
	useCallback,
	useContext,
} from "react";
import { MeContext } from "../../MeContext";
import { useRegistration } from "../useRegistration";
import { APIPost } from "../../API";
import Signup from "./SignupModal/Signup";
import BuyIncognitoTicket from "./SignupModal/BuyIncognitoTicket";
import RememberMe from "./SignupModal/RememberMe";
import AlreadyUser from "./SignupModal/AlreadyUser";
import Processing from "./SignupModal/Processing";
import RecoverPassword from "./SignupModal/RecoverPassword";
import SignupComplete from "./SignupModal/SignupComplete";
import BuyEventTicket from "./SignupModal/BuyEventTicket";
import { LL } from "../../Routes";
import EventCtx from "./useEvent";

const SignupContext = createContext();

export const useSignup = () => {
	const [alreadySigned, setAlreadySigned] = useState(null);
	const [signupComplete, setSignupComplete] = useState(false);
	const [hasAcceptedPolicy, setHasAcceptedPolicy] = useState(null);
	const [subscribeNewsletter, setSubscribeNewsletter] = useState(false);
	const [state, setState] = useState("Signup");
	const [loading, setLoading] = useState(false);
	const [accessProduct, setAccessProduct] = useState()
	const [accessProducts, setAccessProducts] = useState([])
	const [amopusId, setAmopusId] = useState()
	const { me, setMe } = useContext(MeContext);
	const {eventData, } = useContext(EventCtx)
	const {
		getOrganizersMissingAccept,
		register,
		isRegisteredForEvent,
		loading: loadingRegistration,
		refresh: registrationRefresh
	} = useRegistration();
	const [modalVisibility, setModalVisibility] = useState(false);


	// Store information about the user to share between transition.
	const [userInfo, setUserInfo] = useState({
		email: me ? me.email : "",
		name: me ? me.firstName + " " + me.lastName : "",
		zip: me ? me.zipCode : "",
		phoneNo: me ? me.phoneNo : "",
		jobTitle: me ? me.jobTitle : "",
		company: me ? me.company : "",
		country: me ? me.country : "",
	});

	// Use this to navigate between state. Check TransitionTable for valid tokens.
	const setTransitionToken = useCallback(
		(token) => {
			setState(TransitionTable[state][token]);
		}, [state]
	);
	const reset = () => {
		console.log("Resetting")
		registrationRefresh();
		setState("Signup");
		setHasAcceptedPolicy(null);
		setAlreadySigned(null);
		setSignupComplete(false);
		setSubscribeNewsletter(false);
		setAccessProducts([]);
	};


	// Check if the user already signed up for the event
	useEffect(() => {
		if (!loadingRegistration && eventData?.Id && (alreadySigned === null || alreadySigned===undefined)) {
			let r = !!isRegisteredForEvent(eventData.Id)
			setAlreadySigned(r)
		}
	}, [alreadySigned, eventData, eventData?.Id, isRegisteredForEvent, loadingRegistration])


	// Check if the user has accepted terms of the organizers
	useEffect(() => {
		if (alreadySigned) return
		if ((hasAcceptedPolicy === null) && !loadingRegistration && eventData) {
			if (
				eventData.organizers &&
				getOrganizersMissingAccept(eventData.organizers).length === 0
			) {
				setHasAcceptedPolicy("previously");
			}
		}
	}, [
		eventData,
		getOrganizersMissingAccept,
		loadingRegistration,
		hasAcceptedPolicy,
		alreadySigned
	]);

	// populate event access products
	useEffect(() => {
		if (accessProducts.length === 0) {
			APIPost('products', { action: "get_event_access_products" }).then(r => {
				if (r.status === 200) {
					setAccessProducts(r.data.body.products)
				}
				else { console.log("error setting access products") }
			})
		}

	}, [accessProducts.length])


	// Does the user need to pay for a ticket
	useEffect(() => {
		if (accessProducts && eventData?.AccessProductId) {
			let eid = eventData.AccessProductId
			let producturl = `https://zevio.com${LL("/live/")}${eventData.UniqueHash}`
			let ap = {...accessProducts.filter(a => a.Id === eid)[0], headline:eventData.Title, producturl}
			setAccessProduct(ap)
		}
	}, [eventData, accessProducts])


	useEffect(() => {
		if (state === "Register" && userInfo && !loading && eventData?.Id) {
			setLoading(true);
			register({
				signUpForm: { ...userInfo, eventId: eventData.Id, subscribe_newsletter:subscribeNewsletter, ...amopusId?{amopusId}:{} },
				callback: (reply) => {
					setLoading(false);
					if (reply.data.response_code === 201) {
						// First time registering this email for event
						setTransitionToken("RememberMe");
					} else if (reply.data.response_code === 202) {
						if (reply.data.body.msg === "Email already registered" && !me) {
							setTransitionToken("AlreadyUser")
						} else {
							setTransitionToken("SignupComplete");
						}
					} else if (reply.data.response_code === 203) {
						setTransitionToken("SignupComplete");
					}
				},
			});
		}
	}, [state, setTransitionToken, register, userInfo, eventData, me, loading, subscribeNewsletter, amopusId]);

	useEffect(() => {
		if (me) {
			// Check if new information has been added and if so, update what we store in DB
			let me_info = {
				name: me.firstName + " " + me.lastName,
				zip: me.zipCode,
				phoneNo: me.phoneNo,
				jobTitle: me.jobTitle,
				company: me.company,
				country: me.country,
			};
			let should_store = Object.keys(me_info).reduce(
				(prev, curr) => me_info[curr] !== userInfo[curr] || prev,
				false
			);
			if (should_store) {
				APIPost("users", { ...userInfo, ...splitNames(userInfo.name), action: "update_user" }).then(
					(response) => {
						if (response.status === 200) {
						} else {
							console.log("Couldn't save data!");
						}
					}
				);
			}
		}
	}, [userInfo, me, setMe]);

	useEffect(() => {
		if (signupComplete) {
			// Datalayer stuff
			const inlineScript = document.createElement("script");
			inlineScript.innerHTML = `
				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({'event': 'registration','loggedin': ${!!me}});
			`;
			document.body.append(inlineScript);
		}
	}, [signupComplete, me]);

	return {
		accessProduct,
		modalVisibility,
		setModalVisibility,
		state,
		setTransitionToken,
		setUserInfo,
		subscribeNewsletter, 
		setSubscribeNewsletter,
		loading,
		setLoading,
		userInfo,
		setSignupComplete,
		signupComplete,
		hasAcceptedPolicy,
		setHasAcceptedPolicy,
		reset,
		alreadySigned,
		setAlreadySigned,
		amopusId,
		setAmopusId
	};
};

const TransitionTable = {
	Signup: {
		Register: "Register",
		BuyIncognitoTicket: "BuyIncognitoTicket",
		BuyEventTicket: "BuyEventTicket",
	},
	BuyIncognitoTicket: {
		SignupComplete: "SignupComplete",
		Signup: "Signup",
	},
	BuyEventTicket: {
		Signup: "Signup",
		AlreadyUser:"AlreadyUser",
		SignupComplete:"SignupComplete"
	},
	RememberMe: {
		AlreadyUser: "AlreadyUser",
		SignupComplete: "SignupComplete",
	},
	AlreadyUser: {
		Signup:"Signup",
		RecoverPassword: "RecoverPassword",
	},
	Register: {
		RememberMe: "RememberMe",
		SignupComplete: "SignupComplete",
		AlreadyUser: "AlreadyUser",
	},
	RecoverPassword: {
		AlreadyUser: "AlreadyUser",
	},
};


const splitNames = (fullName) => {
	let names = fullName.split(" ")
			if (names.length===1) {
				return {firstName:names[0], lastName:""}
			}
			return {firstName:names.slice(0, -1).join(" "), lastName:names.slice(-1)[0]}
} 

export const stateComponents = {
	Signup: <Signup />,
	BuyIncognitoTicket: <BuyIncognitoTicket />,
	RememberMe: <RememberMe />,
	AlreadyUser: <AlreadyUser />,
	Register: <Processing />,
	RecoverPassword: <RecoverPassword />,
	SignupComplete: <SignupComplete />,
	BuyEventTicket: <BuyEventTicket />
};

export default SignupContext;
