import React, { useContext } from "react";
import EventCtx from "./useEvent";
import { OrganizerCard } from "../Cards";
import { T } from "../../traks";

const OrganizerCardsComponent = () => {
	const { eventData } = useContext(EventCtx)
	if (eventData.organizers.length === 0) {
		return <div />;
	}
	return (
		<div>
			<div className="organized_by ps-1 ps-lg-3 pt-3 pb-3 pb-lg-4">
				<T>Organized by:</T>
			</div>
			<div className="d-flex flex-column">
				{eventData.organizers.map((v, i) => (
					<div className="pb-3 mx-auto ps-lg-3 pb-lg-4" key={i}>
						<OrganizerCard organizerData={v} key={i} />
					</div>
				))}
			</div>
		</div>
	);
};

export default OrganizerCardsComponent