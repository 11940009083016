import React, { useState, useContext, useEffect, useRef } from "react";
import SignupContext from "../useSignup";
import { APIPost } from "../../../API";
import { T } from "../../../traks";
import { FeedbackInput } from "../../Input";

const RecoverPassword = () => {
	const { setTransitionToken, userInfo } = useContext(SignupContext);
	const formRef = useRef()
	const [successText, setSuccessText] = useState();

	useEffect(()=>{
		if (formRef.current && userInfo) {
			formRef.current.email.value = userInfo.email
		}
	},[userInfo, formRef])

	const doTheReset = (e) => {
		e.preventDefault();
		let email = e.nativeEvent.target.email.value
		const postObj = {
			action: "forgot_password",
			email,
			request_domain: window.location.origin,
		};
		APIPost("users", postObj).then((response) => {
			if (response.status === 200) {
				setSuccessText(
					<div className="text-center color_green font_15px">
						<i>
							<T>Check your inbox for a link to reset password.</T>
						</i>
					</div>
				);
			}
		});
	};

	return (
		<div>
			<div className="px-4 py-1">
				<p className="pt-4 font_15px mb-0 font_weight_600">
					<T>Can't log in?</T>
				</p>
				<p className="font_15px mb-0">
					<T>We'll send a recovery link to</T>
				</p>
				{successText && (
					<div className="py-4 grey_frontpage">{successText}</div>
				)}
				<form onSubmit={doTheReset} ref={formRef}>
				<FeedbackInput
					autofocus={true}
					type="email"
					name="email"
					id="email"
					className="pt-4 w-100"
					placeholder="Email"
				/>
				<button
					type="submit"
					className="text-white font_15px btn btn_green w-100 mt-4 mb-2 border-0"
				>
					<T>Send recovery link</T>
				</button>
				</form>
				<button
					onClick={() => setTransitionToken("AlreadyUser")}
					style={{ fontWeight: "normal" }}
					className="btn btn_white font_13px w-100 mb-1 border-0 py-2"
				>
					<T>Back to login</T>
				</button>
			</div>
		</div>
	);
};

export default RecoverPassword;
