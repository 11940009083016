import React from "react";
import { Container } from "reactstrap";
import PromotionalCarousel from "../c/Carousel";
import ZevioNav from "../c/ZevioNav";
import PremiumStreamsContent from "../c/PremiumStreamsContent";
import Footer from "../c/Footer";
import { url_lang } from "../languages";

export const PremiumStreams = () => {
	return (
		<React.Fragment>
			<ZevioNav />
			<PromotionalCarousel searchParams={{
				'limit': '3',
				'paidonly': true,
				'futureandpast': true,
				'lang': [...new Set([url_lang().id, 3])].join(','),
			}} />
			<Container fluid className="bg_white">
				<PremiumStreamsContent />
			</Container>
			<Footer />
		</React.Fragment>
	);
}

export default PremiumStreams;
