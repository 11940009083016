import React, { useContext, useState, useEffect } from "react";
import { APIGet } from '../API.js';
import { T } from '../traks';
import { LL } from "../Routes";
import { Dropdown, DropdownMenu, } from 'reactstrap';
import { url_lang } from "../languages";
import { ImageWithSrcSet } from './ReusableElems';
import { MeContext } from '../MeContext';
import I18N from '../I18N';

const canEditEvent = (user, eventOrganizers) => {
	if(!user) {
		return false;
	}

	if(user.roles.includes(42) || eventOrganizers.filter(e => user.organizers.includes(e.Id)).length)
		return true;

	return false;
}

const ZevioSearchBar = (props) => {
	const handleChange = (e) => {
		setString(e.target.value);
	}
	const language = url_lang().id;
	const searchLang = [...new Set([language, 3])].join(',');
	const [string, setString] = useState('');
	const [eventUpcomingData, setUpcomingEventData] = useState([]);
	const [eventPastData, setPastEventData] = useState([]);
	const { me } = useContext(MeContext);

	useEffect(() => {
		const params = {
			'string': string,
			'limit': 5,
			'oldevents': false,
			'lang': searchLang,
		};
		//Upcoming event
		if (string.length > 1) {
			APIGet('search', params)
				.then((res) => {
					setUpcomingEventData(res.data.body);
				})
		}
		//Past event
		const params2 = {
			'string': string,
			'limit': 5,
			'oldevents': true,
			'lang': searchLang,
		};
		if (string.length > 1) {
			APIGet('search', params2)
				.then((res) => {
					setPastEventData(res.data.body);
				})
		}
	}, [string, searchLang]);

	const EventResult = (props) => {
		let thumbImageElem;
		if(props.eventData.ThumbImageURL) {
			thumbImageElem = <img className="search_icon" src={props.eventData.ThumbImageURL} alt="Event thumb" />;
		}
		if(props.eventData.ThumbnailImage) {
			thumbImageElem = <ImageWithSrcSet className="search_icon" width="53" height="46" URL={props.eventData.ThumbnailImage} imgstr='eventimages/thumb' img2xstr='eventimages/2xthumb' alttxt="Event thumb"/>;
		}
		
		let eventDateTime = <T>On demand</T>;
		if (props.eventData.startTimeStamp > Date.now()/1000) {
			eventDateTime = I18N.datetime_shorter(new Date(props.eventData.startTimeStamp * 1000));
		}
		
		return (
			<div onClick={() => setDropdownOpen(false)} className="d-flex flex-row ps-2 py-lg-2">
				<div className="px-xl-2 pe-3">
					{thumbImageElem}
				</div>
				<div className="col-7 col-lg-5">
					<a className="link_nostyle" href={LL("/event/") + (props.eventData.Safename + ("-") + props.eventData.Id)}>
						<div className="font_weight_700 lineclamp">{props.eventData.Title}</div>
						<div className="font_13px lineclamp">{props.eventData.SubTitle}</div>
					</a>
				</div>
				<div className="col-lg-2 ps-xl-3 font_13px grey_frontpage d-flex justify-content-center flex-column lineclamp">
					<a className="link_nostyle" href={LL("/event/") + (props.eventData.Safename + ("-") + props.eventData.Id)}>
						{eventDateTime}
					</a>
				</div>
				<div className="col-lg-3 font_13px flex-column d-none d-lg-flex align-items-start justify-content-center">
					<a className="link_nostyle" href={LL("/event/") + (props.eventData.Safename + ("-") + props.eventData.Id)}>
					{props.eventData.organizers.slice(0, 2).map((v, i) => (
						<div key={i}>
							<span key={i}>{v.Name}</span>
						</div>
					))}
					</a>
				</div>
				{ canEditEvent(me, props.eventData.organizers) ?
					<div className="d-none d-lg-flex flex-column col-lg-1 align-items-start justify-content-center">
						<a className="link_nostyle" href={LL("/editevent/") + props.eventData.Id}>
							<img style={{height: "24px"}} src="https://static.zevio.com/gfx/gear-icon-0.svg" alt='edit' />
						</a>
					</div>
					:
					<a className="link_nostyle" href={LL("/event/") + (props.eventData.Safename + ("-") + props.eventData.Id)}>
						<span className="carousel-control-next-icon py-xl-4 d-none d-lg-block"></span>
					</a>
				}
			</div>
		);
	}

	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => null;
	const search_placeholder = props.render_static(<T>Search events..</T>);
	const search_bar_open = dropdownOpen && string.length;

	return (
		<div className="w100_mobile">
			<form id="topbar_search_field" className="form-inline mt-lg-1">
				<input autoComplete="off"
					placeholder={search_placeholder}
					onChange={handleChange}
					type="text" className={props.cssClasses}
					onFocus={() => setDropdownOpen(true)}
				/>
				<Dropdown isOpen={Boolean(search_bar_open)} toggle={toggle}>
					{eventUpcomingData.length || eventPastData.length ?
						<DropdownMenu className="search_dropdown"> {/*dropdown_scroll_menu*/}
							{eventUpcomingData.length ?
								<span>
									{eventUpcomingData.map((v, i) => (
										<div key={i} className="bottom_border_promo_listing">
											<EventResult eventData={v} />
										</div>
									))}
								</span>
								: null}
							{eventPastData.length ?
								<span>
									{eventPastData.map((v, i) => (
										<div key={i} className="bottom_border_promo_listing">
											<EventResult eventData={v} />
										</div>
									))}
								</span>
								: null}
						</DropdownMenu>
						: <DropdownMenu className="search_dropdown ps-2 py-2"> <span><T>No search results</T></span> </DropdownMenu>}
				</Dropdown>
			</form>
		</div>
	);
}

export default ZevioSearchBar;
