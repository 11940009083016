import { createContext } from 'react';

const MeContext = createContext({
	me: null,
	setMe: () => {},
});

export {
	MeContext,
};
