import React, { useState, useEffect, useContext, useRef } from "react";
import { Modal, ModalBody } from 'reactstrap';
import { MeContext } from '../MeContext';
import { T, TraksConsumer } from '../traks';
import { LL } from "../Routes";
import { GreenButton } from "../c/ReusableElems";
import ScrollableFeed from 'react-scrollable-feed';
import Linkify from 'react-linkify';

const ChatInput = ({chatInputRef, sendChat, render_static}) => {
	return (
		<React.Fragment>
			<span className="chat_tooltip ps-2 py-1"><T>Be nice. Do not spam. <a target='_blank' rel='noopener noreferrer' href={LL('/terms')}>Terms apply</a></T></span>
			<div className='px-2 pt-1'>
				<input
					ref={chatInputRef}
					onKeyPress={(e) => {
						if (e.key === "Enter" && e.target.value.length) {
							e.preventDefault();
							const msg = e.target.value;
							e.target.value = "";
							sendChat(msg);
							chatInputRef.current.focus();
						}
					}}
					autoComplete='nope'
					type='text' className='col-form-label-sm zevio_chat_input no_outline_focus input_search w-100'
					placeholder={render_static(<T>Enter your message...</T>)}

				/>
			</div>
		</React.Fragment>
	);
};

const ChatComponent = ({eventData}) => {
	let connectUserName = '__85bb65__'; // Placeholder for read-only chat
	const [ nickName, setNickName ] = useState(connectUserName);
	const [ messages, setMessages ] = useState([]);
	const ws = useRef(null);
	const chatInputRef = useRef(null);
	const { me } = useContext(MeContext);
	const sessionId = localStorage.getItem("zevio_ipse_session_id");
	const baseURL = 'wss://q97umw48a6.execute-api.eu-north-1.amazonaws.com/production/';
	let userId = 0;
	let organizerIds = [];
	const wsTimer = useRef(null);
	const pingInterval = 60000;

	eventData.organizers.forEach( (o) => organizerIds.push(o.Id));
	const sendChat = (msg) => {
		const message = { "action": "chatmessage", "data": encodeURI(msg) };
		ws.current.send(JSON.stringify(message));
		clearTimeout(wsTimer.current);
		wsTimer.current = setTimeout(() => { ping(); }, pingInterval);
	}

	if(me && me.firstName && connectUserName !== me.firstName)
		connectUserName = me.firstName;
	if(me && me.Id && userId !== me.Id)
		userId = me.Id;

	const ping = () => {
		console.log("Ping");
		ws.current.send(`{"action": "ping"}`);
		wsTimer.current = setTimeout(() => { ping(); }, pingInterval);
	}

	const SelectChatNickNameModal = (props) => {
		const [ nickName, setNickName ] = useState();
		const [ chatNameModal, setChatNameModal ] = useState(false);
		const placeHolder = props.render_static(<T>Chat name</T>);

		const doneSetting = () => {
			connectUserName = nickName;
			connectWS(true);
			toggleNickNameModal();
		}

		const toggleNickNameModal = () => {
			setChatNameModal((ocm) => !ocm);
		}

		return (
			<div className="p-2">
			<GreenButton className="btn-sm" onClick={toggleNickNameModal}><T>Start chatting</T></GreenButton>
			<Modal centered isOpen={chatNameModal} toggle={toggleNickNameModal}>
				<ModalBody className="text-center">
					<div>
						<img className="btn" style={{ float: 'right' }} src="https://static.zevio.com/gfx/ico_close.svg" alt='icoClose' onClick={toggleNickNameModal} />
					</div>
					<div className="ps-5 pt-3 image-container">
						<div className="icon_modal_chatname">
							<img src="https://static.zevio.com/gfx/ico_chatname.svg" alt='icochatname' />
						</div>
					</div>
					<div className="pt-3">
						<h5><T>Select your chat name</T></h5>
					</div>
					<div className="pt-1 pb-4">
						<T>This will be the name you chat with. It has a limit of 25 characters.</T>
					</div>
					<div>
						<input
							className="zevio_input w-100"
							placeholder={placeHolder}
							onChange={(e) => setNickName(e.target.value)}
						/>
					</div>
					<div className="pt-3 pb-4">
						<GreenButton
							className="w-100"
							onClick={doneSetting}><T>Set chat name</T>
						</GreenButton>
					</div>
				</ModalBody>
			</Modal>
			</div>
		);
	}

	const connectWS = (wipeCurrentWS) => {
		if(wipeCurrentWS) {
			setMessages([]);
			ws.current.close();
		}
		const connectURL =
			baseURL + "?userName=" + connectUserName + "&eventId=" + eventData.Id + "&sessionId=" + sessionId +
			"&userId=" + userId + "&eventOrganizer=" + JSON.stringify(organizerIds);
		ws.current = new WebSocket(connectURL);

		ws.current.onopen = (e) => {
			wsTimer.current = setTimeout(() => { ping(); }, pingInterval);
		}

		ws.current.onmessage = (e) => {
			const msgs = JSON.parse(e.data).msgs;
			setMessages((oldMessages) => [...oldMessages, ...msgs]);
		}

		ws.current.onclose = (e) => {
			clearTimeout(wsTimer.current);
		};

		ws.current.onerror = (e) => {
			console.log("Error:" + e.data);
			clearTimeout(wsTimer.current);
		};

		setNickName(connectUserName);
	}

	useEffect( () => {
		if(!ws.current) {
			connectWS(false);
		}

		return () => {
			console.log('cleaning up');
			ws.current.close();
			clearTimeout(wsTimer.current);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);



	return (
		<div className="bg_white border_1px_solid border_radius_10px d-flex flex-column-reverse align-items-stretch overflow-hidden inner_chat_component">
			<TraksConsumer>
				{nickName === '__85bb65__' ? <SelectChatNickNameModal /> : <ChatInput chatInputRef={chatInputRef} sendChat={sendChat} />}
			</TraksConsumer>
			<div className="px-2 pt-1 pb-1 m-1 overflow-auto mh-100">
				<ScrollableFeed>
					{messages && messages.map((message, index) =>
						<div key={index} id="webinar_chat_msg_div">
							<span className="zevio_chat_username">{decodeURI(message.userName)}</span>: <span className="zevio_chat_message">
								<Linkify
									componentDecorator={(decoratedHref, decoratedText, key) => (
										<a target="_blank" className='text_underline' rel='noopener noreferrer' href={decoratedHref} key={key}>
											{decoratedText}
										</a>
									)}
								>
									{decodeURI(message.msg)}
								</Linkify>
							</span>
						</div>
					)}
				</ScrollableFeed>
			</div>
		</div>
	)
}

export default ChatComponent;
