import React, { useContext, useEffect, useState } from "react";
import { APIPost } from "../../API";
import DateSelect from "./DateSelect";
import Table from "./Table";
import Footer from "../Footer";
import ZevioNav from "../ZevioNav";
import { Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import { MeContext } from "../../MeContext";
import { T, TraksConsumer } from '../../traks';

const OrderHub = () => {
	return (
		<>
			<ZevioNav />
			<Container>
				<Row className="mb-5">
					<Col xs="12" className="px-4 mx-auto border_radius_8px promotion_box bg-white">
						<h3 className="pt-4"><T>Welcome to your order hub</T></h3>
						<div className="webinar-small-heading">
							<T>This is where you manage your orders from Zevio</T>
						</div>
						<TraksConsumer>
							<OrdersList />
						</TraksConsumer>
					</Col>
				</Row>
			</Container>
			<Footer />
		</>
	);
};

const OrdersList = ({render_static}) => {
	const queryStringParams = new URLSearchParams(window.location.search);
	const [product, setProduct] = useState(
		queryStringParams.get("productId")
	);
	const [orderStatus, setOrderStatus] = useState("Authorized");
	const [products, setProducts] = useState([]);
	const [fromDate, setFromDate] = useState("");
	const [toDate, setToDate] = useState("");
	const [orders, setOrders] = useState([]);
	const { me } = useContext(MeContext);

	useEffect(()=>{
		// Set the initial span to two weeks
		const d = new Date(Date.now())
		const d2 = new Date(Date.now()-1209600000)
		setFromDate(d2.toISOString().split("T")[0])
		setToDate(d.toISOString().split("T")[0])
	}, [])

	useEffect(() => {
		const GetProductsData = () => {
			APIPost("products", {
				action: "get_products",
				organizer_ids: me.organizers,
			})
				.then((res) => {
					const values = res.data.products.filter(p => p.active).map((p) => ({id:p.Id, name:p.headline ? p.headline : p.Id}));
					setProducts(values);
				})
				.catch((error) => {
					console.log(["Error getting products:", error]);
				});
		};
		GetProductsData();
	}, [me.organizers]);


	useEffect(() => {
		const getOrder = (productId) => {
			let filter = [];
			if (orderStatus !== "All") filter.push(`orderStatus|eq|${orderStatus}`);
			if (fromDate && toDate) filter.push(`createdTs|between|${fromDate}:${toDate}`);
			APIPost("products", {
				action: "get_orders_amopus",
				product_id: productId,
				...(filter.length ? { filter: filter.join(",") } : {}),
			}).then((e) => {
				if (e.status === 200) {
					setOrders(e.data.orders);
				}
			});
		};
		if (product?.id) {
			getOrder(product.id);
		}
	}, [fromDate, orderStatus, product, toDate]);

	return (
		<>
			<div className="d-flex flex-row w-100 pt-3">
				<DateSelect
					fromDate={fromDate}
					setFromDate={setFromDate}
					toDate={toDate}
					setToDate={setToDate}
				/>
				<TraksConsumer>
					<ProductSelect {...{ product, products, setProduct }} />
				</TraksConsumer>
			</div>
			<div>
				<button
					onClick={() => setOrderStatus("Authorized")}
					className={`px-3 py-2 me-2 ${orderStatus === "Authorized" ? "orderstatustabselected" : "orderstatustabdeselected"}`}
				>
					<T>Pending</T>
				</button>
				<button
					onClick={() => setOrderStatus("All")}
					className={`px-3 py-2 ${orderStatus === "All" ? "orderstatustabselected" : "orderstatustabdeselected"}`}
				>
					<T>All</T>
				</button>
			</div>
			<Table {...{ orders }} />
		</>
	);
};

const ProductSelect = ({ products, setProduct, product, render_static }) => {
	const [isOpen, setIsOpen] = useState(false)
	return (
		<div className="m-3">
			<label htmlFor="productDropdown" className="form-label"><T>Select product</T></label>
		<Dropdown id="productDropdown" isOpen={isOpen} toggle={()=> setIsOpen(p => !p)} className="m-1">
			<DropdownToggle caret className="zevio_input" >
				{product?.name ? product.name : render_static(<T>Select product</T>)}
			</DropdownToggle>
				<DropdownMenu container="body">
			{products.map((p) => (
				<DropdownItem key={`item-${p.id}`} onClick={()=> setProduct(p)}>{p.name}</DropdownItem>
			))}
			</DropdownMenu>
		</Dropdown>
		</div>
	);
};

export default OrderHub;
