import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ZevioNav from '../c/ZevioNav';
import Footer from '../c/Footer';
import BigText from '../c/BigText';

const TermsOfTrade = () => {
	return (
		<React.Fragment>
			<ZevioNav />
			<Container>
				<Row className="py-4">
					<Col xs="12" lg="10" className="promotion_box mx-auto px-3 pb-3 pb-lg-5 px-lg-5 bg-white">
						<div className="privacy_page">
							<BigText k="trade_terms"/>
						</div>
					</Col>
				</Row>
			</Container>
			<Footer />
		</React.Fragment>
	);
}

export default TermsOfTrade;
