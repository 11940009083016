import React, { lazy, useCallback, useContext, useEffect, useRef, useState } from 'react';
import ChatComponent from './ChatComponent';
import axios from 'axios';
import { T } from '../traks';
import { MeContext } from '../MeContext';
import { countPlayEvent } from '../API';
import { EventDateTime } from './ReusableElems';
import { CountDownSignupElement } from './Event';

const ZeVideoPlayer = lazy( () => import('./VideoPlayer') );

const DotLiveOrOndemand = ({live, startTs}) => {
	const rightNow = parseInt(Date.now() / 1000);
	let dotClassName = "video_symbol_ondemand";
	let statusText = "On demand";
	if(live) {
		dotClassName = "video_symbol_live";
		statusText = "Live";
	} else if(startTs - rightNow > 0) {
		statusText = <><span><T>Live on </T></span><EventDateTime eventStartTs={startTs} /></>;
	}
	return (
		<div className="vertical_line_tiny">
			<svg className={dotClassName} viewBox="0 0 13 13" width="13" height="13" xmlns="http://www.w3.org/2000/svg">
				<circle cx="6.5" cy="6.5" r="5.5"/>
			</svg>
			<span className="px-2">{statusText}</span>
		</div>
	);
}

const Watch = ({eventData = null}) => {
	const [ liveNow, setLiveNow ] = useState(eventData.LiveNow);
	const [ showChat, setShowChat ] = useState(false);
	let streamCheckCount = 0;
	const alreadyCountedPlayingRef = useRef(null);
	const playerRef = useRef(null);
	const isIVSstream = !(eventData.VideoURL && eventData.VideoURL !== eventData.PlaybackURL);
	const { me } = useContext(MeContext);
	const rightNow = useRef(Math.floor(Date.now()/1000)).current;
	let videoOptions = {
		techOrder: ["AmazonIVS"],
		controls: true,
		autoplay: isIVSstream, // We autoplay IVS streams
	}

	useEffect( () => {		
		if (eventData.ShowChat === true && (rightNow > eventData.startTimeStamp - 1800 && rightNow < eventData.startTimeStamp + 21600))
			setShowChat(true);
	}, [eventData, rightNow]);
 
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const checkStream = useCallback( () => {
		if (playerRef.current && window.location.pathname.includes(eventData.UniqueHash)) {
			const retryMax = 900;
			const waitingMs = 1500; // 1500 miliseconds
			if(isIVSstream) {
				axios.head(eventData.PlaybackURL).then( (res) => {
					playerRef.current.src(eventData.PlaybackURL);
				}).catch( () => {
					if(streamCheckCount < retryMax) {
						setTimeout(() => {
							streamCheckCount = streamCheckCount + 1;
							checkStream();
						}, waitingMs);
					}
				});
			}
		}
	});

	const liveStreamEnded = () => {
		setLiveNow(false);
	};
	console.log({showChat, liveNow, oldEvent:rightNow>eventData.startTimeStamp})
	const handlePlayerReady = (player) => {
		playerRef.current = player;
		const IVSplayer = player.getIVSPlayer();
		IVSplayer.addEventListener("Ready", () => {
			const qualities = IVSplayer.getQualities();
			qualities.forEach( (quality) => {
				if(quality.name.substr(0, 3) === '720') {
					IVSplayer.setQuality(quality, true);
				}
			});
		});

		player.on("playing", () => {
			if(!alreadyCountedPlayingRef.current) {
				alreadyCountedPlayingRef.current = true;
				countPlayEvent(eventData.Id);
			}
		});

		playerRef.current.enableIVSQualityPlugin();

		if(isIVSstream) {
			playerRef.current.src(eventData.PlaybackURL);
			IVSplayer.addEventListener("Playing", () => {
				setLiveNow(true)
			});
			IVSplayer.addEventListener("Ended", () => {
				liveStreamEnded();
			});
			IVSplayer.addEventListener("PlayerError", (e) => {
				console.log(["PlayerError IVS event thrown:", e]);
				checkStream();
			});
		} else {
			player.src(eventData.VideoURL);
			player.on("ended", () => {
				player.bigPlayButton.show(true);
			});
			if(eventData.seekSeconds) {
				const video_html_elem = document.querySelector('video');
				if (video_html_elem) { // silence crash; video_html_elem null
					video_html_elem.addEventListener("canplay", (event) => {
						player.currentTime(eventData.seekSeconds);
					}, { once: true });
				}
			}
		}
	}

	const WatchVideoInfo = () => {
		return (
			<div className='d-flex justify-content-between'>
				<div className="d-flex flex-row pt-2 pt-lg-3 justify-content-center justify-content-lg-between">
					<div className='d-flex watch_video_info'>
						<DotLiveOrOndemand startTs={eventData.startTimeStamp} live={liveNow} />
						<div className="vertical_line_tiny d-none d-lg-block px-2">{eventData.Title}</div>
						<div className="vertical_line_tiny text-capitalize px-2">{eventData.LanguageName}</div>
						<div className="px-2">{eventData.CategoryName}</div>
					</div>
				</div>
			</div>
		);
	}


	return (
		<React.Fragment>
		<div className="d-flex flex-column flex-lg-row">
			<div className="video flex-grow-1">
				<div className="video_player_elem">
					<ZeVideoPlayer options={videoOptions} onReadyHandler={handlePlayerReady} />
					{ !liveNow && rightNow<eventData.startTimeStamp ? <div className='w-100 h-100 d-flex flex-column text-white position-absolute text-center top-0 start-0'><CountDownSignupElement/></div> : null}
				</div>
			</div>
			<div className="d-lg-none px-2">
				<WatchVideoInfo />
			</div>
			{showChat && <div className="live_page_chat px-1 px-lg-0 ps-lg-2 pt-2 pt-lg-0"><ChatComponent eventData={eventData} /></div>}
		</div>
		<div className="d-none d-lg-block">
			<WatchVideoInfo />
		</div>
		</React.Fragment>
	);
}

export default Watch;
