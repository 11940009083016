import React, { useContext, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { T } from "../../traks";
import OrderContext, { useOrder } from "../Amopus/useOrder";
import ShowProduct from "./ShowProduct";
import CheckoutAccordion from "./CheckoutAccordion";
import PurchaseComplete from "./PurchaseComplete";
import TransactionSummary from "./TransactionSummary";
import CheckoutContext, { useCheckout } from "../Amopus/useCheckout";

const BuyProductModal = ({ productData, className }) => {
	let orderCtx = useOrder(productData);
	let checkoutCtx = useCheckout();
	const [modalVisibility, setModalVisibility] = useState(false);
	const [modalState, setModalState] = useState(0);
	const closeIconClick = () => {
		setModalState(0);
		setModalVisibility(false);
		checkoutCtx.setOrderId()
		checkoutCtx.setCheckoutType()
		checkoutCtx.setError()
	};
	

	const closeIcon = (
		<img
			className="btn btn_grey border-0 position-absolute top-0 end-0 bg-white p-2 "
			style={{ zIndex: 100 }}
			onClick={closeIconClick}
			src="https://static.zevio.com/gfx/ico_close.svg"
			alt="icoClose"
		/>
	);
		
	return (
		<OrderContext.Provider value={orderCtx}>
			<CheckoutContext.Provider value={checkoutCtx}>
				<div className="m-2">
					<button
						onClick={() => setModalVisibility(true)}
						className={className}
					>
						<T>Buy</T>
					</button>
					<Modal
						fade={false}
						isOpen={modalVisibility}
						fullscreen="md"
						size="xl"
						centered
					>
						<ModalBody>
							<div>
								{closeIcon}
								<BackButton
									onClick={() => setModalState((p) => p - 1)}
									modalState={modalState}
								/>
								{
									[
										<ShowProduct onClick={() => setModalState((p) => p + 1)} />,
										<>
											<div className="d-flex flex-row gap-2">
												<CheckoutAccordion
													onSuccess={() => setModalState((p) => p + 1)}
													onError={closeIconClick}
												/>
												<div className="d-none d-lg-flex rounded bg-light p-3 w-100 p-xl-5">
													<TransactionSummary />
												</div>
											</div>
										</>,
										<PurchaseComplete />,
									][modalState]
								}
							</div>
						</ModalBody>
					</Modal>
				</div>
			</CheckoutContext.Provider>
		</OrderContext.Provider>
	);
};

const BackButton = ({ onClick, modalState }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const {orderDetails, showCurrency} = useContext(OrderContext);
	const dropdownAvailable = 0 < modalState && modalState < 3;
	if (!modalState || modalState === 4) return null;
	return (
		<div className="text-bg-light rounded w-100 justify-content-center p-3 d-lg-none">
			<svg
				className="position-absolute"
				onClick={onClick}
				width="30"
				height="30"
				viewBox="0 0 20 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				style={{ zIndex: 100 }}
			>
				<path
					d="M3.75 10H16.875"
					stroke="#77797e"
					strokeWidth="1.5"
					strokeLinecap="round"
				/>
				<path
					d="M8.125 5L3.125 10L8.125 15"
					stroke="#77797e"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
			{dropdownOpen && dropdownAvailable && <TransactionSummary />}
			<div
				className="d-flex flex-row justify-content-center btn gap-2"
				onClick={() => {
					if (dropdownAvailable) setDropdownOpen((p) => !p);
				}}
			>
				<div className="text-capitalize text-secondary">{showCurrency.format(
										parseInt(orderDetails.amount) / 100
									)}</div>
				{dropdownAvailable && (
					<img
						alt="caret"
						width="12"
						height="18"
						src={
							dropdownOpen
								? "https://static.zevio.com/gfx/ico_dropdown_toggle_open.svg"
								: "https://static.zevio.com/gfx/ico_dropdown_toggle_closed.svg"
						}
						className="dropdown_toggler pt-1"
					/>
				)}
			</div>
		</div>
	);
};

export default BuyProductModal;
