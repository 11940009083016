import React, { useContext } from "react";
import { Container } from "reactstrap";
import Countdown from "../Countdown";
import {
	CollapsibleTroubleshoot,
	ImageWithSrcSet,
	smallCoverImageURL,
	EventDateTime,
} from "../ReusableElems";
import { T } from "../../traks";
import Watch from "../Watch";
import { MeContext } from "../../MeContext";
import SignupModal, { SignUpModalButton } from "./SignupModal";
import SignupContext, { useSignup } from "./useSignup.js";
import EventCtx from "./useEvent.js";
import ProductsCtx, { useProducts } from "./useProducts.js";
import { APIGet } from "../../API";
import ImagePlaceHolder from "./ImagePlaceHolder";
import ProductCardComponent from "./ProductCardComponent";
import OrganizerCardsComponent from "./OrganizerCardsComponent";
import VideoProductModal from "./VideoProductModal";
import PremiumEvents from "../../c/PremiumEvents";

const EventProfile = () => {
	const {eventData, eventId} = useContext(EventCtx)
	const signupCtx = useSignup()
	const productsCtx = useProducts(eventId)

	if (!eventData) return null;
	if (
		eventData === 404 ||
		(!eventData.SaveWebinar &&
			eventData.startTimeStamp + 48 * 60 * 60 < parseInt(Date.now() / 1000))
	) {
		return (
			<Container className="pt-5 text-center">
				<div className="mx-auto">
					<h1 className="pb-4">404</h1>
					<div>
						<b>
							<T>This event does not exist, or has not yet been released to the public.</T>
						</b>
					</div>
					<T>Please contact us, if you believe this is in err.</T>
				</div>
			</Container>
		);
	}
	if (Object.keys(eventData).length) {
		return (
			<SignupContext.Provider value={signupCtx}>
				<ProductsCtx.Provider value={productsCtx}>
				<Container className="px-0 pt-0 pt-lg-3">
					<PlayerComponent/>
					<EventContent/>
				</Container>
				</ProductsCtx.Provider>
			</SignupContext.Provider>
		);
	} else {
		return <ImagePlaceHolder />;
	}
};


export const CountDownSignupElement = () => {
	const { eventData } = useContext(EventCtx);
	const rightNow = parseInt(Date.now() / 1000);
	if (!eventData || eventData.startTimeStamp < rightNow) return null;
	return (
		<div className="pb-lg-5">
				<div>
					<div className="pt-lg-5 font_weight_700 d-none d-lg-block">
						<T>Event starting in:</T>
					</div>
					<div className="countdown_text pt-lg-4 d-flex justify-content-center">
						<Countdown dateVal={eventData.startTimeStamp} />
					</div>
				</div>
		</div>
	);
};


const OnEventUrlComponent = () => {
	const { eventData } = useContext(EventCtx);
	const {alreadySigned, signupComplete, setSignupComplete } = useContext(SignupContext);
	if (!eventData) return null;
	/* Legacy support for old image handling system */
	let coverImageElem, smallCoverImageElem;
	if (eventData.ImageURL) {
		coverImageElem = smallCoverImageElem = (
			<img
				src={eventData.ImageURL}
				width="100%"
				height="100%"
				alt="Event Hero"
				className="mw-100 w-100"
			/>
		);
	}
	if (eventData.CoverImage) {
		coverImageElem = (
			<ImageWithSrcSet
				width="100%"
				height="100%"
				className="w-100 d-none d-md-block"
				URL={eventData.CoverImage}
				imgstr="eventimages/cover"
				img2xstr="eventimages/2xcover"
				alttxt="Event Hero"
			/>
		);
		smallCoverImageElem = (
			<ImageWithSrcSet
				width="100%"
				height="100%"
				className="w-100 d-md-none"
				URL={smallCoverImageURL(eventData.CoverImage)}
				imgstr="eventimages/smallcover"
				img2xstr="eventimages/2xsmallcover"
				alt="Event Hero"
			/>
		);
	}
	let eventDateTime = <T>On demand</T>;
	if (eventData.startTimeStamp > Date.now()/1000) {
			eventDateTime = <EventDateTime eventStartTs={eventData.startTimeStamp} />
	}
	return (
		<>
			<div className="position-relative event_cls min_w100">
				<div className="event_profile">
					<div className="d-none d-md-block">{coverImageElem}</div>
					<div className="d-md-none">{smallCoverImageElem}</div>
					<div
						style={{ background: "rgba(42, 45, 50, 0.600)" }}
						className="p-0 h-100 w-100 position-absolute top-0 start-0"
					/>
				</div>
				<div className="w-100 h-100 d-flex flex-column text-white position-absolute text-center top-0 start-0">
					<div className="h-100 d-flex flex-column align-items-center justify-content-center pt-4 py-lg-0">
						<div>
							<h2 className="px-lg-3">{eventData.Title}</h2>
						</div>
						<div>
							<h5>{eventData.SubTitle}</h5>
						</div>
						<CountDownSignupElement/>
						{(alreadySigned || signupComplete) &&(
							<SignUpCompleted/>
						)}
<div className="pt-4 d-none d-lg-block"><SignUpModalButton/></div> 
					</div>
				</div>
			</div>
			<div className="d-flex flex-wrap pt-lg-3 px-2 px-lg-0 event_hero_bottom_info_text ">
				<div className="">
					<div className="vertical_line_tiny pe-2">
					<img
						className="me-1 me-lg-2 mb-1"
						width="16"
						height="16"
						src="https://static.zevio.com/gfx/ico_calendercard.svg"
						alt="Calendar icon"
					/>
					{eventDateTime}
					</div>
				</div>
				<div className="vertical_line_tiny d-none d-lg-block px-2">
					{eventData.SubTitle}
				</div>
				<div className="vertical_line_tiny text-capitalize px-2">
					{eventData.LanguageName}
				</div>
				<div className="ms-2">{eventData.CategoryName}</div>
			</div>
			<div className="pt-4 d-block d-lg-none">
					<SignUpModalButton/>
				</div>
				<SignupModal
					eventData={eventData}
					setSignupComplete={setSignupComplete}
				/>
		</>
	);
};

const PlayerComponent = () => {
	const {eventData, isOnLiveUrl} = useContext(EventCtx)
	return (
		<div>
			{isOnLiveUrl ? (
				<span>
					{" "}
					<VideoProductModal />{" "}
					<Watch eventData={eventData} />{" "}
				</span>
			) : (
				<OnEventUrlComponent/>
			)}
		</div>
	);
};

const EventContent = () => {
	const {eventData, isOnLiveUrl} = useContext(EventCtx);
	const organizers = eventData?.organizers.map(o => o.Name);
	return (
		<div className="py-3 py-lg-5 px-2 px-lg-0">
			<div className="d-flex flex-column flex-lg-row justify-content-between">
				<div className="mw_944px event_profile_descriptions">
					<div className="flex-grow-1">
						<h1>{eventData.Title}</h1>
						<div style={{fontSize: '110%'}}><T>This stream is organized by</T> {organizers.join(', ')}</div>
						<div className="pt-3 pb-4 font_weight_700">
							{eventData.DescriptionShort}
						</div>
						{isOnLiveUrl ? (
							<div className='pb-3 overflow_wrap'
								dangerouslySetInnerHTML={{
									__html: eventData.EmbedPagePromoText,
								}}
							/>
						) : (
							<span
								dangerouslySetInnerHTML={{ __html: eventData.Description }}
							/>
						)}
						{isOnLiveUrl ? (
							<div className="pt-2 pt-lg-4">
								<CollapsibleTroubleshoot />
							</div>
						) : null}
						<div className="pt-5">
							<ProductCardComponent />
						</div>
					</div>
				</div>
				<div className="ps-lg-5">
					<OrganizerCardsComponent/>
				</div>
			</div>
			<div className="pt-3 pt-lg-5">
				<div className="explore_subtitle"><T>Premium streams</T></div>
				<PremiumEvents />
			</div>
		</div>
	);
};


const SignUpCompleted = () => {
	const { me } = useContext(MeContext);
	const {suggestFollow} = useContext(EventCtx)
	return (<>
			{me !== null && suggestFollow ?
				<div className="bg_white border_radius_8px px-4 py-2 zevio_dark_grey py-lg-4">
					<FollowOrganizers/>
				</div>
			: null}
	</>);
};


const FollowOrganizers = () => {
	const {setSuggestFollow, followStr, organizerIdsOnEvent} = useContext(EventCtx)
	const doFollowOrgsAndFinish = (organizerIds) => {
		APIGet("sequor", {
			action: "like_organizers",
			organizerIds: organizerIds.join(","),
		})
			.then((res) => {
				console.log(res);
			})
			.catch((error) => {
				console.log("Error fetching followers:", error);
			});
		setSuggestFollow(false);
	};
	return (
		<>
			{!!followStr.length && (
				<div className="text-start" style={{ maxWidth: "330px" }}>
					<div className="py-2">
						<T>Would you like to follow</T>&nbsp;<b>{followStr}</b>?
					</div>
					<div className="text-center">
						<button
							className="my-2 btn btn_green px-5 py-lg-2 me-3"
							onClick={() => {
								doFollowOrgsAndFinish(organizerIdsOnEvent);
							}}
						>
							<span className="px-1">
								<T>Yes</T>
							</span>
						</button>
						<button
							onClick={() => setSuggestFollow(false)}
							className="btn btn_grey me-lg-2 px-lg-4 mb-lg-0 py-lg-2 font_weight_500"
						>
							<T>Maybe later</T>
						</button>
					</div>
				</div>
			)}
		</>
	);
};

export default EventProfile;
