import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { T } from '../traks';

export const FAQEntry = (props) => {
	const [ showAnswer, setShowAnswer ] = useState(false);
	const toggle = () => {
		setShowAnswer(!showAnswer);
	}

	let toggle_img = "https://static.zevio.com/gfx/caret-open-0.svg";

	if(showAnswer) {
		toggle_img = "https://static.zevio.com/gfx/caret-closed-0.svg";
	}

	const toggle_element = (
		<span className="faq_toggle_button float-end">
			<img alt='toggle' className="mb-1" width="14" height="14" src={toggle_img}/>
		</span>
	);

	let question = "This is an FAQ question";
	let answer = "This is an FAQ answer. There are many answers like it. But this one is yours";

	if(props.question) {
		question = props.question;
	}
	if(props.children) {
		answer = props.children;
	}

	return (
		<div className="pb-2">
			<div className="faq_entry px-3 py-2">
				<div onClick={toggle} className="cursor-pointer font_15px d-flex flex-row justify-content-between align-items-center">
					<div>{question}</div><div className="ps-1">{toggle_element}</div>
				</div>
				{showAnswer &&
					<div className="pt-2 grey_frontpage">
						{answer}
					</div>
				}
			</div>
		</div>
	);
}

export const FAQ = (props) => {
	return (
		<Container className="pb-5">
			<h2 className="text-center">FAQ</h2>
			<Row className="justify-content-center">
				<Col xs="12" lg="10" xl="8" xxl="7" className="d-flex flex-column">
					<FAQEntry question={props.render_static(<T>Can I watch talks after they are over if I get delayed?</T>)}>
						<T>Yes, but for a limited time.<br />
						Our speakers and organisers decide how long an event will be available.<br />
						We aspire to keep all events viewable for at least 48 hours after we go live. However, this is entirely
						at the discretion of the organisers.</T>
					</FAQEntry>
					<FAQEntry question={props.render_static(<T>How much does it cost?</T>)}>
						<T>Enjoying live talks on Zevio is free.<br />
						Viewers may be signed up to mailing lists from organisers and Zevio when registering for a talk.
						You can, of course, unsubscribe from mailing lists at any time. Also, we want our speakers to be
						able to pursue their passion - and we do this by letting them give our viewers special offers.
						We take a commission on these sales.</T>

					</FAQEntry>
					<FAQEntry question={props.render_static(<T>Can I go live on Zevio?</T>)}>
						<T>Sure, but let’s talk first to make sure we got the technical aspects sorted out.<br/>
						Write us at <a href="mailto:hello@zevio.com">hello@zevio.com</a> if you are interested.
						Please include what you want to talk about, and a link to your homepage/company.</T>
					</FAQEntry>
					<FAQEntry question={props.render_static(<T>Can I ask my questions to the speakers?</T>)}>
						<T>Some speakers take questions during the sessions; some do not. The speakers decide.</T>
					</FAQEntry>
					<FAQEntry question={props.render_static(<T>Is it always live?</T>)}>
						<T>No. There have been instances of pre-recorded videos. Recordings happen when
						there is a conflict of calendars or unforeseen circumstances set in. We aspire to
						be live every time, but everyone has daily lives that take unexpected turns now
						and then.</T>
					</FAQEntry>
					<FAQEntry question={props.render_static(<T>What devices do you support?</T>)}>
						<T>You can watch Zevio talks on any reasonably new smartphone, tablet or computer.
						Any browser with a decent internet connection should do. However, some browsers
						may have restrictive policies on playing streams with sound and/or auto-playing
						streams.<br />
						It is not possible to provide support for every configuration of
						browser/computer/device out there.<br />
						We suggest using the free Zevio-app for a more streamlined experience. Find it
						where you usually get your apps.</T>
					</FAQEntry>
				</Col>
			</Row>
		</Container>
	);
}
