import { array2set } from './misc';
import languagedefs from './languagedefs.json';

const languages = languagedefs.map(x => x.lang);
const language_set = array2set(languages);

const url_lang = () => {
	const try_lang = window.location.pathname.split("/").slice(1)[0].toLowerCase();

	for(const languagedef of languagedefs) {
		if(languagedef.lang === try_lang) {
			return languagedef;
		}
	}
}

export {
	languages,
	language_set,
	languagedefs,
	url_lang,
}
