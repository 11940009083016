import React from "react";

const Flag = ({ lang = "", width = "48", height = "36", className="" }) => {
	switch(lang) {
		case 'da':
			return (
				<svg className={className} viewBox="0 0 48 36" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
					<g transform="matrix(1, 0, 0, 1, 0, -6)">
						<path fill="#D00C32" d="M48,40c0,1.105-0.895,2-2,2H2c-1.105,0-2-0.895-2-2V8c0-1.105,0.895-2,2-2h44c1.105,0,2,0.895,2,2V40z"/>
						<rect x="12" y="6" fill="#E6E6E6" width="8" height="36"/>
						<rect y="20" fill="#E6E6E6" width="48" height="8"/>
					</g>
				</svg>
			);
		case 'de':
			return (
				<svg className={className} viewBox="0 0 48 36" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
					<g transform="matrix(1, 0, 0, 1, 0, -6)">
						<path d="M48,18H0V8c0-1.105,0.895-2,2-2h44c1.105,0,2,0.895,2,2V18z"/>
						<rect y="18" fill="#EE0000" width="48" height="12"/>
						<path fill="#FDCF00" d="M48,40c0,1.105-0.895,2-2,2H2c-1.105,0-2-0.895-2-2V30h48V40z"/>
					</g>
				</svg>
			);
		case 'en':
			return (
				<svg className={className} viewBox="0 0 48 36" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
					<g transform="matrix(1, 0, 0, 1, 0, -6)">
						<path fill="#B41F30" d="M48,8c0-1.105-0.895-2-2-2H20v20H0v14c0,1.105,0.895,2,2,2h44c1.105,0,2-0.895,2-2V8z"/>
						<rect x="20" y="10" fill="#E6E6E6" width="28" height="4"/>
						<rect x="20" y="18" fill="#E6E6E6" width="28" height="4"/>
						<polygon fill="#E6E6E6" points="0,26 0,30 48,30 48,26 20,26 "/>
						<rect y="34" fill="#E6E6E6" width="48" height="4"/>
						<path fill="#00256A" d="M20,26H0V8c0-1.105,0.895-2,2-2h18V26z"/>
						<polygon fill="#FFFFFF" points="10,13.922 9.367,15.29 7.863,15.29 8.963,16.43 8.557,18.078 10,17.26 11.443,18.078 11.037,16.43 12.137,15.29 10.631,15.29 "/>
						<polygon fill="#FFFFFF" points="5.107,9.029 4.475,10.397 2.971,10.397 4.07,11.537 3.664,13.185 5.107,12.367 6.551,13.185 6.144,11.537 7.244,10.397 5.738,10.397 "/>
						<polygon fill="#FFFFFF" points="14.893,9.029 15.525,10.397 17.029,10.397 15.93,11.537 16.336,13.185 14.893,12.367 13.449,13.185 13.856,11.537 12.756,10.397 14.262,10.397 "/>
						<polygon fill="#FFFFFF" points="5.107,22.971 4.475,21.603 2.971,21.603 4.07,20.463 3.664,18.815 5.107,19.633 6.551,18.815 6.144,20.463 7.244,21.603 5.738,21.603 "/>
						<polygon fill="#FFFFFF" points="14.893,22.971 15.525,21.603 17.029,21.603 15.93,20.463 16.336,18.815 14.893,19.633 13.449,18.815 13.856,20.463 12.756,21.603 14.262,21.603 "/>
					</g>
				</svg>
			);
		case 'eu':
			return(
				<svg viewBox="0 0 48 36" width={width} height={height} className={className} xmlns="http://www.w3.org/2000/svg">
					<g transform="matrix(1, 0, 0, 1, 0, -6)">
						<path fill="#3200D0" d="M46,6H2C0.896,6,0,6.896,0,8v32c0,1.104,0.896,2,2,2h44c1.104,0,2-0.896,2-2V8C48,6.896,47.104,6,46,6z"/>
						<polygon fill="#F6CF00" points="27.106,13.997 24.919,13.997 24,12 23.079,13.997 20.894,13.997 22.49,15.644 21.9,18.047 24,16.856 26.1,18.047 25.508,15.644 "/>
						<polygon fill="#F6CF00" points="33.454,16.313 31.267,16.313 30.347,14.317 29.426,16.313 27.241,16.313 28.838,17.961 28.247,20.363 30.347,19.172 32.447,20.363 31.855,17.961 "/>
						<polygon fill="#F6CF00" points="27.106,31.95 24.919,31.95 24,29.953 23.079,31.95 20.894,31.95 22.49,33.598 21.9,36 24,34.809 26.1,36 25.508,33.598 "/>
						<polygon fill="#F6CF00" points="20.759,16.313 18.572,16.313 17.653,14.317 16.731,16.313 14.546,16.313 16.143,17.961 15.552,20.363 17.653,19.172 19.753,20.363 19.16,17.961 "/>
						<polygon fill="#F6CF00" points="17.213,22.95 15.026,22.95 14.106,20.953 13.185,22.95 11,22.95 12.597,24.598 12.006,27 14.106,25.809 16.206,27 15.614,24.598 "/>
						<polygon fill="#F6CF00" points="37,22.95 34.813,22.95 33.894,20.953 32.972,22.95 30.787,22.95 32.384,24.598 31.793,27 33.894,25.809 35.993,27 35.401,24.598 "/>
						<polygon fill="#F6CF00" points="33.454,29.512 31.267,29.512 30.347,27.516 29.426,29.512 27.241,29.512 28.838,31.16 28.247,33.562 30.347,32.371 32.447,33.562 31.855,31.16 "/>
						<polygon fill="#F6CF00" points="20.759,29.512 18.572,29.512 17.653,27.516 16.731,29.512 14.546,29.512 16.143,31.16 15.552,33.562 17.653,32.371 19.753,33.562 19.16,31.16 "/>
					</g>
				</svg>
			);
		case 'fr':
			return (
				<svg className={className} viewBox="0 0 48 36" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
					<g transform="matrix(1, 0, 0, 1, 0, -6)">
						<path fill="#01209F" d="M16,42H2c-1.105,0-2-0.895-2-2V8c0-1.105,0.895-2,2-2h14V42z"/>
						<path fill="#EF4234" d="M48,40c0,1.105-0.895,2-2,2H32V6h14c1.105,0,2,0.895,2,2V40z"/>
						<rect x="16" y="6" fill="#E6E6E6" width="16" height="36"/>
					</g>
				</svg>
			);
		case 'nl':
			return (
				<svg className={className} viewBox="0 0 48 36" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
					<g transform="matrix(1, 0, 0, 1, 0, -6)">
						<path fill="#21468C" d="M48,40c0,1.105-0.895,2-2,2H2c-1.105,0-2-0.895-2-2V30h48V40z"/>
						<rect y="18" fill="#E6E6E6" width="48" height="12"/>
						<path fill="#AE1C27" d="M48,18H0V8c0-1.105,0.895-2,2-2h44c1.105,0,2,0.895,2,2V18z"/>
					</g>
				</svg>
			);
		case 'no':
			return (
				<svg className={className} viewBox="0 0 48 36" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
					<g transform="matrix(1, 0, 0, 1, 0, -6)">
						<path fill="#EF2B2D" d="M48,40c0,1.105-0.895,2-2,2H2c-1.105,0-2-0.895-2-2V8c0-1.105,0.895-2,2-2h44c1.105,0,2,0.895,2,2V40z"/>
						<polygon fill="#FFFFFF" points="10,18 0,18 0,20 12,20 12,18 12,6 10,6 "/>
						<polygon fill="#FFFFFF" points="0,30 10,30 10,42 12,42 12,30 12,28 0,28 "/>
						<polygon fill="#FFFFFF" points="22,18 22,6 20,6 20,18 20,20 48,20 48,18 "/>
						<polygon fill="#FFFFFF" points="20,30 20,42 22,42 22,30 48,30 48,28 20,28 "/>
						<polygon fill="#003680" points="48,20 20,20 20,6 12,6 12,20 0,20 0,28 12,28 12,42 20,42 20,28 48,28 "/>
					</g>
				</svg>
			);
		case 'sv':
			return (
				<svg className={className} viewBox="0 0 48 36" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
					<g transform="matrix(1, 0, 0, 1, 0, -6)">
						<path fill="#0A5189" d="M48,40c0,1.105-0.895,2-2,2H2c-1.105,0-2-0.895-2-2V8c0-1.105,0.895-2,2-2h44c1.105,0,2,0.895,2,2V40z"/>
						<polygon fill="#EFD358" points="48,20 20,20 20,6 12,6 12,20 0,20 0,28 12,28 12,42 20,42 20,28 48,28 "/>
					</g>
				</svg>
			);
		default:
			return null;
	}
}

export default Flag;
