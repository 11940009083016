import React, { useCallback, useEffect, useState } from "react";
import { APIGet, APIPost } from "../API";
import { url_lang } from "../languages";

// Use this to populate RegistrationContext
export const useRegistration = () => {
	const [shouldLoad, setShouldLoad] = useState(true);
	const [loading, setLoading] = useState(true);
	const [registeredEvents, setRegisteredEvents] = useState([]);
	const [acceptedOrganizers, setAcceptedOrganizers] = useState([]);

	// Load the Ids for events where the user is/has been registered
	useEffect(() => {
		if (shouldLoad) {
			setLoading(true);
			APIGet("users", { action: "user_info" })
				.then((r) => {
					setRegisteredEvents(r.data.body.user_info.orders);
					setAcceptedOrganizers(r.data.body.user_info.organizers_events);
				})
				.catch(() => {
					setRegisteredEvents([]);
					setAcceptedOrganizers([]);
				})
				.finally(() => {
					setShouldLoad(false);
					setLoading(false);
				});
		}
	}, [registeredEvents, shouldLoad]);

	const register = ({ signUpForm, callback }) => {
		APIPost("register", {
			...signUpForm,
			origin: "web",
			action: "registerEvent",
			site_lang: url_lang().lang,
		}).then((reply) => {
			callback(reply);
			setShouldLoad(true);
		});
	};
	const isRegisteredForEvent = useCallback((eventId) => registeredEvents.find(e => e.eventId===eventId), [registeredEvents] )

	const getOrganizersMissingAccept = (organizers) => organizers

	return {
		registeredEvents,
		setRegisteredEvents,
		setAcceptedOrganizers,
		acceptedOrganizers,
		getOrganizersMissingAccept,
		register,
		loading,
		isRegisteredForEvent,
		refresh:()=>setShouldLoad(true)
	};
};

const RegistrationContext = React.createContext();

export default RegistrationContext;
