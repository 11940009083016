import React, { useContext, useEffect, useRef, useState } from "react";
import { T } from "../../traks.js";
import OrderContext from "../Amopus/useOrder";
import { Collapse } from "reactstrap";
import AddressForm from "./AddressForm";
import IframeCheckout from "../Amopus/IframeCheckout.js";
import CheckoutContext from "../Amopus/useCheckout.js";
import TermsOfTrade from "./TermsOfTrade.js";

const AccordionHeader = ({
	idx,
	selected,
	setSelected,
	children,
	completed,
}) => (
	<h2 className="accordion-header" id="BillingAddressHeader">
		<button
			className={`accordion-button ${selected === idx ? "" : " collapsed"}`}
			type="button"
			onClick={() => setSelected((p) => (p === idx ? -1 : idx))}
		>
			<span>
				<img
					className={`pe-3 pb-1 ${completed ? "" : "invisible"}`}
					style={{
						filter:
							"invert(52%) sepia(88%) saturate(423%) hue-rotate(94deg) brightness(98%) contrast(77%)",
					}}
					src="https://static.zevio.com/gfx/ico_signUpComplete.svg"
					alt="icosuccess"
				/>
				{children}
			</span>
		</button>
	</h2>
);

const AccordionBody = ({ idx, selected, children }) => (
	<Collapse isOpen={idx === selected}>
		<div className="accordion-body">{children}</div>
	</Collapse>
);

const CheckoutAccordion = ({ onError, onSuccess }) => {
	const {
		setBillingAddress,
		billingAddress,
		setShippingAddress,
		shippingAddress,
		createOrder,
		orderId
	} = useContext(OrderContext);
	const {setOrderId} = useContext(CheckoutContext)
	const [selected, setSelected] = useState(0);
	const [useAsShipping, setUseAsShipping] = useState(false);
	const [billingComplete, setBillingComplete] = useState(false);
	const [shippingComplete, setShippingComplete] = useState(false);
	const [acceptedTerms, setAcceptedTerms] = useState(false);
	const [invalidBilling, setInvalidBilling] = useState(false);
	const [invalidShipping, setInvalidShipping] = useState(false);
	const [invalidTerms, setInvalidTerms] = useState(false)

	const billingFormRef = useRef()
	const shippingFormRef = useRef()

	useEffect(()=>{
		if (billingComplete && shippingComplete && !orderId) {
			createOrder()
		} else if (orderId) {
			setOrderId(orderId)
		}
	}, [billingComplete, shippingComplete, createOrder, orderId, setOrderId])

	const submitBillingAddress = (e) => {
		e.preventDefault()
		let form = e.nativeEvent.target 
		let address = Object.values(form).filter(o => o.id && !o.id.includes("Check")).map(o => ({[o.id]:o.value})).reduce((acc,curr) => ({...acc, ...curr}))
		setBillingAddress(() => ({...address}))
		let isValid = Object.values(form).filter(o => o.id && !o.id.includes("Check")).reduce((acc,curr) => !!curr.value && acc, true)
		setInvalidBilling(!isValid)

		let nextSelect = 1
		if (form.useAsShippingCheck.value === "true" && isValid) {
			setShippingAddress(() => ({...address}))
			setShippingComplete(true)
			nextSelect = 2
		}
		if (form.termsOfTradeCheck.value !=="true") {
			setInvalidTerms(true)
		}
		if (form.termsOfTradeCheck.value === "true" && isValid) {
			setBillingComplete(true)
			setSelected(nextSelect)
		}
	}

	const submitShippingAddress = (e) => {
		e.preventDefault()
		let form = e.nativeEvent.target 
		let address = Object.values(form).filter(o => o.id && !o.id.includes("Check")).map(o => ({[o.id]:o.value})).reduce((acc,curr) => ({...acc, ...curr}))
		setShippingAddress(() => ({...address}))	
		let isValid = Object.values(form).filter(o => o.id).reduce((acc,curr) => !!curr.value && acc, true)
		setInvalidShipping(!isValid)
		setShippingComplete(isValid)
		if (isValid) {
			setSelected(2)
		} 
	}

	return (
		<div className="col-12 col-lg-6">
			<div className="accordion" id="CheckoutAccordion">
				<div className="accordion-item">
					<AccordionHeader
						idx={0}
						selected={selected}
						setSelected={setSelected}
						completed={billingComplete && acceptedTerms}
					>
						<T>Billing Address</T>
					</AccordionHeader>
					<AccordionBody idx={0} selected={selected}>
						<form onSubmit={submitBillingAddress} ref={billingFormRef}>
						<AddressForm
							address={{...billingAddress}}
							showInvalid={invalidBilling}
							formRef={billingFormRef}
						/>
						<TermsOfTrade
							acceptedTerms={acceptedTerms}
							setAcceptedTerms={setAcceptedTerms}
							invalidTerms={invalidTerms}
							setInvalidTerms={setInvalidTerms}
						/>
						<div className="py-3">
							<input
								id="useAsShippingCheck"
								type="checkbox"
								value={useAsShipping}
								onChange={e => setUseAsShipping(e.target.checked)}
							/>
							<label
								className="m-2"
								htmlFor="useAsShippingCheck"
							>
								<T>Use as shipping address</T>
							</label>
							<button
								type="submit"
								className="text-white btn btn_green w-100 p-2 my-2 border-0"
							>
								<T>Continue to checkout</T>
							</button>
						</div>
						</form>
					</AccordionBody>
				</div>
				<div className="accordion-item">
					<AccordionHeader
						idx={1}
						selected={selected}
						setSelected={setSelected}
						completed={shippingComplete}
						disabled={!billingAddress}
					>
						<T>Shipping Address</T>
					</AccordionHeader>
					
					<AccordionBody idx={1} selected={selected}>
					<form onSubmit={submitShippingAddress} ref={shippingFormRef}>
						<AddressForm
							address={{...shippingAddress}}
							showInvalid={invalidShipping}
							formRef={shippingFormRef}
						/>
							<button
								type="submit"
								className="text-white btn btn_green w-100 p-2 my-2 border-0"
							>
								<T>Continue to checkout</T>
							</button>
						</form>
					</AccordionBody>
				</div>
				<div className="accordion-item">
					<AccordionHeader
						idx={2}
						selected={selected}
						setSelected={setSelected}
						disabled={shippingComplete}
					>
						<T>Checkout</T>
					</AccordionHeader>
					<AccordionBody idx={2} selected={selected}>
						<IframeCheckout onError={onError} onSuccess={onSuccess} />
					</AccordionBody>
				</div>
			</div>
		</div>
	);
};

export default CheckoutAccordion;
