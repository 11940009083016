import React, { useContext, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { LL } from "../../../Routes";
import { T } from "../../../traks";
import EventCtx from "../useEvent";
import SignupContext from "../useSignup";


const PrivacyPolicy = () => {
	const {eventData} = useContext(EventCtx)
	const {accessProduct} = useContext(SignupContext)
	
	const [showOrganizerModal, setShowOrganizerModal] = useState(false);
	const toggleOrganizerDataModal = (event) => {
		setShowOrganizerModal(!showOrganizerModal);
		event.preventDefault();
	};

	// Dont show this for paid events
	if (accessProduct) {return null;}

	let privacy = (
		<T>
			By entering my name and email and signing up for the webinar, I consent to
			Zevio using my name, email address, and information about my viewing
			preferences to send newsletters containing marketing information from
			Zevio about speakers and webinars. My consent may at any time be revoked
			using the link in the newsletter and my personal information is processed in
			accordance with Zevios <a target="_blank" rel="noopener noreferrer"
			href={LL("/privacy")}>Privacy Policy</a>.
		</T>
	);

	

	if(eventData && eventData.organizers && eventData.organizers.length) {
		let conglomerateNames = [];
		eventData.organizers.forEach( (o) => {
			if(o.ConglomerateId) {
				conglomerateNames.push(o.Name);
			}
		});
		if(conglomerateNames.length > 0) {
			privacy = (
				<div style={{ fontSize: "13px" }}>
					<T>
						By entering my name and my email and signing up for the webinar, I consent to
						Zevio using my name, my email address, and information about my viewing
						preferences to send newsletters containing marketing information from Zevio
						about speakers and webinars. I am aware that my data can be forwarded to
						to <b><a href="/organizers" onClick={toggleOrganizerDataModal}>
						{conglomerateNames.join(', ')}</a></b> and that my consent may at any time be revoked
						using the link in the newsletter, and that my personal information is processed in accordance with
						Zevios <a target="_blank" rel="noopener noreferrer" href={LL("/privacy")}>Privacy Policy</a>.
					</T>
				</div>
			);
		}
	}

	return (
		<div
			className="register_consent green_link register_custom py-3 pe-3 rounded position-relative"
			style={{ paddingLeft: 15 }}
		>
			<div>
				<OrganizerInfoModal showOrganizerModal={showOrganizerModal} toggleOrganizerDataModal={toggleOrganizerDataModal} />
				{privacy}
			</div>
		</div>
	);
};



const OrganizerInfoModal = ({showOrganizerModal, toggleOrganizerDataModal}) => {
	const {eventData} = useContext(EventCtx)
	if (showOrganizerModal)
		return (
			<Modal
				isOpen={showOrganizerModal}
				toggle={toggleOrganizerDataModal}
				size="lg"
				centered
				style={{ maxWidth: "430px", width: "100%" }}
			>
				<ModalBody className="signup_organizer_modal green_link">
					<OrganizerInfoModalContent
						eventData={eventData}
						closeModal={toggleOrganizerDataModal}
					/>
				</ModalBody>
			</Modal>
		);
	else return null;
};

const OrganizerInfoModalContent = ({ eventData, closeModal }) => {
	return (
		<div className="d-flex flex-column bg_white p-3 p-lg-4 signup_organizers_modal">
			<div className="font_125pct">
				<T>Organisers of this event</T>
				<img
					className="btn p-0"
					style={{ float: "right" }}
					src="https://static.zevio.com/gfx/ico_close.svg"
					alt="icoClose"
					onClick={closeModal}
				/>
			</div>
			{eventData.organizers.map((organizer) => (
				<div key={"organizer_" + organizer.Id}>
					<hr />
					<div className="font_weight_500 pb-2">
						<T>Name</T>: {organizer.Name}
					</div>
					<div>
						<T>Website</T>:{" "}
						<a
							target="_blank"
							rel="noopener noreferrer"
							href={organizer.Website}
						>
							{organizer.Website}
						</a>
					</div>
					{organizer.PrivacyPolicyLink && (
						<div>
							<T>Privacy policy</T>:{" "}
							<a
								target="_blank"
								rel="noopener noreferrer"
								href={organizer.PrivacyPolicyLink}
							>
								<T>External link</T>
							</a>
						</div>
					)}
				</div>
			))}
		</div>
	);
};

export default PrivacyPolicy;
