import React, { useCallback, useState, useEffect, useRef, useContext } from "react";
import axios from 'axios';
import { Col, Container, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner, Modal } from 'reactstrap';

import Footer from '../c/Footer';
import { GreenButton, RedButton, EventGuideCollapsibles, AlertReusable, NotOrganizer } from '../c/ReusableElems';
import { MeContext } from '../MeContext';
import ZevioNav from '../c/ZevioNav';
import { EventCard } from "../c/Cards";
import DropCropper from '../c/DropCropper';
import { LL, Link } from '../Routes';
import { T, TraksConsumer } from '../traks';
import { useParams, useNavigate } from "react-router-dom";

import { APIGet, APIPost } from "../API";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import EditorToolbar, { modules, formats } from "../c/EditorToolbar";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { url_lang } from "../languages";
import { Buffer } from "buffer";


export const ModalBroadcastInfo = ({ liveURL, eventData, onClick }) => {
	if (eventData) {
		return (<>
			<div className="pt-2">
				<img onClick={onClick} className="btn" style={{ float: 'right' }} src="https://static.zevio.com/gfx/ico_close.svg" alt='icoClose' />
			</div>
			<div className="p-3">
				<div>
					<h5><T>Broadcast info</T></h5>
				</div>
				<div className="pt-3">
					<T>Broadcast URL:</T>
				</div>
				{eventData.BroadcastURL ?
					<div className="pt-1 px-2">
						<Row className="successbox opposites">
							<span className="break-word font_monospace pt-1">{eventData.BroadcastURL}</span>
							<span className="btn login_or_seperator text-center" onClick={() => navigator.clipboard.writeText(eventData.BroadcastURL)}><T>COPY</T></span>
						</Row>
					</div>
					: <span className="pt-1 text-center"><T>Not available</T></span>}
				<div className="pt-3">
					<T>Broadcast key:</T>
				</div>
				{eventData.BroadcastKey ?
					<div className="pt-1 px-2">
						<Row className="successbox">
							<span className="break-word font_monospace pt-1">{eventData.BroadcastKey}</span>
							<span className="btn login_or_seperator" onClick={() => navigator.clipboard.writeText(eventData.BroadcastKey)}><T>COPY</T></span>
						</Row>
					</div>
					: <span className="pt-1 text-center"><T>Not available</T></span>}
				<div className="pt-3">
					<T>Live event watch URL:</T>
				</div>
				<div className="pt-1 px-2">
					<Row className="successbox">
						<span className="break-word font_monospace pt-1">{liveURL}</span>
						<span className="btn login_or_seperator" onClick={() => navigator.clipboard.writeText(liveURL)}><T>COPY</T></span>
					</Row>
				</div>
				<div className="py-3">
					<button onClick={onClick} className="btn_editevent zevio_input w-100"><T>Close</T></button>
				</div>
			</div >
		</>)
	} else {
		return <div className='text-center py-4'><T>Loading data...</T><Spinner className="p-2" color="success" /></div>;
	}
}

const PaymentSelector = ( { eventData, setEvent, render_static} ) => {
	const [payments, setPayments] = useState([])
	const [open, setOpen] = useState(false);
	let priceElems = [];
	useEffect( () => {
		APIPost('products', { 'action': 'get_event_access_products' })
		.then( r => setPayments(r.data.body.products) );
	}, []);

	payments.forEach( (p) => {
		if(p.currency.toLowerCase() === url_lang().currency) {
			priceElems.push(
				<DropdownItem
					className="text-capitalize"
					key={p.Id}
					value={p.price[url_lang().currency]}
					onClick={() => {
						setEvent({ ...eventData, AccessProductId: p.Id});
					}}
				>
					{url_lang().currency.toUpperCase() + " " + p.price[url_lang().currency]}
				</DropdownItem>
			);
		}
	});

	if(!payments || payments.length < 1) return null

	return (
		<Dropdown
			isOpen={open}
			toggle={() => setOpen((o) => !o)}
		>
			<DropdownToggle className="btn_editevent zevio_input">
				<span className="opposites text-capitalize">
					{eventData.AccessProductId ? eventData.AccessProductId : render_static(<T>Free to watch</T>)}
					<img src="https://static.zevio.com/gfx/ico_expandable.svg" alt='icoexp' />
				</span>
			</DropdownToggle>
			<DropdownMenu>
			<DropdownItem
					className="text-capitalize"
					value={0}
					onClick={() => {
						setEvent({ ...eventData, AccessProductId: null});
					}}
				>
					<T>Free to watch</T>
				</DropdownItem>
				{priceElems}
			</DropdownMenu>
		</Dropdown>
	);
}

const ProductSelector = ({eventData}) => {
	const [availableProducts, setAvailableProducts] = useState([])
	const [selectedProducts, setSelectedProducts] = useState([]);
	const { me } = useContext(MeContext);

	useEffect(() => {
		APIPost('products', { 'action': 'get_products', 'only_active': ['true'], 'event_ids': [eventData.Id] })
			.then((res) => {
				setSelectedProducts(res.data.products);
				let filterX = (res.data.products = res.data.products.map(a => a.productId));
				APIPost('products', { 'action': 'get_products', 'organizer_ids': me.organizers, 'only_active': ['true'] })
					.then((res) => {
						setAvailableProducts(res.data.products.filter(x => !filterX.includes(x.productId)));
					})
					.catch((error) => {
						console.log(['Error getting products:', error]);
					})
			})
			.catch((error) => {
				console.log(['Error getting products:', error]);
			})
	}, [eventData.Id, me.organizers]);

	const AddProduct = (product_id) => {
		let uploadObj = {
			'action': 'add_to_event', 'product_id': product_id, 'event_ids': [eventData.Id]
		};
		APIPost('products', uploadObj)
			.then((res) => {
			})
			.catch((error) => {
				console.log(['Error adding products:', error]);
			})
	};
	const RemoveProduct = (product_id) => {
		let uploadObj = {
			'action': 'delete_from_event', 'product_id': product_id, 'event_ids': [eventData.Id]
		};
		APIPost('products', uploadObj)
			.then((res) => {
			})
			.catch((error) => {
				console.log(['Error deleting products:', error]);
			})
	};

	const prod_select_it = (e, id) => {
		let filtered = availableProducts.filter((prod) => prod !== id);
		setAvailableProducts(filtered);
		selectedProducts && selectedProducts.length ? setSelectedProducts([...selectedProducts, id]) : setSelectedProducts([id]);
		AddProduct(id.productId);
	}
	const prod_deSelect_it = (e, id) => {
		let filtered = selectedProducts.filter((prod) => prod !== id);
		setSelectedProducts(filtered);
		availableProducts && availableProducts.length ? setAvailableProducts([...availableProducts, id]) : setAvailableProducts([id]);
		RemoveProduct(id.productId);
	}

	if (!(availableProducts.length || selectedProducts.length))
		return null;

	return (
		<Container fluid className="bg_white promotion_box p-0 mt-3 mb-4">
			<Row>
				<div className="w-100 font_15px font_weight_500">
					<div className="ps-4 py-2 bottom_border_promo_listing">
						<T>Event products</T>
					</div>
				</div>
				<Col className="pt-2 pb-3 vertical_line_tiny">
					<div className="ps-4 py-2 text-center">
						<span><T>Available products</T></span>
					</div>
					<div className="pt-1">
						<span>
							{availableProducts.map((prod, i) => (
								<span
									className="col-9 pt-0 ps-4 grey_light btn btn_radio"
									key={i}
									onClick={(e) => prod_select_it(e, prod)}
								>
									<span>{prod.imageURL ? <img className="col-1" src={prod.imageURL} alt='thumbimg' /> : <span className="ps-5" />}</span>
									<span className="ps-3">{prod.headline ? prod.headline.length > 30 ? `${prod.headline.substring(0, 30)}...` : prod.headline : null}</span>

								</span>
							))}
						</span>
					</div>
				</Col>
				<Col className="pt-2 pb-3">
					<div className="ps-4 py-2 text-center">
						<T>Added products</T>
					</div>
					<div className="pt-1 me-3">
						<span>
							{selectedProducts.map((prod, i) => (
								<Col
									style={{ color: i === 0 ? '#00B00F' : 'none' }}
									className="col-9 pt-0 ps-4 grey_light btn btn_radio"
									key={i}
									onClick={(e) => prod_deSelect_it(e, prod)}
								>
									<span>{prod.imageURL ? <img className="col-1" src={prod.imageURL} alt='thumbimg' /> : <span className="ps-5" />}</span>
									<span className="ps-3">{prod.headline ? prod.headline.length > 30 ? `${prod.headline.substring(0, 30)}...` : prod.headline : null}</span>
								</Col>
							))}
						</span>
					</div>
				</Col>
			</Row>
		</Container>
	);
}

const HelpText = (props) => {
	return (
		<div className="explanation_text">
			{props.children}
		</div>
	);
}

const EditEventInner = () => {
	const { me } = useContext(MeContext);
	if (me && me.currentSelectedOrganizer) {
		return (
			<TraksConsumer>
				<EventEdit />
			</TraksConsumer>
		);
	} else {
		return (
			<NotOrganizer />
		);
	}
}

const EventEdit = (props) => {
	const { me } = useContext(MeContext);
	const { eventId } = useParams();
	const urlParams = eventId;

	const [eventData, setEvent] = useState({
		"Id": 0,
		"Title": "",
		"SubTitle": "",
		"LanguageId": url_lang().id,
		"LanguageName": "",
		"CategoryId": "",
		"CategoryName": "",
		"OrganizerIds": [me.currentSelectedOrganizer],
		"DescriptionShort": "",
		"Description": "",
		"EmbedPagePromoText": "",
		"StartTs": 0,
		"EndTs": 0,
		"ShowChat": true,
		"FacebookEventUrl": "",
		"IsHidden": false,
		"SignupFormType": "",
		"SignupFormIncludeCountry": "",
		"SaveWebinar": true,
		"PlaybackURL": "",
		"BroadcastURL": "",
		"BroadcastKey": "",
		"SafeName": "",
		"Signups": 0,
		"AccessProductId": "",
	});

	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [coverImage, setCoverImage] = useState(null);
	const [thumbnailImage, setThumbnailImage] = useState(null);

	const [organizerInfo, setOrganizerInfo] = useState(['']);

	useEffect(() => {
		let organizerIdsToGet = [...new Set([...me.organizers, ...eventData.OrganizerIds])].join(',');
		if(me.roles.indexOf(42) !== -1)
			organizerIdsToGet = "all";
		APIGet('search', { 'organizer_id': organizerIdsToGet })
			.then((res) => {
				setOrganizerInfo(res.data.body);
			}).catch((error) => {
				console.log('Error:', error);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [eventData.Id]);

	useEffect(() => {
		APIGet('events', {
			'action': 'get_categories_langs',
		}).then((response) =>
			setCategoriesData(response.data.body)
		);
	}, []);

	useEffect(() => {
		if (!urlParams || !urlParams.length) {
			const now = Math.floor((new Date().getTime()) / 1000);
			const nowAtMinute00 = now - now % 3600;
			const ninetyDaysOut = nowAtMinute00 + 90 * 24 * 60 * 60;
			APIPost('events', {
				'action': 'create_event',
				'event': {
					"Id": 0,
					"OrganizerIds": [me.currentSelectedOrganizer],
					"LanguageId": url_lang().id,
					"StartTs": ninetyDaysOut,
					"EndTs": ninetyDaysOut + 30 * 60,
				}
			}).then((res) => {
				setEvent({ ...eventData, Id: res.data.body.event.Id });
				updateStartDateTime(new Date(ninetyDaysOut * 1000));
				let eventIdStr;
				if (window.location.pathname.charAt(-1) !== '/') {
					eventIdStr = LL('/editevent/') + res.data.body.event.Id;
				} else {
					eventIdStr = res.data.body.event.Id;
				}
				window.history.replaceState(null, '', eventIdStr);
			})
				.catch((error) => {
					console.log(['Error:', error])
				})
		} else {
			populateEventData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const saveEvent = useCallback((doAlert = false) => {
		const postObj = {
			'action': 'update_event',
			'event': {
				...eventData,
				StartTs: Math.floor(startDate.getTime() / 1000),
				EndTs: Math.floor(endDate.getTime() / 1000),
			},
		};
		APIPost('events', postObj).then((res) => {
			if (res.status === 200 && doAlert) {
				setAlertText(<T>Event saved</T>);
				setAlertColor("success");
				setActivateAlert((oa) => oa + 1);
			}
		}).catch((error) => {
			setAlertText(<T>Event save failed</T>);
			setAlertColor("danger");
			setActivateAlert((oa) => oa + 1);
		})
	}, [eventData, startDate, endDate]);


	const requestApproval = () => {
		const postObj = {
			'action': 'request_approval',
			'EventId': eventData.Id,
		};
		APIPost('events', postObj).then((res) => {
			setAlertText(<T>Approval requested</T>);
			setAlertColor("success");
			setActivateAlert(activateAlert + 1);
			setEvent({ ...eventData, Active: 1 })
		}).catch((error) => {
			setAlertText(<T>Approval request failed</T>);
			setAlertColor("danger");
			setActivateAlert(activateAlert + 1);
		})
	}

	const setEventActiveFlag = (activeValue) => {
		const postObj = {
			'EventId': eventData.Id,
		};
		if(activeValue)
			postObj['action'] = 'activate_event';
		else
			postObj['action'] = 'deactivate_event';
		APIPost('events', postObj).then((res) => {
			setAlertText(activeValue === 0 ? <T>Event deactivated</T> : <T>Event activated</T>);
			setAlertColor("success");
			setActivateAlert(activateAlert + 1);
			setEvent({ ...eventData, Active: activeValue })
		}).catch((error) => {
			setAlertText(<T>Active change request failed</T>);
			setAlertColor("danger");
			setActivateAlert(activateAlert + 1);
		})
	}

	const [categoriesData, setCategoriesData] = useState([]);

	const massageDataAndSetEvent = (res) => {
		let eventFromAPI = { ...res.data.body.event }
		if (eventFromAPI.CoverImage)
			setCoverImage(eventFromAPI.CoverImage)
		if (eventFromAPI.ThumbnailImage)
			setThumbnailImage(eventFromAPI.ThumbnailImage)
		delete eventFromAPI['Date'];
		delete eventFromAPI['TimeFrom'];
		delete eventFromAPI['TimeTo'];
		delete eventFromAPI['CoverImage'];
		delete eventFromAPI['ThumbnailImage'];
		setEvent((oe) => eventFromAPI);
		if (eventFromAPI.StartTs)
			setStartDate(new Date(eventFromAPI.StartTs * 1000))
		if (eventFromAPI.EndTs)
			setEndDate(new Date(eventFromAPI.EndTs * 1000))
		else
			setEndDate(new Date((eventFromAPI.StartTs + 30 * 60) * 1000 ))
		setStateTextEditorDescription({ value: res.data.body.event.Description });
		setStateTextEditorPromoText({ value: res.data.body.event.EmbedPagePromoText });
	}

	const populateEventData = () => {
		const eventId = urlParams;
		APIGet('events', { 'EventId': eventId, 'action': 'get_event' })
			.then((res) => {
				massageDataAndSetEvent(res);
			})
			.catch((error) => {
				console.log(['Error:', error])
			})
	}


	const doneCropping = (imageParam) => {
		const uploadObj = {
			'action': 'update_event',
			'event': {
				'Id': eventData['Id'],
				...imageParam,
			},
		};
		APIPost('events', uploadObj).then((response) => {
			if (response.status === 200) {
				massageDataAndSetEvent(response);
			}
		});
	}

	const uploadImage = (imageParams) => {
		const imgTypeRegExp = new RegExp('^data:image/([a-z]+);base64,');
		const imgType = imageParams.image.match(imgTypeRegExp)[1];
		saveEvent(false);
		APIGet('events', { 'action': 'get_image_upload_url', 'file_extension': imgType }).then((res) => {
			const uploadInfo = res.data.body.upload_info;
			let uploadForm = new FormData();
			for (const key in uploadInfo.fields) {
				uploadForm.append(key, uploadInfo.fields[key]);
			};
			uploadForm.append('acl', 'public-read');
			const imgData = imageParams.image.replace(imgTypeRegExp, '');
			const imgBuffer = new Buffer(imgData, 'base64');
			const imgBlob = new Blob([imgBuffer], { type: "image/" + imgType });
			uploadForm.append('file', imgBlob);
			axios.post(uploadInfo.url, uploadForm).then((response) => {
				if (response.status === 204) {
					doneCropping({ [imageParams.whichImage]: uploadInfo.fields.key });
				}
			});
		});
	}

	const doneCroppingCover = (image) => uploadImage({ 'image': image, 'whichImage': 'CoverImage' });
	const doneCroppingThumb = (image) => uploadImage({ 'image': image, 'whichImage': 'ThumbnailImage' });
	const deleteCoverImg = () => {
		doneCropping({ 'CoverImage': '' });
		setCoverImage(null);
	}
	const deleteThumbImg = () => {
		doneCropping({ 'ThumbnailImage': '' });
		setThumbnailImage(null);
	}

	const OverlayedImage = (props) => {
		return (
			<div className="position-relative pb-4">
				<img alt="" {...props} />
				<div style={{ background: "rgba(255, 255, 255, 0.55" }} className="position-absolute top-0 start-0 h-100 w-100" />
				<div className="position-absolute top-0 h-100 w-100">
					<img alt="" {...props} style={{ borderRadius: "50%" }} />
				</div>
			</div>
		);
	}

	const ShowImage = (props) => {
		let ourProps = { ...props };
		delete ourProps.onClick;
		let imagePart = (
			<div className="pb-4">
				<img alt="" {...ourProps} />
			</div>
		);
		if (props.overlay) imagePart = <OverlayedImage {...props} />;
		return (
			<div className='text-center'>
				{imagePart}
				{props.showbutton && <RedButton onClick={props.onClick}>Delete</RedButton>}
			</div>
		);
	}

	const [activateAlert, setActivateAlert] = useState(0);
	const [alertText, setAlertText] = useState('');
	const [alertColor, setAlertColor] = useState('');

	const updateStartDateTime = (date) => {
		setStartDate(date);
		updateEndDateTime(new Date(date.getTime() + 30 * 60000));
	};

	const updateEndDateTime = (date) => {
		setEndDate(date);
	};

	const [stateTextEditorDescription, setStateTextEditorDescription] = useState({ value: '' });
	const [stateTextEditorPromoText, setStateTextEditorPromoText] = useState({ value: '' });

	const handleDescriptionChange = value => {
		setStateTextEditorDescription({ value });
		setEvent({ ...eventData, Description: value });
	};
	const handleDescriptionChangeShort = value => {
		setEvent({ ...eventData, DescriptionShort: value.target.value });
	};
	const handleDescriptionChangePromoText = value => {
		const fixed = value.substring(3, value.length - 4);
		setStateTextEditorPromoText({ value });
		setEvent({ ...eventData, EmbedPagePromoText: fixed });
	};

	const submit_button_disabled = !(eventData.SubTitle.length && eventData.Title.length && eventData.OrganizerIds.length
		&& eventData.LanguageId && eventData.CategoryId && eventData.DescriptionShort
		&& eventData.Description && eventData.Description.length && coverImage && thumbnailImage && startDate && endDate);

	const [dropdownOrganizerOpen, setDropdownOrganizerOpen] = useState(false);
	const [dropdownLanguageOpen, setDropdownLanguageOpen] = useState(false);
	const [dropdownCategoryOpen, setDropdownCategoryOpen] = useState(false);
	const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false);

	const [modalDelete, setModalDelete] = useState(false);
	const toggleDelete = () => setModalDelete(!modalDelete);
	const [modalBroadcast, setModalBroadcast] = useState(false);
	const toggleBroadcast = () => setModalBroadcast(!modalBroadcast);
	const hideEventText = eventData.IsHidden ? <T>Make visible</T> : <T>Hide event</T>;
	const activateText = eventData.Active ? <T>Deactivate event</T> : <T>Activate event</T>;

	const VisibilityBadge = () => {
		return <span>{eventData.IsHidden ? <span className="broadcast_badge badgecolor_hidden"><T>Hidden</T></span> : <span className="broadcast_badge badgecolor_approved"><T>Visible</T></span>}</span>;
	}

	const ActiveBadge = () => {
		return <span>{eventData.Active ? <span className="broadcast_badge badgecolor_approved"><T>Active</T></span> : <span className="broadcast_badge badgecolor_hidden"><T>Inactive</T></span>}</span>;
	}

	const ApprovedBadge = () => {
		return <span>{eventData.Approved === 1 ?
				<span className="badgecolor_approved card_badge"><T>Approved</T></span> :
				eventData.Approved === 0 && eventData.Active === 1 ? <span className="badgecolor_pending card_badge"><T>Pending</T></span> :
				<span className="badgecolor_hidden card_badge"><T>Not approved</T>
				</span>}
			</span>;
	}

	const [modalPreview, setModalPreview] = useState(false);
	const togglePreview = () => setModalPreview(!modalPreview);
	const ModalPreviewCard = () => {
		const previewObj = {
			...eventData,
			'ThumbnailImage': thumbnailImage,
			'startTimeStamp': startDate.getTime() / 1000,
			'Duration': (endDate.getTime() - startDate.getTime())/1000,
		};
		return (
			<div className="d-flex justify-content-center py-3">
				<EventCard eventData={previewObj} />
			</div>
		);
	}

	const urlPort = window.location.port;
	const liveURL = window.location.protocol + "//" + window.location.hostname + (urlPort ? ":" + urlPort : "") + LL("/live/") + eventData.UniqueHash;

	const ModalDelete = ({ eventName, eventId, onClick }) => {
		let navigate = useNavigate();
		const uploadObj = {
			'action': 'update_event',
			'event': { 'Id': eventId, 'Deleted': 1 },
		};
		const deleteEvent = () => {
			if (deleteText === "delete") {
				APIPost('events', uploadObj).then((response) => {
					if (response.status === 200) {
						onClick();
						setAlertText('Event deleted')
						setAlertColor('success');
						setActivateAlert(activateAlert + 1);
						setTimeout(() => navigate(LL("/broadcasts")), 1000);
					}
				});
			}
		};

		const [deleteText, setDeleteText] = useState('');
		const submit_button_disabled = !(deleteText === ('delete'))

		const inputElement = useRef(null);
		useEffect(() => {
			if (inputElement.current) {
				inputElement.current.focus();
			}
		}, []);

		return (
			<div className="p-3">
				<div>
					<img onClick={onClick} className="btn" style={{ float: 'right' }} src="https://static.zevio.com/gfx/ico_close.svg" alt='icoClose' />
				</div>
				<div className="ps-5 pt-3 image-container">
					<div className="icon_modal_delete">
						<img src="https://static.zevio.com/gfx/ico_deleteprofile.svg" alt='icoDelProf' />
					</div>
				</div>
				<div className="pt-3">
					<span><T>Are you sure you want to delete event:</T></span> <div className="font_weight_500"> {eventName}</div>
				</div>
				<div className="pt-4 pb-2">
					<span><T>Type: </T><span style={{ fontWeight: "900" }}>DELETE</span> <T>to delete this event</T></span>
				</div>
				<div>
					<form onSubmit={(e) => e.preventDefault() + deleteEvent(e)}>
						<input
							ref={inputElement}
							name="Delete"
							id="Delete"
							className="zevio_input w-450 w-100"
							onChange={(e) => setDeleteText(e.target.value.toLowerCase())}
						/>
					</form>
				</div>
				<div className='pt-3 pb-4'>
					<RedButton
						style={{ width: '100%' }}
						disabled={submit_button_disabled}
						onClick={deleteEvent}><T>Delete event</T>
					</RedButton>
				</div>
			</div >
		);
	}

	const org_select_it = (e, id) => {
		setEvent((oe) => {
			return { ...oe, OrganizerIds: [...oe.OrganizerIds, id] }
		});
	}
	const org_deSelect_it = (e, id) => {
		let filtered = eventData.OrganizerIds.filter((org) => org !== id);
		setEvent((oe) => {
			return { ...oe, OrganizerIds: filtered }
		});
	}

	let activateDropDownElement = null;
	if (me.roles.includes(42) && eventData.Approved === 1) {
		if (eventData.Active === 1) {
			activateDropDownElement = (
				<DropdownItem onClick={() => setEventActiveFlag(0)}>
					<span>{activateText}</span><div><ActiveBadge /></div>
				</DropdownItem>
			);
		} else {
			activateDropDownElement = (
				<DropdownItem onClick={() => setEventActiveFlag(1)}>
					<span>{activateText}</span><div><ActiveBadge /></div>
				</DropdownItem>
			);
		}
	}

	const postprocessUrl = eventData.Id ? "https://lgcy.zevio.com/broadcastended.php?webinar=" + eventData.Id : null;
	const setVisibility = (SignupFormType, checked) => {
		if (!checked) setEvent({ ...eventData, SignupFormType:'simple'})
		else setEvent({ ...eventData, SignupFormType})
	}

	if (categoriesData.languages && eventData.Id > 0 && [me.organizers]) {
		const goLiveLinkVisible = !!(eventData.Approved && eventData.Active);
		return (
			<Container>
				{eventData.OrganizerIds.length > 1 &&
				<div style={{borderRadius: '6px', backgroundColor: 'red', color: 'yellow', fontSize: '125%', border: '1px solid black'}} className='p-4 font_weight_600'>
					<T>
						You have added more than 1 organizer for this event. This is against Zevio company policy, so please
						confirm with Zevio that is is okay, before proceeding with this approach.
					</T>
				</div>
				}
				<AlertReusable
					color={alertColor}
					activateAlert={activateAlert}
					text={alertText}
					delay="2000"
				/>
				<div className="pt-4 pb-3 d-flex flex-col justify-content-between">
					<span>
						<div className="pb-2 d-flex flex-col">
							<h3><T>Edit event</T></h3>
							<Dropdown
								className="ps-2"
								isOpen={dropdownMenuOpen}
								toggle={() => setDropdownMenuOpen(!dropdownMenuOpen)}
							>
								<DropdownToggle className="zevio_input">
									<span><img src="https://static.zevio.com/gfx/ico_expandable.svg" alt='icoexp' /></span>
								</DropdownToggle>
								<DropdownMenu>
									{goLiveLinkVisible &&
									<DropdownItem>
										<a className="link_nostyle" href={LL("/golive/") + eventData.UniqueHash}><T>Go live from browser</T></a>
									</DropdownItem>
									}
									<DropdownItem onClick={() => toggleBroadcast(true)}>
										<Modal toggle={toggleBroadcast} centered isOpen={modalBroadcast}>
											<ModalBroadcastInfo liveURL={liveURL} eventData={eventData} onClick={toggleBroadcast} />
										</Modal>
										<span><T>Broadcast info</T></span>
									</DropdownItem>
									<DropdownItem onClick={() => toggleDelete(true)}>
										<Modal toggle={toggleDelete} centered isOpen={modalDelete}>
											<ModalDelete onClick={toggleDelete} eventName={eventData.Title} eventId={eventData.Id} />
										</Modal>
										<span><T>Delete event</T></span>
									</DropdownItem>
									<DropdownItem divider />
									<DropdownItem onClick={() => setEvent({ ...eventData, IsHidden: !eventData.IsHidden })}>
										<span>{hideEventText}</span><div><VisibilityBadge /></div>
									</DropdownItem>
									{activateDropDownElement}
								</DropdownMenu>
							</Dropdown>
						</div>
						<span className="pe-3"><span className="pe-2"><T>Status:</T></span><ApprovedBadge /></span>
						<span className="pe-2"><span className="pe-2"><T>Visibility:</T></span><VisibilityBadge /></span>
						<span className="pe-2"><span className="pe-2"><T>Signups:</T></span><span className="font_weight_500">{eventData.Signups}</span></span>
					</span>
					<div>
						{eventData.Approved === 0 && eventData.Active === 0 &&
							<GreenButton disabled={submit_button_disabled} onClick={requestApproval}><T>Request approval</T></GreenButton>
						}
						{!liveURL.includes('undefined') ? <a target="_blank" rel="noopener noreferrer" href={liveURL}>
							<button className="btn btn_white_dark_border w-10 py-2 px-3 ms-2 font_weight_500"><T>Live page</T></button>
						</a> : null }
						{!liveURL.includes('undefined') ? <Link className="link_nostyle" target="_blank" rel="noopener noreferrer" to={{
							pathname: "/eventpreview/" + eventData.UniqueHash
						}}>
							<button className="btn btn_white_dark_border w-10 py-2 px-3 ms-2 font_weight_500"><T>Preview</T></button>
						</Link> : null}
						<button onClick={togglePreview} className="btn btn_white_dark_border w-10 py-2 px-3 mx-2 font_weight_500"><T>Preview</T> card
							<Modal toggle={togglePreview} centered isOpen={modalPreview}>
								<ModalPreviewCard />
							</Modal>
						</button>
						<GreenButton onClick={() => saveEvent(true)}><T>Save</T></GreenButton>
					</div>
				</div>
				<form id='edit_event'>
					<Container style={{ overflow: "visible" }} fluid className="bg_white promotion_box p-0">
						<Row>
							<div className="bottom_border_promo_listing w-100 font_15px font_weight_500">
								<div className="ps-4 py-2">
									<T>Event info</T>
								</div>
							</div>
							<Col xs="12" lg="6" className="pt-2 pb-3">
								<div className="ps-4">
									<div className="py-2">
										<T>Event title</T>
										<input
											placeholder={props.render_static(<T>The name of your event (e.g. &lsquo;Learn Investing&rsquo;)</T>)}
											onChange={(e) => setEvent({ ...eventData, Title: e.target.value })}
											name="EventTitle"
											id="EventTitle"
											form="edit_event"
											className="zevio_input w-100"
											value={eventData.Title}
											onBlur={() => saveEvent(false)}
										/>
									</div>
									<div className="py-2">
										<T>Speakers</T>
										<input
											placeholder={props.render_static(<T>The name of the speaker (e.g. &rsquo;Donald McChris&lsquo;)</T>)}
											onChange={(e) => setEvent({ ...eventData, SubTitle: e.target.value })}
											name="SubTitle"
											id="SubTitle"
											form="edit_event"
											className="zevio_input w-100"
											value={eventData.SubTitle}
											onBlur={() => saveEvent(false)}
										/>
									</div>
									<div className="py-2">
										<T>Language</T>
										<Dropdown
											isOpen={dropdownLanguageOpen}
											toggle={() => setDropdownLanguageOpen(!dropdownLanguageOpen)}
										>
											<DropdownToggle className="btn_editevent zevio_input">
												<span className="opposites text-capitalize">{eventData.LanguageName ? eventData.LanguageName : 'Language'}<img src="https://static.zevio.com/gfx/ico_expandable.svg" alt='icoexp' /></span>
											</DropdownToggle>
											<DropdownMenu>
												{Object.entries(categoriesData.languages).map(([key, eventLanguageData], i) => (
													<DropdownItem
														className="text-capitalize"
														key={i}
														value={eventLanguageData[key]}
														onClick={() => {
															setEvent({ ...eventData, LanguageName: eventLanguageData.humanName, LanguageId: eventLanguageData.Id });
															saveEvent(false);
														}}
													>
														{eventLanguageData.humanName}
													</DropdownItem>
												))}
											</DropdownMenu>
										</Dropdown>
									</div>
									<div className="py-2">
										<T>Category</T>
										<Dropdown
											isOpen={dropdownCategoryOpen}
											toggle={() => setDropdownCategoryOpen(!dropdownCategoryOpen)}
										>
											<DropdownToggle className="btn_editevent zevio_input">
												<span className="opposites text-capitalize">{eventData.CategoryName ? eventData.CategoryName : props.render_static(<T>Category</T>)}<img src="https://static.zevio.com/gfx/ico_expandable.svg" alt='icoexp' /></span>
											</DropdownToggle>
											<DropdownMenu className="dropdown_scroll_menu">
												{Object.entries(categoriesData.categories).map(([key, eventCategoryData], i) => (
													<DropdownItem
														className="text-capitalize"
														key={i}
														value={eventCategoryData[key]}
														onClick={() => {
															setEvent({ ...eventData, CategoryName: eventCategoryData.Name, CategoryId: eventCategoryData.Id });
															saveEvent(false);
														}}
													>
														{eventCategoryData.Name}
													</DropdownItem>
												))}
											</DropdownMenu>
										</Dropdown>
									</div>
									{me.roles.indexOf(42) !== -1 &&
										<div className="py-2">
											<T>Stream payment</T>
											<HelpText>
												<T>If you want the audience to pay for access, select a price here.</T>
											</HelpText>
											<PaymentSelector eventData={eventData} render_static={props.render_static} setEvent={setEvent} />
										</div>
									}
									{organizerInfo.length > 1 ?
										<div className="py-2">
											<T>Event organisers</T>
											<div>
												<Dropdown className="py-2" isOpen={dropdownOrganizerOpen} toggle={() => setDropdownOrganizerOpen(!dropdownOrganizerOpen)}>
													<DropdownToggle className="btn_editevent zevio_input">
														<span className="opposites"><T>Select</T><img src="https://static.zevio.com/gfx/ico_expandable.svg" alt='icoexp' /></span>
													</DropdownToggle>
													<DropdownMenu>
														{organizerInfo.filter((item) => { return !eventData.OrganizerIds.includes(item.Id) }).sort((a, b) => a.Name.localeCompare(b.Name)).map((org, i) => (
															<DropdownItem
																key={i}
																onClick={(e) => org_select_it(e, org.Id)}
															>
																<span>
																	{org.Name}
																</span>
															</DropdownItem>
														))}
													</DropdownMenu>
												</Dropdown>
												<div className="pt-1">
													{organizerInfo.filter((item) => { return eventData.OrganizerIds.includes(item.Id) }).map((org, i) => (
														<button
															key={i}
															className="badge_grey zevio_input"
															onClick={(e) => e.preventDefault() + org_deSelect_it(e, org.Id)}
														>
															<img src="https://static.zevio.com/gfx/ico_close.svg"
																className="me-1"
																alt="close"
																style={{ marginBottom: "2px" }} />
															{org.Name}
														</button>
													))}
												</div>
											</div>
											{eventData.OrganizerIds.length === 0 ? <span className="red_text"><T>Please add organizer(s)</T></span> : null}
										</div>
										: null}
									<div className="py-2">
										<T>Short Description</T>
										<HelpText>
											<T>Let your audience know in one or two sentences what this webinar will give them</T>
										</HelpText>
										<div>
											<textarea
												className="w-100 zevio_input"
												onChange={handleDescriptionChangeShort}
												value={(eventData.DescriptionShort ? eventData.DescriptionShort : "")}
												rows={3}
												onBlur={() => saveEvent(false)}
											/>
										</div>
									</div>
									<div className="py-2">
										<T>Description</T>
										<HelpText>
											<T>The more verbose story about your organisation</T>
										</HelpText>
										<div>
											<EditorToolbar />
											<ReactQuill
												onChange={handleDescriptionChange}
												value={stateTextEditorDescription.value}
												modules={modules}
												formats={formats}
												onBlur={() => saveEvent(false)}
											/>
										</div>
									</div>
									<div className="py-2">
										<T>Action text</T>
										<HelpText>
											<T>Get the viewer to take action. For instance: 'Find my book in Amazon...'</T>
										</HelpText>
										<div>
											<ReactQuill
												onChange={handleDescriptionChangePromoText}
												value={stateTextEditorPromoText.value}
												formats={formats}
												onBlur={() => saveEvent(false)}
											/>
										</div>
									</div>
								</div>
							</Col>
							<Col xs="12" lg="6" className="pt-2 pb-3 pe-4">
								<div className="py-2">
									<T>Cover image</T>
									{coverImage
										?
										<ShowImage showbutton="true" src={coverImage} onClick={deleteCoverImg} className="w-100 transform_scale_1" />
										:
										(
											<React.Fragment>
												<HelpText>
													<T>Minimum dimensions 1320px &times; 742px. <span className="font_weight_500"><i>Recommended</i></span> minimum is 2640px &times; 1484px</T>
												</HelpText>
												<div className="pe-lg-4 pt-2">
													<DropCropper doneCropping={doneCroppingCover} maxWidth={2640} maxHeight={1484} minWidth={1320} minHeight={742} />
												</div>
											</React.Fragment>
										)}
								</div>
								<div className="py-2">
									<T>Thumbnail Image</T>
									{thumbnailImage
										?
										<ShowImage showbutton="true" src={thumbnailImage} onClick={deleteThumbImg} className="w-50 mw_mh_300px transform_scale_1" />
										:
										(
											<React.Fragment>
												<HelpText>
													<T>Minimum dimensions 312px &times; 191px. <span className="font_weight_500"><i>Recommended</i></span> minimum is 624px &times; 382px</T>
												</HelpText>
												<div className="pe-lg-4 pt-2 w-50">
													<DropCropper doneCropping={doneCroppingThumb} minWidth={312} minHeight={191} maxWidth={624} maxHeight={382} />
												</div>
											</React.Fragment>
										)}
								</div>
							</Col>
						</Row>
					</Container>
					<Container fluid className="bg_white promotion_box p-0 mt-3 mb-4">
						<Row>
							<div className="bottom_border_promo_listing w-100 font_15px font_weight_500">
								<div className="ps-4 py-2">
									<T>Time and Date</T>
								</div>
							</div>
							<Col xs="12" lg="6" className="pt-2 pb-3">
								<div className="px-4">
									<div className="py-2">
										<T>Start Date & Time</T><br />
										<DatePicker
											selected={startDate}
											minDate={new Date('2020-01-01')}
											timeIntervals={15}
											dateFormat="yyyy-MM-dd HH:mm"
											showTimeSelect
											timeFormat="HH:mm"
											onChange={updateStartDateTime}
											className="zevio_input w-100"
											placeholderText={eventData.TimeFrom ? eventData.Date + " " + eventData.TimeFrom.slice(0, -3) : props.render_static(<T>Start date & time</T>)}
										/>
									</div>
								</div>
							</Col>
							<Col xs="12" lg="6" className="pt-2 pb-3">
								<div className="px-4">
									<div className="py-2">
										<T>End time</T><br />
										<DatePicker
											showTimeSelect
											showTimeSelectOnly
											selected={endDate}
											minDate={startDate}
											timeIntervals={15}
											dateFormat="HH:mm"
											timeFormat="HH:mm"
											onChange={updateEndDateTime}
											className="zevio_input w-100"
											placeholderText={eventData.TimeTo ? eventData.TimeTo.slice(0, -3) : props.render_static(<T>End time</T>)}
										/>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
					<Container fluid className="bg_white promotion_box p-0 mt-3 mb-4">
						<Row>
							<div className="bottom_border_promo_listing w-100 font_15px font_weight_500">
								<div className="ps-4 py-2">
									<T>Social</T>
								</div>
							</div>
							<Col xs="12" lg="6" className="pt-2 pb-3">
								<div className="px-4">
									<label className='pt-3'>
										<input className="checkbox_green ps-5"
											key={Math.random()}
											style={{ transform: "scale(1.2)", }}
											id="agree_terms"
											type="checkbox"
											defaultChecked={eventData.ShowChat}
											onChange={(e) => setEvent({ ...eventData, ShowChat: e.target.checked })}
										/>
										<span className="ps-2"><T>Show chat</T></span>
										<div className="ps-4">
											<HelpText>
												<T>Would you want people to be able to chat while broadcasting?</T>
											</HelpText>
										</div>
									</label>
								</div>
							</Col>
							<Col xs="12" lg="6" className="pt-2 pb-3">
								<div className="px-4">
									<div className="py-2">
										<T>Facebook event URL</T><br />
										<HelpText>
											<T>If you have made an event on Facebook for this event, you can add the link here.</T>
										</HelpText>
										<input
											placeholder={eventData.FacebookEventUrl ? eventData.FacebookEventUrl : "https://"}
											name="FacebookLink"
											id="FacebookLink"
											form="edit_event"
											className="zevio_input w-100"
											value={eventData.FacebookEventUrl}
											onChange={(e) => setEvent({ ...eventData, FacebookEventUrl: e.target.value })}
										/>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
					<Container fluid className="bg_white promotion_box p-0 mt-3 mb-4">
						<Row>
							<div className="bottom_border_promo_listing w-100 font_15px font_weight_500">
								<div className="ps-4 py-2">
									<T>Visibility and more</T>
								</div>
							</div>
							<Col xs="12" lg="4" className="pb-3">
								<div className="px-4">
									<label className='pt-3'>
										<input className="checkbox_green ps-5"
											key={Math.random()}
											style={{ transform: "scale(1.2)", }}
											id="agree_terms"
											type="checkbox"
											defaultChecked={eventData.IsHidden}
											onChange={() => setEvent({ ...eventData, IsHidden: !eventData.IsHidden })}
										/>
										<span className="ps-2"><T>Hide event</T></span>
										<div className="ps-4">
											<HelpText>
												<T>Make this event unsearchable</T>
											</HelpText>
										</div>
									</label>
								</div>
							</Col>
							{me?.roles?.includes(42) && <>
							<Col xs="12" lg="4" className="pb-3">							
								<div className="px-4">
									<label className='pt-3'>
										<input className="checkbox_green ps-5"
											key={Math.random()}
											style={{ transform: "scale(1.2)", }}
											id="agree_terms"
											type="checkbox"
											defaultChecked={eventData.SignupFormType === 'simple_zip' ? true : false}
											onChange={(e) => setVisibility("simple_zip", e.target.checked)}
										/>
										<span className="ps-2">Include zip code</span>
										<div className="ps-4">
											<HelpText>
												<T>Require user to enter zip code in addition to name and email when signing up</T>
											</HelpText>
										</div>
									</label>
								</div></Col>
											<Col xs="12" lg="4" className="pb-3">							
								<div className="px-4">
									<label className='pt-3'>
										<input className="checkbox_green ps-5"
											key={Math.random()}
											style={{ transform: "scale(1.2)", }}
											id="agree_terms"
											type="checkbox"
											defaultChecked={eventData.SignupFormType === 'b2b' ? true : false}
											onChange={(e) =>  setVisibility("b2b", e.target.checked)}
										/>
										<span className="ps-2">B2B event</span>
										<div className="ps-4">
											<HelpText>
												<T>This is a business-to-business event.</T>
											</HelpText>
											<HelpText>
												<T>(Add phone, Title and Company to checkout)</T>
											</HelpText>
										</div>
									</label>
								</div>
							</Col>
							</>	}
						</Row>
					</Container>
					<Container fluid className="bg_white promotion_box p-0 mt-3 mb-4">
						<Row>
							<div className="bottom_border_promo_listing w-100 font_15px font_weight_500">
								<div className="ps-4 py-2">
									<T>Save event</T>
								</div>
							</div>
							<Col xs="12" lg="4" className="pb-3">
								<div className="px-4">
									<label className='pt-3'>
										<input className="checkbox_green ps-5"
											key={Math.random()}
											style={{ transform: "scale(1.2)", }}
											id="agree_terms"
											type="checkbox"
											defaultChecked={eventData.SaveWebinar}
											onChange={(e) => setEvent({ ...eventData, SaveWebinar: e.target.checked })}
										/>
										<span className="ps-2">Save event</span>
										<div className="ps-4">
											<HelpText>
												<T>Make it watchable anytime as on demand</T>
											</HelpText>
										</div>
									</label>
								</div>
							</Col>
							<Col xs="12" lg="4" className="pb-3">
								<div className="px-4">
									<label className='pt-3'>
										<span><T>Post processing</T></span>
										<HelpText>
											<T>Post processing for editing and reupload of video will be available once the event is over</T>
										</HelpText>
										<a href={postprocessUrl}>URL</a>
									</label>
								</div>
							</Col>
						</Row>
					</Container>
				</form>
				<ProductSelector eventData={eventData} />
				<EventGuideCollapsibles />
			</Container>
		);
	} else {
		return <div className='text-center'><T>Loading data...</T><Spinner className="p-2" color="success" /></div>;
	}
}

const EditEvent = () => {
	return (<>
			<ZevioNav />
			<EditEventInner />
			<Footer />
		</>
	);
}
export default EditEvent;
