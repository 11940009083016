import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { APIGet } from '../API.js';
import ZevioNav from "../c/ZevioNav";
import Footer from "../c/Footer";
import ChatComponent from "../c/ChatComponent";
import { MeContext } from "../MeContext";
import { T } from '../traks';

const DownloadChatTranscript = ({eventData}) => {
	const processMsgs = (msgs) => {
		let msgsStr = "";
		msgs.forEach( (m) => {
			msgsStr += m.ts.substring(11, 19) + " [" + m.userName + "]: " + m.msg + '\n';
		});
		const element = document.createElement("a");
		const file = new Blob([msgsStr], {type: 'text/plain;charset=utf-8'});
		element.href = URL.createObjectURL(file);
		element.download = "chat-" + eventData.Id + ".txt";
		document.body.appendChild(element);
		element.click();
	}

	const doDownload = () => {
		APIGet('organizer', {'action': 'download_chat_transcript', 'event_id': eventData.Id})
			.then((res) => {
				processMsgs(res.data.body);
			})
			.catch((error) => {
				console.log(['Error:', error])
			})
	}
	return (
		<div>
			<span onClick={doDownload} className="grey_frontpage font_11px ps-1 text_underline cursor-pointer"><T>Download chat transcript</T></span>
		</div>
	);
}

export const ChatPage = () => {
	let userIsOrganizer = false;
	const { me } = useContext(MeContext);
	const [eventData, setEventData] = useState(false);
	const params = {
		'unique_hash': (useParams().uniqueHash),
	}

	useEffect( () => {
		APIGet('search', params)
			.then((res) => {
				setEventData(res.data.body[0])
			})
			.catch((error) => {
				console.log(['Error:', error])
			})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if(me && (me.roles.includes(42) || eventData.organizers.filter(e => me.organizers.includes(e.Id)).length))
		userIsOrganizer = true;

	return (
		<React.Fragment>
			<ZevioNav />
			<Container className="pb-5">
				{eventData && <ChatComponent eventData={eventData} />}
				{userIsOrganizer && <DownloadChatTranscript eventData={eventData} />}
			</Container>
			<Footer />
		</React.Fragment>
	);
}

export default ChatPage;
