import React from 'react';
import { T } from '../traks';
import I18N from '../I18N';
import { url_lang } from "../languages";
import BuyProductModal from './BuyProduct';

export const ProductPriceElem = ({productData}) => {
	let from = null;
	if(productData.externalLink) from = <span><b><T>From</T></b>&nbsp;</span>
	if(Object.keys(productData.price).length) {
		return (
			<div>
			{from}
			<span className="card_product_price ps-2">
				{I18N.floatval_unit(productData.price[url_lang().currency], 2, url_lang().currency.toUpperCase(), "card_product_currency")}
			</span>
			</div>
		);
	}

	return <div className="py-3 font_weight_500"><T>Contact us for price</T></div>;
}

export const ProductCardButton = ({productData, classes}) => {
	if(productData.externalLink) {
		const href = productData.externalLink + "?utm_source=Zevio&utm_medium=Zevio_product&utm_campaign=zevio_" + productData['productId'];
		return (
			<a target="_blank" rel="noopener noreferrer" href={href}>
				<button className={classes}>
					<T>See more</T>
				</button>
			</a>
		)
	}
	return (<BuyProductModal className='btn btn_green px-2 py-2 border-0 w-100' productData={productData} />
	);
}
