import React, { useEffect, useState } from 'react';
import { Container, Row } from 'reactstrap';
import { APIGet } from '../../API';
import { T } from '../../traks';

const WarningIcon = () => <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" viewBox="0 0 48 48"><path d="M46.707,14.6l-12.3-12.3A1,1,0,0,0,33.7,2H16.3a1,1,0,0,0-.707.293L3.293,14.6A1,1,0,0,0,3,15.3V32.7a1,1,0,0,0,.293.707l12.3,12.3A1,1,0,0,0,16.3,46H33.7a1,1,0,0,0,.707-.293l12.3-12.3A1,1,0,0,0,47,32.7V15.3A1,1,0,0,0,46.707,14.6Z" fill="#f74b3b"></path><path d="M36,29a8,8,0,0,1-8,8H22.743a8,8,0,0,1-7.019-4.162L11.2,24.571l.006,0a1.733,1.733,0,0,1-.21-.816,1.745,1.745,0,0,1,3.108-1.1l.006,0L18,26.781V12.5a1.5,1.5,0,0,1,3,0V21a1,1,0,0,0,2,0V11.5a1.5,1.5,0,0,1,3,0V21a1,1,0,0,0,2,0V12.5a1.5,1.5,0,0,1,3,0V22a1,1,0,0,0,2,0V16a1.5,1.5,0,0,1,3,0Z" fill="#fff"></path></svg>

const Credentials = ({ creds }) => {
	return (
		<table className='table table-striped'>
			<thead>
				<tr className='text-start'>
					<th scope='col' className='ps-4 text-end' style={{width: "1px"}}>ID</th>
					<th scope='col' className='ps-4'><T>Organizer Name</T></th>
				</tr>
			</thead>
			<tbody>
				{creds.map( (c) => (
				<tr key={'row_' + c.organizerId}>
					<td className='text-end'>{c.organizerId}</td>
					<td className='text-start text-nowrap ps-4'>{c.Name}</td>
				</tr>
				))}
			</tbody>
		</table>
	);
}

const VERIFY_TEXT = 'I understand that with great power comes great responsibility';

const DeleteEmail = ({ verify }) => {
	const [ email2del, setEmail2Del ] = useState('');
	const [ button, setButton ] = useState(<button type='submit' className='px-5 py-2 btn' style={{backgroundColor: 'red', color: 'white'}}>NUKE IT!!</button>);

	const doDelete = (event) => {
		event.preventDefault();
		setButton(<span>Processing... please be patient</span>);
		APIGet('admin', { 'action': 'remove_email_getresponse', 'email2delete': email2del }).then((res) => {
			setButton(<span>{email2del} deletion scheduled.</span>);
		});
	}

	if(verify === VERIFY_TEXT) {
		return (
			<div className='flex-grow-1'>
			<form onSubmit={doDelete}>
				<div className='font_24px font_weight_600'>
				Email in question:
				</div>
				<div className='pt-3 pb-4'>
				<input className='px-3 py-2 w-100' style={{border: '1px solid #aaa', borderRadius: '4px'}}
				type='email' onChange={(e) => setEmail2Del(e.target.value)} />
				</div>
				{button}
			</form>
			</div>
		);
	}

	return (
		<div style={{fontSize: '120%'}}>
			Be very aware that when you use this service <b>all</b> the external mailinglists
			for <b>all</b> the below companies will have the email address <span
			style={{color: 'red'}}>deleted</span> from <b>all</b> their mailing lists.<br />
			Futhermore will all orders in the Zevio backend be forever anonymized for this
			email as well.<br />
			Please confirm that these consequences are clear to you by typing '{VERIFY_TEXT}'
			in the input field below the list.
		</div>
	);
}

const Seppuku = () => {
	const [ creds, setCreds ] = useState([]);
	const [ verifyInput, setVerifyInput ] = useState('');
	const [ verify, setVerify ] = useState('');
	const [ cheatingTxt, setCheatingTxt ] = useState(null);

	useEffect( () => {
		APIGet('admin', {'action': 'get_getresponse_credentials'}).then( (r) => {
			setCreds(r.data.body.credentials);
		});
	}, []);

	const onPaste = (e) => {
		e.preventDefault();
		setCheatingTxt(<span style={{color: 'red'}}>No cheating. You have to type it.</span>);
	};

	const doAck = (event) => {
		event.preventDefault();
		setVerify(verifyInput);
	}

	return (
		<Container className='pb-5'>
			<div className='py-4 text-center'>
				<h3><T>Delete email from GetResponse lists</T></h3>
			</div>
			<Container fluid className='mb-3' style={{backgroundColor: 'yellow', border: '1px solid black', borderRadius: '8px'}}>
				<div className='d-flex flex-row p-5 justify-content-between'>
					<div className='pe-5 d-flex align-items-center'>
						<WarningIcon />
					</div>
					<DeleteEmail verify={verify} />
					<div className='ps-5 d-flex align-items-center'>
						<WarningIcon />
					</div>
				</div>

			</Container>
			<Container fluid className='bg-white' style={{borderRadius: '8px'}}>
				<Row className='px-0'>
					<Credentials creds={creds} />
					{verify !== VERIFY_TEXT &&
					<form className='pb-3' onSubmit={doAck}>
						{cheatingTxt ? cheatingTxt : <span>Acknowledge here:</span>}
						<input className='ms-3 w-50 px-3 py-2' type='text' style={{border: '1px solid #aaa',
						borderRadius: '4px'}} onChange={(e) => setVerifyInput(e.target.value)} onPaste={onPaste} />
					</form>}
				</Row>
			</Container>
		</Container>
	);
}

export default Seppuku;
