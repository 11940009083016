import React, { useContext, useEffect } from "react";
import SignupContext from "../useSignup";
import { T } from "../../../traks";
import EventCtx from "../useEvent";

const SubscribeNewsletter = () => {
	const { subscribeNewsletter, setSubscribeNewsletter, accessProduct } =
		useContext(SignupContext);
	const { eventData } = useContext(EventCtx);
	const organizersStr = eventData?.organizers?.map((o) => o.Name).join(", ");
	
	// automatically subscribe to newsletter if non-paid event
	useEffect(()=>{
		if (!accessProduct) setSubscribeNewsletter(true)
	}, [accessProduct, setSubscribeNewsletter])
	if (!accessProduct) return null
	
	return (
		<label className="pt-4 pb-2">
			<input
				className="checkbox_green"
				id="subscribeNewsletterCheck"
				style={{float:"left", marginTop:"6px", marginRight:"7px"}}
				type="checkbox"
				checked={subscribeNewsletter}
				onChange={(e) => {
					setSubscribeNewsletter(e.nativeEvent.target.checked);
				}}
			/>
			{eventData?.organizers?.length? <MultipleOrganizers organizersStr={organizersStr}/> : <span className="grey_light" style={{fontSize:"13px"}}>
	<T>I want to receive newsletters from</T>{" "}<span style={{fontWeight:700}}>Zevio</span>
	</span>}
		</label>
	);
};

const MultipleOrganizers = ({organizersStr}) => <span className="grey_light" style={{fontSize:"13px"}}>
	<T>I want to receive newsletters from the organizers</T>{" "}<span style={{fontWeight:700}}>{organizersStr}</span>{" "}<T>and <span style={{fontWeight:700}}>Zevio</span></T>
	</span>

export default SubscribeNewsletter;
