import React from "react";
import { Container } from "reactstrap";
import ZevioNav from "../c/ZevioNav";
import Footer from "../c/Footer";
import EventCtx, { useEvent } from "../c/Event/useEvent";
import EventProfile from "../c/Event";

export const Event = () => {
	const eventCtx = useEvent()
  return (
    <>
      <ZevioNav />
      <Container fluid className="bg_white p-0 min_w100 min_vh100">
				<EventCtx.Provider value={eventCtx}>
				<EventProfile/>
				</EventCtx.Provider>
      </Container>
      <Footer />
    </>
  );
};

export default Event;
