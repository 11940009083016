import React, { useState, useContext, useEffect } from "react";
import { Col, Container, Row, Modal, ModalBody, Navbar } from 'reactstrap';
import { MeContext } from '../MeContext';
import Footer from '../c/Footer';
import { GreenButton, RedButton, AlertReusable } from '../c/ReusableElems';
import ZevioNav from '../c/ZevioNav';
import { T, TraksConsumer } from '../traks';
import { LL } from "../Routes";
import { useNavigate } from "react-router-dom";
import { APIPost } from "../API";

const NotUser = () => {
	const navigate = useNavigate();
	useEffect(() => {
		navigate(LL("/login"));
	}, [navigate])
	return null;
}

const AccountInner = () => {
	const { me } = useContext(MeContext);
	if(me) {
		return (
				<AccountPage />
		);
	} else {
		return (
			<NotUser />
		);
	}
}

const AccountPage = () => {
	const { me, setMe } = useContext(MeContext);
	const [user, setUser] = useState({
		'email': me.email ? me.email : '',
		'firstName': me.firstName ? me.firstName : '',
		'lastName': me.lastName ? me.lastName : '',
		'zip': me.zipCode ? me.zipCode : '',
		'phoneNo': me.phoneNo ? me.phoneNo : '',
		'jobTitle': me.jobTitle ? me.jobTitle : '',
		'company': me.company ? me.company : '',
		'country': me.country ? me.country : '',
		'password': me.password ? me.password : '',
	});

	const [verifyPassword, setVerifyPassword] = useState('');
	const submit_button_disabled =
		!(user.password.length === 0 || (verifyPassword.length > 5 && user.password) === verifyPassword);

	const [feedbackTxt, setFeedbackTxt] = useState('');
	const [feedbackTxt2, setFeedbackTxt2] = useState('');

	const [activateAlert, setActivateAlert] = useState(0);
	const [alertText, setAlertText] = useState('');
	const [alertColor, setAlertColor] = useState('');

	useEffect(() => {
		(user.password.length > 1 && user.password.length < 6 ? setFeedbackTxt(<T>New password too short</T>) : setFeedbackTxt(''));
	}, [user.password]);
	useEffect(() => {
		if (user.password.length > 5 && verifyPassword !== '') {
			(user.password !== verifyPassword ? setFeedbackTxt2(<T>Passwords do not match</T>) : setFeedbackTxt2(''));
		}
	}, [verifyPassword, user.password]);

	const updateUser = () => {
		APIPost("users", { ...user, 'action': 'update_user' }).then((response) => {
		if (response.status === 200) {
			setAlertText(<T>Profile saved</T>);
			setAlertColor("success");
			setActivateAlert(activateAlert + 1);
		}
		else {
			setAlertText(<T>Profile save failed</T>)
			setAlertColor("danger");
			setActivateAlert(activateAlert + 1);
		}})
	}

	function ModalDelete() {
		const [deleteText, setDeleteText] = useState('');
		const [modal, setModal] = useState(false);
		const toggle = () => setModal(!modal);
		const submit_button_disabled = !(deleteText === ('delete me'))

		const navigate = useNavigate();
		const deleteAccount = () => {
			if (deleteText === "delete me") {
				const postObj = {
					'action': 'delete_user',
				};
				APIPost('users', postObj).then((response) => {
					if (response.status === 200) {
						localStorage.removeItem('zevio_ipse_session_id');
						setMe(null);
						setTimeout(() => navigate(LL("/")), 1000);
				}	})
			}
		}

		return (
			<div>
				<span onClick={toggle} className="p-0"><T>Delete account</T></span>
				<Modal centered isOpen={modal} toggle={toggle}>
					<ModalBody className="text-center">
						<div>
							<img className="btn" style={{ float: 'right' }} src="https://static.zevio.com/gfx/ico_close.svg" alt='icoClose' onClick={toggle} />
						</div>
						<div className="ps-5 pt-3 image-container">
							<div className="icon_modal_delete">
								<img src="https://static.zevio.com/gfx/ico_deleteprofile.svg" alt='icoDelProf' />
							</div>
						</div>
						<div className="pt-3">
							<T>Are you sure you want to delete your account?</T>
						</div>
						<div className="pt-4 pb-2">
							<span><T>Type: </T><span style={{ fontWeight: "900" }}>DELETE ME</span><T> to delete this account</T></span>
						</div>
						<div>
							<form onSubmit={(e) => e.preventDefault() + deleteAccount()}>
								<input
								name="Delete"
								id="Delete"
								className="zevio_input w-450 w-100"
								onChange={(e) => setDeleteText(e.target.value.toLowerCase())}
								/>
							</form>
						</div>
						<div className="pt-3 pb-4">
							<RedButton
								className="w-100"
								disabled={submit_button_disabled}
								onClick={deleteAccount}><T>Delete account</T>
							</RedButton>
						</div>
					</ModalBody>
				</Modal>
			</div >
		);
	}

	function ModalResetEmail(props) {

		const [modal, setModal] = useState(false);
		const toggle = () => setModal(!modal);
		const [guideText, setGuideText] = useState(props.render_static(<T>Type in your email and we will send you a reset link</T>));
		const [emailText, setEmailText] = useState('');
		const submit_button_disabled = !(emailText.includes("@"))

		const resetPassword = () => {
			if (!submit_button_disabled) {
				setGuideText(props.render_static(<T>Password has been reset and emailed to you</T>));
				const postObj = {
					'action': 'forgot_password',
					'email': emailText,
					'request_domain': window.location.origin,
				};
				APIPost('users', postObj).then((response) => console.log(response));
			}
		}

		return (
			<div>
				<span onClick={toggle} className="p-0"><T>Reset password</T></span>
				<Modal centered isOpen={modal} toggle={toggle}>
					<ModalBody className="text-center">
						<div>
							<img className="btn" style={{ float: 'right' }} src="https://static.zevio.com/gfx/ico_close.svg" alt='icoClose' onClick={toggle} />
						</div>
						<div className="ps-5 pt-3 image-container">
							<div className="icon_modal_reset">
								<img src="https://static.zevio.com/gfx/ico_resetps.svg" alt='icoDelProf' />
							</div>
						</div>
						<div className="pt-3">
							<h5><T>Forgot password?</T></h5>
						</div>
						<div className="pt-1 pb-4">
							<T>{guideText}</T>
						</div>
						<div>
							<form onSubmit={(e) => e.preventDefault() + resetPassword()}>
								<input
									style={{ width: '100%' }}
									type='email'
									name='Reset'
									id='Reset'
									value={emailText}
									className='zevio_input w-450 w-100'
									placeholder={props.render_static(<T>Your email</T>)}
									onChange={(e) => setEmailText(e.target.value)}
								/>
							</form>
						</div>
						<div className="pt-3 pb-4">
							<GreenButton
								className="w-100"
								disabled={submit_button_disabled}
								onClick={resetPassword}><T>Reset password</T>
							</GreenButton>
						</div>
					</ModalBody>
				</Modal>
			</div >
		);
	}

	return (
		<Container>
			<AlertReusable
				color={alertColor}
				activateAlert={activateAlert}
				text={alertText}
				delay="2000"
			/>
			<div className="pt-4 pb-3 d-flex flex-col align-items-center justify-content-between">
				<div>
					<h3><T>My account</T></h3>
				</div>
				<div>
					<GreenButton disabled={submit_button_disabled} onClick={updateUser}><T>Save</T></GreenButton>
				</div>
			</div>
			<form onSubmit={(e) => e.preventDefault()} id='edit_user'>
			<Container fluid className="bg_white promotion_box p-0 pb-3">
				<Row>
					<div className="bottom_border_promo_listing w-100 font_15px font_weight_500">
						<div className="py-2 px-2 ps-lg-4 font_weight_500">
							<T>Account information</T>
						</div>
					</div>
					<Col xs="12" lg="6" className="pt-2">
						<div className="px-2 ps-lg-4">
							<div className="py-2">
								<T>First name</T>
								<input
									name="Firstname"
									id="FirstName"
									form="edit_user"
									className="zevio_input w-100"
									value={user.firstName}
									onChange={(e) => setUser({ ...user, firstName: e.target.value })}
							/>
							</div>
							<div className="py-2">
								<T>Email</T>
								<input
									name="Email"
									id="Email"
									form="edit_user"
									className="zevio_input w-100"
									value={user.email}
									onChange={(e) => setUser({ ...user, email: e.target.value })}
								/>
							</div>
							<div className="py-2">
								<T>Zip code</T>
								<input
									name="Zipcode"
									id="Zipcode"
									form="edit_user"
									className="zevio_input w-100"
									value={user.zip}
									onChange={(e) => setUser({ ...user, zip: e.target.value})}
								/>
							</div>
							<div className="py-2">
								<T>Job title</T>
								<input
									name="JobTitle"
									id="JobTitle"
									form="edit_user"
									className="zevio_input w-100"
									value={user.jobTitle}
									onChange={(e) => setUser({ ...user, jobTitle: e.target.value})}
								/>
							</div>
						</div>
					</Col>
					<Col xs="12" lg="6" className="pt-lg-2 pb-3">
						<div className="px-2 pe-lg-4">
							<div className="py-2">
								<T>Last name</T>
								<input
									name="Lastname"
									id="LastName"
									form="edit_user"
									className="zevio_input w-100"
									value={user.lastName}
									onChange={(e) => setUser({ ...user, lastName: e.target.value })}
								/>
							</div>
							<div className="py-2">
									<T>Country</T>
									<input
										name="Country"
										id="Country"
										form="edit_user"
										className="zevio_input w-100"
										value={user.country}
										onChange={(e) => setUser({ ...user, country: e.target.value })}
									/>
							</div>
							<div className="py-2">
								<T>Phone number (area & country code allowed)</T>
								<input
									name="PhoneNumber"
									id="PhoneNumber"
									form="edit_user"
									className="zevio_input w-100"
									value={user.phoneNo}
									onChange={(e) => setUser({ ...user, phoneNo: e.target.value })}
								/>
							</div>
							<div className="py-2">
								<T>Company / Organization</T>
								<input
									name="Company"
									id="Company"
									form="edit_user"
									className="zevio_input w-100"
									value={user.company}
									onChange={(e) => setUser({ ...user, company: e.target.value })}
								/>
							</div>
						</div>
					</Col>
				</Row>
				</Container>
				<div className="pt-5"> </div>
				<Container fluid className="bg_white promotion_box p-0 pb-3">
					<Row>
						<div className="bottom_border_promo_listing w-100 font_15px font_weight_500">
							<div className="py-2 px-2 ps-lg-4 font_weight_500">
								<T>Change password</T>
							</div>
						</div>
						<Col xs="12" lg="6" className="pt-2 pb-3">
							<div className="px-2 ps-lg-4">
								<div className="py-2">
									<T>New password</T>
									<input
										type='password'
										name="Password"
										id="Password"
										form='edit_user'
										className='zevio_input w-100'
										onChange={(e) => setUser({ ...user, password: e.target.value })}
								/>
								</div>
							</div>
							<p className="red_text m-0 ps-4">{feedbackTxt}</p>
						</Col>
						<Col xs="12" lg="6" className="pt-2 pb-3 ">
							<div className="px-2 pe-lg-4">
								<div className="py-2">
									<T>New password again</T>
									<input
										type='password'
										name='Passwordagain'
										id='Passwordagain'
										form='edit_user'
										className='zevio_input w-100'
										onChange={(e) => setVerifyPassword(e.target.value) }
									/>
								</div>
							</div>
							<p className="red_text m-0">{feedbackTxt2}</p>
						</Col>
					</Row>
				</Container>
				<div className="pt-3 pb-5 text-end">
					<span className="btn"><TraksConsumer><ModalResetEmail /></TraksConsumer></span>
					<span className="vertical_line_dark"/>
					<span className="btn pe-0"><ModalDelete /></span>
				</div>
			</form>
		</Container>
	);
}

const Account = () => {
	return (<>
		<ZevioNav />
		<AccountInner />
		<Footer />
	</>);
}
export default Account;
