import React from "react";
import { Container } from "reactstrap";
import ZevioNav from "../c/ZevioNav";
import Footer from "../c/Footer";
import OrganizerProfile from "../c/OrganizerProfile";
import { useParams } from "react-router-dom";

export const Organizer = () => {
	const { organizerNameId } = useParams();

	return (
		<React.Fragment>
			<ZevioNav />
			<Container fluid className="bg_white p-0 min_w100 min_vh100">
				<OrganizerProfile organizerNameId={organizerNameId} />
			</Container>
			<Footer />
		</React.Fragment>
	);
}

export default Organizer;
